@charset "UTF-8";

//이용약관
.agree-wrap {
    margin-top:6.9444vw;
    #agreeAll {
        &+label {
            span{@include defaultTxt;}
        }
    }
    .agree-group {
        margin-top:5.5556vw; padding:0 4.1667vw;
        .btn-check {
            margin-bottom:4.1667vw;
            label {
                span {
                    font-weight: 400;
                    &>strong {@include fontInherit;}
                    &>em.txt-vt{color: $black !important;}
                    &>b {@include fontInherit;color:$gray05 !important;}
                }
            }
            &.agree-nec {
                label {
                    span{color:#191919 !important;}
                }
             } /*필수 색상*/
            &.agree-sel {
                label {
                    span{color:#666 !important;}
                }
             } /*선택 색상*/
        }
    }
}

.box-input {
    width:100%;
    .input-wrap{position: relative;}
    input[type=text],input[type=number]{
        &:disabled{
            background-color: $gray01;
            &::placeholder{
                opacity: 0.5;  @include smallDepthTxt;
            }
        }
    }
    .input {
        &-label {
            @include label;
            & ~ .ico-delete{top: 15.1944vw;}
        }
        &-default{
            @include input;
            &.is-delete{padding-right: 10.8333vw;}
            &.is-time{padding-right: 23.8556vw;}
            // &.is-unit{padding-right: 12.1111vw;}
            &.is-unit{padding-right: 20.8556vw;}
            &.is-masking{padding-right: 22.2222vw;}
            &::placeholder{@include smallDepthTxt;}
            &:focus{border-color: $black;}
        }
        &-unit{
            @include defaultTxt;
            position: absolute;right: 4.1667vw;top: 50%;transform: translateY(-50%);
            &.on{right: 12.5vw;}
        }
    }
    .ico{
        &-delete {display: none; position: absolute; top: 50%;right: 4.1667vw;transform: translateY(-50%);}
        &-eye {
            display: block;position: absolute; top: 50%;right: 4.1667vw; width: 6.6667vw; height: 6.6667vw;@include backgroundImg('/assets/images/icon/ico-eye-off.svg', center, contain); transform: translateY(-50%);
            &.show{background-image: url('/assets/images/icon/ico-eye-on.svg');}
            &.on{right: 12.5vw;}
        }
    }
    .txt{
        &-error {display: none;}
        &-description {display:none; @include defaultTxt;font-weight: 400;}
        &-sending {display:none; @include defaultTxt; font-weight: 400;}
        &-complete {display:none; @include smallTxt; color:#1baa46;}
        &-time {
            position: absolute;top: 50%;right: 4.1667vw;@include defaultTxt; color:$purple;transform: translateY(-50%);
            &.on{right: 12.5vw;}
        }
    }

    // error case
    &.error {
        // .input-default {border-color: $red;}
        .txt-error{
            display:block;color: $red;margin-top: 2.2222vw;@include defaultTxt; color: $red; font-weight: 400; text-align: left;


        }
    }

    // success case
    &.success {
        .txt-description{display:block;margin-top: 2.2222vw;}
    }

    // 인증번호 발송 case
    &.sending {
        .txt-sending{
            display:block; margin-top: 2.7778vw;
        }
    }
    // 인증번호 발송 완료 case
    &.complete {
        .txt-complete{
            display:block; margin-top: 2.7778vw;
            & + .txt-sending{display: none;}
        }
    }

    // validation-list
    .validation-list{
        margin-top: 2.7778vw;
        &-item{
            position: relative; padding-left: 6.1111vw;font-size:3.8889vw;
            &::before{
                content: '';display: inline-block;position: absolute; left: 0; top: 50%; width: 3.9722vw;height: 3.9722vw;@include backgroundImg('/assets/images/icon/ico-check.svg', center, contain); transform: translateY(-50%);
            }
            &+.validation-list-item{margin-top: 2.2222vw;}
            &.success {
                color: #1baa46;
                &::before{background-image: url('/assets/images/icon/ico-check-success.svg');}
            }
            &.error {
                color: $red;
                &::before{background-image: url('/assets/images/icon/ico-check-error.svg');}
            }
        }
    }
    .serach-input-warp{
        .input-default{
            padding-right:18.8889vw;
        }
        &.txt-search {
            .input-default{
               padding: 3.6111vw 18.8889vw 3.6111vw 4.1667vw; font-size: 5.5556vw;font-weight: 600;line-height: 1;letter-spacing: -0.1111vw;text-align: left;color: #662d91;
            }
            &.search-after {
                .input-default{color:#191919}
            }
        }
        .ico-search{
            position: absolute; top: 50%; right:4.1667vw; width:4.4444vw; height:100%; background: url('/assets/images/icon/ico-search.svg') no-repeat center center; background-size: contain; transform: translateY(-50%); font-size: 0;
            &.on {
                & + .ico-delete{
                    right: 12.5vw;
                }
            }
        }
    }
}

.input-group {
    display:flex;flex-wrap:wrap;gap:2.7778vw;
    .default-txt{@include mainTxt;}
    &>label {width:100%;}
    .box-input {flex:1 1 40%;margin-top:0 !important;}
}
.select-input-wrap{
    display:flex;flex-wrap:wrap;gap:2.7778vw;
    &>label {width:100%;}
    .box-select{
        width: 33.3333vw;
    }
    .box-input {flex:1 1 40%;margin-top:0 !important;}
}

.button-input-wrap {
    display:flex;flex-wrap:wrap;gap:2.7778vw;
    &>.default-txt {width:100%;}
    .btn-select{
        width: 33.3333vw;
    }
    .box-input {flex:1 1 40%;margin-top:0 !important;}
}
.input-depth{
    display: flex; flex-direction: column; gap: 2.2222vw;
    .btn-check{
        justify-content: flex-end;
        label{
            justify-content: flex-end;
            span{font-weight: 400;}
        }
    }
}
.box-flex{
    display: flex;flex-wrap: nowrap;align-items: flex-start;gap: 1.3889vw;position: relative; padding-top: 7.6389vw; position: relative;
    .box-input{
        .input-label {position: absolute; top: 0;}
        .input-default{
            min-height: 13.8889vw;padding: 3.8889vw 4.4444vw 3.8889vw;
            // padding 작은 사이즈
            &.input-sm {padding: 3.8889vw 2.1667vw 3.8889vw;}
            &.is-delete{
                padding: 3.8889vw 11.3vw 3.8889vw 4.4444vw;
                &.is-time{
                    padding: 3.8889vw 23.8556vw 3.8889vw 4.4444vw;
                }
            }
        }
    }
    .box-select {
        position: static;
        .select-label {position: absolute; top: 0;}
        .select-default{
            padding: 4.0444vw 4.1667vw;
        }
    }
    .box-btn{
        flex-shrink: 0;
         button{width: 100%;min-height: 13.8889vw; min-width: 25vw;}
         a{display: block; min-width: 25.4167vw; text-align: center;}
    }
    &.column{
        flex-direction: column; gap:4.1667vw;
        .box-input{flex: 1 1 100%;}
        .box-btn{flex: 1 1 100%;width: 100%;}
    }
    &.search-input-warp {
        padding-top:0;
        .box-input, .box-btn {margin-top:0 !important;}
        .box-btn {
            button {width:26.3889vw;}
        }
    }
    &.box-unit {
        .box-input {
            .input-default {
                &.is-unit {padding-right:17.5vw!important;}
            }
        }
    }
}

// 휴대폰 인풋
.box-input-phone{
    display: flex; flex-wrap: wrap;
    .input-label{width: 100%;}
    .input-wrap{
        position: relative; max-width: 26.3889vw;width: calc((100% - 9.4444vw) / 3); justify-content: space-between;
        &:nth-of-type(n + 2) {
            margin-left: 4.7222vw;
            &::before{
                content: '-';position: absolute; top: 50%; left: -3.0556vw; transform: translateY(-50%);
            }
        }
    }
    .box-btn{
        width: 100%; margin-top: 4.1667vw;
        p{
            margin-top: 2.7778vw;
            @include smallTxt;
        }
    }
}

.box-select{
    position:relative;width: 100%;
    .select{
        &-label{@include label;}
        &-default{
            @include input;cursor: pointer;@include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', calc(100% - 4.1667vw) center, 5.5556vw 5.5556vw);
            @include ellipsis; padding: 4.4444vw 11.1667vw 4.4444vw  4.1667vw;
            &:disabled{
                color: #afafaf;cursor: default;background-image: url('/assets/images/icon/ico-arrow-bottom-gray.svg'); background-color: #f6f6f6;
            }
        }
    }
    &.select-more{
        display: none; margin-top: 2.2222vw;
        &.on{display: block;}
    }
    .text-select{
        width: 29.1667vw;
        cursor: pointer;
        padding: 0 4.1667vw 0 0;
        border:none;
        @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', no-repeat calc(29.1667vw - 4.1667vw) center, 2.75vw 2.75vw );
        @include mainEmphasize;
    }
}
.btn-select{
    .main-txt{margin-bottom: 2.2222vw;}
    button{
        @include input;cursor: pointer;@include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', calc(100% - 4.1667vw) center, 5.5556vw 5.5556vw);
        text-align: left; width: 100%; display: block;
    }
}
.date-picker{
    @include backgroundImg('/assets/images/icon/ico-calendar.svg', calc(100% - 5.5556vw) center, 5.5556vw 5.5556vw);
}
.month-picker{
    @include backgroundImg('/assets/images/icon/ico-calendar.svg', calc(100% - 5.5556vw) center, 5.5556vw 5.5556vw);
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background-image: url(/assets/images/icon/ico-calendar.svg) !important;
    }
    
}

//체크박스
.btn-check{
    position: relative; display: flex; justify-content: space-between; align-items: flex-start;
    input[type=checkbox] {
        @include hidden;
        &:checked + label{
            span{
                &::before{
                    background-color:$purple; transition: all 0.3s ease-out;
                }
                &::after{
                    @include backgroundImg('/assets/images/icon/ico-check-on.svg', center,3.0556vw 2.2222vw);
                }
            }
        }
        &:disabled + label{
            cursor: default;
            span{
               color:$gray05;
               &::after{
                    content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: inline-block; width: 5.5556vw; height: 5.5556vw;
                    @include backgroundImg('/assets/images/icon/ico-check-disable.svg', center,3.0556vw 2.2222vw);
               }
            }
        }
    }
    label{
        cursor: pointer; display: inline-flex; align-items: center; width: calc(100% - 7vw);
        span{
            @include defaultTxt; font-weight: 400;
            display: inline-block; padding-left:8.3333vw; position: relative; transition: all 0.3s ease-out;
             &::before{
                content: ""; position:absolute; top:0; left:0; display: inline-block; width: 5.5556vw; height: 5.5556vw; background-color:$gray02; border-radius: 50%;
            }
            &::after{
                content: ""; position:absolute; top: 0.1vw; left:0;display: inline-block; width: 5.5556vw; height: 5.5556vw;
                @include backgroundImg('/assets/images/icon/ico-check-on.svg', center, 3.0556vw 2.2222vw);
            }
            &>strong {@include fontInherit;}
            &>em.txt-vt{color: $black !important;}
            &>b {@include fontInherit;color:$gray05 !important;}
        }
    }
    &.white{
        width:100%; padding:4.1667vw; border:0.2778vw solid $black;border-radius: 1.9444vw;
    }
}
//btn-check-type2
.btn-check-type2{
    input[type=checkbox]{
        @include hidden;
        &:checked + label{
            border:0.2778vw solid $black; transition: all 0.3s ease-out;
            span{
                &::before{
                    background-color:$purple;
                }
                &::after{
                    @include backgroundImg('/assets/images/icon/ico-check-on.svg', center,3.0556vw 2.2222vw);
                }
            }
        }
    }
    label{
        cursor: pointer; display: inline-flex; align-items: center; width:100%; padding:4.1667vw; border:0.2778vw solid $gray02; border-radius: 1.9444vw;
        span{
            @include defaultTxt; font-weight: 600;
            display: inline-block; padding-left:8.3333vw; position: relative;
             &::before{
                content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: inline-block; width: 5.5556vw; height: 5.5556vw; background-color: $gray02; border-radius: 50%;
            }
            &::after{
                content: ""; position:absolute; top:50%; left:0; transform: translateY(-50%); display: inline-block; width: 5.5556vw; height: 5.5556vw;
                @include backgroundImg('/assets/images/icon/ico-check-on.svg', center, 3.0556vw 2.2222vw);
            }
            &>strong {@include fontInherit;}
            &>em.txt-vt{color: $black !important;}
            &>b {@include fontInherit;color:$gray05 !important;}
        }
    }
}
//btn-check-type3
.btn-check-type3{
    input[type=checkbox]{
        @include hidden;
        &:checked + label{
            border:0.2778vw solid $black; transition: all 0.3s ease-out;
        }
    }
    label{
        cursor: pointer; padding: 3.4722vw 0;  border-radius: 4.1667vw;  border: 0.2778vw solid $gray02;  display: inline-block;  min-width:21.3889vw; text-align: center;
    }
}
//btn-check-type4
.btn-check-type4{
    input[type=checkbox]{
        @include hidden;
        &:checked + label{
            span{color: $purple;transition: all 0.3s ease-out;}
            &::before{background-image: url('/assets/images/icon/ico-checkbox-on.svg');}
        }
    }
    label{
        display: inline-flex; align-items: center; cursor: pointer;
        span{@include smallTxt; font-weight: 600; }
        &::before {
            content: ''; display: inline-block; width: 5.5556vw; height: 5.5556vw; margin-right:0.5556vw;  @include backgroundImg('/assets/images/icon/ico-checkbox.svg', center, contain); margin-right: 1.3889vw;
        }
        &:nth-of-type(n+2){margin-left: 4.1667vw;}
    }
}


//라디오 버튼 타이틀
.radio-group-wrap{
    .group-tit{
        @include mainTxt; margin-bottom: 2.2222vw;
    }
}
//라디오 버튼
.btn-radio{
    position: relative; width:100%; border-radius:1.6667vw; overflow: hidden;
    input[type=radio] {
        @include hidden;
        &:checked + label{
            border:0.2778vw solid $purple; color:$purple; transition: all 0.3s ease-out; background: $white;
            & > span{color: $purple;}
        }
        &:disabled + label{
            background-color: $gray01;
            &::placeholder{
                opacity: 0.5;  @include smallDepthTxt;
            }
        }
    }
    label{
        @include defaultTxt; color: $gray05;font-weight: 600;
        display: block; text-align: center; padding:3.4722vw 0vw;  border-radius:1.9444vw; border:0.2778vw solid $gray02; background: $white;
    }
}
.btn-radio-type2{
    position: relative; width:100%; border-radius:1.6667vw; overflow: hidden;
    input[type=radio]{
        @include hidden;
        clip: rect(0 0 0 0);
         &:disabled + label{
           // background-color: $gray01;
           color:#afafaf;
            &::placeholder{
                opacity: 0.5;  @include smallDepthTxt;
            }
        }
        &:disabled + label span {
            background:#ddd;
        }
         &:checked + label{
            color: $purple;
            span{
                border:0;
                &::after{
                    transform: scale(1);  opacity: 1;
                }
            }
        }
    }
    label{
        position: relative;  display: flex; align-items: center; cursor: pointer;

        @include defaultTxt;
        span{
            width:5.5556vw; height:5.5556vw; border-radius:50%; border:0.2778vw solid $gray02;  position: relative; transition: all 0.3s ease-out; margin-right:2.7778vw; flex-shrink: 0; transition: all 0.3s ease-out;
            &::after{
                content: ''; position: absolute; top: 0; left: 0; transform: scale(0.8); width: 100%; height: 100%; border-radius:50%; border: 1.610vw solid $purple;  box-sizing: border-box; opacity: 0; transition: all 0.3s ease-out;
            }
        }
    }
}
.btn-radio-group{
    display: flex; flex:1; gap:2.7778vw;
    // 위아래 패딩 30px 인 라디오버튼
    &.is-padding {
        .btn-radio{
            label {padding: 4.1667vw 0 !important;}
        }
    }
    &.group4{
        display: flex; flex-wrap: wrap;
        .btn-radio{
            width:calc(50% - 1.3889vw);
        }
    }
    &.group6{
        display: flex; flex-wrap: wrap;
        .btn-radio{
            width:calc(33.3% - 1.8778vw);
        }
    }
    &.group8{
        display: flex; flex-wrap: wrap; gap: 1.6667vw;
        .btn-radio{
            width:calc(25% - 1.6667vw);
            label{padding: 3.0556vw 0;}
        }
    }
    &.group-free {
        justify-content:flex-start;flex-wrap:wrap;flex:auto;
        .btn-radio {
            width:auto;
            label {padding:3.4722vw;}
        }
    }
    &.radio-list {
        flex-wrap: wrap; gap: 2.7778vw;
        .btn-radio{width: calc(50% - 1.3889vw);}
    }
}

//textarea
.textarea-wrap{
    margin-bottom:5.4167vw;
    .input-label {display: block;margin-bottom: 2.2222vw; @include mainTxt;}
    .box-textarea{
        width: 100%;height:41.6667vw;padding: 4.1667vw;box-sizing:border-box; @include defaultTxt;  border: 0.2778vw solid $gray02;border-radius: 1.9444vw;transition: border-color .3s ease; display: flex; position: relative;
        .input-default{
            height:100%; resize:none;  background: $white; width:calc(100% - 5.5556vw); border:none; border-radius: 0; padding:0;
        }
        .ico-delete{
            width:5.5556vw; height:5.5556vw; display: none; top:4.1667vw; right:4.1667vw; transform: translateY(0);
        }
    }
    .txt-error{display: none;}
    &.error{
        // .box-textarea{border-color: $red;}
        .txt-error{
            display:block;color: $red;margin-top: 2.2222vw;@include defaultTxt; color: $red; text-align: left; font-weight: 400;
        }
    }
    textarea::placeholder {
        font-size: 3.8889vw;
        @include smallDepthTxt;
        opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
    }
    textarea::-webkit-input-placeholder {color:$gray04;}
    textarea:-ms-input-placeholder {color:$gray04;}
    textarea:-mos-input-placeholder {color:$gray04;}
}

// 파일첨부 input
.box-attach{
    .default-txt{display: block;}
    input[type="file"]{@include hidden;}
    .input-file{@include smallTxt; font-weight: 600; margin-top: 2.7778vw;}
    .attached-list{
        margin-top: 2.7778vw;
        li{
            display: flex; justify-content: space-between; align-items: center;
            .file-name{
                display: block; padding-left: 6.9444vw;
                // 임시 아이콘
                @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', left center, 5.5556vw 5.5556vw);
            }
        }
    }
    & > .box-btn{margin-top: 5vw;}
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    //이용약관
    .agree-wrap {
        margin-top:3.5714vw;
        .agree-group {
            margin-top:2.8571vw; padding:0 2.1429vw;
            .btn-check {
                margin-bottom:2.1429vw;
                label {
                    span {
                        font-weight: 400;
                        &>em.txt-vt{color: $black !important;}
                        &>b {@include fontInherit;color:$gray05 !important;}
                    }
                }
                &.agree-nec {
                    label {
                        span{color:#191919 !important;}
                    }
                } /*필수 색상*/
                &.agree-sel {
                    label {
                        span{color:#666 !important;}
                    }
                } /*선택 색상*/
            }
        }
    }

    .box-input {
        width:100%;
        .input-wrap{position: relative;}
        input[type=text],input[type=number]{
            &:disabled{
                background-color: $gray01;
                &::placeholder{
                    opacity: 0.5;
                }
            }
        }
        .input {
            &-label {
                & ~ .ico-delete{top: 7.7857vw;}
            }
            &-default{
                &.is-delete{padding-right: 5.5714vw;}
                &.is-time{padding-right: 12.2857vw;}
                // &.is-unit{padding-right: 6.2143vw;}
                &.is-unit{padding-right: 10.7143vw;}
                &.is-masking{padding-right: 11.4286vw;}
                &:focus{border-color: $black;}
            }
            &-unit{
                position: absolute;right: 2.1429vw;top: 50%;transform: translateY(-50%);
                &.on{right: 6.4286vw;}
            }
        }
        .ico{
            &-delete {display: none; position: absolute; top: 50%;right: 2.1429vw;transform: translateY(-50%);}
            &-eye {
                display: block;position: absolute; top: 50%;right: 2.1429vw; width: 3.4286vw; height: 3.4286vw;@include backgroundImg('/assets/images/icon/ico-eye-off.svg', center, contain); transform: translateY(-50%);
                &.show{background-image: url('/assets/images/icon/ico-eye-on.svg');}
                &.on{right: 6.4286vw;}
            }
        }
        .txt{
            &-error {display: none;}
            &-description {display:none; font-weight: 400;}
            &-sending {display:none;font-weight: 400;}
            &-complete {display:none; color:#1baa46;}
            &-time {
                position: absolute;top: 50%;right: 2.1429vw;color:$purple;transform: translateY(-50%);
                &.on{right: 6.4286vw;}
            }
        }

        // error case
        &.error {
            .txt-error{
                display:block;color: $red;margin-top: 1.1429vw; color: $red; font-weight: 400; text-align: left;


            }
        }

        // success case
        &.success {
            .txt-description{display:block;margin-top: 1.1429vw;}
        }

        // 인증번호 발송 case
        &.sending {
            .txt-sending{
                display:block; margin-top: 1.4286vw;
            }
        }
        // 인증번호 발송 완료 case
        &.complete {
            .txt-complete{
                display:block; margin-top: 1.4286vw;
                & + .txt-sending{display: none;}
            }
        }

        // validation-list
        .validation-list{
            margin-top: 1.4286vw;
            &-item{
                position: relative; padding-left: 3.1429vw;font-size:2.0000vw;
                &::before{
                    content: '';display: inline-block;position: absolute; left: 0; top: 50%; width: 2.0714vw;height: 2.0714vw;@include backgroundImg('/assets/images/icon/ico-check.svg', center, contain); transform: translateY(-50%);
                }
                &+.validation-list-item{margin-top: 1.1429vw;}
                &.success {
                    color: #1baa46;
                    &::before{background-image: url('/assets/images/icon/ico-check-success.svg');}
                }
                &.error {
                    color: $red;
                    &::before{background-image: url('/assets/images/icon/ico-check-error.svg');}
                }
            }
        }
        .serach-input-warp{
            .input-default{
                padding-right:9.7143vw;
            }
            .ico-search{
                position: absolute; top: 50%; right:2.1429vw; width:2.2857vw; height:100%; background: url('/assets/images/icon/ico-search.svg') no-repeat center center; background-size: contain; transform: translateY(-50%); font-size: 0;
                &.on {
                    & + .ico-delete{
                        right: 6.4286vw;
                    }
                }
            }
        }
    }

    .input-group {
        display:flex;flex-wrap:wrap;gap:1.4286vw;
        &>label {width:100%;}
        .box-input {flex:1 1 40%;margin-top:0 !important;}
    }
    .select-input-wrap{
        display:flex;flex-wrap:wrap;gap:1.4286vw;
        &>label {width:100%;}
        .box-select{
            width: 17.1429vw;
        }
        .box-input {flex:1 1 40%;margin-top:0 !important;}
    }

    .button-input-wrap {
        display:flex;flex-wrap:wrap;gap:1.4286vw;
        &>.default-txt {width:100%;}
        .btn-select{
            width: 17.1429vw;
        }
        .box-input {flex:1 1 40%;margin-top:0 !important;}
    }
    .input-depth{
        display: flex; flex-direction: column; gap: 1.1429vw;
        .btn-check{
            justify-content: flex-end;
            label{
                justify-content: flex-end;
                span{font-weight: 400;}
            }
        }
    }
    .box-flex{
        display: flex;flex-wrap: nowrap;align-items: flex-start;gap: 0.7143vw;position: relative; padding-top: 3.9286vw; position: relative;
        .box-input{
            .input-label {position: absolute; top: 0;}
            .input-default{
                min-height: 7.1429vw;padding: 2.0000vw 2.2857vw 2.0000vw;
                // padding 작은 사이즈
                &.input-sm {padding: 2.0000vw 1.1429vw 2.0000vw;}
                &.is-delete{
                    padding: 2.0000vw 5.7857vw 2.0000vw 2.2857vw;
                    &.is-time{
                        padding: 2.0000vw 12.2857vw 2.0000vw 2.2857vw;
                    }
                }
            }
        }
        .box-select {
            position: static;
            .select-label {position: absolute; top: 0;}
            .select-default{
                padding: 2.0714vw 2.1429vw;
            }
        }
        .box-btn{
            flex-shrink: 0;
            button{width: 100%;min-height: 7.1429vw; min-width: 12.8571vw;}
            a{display: block; min-width: 13.0714vw; text-align: center;}
        }
        &.column{
            flex-direction: column; gap:2.1429vw;
            .box-input{flex: 1 1 100%;}
            .box-btn{flex: 1 1 100%;width: 100%;}
        }
        &.search-input-warp {
            padding-top:0;
            .box-input, .box-btn {margin-top:0 !important;}
            .box-btn {
                button {width:13.5714vw;}
            }
        }
        &.box-unit {
            .box-input {
                .input-default {
                    &.is-unit {padding-right:9.0000vw!important;}
                }
            }
        }
    }

    // 휴대폰 인풋
    .box-input-phone{
        display: flex; flex-wrap: wrap;
        .input-label{width: 100%;}
        .input-wrap{
            position: relative; max-width: 13.5714vw;width: calc((100% - 4.8571vw) / 3); justify-content: space-between;
            &:nth-of-type(n + 2) {
                margin-left: 2.4286vw;
                &::before{
                    content: '-';position: absolute; top: 50%; left: -1.5714vw; transform: translateY(-50%);
                }
            }
        }
        .box-btn{
            width: 100%; margin-top: 2.1429vw;
            p{
                margin-top: 1.4286vw;
            }
        }
    }

    .box-select{
        position:relative;width: 100%;
        .select{
            &-default{
                cursor: pointer;@include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', calc(100% - 2.1429vw) center, 2.8571vw 2.8571vw);
                @include ellipsis; padding: 2.2857vw 5.7143vw 2.2857vw  2.1429vw;
                &:disabled{
                    color: #afafaf;cursor: default;background-image: url('/assets/images/icon/ico-arrow-bottom-gray.svg');
                }
            }
        }
        &.select-more{
            display: none; margin-top: 1.1429vw;
            &.on{display: block;}
        }
        .text-select{
            width: 15.0000vw;
            cursor: pointer;
            padding: 0 2.1429vw 0 0;
            border:none;
            @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', no-repeat calc(15.0000vw - 2.1429vw) center, 1.4286vw 1.4286vw );
        }
    }

    .btn-select{
        .main-txt{margin-bottom: 1.1429vw;}
        button{
            cursor: pointer;@include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', calc(100% - 2.1429vw) center, 2.8571vw 2.8571vw);
            text-align: left; width: 100%; display: block;
        }
    }
    .date-picker{
        @include backgroundImg('/assets/images/icon/ico-calendar.svg', calc(100% - 2.8571vw) center, 2.8571vw 2.8571vw);
    }
    .month-picker{
        @include backgroundImg('/assets/images/icon/ico-calendar.svg', calc(100% - 2.8571vw) center, 2.8571vw 2.8571vw);
    }

    //체크박스
    .btn-check{
        position: relative; display: flex; justify-content: space-between; align-items: flex-start;
        input[type=checkbox] {
            @include hidden;
            &:checked + label{
                span{
                    &::before{
                        background-color:$purple; transition: all 0.3s ease-out;
                    }
                    &::after{
                        @include backgroundImg('/assets/images/icon/ico-check-on.svg', center,1.5714vw 1.1429vw);
                    }
                }
            }
            &:disabled + label{
                cursor: default;
                span{
                color:$gray05;
                &::after{
                        content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: inline-block; width: 2.8571vw; height: 2.8571vw;
                        @include backgroundImg('/assets/images/icon/ico-check-disable.svg', center,1.5714vw 1.1429vw);
                }
                }
            }
        }
        label{
            cursor: pointer; display: inline-flex; align-items: center; width: calc(100% - 3.5714vw);
            span{
                font-weight: 400;
                display: inline-block; padding-left:4.2857vw; position: relative; transition: all 0.3s ease-out;
                &::before{
                    content: ""; position:absolute; top:0; left:0; display: inline-block; width: 2.8571vw; height: 2.8571vw; background-color:$gray02; border-radius: 50%;
                }
                &::after{
                    content: ""; position:absolute; top: 0.0714vw; left:0;display: inline-block; width: 2.8571vw; height: 2.8571vw;
                    @include backgroundImg('/assets/images/icon/ico-check-on.svg', center, 1.5714vw 1.1429vw);
                }
                &>strong {@include fontInherit;}
                &>em.txt-vt{color: $black !important;}
                &>b {@include fontInherit;color:$gray05 !important;}
            }
        }
        &.white{
            width:100%; padding:2.1429vw; border:0.1429vw solid $black;border-radius: 1.0000vw;
        }
    }
    //btn-check-type2
    .btn-check-type2{
        input[type=checkbox]{
            @include hidden;
            &:checked + label{
                border:0.1429vw solid $black; transition: all 0.3s ease-out;
                span{
                    &::before{
                        background-color:$purple;
                    }
                    &::after{
                        @include backgroundImg('/assets/images/icon/ico-check-on.svg', center,1.5714vw 1.1429vw);
                    }
                }
            }
        }
        label{
            cursor: pointer; display: inline-flex; align-items: center; width:100%; padding:2.1429vw; border:0.1429vw solid $gray02; border-radius: 1.0000vw;
            span{
                font-weight: 600;
                display: inline-block; padding-left:4.2857vw; position: relative;
                &::before{
                    content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: inline-block; width: 2.8571vw; height: 2.8571vw; background-color: $gray02; border-radius: 50%;
                }
                &::after{
                    content: ""; position:absolute; top:50%; left:0; transform: translateY(-50%); display: inline-block; width: 2.8571vw; height: 2.8571vw;
                    @include backgroundImg('/assets/images/icon/ico-check-on.svg', center, 1.5714vw 1.1429vw);
                }
                &>strong {@include fontInherit;}
                &>em.txt-vt{color: $black !important;}
                &>b {@include fontInherit;color:$gray05 !important;}
            }
        }
    }
    //btn-check-type3
    .btn-check-type3{
        input[type=checkbox]{
            @include hidden;
            &:checked + label{
                border:0.1429vw solid $black; transition: all 0.3s ease-out;
            }
        }
        label{
            cursor: pointer; padding: 1.7857vw 0;  border-radius: 2.1429vw;  border: 0.1429vw solid $gray02;  display: inline-block;  min-width:11.0000vw; text-align: center;
        }
    }
    //btn-check-type4
    .btn-check-type4{
        input[type=checkbox]{
            @include hidden;
            &:checked + label{
                span{color: $purple;transition: all 0.3s ease-out;}
                &::before{background-image: url('/assets/images/icon/ico-checkbox-on.svg');}
            }
        }
        label{
            display: inline-flex; align-items: center; cursor: pointer;
            span{font-weight: 600; }
            &::before {
                content: ''; display: inline-block; width: 2.8571vw; height: 2.8571vw; margin-right:0.2857vw;  @include backgroundImg('/assets/images/icon/ico-checkbox.svg', center, contain); margin-right: 0.7143vw;
            }
            &:nth-of-type(n+2){margin-left: 2.1429vw;}
        }
    }


    //라디오 버튼 타이틀
    .radio-group-wrap{
        .group-tit{
            margin-bottom: 1.1429vw;
        }
    }
    //라디오 버튼
    .btn-radio{
        position: relative; width:100%; border-radius:0.8571vw; overflow: hidden;
        input[type=radio] {
            @include hidden;
            &:checked + label{
                border:0.1429vw solid $purple; color:$purple; transition: all 0.3s ease-out; background: $white;
                & > span{color: $purple;}
            }
            &:disabled + label{
                background-color: $gray01;
                &::placeholder{
                    opacity: 0.5;
                }
            }
        }
        label{
            color: $gray05;font-weight: 600;
            display: block; text-align: center; padding:1.7857vw 0.0000vw;  border-radius:1.0000vw; border:0.1429vw solid $gray02; background: $white;
        }
    }
    .btn-radio-type2{
        position: relative; width:100%; border-radius:0.8571vw; overflow: hidden;
        input[type=radio]{
            @include hidden;
            clip: rect(0 0 0 0);
            &:disabled + label{
                background-color: $gray01;
                &::placeholder{
                    opacity: 0.5;
                }
            }
            &:checked + label{
                color: $purple;
                span{
                    border:0;
                    &::after{
                        transform: scale(1);  opacity: 1;
                    }
                }
            }
        }
        label{
            position: relative;  display: flex; align-items: center; cursor: pointer;

            span{
                width:2.8571vw; height:2.8571vw; border-radius:50%; border:0.1429vw solid $gray02;  position: relative; transition: all 0.3s ease-out; margin-right:1.4286vw; flex-shrink: 0; transition: all 0.3s ease-out;
                &::after{
                    content: ''; position: absolute; top: 0; left: 0; transform: scale(0.8); width: 100%; height: 100%; border-radius:50%; border: 0.8571vw solid $purple;  box-sizing: border-box; opacity: 0; transition: all 0.3s ease-out;
                }
            }
        }
    }
    .btn-radio-group{
        display: flex; flex:1; gap:1.4286vw;
        // 위아래 패딩 2.1429vw 인 라디오버튼
        &.is-padding {
            .btn-radio{
                label {padding: 2.1429vw 0 !important;}
            }
        }
        &.group4{
            display: flex; flex-wrap: wrap;
            .btn-radio{
                width:calc(50% - 0.7143vw);
            }
        }
        &.group6{
            display: flex; flex-wrap: wrap;
            .btn-radio{
                width:calc(33.3% - 1.0000vw);
            }
        }
        &.group8{
            display: flex; flex-wrap: wrap; gap: 0.8571vw;
            .btn-radio{
                width:calc(25% - 0.8571vw);
                label{padding: 1.5714vw 0;}
            }
        }
        &.group-free {
            justify-content:flex-start;flex-wrap:wrap;flex:auto;
            .btn-radio {
                width:auto;
                label {padding:1.7857vw;}
            }
        }
        &.radio-list {
            flex-wrap: wrap; gap: 1.4286vw;
            .btn-radio{width: calc(50% - 0.7143vw);}
        }
    }

    //textarea
    .textarea-wrap{
        margin-bottom:2.7857vw;
        .input-label {display: block;margin-bottom: 1.1429vw; }
        .box-textarea{
            width: 100%;height:21.4286vw;padding: 2.1429vw;box-sizing:border-box; border: 0.1429vw solid $gray02;border-radius: 1.0000vw;transition: border-color .3s ease; display: flex; position: relative;
            .input-default{
                height:100%; resize:none;  background: $white; width:calc(100% - 2.8571vw); border:none; border-radius: 0; padding:0;
            }
            .ico-delete{
                width:2.8571vw; height:2.8571vw; display: none; top:2.1429vw; right:2.1429vw; transform: translateY(0);
            }
        }
        .txt-error{display: none;}
        &.error{
            .txt-error{
                display:block;color: $red;margin-top: 1.1429vw; color: $red; text-align: left; font-weight: 400;
            }
        }
        textarea::placeholder {
            font-size: 2.0000vw;
            opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
        }
        textarea::-webkit-input-placeholder {color:$gray04;}
        textarea:-ms-input-placeholder {color:$gray04;}
        textarea:-mos-input-placeholder {color:$gray04;}
    }

    // 파일첨부 input
    .box-attach{
        .default-txt{display: block;}
        input[type="file"]{@include hidden;}
        .input-file{ font-weight: 600; margin-top: 1.4286vw;}
        .attached-list{
            margin-top: 1.4286vw;
            li{
                display: flex; justify-content: space-between; align-items: center;
                .file-name{
                    display: block; padding-left: 3.5714vw;
                    // 임시 아이콘
                    @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', left center, 2.8571vw 2.8571vw);
                }
            }
        }
        & > .box-btn{margin-top: 2.5714vw;}
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .box-input{
        .input{
            &-default{
                &.is-delete{padding-right: 39px;}
            }
        }
        .input-label{
            & ~ .ico-delete{top: 55px;}
        }
        .ico-eye{
            right: 15px; top: 26px; width: 24px; height: 24px;
            &.on{right: 42px;}
        }
        .ico-delete{
            right: 15px; top: 26px; width: 20px; height: 20px;
        }
        .validation-list{
            margin-top: 10px;
        }
        .validation-list-item{
            font-size: 14px; padding-left: 22px;
            &::before{
                width: 14px; height: 14px; 
            }
        }
        &.error{
            .txt-error{color: #e34140; margin-top: 8px; font-weight: 400;}
        }
        &.success{
            .txt-description{margin-top: 8px;}
        }
        .txt-description{font-weight: 400; margin-top: 5px;}
        .serach-input-warp{
            .ico-search{width: 16px; right: 15px;}
        }
    }
    .btn-check{
        label{
            span{
                padding-left: 30px; font-weight: 400;
                &::after{
                    width: 20px; height: 20px; background-size: 11px 8px;
                }
                &::before{
                    width: 20px; height: 20px;
                }
            }
        }
        input[type=checkbox]{
            &:checked + label{
                span{
                    &::after{
                        background-size: 11px 8px;
                    }
                }
            }
        }
    }
    .btn-check-type2{
        label{
            border-width: 1px; padding: 15px; border-radius: 8px;
            span{
                padding-left: 30px;
                &::after{
                    width: 20px; height: 20px; background-size: 11px 8px;
                }
                &::before{
                    width: 20px; height: 20px;
                }
            }
        }
        input[type=checkbox]{
            &:checked + label{
                border-width: 1px;
                span{
                    &::after{
                        background-size: 11px 8px;
                    }
                }
            }
        }
    }
    .btn-check-type4{
        label{
            span{font-weight: 600;}
            &::before{width: 20px; height: 20px; margin-right: 5px;}
        }
    }
    .btn-radio{
        border-radius: 8px;
        label{border-width: 1px; padding: 12px 0; color: #666; border-radius: 8px;}
        input[type=radio]{
            &:checked+label{
                border-width: 1px;
            }
        }
    }
    .btn-radio-type2{
        label{
            span{
                width: 20px; height: 20px; margin-right: 10px;
                &::after{
                    border: 5px solid #662d91;
                }
            }
        }
    }
    .btn-radio-group{
        gap: 1.4286vw;
        &.is-padding{
            .btn-radio{
                label{padding: 15px 0 !important;}
            }
        }
        &.radio-list{gap: 10px;}
    }
    .box-select{
        .select-default{background-position: calc(100% - 20px) center; background-size: 20px 20px;}
    }
    .box-flex{padding-top: 28px; gap: 5px;
        .box-input{
            .input-default{
                min-height: 51px; padding: 14px 16px;
                &.is-delete{padding: 14px 41px 14px 16px;
                    &.is-time{padding: 14px 88px 14px 16px;}
                }
                &.input-sm{padding: 14px 10px;}
            }
            .txt-time{right: 15px; color: #662d91;}
        }
        .box-btn{
            button{min-width: 92px; min-height: 51px;}
            a{min-width: 95px;}
        }
        &.column{gap: 15px;}
        &.search-input-warp{
            .box-btn{
                button{width: 97px;}
            }
        }
        .box-select{
            .select-default{padding: 14px 15px;}
        }
    }
    .select-input-wrap{gap: 10px;}
    .textarea-wrap{
        .box-textarea{height: 160px; padding: 15px; border-radius: 8px; border-width: 1px;}
    }
    .agree-wrap{margin-top: 45px;
        .agree-group{margin-top: 20px; padding: 0 15px;
            .btn-check{margin-bottom: 15px;}
        }
    }
    .input-depth{gap: 10px;}
    .radio-group-wrap{
        .group-tit{margin-bottom: 8px;}
    }
    .box-attach{
        .input-file{margin-top: 10px;}
        .attached-list{
            margin-top: 10px;
            li{
                .file-name{background-size: 20px 20px; padding-left: 25px;}
            }
        }
    }
    .textarea-wrap{
        margin-bottom: 20px;
        .input-label{margin-bottom: 8px;}
    }
    .btn-radio-group.group8{
        gap: 5px;
        .btn-radio label{padding: 10px 0;}
    }
    .input-group{gap: 8px;}
    .box-input-phone{
        .box-btn{
            margin-top: 15px;
            p{margin-top: 10px;}
        }
    }
}