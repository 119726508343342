@charset "UTF-8";

// 초기 라인탭
.box-tab{
    position: relative; margin-top: 3.3333vw; padding-left: 5.5556vw;
    .tab-btn-wrap {
        display: flex;justify-content: flex-start; gap: 2.7778vw;overflow-x: auto;overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        li{
            flex-shrink: 0;
            a{
                display: block; padding: 2.5vw 5.1389vw; border-radius: 5vw; background-color: $gray01; text-align: center;
                @include mainTxt; color: $gray05;
                &.on{
                    background-color: $purple; color: $white;
                }
            }
        }
    }
    &.tab-ctrl {
        .tab-contents-wrap {
            & > * {display: none;}
            [aria-hidden="false"] {display: block;}
        }
    }
    &.tab-accordian-ctrl{
        .tab-contents-wrap {
            & > * {display: none;}
            & > div:first-of-type{
                display: block;
            }
        }
    }
}
.sub-conts, .full-popup-conts{
    .box-tab.tab-ctrl,.box-tab.tab-ctrl{
        margin: 0;  padding-left: 0;
        .tab-btn-wrap{
            margin-bottom: 8.3333vw;
        }
    }
    .box-tab,.box-tab{
        margin: 0 0  8.3333vw 0; padding-left: 0;
    }
}
//꽉차게 들어갈때
.full-tab{
    padding-right:5.5556vw;
    .tab-btn-wrap{
        li{
            flex:1;
        }
    }
}
.full-popup-conts{
    .full-tab{
        padding-right:0;
        .tab-btn-wrap{
            li{
                flex:1;
            }
        }
    }
}

// .box-tab {
//     position:relative;height:16.6667vw;
//     .tab-btn-wrap {
//         position: absolute; left: 0; top: 0; z-index: 10; width: 100%; height:16.6667vw;padding:4.1667vw 9.0278vw 0 5.5556vw;background:$bg; overflow-x: auto; overflow-y: hidden; white-space: nowrap;
//         &>li {
//             display: inline-flex; margin: 0 2.7778vw 4.1667vw 0;
//             a {
//                 display:block;min-width:13.8889vw;padding:0.8333vw 2.7778vw;border:0.1389vw solid #ddd;border-radius:1.9444vw;color:$gray05;font-size:3.8889vw;line-height:6.6667vw;letter-spacing:-0.1167vw;transition:0.3s;
//                 &:hover,
//                 &.on {
//                     border:0.1389vw solid #7c40ce;color:#7c40ce;
//                 }
//             }
//         }
//     }
//     .btn-menu {
//         display:block;position:absolute;right:0;top:2.0833vw;z-index: 11; overflow:hidden;width:12.5000vw;height:12.5000vw;background:url('/assets/images/icon/ico-3dot-gray.svg') no-repeat center $bg;background-size:auto 4.7222vw;font-size: 0;transition:0.3s;
//     }
//     &.on {
//         .tab-btn-wrap {
//             height:auto; overflow: visible; white-space: inherit;
//         }
//         .btn-menu {background:#7c40ce url('/assets/images/icon/ico-3dot-white.svg') no-repeat center;background-size:auto 4.7222vw;}
//     }
//     &.tab-ctrl {

//         .tab-contents-wrap {
//             & > * {display: none;}
//             [aria-hidden="false"] {display: block;}
//         }
//     }
// }

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
    // 초기 라인탭
    .box-tab{
        position: relative; margin-top: 1.7143vw; padding-left: 2.8571vw;
        .tab-btn-wrap {
            display: flex;justify-content: flex-start; gap: 1.4286vw;overflow-x: auto;overflow-y: hidden;
            &::-webkit-scrollbar {
                display: none;
            }
            li{
                flex-shrink: 0;
                a{
                    display: block; padding: 1.2857vw 2.6429vw; border-radius: 2.5714vw; background-color: $gray01; text-align: center;
                    color: $gray05;
                    &.on{
                        background-color: $purple; color: $white;
                    }
                }
            }
        }
        &.tab-ctrl {
            .tab-contents-wrap {
                & > * {display: none;}
                [aria-hidden="false"] {display: block;}
            }
        }
        &.tab-accordian-ctrl{
            .tab-contents-wrap {
                & > * {display: none;}
                & > div:first-of-type{
                    display: block;
                }
            }
        }
    }
    .sub-conts, .full-popup-conts{
        .box-tab.tab-ctrl,.box-tab.tab-ctrl{
            margin: 0;  padding-left: 0;
            .tab-btn-wrap{
                margin-bottom: 4.2857vw;
            }
        }
        .box-tab,.box-tab{
            margin: 0 0  4.2857vw 0; padding-left: 0;
        }
    }
    //꽉차게 들어갈때
    .full-tab{
        padding-right:2.8571vw;
        .tab-btn-wrap{
            li{
                flex:1;
            }
        }
    }
    .full-popup-conts{
        .full-tab{
            padding-right:0;
            .tab-btn-wrap{
                li{
                    flex:1;
                }
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .full-tab{
        padding-right: 20px;
    }
    .box-tab{
        margin-top: 15px; padding-left: 20px;
        .tab-btn-wrap{
            gap: 10px;
            li{
                a{padding: 9px 19px; border-radius: 20px;}
            }
        }
    }
    .full-popup-conts{
        .box-tab{
            &.tab-ctrl{
                .tab-btn-wrap{margin-bottom: 30px;}
            }
        }
    }
    .sub-conts{
        .box-tab{
            &.tab-ctrl{
                .tab-btn-wrap{margin-bottom: 30px;}
            }
        }
    }
}