@charset "UTF-8";

.event{
    // 기본 템플릿 용
    .evt-section{
        display: flex; justify-content: center;
        .evt-conts{
            position: relative; width: 100vw;
            .conts760{
                width: 90.0000vw; margin: 0 auto;
            }
        }
    }

    /* [팝업] 사은품 안내 */
    .evt-freebies {
        .inner{
            padding: 27.7778vw 5.5556vw 0vw 5.5556vw;
        }
        .page-tit {
            margin-bottom:5.5556vw;
            h3 {
                font-size:7.2222vw;line-height:8.6111vw;
                &+p {margin-top:2.7778vw;}
            }
        }
        h4 {
            &+p {margin-top:0.6944vw;}
        }
        .freebies-list {
            display:flex;flex-wrap:wrap;gap:2.7778vw;margin:5.5556vw 0 11.1111vw;
            &>li {
                flex:1 1 40%;max-width:43.7500vw;
                input[type=checkbox] {position:absolute;opacity:0;}
                input[type=checkbox]:checked {
                    &+label {border:0.1389vw solid $purple;}
                }
                label {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:44.0278vw;padding:2.7778vw;border:0.1389vw solid #ddd;border-radius:2.7778vw;text-align:center;
                    .product-img{
                        width: 31.9444vw; height: 31.9444vw; margin:0 auto; overflow: hidden;
                        img{
                            width: 100%; display: block; height:100%;
                        }
                    }
                    .txt {@include smallTxt;text-align:center; margin-top:0.8333vw}
                }
                .freebies {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:44.0278vw;padding:2.7778vw;border:0.1389vw solid #ddd;border-radius:2.7778vw;text-align:center;
                    .product-img{
                        width: 31.9444vw; height: 31.9444vw; margin:0 auto; overflow: hidden;
                        img{
                            width: 100%; display: block; height:100%;
                        }
                    }
                    .txt {@include smallTxt;text-align:center; margin-top:0.8333vw}
                }
            }
        }
        .box-notice {
            width:100vw;margin-left:-5.5556vw;padding:5.5556vw;background:$gray01;
            .bang-notice {margin:0 0 2.7778vw;}
            .notification{
                &>li {font-size:3.8889vw;}
            }
        }
    }
    /* [팝업] 사전예약신청 */
    .evt-reservation {
        .mt80 {
            margin-top: 11.1111vw;
        }
        .appli-ct {
            margin-bottom:4.1667vw;
        }
        .phone-info {
            strong{ @include subContsTitle; margin-bottom: 1.3889vw; display: block;}
            &.underline{
                padding: 0; padding-bottom:5.5556vw; margin-bottom: 5.5556vw; border-bottom:0.2778vw solid $gray02;
            }
        }
        .section{margin-top: 11.1111vw;}
        .btn-check-wrap{
            margin-top: 5.5556vw;
            li{
                margin-bottom: 4.1667vw;
            }
        }
        .info-txt{
            @include smallTxt; margin-top: 11.1111vw;
        }
        .notification{margin: 2.7778vw 0 5.5556vw;}
        &.school{
            .section{margin-top: 0;}
        }
    }

    // [바텀시트]공유하기 링크
    .evt-share{
        .list-share{
            display: flex; gap: 2.2222vw;
        }
    }
    //진행중인 이벤트 목록
    .evt-going-list{
        .main-title{ @include defaultTxt;}
        .going-list-wrap{
            margin-top:8.3333vw;
            .cardList-wrap{display: block;}
        }
        .white{margin-top:11.1111vw;}
        .is-filter-warp{
            .is-filter-list{
                width: 100vw;
                padding: 0 5.5556vw 0 5.5556vw;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        .cardList-img{
            img{
                height: 100%; object-fit: cover; width:100%;
            }
        }
        .no-evt-list{
            border-top:0.2778vw solid $gray02; border-bottom:0.2778vw solid $gray02; text-align: center;
            @include mainEmphasize; padding: 33.2778vw 0 9.8889vw;
            @include backgroundImg('/assets/images/icon/ico-no-evt.svg',center top, 36.1111vw 36.1111vw);
        }
    }

    //진행중인 이벤트 상세 페이지 - 기본 템플릿
    .template{
        &.sub-conts{
            padding: 0 5.5556vw 0 5.5556vw;
        }
        .template-view{
            &.unit-wide{
                margin-bottom: 11.1111vw;
            }
        }
        .info-tit{
            @include smallTxt; margin-bottom: 5.5556vw;
        }
        .title-wrap{
            padding:5.5556vw 0;
            .title{@include mainTxt;}
            .title-share-wrap{
                display: flex; justify-content: space-between;
                .ico-share{flex-shrink: 0;}
            }
            .desc-wrap{
                display: flex; justify-content: space-between; align-items: center;
                .title{
                    @include mainTxt; width:calc(100% - 8.8889vw);
                }
            }
            .period{
                @include smallDepthTxt; margin-top: 2.7778vw;
            }
        }
        .event-banner{
            margin-bottom: 11.1111vw;
            img{width: 100%;}
        }
        .pre-reservation{
            margin: 0 5.5556vw;
           .btns{
             & + .btns{
                margin-top: 4.1667vw;
             }
            }
        }
        .evt-count{
            display: flex; justify-content: center; align-items: center; padding:5.5556vw 0; background: rgba(0, 0, 0, 0.9); border-top: 0.2778vw solid $gray02; text-align: center; letter-spacing: 0;
            p{
                display: flex;align-items: center;
                @include mainTxt; color: $white; font-weight: 400;
            }
            .date{
                @include mainTxt;
                background: rgba(0, 0, 0, 0.9); color:$white; height:6.8056vw; padding:0 2.2222vw; display:inline-block; border-radius: 1.9444vw; line-height: 6.8056vw;
                margin: 0 0.5556vw 0 1.3889vw;
                span{@include fontInherit; color:$white;}
            }
        }
        .detail-sec{
            .detail-sec-tit{
                @include subContsTitle; margin-bottom: 5.5556vw;
            }
            .detail-sec-desc{
                 @include smallTxt;
            }
             .detail-sec-tit + .detail-sec-desc{
                margin-top:-4.1667vw; margin-bottom: 5.5556vw;
             }
            .info-tit {padding-top: 5.5556vw; text-align: center;}
            .notice-tit {margin-top:11.1111vw}
        }
        .accordian-list{
            width:100%; margin-top: 5.5556vw; border-top:0.2778vw solid $gray02; border-bottom:0.2778vw solid $gray02;
            & > li{
                button{
                    height: 16.6667vw; padding-bottom:0; margin-bottom: 0; padding-right: 6.9444vw;
                }
                .acc-conts{
                    background:$white; padding:0;
                }
            }
        }
        .template-btn{
            margin-bottom: 11.1111vw;
        }
        .float-bnr{
            position:sticky; bottom:0;  padding:2.3611vw 0;  border: 0.5556vw solid $black; background: $white; border-radius: 4.8611vw; @include defaultTxt;  text-align:center;
            span{
                @include defaultTxt; color: $purple;
            }
        }
        .box-img{
            padding-bottom: 11.1111vw;
            .img-wrap {
                display: flex; flex-direction: column; gap: 5.5556vw;
                li{
                    position: relative; width: 100%; height: auto;
                    img{
                        display: block; width:100%;
                    }
                }
            }
        }
        .notice-tit{
            @include mainTxt; margin-bottom: 1.3889vw;
        }
        .slick-center-mode{
            .slick-dots{
                bottom: -5.1667vw;
            }
        }
        .item-bottom{
             margin-top:5.5556vw;
            .info-txt{
                padding:4.1667vw 0; border-top:0.2778vw solid $gray02;
            }
        }
        .info-txt{
            @include normalTxt;
        }
        .video-slider{
            .slick-item{
                border-radius: 2.7778vw; background: $white; overflow:hidden;
                a{
                    display: block;
                    img{
                        object-fit: cover;
                    }
                }
            }
        }
        .template-video {
            width:100%;height:62.5vw;margin:8.3333vw 0;padding:0 5.5556vw;
            iframe {width:100% !important;height:100% !important}
        }
       
    }

    //진행중인 이벤트 상세 페이지 - 유심 템플릿
    .evt-usim-template{
        .filter-box{
            border-bottom: 0.4vw solid #662d91;
            .is-filter-warp{
                margin-bottom: 0;
                .is-filter-list{
                    padding: 0 5.5556vw 0 5.5556vw;width: 100vw;
                &::-webkit-scrollbar{
                        display: none;
                    }
                }
            }
        }
        .cate-radio-group{
            display: flex;
            li{
                flex-shrink: 0;
                padding-right: 1.25vw;
                &.cate-radio{
                    border-radius: 0;
                    input[type=radio]{
                        @include hidden;
                    }
                    label{
                        font-size: 4.5vw; font-weight: 600; letter-spacing: -0.0625vw; color: #666; background-color: #f6f6f6; padding: 1.875vw 5vw; border-radius: 1.9444vw 1.9444vw 0 0; display: block;
                    }
                    input[type=radio]:checked{
                        & + label{background: $purple; color: #fff;}
                    }
                    &:last-child{padding-right: 0;}
                }
            }
        }
        .payment-imgCard-btn{
            margin-top:5.5556vw;
            & + .payment-imgCard-btn{
                margin-top:4.1667vw;
            }
            .item-top{
                padding-bottom: 0; position: relative;
                .payment-desc{
                    dt{
                        b{
                            @include subContsTitle; font-weight: 700;
                        }
                    }

                }
                .pay-amount{
                    padding-top: 5.5556vw;  @include contsTitle; color: $purple;
                }
                .banner-randombox-wrap{
                    left: 0; bottom: -4.7222vw; z-index: 0;
                }
            }
            .item-bottom {
               .btns  + .btns  {margin-top:2.7778vw;}
            }
        }
        & > .box-btn{margin-bottom: 11.1111vw;}
    }
    //진행중인 이벤트 상세 페이지 - 휴대폰 템플릿
    .evt-phone-template{
        .filter-box{
            border-bottom: 0.4vw solid #662d91;
            .is-filter-warp{
                margin-bottom: 0;
                .is-filter-list{
                    padding: 0 5.5556vw 0 5.5556vw;width: 100vw;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
                // 휴대폰 템플릿 카테고리 버튼 디자인 수정
                .cate-radio-group{
                    display: flex;
                    li{
                        flex-shrink: 0; 
                        padding-right: 1.25vw;
                        &.cate-radio{
                            border-radius: 0;
                            input[type=radio]{
                                @include hidden;
                            }
                            label{
                                font-size: 4.5vw; font-weight: 600; letter-spacing: -0.0625vw; color: #666; background-color: #f6f6f6; padding: 1.875vw 5vw; border-radius: 1.9444vw 1.9444vw 0 0; display: block;
                            }
                            input[type=radio]:checked{
                                & + label{background: $purple; color: #fff;}
                            }
                            &:last-child{padding-right: 0;}
                        }
                    }
                }
            }
        }
        .payment-imgCard-btn{
            .cardList{
                .item-top{
                    padding-top:0;
                }
                .payment-desc{
                    dt{
                        display: flex;justify-content: center; align-items: center; gap:2.7778vw; width:100%;
                        .img-wrap{
                            .img{
                                width:25.5556vw; height:25.5556vw; display: none;
                                img{
                                    width:100%; height: 100%; display: block;
                                }
                                &.on{
                                    display: block;
                                }
                            }

                        }
                    }
                }
            }

        }
        .phone-info-head{
            .chip-wrap{margin-bottom: 4.1667vw;}
        }
        .phone-info-slide{
            padding-bottom: 5.5556vw;
            .slick-dots{
                bottom: 0;
            }
            .slick-item{
                padding: 8.3333vw 5.5556vw; border: 0.2778vw solid #e6e6e6;border-radius: 2.7778vw;
            }
            .phone-color-box{
                width: 100%;
            }
            .phone-charge-box{
                margin-top: 11.1111vw;
                .total-box{
                    display: flex; justify-content: space-between; margin-bottom: 4.1667vw;
                    *{@include subContsTitle;}
                }
                .charge-wrap{
                    padding-bottom: 5.5556vw; background-color: $bg; border-radius: 2.7778vw;
                    & > div {
                        display: flex; flex-wrap: wrap; justify-content: space-between; padding: 5.5556vw;
                        & + div{
                           padding-top: 0; padding-bottom: 0;
                        }
                        .plan{
                            &-title{
                                @include smallTxt; color: $black; width: 50%;
                            }
                            &-name{
                                @include smallDepthTxt; margin-top: 1.3889vw;
                            }
                            &-price{
                                @include defaultTxt;
                                display: block; text-align: right;width: 50%;
                            }
                        }
                        &.accordian{
                            position: relative; padding-bottom: 5.5556vw; background: none;
                            .title{
                                display: flex; flex-wrap: wrap; justify-content: space-between; padding: 5.5556vw 0 4.1667vw 0; border-top: 0.2778vw solid $gray02;
                            }
                            .acc-trigger{
                                position: absolute; left: 0; bottom: 0; width: 100%;
                                &:after {
                                    top: auto; bottom: 0; right: auto; left: 50%; transform: translate(-50%, 0);
                                }
                                &.on{
                                    &:after{
                                        transform: translate(-50%, 0) rotate(180deg);
                                    }
                                }
                            }
                            .acc-conts{
                                padding: 0 0 4.1667vw 0;
                                .cardList{
                                    border: none; padding: 0; border-radius: 0;
                                    dl{
                                        dt{
                                            width: 60%; padding-bottom: 2.7778vw; @include smallTxt;
                                        }
                                        dd{width: 40%; padding-bottom: 2.7778vw;  @include smallTxt;}
                                        .bold{
                                          padding-bottom: 0; @include defaultTxt;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .box-btn{
                margin-top: 5.5556vw;
                .btns{
                    margin-top: 5.5556vw;
                }
            }
        }
        .phone-img {
            li{
                display: none;
                position: relative; width: 60vw; height: 60vw; margin: 0 auto;
                &.on{display: block;}
               img {height: 100%; object-fit: contain;}
            }
        }

        .product-imgCard{
            display: flex; flex-direction: column; gap: 4.1667vw; margin-top: 8.3333vw; margin-bottom: 8.3333vw;
            .card-list-header{
                justify-content: flex-start;
                .chip-wrap{position: static;}
            }
            .item-price{
                position: static;margin-top: 4.1667vw;
            }
            .phone-img{
                li{
                    width: 27.7778vw; height: 27.7778vw;
                }
            }
            .color-type{
                margin-top:5.5556vw; justify-content: flex-start;
            }
            .item-bottom{
                width: 100%; margin-top: 5.5556vw;
                .notification {
                    li{
                        *{@include fontInherit;}
                    }
                }
                .info-txt{
                    @include defaultTxt; padding:4.1667vw 0;  border-top: 0.2778vw solid $gray02; margin-top: 5.5556vw;
                    & + .box-btn{
                        margin-top: 0;
                    }
                }
                .box-btn{margin-top: 8.3333vw;}
            }
        }
    }
    //진행중인 이벤트 상세 페이지 - 템플릿 없는 페이지
    &.pb0{
        padding-bottom: 0;
    }
    //대상자 발표 상세페이지
    .evt-traget-announce{
       .btn-text-arrow{
        margin-bottom:5.5556vw;
       }
    }

    //포인트
    .ualmo-point{
        img{display: block;}
        .intro{
            position: relative;
            .btn-position{
                display: block;position: absolute;top: 72.0556vw;left: 50%;transform: translateX(-50%);width: 46.9444vw; height: 15.5556vw;font-size: 0;
            }
            .img{
                img{width: 100%;}
            }
        }
        .box-tab{
            margin: 13.8889vw 0 0 0;
            .sec-common {
                padding: 16.6667vw 0 5.5556vw;
                &:first-child{
                    padding-top: 11.1111vw;
                }
                .txt {
                    h3{@include mainContsTitle;}
                    p{
                        @include subContsTitle; font-weight: 400; margin-top: 4.1667vw;
                    }
                    .s-txt{margin-top:1.3889vw; font-size: 3.8889vw; line-height: 1.36; text-align: left;  color: #666; }
                }
                .img{
                    display: flex; justify-content: flex-end; margin-top: 5.5556vw;
                    img{width: 77.7778vw;}
                }
                .box-btn{
                    margin-top: 5.5556vw;
                    .btns{
                        background-color: $black; @include mainEmphasize; color: $white;
                        & + .btns {margin-top:1.3889vw;}
                    }
                }
                .accordian{margin-top: 8.3333vw;}
            }
        }
    }
    // 친구 추천
    .event-friend{
        .cont-wrap{
            margin-bottom: 5.5556vw;
            &:last-of-type{
                margin-bottom: 0;
            }
            h2{
                display:inline-block;@include mainTxt;vertical-align:top;
            }
            & + .btn-check{margin:5.5556vw 0 11.1111vw;}
            .box-flex {
                & + .box-flex {padding-top:2.7778vw;}
            }
            .txt-description {
                & + .box-flex {padding-top:2.7778vw;}
            }
        }
        &.friend-ranking{
            .page-tit{
                margin: 8.3333vw 0 5.5556vw;
                h1{margin-bottom: 1.3889vw;}
            }
            .box-btn{margin-top: 11.1111vw;}
        }
        &.friend-usim{
            .page-tit{
                margin-bottom: 5.5556vw;
                h1{
                    p,span{@include fontInherit;}
                }
                & + .box-btn{margin-bottom: 9.7222vw;}
            }
            .self-write-wrap{display: none; margin-top: 2.2222vw;}
            .textarea-wrap{
                & + .btn-box.btn-check{margin: 5.5556vw 0 11.1111vw;}
            }
            .main-txt{
                & + .notification{margin-top: 4.1667vw;}
            }
            .box-input{
                .input-wrap{
                    & + .txt-description{margin-top: 1.875vw;}
                }
                .txt-description{margin-top: 1.25vw; color: #666;}
            }
        }
        &.friend-my-announce{
            .page-tit{
                margin-bottom: 6.9444vw;
                span{ @include fontInherit; }
                .btn-text-arrow{
                    margin-top:2.7778vw;
                }
            }
            .announce-img{
                width:100%; max-height:44.4444vw;
                img{
                    display: block;
                }
            }

            .announce-tble {
                margin-top:11.1111vw;
                .box-table {
                    & + .box-table {margin-top:8.3334vw;}
                    table {
                        td {padding:5.5556vw 0.6944vw;}
                        .btn {display:inline-block;padding: 0.5556vw 2.2222vw 0.4167vw; border-radius: 1.3889vw;border: solid 1px #191919; background-color: #fff; font-size: 4.1667vw;   font-weight: 600; line-height: 1.33;  letter-spacing: -0.0833vw; text-align: center; color: #191919;}
                    }
                }
            }

            .box-tab{
                margin-top: 11.1111vw;
                .tab-btn-wrap{
                    li{
                        width:48%;
                        a{
                            width:100%; margin:0;
                        }
                    }
                }
                .tab-contents-wrap{
                    padding:0 0 2.7778vw 0;
                    .page-tit{
                        h3{
                            @include mainTxt;
                        }
                    }
                }
            }
            .table-type-04{
                width:100%; margin-bottom: 2.7778vw;
                td{
                    &:first-of-type{
                        color:$gray05;
                    }
                }
            }
        }
        &.friend-usim-history{
            .box-table{margin-top: 8.3333vw;}
        }
        &.friend-detail{
            padding: 11.1111vw 0 0;
            .sec-common{
                position: relative;width: 100%;margin: 0 auto;
                .img{
                    img{display: block;width: 100%;}
                }
                .btn-position{
                    position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);width: 69.4444vw;height: 13.8889vw;display: block;background: 0 0;border-radius: 6.9444vw;font-size: 0;
                }
                &.intro{
                    .btn-position{bottom: 67.0556vw;}
                }
                &.sec03{
                    .btn-position{bottom: 4vw;}
                }
                &.sec04{
                    .btn-position{bottom: 21vw;}
                }
                &.sec05{
                    .btn-position{bottom:17vw;}
                }
                &.sec06{
                    .btn-position{bottom: 35vw;}
                }
            }
            .btn-group.btn-fix {
                button{
                    img{height: 5.5556vw; vertical-align: middle;}
                    &.white{
                        background-color: #dee1e6;
                    }
                }
            }
            .fix-unit-group{
                padding: 0 5.5556vw;
                .unit-tit-s{
                    display: block;margin: 11.1111vw 0 4.1667vw;
                    @include mainTxt;
                }
            }
        }
        .notification li {
            .link {color:#3392ff; word-break: break-all; }
        }
    }

    //유알모소개
    .event-friend-intro{
        padding: 11.1111vw 0 0;
        .main-banner-wrap{
            position: relative;
            .img-wrap{
                width: 100%;
                img{width: 100%; vertical-align: top;}
            }
            .txt-wrap{
                position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 12.2222vw 0 0 0;
                h2{
                    @include mainContsTitle; text-align: center; margin-bottom: 6.9444vw;
                    strong{
                            font-size: 7.2222vw; font-weight: 700; line-height: 1.15; letter-spacing: -.2167vw;color:#842cfd}
                }
                p{
                   padding-left:11.25vw; @include subContsTitle; font-weight: 400; text-align: left;
                    span{
                        display: block; margin-top: 4.1667vw; color: $black; font-size: 3.8889vw;
                    }
                }
                .etc{
                    @include subContsTitle; font-weight: 400; text-align: center; display: block;
                }
            }
            .btn-position{
                position:absolute; top:59.7777vw; left:50%; transform:translateX(-50%); width:45vw; height:13.4211vw; display:block; font-size:0;
            }
            .btn-position02{
                position:absolute; top:51.1111vw; left:50%; transform:translateX(-50%); width:60.5556vw; height:13.8889vw; display:block; font-size:0;
            }
            .notice-box  {
                padding:0 5.5556vw 13.8889vw 5.5556vw; background:#fcf2ff; vertical-align: top;
                .notice-msg  {
                    min-width:88.8889vw; padding: 13.8889vw 9.7222vw; border-radius: 4.1667vw; background-color: #fff; text-align: center;
                    .msg-txt {
                        line-height: 1.2; font-size: 7.2222vw;font-weight:700; color: #191919;
                        strong {color:#842cfd;font-weight:700;font-size:7.2222vw;}
                    }
                    .text {margin-top: 8.3333vw; line-height: 1.2; font-size: 5vw; letter-spacing: -0.0833vw; text-align: center; color: #191919;}
                    .btn-link {display:block;width:100%; margin-top:8.3333vw; padding:4.1667vw 0; border-radius: 1.9444vw; background: #191919; color:#fff; font-size: 4.4444vw;}
                }
            }
        }
        .intro-item {
            padding-top: 16.6667vw;
            &:last-of-type{
                .box-img{margin-bottom: 13.8889vw;}
            }
            &.is-bg{background-color: #f2f3f8;}
            .box{
                &-title{
                    padding: 0 5.5556vw;
                    strong{@include mainContsTitle;}
                    p{margin-top: 4.1667vw; @include subContsTitle; font-weight: 400;}
                }
                &-img{
                    width:100%; margin-top: 13.8889vw; font-size: 0;
                }
                &-btn{
                    padding: 0 5.5556vw;
                    .btns{
                        background: $black; @include mainEmphasize; color: $white;
                    }
                }
            }
        }
    }

    // 친구추천 유심 선물하기 - 편의점 바로 픽업
    .delivery {
        &-tit {
            margin-bottom:4.1667vw;
            h2 {display:inline-block;@include mainTxt;vertical-align:top;}
            .tooltip-wrap {display:inline-flex;margin:0.6944vw 0 0 2.7778vw;vertical-align:top;}
        }
        &-way {
            margin-bottom:11.1111vw;
            .txt-tip {
                margin:2.7778vw 0 4.1667vw 0;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;
                strong {font-size: 4.1667vw; color:#191919; font-weight: 700;}
            }
            .btn-radio {
                &+.btn-radio {margin-top:4.1667vw;}
                label {
                    display:flex;justify-content:center;align-items:center;line-height:normal;
                    img {
                        display:inline-block;margin-right:1.3889vw;vertical-align:middle;
                        &.mark-ico {height:6.2500vw;}
                        &.mark-logo {height:4.8611vw;}
                    }
                }
            }
        }
        &-place {
            button, a {width:100%;}
        }
    }
}


.fridend-recdtin {
    .popup-header {
        .txt-main  {text-align: left;}
    }
    .is-scroll{height: 100%; overflow-y: scroll; overflow-x: hidden;}
   .details-recdtin {    
        height: 56vh; text-align: left;
       .bg-box {
           height: 13.8889vw;padding:0; border-radius: 1.3889vw;background-color: #f9f5ff; text-align: center;
           p {margin:0; font-size: 5vw;  font-weight: 600; line-height: 13.8889vw; letter-spacing: -0.1vw; text-align: center; color: #662d91;}
       }
       .box-table {
            margin-top:8.3333vw;
            tbody {
                th {border-bottom: 1px solid #ddd; font-size: 16px; font-weight: normal; line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #666; background:none; vertical-align: top;}             
                tr.odd {
                  background-color:#fafafa;
                }
                td {padding-left:0; color:#666; font-size: 4.1667vw; text-align: left;}               
            }            
        }
   }      
}



// 팝업
// [바텀시트]공유하기 링크
.evt-share{
    .list-share{
        display: flex; gap: 2.2222vw;
        li{
            width: 100%;
            img{width: 148px;}
            *:not(.pop-toast){@include defaultTxt; font-weight: 600; display: block; text-align: center;}
        }
    }
}

//[팝업] 사전예약신청조회
.evt-pop{
    .box-input{
        & + .box-input {margin-top: 4.1667vw;}
    }
    .popup-footer{
        .btns{font-size: 3.8889vw;}
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
.event{
    // 기본 템플릿 용
    .evt-section{
        display: flex; justify-content: center;
        .evt-conts{
            position: relative; width: 51.4286vw;
            .conts760{
                width: 46.2857vw; margin: 0 auto;
            }
        }
    }

    /* [팝업] 사은품 안내 */
    .evt-freebies {
        .inner{
            padding: 14.2857vw 2.8571vw 0.0000vw 2.8571vw;
        }
        .page-tit {
            margin-bottom:2.8571vw;
            h3 {
                font-size:3.7143vw;line-height:4.4286vw;
                &+p {margin-top:1.4286vw;}
            }
        }
        h4 {
            &+p {margin-top:0.3571vw;}
        }
        .freebies-list {
            display:flex;flex-wrap:wrap;gap:1.4286vw;margin:2.8571vw 0 5.7143vw;
            &>li {
                flex:1 1 40%;max-width:22.5000vw;
                input[type=checkbox] {position:absolute;opacity:0;}
                input[type=checkbox]:checked {
                    &+label {border:0.0714vw solid $purple;}
                }
                label {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:22.6429vw;padding:1.4286vw;border:0.0714vw solid #ddd;border-radius:1.4286vw;text-align:center;
                    .product-img{
                        width: 16.4286vw; height: 16.4286vw; margin:0 auto; overflow: hidden;
                        img{
                            width: 100%; display: block; height:100%;
                        }
                    }
                    .txt {text-align:center; margin-top:0.4286vw}
                }
                .freebies {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:22.6429vw;padding:1.4286vw;border:0.0714vw solid #ddd;border-radius:1.4286vw;text-align:center;
                    .product-img{
                        width: 16.4286vw; height: 16.4286vw; margin:0 auto; overflow: hidden;
                        img{
                            width: 100%; display: block; height:100%;
                        }
                    }
                    .txt {text-align:center; margin-top:0.4286vw}
                }
            }
        }
        .box-notice {
            width:51.4286vw;margin-left:-2.8571vw;padding:2.8571vw;background:$gray01;
            .bang-notice {margin:0 0 1.4286vw;}
            .notification{
                &>li {font-size:2.0000vw;}
            }
        }
    }
    /* [팝업] 사전예약신청 */
    .evt-reservation {
        .mt80 {
            margin-top: 5.7143vw;
        }
        .appli-ct {
            margin-bottom:2.1429vw;
        }
        .phone-info {
            strong{  margin-bottom: 0.7143vw; display: block;}
            &.underline{
                padding: 0; padding-bottom:2.8571vw; margin-bottom: 2.8571vw; border-bottom:0.1429vw solid $gray02;
            }
        }
        .section{margin-top: 5.7143vw;}
        .btn-check-wrap{
            margin-top: 2.8571vw;
            li{
                margin-bottom: 2.1429vw;
            }
        }
        .info-txt{
         margin-top: 5.7143vw;
        }
    }

    // [바텀시트]공유하기 링크
    .evt-share{
        .list-share{
            display: flex; gap: 1.1429vw;
        }
    }
    //진행중인 이벤트 목록
    .evt-going-list{
        .going-list-wrap{
            margin-top:4.2857vw;
            .cardList-wrap{display: block;}
        }
        .white{margin-top:5.7143vw;}
        .is-filter-warp{
            .is-filter-list{
                width: 51.4286vw;
                padding: 0 2.8571vw 0 2.8571vw;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        .cardList-img{
            img{
                height: 100%; object-fit: cover; width:100%;
            }
        }
        .no-evt-list{
            border-top:0.1429vw solid $gray02; border-bottom:0.1429vw solid $gray02; text-align: center;
            padding: 17.1429vw 0 5.0714vw;
            @include backgroundImg('/assets/images/icon/ico-no-evt.svg',center top, 18.5714vw 18.5714vw);
        }
    }

    //진행중인 이벤트 상세 페이지 - 기본 템플릿
    .template{
        &.sub-conts{
            padding: 0 2.8571vw 0 2.8571vw;
        }
        .template-view{
            &.unit-wide{
                margin-bottom: 5.7143vw;
            }
        }
        .info-tit{
            margin-bottom: 2.8571vw;
        }
        .title-wrap{
            padding:2.8571vw 0;
            .title-share-wrap{
                display: flex; justify-content: space-between;
                .ico-share{flex-shrink: 0;}
            }
            .desc-wrap{
                display: flex; justify-content: space-between; align-items: center;
                .title{
                     width:calc(100% - 4.5714vw);
                }
            }
            .period{
                margin-top: 1.4286vw;
            }
        }
        .event-banner{
            margin-bottom: 5.7143vw;
            img{width: 100%;}
        }
        .pre-reservation{
            margin: 0 2.8571vw;
           .btns{
             & + .btns{
                margin-top: 2.1429vw;
             }
            }
        }
        .evt-count{
            display: flex; justify-content: center; align-items: center; padding:2.8571vw 0; background: rgba(0, 0, 0, 0.9); border-top: 0.1429vw solid $gray02; text-align: center; letter-spacing: 0;
            p{
                display: flex;align-items: center;
               color: $white; font-weight: 400;
            }
            .date{
                background: rgba(0, 0, 0, 0.9); color:$white; height:3.5000vw; padding:0 1.1429vw; display:inline-block; border-radius: 1.0000vw; line-height: 3.5000vw;
                margin: 0 0.2857vw 0 0.7143vw;
                span{ color:$white;}
            }
        }
        .detail-sec{
            .detail-sec-tit{
                margin-bottom: 2.8571vw;
            }

             .detail-sec-tit + .detail-sec-desc{
                margin-top:-2.1429vw; margin-bottom: 2.8571vw;
             }
        }
        .accordian-list{
            width:100%; margin-top: 2.8571vw; border-top:0.1429vw solid $gray02; border-bottom:0.1429vw solid $gray02;
            & > li{
                button{
                    height: 8.5714vw; padding-bottom:0; margin-bottom: 0;
                    padding-right: 3.5714vw;
                }
                .acc-conts{
                    background:$white; padding:0;
                }
            }
        }
        .template-btn{
            margin-bottom: 5.7143vw;
        }
        .float-bnr{
            position:sticky; bottom:0;  padding:1.2143vw 0;  border: 0.2857vw solid $black; background: $white; border-radius: 2.5000vw;  text-align:center;
            span{
                color: $purple;
            }
        }
        .box-img{
            padding-bottom: 5.7143vw;
            .img-wrap {
                display: flex; flex-direction: column; gap: 2.8571vw;
                li{
                    position: relative; width: 100%; height: auto;
                    img{
                        display: block; width:100%;
                    }
                }
            }
        }
        .notice-tit{
             margin-bottom: 0.7143vw;
        }
        .slick-center-mode{
            .slick-dots{
                bottom: -2.6429vw;
            }
        }
        .item-bottom{
             margin-top:2.8571vw;
            .info-txt{
                padding:2.1429vw 0; border-top:0.1429vw solid $gray02;
            }
        }

        .video-slider{
            .slick-item{
                border-radius: 1.4286vw; background: $white; overflow:hidden;
                a{
                    display: block;
                    img{
                        object-fit: cover;
                    }
                }
            }
        }
    }

    //진행중인 이벤트 상세 페이지 - 유심 템플릿
    .evt-usim-template{
        .is-filter-warp{
            .is-filter-list{
                padding: 0 2.8571vw 0 2.8571vw;width: 51.4286vw;
            &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        .payment-imgCard-btn{
            margin-top:2.8571vw;
            & + .payment-imgCard-btn{
                margin-top:2.1429vw;
            }
            .item-top{
                padding-bottom: 0;
                .payment-desc{
                    dt{
                        b{
                            font-weight: 700;
                        }
                    }

                }
                .pay-amount{
                    padding-top: 2.8571vw;color: $purple;
                }
            }
        }
        & > .box-btn{margin-bottom: 5.7143vw;}
    }
    //진행중인 이벤트 상세 페이지 - 휴대폰 템플릿
    .evt-phone-template{
        .is-filter-warp{
            .is-filter-list{
                padding: 0 2.8571vw 0 2.8571vw;width: 51.4286vw;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        .payment-imgCard-btn{
            .cardList{
                .item-top{
                    padding-top:0;
                }
                .payment-desc{
                    dt{
                        display: flex;justify-content: center; align-items: center; gap:1.4286vw; width:100%;
                        .img-wrap{
                            .img{
                                width:13.1429vw; height:13.1429vw; display: none;
                                img{
                                    width:100%; height: 100%; display: block;
                                }
                                &.on{
                                    display: block;
                                }
                            }

                        }
                    }
                }
            }

        }
        .phone-info-head{
            .chip-wrap{margin-bottom: 2.1429vw;}
        }
        .phone-info-slide{
            padding-bottom: 2.8571vw;
            .slick-dots{
                bottom: 0;
            }
            .slick-item{
                padding: 4.2857vw 2.8571vw; border: 0.1429vw solid #e6e6e6;border-radius: 1.4286vw;
            }
            .phone-color-box{
                width: 100%;
            }
            .phone-charge-box{
                margin-top: 5.7143vw;
                .total-box{
                    display: flex; justify-content: space-between; margin-bottom: 2.1429vw;
                }
                .charge-wrap{
                    padding-bottom: 2.8571vw; background-color: $bg; border-radius: 1.4286vw;
                    & > div {
                        display: flex; flex-wrap: wrap; justify-content: space-between; padding: 2.8571vw;
                        & + div{
                           padding-top: 0; padding-bottom: 0;
                        }
                        .plan{
                            &-title{
                               color: $black; width: 50%;
                            }
                            &-name{
                                margin-top: 0.7143vw;
                            }
                            &-price{
                                display: block; text-align: right;width: 50%;
                            }
                        }
                        &.accordian{
                            position: relative; padding-bottom: 2.8571vw; background: none;
                            .title{
                                display: flex; flex-wrap: wrap; justify-content: space-between; padding: 2.8571vw 0 2.1429vw 0; border-top: 0.1429vw solid $gray02;
                            }
                            .acc-trigger{
                                position: absolute; left: 0; bottom: 0; width: 100%;
                                &:after {
                                    top: auto; bottom: 0; right: auto; left: 50%; transform: translate(-50%, 0);
                                }
                                &.on{
                                    &:after{
                                        transform: translate(-50%, 0) rotate(180deg);
                                    }
                                }
                            }
                            .acc-conts{
                                padding: 0 0 2.1429vw 0;
                                .cardList{
                                    border: none; padding: 0; border-radius: 0;
                                    dl{
                                        dt{
                                            width: 60%; padding-bottom: 1.4286vw;
                                        }
                                        dd{width: 40%; padding-bottom: 1.4286vw; }
                                        .bold{
                                          padding-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .box-btn{
                margin-top: 2.8571vw;
                .btns{
                    margin-top: 2.8571vw;
                }
            }
        }
        .phone-img {
            li{
                display: none;
                position: relative; width: 30.8571vw; height: 30.8571vw; margin: 0 auto;
                &.on{display: block;}
               img {height: 100%; object-fit: contain;}
            }
        }

        .product-imgCard{
            display: flex; flex-direction: column; gap: 2.1429vw; margin-top: 4.2857vw;
            .card-list-header{
                justify-content: flex-start;
                .chip-wrap{position: static;}
            }
            .item-price{
                position: static;margin-top: 2.1429vw;
            }
            .phone-img{
                li{
                    width: 14.2857vw; height: 14.2857vw;
                }
            }
            .color-type{
                margin-top:2.8571vw; justify-content: flex-start;
            }
            .item-bottom{
                width: 100%; margin-top: 2.8571vw;
                .info-txt{
                     padding:2.1429vw 0;  border-top: 0.1429vw solid $gray02; margin-top: 2.8571vw;
                    & + .box-btn{
                        margin-top: 0;
                    }
                }
                .box-btn{margin-top: 4.2857vw;}
            }
        }
    }
    //진행중인 이벤트 상세 페이지 - 템플릿 없는 페이지
    &.pb0{
        padding-bottom: 0;
    }
    //대상자 발표 상세페이지
    .evt-traget-announce{
       .btn-text-arrow{
        margin-bottom:2.8571vw;
       }
    }

    //포인트
    .ualmo-point{
        img{display: block;}
        .intro{
            position: relative;
            .btn-position{
                display: block;position: absolute;top: 37.0714vw;left: 50%;transform: translateX(-50%);width: 24.1429vw; height: 8.0000vw;font-size: 0;
            }
            .img{
                img{width: 100%;}
            }
        }
        .box-tab{
            margin: 7.1429vw 0 0 0;
            .sec-common {
                padding: 8.5714vw 0 2.8571vw;
                &:first-child{
                    padding-top: 5.7143vw;
                }
                .txt {
                    p{
                        font-weight: 400; margin-top: 2.1429vw;
                    }
                }
                .img{
                    display: flex; justify-content: flex-end; margin-top: 2.8571vw;
                    img{width: 40.0000vw;}
                }
                .box-btn{
                    margin-top: 2.8571vw;
                    .btns{
                        background-color: $black; color: $white;
                    }
                }
                .accordian{margin-top: 4.2857vw;}
            }
        }
    }
    // 친구 추천
    .event-friend{
        .cont-wrap{
            margin-bottom: 2.8571vw;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &.friend-ranking{
            .page-tit{
                margin: 4.2857vw 0 2.8571vw;
                h1{margin-bottom: 0.7143vw;}
            }
            .box-btn{margin-top: 5.7143vw;}
        }
        &.friend-usim{
            .page-tit{
                margin-bottom: 2.8571vw;
                & + .box-btn{margin-bottom: 5.0000vw;}
            }
            .self-write-wrap{display: none; margin-top: 1.1429vw;}
            .textarea-wrap{
                & + .btn-box.btn-check{margin: 2.8571vw 0 5.7143vw;}
            }
            .main-txt{
                & + .notification{margin-top: 2.1429vw;}
            }
        }
        &.friend-my-announce{
            .page-tit{
                margin-bottom: 3.5714vw;
                .btn-text-arrow{
                    margin-top:1.4286vw;
                }
            }
            .announce-img{
                width:100%; max-height:22.8571vw;
                img{
                    display: block;
                }
            }
            .box-tab{
                margin-top: 5.7143vw;
                .tab-btn-wrap{
                    li{
                        width:48%;
                        a{
                            width:100%; margin:0;
                        }
                    }
                }
                .tab-contents-wrap{
                    padding:0 0 1.4286vw 0;
                }
            }
            .table-type-04{
                width:100%; margin-bottom: 1.4286vw;
                td{
                    &:first-of-type{
                        color:$gray05;
                    }
                }
            }
        }
        &.friend-usim-history{
            .box-table{margin-top: 4.2857vw;}
        }
        &.friend-detail{
            padding: 5.7143vw 0 0;
            .sec-common{
                position: relative;width: 100%;margin: 0 auto;
                .img{
                    img{display: block;width: 100%;}
                }
                .btn-position{
                    position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);width: 35.7143vw;height: 7.1429vw;display: block;background: 0 0;border-radius: 3.5714vw;font-size: 0;
                }
                &.intro{
                    .btn-position{bottom: 34.5000vw;}
                }
                &.sec03{
                    .btn-position{bottom: 2.0714vw;}
                }
                &.sec04{
                    .btn-position{bottom: 10.7857vw;}
                }
                &.sec05{ 
                    .btn-position{bottom: 17.3vw;}
                }
                &.sec06{
                    .btn-position{bottom: 18.0000vw;}
                }
            }
            .btn-group.btn-fix {
                button{
                    img{height: 2.8571vw; vertical-align: middle;}
                    &.white{
                        background-color: #dee1e6;
                    }
                }
            }
            .fix-unit-group{
                padding: 0 2.8571vw;
                .unit-tit-s{
                    display: block;margin: 5.7143vw 0 2.1429vw;
                }
            }
        }
    }
    //유알모소개
    .event-friend-intro{
        padding: 5.7143vw 0 0;
        .main-banner-wrap{
            position: relative;
            .img-wrap{
                width: 100%;
                img{width: 100%;}
            }
            .txt-wrap{
                position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 6.2857vw 0 0 0;
                h2{
                   text-align: center; margin-bottom: 3.5714vw;
                }
                p{
                    font-weight: 400; text-align: center;
                    span{
                        display: block; margin-top: 2.1429vw; color: $black; font-size: 2.0000vw;
                    }
                }
            }
        }
        .intro-item {
            padding-top: 8.5714vw;
            &:last-of-type{
                .box-img{margin-bottom: 7.1429vw;}
            }
            &.is-bg{background-color: #f2f3f8;}
            .box{
                &-title{
                    padding: 0 2.8571vw;
                    p{margin-top: 2.1429vw; font-weight: 400;}
                }
                &-img{
                    width:100%; margin-top: 7.1429vw; font-size: 0;
                }
                &-btn{
                    padding: 0 2.8571vw;
                    .btns{
                        background: $black; color: $white;
                    }
                }
            }
        }
    }

    // 친구추천 유심 선물하기 - 편의점 바로 픽업
    .delivery {
        &-tit {
            margin-bottom:2.1429vw;
            h2 {display:inline-block;vertical-align:top;}
            .tooltip-wrap {display:inline-flex;margin:0.3571vw 0 0 1.4286vw;vertical-align:top;}
        }
        &-way {
            margin-bottom:5.7143vw;
            .btn-radio {
                &+.btn-radio {margin-top:2.1429vw;}
                label {
                    display:flex;justify-content:center;align-items:center;line-height:normal;
                    img {
                        display:inline-block;margin-right:0.7143vw;vertical-align:middle;
                        &.mark-ico {height:3.2143vw;}
                        &.mark-logo {height:2.5000vw;}
                    }
                }
            }
        }
        &-place {
            margin-bottom:5.7143vw;
            button, a {width:100%;}
        }
        &-request {
            margin-top:2.8571vw;
            h2 {display:block;margin-bottom:1.1429vw;}
            .textarea-wrap {margin-top:1.4286vw;}
        }
    }
}

// 팝업
// [바텀시트]공유하기 링크
.evt-share{
    .list-share{
        display: flex; gap: 1.1429vw;
        li{
            width: 100%;
            img{width: 10.5714vw;}
            *:not(.pop-toast){ font-weight: 600; display: block; text-align: center;}
        }
    }
}

//[팝업] 사전예약신청조회
.evt-pop{
    .box-input{
        & + .box-input {margin-top: 2.1429vw;}
    }
    .popup-footer{
        .btns{font-size: 2.0000vw;}
    }
}

}

.filter-box {
    position:relative; margin-right:-5.5556vw;
    &::before {content:'';position:absolute; right:0;top:0; width: 13.8889vw; height: 9.0556vw; background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0)); z-index:10;}
}

.badge-box{
     strong {display:inline-block; padding: 0.9722vw 1.6667vw; border-radius: 0.8333vw;  font-size: 3.8889vw; line-height: 1.2; letter-spacing: -0.0778vw; color: #fff;}
}
.phone-type01 {
    .phone-spc{ 
        .model-tit {
            margin-top:2.7778vw; padding: 4.1667vw; border-radius: 1.9444vw; background: #f7f8fa; text-align: center;
            .tit {display: block; font-size: 5.5556vw; font-weight: 700;  line-height: 1;  letter-spacing: -0.1111vw; color: #191919;}
            .txt {display: block; margin-top:1.3889vw; font-size: 4.4444vw; line-height: 1.25;  letter-spacing: -0.0889vw; color: #666;}
        } 
        .phone-img {margin-top:5.5556vw;}
        .chge {
            margin-top:5.5556vw;       
            dl {
                display: flex;
                dt {width:41.6667vw; padding-bottom:10px; font-size: 4.1667vw;  line-height: 1.33; letter-spacing: -0.0833vw; text-align: left;  color: #666; text-align: left;}
                dd {width: calc(100% - 41.6667vw); padding-bottom:2.7778vw; text-align: right; font-weight:normal; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; color: #666}
            }
        }
        .chge-prce {
            display:flex; align-items: center; padding-top:2.7778vw; border-top:1px solid #ddd;
           .prce-tit {width:15.2778vw; font-size: 4.1667vw; font-weight: 600;  line-height: 1.33;  letter-spacing: -0.0833vw; text-align: left; color: #191919;}
           .prce {width: calc(100% - 15.2778vw); font-size: 5.5556vw;  font-weight: 700; line-height: 5.5556vw; letter-spacing: -0.1111vw;text-align: right; color: #662d91;}
        }
    }  
    .box-btn {margin-top:4.1667vw;}
}


.phone-type01-01.cardList {
    display:block;padding:0; border:0;
    & + .phone-type01-01.cardList {margin-top:14.4444vw}    
    .phone-info {
        display: flex; gap:1.3889vw; margin-top:5.5556vw;
        .phone-img {
            width:41.6667vw;
            img {width:100%;}
        }
        .phone-spc {
            width:45.8333vw;
            .model-tit {
               padding:3.4722vw;   border-radius: 1.9444vw; background:#f7f8fa; text-align: left;
                .tit {display:block; font-size: 5.2778vw; font-weight: 700; line-height: 1.22;  letter-spacing: -0.1056vw; color: #191919;}
                .txt {display:block; margin-top:1.3889vw; font-size: 3.6111vw; line-height: 1.21; letter-spacing: -0.0722vw; color: #666;}
            }
            .chge {
                dl {display: flex; margin:2.7778vw 0 0 0;}
                dt {width:27.7778vw; padding-bottom:1.3889vw; text-align: left; font-size:3.6111vw}
                dd {width: calc(100% - 27.7778vw); padding-bottom:1.3889vw; text-align: right; font-size: 3.8889vw; line-height: 1.19; letter-spacing: -0.0778vw; color: #666;}
            }
            .chge-prce {
                display:flex; align-items: center; padding-top:3.7778vw; border-top:1px solid #ddd;
                .prce-tit {width:13.1944vw;   font-size: 3.6111vw;line-height: 1.2; letter-spacing: -0.0722vw; text-align: left; color: #662d91;}
                .prce {width: calc(100% - 13.1944vw); font-size: 5.5556vw;  font-weight: 700; line-height: 5.5556vw; letter-spacing: -0.1111vw;text-align: right; color: #662d91;}
            }
            .box-btn {
                margin-top:4.1667vw;
                .btns {width:45.8333vw; min-height:11.1111vw;}
            }        
        }

    }
}


.phone-type02 {
    &.cardList {display: flex; justify-content: space-between;  flex-wrap: wrap; gap:0;}
    .phone-info {
        display: flex; gap:0.8333vw; margin-top:6.9444vw;
        .phone-img {
            width:27.7778vw;
            img {width:100%;}
        }
        .phone-spc {
            width:48.6111vw;
            .model-tit {
                 text-align: left;
                .tit {display:block; font-size: 5vw; font-weight: 700; line-height: 5vw; letter-spacing: -0.1vw; color: #191919;}
                .txt {display:block; margin-top:1.3889vw; font-size: 4.1667vw; line-height: 1.19; letter-spacing: -0.0722vw; text-align: left; color: #666;}
            }
            .chge-prce {
                display:flex; margin-top:3.8889vw; align-items: center; 
                .prce-tit {font-size:4.1667vw; font-weight: 600; line-height: 1.2; letter-spacing: -0.0833vw; text-align: left; color: #191919; }
                .prce {width:32.8333vw; font-size: 5vw; font-weight: 700; line-height: 1.2; letter-spacing: -0.6px; text-align: right; color: #662d91;}              
            }            
        }
    }
    .btns {margin-top:4.5833vw}
}


.recommended-result{
    .popup-body {
        .txt-main {font-size: 5vw; font-weight: 600; line-height: 1.17;  letter-spacing: -0.1vw; text-align: center; color: #191919;}
        .btn-group {margin-top:5.5556vw;}
        .txt-sub {margin-top:4.1667vw; font-size: 4.1667vw; line-height: 1.4; letter-spacing: -0.0833vw;  text-align: center; color: #666;}
        .phone-certify {
            .txt-main {font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
            .form-bx {margin-top:5.5556vw;}
            & + .box-btn {margin-top: 4.1667vw;}
        }   
    }
}

.result-view {
    text-align: left;            
   .txt-main {display:block; margin-bottom:8.3333vw; font-size: 5vw; font-weight: 600; line-height: 1.11; letter-spacing: -0.1vw; text-align: left; color: #191919;}
   .msg-txt {
        font-size: 4.4444vw; font-weight: 600; line-height: 1.19; letter-spacing: -0.0889vw; text-align: left; color: #191919;
        & + p {margin-top:2.7778vw; line-height: 1.5;}
       & + .notification {margin-top:2.7778vw;}
       & + .box-table {margin-top:50px;}
   }
   p +  .msg-txt {margin-top:50px;}
   .sm-txt {font-size:16px; color:#000; font-weight:600;}
   .bg-bx {
       margin: 2.7778vw  0; padding: 5.5556vw; background-color: #f7f8fa;
       p {font-size: 4.1667vw;  font-weight: 600; line-height: 1.33; letter-spacing: -0.6px; text-align: left; color: #662d91;}
   }
   .btn-group{
       margin:13.8889vw 0 11.1111vw 0;
       .btns {width:100%;}
   }
   .box-table {
       margin-top:8.3333vw;
       & + p {margin-top:50px;}
       .txt {
           font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #191919;
           & + table {margin-top:10px;}
       } 
       & + .notification  {margin-top:20px;}
   }
}

.full-popup{
    &.terms {
        .full-popup-conts{
             .agree-cont {padding-bottom:15.2778vw;}
        }
    }
    .appli-ct{margin-bottom: 4.1667vw;}
    .evt-youandme{
        .box-tit{font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; color: #662d91; margin: 7.6389vw 0 1.3889vw 0;}
    }
    .agree-wrap{margin-top: 0;}  
    .page-tit{
        p{
            padding-left: 4.1667vw; position: relative;
            &::before{
                content: '※'; width: 4.1667vw; height: 5vw; font-size: 4.1667vw; position: absolute; left: 0; top: 0;
            }
        }
        &.star-none{
            p{padding-left: 0;
                &::before{display: none;}
            }
        }
    }
    .iphone15-event {
        margin-top:11.1111vw;
        padding-bottom:15.2778vw;
    .box-input .input-label {letter-spacing: -0.1111vw;}
    .box-input + .rq-phone {margin-top:8.3333vw}
    .rq-phone + .column {margin-top:2.0833vw;padding:0;}
    .column +  .box-flex {margin-top:8.3333vw;}
    .box-flex + .box-input {margin-top:2.0833vw}
    .agree-wrap {
            margin-top:5.5556vw;
            label {color:#191919;}
            .btn-check {
                label span::before {top:50%; transform: translateY(-50%);}
                label span::after {top:50%;  transform: translateY(-50%);}
            }
        }
    }

    // agree popup text 
    @media (max-width :674px) { 
        &.full-popup-agree{
            header{
                h2{
                    font-size: 4.5556vw;
                }
            }
        }
    }

    .pull-view {
        margin-top:8.3333vw;
        .pull-result {
            padding-bottom:16.6667vw;
            .result-list {
                padding: 5.5556vw;border-radius: 2.7778vw; border: solid 1px #ddd; background-color: #fff;
                li {
                    display:flex; align-items: center;
                    img {width:18.6667vw;}
                    span {display:inline-block;margin-left:2.7778vw; font-size: 3.8889vw;font-weight: 600;line-height: 1.5;letter-spacing: -0.0778vw;text-align: left;color: #191919;}
                    .txt-nber {display:inline-block; margin-left:auto; font-size: 5.5556vw; font-weight: 600; line-height: 1;letter-spacing: -0.1111vw;text-align: right; color: #662d91;}
                }
                li + li {margin-top:4.1667vw;}
            }
            .box-table {
                margin-top:8.3333vw;
                .table-type-04 td:first-of-type {color:#666;}
            }
        }    
    }

    .phone-certification {
        .box-input + .rq-phone {margin-top:5.5556vw;}
        .rq-phone + .column {margin-top:2.0833vw;padding:0;}
    }  
    .share-box {
        margin-top:8.3333vw;padding-top:8.3333vw;border-top:1px solid #ddd;
        .tit {margin-bottom:5.5556vw; font-size: 5vw;font-weight: 600;line-height: 1.17;letter-spacing: -0.1vw;text-align: left;color: #191919;}
        .txt {font-size: 4.4444vw;font-weight: 600; line-height: 1.31; letter-spacing: -0.0889vw; text-align: left;color: #191919;}
        .share-lik {
            position:relative; display:flex; justify-content: center;gap: 2.0833vw;
            a {
                img {width:20.5556vw;}
                strong {display:block; font-size: 4.1667vw;font-weight: 600; line-height: 1.33;letter-spacing: -0.0833vw;text-align: center; color: #191919;}          
            }
            .ts-msg {
                position:absolute;left:50%;bottom:-4.0833vw;width:88.8889vw;height:9.7222vw; ; border-radius: 20px; box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16); opacity: 0.67; background-color: #191919; transform: translateX(-50%);
                p { font-size: 4.1667vw;line-height:9.7222vw;letter-spacing: -0.0833vw;text-align: center;color: #fff;}
            }
        }
    }
    &.terms{
        .evt-kyobo{
            .agree-cont{padding-bottom: 8.3333vw;}
        }
    }
    .blackfriday-wrap{
        .page-tit{
            h2{
                span{font-size: 5vw; font-weight: 600; line-height: 1.6; letter-spacing: -.1vw; color: #662d91;}
            }
            ul{
                li{font-size: 4.1667vw; font-weight: normal; line-height: 1.4; color: #191919; word-break: keep-all;}
            }
        }
        .box-flex{
            &.column{padding-top: 0;}
        }
        .agree-wrap{
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.3;
                    span{
                        &::before{top: 50%; transform: translateY(-50%);}
                        &::after{top: 50%; transform: translateY(-50%);}
                    }
                }
            }
        }
        padding-bottom: 50vw !important;
    }
    .evt-udolf{
        .page-tit{
            h2{
                span{font-size: 5vw; font-weight: 600; line-height: 1.6; letter-spacing: -.1vw; color: #662d91;}
            }
            ul{
                li{
                    font-size: 4.1667vw; font-weight: normal; line-height: 1.4; word-break: keep-all; padding-left: 3.4722vw; position: relative; display: flex; align-items: center;
                    &::before{content: '※'; position: absolute; left: 0; top: 0;}
                }
            }
            .desc-01, span{
                font-size: 5.5556vw; font-weight: 600; line-height: normal; letter-spacing: -.1vw;  color: #191919; margin: 2.7778vw 0 4.1667vw;
                span{color: #662d91;}
            }
        }
        .box-input{
            &+.rq-phone{margin-top: 8.3333vw;}
        }
        .box-flex{
            &.column{padding: 0; margin-top: 2.0833vw;}
        }
        .agree-wrap{
            margin-top: 5.5556vw;
            label {color:#191919;}
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.33;
                    span{
                        &::before{top:50%; transform: translateY(-50%); top: 0;}
                        &::after{top:50%; transform: translateY(-50%);}
                    }
                }
            }
        }
        .share-box{
            margin-top: 0; padding-top: 0; border: 0;
            .tit{font-weight: normal; font-size: 4.1667vw;}
            .txt{font-size: 4.4444vw;}
            .share-txt-box{
                background-color: #f9f5ff; padding: 4.1667vw 0; margin: 4.1667vw 0 2.7778vw; text-align: center; font-size: 5.5556vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.8px; color: #191919; border-radius: 10px;
                span{color: #662d91; font-size: 5.5556vw; font-weight: 600; letter-spacing: -0.8px;}
                &+p{font-size: 4.1667vw;}
            }
            .share-lik{
                margin-top: 12.5vw;
                .ts-msg{bottom: -9.7222vw;}
            }
        }
        .udolf-stamp{
            padding: 1.3889vw 0 8.3333vw;
            ul{
                display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 2.7778vw 2.0833vw; justify-items: center;
                li{
                    width: 15.2778vw; height: 15.2778vw; background: transparent url('/assets/images/event/udolf-stamp-off.png') 0 0 no-repeat; background-size: 100%;
                    &.on{background: transparent url('/assets/images/event/udolf-stamp-on.png') 0 0 no-repeat; background-size: 100%;}
                }
            }
        }
        .udolf-txt-box{
            background-color: #f9f5ff; padding: 5.5556vw 0; text-align: center; border-radius: 10px;
            p,span{
                font-size: 4.1667vw; font-weight: 600; color: #191919; letter-spacing: -0.0833vw; line-height: 1.33;
                span{
                    &.under-line{text-decoration: underline;}
                }
                &.upoint{
                    font-size: 5vw; margin-bottom: 2.7778vw;
                    span{font-size: 5vw;}
                }
            }
        }
        .agree-wrap{padding-bottom: 15.2778vw;}
    }
    .evt-s24{
        .box-input{
            &+.rq-phone{margin-top: 8.3333vw;}
        } 
        .box-flex{
            &.column{padding: 0; margin-top: 2.0833vw;}
        }
        .agree-wrap{
            margin-top: 5.5556vw; padding-bottom: 15.2778vw;
            label {color:#191919;}
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.33;
                    span{
                        &::before{top:50%; transform: translateY(-50%); top: 0;}
                        &::after{top:50%; transform: translateY(-50%);}
                    }
                }
            }
        }
        .share-box{
            margin-top: 0; padding-top: 0; border: 0;
            .tit{font-weight: normal; font-size: 4.1667vw;}
            .txt{font-size: 4.4444vw;}
            .share-txt-box{
                background-color: #f9f5ff; padding: 4.1667vw 0; margin: 4.1667vw 0 2.7778vw; text-align: center; font-size: 5.5556vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.1111vw; color: #191919; border-radius: 1.3889vw;
                span{color: #662d91; font-size: 5.5556vw; font-weight: 600; letter-spacing: -0.1111vw;}
                &+p{
                    font-size: 4.1667vw; color: #959595; letter-spacing: -0.0833vw; line-height: 1.4; position: relative; padding-left: 2.7778vw;
                    &::before{content:'*'; position: absolute; left: 0.4167vw;}
                }
            }
            .share-lik{
                margin-top: 12.5vw;
                .ts-msg{bottom: -9.7222vw;}
            }
        }
        .area-desc{
            margin: 6.9444vw 0 1.3889vw 0;
            h2{color: #191919; font-size: 5vw; font-weight: 600; line-height: 1.6; letter-spacing: -0.1vw;}
        }
        .recommend-table{
            padding-bottom: 15.2778vw; margin-top: 6.9444vw;
            .table-type-04{
                td{color: #191919;}
                &:first-child{
                    td{border: 0;}
                }
                .state{
                    td{font-weight: 900;}
                }
                & + ul{
                    margin-top: 1.3889vw;
                    li{line-height: 1.5;}
                }
            }
        }

        &.evt-76970-popup{
            /*
            .inner{
                padding: 8.3333vw 5.5556vw;
            }
            */

            .page-desc{
                margin: 0 0 8.3333vw;
                h2{
                    color: #191919;
                    font-size: 5.5556vw;
                    font-weight: 600;
                    letter-spacing: -0.8px;
                    line-height: 1.25;
                    margin-bottom: 4.1667vw;
                }
                .txt-vt{
                    font-size: 4.1667vw;
                    font-weight: 600;
                    letter-spacing: -0.6px;
                }
            } & +.page-desc{
                margin-top: 4.1667vw 0 8.3333vw;
            }

            .box-input{
                margin-top: 2.2222vw;
                .input-label{
                    position: relative;
                    font-size: 4.4444vw;
                }
            }

            .box-flex{
                padding-top: 0;
            }

            .input-wrap{
                margin-top: 2.2222vw;
            }

            .box-input+.rq-phone{
                margin-top: 5.5556vw;
            }

            .agree-wrap{
                margin: 11.1111vw 0 26.9444vw;
                .btn-text-line{
                    padding: 4.1667vw;
                    label{
                        font-size: 4.1667vw;
                        letter-spacing: -0.6px;
                    }
                }
            }

            @media (min-width:674px) {
                .page-desc{
                    margin: 0 0 30px;
                    h2{
                        font-size: 20px;
                        letter-spacing: -0.4px;
                        margin-bottom: 0;
                    }
                    .txt-vt{
                        margin-top: 30px;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                    }
                } & +.page-desc{
                    margin-top: 4.1667vw 0 8.3333vw;
                }
                .box-input{
                    margin-top: 0;
                    .input-label{
                        font-size: 16px;
                    }
                }
                .input-wrap{
                    margin-top: 0;
                }
                .box-input+.rq-phone{
                    margin-top: 20px;
                }
                .box-flex {
                    padding-top: 28px;
                }
                .agree-wrap{
                    margin: 45px 0 0;
                    .btn-text-line{
                        padding: 15px 20px 15px 10px;
                        label{
                            font-size: 15px;
                            letter-spacing: -0.3px;
                        }
                    }
                }
            }
            
        }

      
    }
    .evt-randombox{
        .page-tit{
            p{
                position: relative; padding-left: 4.1667vw;
                &::before{content: '※'; position: absolute; top: 0; left: 0;}
            }
        }
        .box-btn{margin-bottom: 11.1111vw;}
        .box-tit{
            font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.1333vw; margin-bottom: 8.3333vw; color: #191919;
        }
        .box-input{
            & + .box-input{margin-top: 5.5556vw;}
        }
        .agree-wrap{
            margin-top: 11.1111vw; padding-bottom: 15.2778vw;
            label{color:#191919;}
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.33;
                    span{
                        &::before{top:50%; transform: translateY(-50%); top: 0;}
                        &::after{top:50%; transform: translateY(-50%);}
                    }
                }
            }
        }
    }
    .evt-5g{
        .page-tit{
            h2{
                margin-bottom: 2.7778vw;
                span{font-size: 5vw; font-weight: 600; line-height: 1.6; letter-spacing: -.1vw; color: #662d91;}
            }
            ul{
                li{
                    font-size: 4.1667vw; font-weight: normal; line-height: 1.4; word-break: keep-all; padding-left: 3.4722vw; position: relative; display: flex; align-items: center;
                    &::before{content: '※'; position: absolute; left: 0; top: 0;}
                }
            }
        }
        .evt-desc{
            margin-bottom: 8.3333vw;
            h2{
                font-size: 5.5556vw; font-weight: 600; color: #191919; line-height: 1.25;
                span{color: #662d91; font-size: 5.5556vw; font-weight: 600;}
            }
        }
        .choice-wrap{
            ul{
                li{
                    margin-bottom: 4.1667vw;
                    label{
                        text-align: left; font-weight: normal; padding: 4.1667vw 0 4.1667vw 12.5vw; font-size: 3.8889vw; background-color: #f6f6f6; 
                        &::before{
                            content: ''; position: absolute; left: 4.1667vw; top: 50%; margin-top: -2.7778vw; width: 5.5556vw; height: 5.5556vw; background: transparent url(/assets/images/icon/ico-radio-check.png) no-repeat center;
                        }
                    }
                    input[type=radio]{
                        &:checked{
                            &+label{
                                background-color: #fff; font-weight: 600; color: #662d91;
                                &::before{background: transparent url(/assets/images/icon/ico-radio-check-on.png) no-repeat center;}
                            }
                        }
                    }
                }
            }
        }
        .box-input{
            &+.rq-phone{margin-top: 8.3333vw;}
        }
        .box-flex{
            &.column{padding: 0; margin-top: 2.0833vw;}
        }
        .agree-wrap{
            margin-top: 11.1111vw; padding-bottom: 15.2778vw;
            label{color:#191919;}
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.33;
                    span{
                        &::before{top:50%; transform: translateY(-50%); top: 0;}
                        &::after{top:50%; transform: translateY(-50%);}
                    }
                }
            }
        }
    }
    .evt-thepeoplelife{
        .page-tit{
            p{
                padding-left: 4.1667vw; position: relative; margin-top: 4.1667vw;
                &::before{content: '※'; display: block; position: absolute; left: 0; top: 0;}
            }
        }
        .box-select-group{
            margin: 5.5556vw 0 11.1111vw;
            .box-select{
                & + .box-select{
                    margin-top: 4.1667vw;
                }
            }
        }
        .rq-phone{margin-top: 5.5556vw;}
        .box-flex{
            &.column{padding-top: 2.0833vw;}
        }
        .rq-form{
            margin-top: 11.1111vw;
        }
        .agree-wrap{
            margin-top: 5.5556vw;
            label {color:#191919;}
            .btn-check{
                align-items: center;
                label{
                    line-height: 1.33;
                    span{
                        &::before{top:50%; transform: translateY(-50%); top: 0;}
                        &::after{top:50%; transform: translateY(-50%);}
                    }
                }
            }
        }
    }
    .evt-uphone{
        .cash-state{
           width: 100%; height: 13.8889vw; border-radius: 1.9444vw; margin-bottom: 11.1111vw; background-color: #f9f5ff; font-size: 5vw; font-weight: 600; color: #191919; text-align: center; line-height: 13.8889vw; letter-spacing: -0.0972vw;
        }
        .box-tit{font-size: 4.4444vw; color: #191919; letter-spacing: -0.0972vw; margin-bottom: 2.7778vw; font-weight: 600;}
        .gift-cash{
            margin-bottom: 8.3333vw;
            .btn-radio{
                label{
                    padding: 2.7778vw 0;
                }
            }
        }
        .gift-receive{
            .box-tab{
                .tab-btn-wrap{
                    margin-bottom: 5.5556vw;
                    > li{
                        flex: 1;
                        a{
                            border-radius: 1.9444vw; background-color: #fff; border: 1px solid #ddd; font-size: 4.1667vw; color: #666;
                            &.on{
                                border: 1px solid #662d91; color: #662d91; transition: all .3s ease-out;
                            }
                        }
                    }
                }
            }
        }
        .notification{
            margin: 2.0833vw 0 2.7778vw;
            &.type02{
                li{
                    padding-left: 4.1667vw;
                    &::before{content: "※"; width: unset; height: unset; background: none; top: unset;}
                    .btn-join{
                        font-size: 4.4444vw; font-weight: 600; letter-spacing: -0.0833vw; color: #191919; margin-left: 2.0833vw; padding-right: 5.8333vw; position: relative; border: 0;
                        &::after{
                            content: ''; display: block; width: 4.4444vw; height: 4.4444vw; background: url('/assets/images/icon/ico-arrow-circle.svg') 0 0 no-repeat; background-size: 100%; position: absolute; right: 0; top: 50%; transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        .page-tit{
            & + .cardList-wrap{margin-top: 11.1111vw;}
        }
        .btn-check{
            margin: 5.5556vw 0;
            label{
                font-weight: 600; color: #191919;
                span{
                    &::before, &::after{top: 50%; transform: translateY(-50%);}
                }
            }
        }
        .box-btn{padding-bottom: 20vw;}
    }
    .evt-kyobo{
        .agree-chk-wrap{
            padding-bottom: 13.8889vw;
            .agree-chk{
                width: 100%; border-radius: 2.7778vw; padding: 5.5556vw; background-color: #f6f6f6;
                .btn-radio-type2{
                    label{
                        font-weight: 400;
                        span{
                            border: 0;
                            &::before{content: ''; width: 5.5556vw; height: 5.5556vw; display: inline-block; background-color: #ddd; border-radius: 50%;}
                            &::after{
                                width: 5.5556vw; height: 5.5556vw; background: transparent url('/assets/images/icon/ico-check-on.svg') center center no-repeat; background-size: 3.0556vw 2.2222vw;
                                opacity: 1; border: 0; transform: none;
                            }
                        }
                    }
                    input[type=radio]{
                        &:checked{
                            &+label{
                                color: #191919;
                                span{
                                    &::before{background-color: #662d91; transition: all .3s ease-out;}
                                }
                            }
                        }
                    }
                }
                .btn-check{
                    padding-left: 0; margin-top: 0; align-items: center;
                    label{
                        width: auto; color:#191919; line-height: 1.33;
                        span{
                            &::before{top:50%; transform: translateY(-50%); top: 0;}
                            &::after{top:50%; transform: translateY(-50%);}
                        }
                    }
                }
                ul{
                    display: flex; justify-content: space-between;
                }
                p{margin-bottom: 2.7778vw; line-height: 1.33; font-size: 4.1667vw;}
            }
        }
        .uphone-desc{
            img{display: block; margin: 0 auto; width: 100%;}
            h1{font-size: 5vw; font-weight: 600; text-align: center; color: #191919; padding: 9.0278vw 0;}
            dl{
                dt{font-size: 5vw; font-weight: 600; color: #662d91; border-top: 1px solid #ddd; padding-top: 9.0278vw; margin-bottom: 5.5556vw;}
                dd{
                    padding-bottom: 8.3333vw;
                    .tit-desc{font-size: 4.1667vw; font-weight: 600; color: #191919; margin-bottom: 4.1667vw;}
                    .notice{font-size: 4.1667vw; margin-top: 2.7778vw;} 
                }
            }
        }
        .txt-box{
            padding: 9.7222vw 5.2778vw; background-color: #f9f5ff;
            .tit{text-align: center; font-size: 5vw; font-weight: 600; color: #191919; margin-bottom: 4.1667vw; letter-spacing: -0.1vw;}
            .point{background-color: #662d91; color: #fff; padding: 0 1.3889vw; font-size: inherit; font-weight: inherit;}
            ul{
                padding-left: 5.2778vw;
                li{
                    font-size: 4.1667vw; line-height: 1.4; letter-spacing: -0.6px; color: #191919; list-style: decimal; list-style-position : outside; word-break: keep-all;
                }
            }
        }
    }
    .evt-paikdabang{
        .box-tit{
            font-size: 5vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0417vw; color: #662d91; border-top: 1px solid #ddd; margin: 8.3333vw 0 4.1667vw; padding-top: 8.3333vw;
        }
        .box-flex{
            &.column{padding-top: 0; padding-top: 2.7778vw;}
        }
        .agree-wrap{
            margin: 11.1111vw 0 25vw;
        }
        .btn-check{
            align-items: center;
            label{
                color:#191919; line-height: 1.33; word-break: keep-all;
                span{
                    &::before{top:50%; transform: translateY(-50%); top: 0;}
                    &::after{top:50%; transform: translateY(-50%);}
                }
            }
        }
    }
    &.event{
        header{
            h2{max-width: calc(100% - 13.8889vw);}
        }
        .agree-wrap{
            margin: 11.1111vw 0 25vw;
        }
        .btn-check{
            align-items: center;
            label{
                color:#191919; line-height: 1.33; word-break: keep-all;
                span{
                    &::before{top:50%; transform: translateY(-50%); top: 0;}
                    &::after{top:50%; transform: translateY(-50%);}
                }
            }
        }
    }
    .evt-vacance{
        .evt-notice{
            background-color: #f9f5ff; border-radius: 1.3889vw; padding: 6.25vw 0; text-align: center; margin-bottom: 40px;
            .tit{font-size: 6.3889vw; color: #662d91; font-weight: 900; margin-bottom: 3.4722vw;}
            .btn-text-arrow{font-size: 4.4444vw;}
        }
        .box-btn{margin-bottom: 11.1111vw;}
        .box-tit{
            font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.1333vw; margin-bottom: 8.3333vw; color: #191919;
        }
        .box-input{
            & + .box-input{margin-top: 5.5556vw;}
        }
        .vacance-find-box{
            background-color: #f9f5ff; padding: 6.25vw 5.5556vw; text-align: center; border-radius: 1.3889vw;
            h3{margin-bottom: 4.1667vw; font-weight: 600; color: #191919; font-size: 5vw;}
            p{color: #191919; font-size: 4.1667vw; font-weight: 600; margin-bottom: 8.3333vw; line-height: 5.5556vw; letter-spacing: -0.0833vw;}
            ul{
                display: flex; gap: 3.4722vw; justify-content: space-evenly;
            }
        }
    }
    // 사전예약 팝업(공통, 나이스인증) 
    .evt-reservation{
        .page-tit{
            & + .box-btn{margin-bottom: 11.1111vw;}
        }
        .box-tit{font-size: 5vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.33vw; margin-bottom: 8.3333vw; color: #191919;}
        .box-input{
            & + .box-input{margin-top: 5.5556vw;}
            & + .box-flex{margin-top: 5.5556vw;}
            & + .box-tit{margin-top: 9.7222vw;}
        }
        .box-flex{
            & + .box-input{margin-top: 5.5556vw;}
            &.column{padding: 0; margin-top: 2.0833vw;}
        }
    }
    .evt-zflip6{
        .share-info{padding-bottom: 25vw;}
        .share-box{padding-top: 0; border: 0;}
    }
    .evt-iphone16{
        .box-flex{
            & +.box-input{margin-top: 5.5556vw;}
        }
        .benefit-radio-wrap{
            margin-bottom: 6.9444vw;
            h3{color: #191919; font-size: 5vw; font-weight: 600; line-height: normal; letter-spacing: -0.1vw; margin: 11.1111vw 0 8.3333vw;}
            .btn-radio-group{
                display: grid; grid-template-columns: 1fr 1fr;
                .btn-radio{
                    width: 100%;
                    label{width: 100%; height: auto; padding: 2.7778vw 0; text-align: center;}
                } 
            }
        }
        .notice{
            margin: 2.7778vw 0 11.1111vw;
            li{font-size: 4.1667vw; line-height: 1.33; font-weight: normal; margin-bottom: 1.3889vw;}
        }
        .share-box{margin: 0; padding: 0; border: 0;}
        .img-box{margin-top: 11.1111vw;}
    }
}

.layer-popup{
    .evt-kyobo{
        .kyobo-img-wrap{margin: 5.5556vw 0;}
        .agree-wrap{
            .agree-desc{
                width: 100%; text-align: center; font-size: 4.7222vw; font-weight: 600; line-height: 1.29; color: #191919; margin-bottom: 3.75vw; letter-spacing: -1px;
            }
            .agree-chk{
                width: 100%; border: 1px solid #ddd; border-radius: 1.3889vw; padding: 4.1667vw;
                .btn-check{
                    padding-left: 0; margin-top: 0; align-items: center;
                    label{
                        color:#191919; line-height: 1.33;
                        span{
                            &::before{top:50%; transform: translateY(-50%); top: 0;}
                            &::after{top:50%; transform: translateY(-50%);}
                        }
                    }
                }
                ul{
                    border-top: 1px solid #ddd; margin-top: 4.1667vw; padding-top: 4.1667vw; display: grid; grid-template-columns: 1fr 1fr; gap: 4.1667vw 0;
                }
            }
            .notice-txt{font-size: 3.75vw; line-height: 1.4; margin-bottom: 3.75vw;}
        }
        .alert-txt{font-size: 5vw; color: #191919; font-weight: 600; line-height: 1.16;}
        .alert-btn-group{
            .btns{padding: 2.5vw 0;}
        }
    }

    .evt-76970-popup03 {        
        h2 {
            font-size:5vw;
            font-weight:600;
            color:#191919;
            text-align:center;
            letter-spacing:-0.1vw;
            line-height:1.11;
        }
        .result-cont {
            margin-top:5.5556vw;
            padding:5.5556vw;
            font-size:4.1667vw;
            font-weight:600;
            color:#191919;
            text-align:center;
            line-height:1.33;
            letter-spacing:-0.0833vw;
            background-color:#f9f5ff;
            border-radius:1.9444vw;
        }
        @media (min-width:674px) {
            h2 {
                font-size:20px;font-weight:600;color:#191919;text-align:center;letter-spacing:-0.4px;
            }
            .result-cont {
                margin-top:20px;padding:30px;font-size:16px;font-weight:600;color:#191919;text-align:center;line-height:1.38;letter-spacing:-0.32px;background-color:#f9f5ff;border-radius:10px;
            }
        }
    }
    
}

.carnival-event{   
    .event-inner {
        padding-bottom:12.5vw;text-align: center;background:#000;
        .event-top {background:#d1d1d1;}
        .event-cont {
            position:relative;
            .carnival-btn01{position:absolute;top:0;left:50%;margin-left:-34.3056vw;}
        }
        .btn-wrap{
            width: 100%; height: 32.5vw; position: relative;
            .carnival-btn01{top: 10vw;}
        } 
    }
    .event-benefit {
        position:relative; min-height:25.6944vw;
        .benefit-inner {
            margin:0 2.7778vw; padding-bottom:5.5556vw; background:#fff; border-radius: 5vw; margin-top: 2.5vw;
            .txt {
                width: 78.75vw;margin:0 auto; text-align: left;
                strong {width:17.2222vw;}
            & strong,
            & span {display:inline-block; font-size:3.3333vw; color:#000;}
            & + .txt {margin-top:2.7778vw;}
            }
            .btn.carnival-btn02,
            .btn.carnival-btn03 {position:relative; display:block; margin:5.5556vw auto 0; z-index: 10;}
            .btn {
                display: inline-block; margin-top:5.5556vw;               
            }
        }
    }
    .detail-sec {
        padding:5.5556vw;
        .notification {margin-top:2.7778vw;}
    }
    .template-info {width:111.1111vw; margin:13.8889vw auto;}
    .btn {width:68.75vw;}
    .notification{
        li{word-break: keep-all;}
    }
    .event-end{
        img{width: 61.875vw; height: 12.625vw; margin: 10vw auto;}
    }
}

.carnival-certify { 
    padding-bottom:16.6667vw;
    .rq-phone {margin-top:8.3333vw;}
    .rq-phone + .column {padding-top:2.2222vw;}
    .notification {margin:8.3333vw 0 5.5556vw 0;}
    .agree-wrap{
        .btn-check {align-items: center;}
        .btn-check label {align-items: normal;} 
        .btn-check label span::before {top:50%;}
        .btn-check label span::after {top:50%;}
    }
}

/* 유돌프를 찾아라 기획전 */
.udolt-wrap{
    ul{
        li{position: absolute;}
    }
    &.posi01{
        li{
            &:nth-child(1){top: 10%;}
            &:nth-child(2){top: 40%;}
            &:nth-child(3){top: 70%;}
            &:nth-child(4){top: 85%;}
        }
    }
    &.posi02{
        li{
            &:nth-child(1){top: 5%;}
            &:nth-child(2){top: 50%;}
            &:nth-child(3){top: 60%;}
            &:nth-child(4){top: 79%;}
        }
    }
    &.posi03{
        li{
            &:nth-child(1){top: 8%;}
            &:nth-child(2){top: 20%;}
            &:nth-child(3){top: 65%;}
            &:nth-child(4){top: 87%;}
        }
    }
    &.posi04{
        li{
            &:nth-child(1){top: 10%;}
            &:nth-child(2){top: 25%;}
            &:nth-child(3){top: 50%;}
            &:nth-child(4){top: 85%;}
        }
    }
    &.posi05{
        li{
            &:nth-child(1){top: 15%;}
            &:nth-child(2){top: 30%;}
            &:nth-child(3){top: 64%;}
            &:nth-child(4){top: 82%;}
        }
    }
    ul{
        &.horizontal01{
            li{
                margin-bottom: 3.75vw;
                &:nth-child(1){margin-left: 70%;}
                &:nth-child(2){margin-left: 50%;}
                &:nth-child(3){margin-left: 20%;}
                &:nth-child(4){margin-left: 60%;}
            }
        }
        &.horizontal02{
            li{
                margin-bottom: 3.75vw;
                &:nth-child(1){margin-left: 20%;}
                &:nth-child(2){margin-left: 60%;}
                &:nth-child(3){margin-left: 10%;}
                &:nth-child(4){margin-left: 70%;}
            }
        }
        &.horizontal03{
            li{
                margin-bottom: 3.75vw;
                &:nth-child(1){margin-left: 50%;}
                &:nth-child(2){margin-left: 70%;}
                &:nth-child(3){margin-left: 30%;}
                &:nth-child(4){margin-left: 40%;}
            }
        }
    }
}
.udolf-cha{
    width: 20.8333vw; height: 20.8333vw;
    background: transparent url('/assets/images/event/udolf-sprites.png') 0 0 no-repeat;
    background-size: auto 20.8333vw;
    animation: udolf-move 1.5s infinite steps(10);
    &.del{
        visibility: hidden;
    }
}

@keyframes udolf-move{
    100%{
        background-position: -208.3333vw 0;
    }
}

// 무료 혜택관
.free-benefit{
    &.sub-conts{padding-top: 0;}
    .page-img-tit{
        background-color: #fcefff; margin-bottom: 11.1111vw; padding: 0 5.5556vw; display: flex; align-items: center; justify-content: space-between;
        h1{
            font-size: 4vw; color: #191919; line-height: 1.16;
            .txt-bk-b{font-size: 5vw; margin-bottom: 1.25vw;}
        }
        img{width: auto; height: 27.7778vw;}
    }
    .page-tit{
        h2{margin-top: 11.1111vw; padding-bottom: 8.3333vw; border-bottom: 1px solid #ddd;}
    }
    .free-list-wrap{
        li{
            & + li{margin-top: 4.1667vw;}
            a{
                display: block; width: 100%; padding: 5.5556vw; border: 1px solid #ddd; border-radius: 2.7778vw;
            }
            .ltst-txt{
                dt{font-size: 5vw; font-weight: 900; letter-spacing: -0.0833vw; line-height: 1.22; margin-bottom: 1.3889vw; color: #191919;}
                dd{font-size: 4.1667vw; font-weight: normal; margin-bottom: 4.1667vw; letter-spacing: -0.0833vw; line-height: 1.2; color: #191919;}
            }
        }
        & + .box-tit{margin-top: 16.6667vw;}
    }
    .box-tit{font-size: 4.4444vw; font-weight: 600; line-height: 1.31; letter-spacing: -0.0889vw; color: #191919; margin-bottom: 2.7778vw;}
    .free-benefit-desc{
        .benefit-step{
            margin-bottom: 8.3333vw;
            li{
                font-size: 4.1667vw; font-weight: normal; line-height: 1.4; letter-spacing: -0.0833vw; padding-left: 5.5556vw; position: relative;
                &::before{content: ''; display: block; position: absolute; top: 0; left: 0; font-size: 4.1667vw;}
                &:nth-child(1)::before{content: '①';}
                &:nth-child(2)::before{content: '②';}
                &:nth-child(3)::before{content: '④';}
            }
        }
    }
    .term-agree-wrap{
        width: 100%; padding: 5.5556vw; background-color: #f6f6f6; border-radius: 2.7778vw; margin-bottom: 4.1667vw;
        dl{
            padding-left: 8.3333vw; position: relative;
            dd{
                p{font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; margin: 1.3889vw 0 2.7778vw 0;}
            }
        }
        .btn-check{
            display: block; position: static;
            label{
                display: block; width: auto; font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; color: #191919;
                .ico-check{position: absolute; top: 0; left: 0;}
            }
        }
    }
    .notification-wrap{
        margin-top: 11.1111vw;
        .notification{
            li{word-break: keep-all;}
        }
    }
    .accordian{
        .box-tit{margin-bottom: 0;}
    }
}

.free-service {
    min-height: 100vh;background-color: #f6f8fc;
    .box-tit {margin:0 0 2.7778vw 0; font-size: 5vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.1vw;text-align: left;color: #191919;}
    .coupon-list {
        .coupon-box {
            display: flex; align-items: center; padding:5.5556vw;border-radius: 3.0556vw; background: #fff;
            & +  .coupon-box {margin-top:4.1667vw;}
            .left-box {
                display: flex; align-items: center;gap:5.5556vw; width:100%;
                img {width:20.8333vw;}
                .txt-bx {
                    width:100%;         
                    .ltst-txt {                    
                        dt {
                            margin-bottom:1.3889vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;
                            &.icon-new {
                                display: inline-block; position: relative;padding-right:5.2778vw;
                                &:after { content:''; position: absolute;right:0;top:50%;transform: translateY(-50%); width:3.8889vw;height:3.8889vw;background:url('/assets/images/icon/icon-new.png') no-repeat 0 0; background-size: 3.8889vw;}
                            }
                        }
                        dd {overflow: hidden; max-height:11.1111vw; white-space: normal; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;word-break: keep-all; 
                            font-size: 3.8889vw;line-height: 1.29;letter-spacing: -0.56px;text-align: left;color: #666;}
                    }
                    .lnk-btn {
                        margin-top:2.0833vw;
                        &.col02 {
                            display:flex; gap:1.3889vw;    
                            .lnk {width:50%;}                     
                        }
                       .lnk {
                            display:block;height:6.6667vw;line-height:6.6667vw; border-radius: 1.3889vw;background-color: #f2e9ff;font-size: 3.3333vw;font-weight: 600;letter-spacing: -0.1667vw;text-align: center;color: #864bb2;                       
                            &.type01 {background: #eff1f5;color:#666;}
                            &.type02 {background: #ddd;color:#afafaf;}
                       }
                    }
                }                     
            }
        }
        & + .box-tit {margin-top:6.9444vw;}
    }
}

.coupon-form{
    .tit {margin-bottom:2.7778vw; font-size: 5vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.1vw;text-align: left;color: #191919;}
    .line-box {padding: 8.3333vw 5.5556vw;border-radius: 2.7778vw;border:1px solid #ddd;background-color: #fff;}
    .coupon-nuber {
        text-align: center;
       .nuber-bx {
             display: inline-flex ;flex-direction: column; align-items: center;gap: 10px; width: 100%;padding: 5.5556vw 0 4.1667vw 0;border-radius: .9722vw;border: solid 1px #191919;background-color: #fff;
            .cpn-tit {width:17vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.0444vw;text-align: center;color: #191919}
            .cpn-txt {font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.1vw;text-align: center;color: #662d91;color: #662d91;}
            .btn-copy {width:12.5vw;height:7.5vw;  border-radius: 0.9722vw; background-color: #eff1f5; font-size: 3.6111vw;font-weight: 600;line-height: 1.23;letter-spacing: -0.0722vw;text-align: center;color: #666;}
        }
        .list {
           margin:2.7778vw 0 0 0; border:0;text-align:center;
            li {
                font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;color: #666;
                & + li {margin-top:8px;}
            }            
        }
        .txt {margin-top:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #191919;}
        .btn-wrp {margin-top:8.3333vw;}
    }
    .dscrtin-box {
        margin-top:4.1667vw; padding: 5.5556vw; border-radius: 1.3889vw;background-color: #f7f8fa;
        ul {
            li {margin-top: 1.1111vw;}
        }
    }
    .notification-wrap {
        margin-top:11.1111vw;
        h3 {margin-bottom:2.0833vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}        
    }
    
    .not-coupon {
        text-align: center;
        .txt {  font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: center;color: #191919;}
        .btn-wrp {margin-top:8.3333vw;}
    }

}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .event{
        .template{ 
            &.sub-conts{padding: 0 20px 0 20px;}
            .title-wrap{
                padding: 20px 0;
                .period{margin-top: 10px;}
            }
            .evt-count{
                padding: 20px 0; border-width: 1px;
                p{color: #fff; font-weight: 400;}
                .date{height: 25px; padding: 0 8px; margin: 0 2px 0 5px; color: #fff; line-height: 25px; border-radius: 8px;}
            }
            .detail-sec{
                .detail-sec-tit{
                    margin-bottom: 20px;
                    & + .detail-sec-desc{margin-top: -15px; margin-bottom: 20px;}
                }
            }
            .accordian-list{
                margin-top: 20px; border-width: 1px;
                & > li{
                    button{height: 60px; padding-right: 25px;}
                }
            }
            .slick-center-mode{
                .slick-dots{bottom: -20px;}
            }
            .template-btn{margin-bottom: 40px;}
            .event-banner{margin-bottom: 40px;}
            .item-bottom{
                margin-top: 20px;
                .info-txt{padding: 15px 0; border-width: 1px;}
            }
            .box-img{padding-bottom: 40px;}
        }
        .evt-going-list{
            .is-filter-warp{
                .is-filter-list{width: 100%; padding: 0 20px 0 20px;}
            }
            .going-list-wrap{margin-top: 30px;}
            .no-evt-list{border-width: 1px; padding: 120px 0 35px; background-size: 130px 130px;}
        }
        .evt-usim-template{
            .is-filter-warp{
                .is-filter-list{width: 100%; padding: 0 20px 0 20px;}
            }
            .cate-radio-group{
                li{
                    border-bottom-width: 2px;
                    &.cate-radio{
                        label{font-size: 18px; padding: 10px 30px;}
                    }
                }
            }
            .payment-imgCard-btn{
                margin-top: 30px;
                .item-top{
                    .pay-amount{color: #662d91; padding-top: 20px;}
                    .banner-randombox-wrap{right: 0; top: -10px; left: unset; bottom: 0;}
                }
                .item-bottom{
                    .btns{
                        & + .btns{margin-top: 20px;}
                    }
                }
                & + .payment-imgCard-btn{margin-top: 15px;}
            }
            & > .box-btn{margin-bottom: 40px;}
        }
        .evt-phone-template{
            .is-filter-warp{
                .is-filter-list{
                    width: 100%; padding: 0 20px 0 20px;
                }
                .cate-radio-group{
                    li{
                        border-bottom-width: 2px;
                        &.cate-radio{
                            label{font-size: 18px; padding: 10px 30px;}
                        }
                    }
                }
            }
            .phone-info-head{
                .chip-wrap{margin-bottom: 15px;}
            }
            .phone-img{
                li{width: 220px; height: 220px;}
            }
            .phone-info-slide{
                .slick-item{padding: 30px 20px; border-width: 1px; border-radius: 8px;}
                .phone-charge-box{
                    margin-top: 40px;
                    .total-box{margin-bottom: 15px;}
                    .charge-wrap{
                        padding-bottom: 20px; border-radius: 8px;
                        & > div{
                            padding: 20px;
                            .plan-name{margin-top: 5px;}
                            &.accordian{
                                display: block; padding-bottom: 20px;
                                .title{padding: 20px 0 15px; border-width: 1px;}
                                .acc-conts{
                                    .cardList{
                                        dl{
                                            dt, dd{
                                                padding-bottom: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .box-btn{
                    margin-top: 20px;
                    .btns{margin-top: 20px;}
                }
            }
            .product-imgCard{
                gap: 20px; margin-top: 30px; margin-bottom: 30px;
                .phone-img{
                    li{width: 102px; height: 102px;}
                }
                .item-price{margin-top: 15px;}
                .color-type{margin-top: 20px;}
                .item-bottom{
                    margin-top: 20px;
                    .info-txt{border-width: 1px; padding: 15px 0; margin-top: 20px;}
                }
            }
        }
        .badge-box{
            strong{padding: 4px 10px; font-size: 14px;}
        }
        .evt-reservation{
            .section{margin-top: 40px;}
            .appli-ct{margin-bottom: 15px;}
            .mt80{margin-top: 40px;}
            .phone-info{
                &.underline{border-width: 1px; padding-bottom: 20px; margin-bottom: 20px;}
            }
            .info-txt{margin-top: 30px;}
        }
        .evt-traget-announce{
            .btn-text-arrow{margin-bottom: 20px;}
        }
        .ualmo-point{
            .box-tab{
                margin: 50px 0 0 0;
                .sec-common{
                    padding: 60px 0 20px;
                    &:first-child{padding-top: 40px;}
                    .txt{
                        p{margin-top: 15px; font-weight: 400;}
                        .s-txt{font-size: 16px; font-weight: 400;}
                    }
                    .img{
                        margin-top: 20px;
                        img{width: 280px;}
                    }
                    .box-btn{
                        margin-top: 20px;
                        .btns{color: #fff;}
                    }
                }
            }
        }
        .event-friend{
            .cont-wrap{margin-bottom: 20px;}
            &.friend-detail{
                .fix-unit-group{
                    padding: 0 20px;
                    .unit-tit-s{margin: 40px 0 15px;}
                }
                .btn-group{
                    &.btn-fix{
                        button{
                            img{height: 20px;}
                        }
                    }
                }
            }
            &.friend-ranking{
                .page-tit{
                    margin: 30px 0 20px;
                    h1{margin-bottom: 5px;}
                }
            }
            &.friend-my-announce{
                .page-tit{
                    margin-bottom: 25px;
                    .btn-text-arrow{margin-top: 10px;}
                }
                .announce-img{
                    max-height: 320px;
                    img{height: 100%; margin: 0 auto;}
                }
                .box-tab{margin-top: 41px;}
                .table-type-04{
                    td{
                        &:first-of-type{font-weight: 400;}
                    }
                }
            }
            &.friend-usim{
                .page-tit{
                    margin-bottom: 25px;
                    & + .box-btn{margin-bottom: 35px;}
                }
                .textarea-wrap{
                    & + .btn-box.btn-check{margin: 20px 0 40px;}
                }
                .main-txt{
                    & + .notification{margin-top: 15px;}
                }
            }
            &.friend-usim-history{
                .box-table{margin-top: 30px;}
            }
        }
        .delivery-tit{
            margin-bottom: 15px;
            .tooltip-wrap{margin: 2px 0 0 10px;}
        }
        .delivery-way{
            margin-bottom: 40px;
            .btn-radio{
                label{
                    img{
                        &.mark-ico{height: 25px;}
                        &.mark-logo{height: 20px;}
                    }
                }
                & + .btn-radio{margin-top: 15px;}
            }
        }
        .delivery-place{
            margin-bottom: 40px;
        }
        .event-friend-intro{
            padding: 40px 0 0;
            .main-banner-wrap{
                .txt-wrap{
                    padding: 17vw 0 0 0;
                    h2{
                        font-size: 40px; margin-bottom: 25px;
                        strong{font-size: 40px;}
                    }
                }
            }
            .intro-item{
                padding-top: 60px;
                &:last-of-type{
                    .box-img{margin-bottom: 50px;}
                }
                .box-title{
                    padding: 0 20px;
                    p{margin-top: 15px;}
                }
                .box-img{margin-top: 50px;}
                .box-btn{
                    padding: 0 20px;
                    .btns{color: #fff;}
                }
            }
        }
    }
    .evt-pop{
        .box-input{
            & + .box-input{margin-top: 15px;}
        }
        .popup-footer{
            .btns{font-size: 14px;}
        }
    }
    .full-popup{
        .share-box{
            .tit{font-size: 18px;}
            .share-lik{
                a{
                    img{width: 120px;}
                    strong{font-size: 18px;}
                }
                .ts-msg{
                    width: 100%; height: 50px;
                    p{font-size: 18px; line-height: 50px;}
                }
            }
        }
        .evt-udolf{
            .page-tit{
                .desc-01, span{
                    font-size: 26px; font-weight: 600; line-height: normal; letter-spacing: -.1vw;  color: #191919; margin: 15px 0 20px;
                }
                ul{
                    li{font-size: 18px; padding-left: 20px;}
                }
            }
            .box-input{
                &+.rq-phone{margin-top: 20px;}
            }
            .box-flex{
                &.column{margin-top: 15px;}
            }
            .share-box{
                .tit{font-size: 18px; margin-bottom: 20px;}
                .txt{font-size: 20px;}
                .share-txt-box{
                    padding: 20px 0; margin: 20px 0 10px; font-size: 24px;
                    span{font-size: 24px;}
                    &+p{font-size: 18px;}
                }
                .share-lik{margin-top: 30px;}
            }
            .udolf-stamp{padding: 10px 0 30px;}
            .udolf-txt-box{
                padding: 20px;
                p, span{
                    font-size: 18px;
                    &.upoint{
                        font-size: 24px; margin-bottom: 10px;
                        span{font-size: 24px;}
                    }
                }
            }
        }
        .evt-s24{
            .page-tit{
                .desc-01, span{
                    font-size: 26px; font-weight: 600; line-height: normal; letter-spacing: -.1vw;  color: #191919; margin: 15px 0 20px;
                }
                ul{
                    li{font-size: 18px; padding-left: 20px;}
                }
            }
            .box-input{
                &+.rq-phone{margin-top: 20px;}
            }
            .box-flex{
                &.column{margin-top: 15px;}
            }
            .share-box{
                .tit{font-size: 18px; margin-bottom: 20px;}
                .txt{font-size: 20px;}
                .share-txt-box{
                    padding: 20px 0; margin: 20px 0 10px; font-size: 24px;
                    span{font-size: 24px;}
                    &+p{font-size: 18px;}
                }
                .share-lik{margin-top: 30px;}
            }
            .udolf-stamp{padding: 10px 0 30px;}
            .udolf-txt-box{
                padding: 20px;
                p, span{
                    font-size: 18px;
                    &.upoint{
                        font-size: 24px; margin-bottom: 10px;
                        span{font-size: 24px;}
                    }
                }
            }
        }
        .evt-uphone{
            .cash-state{
                height: 50px; line-height: 50px; border-radius: 8px; font-size: 20px; margin-bottom: 40px;
            }
            .box-tit{font-size: 16px; margin-bottom: 20px;}
            .gift-cash{
                margin-bottom: 50px;
                .btn-radio{
                    label{
                        padding: 15px 0;
                    }
                }
            }
            .gift-receive{
                .box-tab{
                    .tab-btn-wrap{
                        margin-bottom: 20px;
                        > li{
                            a{
                                font-size: 15px; border-radius: 8px;
                            }
                        }
                    }
                }
            }
            .notification{
                &.type02{
                    li{
                        padding-left: 15px;
                        .btn-join{
                            font-size: 15px; margin-left: 10px; padding-right: 25px;
                            &::after{width: 20px; height: 20px;}
                        }
                    }
                }
            }
            .page-tit{
                & + .cardList-wrap{margin-top: 30px;}
            }
            .btn-check{margin: 20px 0;}
        }
        .evt-kyobo{
            .agree-chk-wrap{
                .agree-chk{
                    padding: 20px; border-radius: 15px;
                    p{font-size: 16px;}
                    .btn-radio-type2{
                        label{
                            span{
                                &::after{width: 20px; height: 20px; background-size: 11px 8px;}
                                &::before{width: 20px; height: 20px;}
                            }
                        }
                    }
                }
                
            }
            .uphone-desc{
                h1{font-size: 24px; padding: 40px 0;}
                dl{
                    dt{font-size: 22px; padding-top: 30px; margin-bottom: 15px;}
                    dd{
                        padding-bottom: 30px;
                        .tit-desc{font-size: 18px; margin-bottom: 20px;}
                        .notice{font-size: 18px; margin-top: 10px;} 
                    }
                }
                .txt-box{
                    padding: 40px 30px;
                    .tit{font-size: 26px; margin-bottom: 15px;}
                    ul{
                        li{font-size: 20px;}
                    }
                }
            }
        }
        &.terms{
            .evt-kyobo{
                .agree-cont{padding-bottom: 60px;}
            }
        }
        .evt-paikdabang{
            .box-tit{
                font-size: 20px; letter-spacing: -0.3px; border-top: 1px solid #ddd; margin: 20px 0 10px; padding-top: 20px;}
            .box-flex{
                &.column{padding-top: 10px;}
            }
            .agree-wrap{
                margin: 20px 0 60px;
            }
        }
        .evt-randombox{
            .page-tit{
                p{
                    padding-left: 20px;
                    &::before{width: 20px; font-size: 20px; top: -5px}
                }
            } 
            .box-btn{margin-bottom: 40px;}
            .box-tit{font-size: 20px; margin-bottom: 20px;}
            .agree-wrap{margin-top: 40px;}
        }
        .evt-vacance{
            .evt-notice{
                padding: 20px 0; margin-bottom: 30px;
                .tit{font-size: 20px; margin-bottom: 15px;}
                .btn-text-arrow{font-size: 18px;}
            }
            .box-btn{margin-bottom: 30px;}
            .box-tit{font-size: 20px; margin-bottom: 20px;}
            .vacance-find-box{
                padding: 40px;
                h3{font-size: 26px; margin-bottom: 10px;}
                p{font-size: 20px; margin-bottom: 40px; line-height: 22px;}
                ul{
                    gap: 20px;
                }
            }
        }
        .page-tit{
            p{
                padding-left: 20px;
                &::before{width: 20px; font-size: 20px; top: -5px}
            }
        } 
        .evt-reservation{
            .page-tit{
                & + .box-btn{margin-bottom: 50px;}
            }
            .box-tit{font-size: 18px; margin-bottom: 30px;}
            .box-input{
                & + .box-tit{margin-top: 50px;}
            }
        }
        .evt-iphone16{
            .benefit-radio-wrap{
                h3{font-size: 18px; margin: 50px 0 40px;}
            }
            .notice{
                li{font-size: 16px;}
            }
        }
    }
    .phone-type02{
        .phone-info{
            width: 100%; margin-top: 20px; justify-content: space-evenly;
            .phone-spc{
                .model-tit{
                    .tit{font-size: 22px; line-height: normal;}
                    .txt{font-size: 18px; margin-top: 10px;}
                }
                .chge-prce{
                    margin-top: 20px;
                    .prce-tit{font-size: 18px;}
                    .prce{font-size: 20px;}
                }
            }
        }
        .btns{margin-top: 20px;}
    }
    .layer-popup{
        .layer-popup-item{
            &.evt-kyobo{
                .kyobo-img-wrap{max-width: 400px; margin: 20px auto;}
                .agree-wrap{
                    margin-top: 20px;
                    .agree-desc{font-size: 18px; margin-bottom: 15px;}
                    .agree-chk{
                        padding: 20px; 
                        ul{
                            margin-top: 15px; padding-top: 15px; gap: 15px;
                        }
                    }
                }
                .notice-txt{font-size: 16px; margin-bottom: 15px;}
            }
            .alert-txt{font-size: 20px;}
        }
    }
    .free-benefit{
        .page-tit{
            h2{margin-top: 40px; margin-bottom: 20px; padding-bottom: 40px;}
        }
        .box-tit{font-size: 18px; margin-bottom: 10px;}
        .free-benefit-desc{
            .benefit-step{
                margin-bottom: 40px;
                li{
                    font-size: 16px; padding-left: 20px;
                    &::before{font-size: 16px;}
                }
            }
        }
        .term-agree-wrap{
            padding: 30px; border-radius: 10px;
            dl{
                padding-left: 30px;
                dd{
                    p{font-size: 16px; margin: 5px 0 10px 0;}
                }
            }
            .btn-check{
                label{font-size: 18px;}
            }
        }
        .notification-wrap{
            margin-top: 50px;
        }
    }
}


.family-union {
    .page-tit {
        .box-btn {margin-top:4.1667vw;}
        h1 {font-weight:700;}
    }
    .union-histy { 
        .cont-section  {
            .tite {margin-bottom:4.1667vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
            & + .cont-section {margin-top:11.1111vw;}
            .box-table {
                margin-top:4.1667vw;
                table {
                    th {padding: 5.5556vw 0;}
                    td {padding: 5.5556vw 0; color:#666;font-size: 4.1667vw;}
                }
                .btns.w-xs { width:auto; padding: 0.4167vw 2.2222vw;line-height: 1; border-radius: 10px;border: solid 1px #191919;background-color: #fff; font-size: 3.8889vw;font-weight: 600;letter-spacing: -0.0778vw;text-align: center;color: #191919;}
            }
        }

       .histy-not {
            padding: 6.9444vw 0 8.3333vw; border-radius: 2.7778vw; border: solid 0.2778vw #ddd; background-color: #fff;text-align: center; 
            p {margin-top:4.1667vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.19; letter-spacing: -0.0889vw;text-align: center;color: #191919;}
            img {width:16.6667vw;}
        }

        .family-histy {           
            table td {
                border-bottom:0;
            }           
            .btns  {color:#191919}
        }

        .member-view {font-size: 4.4444vw; font-weight: 600;  line-height: 1.25;  letter-spacing: -0.0889vw;text-align: left;color: #191919;}
        .txt-date {margin-top:2.7778vw; font-size: 4.1667vw;  line-height: 1.33; letter-spacing: -0.0833vw;text-align: left; color: #959595;}

        .accordian {
            .acc-conts {padding:5.5556vw;border-radius: 2.7778vw; background:#f6f6f6;}
            .title {font-size: 4.4444vw;font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw;text-align: left; color: #191919;}
            .notification-wrp {
                .noti-tit {display:block;margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left;color: #191919;}
                & + .notification-wrp {margin-top:2.7778vw}
            }
         }
    }
}

.sub-conts.benefit-plans-view {  
    padding-bottom:8.3333vw;
    .benefit-plans {       
        .sb-tit {margin-bottom:3.0556vw;font-size: 5vw;font-weight: 600;line-height: 1.17;letter-spacing: -0.1vw;text-align: left;color: #191919;}
        .benefit-list {
            .list {
                margin:0;border:0;
                li {
                    & + li {margin-top:4.1667vw;}
                    a {
                        display: flex;gap:5.5556vw; padding:0; border-radius: 2.7778vw; border: solid 0.1389vw #ddd;background-color: #fff;
                        img {width:34.7222vw;}
                        .dscrt {
                            padding:4.1667vw 0;
                            .tit {overflow: hidden; display:block;height:10vw; margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                            p {font-size: 3.6111vw;line-height: 1.23;letter-spacing: -0.0722vw;text-align: left;color: #666;}
                        }
                    }
                }
            }
        }
        section + section {margin-top:11.1111vw;}
        .plans-list {
            .list {
                margin:0;border:0;
                li {                  
                    & + li  {margin-top:4.1667vw;}
                    a {
                        padding:4.1667vw 5.5556vw; border-radius: 1.3889vw;border: solid 0.1389vw #ddd;background-color: #fff;
                        .plan-box {
                            display:flex;align-items: center;                  
                            img {width:29.1667vw;}
                            .dscrt {
                                margin-left:5.5556vw; 
                                .prc-box {
                                    display: flex;gap:1.3889vw; align-items: flex-end;margin-bottom:1.3889vw;
                                    .prc {display: inline-block; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: right;color: #959595;text-decoration: line-through;}
                                    .dc-prc {display: inline-block; font-size: 5.5556vw;font-weight: bold;line-height: 1.2;letter-spacing: -0.1111vw;text-align: right;color: #662d91;}
                                }
                                p {font-size: 3.6111vw;line-height: 1.54;letter-spacing: -0.0722vw;text-align: left;color: #666;}
                            }
                        }
                    }
                    .balloon {
                      position: relative;margin-top:2.7778vw; padding: 1.3889vw;border-radius: 1.3889vw;background-color: #e2d4f8; font-size: 3.6111vw;font-weight: 600;line-height: 1.15;letter-spacing: -0.0722vw;text-align: center;color: #662d91; 
                        &::before {content:''; position:absolute;left:50%;top:-1.5944vw; transform: translateX(-50%); width: 0px; height: 0px;border-style: solid;border-width: 0px 1.1111vw 1.6667vw 1.1111vw;border-color: transparent transparent #e2d4f8 transparent;}
                    }
                }
            }
        }
    }
    .btn-group {
        position: sticky;bottom:0; gap:0;
       .btns {
        height:80px;line-height: 80px;padding:0;border-radius: 0; font-size: 20px;font-weight: 600; text-align: center;color: #666;color:#fff;
        &:first-child { background-color: #eff1f5;  color: #666;}
    }
    }
}


.family-form {
    margin-top:11.1111vw; padding-bottom:19.4444vw;
    .tite {margin-bottom:5.5556vw; font-size: 5vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.1vw;text-align: left;color: #191919;}
    .section + .section {margin-top:11.1111vw}
    .box-input {
        & + .box-input {margin-top:5.5556vw;}
        .input-wrap + .input-wrap {margin-top:2.0833vw;}
        & + .box-select {margin-top:5.5556vw;}
        input[type=number]:disabled, input[type=text]:disabled {background: #ddd; color:#afafaf}
    }
    .box-btn {margin-top:4.1667vw;}
    .notification-wrp {
        .noti-tit {display:block;margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left;color: #191919;}
        & + .notification-wrp {margin-top:2.7778vw}
    }
    .family-file {
        .box-flex {
            .box-btn {
                margin-top:0;
               .btns.white{background:#dee1e5;color:#191919}
            }
            & + .box-flex {margin-top:4.1667vw; padding:0;}           
            .box-input input[type=number]:disabled, .box-input input[type=text]:disabled {background: #f6f6f6;}
        }
        .notification-wrp + .box-flex {margin-top:5.5556vw;padding-top:5.5556vw; border-top:1px solid #ddd;}
        .file-add {border-radius: 1.9444vw; background:#fff; color:#191919; border:1px solid #ddd}
    }

   .agree-wrap {margin-top:5.5556vw; padding: 4.1667vw;  border-radius: 1.9444vw; border: solid 1px  #ddd; background-color: #fff;}
}

.phone-redress{
    .top-banner {
        .baner-tit {display:block;margin-bottom:4.1667vw; font-size: 5.5556vw;font-weight: bold;line-height: 1.2;letter-spacing: -0.1111vw;text-align: left;color: #191919;}
        .baner-txt {font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.1111vw;text-align: left;color: #191919;}
        .img {
            margin-top:5.5556vw;text-align: center;
            img {width:55.5556vw;}
        }
        .box-btn {
            margin-top:8.3333vw;
            button + button {margin-top:2.7778vw;}
        }
    }
    .section {
        margin-top:11.1111vw;
        .sectin-tit {margin-bottom:2.7778vw; font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.1vw;text-align: left; color: #191919;}
        .sello-service {
            margin-top:4.1667vw;
           .service-box {
                width: 88.8889vw;height: 67.7778vw; padding: 5.5556vw 5.5556vw 8.3333vw;border-radius: 20px;background-color: #edfafd;text-align: center;
                .img-bx {display:inline-block;width:36.1111vw;height:36.1111vw;}
               .tit {
                    display: block;margin-top:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.2;letter-spacing: -0.0833vw;color: #191919;
                    span {color:#662d91;font-weight:600;}
                }
                .txt {margin-top:1.3889vw; font-size: 4.1667vw;line-height: 1.2;letter-spacing: -0.0833vw;color: #666;}
                & + .service-box { margin-top:4.1667vw;}
            }
        }
        .step-list {
           padding:5.5556vw; border-radius: 2.7778vw;border: solid 0.1389vw #ddd;background-color: #fff;
           li {
                & + li {margin-top:8.3333vw;}
                .step {display:inline-block;height:6.9444vw;line-height: 6.9444vw;padding:0 2.7778vw; border-radius: 3.4722vw;background-color: #191919;font-size: 3.8889vw;font-weight: 600;letter-spacing: -0.0833vw; color: #fff; text-align: center;}
                .step-view {
                    display:flex;gap:5.5556vw;margin-top:3.4722vw;
                    .icon-wrap {width:16.6667vw;height:16.6667vw;}
                    .txt-bx {
                        .tit {display:block; margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.2;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        .txt {
                            font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;
                            & + p {margin-top:1.3889vw;color: #959595;font-size: 3.8889vw;}
                        }
                    }
                }
           }
        }
        .notice-wrp {
            margin-top:11.1111vw;
            .tite {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
        }
    }
}

// 11509-re
.event-11509-re{
    position: relative;
    a{ font-size: 0;}  
    .menu-wrap{
        width: 100%; height: 11vw; background-color: #061f60;  z-index: 2; 
        &.sticky{position: fixed; top: 16.5vw; height: 11.875vw;  transition: top ease .5s;}
        .menu-item{
            width: 100vw; height: 100%; margin: 0 auto; display: flex;
            li{
                width: 25%;
                
                &.item01 { 
                    background: url('/upload/event/images/event11509-menu01.png') no-repeat center center;
                    background-size: auto 2.5vw;
                }
                &.item02 { 
                    background: url('/upload/event/images/event11509-menu02.png') no-repeat center center;
                    background-size: auto 2.5vw;
                }
                &.item03 { 
                    background:  url('/upload/event/images/event11509-menu03.png') no-repeat center center;
                    background-size: auto 2.5vw;
                }
                &.item04 { 
                    background: url('/upload/event/images/event11509-menu04.png') no-repeat center center;
                    background-size: auto 2.5vw;
                }
                a{width: 100%; height: 100%; font-size: 0;}
            }
        }
    }   
    .kv-area{       
        position: relative;  width: 100%; max-width: 100%;height: 87.5vw; overflow: hidden;
        -webkit-animation: background-swich 8s ease-in-out infinite both;
        animation: background-swich 8s ease-in-out infinite both;
        .tit{ padding-top: 15vw; width: 64.875vw; height: 25.5vw; margin: auto;}
        .bg-cloud{left: calc(50% + 42.5vw) ; transform: translateX(-50%); position:absolute;top:30vw;
            animation: cloude-2 28s linear infinite;
            -webkit-animation: cloude-2 28s linear infinite;      width:19.5vw !important;
        }
        .bg-cloud2{left: calc(50% - 42.5vw) ; transform: translateX(-50%); position:absolute;top:42.5vw;
            animation: cloude-1 28s linear infinite;
            -webkit-animation: cloude-1 28s linear infinite;      width: 16vw !important;
        }
        .object-sun{ position: absolute; left: calc(50% + 33.75vw); top: 15vw; width: 17.5vw !important;
            -webkit-animation: evt11509-sun 8s ease-in-out infinite both;
            animation: evt11509-sun 8s ease-in-out infinite both;        
        }
        .object-moon{ position: absolute; left: calc(50% + 33.75vw); top: 15vw;width: 12.5vw !important;
            -webkit-animation: evt11509-moon 8s ease-in-out infinite both;
            animation: evt11509-moon 8s ease-in-out infinite both;        
        }
        .store-area { 
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 71.75vw;
            height: 40.25vw;
            text-align: center;
            animation: evt11509-store 8s ease-in-out infinite both;
        }
        .people-area1 { 
            z-index: 2;
            width: 100%;
            height: 24.375vw;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: evt11509-people 8s ease-in-out infinite both;
        }
        .people-area2 { 
            z-index: 2;
            width: 100%;
            height: 24.375vw;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: evt11509-people2 8s ease-in-out infinite both;
        }
        .object-coupon{
            position: absolute; left: calc(50% - 3vw); bottom: 0;
            width:45vw;
        }
    }
    @media (max-width:800px){
        iframe{
            width: 100vw;
            height: 57.5vw;
        }
    }
}


@keyframes background-swich {
    0%{
     background-color: #95d3ff;
    }
    50%{
     background-color: #2b2e5a; 
    }
    100%{
     background-color: #95d3ff;
    } 
}
@keyframes cloude-1 {
    0% {
      left: 35%;
    }
    25% {
      left: 70%;
    }
    50%{
        left: 105%;
    }
    50.001% {
      left: -10%;
    }
    100% {
      left: 35%;
    }
}
@keyframes cloude-2 {
    0% {
      left: 75%;
    }
    25% {
      left: 110%;
    }
    25.001% {
      left: -10%;
    }
    100% {
      left: 75%;
    }
}
@keyframes evt11509-sun {
    0%{
    opacity: 1;        
    }
    50%{
    opacity: 0;
    transform: translateX(90px);
    } 
    100%{
    opacity: 1;
    transform: translateX(0);
    } 
}
@keyframes evt11509-moon {
    0%{
    opacity: 0;    
    transform: translateX(90px);    
    }
    50%{
    opacity: 1;
    transform: translateX(0);
    } 
    100%{
    opacity: 0;
    transform: translateX(90px);   
    } 
}
@keyframes evt11509-store {
    0%{
    background-image: url(/upload/event/images/event11509-store-a.png) ; 
    background-size: contain;
    }
    50%{
    background-image: url(/upload/event/images/event11509-store-b.png) ; 
    background-size: contain;
    } 
    100%{
    background-image: url(/upload/event/images/event11509-store-a.png) ;
    background-size: contain; 
    } 
}
@keyframes evt11509-people {
    0%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat;
        background-size: contain;
       }
       30%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat;
        background-size: contain;
       }
       50%{
        background: url('/upload/event/images/event11509-people-b.png')  center bottom no-repeat; background-size: contain;
       } 
       80%{
        background: url('/upload/event/images/event11509-people-b.png')  center bottom no-repeat; background-size: contain;
       } 
       100%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat ; background-size: contain;
       } 
}
// 11526 modify250226
.evt-roulette {
    &.modify250226{
        .wishlist-envet{
            .loading{
                display: none;
                .loading-img02{
                    width: 500vw;
                    height: 37.5vw;
                    background: transparent url('/upload/event/images/event-loading-img-0227.png') repeat-x; background-size: auto 100%;
                    animation: slide 10s linear infinite;
                    position: absolute; top: 40%;
                    z-index: 2;
                }
                &.on{
                    display: block;
                }
            }
            .result{
                position: relative;
                display: none;
                &.on{
                    display: block;
                }
                .result-goods{
                    width: 100vw;
                    height: 100vw;
                    position: absolute;
                    bottom: 22.5vw;
                    left: 50%;
                    transform: translateX(-50%);
                    a{
                        font-size: 0;
                        position: absolute;
                        width: 63.75vw;
                        height: 9.375vw;
                        bottom: 7.625vw;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            .swiper {
                width: 100vw;
                height: 100%;
                .swiper-wrapper{
                    position: absolute;
                    left: 0;
                    top: 44vw;
                    width: 100vw;
                    height: 171.5vw;
                    z-index: 10;
                    img{
                        width: 75vw;
                    }
                }
            }
        }
        .swiper-slide{
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vw;
        }
        .btn-11526-share{
            width: 78.75vw;
            height: 14.5vw;
            position: absolute;
            bottom: 30.375vw;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
        }
        .btn-group {
            width: 100%;         
            height: 12.5vw;
            gap: 0;
            z-index: 2;
            &.sticky{
                position: fixed;
                bottom: 0;
                left: 0;
            }
            .btns{
                height: 100%;
                border-radius: 0;
                width: 100%;
                font-size: 0;
                background-color: #2a2a2a;
                padding:0;
                &.item01{
                    position: relative;
                    text-align: right;
                    img { display: inline-block; height:12.5vw; margin: 0;}
                    &::after { position: absolute; right: 0; top:2.75vw; content: ''; width: 0.25vw; height: 7vw; background-color: #fff;}
                }
                &.item02{
                    text-align: left;
                    img { display: inline-block; height:12.5vw;}
                }
            }
        } 
    }
}

// 이즐    
.event-11673{
    img{
        display: block;
        margin: 0 auto;
    }
    .cont{
        position: relative;
        text-align: center;
    }
    a{
        font-size: 0;
    }
    .menu-wrap{
        width: 100%;
        height: 9.5vw;
        z-index: 2; 
        &.sticky{
            position: fixed;
            top: 16.4vw;
            height: 10vw;
            transition: top ease 0.5s;
        }
        .menu-item{
            width: 100vw;
            max-width: 100vw;
            height: 100%;
            margin: 0 auto;
            display: flex;
            li{
                width: 25%;
                &.item01 { 
                    background: url('/upload/event/images/event11673-menu01.png') no-repeat center center;
                    background-size: auto 4.5vw;
                }
                &.item02 { 
                    background: url('/upload/event/images/event11673-menu02.png') no-repeat center center;
                    background-size: auto 4.5vw;
                }
                &.item03 { 
                    background:  url('/upload/event/images/event11673-menu03.png') no-repeat center center;
                    background-size: auto 4.5vw;
                }
                &.item04 { 
                    background: url('/upload/event/images/event11673-menu04.png') no-repeat center center;
                    background-size: auto 4.5vw;
                }
                a{
                    width: 100%;
                    height: 100%;
                    font-size: 0;
                }
            }
        }
    }  
    .kpass-plan{
        position: relative;
        a{
            position: absolute;
            width: 93.25vw;
            height: 14.875vw;
            left: 50%;
            transform: translateX(-50%);
        }
        .plan01{
            height: 17.5vw;
            top:33vw;
        }
        .plan02{
            top:50.25vw;
        }
        .plan03{
            top:64.875vw;
        }
        .plan04{
            top:79.5vw;
        }
        .plan05{
            height: 17.5vw;
            top:94.5vw;
        }
    }
    .cont-btn{
        position: absolute;
        width: 73.75vw;
        height: 10.75vw;
        bottom: 10.25vw;
        left: 50%;
        transform: translateX(-50%);
    }
    .use_pc{
        img{        
            width: 100%;
        }

    }
}
// 이즐 end

.telenursing-box {
    position:sticky;left:0; bottom:0;width:100%;height:16.6667vw;border-radius: 20px 20px 0 0; background-image: linear-gradient(to bottom, #be7def, #8b17e3);
    .box-inner {
        display: flex;align-items: center;height: 100%;
        .img-bx {width:18.0556vw;margin:0.8333vw 0 0 6.9444vw}
        .telenursing-btn {
            display: flex;align-items: center; height:9.3056vw; border-radius: 100px;background-color: #4e147a;
            a {
                position: relative; display:flex;justify-content: center; align-items: center;padding:0 4.1667vw 0 11.1111vw; font-size: 5vw;font-weight: bold;   line-height: 1.19; text-align: left; color: #fff;
                &::before {content: '';position: absolute;left:4.1667vw;width:5.5556vw;height:5.5556vw; background: url('/assets/images/subpage/img-telenursing02.png') no-repeat 50%;background-size:5.5556vw;}
            }
        }
    }   
}

.plus-combine {
    .page-tit {
        .btn-box {
            margin-top:4.1667vw;
            .btns {
                width:35.2778vw;min-height:11.9444vw;padding:3.6111vw 0;font-size: 3.8889vw;
            }
        }
    }

    .accordian {
        margin-top:11.1111vw;
        .acc-conts {
            .txt {color:#e34140;}
            .notification {
                margin-top:1.3889vw;
                li{
                    padding-left:6.9444vw;
                    text-indent: -7vw;
                    &::before {content: '';display:none;}                    
                }
            }
        }
    }   
    
    .announce-tble {
        .box-table {
            & + .box-table {margin-top:11.1111vw;}
            table {
                th, td {padding:5.5556vw 0;}
                tbody {
                    th, td {color:#666;}
                }
                .btn {display:inline-block;padding: 0.5556vw 2.2222vw 0.4167vw; border-radius: 1.3889vw;border: solid 1px #191919; background-color: #fff; font-size: 4.1667vw;   font-weight: 600; line-height: 1.33;  letter-spacing: -0.0833vw; text-align: center; color: #191919;}
            }
            .box-select {margin-bottom:4.1667vw;}
        }
    }
}

.plus-combine-pop {
    .combine-pop-inner {
        .iner-tit {margin-bottom:11.1111vw; font-size: 5vw;font-weight: 600;  line-height: 1.17;letter-spacing: -0.1vw;text-align: left;color: #191919;}    
        .box-select + .box-btn {margin-top:4.1667vw;}
        .box-table {margin-top:11.1111vw;}
        .msg-bx {
            margin-top:11.1111vw;padding: 8.3333vw 5.5556vw;border-radius: 1.9444vw;background-color: #f9f5ff; text-align: center;
            p { font-size: 3.8889vw; line-height: 1.21;letter-spacing: -0.0778vw;text-align: center;color: #191919;}
            .msg01 {
                margin-bottom:2.7778vw; font-size: 4.4444vw;font-weight: 600; line-height: 1.19;letter-spacing: -0.0889vw;text-align: center;color: #191919;
                strong{color:#662d91; font-size: 4.4444vw;font-weight: 600;}
                span {color:#eb008b; font-size: 4.4444vw;font-weight: 600;}
            }
        }
        .box-flex {
            margin-top:5.5556vw;
            .btns:disabled,
            .input-default:disabled {background:#ddd;}
        }    
        .btn-check {margin-top:5.5556vw;}
        &.finish {
            .iner-tit {margin-bottom:4.1667vw;}
            .img-bx {
                text-align: center;
                img {width:36.1111vw;}
            }
            .msg-bx {margin-top:5.5556vw;}
            .notic {
                margin-top:11.1111vw;
                .noti-tit { font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                .notification {
                    margin-top:2.7778vw;
                    li {letter-spacing: -0.125vw;}
                    & + .noti-tit {margin-top:2.7778vw;}
                }
            }
        }
    }
}

.terms.terms-combine {
    .agree-cont {
        h3 {margin-top:8.3333vw;}
        p {font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
        .txt-b {font-weight: 600;color:#191919}
        ul {
            li {
                position:relative; margin-top:1.3889vw;padding-left:3.4722vw; color:#191919;
                &::before {content: '-';position:absolute;left:0;top:0.2167vw;line-height: 1.2; font-size:4.1667vw;color:#191919;}
            }
        }
        .table-list-wrap {margin-top:8.3333vw;}
    }
}