@charset "UTF-8";


//공통요소
.products {
    .sub-conts{
        &.pt-0 {
            padding-top: 0;
        }
    }
    // 부가서비스 상세 cms 영역 마진
    .detail-contents-wrap{
        margin-top: 11.1111vw;
        & + .box-btn{margin-top: 11.1111vw;}
    }
    // 부가서비스 상세 공통 카드영역
    .payment-list-card-wrap.addition-service-card{
        .payment-list-card{
            .sub-title{@include subContsTitle;}
            .default-txt{display: block; margin-top: 4.1667vw;}
            p{margin-top: 1.3889vw; @include smallTxt; word-break: keep-all;}
            .icon-wrap{
                img{
                    display: inline-block; width: 11.1111vw;
                    & + img{margin-left: 2.7778vw;}
                }
            }
            .pay-amount{
                align-items: center; padding-top: 5.5556vw; gap: 1.3889vw;
                span{@include smallTxt;}
                &.is-icon{justify-content: space-between;}
                .discount-pay{@include mainTxt; color:$purple}
            }

        }
    }
    .noti-section{
        margin-top: 11.1111vw;
        .sub-contents-title{display: block; margin-bottom: 1.3889vw;}
    }
    //유심 요금제 - 추천요금제,전체요금제
    .page-img-tit {
        display:flex;justify-content:space-between;align-items:center;gap:4.1667vw;margin-bottom:11.1111vw;padding-left: 5.5556vw;
        h1 {display:block;@include subContsTitle;font-weight:normal; letter-spacing: -0.1389vw;}
        img {width: 41.6667vw;}
        &.addition-img-tit{
            background-color: #f4f6ff;
            h1{
                word-break: keep-all; font-size: 4vw; padding: 4vw 0;
                p{font-size: 5vw; margin-bottom: 1.25vw;}
            }
        }
    }
    .payment-list{
        .page-img-tit {background:#fcfaea;}
        .is-filter-warp {
            margin-bottom: 11.1111vw;
            .is-filter-list {width:100vw;}
        }
        .life-list-wrap {
            margin-bottom: 11.1111vw;
            .payment-accordian-card-wrap {
                margin-top:11.1111vw;
                &:first-of-type {margin-top:5.5556vw;}
            }
        }
        .pay-amount {
            .origin-pay {color:$gray04;}
        }      
    }
    .payment-recommend-list {
        .page-img-tit {
            &.usim-ch{
                background:#effbf5;
            }
            &.esim-ch{
                background: #f8f4ff;
            }
            &.phone-ch{
                background: #f5f8ff;
            }
            &.usim-all {background:#fcfaea}
            &.usim-plan {background:#f6f2ec}

            &.esim-all {background:#fcfaea}
            &.esim-ch {background:#f8f4ff}
            &.esim-plan {background:#f6f2ec}

            &.phe-all {background:#fcfaea}
            &.phe-ch {background:#f5f8ff}
            &.phe-plan {background:#f6f2ec}
        }
    }

.plan-slider{    
    overflow: hidden;height:44.1944vw; position: relative;margin:-11.1111vw -5.5556vw 8.3333vw -5.5556vw;
    .slide {
       min-height:48.1944vw; padding:6.9444vw 5.5556vw; background:#f8f4ff;
        .plan-dtil {      
            .plan-tit {display:block;  font-size: 4.4444vw;font-weight: bold;letter-spacing: -0.0889vw;text-align: left;color: #191919;}     
            .spc {
               position: relative; margin-top:4.1667vw;padding-bottom:6.9444vw;
                .spc-view {
                    display:flex;
                    span{
                        position: relative; margin:0; padding-left: 6.3889vw; font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.0778vw; text-align: left;color: #191919;
                        & + span {margin-left:3.4722vw; }      
                        &::before {content:'';position:absolute;left:0;top:50%; transform: translateY(-50%);  width:5.5556vw;height:100%;}
                    }
                   span.vol::before {background:url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center;background-size:5.5556vw;}
                   span.limit::before {background:url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size:5.5556vw;}
                   span.supply::before {background:url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center;background-size:5.5556vw;}
                }
                .txt {
                   position: absolute;left:0; bottom:0;min-height: 2.6389vw; margin-top:2.0833vw;padding-left:2.2222vw; font-size: 3.8889vw;letter-spacing: -0.0778vw;text-align: left;color: #959595;
                    &::before {content: ''; position: absolute;left:0;top:0.8333vw; width:0.8333vw;height:0.8333vw;border-radius: 50%; background: #959595;}
                }
            }    
            .price-box {
                display: flex;justify-content: flex-end; align-items: flex-end; margin-top:4.1667vw;text-align: right;    
                .price {margin-right:1.3889vw; font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: right;color: #959595; text-decoration: line-through;}
                .dc-price {
                    .dc-txt {font-size: 6.1111vw;font-weight: bold; letter-spacing: -0.1222vw;text-align: right;color: #662d91;}
                }
            }    
        }
        &01 { background-color: #f8f4ff;}
        &02 { background-color: #f1f7ff;}
        &03 { background-color: #f3fff9;}
    }
    .slick-control {
        position: absolute;left:5.5556vw;bottom:6.9444vw;
        .slick-cunt {
            display: flex;align-items: center; padding: 0.6944vw 1.6667vw;border-radius: 13.8889vw;background-color: #dbe6e5;
            .first-page {min-width: 2.5vw; font-size: 3.3333vw;font-weight: 600;letter-spacing: -0.0667vw;text-align: center;color: #666;}
            .end-page {
                position:relative;min-width: 2.5vw; margin-left:1.1111vw;padding-left:1.6667vw;  font-size: 3.3333vw;font-weight: 600;letter-spacing: -0.0667vw;text-align: center;color: #959595; opacity: 0.6;
                &::before {content:''; position:absolute; left:0.1778vw;top:50%;width:0.2778vw;height:2.0833vw; background: #959595; transform: translateY(-50%) rotate(20deg);opacity: 0.6;}
            }
        }
    }
}

.page-img-tit.basic {position:relative;padding:4.7222vw 4.1667vw 5.2778vw 5.5556vw;  border-radius: 2.7778vw; font-size: 4.7222vw;line-height: 1.29;letter-spacing: -0.0944vw; text-align: left;color: #191919;gap:0;}
.page-img-tit.basic .txt-b {font-size:4.7222vw;font-weight:600;color:#191919}
.page-img-tit.basic img {position:absolute;right:0;top:-5.7778vw;width:33.3333vw;}

.recmend-fee {
    margin-top:11.1111vw;
    .fee-list {
      &>li {position:relative;top:5.5556vw;visibility: hidden;padding:8.3333vw 5.5556vw 5.5556vw;border:1px solid #ddd; border-radius: 2.7778vw;opacity:0; transition: all .4s;background:#fff;}
      &>li.on {visibility: visible; opacity: 1; top: 0;}
      &>li + li {margin-top:4.1667vw;}
      li{
        position: relative;
        .bge {display:block;width:21.5278vw;}
        .fee-tit {display:block; margin-top:2.0833vw;font-size: 4.4444vw; font-weight: 600;ne-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
        .pay-amount {
            margin-top:4.1667vw;padding:0;text-align: right;
            .origin-pay {font-size:4.1667vw; color:#959595;text-decoration:ne-through;}
        }
        .txt-limited {margin-top:1.3889vw; font-size: 3.6111vw; line-height: 1.19; letter-spacing: -0.0722vw; text-align: right; color: #959595;}                
        .feature {
            margin:4.1667vw 0 0 0;padding:4.5833vw 0 4.5833vw 4.1667vw;border-radius: 2.7778vw; background:#f7f8fa;
            span{
                margin:0;font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.0778vw; text-align: left;color: #191919;
                & + span {margin-left:3.4722vw; }                
            }
           span.vol::before {background:url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center;background-size:5.5556vw;}
           span.limit::before {background:url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size:5.5556vw;}
           span.supply::before {background:url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center;background-size:5.5556vw;}
        }
        .notification {margin-top:4.1667vw;}
        .notification li {  font-size: 3.8889vw;line-height: 1.43; letter-spacing: -0.0778vw; text-align: left; color: #959595;}

        .compare-box {
            position:absolute;right:-1px;top:-1px;border:0.1389vw solid #ddd; border-radius: 0px 2.7778vw 0px 2.7778vw; text-align:center;background:#fff;
            span {
                position: relative; display:block;width:23.75vw;height:8.0556vw;padding-right:4.1667vw; font-size: 3.8889vw;font-weight: 600;line-height: 8.0556vw;letter-spacing: -0.0778vw;text-align: center;color: #662d91;
                &::after {content:''; position:absolute;right:2.7778vw;top:50%;width:4.1667vw;height:4.1667vw;margin-top:-2.0833vw; background: url('/assets/images/icon/icon-plus.png') no-repeat center;background-size:4.1667vw ;}
            }
            button.checked {
               span {
                color:#bbb;
                &::after {background: url('/assets/images/icon/icon-plus-gray.png') no-repeat center; background-size:4.1667vw ;}
            }
            }
        }
      }
    }
}

.bottom-layer-pop {
    position: fixed; left: 0; bottom: 0; display:block; z-index: 100; width: 100%;text-align:center;
    &::before {content:''; position:absolute;left:50%;top:0;width:75vw; height:14.4444vw;transform: translateX(-50%); background: url('/assets/images/subpage/img-compare-bottom-layer.png') no-repeat 50%;background-size:75vw; }
    &.on  {
        .bottom-pop-btn {
            .tite::after {transform: translateY(-50%) rotate(0);}
        }
    }

    .bottom-pop-btn {
       position: relative; display: block;width:100%; height:14.4444vw;padding:0 12.5vw; line-height: 14.4444vw;z-index: 10;             
       &::before {content:''; position:absolute;left:0;bottom:0;width:12.5vw;border-bottom:1px solid #662d91}
       &::after {content:''; position:absolute;right:0;bottom:0;width:12.5vw;border-bottom:1px solid #662d91}
        .tite {
            position: relative; display:block;padding-right:6.9444vw; font-size: 4.4444vw;font-weight: bold; letter-spacing: -0.0889vw;text-align: center;color: #191919;       
            &::after {content:''; position: absolute; right:9.7222vw;top:50%;transform: translateY(-50%) rotate(180deg); width:5.5556vw;height:5.5556vw;  background: url('/assets/images/subpage/img-compare-bottom-arrow.png') no-repeat 50%;background-size:5.5556vw;}            
           .cunt{display: inline-block;margin-left:2.0833vw; font-size: 4.4444vw;font-weight: bold;letter-spacing: -0.0889vw;text-align: left;color: #662d91;}
        }

    }
    .bottom-container {    
        display: none; padding-bottom:8.3333vw;background: #fff;  
        .container-inner {    
            overflow-x: auto;overflow-y: hidden; padding:0 0 6.9444vw 0;
        }
       .compare-cont {
            width:130%; padding-top:5.5556vw;
            .compare-inner {
                display: flex;gap:4.1667vw;align-items: center;padding:0 5.5556vw;
                .compare-view {
                    width: 45%;height: 33.6111vw;padding: 4.1667vw;border-radius: 2.7778vw;border: solid 1px #ddd;background-color: #fff;
                    .tit {overflow: hidden; width:20.8333vw; height:15.5556vw; word-break: break-all; font-size: 4.1667vw;font-weight: 600;line-height: 1.2;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    .pric {display: block;margin-top:4.1667vw; font-size: 4.7222vw;font-weight: 700;line-height: 1.2;letter-spacing: -0.0944vw;color: #662d91; text-align: right;}
                    .compare-tit {
                        position:relative; 
                        .btn-del {position:absolute;right:0;top:0;width:5.5556vw;}
                    }
                }
                .before-comparison {
                    width: 45%;min-height: 33.6111vw;padding: 4.1667vw;border-radius: 2.7778vw;border: dashed 1px #ddd;background-color: #fff;
                    p {
                        padding-top:6.25vw;font-size: 3.8889vw;letter-spacing: -0.05vw;text-align: center;color: #999;background:url('/assets/images/icon/icon-plus02.png') no-repeat 50% 0;background-size: 5.5556vw;                        
                    }
                }
            }
       }
       .box-btn {
            padding:0 5.5556vw;
            .btns {padding: 3.3333vw 0; border-radius: 1.9444vw;}
        }
    }
}

.all-fee-wap{
    margin-top: 5.5556vw;
    .title{@include subContsTitle;}
    & > div{
        & + .title{margin-top: 11.1111vw;}
    } 
    .recmend-fee{
        margin-top: 0;
        .fee-list{
            margin-top: 2.7778vw;
            & > li{
                visibility: visible; opacity: 1; top: 0;
            } 
        }
    }
    .accordian  {
        .acc-trigger {
            padding-bottom:2.7778vw; border-bottom: 1px solid #ddd;    
            &:after {content: "";position: absolute;right: 0;top:50%;width: 5.5556vw;height: 5.5556vw;background: url(/assets/images/icon/ico-arrow-bottom-gray.svg) no-repeat 0 0;background-size:5.5556vw;transform: translateY(-50%);}        
            &.on {
                border-bottom: 1px solid #191919;
                &:after {content: "";position: absolute;right: 0;top:50%;width: 5.5556vw;height: 5.5556vw;background: url(/assets/images/icon/ico-arrow-bottom.svg) no-repeat 0 0;background-size:5.5556vw;transform: translateY(-50%) rotate(180deg);}        
            }
        }
        .unit-tit {margin:0;}
        .acc-conts {
          padding:2.7778vw 0 0 0; background: none;
        }
    }
}

    // 자동결제 출금 동의 신청
    .recurring-payment{
        margin-top: 11.1111vw;
        .contents-title{display: block; margin-bottom: 1.3889vw; @include subContsTitle;}
        p {
            @include smallTxt;
            & + .payment-wrap{
                margin-top: 5.5556vw;
            }
        }
    }
    .payment-wrap{
        & > strong{@include defaultTxt;}
        .pay-box{
            display: flex; justify-content: space-between; padding: 4.1667vw 5.5556vw; border: 0.2778vw solid $gray02; border-radius: 1.9444vw; margin-top: 2.2222vw;
            .txt-vt{@include mainTxt;}
        }
        .btn-check{
            margin-top:2.7778vw;
            b{@include fontInherit; color: $gray05 !important;}
            & + .box-btn{margin-top: 5.5556vw;}
        }
        .box-table{
            border-top: 0.2778vw solid $gray02; margin-top: 5.5556vw;
        }
    }
    //내게 맞는 요금제 찾기
    .payment-find{
        .btn-radio-group{
            flex-wrap: wrap;
        }
    }
    //요금제 - 설계하기
    .payment-list-design{
        .page-img-tit {background:#f6f2ec;}
        .products-radio-box{
            .radio-group-wrap{
                &+.radio-group-wrap{margin-top: 11.1111vw;}
                &.on {
                    .group-tit {
                        color:$purple;
                        &::after {@include backgroundImg('/assets/images/icon/ico-checkbox-on.svg', right center, 5.5556vw 5.5556vw);}
                    }
                }
            }
            .box-btn{margin-top: 11.1111vw;}
            .group-tit {
                margin-bottom:2.7778vw;@include mainTxt;
                &::after {
                    content:'';display:inline-block;margin-left:1.3889vw;width:5.5556vw;height:5.5556vw;vertical-align:top;
                    @include backgroundImg('/assets/images/icon/ico-checkbox.svg', right center, 5.5556vw 5.5556vw);
                }

                /*.number{
                    display: inline-block; width: 6.3889vw; height: 6.3889vw; background-color: $purple; border-radius: 50%; text-align: center; @include defaultTxt; color: $white;line-height: 6.3889vw; margin-right: 2.7778vw;
                }*/
            }
        }
    }
    //신청불가요금제
    .payment-unable {
        .inner {padding-bottom:0;}
        .page-tit {
            p {margin-top: 0;}
        }
        .search-input-wrap{padding-top:0; margin-bottom:8.3333vw}
        .guide {display:block;margin-bottom:2.7778vw;@include smallTxt;text-align:right;}
        .tab-contents-wrap {overflow-x:hidden;overflow-y:auto;height:55vh;}
        .payment-list-card-wrap {
            .card-header {
                display: flex; justify-content: space-between; margin-bottom: 2.7778vw;
                .pay-amount {
                    padding-top: 0;
                    .undeter-pay{color: $purple;}
                }
            }
            .payment-list-card {
                &>.pay-amount {
                    margin-top:4.1667vw;padding-top:0;
                    .undeter-pay{color: $purple;}
                    &.type-item {
                        display:flex;justify-content:space-between;@include smallTxt;
                        &+.type-item {margin-top:2.7778vw;}
                    }
                }
            }
            .notification {margin-top: 4.1667vw;}
        }
    }
    //생활구독서비스
    .life-service{
        margin-bottom:19.4444vw;
        .page-img-tit{
            &.life-ch{
                background-color: #f2f3f8;
            }
        }
        .white{margin-top:6.9444vw;}
        .chip-wrap{
            .chip{
                &:first-child{background-color:$lightpurple;}
                &:nth-child(2){ background-color:$purpleblue;}
            }
        }
    }
    //생활구독서비스 - 상세
    .life-detail{
        &.sub-conts{padding-top:0;}
        .life-list-wrap{
            margin-bottom: 11.1111vw;
            .life-list{
                margin-top:0;
                .life-list-img{
                    border-radius: 0;  width:100vw; height:72.2222vw; background: #ffdede; overflow: hidden;
                }

            }
        }
        .detail-sec{
            .sub-conts{
                padding-top: 0;
            }
            .notification{
                strong{
                    display: block; @include defaultTxt; margin-top: 1.3889vw;
                }
                & + .btns{
                    margin-top: 2.7778vw;
                }
            }
        }
        .detail-sec-tit{
            margin-bottom: 5.5556vw;
            & + .notification{
                margin-top: -4.1667vw;
            }
        }
    }
    //생활구독서비스 - 이용약관
    .subscribe-agree{

        .cardList-wrap{
            .cardList-title{
                @include subContsTitle;
            }
            .cardList{
                margin-bottom: 2.7778vw;
            }
        }
        .agree-wrap{
            margin-top:6.9444vw;
            .agree-group{
                margin-top:5.5556vw; padding:0 4.1667vw;
                .btn-check{margin-bottom:4.1667vw;}
            }
        }
    }
    //주소찾기
    .subscribe-adress{
        .box-flex{
            margin-bottom:4.1667vw; padding-top: 0;
        }
        .ligt-gray-box{
            width:100%; background: $gray01; padding:5.5556vw; box-sizing: border-box; border-radius:2.7778vw; margin-bottom: 8.3333vw;
            h3{
                @include mainTxt;
            }
            .desc{
                margin-top:2.2222vw;
                @include defaultTxt;
            }
            .desc-ex{
                margin-top:2.2222vw;
                li{
                    @include smallTxt;
                }
            }
        }
        .adress-step02-title{
            display: flex;
            justify-content: space-between;
            margin-bottom:2.7778vw;
            span{
                @include mainEmphasize;
            }
            a{
                width:5.5556vw;
                height:5.5556vw;
                background: #ffdede;
                font-size:0;
            }
        }

    }
    //부가서비스 신청
    .addition-service-appli{
        .page-tit{
            span{@include fontInherit;}
        }
        section {
            &.cardList-wrap{
                margin-top: 8.3333vw;
            }
            & + section {margin-top: 11.1111vw;}
        }
        .error-msg{
            text-align: center; @include mainTxt; margin-top: 11.1111vw;
        }
    }
    .addition-service-appli-zero{
        .page-tit{
            h1{
                @include subContsTitle;
                span{
                    @include fontInherit;
                }
            }
        }
        .zero-bundle{
            margin-top: 8.3333vw;
        }
        .flex-box{
            display: flex; justify-content: space-between; gap:2.7778vw; margin-top: 2.2222vw;
        }
    }
    //요금제 - 요금제 상세 페이지
    .payment-detail-list {
        .payment-detail-desc {
            border-bottom:0.2778vw solid $gray02;padding:0 5.5556vw 11.1111vw;box-sizing:border-box;
            .title-wrap {
                display: flex; justify-content: space-between; align-items: center;
                .left {
                    .chip-wrap{margin-bottom:2.7778vw;max-width:100%;}
                    .title {margin-top: 2.7778vw;@include subContsTitle;}
                    p {margin-top:1.3889vw;}
                }
            }
            .data-box {margin-top:5.5556vw;padding:5.5556vw;border-radius:2.7778vw;background:$bg;}
            .data-list {
                margin-bottom:5.5556vw;
                li {
                    display:flex;align-items: center;
                    &+li {margin-top:2.7778;}
                    .data-tx {
                        @include smallTxt;
                        span {@include smallDepthTxt;}
                    }
                }
                &+.notification {padding-top:5.5556vw;border-top:0.2778vw solid $gray02;}
            }
            .pay-amount {padding-top:4.1667vw;}
        }
        .accordian-wrap{
            .accordian{
                &>.acc-trigger{
                    border-bottom:0; margin-top:8.3333vw; padding-bottom:0; @include subContsTitle;
                }
                .acc-conts{
                    background:$white; padding:0; padding-top:4.1667vw;
                    dt{
                        @include defaultTxt; font-weight: 600;
                    }
                    dd{
                        margin: 1.3889vw 0 2.7778vw;
                    }
                }
            }
        }
    }
    .btn-group{
        .banner-randombox-wrap{
          left: 5.5556vw; bottom: 17.3611vw;
        }
    }
    //결제하기
    .subscribe-payment{
        section {
            & + section{margin-top: 11.1111vw;}
            .contents-title{
                display: block; margin-bottom: 1.3889vw; @include subContsTitle;
            }
            p{
                @include smallTxt;
                & + .payment-wrap{margin-top: 5.5556vw;}
            }
        }
        &.point{
            section + section {margin-top:  5.5556vw;}
        }
        .payment-wrap{
            .box-table {border-top: 0;}
        }
    }
    //고객후기
    .customer-review{
        .review-ch{
            background-color: #f2f3f8;
        }
    }
    //휴대폰 인증 및 배송지 정보
    .subscribe-phone-adress{
        .sec-detail{
            .sec-tit{
                margin-bottom: 5.5556vw;
                h2{
                    @include subContsTitle;
                }
            }
            & + .sec-detail{
                margin-top: 11.1111vw;
            }
            .box-select{margin-top:5.5556vw;}
            .self-write-wrap{
                width:100%; margin-top:4.1667vw; display: none;
                p{
                    text-align: right; margin-top:1.3889vw;
                    @include smallTxt;
                    strong{
                        color:$black; font-weight: inherit; font-size: inherit;
                    }
                }
            }

        }
        .group{
            margin-top:5.5556vw;
        }
    }
    //구독신청 완료
    .subscribe-completed{
        .page-tit{
            margin-bottom: 5.5556vw;
        }
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 8.3333vw;}
            .cardList {
                dl{
                    dt{
                        width: 40%;
                        span{@include fontInherit;}
                    }
                    dd{
                        width: 60%;
                        span{@include fontInherit;}
                    }
                }
            }
            .item-bottom{
                border-top: 0.2778vw solid $gray02; padding-top: 5.5556vw;
                strong{@include defaultTxt;}
            }
        }

    }

    //통신부가서비스 - 인트로
    .addition-intro{
        &.sub-conts{padding-bottom:19.4444vw;}
        .addition-list{
            li{
                margin-top: 4.1667vw;
                .payment-imgCard-btn{
                    position: relative;
                    .item-top{
                        padding-bottom: 5.5556vw;
                        .flex-box{
                           overflow: hidden;
                        }
                        .chip-wrap{
                            float: left;
                        }
                        .status{
                            @include defaultTxt; color:$purple; text-align: right; float: right;
                        }
                        .emphasis-txt{
                            em{margin-top: 1.3889vw; @include smallTxt; display: block;}
                        }
                        .pay-amount{
                            em{margin-top: 1.3889vw; @include smallTxt;}
                        }
                    }
                    .item-bottom{
                        border-top:0.2778vw solid $gray02; padding-top: 5.5556vw;
                        & > p{
                            @include smallTxt;
                        }
                        dl{
                            align-items: center; justify-content: space-between; margin-top: 4.1667vw; margin-bottom: 8.3333vw;
                        }
                        dt{
                            width:47vw;
                            .btn-text-arrow{
                                border-top:0; padding-top:0; padding-bottom:0;
                                &.no-arrow{
                                    background-image: none;
                                }
                            }
                        }
                        dd{
                            text-align: right; width:calc(100% - 47vw);
                            .ico-img{
                                width:6.6667vw; height:6.6667vw; display: inline-block; margin-right: 2.7778vw;
                                img{
                                    width:100%;
                                }
                            }
                        }

                    }
                    .btn-group{
                        gap:2.7778vw;
                       .btns{
                            padding: 3.3333vw 0; border-radius: 1.9444vw; font-size: 4.1667vw;
                        }
                    }
                }
            }
        }
    }
    .search-result{
        @include defaultTxt; //margin-top:8.3333vw;
        strong{
            @include defaultTxt; margin-right: 1.3889vw;

        }
        span{
            color:$purple; font-weight: 600; font-size: 3.8889vw;
        }
    }
    //휴대폰 분실 / 파손 보험
    .breakage-list{
        .full-tab {padding:0;}
        .unit{
            margin-bottom: 11.1111vw;
            .clr-gray {color:#666;}            
            .page-tit{
                margin:11.1111vw 0 4.1667vw;
            }           
            .count-tit{
                margin-top:8.3333vw;margin-bottom:1.3889vw;@include mainTxt;
                & + strong{
                    display: block;margin: 1.3889vw 0;@include defaultTxt;font-weight: 400;
                }
            }
            &.unit01 {
                .accordian {
                    margin-top:5.5556vw;
                    .count-tit {margin-top:0; font-size:5vw;}                    
                }
                .count-tit {
                    font-size:5vw;
                    & + .count-stit {margin-top:5.5556vw;}
                }                  
                .count-stit {
                    margin:8.3333vw 0 4.1667vw 0; font-size:4.4444vw; font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;                   
                    &.txt-dt {
                        position:relative;padding-left:2.2222vw;
                        &::before {content:'';position: absolute; left:0;top:2.0833vw;width:0.8333vw;height:0.8333vw; background:#191919;border-radius: 50%;}
                    }
                    &.notice-tit {margin-bottom:2.7778vw;}
                }
                .page-tit.breakage{
                    h1 {margin-bottom:6.9444vw;}
                    p {font-size: 4.4444vw; line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                    .s-tit {font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                    .notification {
                        margin-top:2.7778vw;
                        li {
                            font-size: 4.4444vw; font-weight: 600; line-height: 1.31; letter-spacing: -0.0889vw; text-align: left;color: #191919;
                            &::before {background:#191919;}
                            .txt-emp {margin-top:0.6944vw; font-size:4.1667vw; font-weight: 600; line-height: 1.31; letter-spacing: -0.0889vw; text-align: left;}
                        }
                    }
                }
                .page-tit {margin-top:0;}
                .count-tit {margin-top:11.1111vw;}
                .ico-wrap {
                    .ico-img {margin:0 auto; width:55.5556vw;}
                    .info-txt {margin-top:5.5556vw; @include smallTxt;}
                }
                .notification {
                    li {
                        .btn-group {
                            gap:2.7778vw;margin-bottom:5.5556vw;
                            .btns{
                                padding: 3.4722vw 4.1667vw; border-radius:2.7778vw; text-align: left; @include defaultTxt; position: relative;
                                &::after{
                                    content:'';width:5.5556vw;height:5.5556vw;display:block;position:absolute;top:50%;right:3.4722vw;transform:translateY(-50%);
                                    @include backgroundImg('/assets/images/icon/ico-call-bk.svg',center center,contain);
                                }
                            }
                        }
                    }
                }
                .btns {margin-top:2.7778vw;}
                .box-select {margin-top:11.1111vw;}
            }
            .one-title {
                dt {
                    strong { @include defaultTxt; margin-bottom: 2.7778vw; display: block;}
                }
            }
            .underLine {
                padding:0 5.5556vw;
                dl {
                    border-bottom:0.2778vw solid $gray02;padding:5.5556vw 0;align-items: center;
                    &:last-child {border-bottom:0;}
                    dt {width:16.6667vw;}
                    dd {
                        width:calc(100% - 16.6667vw); padding-left:2.7778vw;@include defaultTxt; font-weight: 400;
                        strong{@include defaultTxt;}
                    }
                }
                .img-icon {
                    dl {
                        dt {
                            width:16.6667vw;
                            img {width:16.6667vw; height: 16.6667vw;}
                        }
                        dd {
                            width:calc(100% - 16.6667vw);
                            strong {@include defaultTxt;}
                        }
                        &:last-child {border-bottom:0;}
                    }
                }
                &.one-title {
                    dt {width:0;}
                    dd {width:100%;}
                }
            }
            .info-txt {@include smallTxt;}
            .cardList-wrap {margin-top: 5.5556vw; margin-bottom: 8.3333vw;}
            .table-list-wrap{
                margin-top: 5.5556vw;
                .table-list {
                    thead {
                        th {border-bottom:0; }
                    }
                    tbody {
                        th {background:none; border-bottom: 0.2778vw solid $gray02;}
                    }
                    th, td {padding:5.5556vw 1.3889vw 5.5556vw 4.1667vw;}
                    &+.table-list {margin-top:-1px;}
                }
            }
            .btn-text-num-wrap {
                margin-top: 8.3333vw;
                .btn-text-arrow {margin-top: 4.1667vw;}
            }
            .num-process-wrap {
                &+.notification {margin-top:2.7778vw;}
            }
            .balloon-list {
                li {
                    display:block;
                    &+li {margin-top:20px;}
                    p {
                        position:relative;padding:4.1667vw;border-radius:2.7778vw;background:#f8f4ff;@include smallTxt;color:$black;
                        &::after {
                            content:'';position:absolute;left:2.7778vw;bottom:-2.7778vw;display:block;width:2.7778vw;height:2.7778vw;
                            @include backgroundImg('/assets/images/subpage/balloon-list-arroB.png', center, 2.7778vw 2.7778vw);
                        }
                    }
                }
            }
            .list-box {
                .count-stit {margin:5.5556vw 0 1.3889vw 0;}
                .notification {margin-top:1.3889vw;}
            }
        }
        .accordian{
            .acc-trigger{
                &::after{bottom: -0.625vw; top: unset;}
            }
        }
        .qa-bx {
            position: relative;margin-top:11.1111vw;
            a{display: flex;gap:20px;align-items: center; padding: 5.5556vw; border-radius: 2.7778vw; border: solid 0.2778vw #ddd; background-color: #fff;}
            .img-qa {width:16.6667vw;}
            dl {                   
                dt {margin-bottom:0.6944vw; font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                dd {font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #666;}
            }
            .ico-arrow-right {position:absolute; right:5.5556vw;top:50%;transform: translateY(-50%);}
        }        
    }
    .breakage-insurance-step02{
        .notification{
            margin-top: 2.7778vw;
        }
    }
    //이용안내 - 셀프 개통
    .use-self-opening{
        .btn-list{
            margin-top:2.7778vw; margin-bottom:5.5556vw;
            li{
                margin-bottom: 2.7778vw;
            }
        }
        .one-title{
            .btn-text-arrow{
                margin-top: 4.1667vw;
            }
        }
        .c-unit {
            .unit-tit {margin-bottom:8.3333vw;}
            &.list-seq {
                margin-top:8.3333vw;
                .unit-sec-tit {margin-bottom:4.1667vw;}
            }
        }
    }
    //이용안내 - 바로픽업
    .use-pickup {
        .c-unit {
            &.list-seq {
                &+.list-seq { margin-top:8.3333vw;}
                .unit-tit {margin-bottom:5.5556vw;}
                .unit-sec-tit {margin-bottom:4.1667vw;}
            }
        }
    }
    // 이용안내 - 휴대폰 정보
    .use-phone-info{
        .c-unit {
            .unit-sec-tit {
                &+h3 {display:block;margin:5.5556vw 0 1.3889vw;@include defaultTxt;}
            }
            &.list-seq {
                &+.list-seq { margin-top:8.3333vw;}
                .unit-tit {margin-bottom:5.5556vw;}
                .unit-sec-tit {margin-bottom:4.1667vw;}
            }
            &.unit-slide{
                padding-top:11.1111vw; margin-top:11.1111vw; border-top:.2778vw solid #ececec;
                .info-reg-benefit {
                     .cardList {
                        dl {
                            dd {color:#666; font-weight: normal;}
                        }
                     }
                }
            }
        }
        .btn-list {
            li { margin-top:2.7778vw;}
        }
        .page-tit {margin-bottom:5.5556vw;}
        .vdo-box {height:50vw;margin:5.5556vw 0 4.1667vw 0;}
    }
    //제휴카드 할인
    .use-card{
        //목록
        &-list{
            .card-list{
                li{
                    a{
                        position: relative; justify-content: flex-start; padding-right: 4.1667vw; gap:2.7778vw;
                        .card-img-wrap{
                            flex: none; width: 27.7778vw; flex-shrink: 0;
                        }
                        .card-desc{
                            margin-left: 0;
                            strong{
                                padding-right: 40px;
                            }
                        }
                        .ico-arrow-right{
                            position: absolute; right: 5.5556vw; top: 5.5556vw;
                        }
                    }
                }
            }
        }
        //상세
        &-detail {
            .card-thumbnail{
                display: flex; justify-content: center; align-items: center; height: 72.2222vw; background-color: $bg; overflow: hidden;
                img{width: 100%; max-width: 55.5556vw;}
            }
            .card-txt{
                margin-top: 5.5556vw;
                .contents-title{
                    display: block;
                    & + span{display: block; margin-top: 1.3889vw;}
                }
            }
            .box-table{
                margin-top: 8.3333vw;
                & + .box-btn {margin-top: 5.5556vw;}
            }
            .box-tab{
                margin-top: 11.1111vw;
            }
        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-detail{
                display: flex; flex-direction: column; gap: 11.1111vw;
                &-item {
                    .item-top{
                        padding-bottom: 0; gap: 5.5556vw; align-items: center;margin-bottom:5.5556vw;
                        .img-wrap{
                            width: 25vw; overflow: hidden;
                            img{
                                width: 100%;
                            }
                            & + strong{@include subContsTitle;}
                        }
                    }
                    .box-table{
                        td{
                            text-align: center;padding: 5.5556vw 0;
                            &:first-of-type{color: $gray05;}
                        }
                    }
                }
            }
        }
    }
    //가입정보 입력[step03]
    .breakage-insurance-step03{
        .group{
            &:nth-of-type(n+3){margin-top:8.3333vw;}
            .notification{
                margin-top: 2.7778vw;
            }
        }
        .cardList-wrap{

            dl {
                dt {width: 40%;}
                dd {width: 60%;}
            }
        }
        .radio-group{
            display: flex; gap: 11.1111vw; margin-top: 4.1667vw;
            .btn-radio-type2{width: auto;}
        }
    }
    //가입정보 입력[step04]
    .breakage-insurance-step04{
        .cardList-wrap{
            dl {
                dt {width: 40%;}
                dd {width: 60%;}
            }
        }
        .box-btn{
            & + .cardList {margin-top: 4.1667vw;}
        }
    }
    //보험가입신청완료
    .breakage-insurance-finish {
        .box-table {
            .table-title {margin-bottom:2.7778vw;@include mainTxt;}
            &+.box-table {margin-top:8.3333vw;}
        }
        .text-info {
            margin-bottom:8.3333vw;
            .small-txt {margin-top:1.3889vw;}
        }
    }
    //첨부 가능한 영수증 예시
    .breakage-receipt{
        ol{
            display: flex; gap: 5.5556vw; flex-direction: column;
            li{
                img{display: block; margin-top: 1.3889vw;}
            }
        }
    }
    //배송안내
    .use-shipping-info{
        .cardList-wrap{
                    .cardList-title{
            @include subContsTitle;
        }
            .notification{
                margin-top: 2.7778vw;
            }
            & + .cardList-wrap{
                margin-top: 11.1111vw;
            }
        }
        .c-unit{
            margin-top: 11.1111vw;
            .btn-text-arrow{
                margin-top: 2.7778vw;
            }
            .info-txt{
                margin-top: 2.7778vw;
                @include smallTxt;
            }
        }
    }
    //바로픽업 - 선택약정 할인
    .use-select-discount{
        .one-title{
            margin-top: 5.5556vw;
            dl{
                &+dl{ border-top:0.2778vw solid $gray02; padding-top:5.5556vw; margin-top: 5.5556vw;}
                dt {
                    &>p {margin-top: 2.7778vw;}
                    .desc {margin-top: 2.7778vw;@include smallDepthTxt;}
                    .btn-text-arrow{margin-top: 2.7778vw;}
                }
            }
        }
        .unit-img {
            margin:8.3333vw 0;
            &+.notification {margin-top: 8.3333vw;}
        }
        .unit-tit02{margin-top:4.1667vw;margin-bottom:1.3889vw;}
        .accordian{
            .acc-trigger{padding-bottom: 0;}
            .acc-conts{
                background: none; padding: 0;
                .unit-sec-tit {
                    margin:8.3333vw 0 1.3889vw;
                    &:first-of-type {margin-top:0;}
                }
                .table-list-wrap {margin-top:5.5556vw;}
            }
        }
    }

    .evnt-box {
        margin-bottom:11.1111vw; padding: 4.1667vw; border-radius: 1.9444vw; background-color: #eff1f5;
        p {  font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
        a {margin-top:3.4722vw; font-size: 4.1667vw; font-weight: 500; line-height: 1.6;  letter-spacing: -0.0833vw; text-align: left; color: #000;}
    }

    .roaming-abroad {
        &.sub-conts {
            padding-bottom:0;
        }
        .addition-wrap {
            .accordian {
                .unit-tit {margin-bottom:0;}
                .acc-conts  {padding:0;background: none;}
            }
            .tite {margin-bottom:1.3889vw;font-size: 5vw; font-weight: 600; line-height: 1.11; letter-spacing: -0.1vw; text-align: left; color: #191919;}
            .sb-txt {font-size: 4.1667vw; line-height: 1.27; letter-spacing: -0.0833vw;text-align: left;color: #666;}
            .addition-list {
                .plan-box {
                    display: flex;justify-content: space-between; margin-top:2.7778vw;
                    .plan-tit {
                        a {font-size: 4.4444vw;font-weight: 600;line-height:1.2;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                        .ico-arrow-right {width:5.5556vw;height:5.5556vw; vertical-align: top; background-size:5.5556vw; background-position: 100% 50%;}
                    }
                    .plan-pric {
                        text-align: right;font-size: 4.1667vw; line-height: 1.23;letter-spacing: -0.0833vw;text-align: right;color: #666;
                        strong {display:block;font-size: 4.4444vw; font-weight: 600; line-height: 1.5; letter-spacing: -0.1333vw; text-align: right; color: #662d91;}
                    }
                }
                .plan-spc {
                    margin-top:4.1667vw;padding: 4.1667vw; border-radius: 2.7778vw; background-color: #f7f8fa;
                    .spc-list {
                        display: flex; align-items: center;
                        li {
                            width:33%;margin:0; text-align:center;font-size: 3.8889vw;  line-height: 1.43; letter-spacing: -0.0778vw;color: #191919;
                            img {width:5.5556vw; vertical-align: top}
                        }
                    }
                }
                .btn-group{
                    margin-top:5.5556vw;                 
                   .btns{
                        padding: 3.3333vw 0; border-radius: 1.9444vw; font-size: 4.1667vw;
                    }
                }               
            }
            .guide-msg {
                margin-top:11.1111vw;
                .txt { font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}                
            } 
            .link-bx {
                margin-top:4.1667vw;
                a + a {margin-top:4.1667vw;}
            }
            .img-list {
                padding:0 5.5556vw;border-radius:2.7778vw;border:solid 0.2778vw $gray02;
                li {
                    a{
                        display: flex; padding:5.5556vw 0; align-items: center; width:100%;
                    }
                    &+li {border-top:solid 0.2778vw $gray02;}
                    .img {
                        flex-shrink:0; overflow:hidden;width:16.6667vw;
                        img {display: block;width: 100%;}
                        &.img-round {border:0.2778vw solid $gray02;border-radius:1.9444vw;}
                    }
                    .info {
                        flex-grow:1; padding-left:2.7778vw; width: calc(100% - 25vw);
                        .tit {display:block;@include defaultTxt;}
                        .txt {display:block; margin-top:0.6944vw; @include smallTxt;}
                    }
                    .ico-arrow-right{width:5.5556vw; height: 5.5556vw; display: block;}
                    &.none{
                        display: flex; padding:5.5556vw 0; align-items: center; width:100%;
                    }
                }
            }
            .guide-img  {
                & + .guide-img {margin-top:4.1667vw;}
                & + .unit-sec-tit {margin-top:8.3333vw;}
            }    
            .qa-bx {
                position: relative;
                a{display: flex;gap:20px;align-items: center; padding: 5.5556vw; border-radius: 2.7778vw; border: solid 0.2778vw #ddd; background-color: #fff;}
                .img-qa {width:16.6667vw;}
                dl {                   
                    dt {margin-bottom:0.6944vw; font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    dd {font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #666;}
                }
                .ico-arrow-right {position:absolute; right:5.5556vw;top:50%;transform: translateY(-50%);}
            }        
        } 
           
        .roaming-abroad-guide {
            .box-tab.full-tab {
                margin:0;padding:0;
                .tab-btn-wrap li a {padding: 2.5vw 4.1389vw;}
            }
            .guide-bx {
                margin-top:8.3333vw; padding: 8.3333vw 5.5556vw;border-radius: 2.7778vw;background-color: #f9f5ff;
                .tite {margin-bottom:0.6944vw;font-size: 5vw;font-weight: 600; line-height: 1.33; letter-spacing: -0.1vw;text-align: left;color: #191919;}
                .txt { font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left; color: #666;}
                .btns {margin-top:5.5556vw;}
                & + .guide-bx  {margin-top:4.1667vw;}
            }
            .accordian  {
                margin-top:11.1111vw;
                .acc-trigger {
                    margin-bottom:5.5556vw;
                    .unit-tit {margin:0;}                    
                }
                .acc-conts {                   
                    .guide-img {margin-bottom:8.3333vw;}
                    .tab-contents-wrap {padding-bottom:10.1389vw}
                    .slide-box {                        
                        .tit {display: block; margin-bottom:0.6944vw; font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #191919;}
                        .txt { font-size: 4.1667vw;line-height: 1.2;letter-spacing: -0.3083vw;text-align: left; color: #666;}
                        .slick-default {
                            min-width:88.8889vw;min-height:117.5vw; margin:2.7778vw 0;
                            .slide {padding:0 16.6667vw; background: #fafafa;}
                            .slick-dots {bottom:-38.1389vw;}
                            &.dot-type {
                                .slick-dots {bottom:-24.7222vw;}
                            }
                        }    
                    }
                }
            }
            .guide-msg {
                margin-top:11.1111vw;
                .tite {margin-bottom:1.3889vw;font-size: 5vw; font-weight: 600; line-height: 1.11; letter-spacing: -0.1vw; text-align: left; color: #191919;}
                .txt { font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}                
            } 
            .link-bx {
                margin-top:4.1667vw;
                a + a {margin-top:4.1667vw;}
            }
        }    
    }

}

.compare-list-view {
    .banner-wrp {position:fixed;left:0;top:0;width:100%; padding:28.4444vw 5.5556vw 0;z-index: 10;background-color: #fff;}
    .call-dibs {
        position:relative; padding:5.5556vw;border-radius: 2.7778vw;background-color: #f4f6ff;
        p {
            width:51.3889vw; font-size: 4.7222vw;line-height: 1.29;letter-spacing: -0.0944vw;text-align: left;color: #191919;
            strong {display:block; font-size:4.7222vw;font-weight:bold;color:#191919;letter-spacing: -0.0944vw}
        }
        img {position:absolute;right:0;top:0; width:31.9444vw;}
        .btn {
            width: 100%;height: 13.8889vw;margin-top:4.1667vw; text-align: center; border-radius: 13.8889vw; background-color: #7385d8;
            span {
                position:relative; padding-right:8.3333vw; font-size: 4.4444vw;font-weight: 600;line-height: 13.8889vw;letter-spacing: -0.64px;color: #fff;
                &::after {content:''; position: absolute; right:0;top:0;width:5.5556vw;height:5.5556vw;background:url('/assets/images/icon/icon-compare-arrow.png') no-repeat 50%;background-size: 5.5556vw; }
            }
        }
    }
    .compare-list {
        margin-top:54.1667vw;
        .txt { font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
        .list {
            margin:2.7778vw 0 0 0;border:0;
            li { 
                padding: 8.3333vw 5.5556vw;border-radius: 2.7778vw;border: solid 0.2778vw #ddd;background-color: #fff;
                & + li {margin-top:4.1667vw;} 
                .tite {font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                .price-box {
                    display: flex;justify-content: space-between;align-items: center; margin-top:6.25vw;
                    span { font-size: 4.1667vw;font-weight: 600;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    strong { font-size: 5.5556vw;font-weight: bold;letter-spacing: -0.1111vw;text-align: right;color: #662d91;}
                }
                .feature {
                    margin:4.1667vw 0 0 0;padding:4.5833vw 0 4.5833vw 4.1667vw;border-radius: 2.7778vw; background:#f7f8fa;
                    span{
                        margin:0;font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.0778vw; text-align: left;color: #191919;
                        & + span {margin-left:3.4722vw; }                
                    }
                   span.vol::before {background:url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center;background-size:5.5556vw;}
                   span.limit::before {background:url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size:5.5556vw;}
                   span.supply::before {background:url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center;background-size:5.5556vw;}
                }
                .btn-box {margin-top:4.1667vw;}
                .benefit-view {
                    margin-top:8.3333vw;
                    .tit {
                        display: block;font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0889vw;text-align: left;color: #191919;
                        span {font-size:4.4444vw; color: #191919;}
                    }
                    dl {
                        margin-top:2.7778vw;
                        dt { font-size: 4.1667vw;font-weight: 600;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        dd {
                            position: relative;margin-top:1.3889vw;padding-left:2.0833vw; font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: left;color: #666;
                            &::before {content:''; position: absolute; left:0; top:50%;width:0.8333vw;height:0.8333vw;margin-top:-0.4167vw;border-radius: 50%; background: #666;}
                        }
                    }
                    .no-results {margin-top:4.1667vw;font-size: 4.1667vw;font-weight: 600;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                }
            }
        }
    }


}

.interest-plan {
    .page-desc h2 {font-size: 5vw;font-weight: 600;line-height: 1.17;letter-spacing: -0.1vw;text-align: left;color: #191919;}
    .btn-box {margin-top:4.1667vw;}
    .interest-view  {
        margin-top:11.1111vw;
        .box-input {
            & + .box-input {margin-top:11.1111vw;}
            & + section {margin-top:5.5556vw;}
        }
        section {
            margin-top:11.1111vw;
            .tit {margin-bottom:2.0833vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
        }
        .plan-list {            
             .list-view { 
                padding: 5.5556vw;border-radius: 2.7778vw; border: solid 0.2778vw #ddd;background-color: #fff;
                .list-bunch {
                    & + .list-bunch {margin-top:4.8611vw;}
                    strong {display:block;margin-bottom:2.7778vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
                    ul {
                        li {
                            font-size: 4.1667vw;font-weight: 600;line-height: 1.6;letter-spacing: -0.0833vw;text-align: left;color: #191919;
                            & + li {margin-top:0.6944vw;}
                        }
                    }
                }
            }
        }
        .agree-group {
            .btn-check {
                & + .btn-check {margin-top:4.1667vw;}
            }
        }
        .agree-wrap {
            margin-top:11.1111vw;
            .agree-group {
                .ico-check {margin-top:-8.3333vw}
                label {color:#191919;}
                .btn-check + .btn-check {
                    .ico-check {margin-top:-5.2778vw}
                    .ico-arrow-right {margin-top:-0.8333vw;}
                }
                .btn-check input[type=checkbox]:disabled+label span::after {transform: translateY(0);}
            }
        }
    }
}

//간편인증 정보
.self-simple-bottomSheet{
    .input-interval{
        li{
            & + li{
                 margin-top: 5.5556vw;
            }
        }
    }
}

//요금제 필터 바텀시트
.payment-list-bottomSheet{
    .popup-body{
        padding-top:0 !important;
    }
    .popup-footer{
        .count{
            @include normalTxt; color:$white;
        }
    }
}
//기준일 선택
.breakage-baseDate-bottomSheet{
    .radio-list{
        .btn-radio{
            width:100%;
            label {padding:2.7778vw 4.1667vw;text-align:left;}
        }
    }

}
//신청 불가 레이어 팝업
.breakage-noApply-layerPopup{
    .popup-header{
        text-align: center;
    }
    .layer-popup-item{
        .popup-body{
            text-align: left;
        }
    }
}
//분실/파손보험 선택 - 약관동의[바텀시트]
.breakage-agreement-bottom{
    .btn-check{
        & + p {
            margin-top: 2.7778vw;
        }
        & ~ .box-btn{
            margin-top: 5.5556vw;
        }
    }
}

//요금제 - 내게 맞는 요금제 찾기 결과[바텀시트]
.payment-result{
    .popup-body{
        padding: 5.5556vw 0 8.3333vw 0 !important;
        .result-wrap{
            padding: 0 5.5556vw 0;
        }
        .main-txt{display: block; margin-bottom: 4.1667vw;}
        .result-txt{@include defaultTxt;}
    }
    .result-slider-wrap{
        margin-top: 5.5556vw;
        // &.wide-box{margin-left: unset;}
        .slick-center-mode {
            .slick-slide{
                max-height: none; margin-left: -1.3889vw !important; margin-right: -1.3889vw !important;
            }
        }
        .slick-slider {
            .slick-slide {width:83.3333vw;padding-left:5.5556vw;}
        }
        .cardList-wrap {
            .cardList {
                width:100%;padding:8.3333vw 5.5556vw;
                .product-name{
                    margin-bottom: 2.7778vw;
                    span {display:block;@include smallTxt;}
                    strong {display:block;margin-top:1.3889vw;@include mainTxt;}
                }
                .pay-amount {
                    padding-top:4.1667vw;
                    .origin-pay {color:$gray04;}
                }
                .box-btn {margin-top:4.3333vw;}
                .feature{
                    padding: 2.125vw; background-color: #f7f8fa; border-radius: 2.7778vw; margin: 2.125vw 0 0 0;
                    span{
                        font-size: 3.0556vw;
                        &.limit{
                            &::before{background: url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size: 100%;}
                        }
                        &.vol{
                            &::before{background: url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center; background-size: 100%;}
                        }
                        &.supply{
                            &::before{background: url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center; background-size: 100%;}
                        }
                    }
                }
            }
        }
    }
}


.plan-detail{
    padding-top: 11.1111vw;
    section{
        &+section{
            margin-top: 9.7222vw;
        }
        .b-tt{
            margin-bottom: 0; @include subContsTitle;
        }
        .m-tt{
            margin-top: 2.7778vw;  @include defaultTxt;
        }
        .notification{
            margin-top: 1.3889vw;
        }
        .txt{
            margin-top: 1.3889vw;
        }
    }
}

.plan-find  {   
    .plan-popup {   
        &.intro-page {
            .btn-group {                                                
                .btn-previous {display:none;}     
            }     
        }
        .inner {  
            padding:16.666vw 5.5556vw 27.7778vw 5.5556vw;            
            .stp-list { 
                padding-bottom:30.9444vw;
                .stp {
                    position: relative;
                    .character-obj {overflow: hidden;width: 61.1111vw;height:61.1111vw;position: absolute;left:50%;top: 20.8333vw;margin-left:-30.5556vw;border-radius: 50%;transform: scale(0.70);transition:all 1.3s cubic-bezier(0.34, 1.56, 0.64, 1);background: url('/assets/images/subpage/img-plan-search-obj01.png') no-repeat 0 0;background-size:244.4444vw; animation: marche 2.8s infinite steps(4);
                        &::after {content:'';position: absolute;left:0;top:0;width:61.1111vw;height:61.1111vw; background: url('/assets/images/subpage/img-plan-search-obj03.png') no-repeat 0 0;background-size: 61.1111vw;  z-index: 10; }  
                        &::before {content:'';position: absolute;left:0;top:0;width:61.1111vw;height:61.1111vw; background: url('/assets/images/subpage/img-plan-search-obj02.png') no-repeat 0 0; background-size:61.1111vw;  animation: glasses 2.5s ease-in infinite alternate both;z-index: 5;}   
                    }
                    .stp-tite {
                        margin-top:11.1111vw; line-height: 1.26;font-size: 5.2778vw;font-weight: bold; letter-spacing: -0.1056vw;text-align: left;color: #191919;
                        strong { font-size: 5.2778vw;font-weight: bold;;color:#662d91;}
                    }
                    &.intro {   
                        .stp-inner {  
                            .step-box  {display:none;}                           
                            .intro-txt {
                                padding-top:11.1111vw; font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: left;color: #666;              
                                strong {display:block;margin-bottom:1.3889vw; font-size: 5.2778vw;font-weight: bold;letter-spacing: -0.1056vw;text-align: left;color: #191919;}
                            }                            
                            .character-obj {
                                top:34.7222vw; transform: scale(1);
                            }  
                            .stp-tite {display: none;}
                            &.show { visibility: visible;opacity: 1;}                                                   
                        }                       
                        .reply-list {visibility: hidden; opacity: 0; transform: translateY(27.7778vw); }
                                                
                        &.show {
                            .intro-txt {display:none;}
                            .character-obj {top:20.8333vw;transform: scale(0.70); transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);}
                            .reply-list {visibility: visible; z-index: 1; opacity: 1;transform: translateY(0); transition: all .8s cubic-bezier(0.34, 1.56, 0.64, 1);}
                            .btn-group {
                                .btn-plan {display:none;}
                                .btn-previous {display:block;}
                            }  
                            .step-box  {display:block;} 
                            .stp-tite {display: block;}
                        }           
                    }                

                    .step-box {
                        overflow:hidden;height:4px;margin:0 -5.5556vw; background:#ddd;
                        li { 
                            float:left; height:100%;
                            &.check {background:#662d91;}
                        }
                        &.step03 {
                            li {width:33.33%;}
                        }
                        &.step04 {
                            li {width:25%;}
                        }
                        &.step05 {
                            li {width:20%;}
                        }
                    }    

                    .reply-list {
                        overflow-x: hidden;overflow-y: auto; height:72.2222vw; margin-top:63.8889vw; width: 100%; z-index:1; transition: all .5s;
                        &::-webkit-scrollbar{width: 0.6944vw;}
                        &::-webkit-scrollbar-thumb{height: 40%;background-color: rgba(221,221,221,1);border-radius: 1.3889vw; }
                        &::-webkit-scrollbar-track{background-color:rgba(221,221,221,0.4);}      
                        .btn-radio-group {
                            display: flex; flex: 1; gap: 10px; flex-wrap: wrap; flex-direction: column;
                            li {
                                width:100%;text-align: center;
                                label {
                                    width:100%;font-weight:600;
                                    strong {color:#191919;font-weight:600;}
                                }
                                input[type=radio]:checked+label {border: 1px solid #ddd; color: #666;}
                            }
                        }
                    }
                
                    @keyframes marche {
                        from { background-position: 0 0; }
                        to { background-position: -244.4444vw 0; }
                    }
                    
                    @keyframes glasses {
                        0%,
                        20% {   
                        transform: scale(1);        
                        }    
                        80% {
                        transform: scale(0.8);
                        }
                    }        
                }
            }
            .find-list {
                .fee-list {
                    &>li {position:relative;top:5.5556vw;padding:8.3333vw 5.5556vw 5.5556vw;border:1px solid #ddd; border-radius: 2.7778vw;background:#fff;}
                    &>li.on {visibility: visible; opacity: 1; top: 0;}
                    &>li + li {margin-top:4.1667vw;}
                    li{
                      position: relative;
                      .bge {display:block;width:21.5278vw;}
                      .fee-tit {display:block; margin-top:2.0833vw;font-size: 4.4444vw; font-weight: 600;ne-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                      .pay-amount {
                          margin-top:4.1667vw;padding:0;text-align: right;
                          .origin-pay {font-size:4.1667vw; color:#959595;text-decoration:ne-through;}
                      }
                      .txt-limited {margin-top:1.3889vw; font-size: 3.6111vw; line-height: 1.19; letter-spacing: -0.0722vw; text-align: right; color: #959595;}                
                      .feature {
                          margin:4.1667vw 0 0 0;padding:4.5833vw 0 4.5833vw 4.1667vw;border-radius: 2.7778vw; background:#f7f8fa;
                          span{
                              margin:0;font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.0778vw; text-align: left;color: #191919;
                              & + span {margin-left:3.4722vw; }                
                          }
                         span.vol::before {background:url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center;background-size:5.5556vw;}
                         span.limit::before {background:url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size:5.5556vw;}
                         span.supply::before {background:url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center;background-size:5.5556vw;}
                      }
                      .notification {margin-top:4.1667vw;}
                      .notification li {  font-size: 3.8889vw;line-height: 1.43; letter-spacing: -0.0778vw; text-align: left; color: #959595;}
              
                      .compare-box {
                          position:absolute;right:-1px;top:-1px;border:0.1389vw solid #ddd; border-radius: 0px 2.7778vw 0px 2.7778vw; text-align:center;background:#fff;
                          span {
                              position: relative; display:block;width:23.75vw;height:8.0556vw;padding-right:4.1667vw; font-size: 3.8889vw;font-weight: 600;line-height: 8.0556vw;letter-spacing: -0.0778vw;text-align: center;color: #662d91;
                              &::after {content:''; position:absolute;right:2.7778vw;top:50%;width:4.1667vw;height:4.1667vw;margin-top:-2.0833vw; background: url('/assets/images/icon/icon-plus.png') no-repeat center;background-size:4.1667vw ;}
                          }
                          button.checked {
                             span {
                              color:#bbb;
                              &::after {background: url('/assets/images/icon/icon-plus-gray.png') no-repeat center; background-size:4.1667vw ;}
                          }
                          }
                      }
                    }
                }
            }
        }
    }   
}

.breakage-instruction-pop {
    .insure-typ {
        .txt {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
        .btn-radio-group {
            flex-direction: column; margin-top:4.1667vw;
            li {
                width:100%;
                label {
                    width:100%; flex-direction: column;font-weight:normal;
                    strong {display:block; font-weight: 600;}
                }
                input[type=radio]:checked+label {
                    strong{color:#662d91}
                }
            }
        }
    }
}


.roaming-notice {
    .notice-bx {
        .tit {margin-bottom:2.7778vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.2;letter-spacing: -0.0556vw;text-align: left;color: #191919;}
        .txt {
            margin-top:1.3889vw;padding-left:3.5556vw;
            span {display: inline-block; text-indent: -3.5556vw;}
        }
        & + .notice-bx {margin-top:8.3333vw;}
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {

//공통요소
.products {
    .sub-conts{
        &.pt-0 {
            padding-top: 0;
        }
    }
    // 부가서비스 상세 cms 영역 마진
    .detail-contents-wrap{
        margin-top: 5.7143vw;
        & + .box-btn{margin-top: 5.7143vw;}
    }
    // 부가서비스 상세 공통 카드영역
    .payment-list-card-wrap.addition-service-card{
        .payment-list-card{
            .default-txt{display: block; margin-top: 2.1429vw;}
            p{margin-top: 0.7143vw;  word-break: keep-all;}
            .icon-wrap{
                img{
                    display: inline-block; width: 5.7143vw;
                    & + img{margin-left: 1.4286vw;}
                }
            }
            .pay-amount{
                align-items: center; padding-top: 2.8571vw; gap: 0.7143vw;
                &.is-icon{justify-content: space-between;}
                .discount-pay{ color:$purple}
            }

        }
    }
    .noti-section{
        margin-top: 5.7143vw;
        .sub-contents-title{display: block; margin-bottom: 0.7143vw;}
    }
    //유심 요금제 - 추천요금제,전체요금제
    .page-img-tit {
        display:flex;justify-content:space-between;align-items:center;gap:2.1429vw;margin-bottom:5.7143vw;padding-left: 2.8571vw;
        h1 {display:block;font-weight:normal;}
        img {width: 21.4286vw;}
    }
    .payment-list{
        .page-img-tit {background:#fcfaea;}
        .is-filter-warp {
            margin-bottom: 5.7143vw;
            .is-filter-list {width:51.4286vw;}
        }
        .life-list-wrap {
            margin-bottom: 5.7143vw;
            .payment-accordian-card-wrap {
                margin-top:5.7143vw;
                &:first-of-type {margin-top:2.8571vw;}
            }
        }
        .pay-amount {
            .origin-pay {color:$gray04;}
        }
    }

    .payment-recommend-list {
        .page-img-tit {
            &.usim-ch{
                background:#effbf5;
            }
            &.esim-ch{
                background: #f8f4ff;
            }
            &.phone-ch{
                background: #f5f8ff;
            }
        }
    }
    // 자동결제 출금 동의 신청
    .recurring-payment{
        margin-top: 5.7143vw;
        .contents-title{display: block; margin-bottom: 0.7143vw;}
        p {
            & + .payment-wrap{
                margin-top: 2.8571vw;
            }
        }
    }
    .payment-wrap{
        .pay-box{
            display: flex; justify-content: space-between; padding: 2.1429vw 2.8571vw; border: 0.1429vw solid $gray02; border-radius: 1.0000vw; margin-top: 1.1429vw;
            .txt-vt{@include mainTxt;}
        }
        .btn-check{
            margin-top:1.4286vw;
            b{color: $gray05 !important;}
            & + .box-btn{margin-top: 2.8571vw;}
        }
        .box-table{
            border-top: 0.1429vw solid $gray02; margin-top: 2.8571vw;
        }
    }
    //내게 맞는 요금제 찾기
    .payment-find{
        .btn-radio-group{
            flex-wrap: wrap;
        }
    }
    //요금제 - 설계하기
    .payment-list-design{
        .page-img-tit {background:#f6f2ec;}
        .products-radio-box{
            .radio-group-wrap{
                &+.radio-group-wrap{margin-top: 5.7143vw;}
                &.on {
                    .group-tit {
                        color:$purple;
                        &::after {@include backgroundImg('/assets/images/icon/ico-checkbox-on.svg', right center, 2.8571vw 2.8571vw);}
                    }
                }
            }
            .box-btn{margin-top: 5.7143vw;}
            .group-tit {
                margin-bottom:1.4286vw;
                &::after {
                    content:'';display:inline-block;margin-left:0.7143vw;width:2.8571vw;height:2.8571vw;vertical-align:top;
                    @include backgroundImg('/assets/images/icon/ico-checkbox.svg', right center, 2.8571vw 2.8571vw);
                }

            }
        }
    }
    //신청불가요금제
    .payment-unable {
        .inner {padding-bottom:0;}
        .page-tit {
            p {margin-top: 0;}
        }
        .guide {display:block;margin-bottom:1.4286vw;text-align:right;}
        .tab-contents-wrap {overflow-x:hidden;overflow-y:auto;height:55vh;}
        .payment-list-card-wrap {
            .card-header {
                display: flex; justify-content: space-between; margin-bottom: 1.4286vw;
                .pay-amount {
                    padding-top: 0;
                    .undeter-pay{color: $purple;}
                }
            }
            .payment-list-card {
                &>.pay-amount {
                    margin-top:2.1429vw;padding-top:0;
                    .undeter-pay{color: $purple;}
                    &.type-item {
                        display:flex;justify-content:space-between;
                        &+.type-item {margin-top:1.4286vw;}
                    }
                }
            }
            .notification {margin-top: 2.1429vw;}
        }
    }
    //생활구독서비스
    .life-service{
        margin-bottom:10.0000vw;
        .page-img-tit{
            &.life-ch{
                background-color: #f2f3f8;
            }
        }
        .white{margin-top:3.5714vw;}
        .chip-wrap{
            .chip{
                &:first-child{background-color:$lightpurple;}
                &:nth-child(2){ background-color:$purpleblue;}
            }
        }
    }
    //생활구독서비스 - 상세
    .life-detail{
        &.sub-conts{padding-top:0;}
        .life-list-wrap{
            margin-bottom: 5.7143vw;
            .life-list{
                margin-top:0;
                .life-list-img{
                    border-radius: 0;  width:51.4286vw; height:37.1429vw; background: #ffdede; overflow: hidden;
                }

            }
        }
        .detail-sec{
            .sub-conts{
                padding-top: 0;
            }
            .notification{
                strong{
                    display: block; margin-top: 0.7143vw;
                }
                & + .btns{
                    margin-top: 1.4286vw;
                }
            }
        }
        .detail-sec-tit{
            margin-bottom: 2.8571vw;
            & + .notification{
                margin-top: -2.1429vw;
            }
        }
    }
    //생활구독서비스 - 이용약관
    .subscribe-agree{

        .cardList-wrap{
            .cardList{
                margin-bottom: 1.4286vw;
            }
        }
        .agree-wrap{
            margin-top:3.5714vw;
            .agree-group{
                margin-top:2.8571vw; padding:0 2.1429vw;
                .btn-check{margin-bottom:2.1429vw;}
            }
        }
    }
    //주소찾기
    .subscribe-adress{
        .box-flex{
            margin-bottom:2.1429vw; padding-top: 0;
        }
        .ligt-gray-box{
            width:100%; background: $gray01; padding:2.8571vw; box-sizing: border-box; border-radius:1.4286vw; margin-bottom: 4.2857vw;
            .desc{
                margin-top:1.1429vw;
            }
            .desc-ex{
                margin-top:1.1429vw;
            }
        }
        .adress-step02-title{
            display: flex;
            justify-content: space-between;
            margin-bottom:1.4286vw;
            a{
                width:2.8571vw;
                height:2.8571vw;
                background: #ffdede;
                font-size:0;
            }
        }

    }
    //부가서비스 신청
    .addition-service-appli{
        .page-tit{
            span{@include fontInherit;}
        }
        section {
            &.cardList-wrap{
                margin-top: 4.2857vw;
            }
            & + section {margin-top: 5.7143vw;}
        }
        .error-msg{
            text-align: center; margin-top: 5.7143vw;
        }
    }
    .addition-service-appli-zero{
        .zero-bundle{
            margin-top: 8.3333vw;
        }
    }
    //요금제 - 요금제 상세 페이지
    .payment-detail-list {
        .payment-detail-desc {
            border-bottom:0.1429vw solid $gray02;padding:0 2.8571vw 5.7143vw;box-sizing:border-box;
            .title-wrap {
                display: flex; justify-content: space-between; align-items: center;
                .left {
                    .chip-wrap{margin-bottom:1.4286vw;max-width:100%;}
                    .title {margin-top: 1.4286vw;}
                    p {margin-top:0.7143vw;}
                }
            }
            .data-box {margin-top:2.8571vw;padding:2.8571vw;border-radius:1.4286vw;background:$bg;}
            .data-list {
                margin-bottom:2.8571vw;
                li {
                    display:flex;align-items: center;
                    &+li {margin-top:2.7778;}
                }
                &+.notification {padding-top:2.8571vw;border-top:0.1429vw solid $gray02;}
            }
            .pay-amount {padding-top:2.1429vw;}
        }
        .accordian-wrap{
            .accordian{
                &>.acc-trigger{
                    border-bottom:0; margin-top:4.2857vw; padding-bottom:0;
                }
                .acc-conts{
                    background:$white; padding:0; padding-top:2.1429vw;
                    dt{
                       font-weight: 600;
                    }
                    dd{
                        margin: 0.7143vw 0 1.4286vw;
                    }
                }
            }
        }
    }
    //결제하기
    .subscribe-payment{
        section {
            & + section{margin-top: 5.7143vw;}
            .contents-title{
                display: block; margin-bottom: 0.7143vw;
            }
            p{
                & + .payment-wrap{margin-top: 2.8571vw;}
            }
        }
        &.point{
            section + section {margin-top:  2.8571vw;}
        }
        .payment-wrap{
            .box-table {border-top: 0;}
        }
    }
    //고객후기
    .customer-review{
        .review-ch{
            background-color: #f2f3f8;
        }
    }
    //휴대폰 인증 및 배송지 정보
    .subscribe-phone-adress{
        .sec-detail{
            .sec-tit{
                margin-bottom: 2.8571vw;
            }
            & + .sec-detail{
                margin-top: 5.7143vw;
            }
            .box-select{margin-top:2.8571vw;}
            .self-write-wrap{
                width:100%; margin-top:2.1429vw; display: none;
                p{
                    text-align: right; margin-top:0.7143vw;
                    strong{
                        color:$black; font-weight: inherit; font-size: inherit;
                    }
                }
            }

        }
        .group{
            margin-top:2.8571vw;
        }
    }
    //구독신청 완료
    .subscribe-completed{
        .page-tit{
            margin-bottom: 2.8571vw;
        }
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 4.2857vw;}
            .cardList {
                dl{
                    dt{
                        width: 40%;
                        span{@include fontInherit;}
                    }
                    dd{
                        width: 60%;
                        span{@include fontInherit;}
                    }
                }
            }
            .item-bottom{
                border-top: 0.1429vw solid $gray02; padding-top: 2.8571vw;
            }
        }

    }

    //통신부가서비스 - 인트로
    .addition-intro{
        &.sub-conts{padding-bottom:10.0000vw;}
        .addition-list{
            li{
                margin-top: 2.1429vw;
                .payment-imgCard-btn{
                    position: relative;
                    .item-top{
                        padding-bottom: 2.8571vw;
                        .flex-box{
                           overflow: hidden;
                        }
                        .chip-wrap{
                            float: left;
                        }
                        .status{
                            color:$purple; text-align: right; float: right;
                        }
                        .emphasis-txt{
                            em{margin-top: 0.7143vw; display: block;}
                        }
                        .pay-amount{
                            em{margin-top: 0.7143vw; }
                        }
                    }
                    .item-bottom{
                        border-top:0.1429vw solid $gray02; padding-top: 2.8571vw;
                        dl{
                            align-items: center; justify-content: space-between; margin-top: 2.1429vw; margin-bottom: 4.2857vw;
                        }
                        dt{
                            width:24.1429vw;
                            .btn-text-arrow{
                                border-top:0; padding-top:0; padding-bottom:0;
                                &.no-arrow{
                                    background-image: none;
                                }
                            }
                        }
                        dd{
                            text-align: right; width:calc(100% - 24.1429vw);
                            .ico-img{
                                width:3.4286vw; height:3.4286vw; display: inline-block; margin-right: 1.4286vw;
                                img{
                                    width:100%;
                                }
                            }
                        }

                    }
                    .btn-group{
                        gap:1.4286vw;
                       .btns{
                            padding: 1.7143vw 0; border-radius: 1.0000vw; font-size: 2.1429vw;
                        }
                    }
                }
            }
        }
    }
    .search-result{
        strong{
             margin-right: 0.7143vw;

        }
        span{
            color:$purple; font-weight: 600; font-size: 2.0000vw;
        }
    }
    //휴대폰 분실 / 파손 보험
    .breakage-list{
        .unit{
            margin-bottom: 5.7143vw;
            .page-tit{
                margin:5.7143vw 0 2.1429vw;
            }
            .count-tit{
                margin-top:4.2857vw;margin-bottom:0.7143vw;
                & + strong{
                    display: block;margin: 0.7143vw 0;font-weight: 400;
                }
            }
            &.unit01 {
                .page-tit {margin-top:0;}
                .count-tit {margin-top:5.7143vw;}
                .ico-wrap {
                    .ico-img {margin:0 auto; width:18.5714vw; height:18.5714vw;}
                    .info-txt {margin-top:2.8571vw;}
                }
                .notification {
                    li {
                        .btn-group {
                            gap:1.4286vw;margin-bottom:2.8571vw;
                            .btns{
                                padding: 1.7857vw 2.1429vw; border-radius:1.4286vw; text-align: left; position: relative;
                                &::after{
                                    content:'';width:2.8571vw;height:2.8571vw;display:block;position:absolute;top:50%;right:1.7857vw;transform:translateY(-50%);
                                    @include backgroundImg('/assets/images/icon/ico-call-bk.svg',center center,contain);
                                }
                            }
                        }
                    }
                }
                .btns {margin-top:1.4286vw;}
                .box-select {margin-top:5.7143vw;}
            }
            .one-title {
                dt {
                    strong {margin-bottom: 1.4286vw; display: block;}
                }
            }
            .underLine {
                padding:0 2.8571vw;
                dl {
                    border-bottom:0.1429vw solid $gray02;padding:2.8571vw 0;align-items: center;
                    &:last-child {border-bottom:0;}
                    dt {width:8.5714vw;}
                    dd {
                        width:calc(100% - 8.5714vw); padding-left:1.4286vw;font-weight: 400;
                    }
                }
                .img-icon {
                    dl {
                        dt {
                            width:8.5714vw;
                            img {width:8.5714vw; height: 8.5714vw;}
                        }
                        dd {
                            width:calc(100% - 8.5714vw);
                        }
                        &:last-child {border-bottom:0;}
                    }
                }
                &.one-title {
                    dt {width:0;}
                    dd {width:100%;}
                }
            }
            .cardList-wrap {margin-top: 2.8571vw; margin-bottom: 4.2857vw;}
            .table-list-wrap{
                margin-top: 2.8571vw;
                .table-list {
                    thead {
                        th {border-bottom:0; }
                    }
                    tbody {
                        th {background:none; border-bottom: 0.1429vw solid $gray02;}
                    }
                    th, td {padding:2.8571vw 2.1429vw;}
                    &+.table-list {margin-top:-0.0714vw;}
                }
            }
            .btn-text-num-wrap {
                margin-top: 4.2857vw;
                .btn-text-arrow {margin-top: 2.1429vw;}
            }
            .num-process-wrap {
                &+.notification {margin-top:1.4286vw;}
            }
            .balloon-list {
                li {
                    display:block;
                    &+li {margin-top:1.4286vw;}
                    p {
                        position:relative;padding:2.1429vw;border-radius:1.4286vw;background:#f8f4ff;color:$black;
                        &::after {
                            content:'';position:absolute;left:1.4286vw;bottom:-1.4286vw;display:block;width:1.4286vw;height:1.4286vw;
                            @include backgroundImg('/assets/images/subpage/balloon-list-arroB.png', center, 1.4286vw 1.4286vw);
                        }
                    }
                }
            }
        }

    }
    .breakage-insurance-step02{
        .notification{
            margin-top: 1.4286vw;
        }
    }
    //이용안내 - 셀프 개통
    .use-self-opening{
        .btn-list{
            margin-top:1.4286vw; margin-bottom:2.8571vw;
            li{
                margin-bottom: 1.4286vw;
            }
        }
        .one-title{
            .btn-text-arrow{
                margin-top: 2.1429vw;
            }
        }
        .c-unit {
            .unit-tit {margin-bottom:4.2857vw;}
            &.list-seq {
                margin-top:4.2857vw;
                .unit-sec-tit {margin-bottom:2.1429vw;}
            }
        }
    }
    //이용안내 - 바로픽업
    .use-pickup {
        .c-unit {
            &.list-seq {
                &+.list-seq { margin-top:4.2857vw;}
                .unit-tit {margin-bottom:2.8571vw;}
                .unit-sec-tit {margin-bottom:2.1429vw;}
            }
        }
    }
    // 이용안내 - 휴대폰 정보
    .use-phone-info{
        .c-unit {
            .unit-sec-tit {
                &+h3 {display:block;margin:2.8571vw 0 0.7143vw;}
            }
            &.list-seq {
                &+.list-seq { margin-top:4.2857vw;}
                .unit-tit {margin-bottom:2.8571vw;}
                .unit-sec-tit {margin-bottom:2.1429vw;}
            }
        }
        .btn-list {
            li { margin-top:1.4286vw;}
        }
    }
    //제휴카드 할인
    .use-card{
        //목록
        &-list{
            .card-list{
                li{
                    a{
                        padding-right: 2.1429vw; gap:1.4286vw;
                        .card-img-wrap{
                            flex: none; width: 14.2857vw; flex-shrink: 0;
                        }
                        .card-desc{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        //상세
        &-detail {
            .card-thumbnail{
                display: flex; justify-content: center; align-items: center; height: 37.1429vw; background-color: $bg; overflow: hidden;
                img{width: 100%; max-width: 28.5714vw;}
            }
            .card-txt{
                margin-top: 2.8571vw;
                .contents-title{
                    display: block;
                    & + span{display: block; margin-top: 0.7143vw;}
                }
            }
            .box-table{
                margin-top: 4.2857vw;
                & + .box-btn {margin-top: 2.8571vw;}
            }
            .box-tab{
                margin-top: 5.7143vw;
            }
        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-detail{
                display: flex; flex-direction: column; gap: 5.7143vw;
                &-item {
                    .item-top{
                        padding-bottom: 0; gap: 2.8571vw; align-items: center;margin-bottom:2.8571vw;
                        .img-wrap{
                            width: 12.8571vw; overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .box-table{
                        td{
                            text-align: center;padding: 2.8571vw 0;
                            &:first-of-type{color: $gray05;}
                        }
                    }
                }
            }
        }
    }
    //가입정보 입력[step03]
    .breakage-insurance-step03{
        .group{
            &:nth-of-type(n+3){margin-top:4.2857vw;}
            .notification{
                margin-top: 1.4286vw;
            }
        }
        .cardList-wrap{

            dl {
                dt {width: 40%;}
                dd {width: 60%;}
            }
        }
        .radio-group{
            display: flex; gap: 5.7143vw; margin-top: 2.1429vw;
            .btn-radio-type2{width: auto;}
        }
    }
    //가입정보 입력[step04]
    .breakage-insurance-step04{
        .cardList-wrap{
            dl {
                dt {width: 40%;}
                dd {width: 60%;}
            }
        }
        .box-btn{
            & + .cardList {margin-top: 2.1429vw;}
        }
    }
    //보험가입신청완료
    .breakage-insurance-finish {
        .box-table {
            .table-title {margin-bottom:1.4286vw;}
            &+.box-table {margin-top:4.2857vw;}
        }
        .text-info {
            margin-bottom:4.2857vw;
            .small-txt {margin-top:0.7143vw;}
        }
    }
    //첨부 가능한 영수증 예시
    .breakage-receipt{
        ol{
            display: flex; gap: 2.8571vw; flex-direction: column;
            li{
                img{display: block; margin-top: 0.7143vw;}
            }
        }
    }
    //배송안내
    .use-shipping-info{
        .cardList-wrap{
            .notification{
                margin-top: 1.4286vw;
            }
            & + .cardList-wrap{
                margin-top: 5.7143vw;
            }
        }
        .c-unit{
            margin-top: 5.7143vw;
            .btn-text-arrow{
                margin-top: 1.4286vw;
            }
            .info-txt{
                margin-top: 1.4286vw;
            }
        }
    }
    //바로픽업 - 선택약정 할인
    .use-select-discount{
        .one-title{
            margin-top: 2.8571vw;
            dl{
                &+dl{ border-top:0.1429vw solid $gray02; padding-top:2.8571vw; margin-top: 2.8571vw;}
                dt {
                    &>p {margin-top: 1.4286vw;}
                    .desc {margin-top: 1.4286vw;}
                    .btn-text-arrow{margin-top: 1.4286vw;}
                }
            }
        }
        .unit-img {
            margin:4.2857vw 0;
            &+.notification {margin-top: 4.2857vw;}
        }
        .unit-tit02{margin-top:2.1429vw;margin-bottom:0.7143vw;}
        .accordian{
            .acc-trigger{padding-bottom: 0;}
            .acc-conts{
                background: none; padding: 0;
                .unit-sec-tit {
                    margin:4.2857vw 0 0.7143vw;
                    &:first-of-type {margin-top:0;}
                }
                .table-list-wrap {margin-top:2.8571vw;}
            }
        }
    }
}
//간편인증 정보
.self-simple-bottomSheet{
    .input-interval{
        li{
            & + li{
                 margin-top: 2.8571vw;
            }
        }
    }
}

//요금제 필터 바텀시트
.payment-list-bottomSheet{
    .popup-body{
        padding-top:0 !important;
    }
    .popup-footer{
        .count{
            color:$white;
        }
    }
}
//기준일 선택
.breakage-baseDate-bottomSheet{
    .radio-list{
        .btn-radio{
            width:100%;
            label {padding:1.4286vw 2.1429vw;text-align:left;}
        }
    }

}
//신청 불가 레이어 팝업
.breakage-noApply-layerPopup{
    .popup-header{
        text-align: center;
    }
    .layer-popup-item{
        .popup-body{
            text-align: left;
        }
    }
}
//분실/파손보험 선택 - 약관동의[바텀시트]
.breakage-agreement-bottom{
    .btn-check{
        & + p {
            margin-top: 1.4286vw;
        }
        & ~ .box-btn{
            margin-top: 2.8571vw;
        }
    }
}

//요금제 - 내게 맞는 요금제 찾기 결과[바텀시트]
.payment-result{
    .popup-body{
        padding: 2.8571vw 0 4.2857vw 0 !important;
        .result-wrap{
            padding: 0 2.8571vw 0;
        }
        .main-txt{display: block; margin-bottom: 2.1429vw;}
    }
    .result-slider-wrap{
        margin-top: 2.8571vw;
        // &.wide-box{margin-left: unset;}
        .slick-center-mode {
            .slick-slide{
                max-height: none; margin-left: -0.7143vw !important; margin-right: -0.7143vw !important;
            }
        }
        .slick-slider {
            .slick-slide {width:42.8571vw;padding-left:2.8571vw;}
        }
        .cardList-wrap {
            .cardList {
                width:100%;padding:4.2857vw 2.8571vw;
                .product-name{
                    margin-bottom: 1.4286vw;
                    span {display:block;}
                    strong {display:block;margin-top:0.7143vw;}
                }
                .pay-amount {
                    padding-top:2.1429vw;
                    .origin-pay {color:$gray04;}
                }
                .box-btn {margin-top:4.2857vw;}
            }
        }
    }
}


.plan-detail{
    padding-top: 5.7143vw;
    section{
        &+section{
            margin-top: 5.0000vw;
        }
        .b-tt{
            margin-bottom: 0;
        }
        .m-tt{
            margin-top: 1.4286vw;
        }
        .notification{
            margin-top: 0.7143vw;
        }
        .txt{
            margin-top: 0.7143vw;
        }
    }
}

}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .products{
        .page-img-tit{
            margin-bottom: 40px; padding-left: 20px;
            img{width: 155px;}
            &.addition-img-tit{
                h1{
                    font-size: 16px; padding: 0;
                    p{font-size: 18px; margin-bottom: 5px;}
                }
            }
        }
        .subscribe-adress{
            .ligt-gray-box{padding: 20px; margin-bottom: 30px; border-radius: 8px;}
            .box-flex{margin-bottom: 15px;}
        }
        .payment-list{
            .is-filter-warp{margin-bottom: 40px;}
            .life-list-wrap{
                margin-bottom: 40px;
                .payment-accordian-card-wrap{
                    margin-top: 40px;
                    &:first-of-type{margin-top: 20px;}
                }
            }
        }
        .payment-list-design{
            .products-radio-box{
                .group-tit{
                    margin-bottom: 20px;
                    &::after{width: 20px; height: 20px; background-size: 20px 20px; margin-left: 5px;}
                }
                .radio-group-wrap{
                    &.on{
                        .group-tit{
                            margin-bottom: 10px;
                            &::after{width: 20px; height: 20px; background-size: 20px 20px; margin-left: 5px;}
                        }
                    }
                    &.radio-group-wrap{margin-top: 40px;}
                }
                .box-btn{margin-top: 40px;}
            }
        }
        .payment-list-card-wrap{
            &.addition-service-card{
                .payment-list-card{
                    .pay-amount{padding-top: 20px;}
                    .icon-wrap{
                        img{
                            width: 40px;
                            & + img{margin-left: 10px;}
                        }
                    }
                    p{margin-top: 5px;}
                    .default-txt{margin-top: 15px;}
                }
            }
        }
        .payment-result{
            .popup-body{
                padding: 20px 0 30px 0 !important;
                .result-wrap{padding: 0 20px;}
                .main-txt{margin-bottom: 15px;}
            }
            .result-slider-wrap{
                margin-top: 20px;
                .slick-slider{
                    .slick-slide{width: 300px; padding-left: 20px;}
                }
                .cardList-wrap{
                    .cardList{
                        padding: 30px 20px;
                        .product-name{margin-bottom: 10px;}
                        .pay-amount{padding-top: 15px;}
                        .box-btn{margin-top: 30px;}
                        .feature{
                            padding: 10px; border-radius: 8px;
                            span{font-size: 12px;}
                        }
                    }
                }
            }
        }
        .payment-unable{
            .guide{margin-bottom: 10px;}
            .payment-list-card-wrap{
                .card-header{margin-bottom: 10px;}
                .notification{margin-top: 15px;}
            }
        }
        .payment-detail-list{
            .payment-detail-desc{
                border-width: 1px; padding: 0 20px 40px;
                .title-wrap{
                    .left{
                        .title{margin-top: 10px;}
                        p{margin-top: 5px;}
                    }
                }
                .data-box{margin-top: 20px; border-width: 1px; border-radius: 8px; padding: 20px;}
                .data-list{
                    margin-bottom: 20px;
                    & + .notification{padding-top: 20px; border-width: 1px;}
                }
                .pay-amount{padding-top: 15px;}
            }
        }
        .recmend-fee{
            .fee-list{
                & > li{
                    padding: 35px 20px 20px 20px; border-radius: 8px;
                    .fee-tit{font-size: 18px;}
                    .pay-amount{
                        margin-top: 15px;
                        .origin-pay{font-size: 18px;}
                    }
                    .feature{
                        margin: 15px 0 0; padding: 20px 0 20px 15px; border-radius: 8px;
                        span{
                            font-size: 16px;
                            &::before{width: 20px; background-size: 20px !important;}
                        }
                    }
                    .notification{
                        margin-top: 15px;
                        li{font-size: 16px;}
                    }
                    & + li{margin-top: 15px;}
                }
            }
        }
        .all-fee-wap{
            & > div{
                & + .title{margin-top: 40px;}
            }
        }
        .search-result{
            span{font-size: 15px;}
        }
        .life-service{margin-bottom: 50px;}
        .life-detail{
            .life-list-wrap{margin-bottom: 40px;}
            .detail-sec-tit{
                margin-bottom: 20px;
                & + .notification{margin-top: -15px;}
            }
            .detail-sec{
                .notification{
                    & + .btns{margin-top: 10px;}
                    strong{margin-top: 5px;}
                }
            }
        }
        .subscribe-agree{
            .cardList-wrap{
                .cardList{margin-bottom: 10px;}
            }
            .agree-wrap{margin-top: 25px;
                .agree-group{
                    margin-top: 20px; padding: 0 15px;
                    .btn-check{margin-bottom: 15px;}
                }
            }
        }
        .subscribe-phone-adress{
            .group{margin-top: 20px;}
            .sec-detail{
                & + .sec-detail{margin-top: 40px;}
                .sec-tit{margin-bottom: 20px;}
                .box-select{margin-top: 20px;}
            }
        }
        .subscribe-payment{
            section{
                & + section{margin-top: 40px;}
                .contents-title{margin-bottom: 5px;}
                & + .payment-wrap{margin-top: 20px;}
            }
            .pay-box{padding: 15px 20px; border-width: 1px; border-radius: 8px; margin-top: 8px;}
            &.point{
                section{
                    & + section{margin-top: 20px;}
                }
            }
        }
        .subscribe-completed{
            .page-tit{margin-bottom: 20px;}
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 30px;}
                .item-bottom{padding-top: 20px; border-width: 1px;}
            }
        }
        .payment-wrap{
            .btn-check{margin-top: 10px;
                & + .box-btn{margin-top: 20px;}
            }
            .box-table{margin-top: 20px;}
            .pay-box{
                padding: 15px 20px; margin-top: 8px; border-radius: 8px; border-width: 1px;
            }
        }
        .noti-section{
            margin-top: 40px;
            .sub-contents-title{margin-bottom: 5px;}
        }
        .recurring-payment{
            margin-top: 40px;
            .contents-title{margin-bottom: 5px;}
            p{
                & + .payment-wrap{margin-top: 20px;}
            }
        }
        .addition-intro{
            .addition-list{
                li{
                    margin-top: 15px;
                    .payment-imgCard-btn{
                        .item-top{padding-bottom: 20px;}
                        .item-bottom{
                            padding-top: 20px; border-width: 1px;
                            dl{margin-top: 15px; margin-bottom: 30px;}
                            dd{
                                .ico-img{width: 24px; height: 24px; margin-right: 10px;}
                            }
                        }
                        .btn-group{
                            .btns{padding: 12px 0; font-size: 15px; border-radius: 8px;}
                        }
                    }
                }
            }
        }
        .addition-service-appli{
            section{
                &.cardList-wrap{margin-top: 30px;}
                & + section{margin-top: 40px;}
            }
        }
        .addition-service-appli-zero{
            .zero-bundle{margin-top: 40px;}
            .flex-box{gap: 10px; margin-top: 10px;}
        }
        .breakage-list{
            .unit{
                margin-bottom: 40px;
                .page-tit{margin: 40px 0 15px;}
                &.unit01{
                    .ico-wrap{
                        .ico-img{width: 133px; height: 133px;}
                    }
                    .info-txt{margin-top: 20px;}
                    .count-tit{margin-top: 40px;}
                    .notification{
                        li{
                            .btn-group{
                                margin-bottom: 20px;
                                .btns{
                                    border-radius: 8px; padding: 12px 15px;
                                    &::after{width: 20px; height: 20px;}
                                }
                            }
                        }
                    }
                    .box-select{margin-top: 40px;}
                    .btns{margin-top: 10px;}
                }
                .cardList-wrap{margin-top: 20px; margin-bottom: 30px;}
                .underLine{
                    padding: 0 20px;
                    dl{
                        padding: 20px 0;
                        dd{font-weight: 400; width: calc(100% - 60px); padding-left: 10px; border-width: 1px;}
                        dt{width: 60px; border-width: 1px;}
                    }
                }
                .count-tit{
                    margin-top: 30px; margin-bottom: 5px;
                    & + strong{margin: 10px 0;}
                }
                .balloon-list{
                    li{
                        p{padding: 15px; border-radius: 8px;}
                    }
                }
                .table-list-wrap{
                    margin-top: 20px;
                    .table-list{
                        th, td{padding: 20px 15px;}
                        tbody{
                            th{border-width: 1px;}
                        }
                    }
                }
                .one-title{
                    dt{
                        strong{margin-bottom: 10px;}
                    }
                }
                .num-process-wrap{
                    & + .notification{margin-top: 20px;}
                }
                .btn-text-num-wrap{
                    margin-top: 30px;
                    .btn-text-arrow{margin-top: 15px;}
                }
            }
        }
        .breakage-insurance-step02{
            .notification{margin-top: 10px;}
        }
        .breakage-insurance-step03{
            .group{
                &:nth-of-type(n+3){margin-top: 30px;}
                .notification{margin-top: 10px;}
            }
            .radio-group{margin-top: 15px;}
        }
        .breakage-insurance-step04{
            .box-btn{
                & + .cardList{margin-top: 15px;}
            }
            .cardList-wrap{
                dl{
                    dt{width: 30%;}
                }
            }
        }
        .breakage-insurance-finish{
            .text-info{margin-bottom: 30px;}
            .box-table{
                .table-title{margin-bottom: 10px;}
                & + .box-table{margin-top: 30px;}
            }
        }
        .breakage-receipt{
            ol{
                li{
                    img{margin-top: 5px; width: 60%;}
                }
            }
        }
        .use-self-opening{
            .c-unit{
                .unit-tit{margin-bottom: 30px;}
                &.list-seq{
                    margin-top: 30px;
                    .unit-sec-tit{margin-bottom: 15px;}
                }
            }
            .btn-list{
                li{margin-bottom: 10px;}
            }
            .one-title{
                .btn-text-arrow{margin-top: 15px;}
            }
        }
        .use-pickup{
            .c-unit{
                &.list-seq{
                    .unit-sec-tit{margin-bottom: 15px;}
                    & + .list-seq{margin-top: 30px;}
                }
            }
        }
        .use-shipping-info{
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 40px;}
            }
            .c-unit{
                margin-top: 40px;
                .info-txt{margin-top: 10px;}
            }
        }
        .use-card-list{
            .card-list{
                li{
                    a{
                        .card-img-wrap{width: 100px;}
                    }
                }
            }
        }
        .use-card-detail{
            .card-thumbnail{
                height: 260px;
                img{max-width: 200px;}
            }
            .card-txt{margin-top: 20px;}
            .box-table{
                margin-top: 30px;
                & + .box-btn{margin-top: 20px;}
            }
        }
        .use-card-benefit{
            .card-detail{gap: 40px;}
            .card-detail-item{
                .item-top{
                    gap: 20px; margin-bottom: 20px;
                    .img-wrap{
                        width: 95px;
                    }
                }
                .box-table{
                    td{padding: 20px 0;}
                }
            }
        }
        .use-select-discount{
            .one-title{
                margin-top: 20px;
                dl{
                    & > dt{
                        p{margin-top: 10px;}
                    }
                    dt{
                        .desc{margin-top: 10px;}
                        .btn-text-arrow{margin-top: 10px;}
                    }
                    & + dl{border-width: 1px; padding-top: 20px; margin-top: 20px;}
                }
            }
            .unit-img{
                margin: 30px 0;
                & + .notification{margin-top: 30px;}
            }
            .unit-tit02{
                margin-top: 15px; margin-bottom: 5px;
            }
            .accordian{
                .acc-cont{
                    .unit-sec-tit{margin: 30px 0 5px;}
                    .table-list-wrap{margin-top: 20px;}
                }
            }
        }
        .use-phone-info{
            .c-unit{
                .unit-sec-tit{
                    & + h3{margin: 20px 0 5px;}
                }
                &.list-seq{
                    .unit-tit{margin-bottom: 20px;}
                    .unit-sec-tit{margin-bottom: 20px;}
                    & +.list-seq{margin-top: 30px;}
                }
            }
            .btn-list{
                li{margin-top: 20px;}
            }
        }
        .btn-group{
            .banner-randombox-wrap{
                left: 20px; bottom: 70px;
            }
        }
    }
    .plan-detail{padding-top: 40px;
        section{
            & + section{margin-top: 35px;}
            .notification{margin-top: 10px;}
        }
    }
    .breakage-baseDate-bottomSheet{
        .radio-list{
            .btn-radio{
                label{padding: 10px 15px;}
            }
        }
    }
    .breakage-agreement-bottom {
        .btn-check{
            & + p{margin-top: 10px;}
            & ~.box-btn{margin-top: 20px;}
        }
    }
    .self-simple-bottomSheet{
        .input-interval{
            li{
                & + li{margin-top: 20px;}
            }
        }
    }
}