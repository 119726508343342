    .use-card{
        //상세
        &-detail {
            &.sub-conts{
                padding-top: 0;
            }
            .card-thumbnail{
                width:100vw; margin-left: -5.5556vw; display: flex; justify-content: center; align-items: center; min-height:72.2222vw; background-color: $bg; overflow: hidden; padding:9.7222vw 0;
                img{
                   border-radius: 1.3889vw; box-shadow: 3vw 4vw 7vw 0.1389vw rgb(0 0 0 /10%); min-width:33.3333vw; max-width:52.7778vw;
                }
            }
            .card-txt{
                margin-top:5.5556vw;
                .contents-title{
                    display: block; @include subContsTitle;
                }
                & > .notification{
                    margin-top: 1.3889vw;
                    li{
                        @include smallTxt; font-weight: 100;
                    }
                }
            }
            .box-table{
                margin-top: 8.3333vw;
                .btn-text-arrow {margin-top: 5.5556vw;}
            }
            // 제휴카드 영역 배너
            .jehyu-banner{
                display: flex; justify-content: space-between; align-items: center; background: #e4eefb; padding: 0 6.9444vw; margin-top: 11.1111vw; height: 26.3889vw;
                a {display: flex; justify-content: space-between; align-items: center; width:100%; height:100%;}
                .left{
                    .title{ @include mainTxt; color: #2b4565; font-weight: bold;}
                }
                .right{
                    .img {width: 19.4444vw;}
                }
            }
            .box-tab{
                margin-top: 11.1111vw !important;
            }
            .c-unit{
                .c-tit{
                    @include mainTxt;
                }
                .c-desc{
                    @include smallTxt; margin-top: 1.3889vw;
                }
                .notification{
                     margin-top: 2.7778vw;
                     strong{
                        @include mainTxt; font-weight: 900;
                        &.sm{
                            @include normalTxt;
                        }
                     }
                }
                .table-list-wrap{
                    margin-top: 4.1667vw;
                    .table-list{
                        tr.bg{
                            td{
                                background-color: #fff8ff; color:$purple; font-weight: 600;
                            }
                        }
                    }
                }
                .btn-text-arrow{
                    margin-top: 5.5556vw;
                }
                .graph-wrap{
                    .graph-tit{
                        @include defaultTxt; margin-top: 5.5556vw;
                    }
                    .round-gy{
                        border-radius:2.7778vw; border:0.2778vw solid $gray02; padding:16.3889vw 4.1667vw 5.5556vw 4.1667vw; margin-top: 4.1667vw;
                        .average-txt{
                             display: inline-block; position: absolute; top:-10.8889vw; right:0; padding: 1.6667vw 4.0278vw; border-radius: 6.25vw;background-color:#ec008c;@include smallTxt; color: #fff;text-align: center;
                                 &::after{
                                    content: ''; display: block;width:2.3611vw;height: 2.3611vw;
                                    position: absolute; bottom: -1.1806vw; left: 50%; transform: translateX(-50%) rotate(45deg); background-color: #ec008c;
                                }
                        }
                        ul{
                            li{
                                 display: flex; flex-wrap: wrap; width:100%; gap:4.1667vw; align-items: center; position: relative;
                                .count{
                                    width:22.3611vw; @include smallTxt; font-weight: 600;
                                }
                                .graph{
                                    width:calc(100% - 27.0278vw); position: relative; display: flex; align-items: center;
                                    &.w-sm{
                                       width:calc(92% - 27.0278vw);
                                    }
                                    &.w-xs{
                                       width:calc(70% - 27.0278vw);
                                    }
                                    span{
                                         display: flex; padding:2.2222vw 4.1667vw;  border-radius: 1.3889vw; min-height:13.8889vw; justify-content: center; flex-wrap: wrap; width:100%; align-items: center;
                                        &.light{
                                            background-color: #ece7f3; @include mainTxt; flex-direction: column;align-items: flex-start;
                                            em{
                                                 @include chipTxt; color:$black;display: block;
                                            }
                                            .sb-txt{
                                                font-size:3.3333vw;
                                                letter-spacing: -0.139vw;
                                            }
                                        }
                                        &.dark{
                                            background-color: $purple; @include defaultTxt; color:$white; flex-direction: column;  align-items: flex-start;
                                            em{
                                                @include chipTxt; line-height: 1.3;
                                            }
                                        }
                                        & + span.dark{
                                            position:absolute; top:0; right:0; padding: 0; text-align: center;
                                           border-radius: 0 1.3889vw 1.3889vw 0; width:45%; display: flex; justify-content: center; align-items: center;flex-direction: column;
                                        }
                                    }
                                }
                                & + li{
                                    margin-top: 2.7778vw;
                                }
                                &.pro{
                                    .count{
                                        position: relative; padding-top:7.5vw;
                                        &::before{
                                            content: '프로모션';display: block;position: absolute; top:-1.1667vw;left:50%; transform: translateX(-50%); background: $purple; padding: 0.9722vw 2.7778vw; text-align: center;@include chipTxt; width: 60%; border-radius: 4.1667vw;
                                        }
                                    }
                                    .count.txt-val01{
                                        &::before{
                                            content: '전월실적';display: block;position: absolute; top:-1.1667vw;left:50%; transform: translateX(-50%); background: $purple; padding: 0.9722vw 2.7778vw; text-align: center;@include chipTxt; width: 60%; border-radius: 4.1667vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .num-process-wrap{
                    margin-top: 4.1667vw;
                    .btn-text-arrow{
                        margin-top: 0;
                    }
                }
                .tel-round{
                    border-radius: 2.7778vw; border:0.2778vw solid $gray02; display: block; padding: 5.5556vw; display: flex; justify-content: space-between; align-items: center;
                    p{
                        @include mainTxt; display: flex;align-items: center;
                        span{
                            @include mainTxt; color:$purple; margin-left: 2.7778vw;
                        }
                    }
                }
            }

        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-detail{
                display: flex; flex-direction: column; gap: 11.1111vw;
                &-item {
                    .item-top{
                        padding-bottom: 0; gap: 5.5556vw; align-items: center;margin-bottom:5.5556vw;
                        .img-wrap{
                            width: 25vw; overflow: hidden;
                            img{
                                width: 100%;
                            }
                            & + strong{@include subContsTitle;}
                        }
                    }
                    .box-table{
                        td{
                            text-align: center;padding: 5.5556vw 0;
                            &:first-of-type{color: $gray05;}
                        }
                    }
                }
            }
        }
    }


@media (min-width: 400px) and (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
    .use-card{
        //상세
        &-detail {
            &.sub-conts{
                padding-top: 0;
            }
            .card-thumbnail{
                width:51.4286vw; margin-left: -2.8571vw; display: flex; justify-content: center; align-items: center; min-height:37.1429vw; background-color: $bg; overflow: hidden; padding:5.0000vw 0;
                img{
                   border-radius: 0.7143vw; box-shadow: 1.5714vw 2.0714vw 3.5714vw 0.0714vw rgb(0 0 0 /10%); min-width:17.1429vw; max-width:27.1429vw;
                }
            }
            .card-txt{
                margin-top:2.8571vw;
                .contents-title{
                    display: block;
                }
                & > .notification{
                    margin-top: 0.7143vw;
                    li{
                       font-weight: 100;
                    }
                }
            }
            // 제휴카드 영역 배너
            .jehyu-banner{
                height: 19vw;
            }
            .box-table{
                margin-top: 4.2857vw;
                .btn-text-arrow {margin-top: 2.8571vw;}
            }
            .box-tab{
                margin-top: 5.7143vw !important;
            }
            .c-unit{
                .c-desc{
                     margin-top: 0.7143vw;
                }
                .notification{
                     margin-top: 1.4286vw;
                     strong{
                        font-weight: 900;
                     }
                }
                .table-list-wrap{
                    margin-top: 2.1429vw;
                    .table-list{
                        tr.bg{
                            td{
                                background-color: #fff8ff; color:$purple; font-weight: 600;
                            }
                        }
                    }
                }
                .btn-text-arrow{
                    margin-top: 2.8571vw;
                }
                .graph-wrap{
                    .graph-tit{
                        margin-top: 2.8571vw;
                    }
                    .round-gy{
                        border-radius:1.4286vw; border:0.1429vw solid $gray02; padding:8.4286vw 2.1429vw 2.8571vw 2.1429vw; margin-top: 2.1429vw;
                        .average-txt{
                             display: inline-block; position: absolute; top:-5.5714vw; right:0; padding: 0.8571vw 2.0714vw; border-radius: 3.2143vw;background-color:#ec008c;color: #fff;text-align: center;
                                 &::after{
                                    content: ''; display: block;width:1.2143vw;height: 1.2143vw;
                                    position: absolute; bottom: -0.6429vw; left: 50%; transform: translateX(-50%) rotate(45deg); background-color: #ec008c;
                                }
                        }
                        ul{
                            li{
                                 display: flex; flex-wrap: wrap; width:100%; gap:2.1429vw; align-items: center; position: relative;
                                .count{
                                    width:11.5000vw; font-weight: 600;
                                }
                                .graph{
                                    width:calc(100% - 13.9286vw); position: relative; display: flex; align-items: center;
                                    &.w-sm{
                                       width:calc(92% - 13.9286vw);
                                    }
                                    &.w-xs{
                                       width:calc(70% - 13.9286vw);
                                    }
                                    span{
                                         display: flex; padding:1.1429vw 2.1429vw;  border-radius: 0.7143vw; min-height:7.1429vw; justify-content: center; flex-wrap: wrap; width:100%; align-items: center;
                                        &.light{
                                            background-color: #ece7f3; flex-direction: column;align-items: flex-start;
                                            em{
                                               color:$black;display: block;
                                            }
                                        }
                                        &.dark{
                                            background-color: $purple; color:$white; flex-direction: column;  align-items: flex-start;
                                            em{
                                                line-height: 1.3;
                                            }
                                        }
                                        & + span.dark{
                                            position:absolute; top:0; right:0; padding: 0; text-align: center;
                                           border-radius: 0 0.7143vw 0.7143vw 0; width:45%; display: flex; justify-content: center; align-items: center;flex-direction: column;
                                        }
                                    }
                                }
                                & + li{
                                    margin-top: 1.4286vw;
                                }
                                &.pro{
                                    .count{
                                        position: relative; padding-top:3.8571vw;
                                        &::before{
                                            content: '프로모션';display: block;position: absolute; top:-0.5714vw;left:50%; transform: translateX(-50%); background: $purple; padding: 0.5000vw 1.4286vw; text-align: center; width: 60%; border-radius: 2.1429vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .num-process-wrap{
                    margin-top: 2.1429vw;
                    .btn-text-arrow{
                        margin-top: 0;
                    }
                }
                .tel-round{
                    border-radius: 1.4286vw; border:0.1429vw solid $gray02; display: block; padding: 2.8571vw; display: flex; justify-content: space-between; align-items: center;
                    p{
                         display: flex;align-items: center;
                        span{
                            color:$purple; margin-left: 1.4286vw;
                        }
                    }
                }
            }

        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-detail{
                display: flex; flex-direction: column; gap: 5.7143vw;
                &-item {
                    .item-top{
                        padding-bottom: 0; gap: 2.8571vw; align-items: center;margin-bottom:2.8571vw;
                        .img-wrap{
                            width: 12.8571vw; overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .box-table{
                        td{
                            text-align: center;padding: 2.8571vw 0;
                            &:first-of-type{color: $gray05;}
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .use-card-detail{
        .card-thumbnail{
            min-height: 260px; padding: 25px; margin-left: -20px;
            img{min-width: 140px; max-width: 280px;}
        }
        .box-tab{margin-top: 40px !important;}
        .card-txt{
            margin-top: 20px;
            & > .notification{margin-top: 5px;}
        }
        .box-table{
            margin-top: 30px;
            .btn-text-arrow{margin-top: 20px;}
        }
        .jehyu-banner{
            height: 100px; margin-top: 40px;
            .right{
                .img{width: 100px;}
            }
        }
        .c-unit{
            .graph-wrap{
                .graph-tit{margin-top: 20px;}
                .round-gy{
                    margin-top: 15px; padding: 60px 15px 20px 15px; border-width: 1px; border-radius: 8px;
                    ul{
                        li{
                            & + li{margin-top: 10px;}
                            gap: 15px;
                            .count{
                                width: 85px; 
                            }
                            .graph{
                                width: calc(100% - 110px);
                                span{
                                    padding: 8px 15px; border-radius: 8px; min-height: 50px;
                                    & + span{
                                        &.dark{color: #fff;}
                                    }
                                }
                            }
                            &.pro{
                                .count{
                                    padding-top: 25px; text-align: center;
                                    &::before{top: -5px; padding: 5px 10px;}
                                }
                            }
                        }
                    }
                    .average-txt{top: -40px; padding: 5px 15px; color: #fff;}
                }
            }
            .notification{margin-top: 10px;}
            .btn-text-arrow{margin-top: 20px;}
            .tel-round{border-radius: 8px; border-width: 1px; padding: 20px;}
            .num-process-wrap{margin-top: 15px;}
        }
    }
}