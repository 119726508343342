@charset "UTF-8";


// pc
@include response("pc") {
    html {font-size: 10px;}
}

// TABLET
@include response("tablet") {
    html {font-size: 52%;}
}

// MOBILE
@include response("mobile") {
    .pc {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
}