@charset "UTF-8";

.box-address {
    .box-flex {
        padding-top:0;
        .box-input {margin-top:0;}
    }
    .box-input {margin-top:3.3333vw;}
    .btn-check {
        margin-top:2.7778vw;justify-content:flex-end;
        label {width:auto;}
    } /*08.26 우측정렬 변경*/
    .today-noti {
        margin-top:2.7778vw;padding-left:6.9444vw;@include defaultTxt;color:$purple;
        @include backgroundImg('/assets/images/icon/ico-delivery-car.png', left top, 5.5556vw 5.5556vw);
    }
}

.box-agree {
    &+.btn-check {
        margin-top:8.3333vw;
        label {
            span {font-weight:400;}
        }
    }
}

.btn-mark {
    position:relative;width:100%;
    .mark {
        position:absolute;left:50%;top:-4.1667vw;z-index:1;height:7.7778vw;padding:0 4.1667vw;background:#eb068b;white-space:nowrap;transform:translateX(-50%);
        @include smallTxt;font-style:normal;color:$white;line-height:7.7778vw;
        &::before {content:'';display:block;position:absolute;left:50%;bottom:-0.9722vw;width:2.0833vw;height:2.0833vw;background:#eb068b;transform:rotate(45deg);}
    }
}

/*** usim 단독 ***/
.usim {
    .point {color:$purple;font-size:inherit;font-weight:inherit;line-height:inherit;}
    .box-tooltip {
        .txt-tooltip {
            bottom:auto;top:7.7222vw;left:-26.6667vw;z-index:5;
            &>span {

                &::after {bottom:auto;top:-2.5000vw;transform:rotate(-135deg);}
                strong {display:block; margin-bottom:2.7778vw; font-size: 5vw; font-weight: 600; line-height: 1.11;letter-spacing: -0.1vw; text-align: left; color: #191919;}
            }
        }
        &.tip-priority {z-index: 100;}
    }

    .unit-item{
        margin-top: 8.3333vw;
    }
    .usim-esim-unique-check {
        .txt-red {display:block;margin-top:2.7778vw; color:#cf192b;}
        .tab-contents-wrap {
            .desc-txt + section {margin-top:5.5556vw}
        }
    }

    .link-box {
        display:block;margin-top:2.7778vw;font-style:normal;font-size:0;
        /*display:block;margin-top:2.7778vw;padding-left:2.2222vw;font-style:normal;font-size:0;
        a {
            display:inline-block;padding-right:7.2222vw;
            @include backgroundImg('/assets/images/icon/ico-arrow-circle.svg', right 0.1389vw top 0.5556vw, 4.4444vw 4.4444vw);
            @include mainEmphasize;
        }*/
        &+.txt-dot {margin-top:4.1667vw;}
    }
    .notification {
        &>li {
            @include smallTxt;line-height:1.33;
            &>b {display:block;margin-bottom:1.3889vw;@include defaultTxt;}
        }
    }
    .box-btn {
        &.result-more {padding:5.5556vw 0;text-align:center;}
    }
    .bang-notice {line-height:1.33;}

    // USIM/eSIM 가입
    .usim-esim-join{
        .payment-imgCard-btn{
            .cardList{
                padding: 8.3333vw 5.5556vw;
                .item {
                    &-top{
                        display: flex; gap: 5.5556vw;
                        img{display: block; width: 27.7778vw;}
                        .txt-top{
                            strong{
                                display: block; @include mainTxt;
                            }
                            p{
                                @include smallTxt; margin-top: 1.3889vw;
                            }
                        }
                    }
                    &-bottom{
                        padding-top: 5.5556vw; border-top: 0.2778vw solid $gray02;
                        .box-btn{
                            display: inline-flex;flex-direction: column; gap: 2.7778vw;
                            .btn-text-arrow{
                                border: none; padding: 0 7.2222vw 0 0;
                            }
                        }
                        .btns{
                            margin-top: 8.3333vw;
                        }
                    }
                }
            }
        }
        .usim-review {margin-top:11.1111vw;}
    }
    .usim-esim-phone-info {
        .radio-group-wrap{
            .btn-radio{
                label{
                    padding: 3.0556vw 5.5556vw; text-align: right;
                    &.icon-iphone{
                        @include backgroundImg('/assets/images/icon/logo-apple-radio.png', 2.7778vw center, 16.6667vw 11.1111vw);
                    }
                    &.icon-galaxy{
                        @include backgroundImg('/assets/images/icon/logo-galaxy.svg', 2.7778vw center, 16.6667vw 11.1111vw);
                    }
                }
            }
        }
        .no-result{
            margin-top: 11.1111vw;
        }
        .box-table{
            .table-title {font-size: 4.4444vw; line-height: 1.25; }
            .table-type-04 {
                th{
                    padding: 5.5556vw 0;
                }
                td{
                    padding: 5.5556vw 0;
                }
                tbody{
                    .btn-radio-type2{
                        label{
                            strong{
                                display: block; flex-shrink: 0; @include smallTxt;
                                span{display: inline-block; margin-right: 0;}
                                &:nth-of-type(1) {
                                    width: 20%; text-align: center;
                                }
                                &:nth-of-type(2) {
                                    width: 40%; color: $black;
                                }
                                &:nth-of-type(3) {
                                    width: 40%;
                                }
                            }
                        }
                        input[type=radio] {
                            &:checked + label {
                                strong{
                                    color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }
        .box-input {
            & > .box-btn{margin-top: 2.7778vw;}
        }
    }
    .usim-esim-phone-choose{
        .radio-list{
            display: flex; flex-direction: column; gap: 5.5556vw;
        }
    }
    .usim-esim-unique-num{
        .bg-box{
            & + .box-btn{
                margin-top: 4.1667vw;
                .btn-text-arrow{margin-top: 5.5556vw;}
            }
        }
    }
    .usim-esim-unique-check{
        section{
            & + section{margin-top: 11.1111vw;}
        }
        .desc-txt{
            @include smallTxt;
            & + .desc-txt{margin-top: 2.7778vw;}
            & + section{margin-bottom: 5.5556vw;}
        }
        .small-txt{
            & + section{margin-top: 5.5556vw;}
        }
        .main-txt {
            & + p{margin-top: 1.3889vw;}
        }
        .cardList-wrap + .box-tab  {margin-top:11.1111vw;}
        .notification{margin-top: 1.3889vw;}
        .img-wrap{
            margin: 4.1667vw 0;
            img{display: block;}
            &.type2{
                margin-bottom: 0;
                img{max-width: 66.6667vw; margin: 0 auto;}
            }
        }
        .cardList-wrap{
            .cardList{
                & > div{
                    & + div{margin-top: 4.1667vw;}
                    strong{@include defaultTxt;}
                }
            }
        }
    }
    .usim-esim-support{
        .cardList{
            .phone-list{
                display: flex; flex-wrap: wrap; gap: 2.7778vw 0;
                li{
                    width: 50%;
                    @include smallTxt;
                }
            }
        }
    }

    .subscribe-adress {
        .buy-finish {margin-bottom:4.1667vw;padding-top:0;}
        .ligt-gray-box {
            width:100%; background:$bg; padding:5.5556vw; box-sizing: border-box; border-radius:2.7778vw; margin-bottom: 11.1111vw; margin-top: 2.7778vw;
            h3 {@include mainTxt;}
            .desc {
                margin-top:2.2222vw;
                @include defaultTxt;
            }
            .desc-ex {
                margin-top:2.2222vw;
                li {@include smallTxt;}
            }
        }
        &.today {
            .box-flex {margin-bottom:4.1667vw;}
            .search-input-warp {margin-bottom:11.1111vw;}
            .bang-notice {margin-top:5.5556vw;}
        }
        .search-result {
            margin-top:11.1111vw;
            .search-result-title{
                display: flex; justify-content: space-between;
                .img{
                    width:32.5vw;
                }
            }
            .search-result-location{
                li{
                    border-top:0.2778vw solid $gray02; border-bottom:0.2778vw solid $gray02;  padding:5.5556vw 4.1667vw;
                    p{
                       @include defaultTxt; font-weight: 400;
                    }

                }
            }
            .result-noti {margin-bottom:4.1667vw;@include mainTxt;}
            .wide-box {
                margin-bottom:4.1667vw;border-bottom:0.2778vw solid #ddd;
                label {background:none;color:$black;}
                .ico-radio {display:none;}
            }
            .box-btn {margin-bottom:5.5556vw;}
        }
    }
    .btn-dark-gray {
        &.h-sm {padding:3.3333vw 0;color:$white;}
    }
    .ualmo-point {
        .noti {margin-top:2.7778vw;}
    }

    .directmall {
        section {
            &+section {margin-top:11.1111vw;}
        }
        .txt-tooltip{
            &.bottom{
                left:-14.2vw;
            }
        }
        h2 {
            display:block;margin:5.5556vw 0 2.2222vw;@include defaultTxt;
            &:first-of-type {margin-top:0;}
            &+.box-flex {padding-top:0;}
        }
        .box-flex {
            &+.box-flex {padding-top:2.2222vw;}
            .success {
                &+.box-btn {display:none;}
            }
        }
        .box-input{
            &.error{
                padding-bottom: 6.9444vw;
                .txt-error{
                    position:absolute;
                }
            }
        }
        .select-input-wrap {
            &+.box-btn {
                margin-top:2.7778vw;
                button, a {width:100%;}
            }
        }
        .box-input {
            .txt-description {display:block;margin-top:2.2222vw;@include smallTxt;color:$black;line-height:1.33;}
            &.success {
                .input-wrap {display:none;}
                .txt-description {color:#1baa46;}
            }
        }
        .box-btn {
            &+.box-flex {padding-top:2.7778vw;}
        }
        .btn-telecom {width:20.8333vw;padding:4.4444vw 0;}
        .box-present-corp {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 30%;align-self:center;margin-bottom:4.1667vw;}
            &>li.btn-combo {
                display:flex;justify-content:flex-start;
                .btn-radio-type2 {align-self:center;width:auto;height:5.5556vw;}
                .box-select {
                    align-self:center;width:auto;min-width:25.8333vw;
                    select {width:auto;min-width:25.8333vw;}
                }
            }
        }
        .buy-finish {
            h2 {
                margin-bottom:11.1111vw;@include subContsTitle;
                &+.txt {margin:-9.7222vw 0 11.1111vw;@include smallTxt;line-height:1.33;}
            }
            .detail-sec-desc {
                margin-bottom:11.1111vw;
                .main-txt{margin-bottom:2.7778vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;}
            }

            .cardList-wrap {
                &+.cardList-wrap {margin-top:5.5556vw;}
                .cardList {
                    dl {
                        dt {width:31.0000vw;}
                        dd {width:calc(100% - 31.0000vw);}
                    }
                    .box-btn {
                        margin-top:4.1667vw;
                        button {width:100%;}
                    }
                }
            }

        }
        .purchase-num {
            display:flex;justify-content:space-between;margin-bottom:5.5556vw;
            .box-num {
                display:flex;justify-content:space-between;width:29.8611vw;
                .num {
                    width:11.1111vw;border:none;@include defaultTxt;text-align:center;
                    &:disabled {background:$white;}
                }
                button {
                    display:block;overflow:hidden;width:8.3333vw;height:8.3333vw;font-size:0;text-indent:-9.7222vw;
                    &.btn-minus {
                        @include backgroundImg('/assets/images/icon/ico-btn-minus.svg', center center,contain);
                        &.disabled{
                            background-image: url('/assets/images/icon/ico-btn-minus-disabled.svg');
                        }
                    }
                    &.btn-plus {
                        @include backgroundImg('/assets/images/icon/ico-btn-plus.svg', center center, contain);
                        &.disabled{
                            background-image: url('/assets/images/icon/ico-btn-plus-disabled.svg');
                        }
                    }

                }
            }
        }
        .ualmo-point {margin-bottom:5.5556vw;}
        .payment-method {
            margin-bottom:5.5556vw;
            .btn-radio {
                label {
                    display:flex;justify-content:center;align-items:center;min-height:11.1111vw;padding:0;
                    img {max-height:6.9444vw;}
                }
            }
        }
        .btn-sum {
            display:flex;flex-wrap:wrap;border-top:0.2778vw solid $gray02;background:$white;
            .payment-sum {
                display:flex;justify-content:space-between;align-self:stretch;width:100%;height:15.0000vw;padding:0 5.5556vw;
                h2 {align-self:center;margin-bottom:0;}
                .sum {
                    align-self:center;
                    @include smallTxt;line-height:1.33;text-align:right;
                    strong {@include subContsTitle;color:$purple;line-height:5.5556vw;}
                }
                .dc-price {display:inline-block;margin-right:1.1111vw;font-size:4.1667vw;color:#959595;text-decoration: line-through;}
            }
            button {flex:1 1 40%;}
        }
        
        &-view {
            padding-top:0;
            .product-info {
                margin-bottom:11.1111vw;padding-bottom:8.3333vw;border-bottom:0.2778vw solid $gray02;
                .product {
                    display:flex;justify-content:center;align-items:center;position:relative;height:72.2222vw;margin:0 0 8.3333vw;background:$bg;
                    img {width:72.2222vw;}
                }
                .btn-share {
                    position:absolute;right:5.5556vw;top:5.5556vw;width:8.8889vw;height:8.8889vw;border-radius:50%;border:0.2778vw solid #e6e6e6;font-size:0;text-indent:-10.0000vw;
                    @include backgroundImg('/assets/images/icon/ico-share.svg', center center, 8.8889vw 8.8889vw);background-color:$white;
                }
                dl {
                    display:flex;justify-content:space-between;padding:0 5.5556vw;
                    dt {display:block;margin-bottom:1.3889vw;@include subContsTitle;}
                    dd {
                        @include smallTxt;line-height:5.5556vw;
                        strong {@include subContsTitle;color:$purple;line-height:5.5556vw;}
                        .dc-price {display:inline-block;margin-right:1.1111vw;font-size:4.1667vw;color:#959595;text-decoration: line-through;}
                    }
                }
                .usim-buy-notice{padding: 0 5.5556vw;}
            }
            .banner-box {
                margin:11.1111vw 0;
                img {vertical-align: top;}
            }
            h2 {display:block;margin:11.1111vw 0 5.5556vw;@include subContsTitle;}
            h3 {display:block;margin:0 0 2.7778vw;@include defaultTxt;}
            .txt {
                @include smallTxt;line-height:5.5556vw;
                &+.txt {margin-top:1.3889vw;}
            }
            .slide-box {
                margin:0 0 11.1111vw;
                .slick-default {
                    .slick-slide{
                        height: 44.4444vw;
                        img{
                            height: 100%; object-fit: contain;
                        }
                    }
                    &>div {
                        a {
                            &.btn-play {
                                position:relative;
                                &::before {
                                    content:'';display:block;position:absolute;left:50%;top:50%;width:19.4444vw;height:19.4444vw;margin:-9.7222vw 0 0 -9.7222vw;
                                    @include backgroundImg('/assets/images/icon/ico-cir-play.png', center center, 19.4444vw 19.4444vw);
                                }
                            }
                        }
                    }
                    .slick-dots{
                        position: static; margin-top: 4.1667vw; font-size: 0; line-height: 0;
                    }
                }
            }
            .step-list {
                margin-bottom:11.1111vw;padding:5.5556vw;border:0.2778vw solid $gray02;border-radius:2.7778vw;
                li {
                    &+li {margin-top:8.3333vw;}
                }
                .step-box {
                    .step {
                        display:inline-block;height:6.9444vw;margin-bottom:2.7778vw;padding:0 3.1944vw;border-radius:3.4722vw;background:$black;vertical-align:top;
                        @include defaultTxt;color:$white;line-height:6.9444vw;
                    }
                    .tit {display:block;@include defaultTxt;}
                    .txt {display:block;margin-top:2.7778vw;@include smallTxt;line-height:1.33;}
                    .logo {
                        display:flex;justify-content:flex-start;gap:2.7778vw;margin-top:5.5556vw;
                        img {height:4.1667vw;}
                    }
                    .link-box {
                        margin-top:4.1667vw;
                        a {font-weight:400;}
                    }
                }
                &+h3 {margin-bottom:1.3889vw;}
            }

            .openmarket-list-noti {@include defaultTxt;}
            .openmarket-list {
                .cardList-wrap {
                    &+.cardList-wrap {margin-top:4.1667vw;}
                    .cardList {
                        transition:0.3s;;
                        &:hover {border-color:$purple;}
                    }
                    .box-btn{
                        margin-top: 4.1667vw;
                    }
                    .box-btn.col02{
                        display: flex; flex-direction: row; gap: 2.7778vw;
                       .btns  {width:17.6389vw;min-height: 13.8889vw;padding: 3.3333vw 0;color: #fff;}
                    }
                    .btns {min-height: 13.8889vw;padding: 3.3333vw 0;color: #fff;}
                }

                .using-market-col02 .itm-wrp {
                  & + .itm-wrp {margin-top:5.5556vw; border-top:1px solid #ddd;}
                  .pc-support {
                    margin-top:4.1667vw; padding:5.5556vw 4.1667vw;border-radius: 1.9444vw; font-size: 3.8889vw; font-weight: 600; line-height: 1.43; letter-spacing: -0.0778vw; text-align: left; color: #191919;   background-color: #f8f8f8;
                    span {font-size: 3.8889vw; font-weight: 600;color:#662d91}
                }
                .item-top {
                    display:block;padding:0;
                    .market .logo img { height: 4.1667vw;  vertical-align: top;}
                }
                .item-bottom {
                    display: flex;  justify-content: space-between;  padding-top: 5.5556vw;
                    .pentg {display: block;text-align: right; font-size: 4.1667vw; line-height: 1.2; letter-spacing: -0.0833vw; color: #662d91;}
                    .info-tit {font-size: 4.4444vw; font-weight: 600; line-height: 1.25;letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                }
                .box-btn {margin-top:4.1667vw;}
                .discount {
                    font-size: 5vw;font-weight: 600; line-height: 1.16; letter-spacing: -.1vw; color: #662d91; text-align: right;
                    span {margin-right: 1.3889vw; color: #959595; font-size: 4.1667vw; font-weight: 400; line-height: 1.3; letter-spacing: -.0833vw; text-decoration: line-through;}
                }
                }
            }
            .store-list-noti {
                margin-bottom:2.0833vw;@include defaultTxt;
                .p-clor { color: #e60033;font-size: 4.4444vw;font-weight: 600;}
                + .unit-txt{
                    .txt{@include defaultTxt;}
                }
            }
            .btn-group{
                .btn-mark{
                    .banner-randombox-wrap{
                        left: 5.5556vw; bottom: 18.0556vw;
                    }
                }
            }
        }
    }
    //팝업
    .map-view {
        .box-btn {margin-top:4.1667vw;}
        .map-wrap {margin-top:5.5556vw;}
    }
    .delivery {
        &-tit {
            margin-bottom:4.1667vw;
            h2 {display:inline-block;margin:0; @include defaultTxt;vertical-align:top;}
            .tooltip-wrap {display:inline-flex;margin:0.6944vw 0 0 2.7778vw;vertical-align:top;}
        }      
        &-way {
            margin-bottom:11.1111vw;
            &.btn-radio-group {
                .btn-radio + .btn-radio {margin-top:0;}
            }
            .btn-radio {
                &+.btn-radio {margin-top:4.1667vw;}
                label {
                    display:flex;justify-content:center;align-items:center;line-height:normal;
                    img {
                        display:inline-block;margin-right:1.3889vw;vertical-align:middle;
                        &.mark-ico {height:6.2500vw;}
                        &.mark-logo {height:4.8611vw;}
                    }
                }
            }
        }
        &-place {
            margin-bottom:11.1111vw;
            button, a {width:100%;}
        }
        &-request {
            margin-top:5.5556vw;
            h2 {display:block;margin-bottom:2.2222vw;}
            .textarea-wrap {margin-top:2.7778vw;}
        }
        &-recipient {
            margin:8.3333vw 0;
            .delivery-tit {
                display: flex;gap:2.7778vw;
                .btn-check {display:inline-block;width:50%;}
            }
            .box-input.error {
                .txt-error {position:static;}
            }
            .txt-msg {margin-top:2.0833vw;font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #662d91;}
        }
    }
    .one-chip {
        position: relative; z-index: 5;        
        .tooltip-wrap {
            position: absolute; right:0; top:0;
            .btn-tooltip {
                display:block;position:relative; width:25.8333vw; height:5.5556vw; padding-left:2.7778vw; border-radius: 13.8889vw; background-image: none;  background-color: #efd9ff;  font-size: 3.6111vw; font-weight: 500; line-height: 1.54; letter-spacing: -0.0722vw; text-align: left; color: #191919;
                &::after {content:''; position:absolute;right:2.5vw;top:50%;width:2.0833vw; height:2.8056vw; transform: translateY(-50%); background:url('/assets/images/icon/ico-arrow-right02.png') no-repeat 50%; background-size: 3.1667vw;}
            }
            .txt-tooltip { right: 0; left:auto;}
        }
        .btn-close {position:absolute; right:2.7778vw; top:2.7778vw; width: 4.1667vw; height:4.1667vw; background:url('/assets/images/icon/ico-close.svg') no-repeat 50%; background-size:4.1667vw; font-size:0;}
    }

    .delivery-way.usm-tip {margin:0;}
    .usm-tip-box {
        position: relative; margin:2.7778vw 0 8.3333vw 0; z-index: 10;
        p {display:inline-block;  font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.1667vw; text-align: left; color: #666;}
        .tooltip-wrap {
            display:inline-block;
            .box-tooltip {
                .txt-tooltip {
                    left:auto;right:-50%;
                    
                }
            }
        }
    }

    .pickup {
        .search-opt {
            .serach-input-warp {margin-top:2.7778vw;}
            .opt-subway {
                display:flex;flex-wrap:wrap;gap:2.7778vw;padding-top:2.7778vw;
                .box-select {flex:1 1 40%;}
            }
            .ico-search {height:4.4444vw;}
            .btn-radio-group {
                .btn-radio {
                    .radio-link {display: block;padding: 3.4722vw 0; border: .2778vw solid #ddd; border-radius: 1.9444vw;text-align: center; line-height: 1.3;background: #fff;letter-spacing: -.0833vw; color: #666;font-weight: 600;font-size: 4.1667vw;}
                }
            }
        }

        .place-address {
            dd {
                 li + li {margin-top:1.3889vw;}
                 .usm-lengt {
                    font-size: 3.8889vw; font-weight: 600; line-height: 1.43; letter-spacing: -0.0778vw; text-align: left; color: #191919;
                    & + .usm-lengt {margin-left:2.0833vw;}
                    span {font-size: 3.8889vw; font-weight: 600; line-height: 1.43; letter-spacing: -0.0778vw; text-align: left;}
                }
                .msg-quantity {margin-top:2.0833vw; font-size: 3.6111vw;  font-weight: 600; line-height: 1.23; letter-spacing: -0.0722vw; text-align: left; color: #191919;}

            }
        } 
        
    }
    .used-phone {
        .popup-header {text-align:center;}
        .notification {
            li {display:block !important;text-align:left;}
        }
    }
    .delivery-guide {
        .popup-header {text-align:center;}
        .notification {
            li {
                display:block !important;text-align:left;
                .tit {display:block;margin-bottom:1.3889vw;@include defaultTxt;}
            }
        }
    }
    //유심장착 방법 안내
    .usim-install {
        .unit-sec-tit {
            margin-bottom:4.1667vw;
            h3 {@include subContsTitle;}
        }
    }
    //유심개통안내
    .opening-guide{
        .sec{
            margin-top:11.1111vw; position: relative;
            &:first-child {margin-top:0;}
            &.sec-slide {padding-top:11.1111vw;border-top:0.2778vw solid #ececec;}
            &.sec-bnr {
                a {
                    display:flex;justify-content:space-between;padding:5vw 0 5vw 5.5556vw;border-radius:2.7778vw;background:#f8f4ff;
                    .bnr-txt {
                        display: flex; align-items: center; flex-wrap: wrap;
                        .page-tit {
                            margin-bottom: 0;
                            h2{
                                font-size: 5.5556vw; font-weight: 400;
                            }
                            &+span {margin-top:4.1667vw;}
                        }
                    }
                    .bnr-img {max-width:42.5vw;}
                }
            }
            .banner-randombox-wrap{
              left: 0; bottom: 13.8889vw;
                .banner-close{
                    right: -8.5vw; top: 7vw;
                }
            }
        }
        .page-tit {
            margin-bottom: 5.5556vw;
            p {margin-top: 1.3889vw;}
        }
        .vdo-box {height:50vw;margin:5.5556vw 0 4.1667vw 0;}
        .tab-btn-wrap {
            width:100%;
            li {
                width: 48%;
                a { width:100%; margin:0; }
            }
        }
        .tab-desc {
            .page-tit {
                margin-bottom: 0;
                &+.page-tit{padding-top: 5.5556vw;}
                h3 {@include mainTxt;}
                p,
                .notification {@include smallTxt; margin-top: 12px;}
            }
            .cardList-wrap {
                margin-top:2.7778vw;
                .cardList-title {display:block;margin-bottom:2.7778vw;@include subContsTitle;}
                .underLine {
                    padding:0 5.5556vw;
                    dl {
                        width:100%; display: flex;align-items: center; border-bottom:0.2778vw solid #ddd; padding:5.5556vw 0;
                        &:last-child { border-bottom:0;}
                        dt {width:16.6667vw;}
                        dd {
                            padding-left: 2.7778vw; width:calc(100% - 16.6667vw);
                            strong {
                                @include defaultTxt; display: block;
                                &+p {margin-top:1.3889vw;}
                            }
                            span{@include smallTxt; }
                            p {
                                &+p {margin-top:1.3889vw;}
                            }
                            .btn-text-arrow {margin-top:2.7778vw;}
                        }
                    }
                }
                .cardList {
                    &>p {
                        line-height:1.33;
                        &+p {margin-top:1.3889vw;}
                    }
                }
            }
           .btn-text-arrow { margin-top: 4.1667vw;}
        }
        .slick-default {padding-bottom:6.3889vw;}
        .slick-dots {bottom:0;}

        &.esim-guide{
            .sec-title{
                @include subContsTitle;
                & + .box-btn{margin-top: 5.5556vw;}
            }
            .sec-desc{@include smallTxt; margin-top: 1.3889vw; }
            .flex-box{
                display: flex; align-items: center; gap: 5.5556vw;
            }
            .qr-wrap{
                margin-top: 2.7778vw;
                .qr {
                    &-img {
                        width: 25vw; height: 25vw; border-radius:1.3889vw; border: 0.2778vw solid $gray02; padding: 4.1667vw; flex-shrink: 0;
                        img{display: block; width: 100%;}
                    }
                    &-desc {
                        @include smallTxt;
                    }
                }
            }
            .cardList-wrap {
                margin-top: 5.5556vw;
                .cardList {
                    padding: 5.5556vw;
                    & + .cardList {margin-top: 4.1667vw;}
                    &.underLine{
                        .icon-wrap{
                            width: 16.6667vw; height: 16.6667vw;
                            img{display: block; width: 100%;}
                        }
                        dd{
                            strong{@include defaultTxt;}
                        }
                        .line-top{
                            flex-wrap: nowrap; align-items: center; gap: 2.7778vw;
                        }
                        .line-bottom{
                            padding-top: 5.5556vw; margin-top: 5.5556vw;  border-top: 0.2778vw solid $gray02;
                            &.flex-box{
                                gap: 2.7778vw;
                            }
                            p {
                                @include smallTxt;
                                & + p {margin-top:2.7778vw;}
                            }
                        }
                    }
                    .link-list{
                        display: block;
                        dt {
                            @include defaultTxt; padding: 0; margin-bottom: 1.3889vw;width: 100%;
                        }
                        dd {
                            width: 100%; padding-bottom: 0;
                            .btn-text-arrow{font-weight: 400;}
                            p{@include smallTxt;}
                        }
                        dd + dt {margin-top: 4.1667vw;}
                    }
                }
            }
            .num-process-wrap{
                margin-top: 5.5556vw;
                li{
                    padding-bottom: 9.7222vw;
                    &:last-of-type{padding-bottom: 0;}
                }
            }
            .accordian-list{
                width:100%; margin-top: 11.1111vw;
                .accordian{
                    border-bottom:0.2778vw solid $gray02;
                    .acc-trigger{
                        height: 16.6667vw; padding-bottom:0; margin-bottom: 0;
                    }
                    .acc-conts{
                        background:$white; padding:5.5556vw 0 11.1111vw;border-top:0.2778vw solid $gray02; border-radius: 0;
                    }
                    .conts-wrap{
                        & + .conts-wrap {margin-top: 13.8889vw;}
                        .main-txt{
                            display: block; margin-bottom: 2.7778vw;
                        }
                        .qr-img{
                            display: block; margin: 0 auto; width: 41.6667vw;
                        }
                    }
                    .slick-default {
                        .slide-desc{
                            @include smallTxt; margin-top: 2.7778vw;
                        }
                    }
                }
            }
            .item-wrap{
                margin-top: 5.5556vw;
                li{
                    &+li{
                        margin-top: 5.5556vw;
                    }
                }
            }
            .item {
                .video-img{
                    width:100%; height:auto; overflow:hidden; border-radius:2.7778vw;
                    iframe{
                        width:100%; height:55.5556vw; display: block;
                    }
                }
                .video-title {
                    @include mainTxt; text-align: center; margin-top:2.7778vw;
                }
            }
        }
    }

    .eism-banner {
        margin:0 -5.5556vw; padding:0 5.5556vw 13.8889vw; background-color: #3aaaf5;
        .txt-bx {
            margin-bottom:5.5556vw; padding-top:16.6667vw;text-align: center; 
            strong {display:block;font-size: 7.2222vw; font-weight: 700;line-height: 1.21;letter-spacing: -1.04px;text-align: center;color: #fff;}
            span {
                display:block; margin-top:2.7778vw; font-size: 5vw; font-weight: normal; line-height: 1.25;letter-spacing: -0.72px;text-align: center;color: #fff;
                strong {display:inline; font-weight:700; font-size: 5vw;}
            }
        }
    }
    .publicity-box {
        display:flex;align-items: center; height: 45.1389vw; padding: 0 0 0 5.5556vw;  border-radius: 20px; background-color: #2553ce;
        .txt-bx {
            .bx-tit {
                font-size: 5.5556vw;  font-weight: 700; line-height: 1.3;letter-spacing: -0.1111vw;  text-align: left; color: #fff;
                del {font-size: 5.5556vw; font-weight: normal; color:#fff;}
                strong {font-size: 5.5556vw; font-weight:700; color:#faff6b}
            }
            .bx-txt {margin-top:2.7778vw; font-size: 3.8889vw; font-weight: normal; line-height: 1.43; letter-spacing: -0.0778vw; text-align: left; color: #cbd9ff;}
        }
        img {width:34.7222vw;}
    }

    .esim-dscrn{
        .dscrn {
            padding-bottom:13.8889vw;
            &:nth-child(2) { margin:0 -5.5556vw; padding:13.8889vw 5.5556vw; background:#f9faff}
            .tit {
                margin-bottom:5.5556vw; font-size: 5vw; line-height: 1.19;letter-spacing: -0.1vw;text-align: left; color: #191919;
                strong {display:block;margin-top:2.7778vw; font-size:6.3889vw;font-weight:700; color:#191919}
            }
            .txt {
                margin-top:5.5556vw;  font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #191919;
                span {display:block; margin-bottom:2.7778vw;font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666; }
            }
        }
        .btns {margin-top:5.5556vw;}
    }
    .opening-guide .sec.t-spc {margin:0 -5.5556vw;}
    .esim-self { 
        padding:13.8889vw 5.5556vw 0;background-image: linear-gradient(175deg, #feefff, #fffaff 62%, #fff);
        .txt-box{
             p {
                font-size: 5vw;font-weight: normal; line-height: 1.2;letter-spacing: -0.1278vw; text-align: left; color: #191919;
                strong {font-size:6.3889vw; font-weight:700; color:#191919}
            }
            p + p {margin-top:1.3889vw; font-size:5.2778vw; font-weight:700;}

        }
    }
    .esim-inf {
        padding:13.8889vw 0; background-color: #f3fbff;
       .inf-inner {padding:0 5.5556vw;}
        &.no-bg {
            background:none;padding-bottom:0;
            .tit {margin-bottom:5.5556vw;}
        }
        .tit {
            font-size: 5vw;font-weight: normal;line-height: 1.19;letter-spacing: -0.1vw;text-align: left;color: #191919;
            strong {display:block;margin-top:2.7778vw; font-size: 6.3889vw;  font-weight: 700; line-height: 1.2; letter-spacing: -0.1278vw; text-align: left; color: #191919;}
        }
        img + p {margin-top:5.5556vw; font-size: 4.1667vw;font-weight: normal; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919; }
    }
    .usim-buy-notice{
        font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.125vw; color: #ec008c; margin-top: 1.3889vw;
        & + .openmarket-list{margin-top: 4.1667vw;}
    }
}

// 통신사 바텀시트
.transfer {
    &-pre-agree {
        .txt {@include smallTxt;line-height:5.5556vw;}
        .point {color:$purple;}
        .popup-body {
            text-align:left;
            .txt {
                &+.notification {
                    margin-top:2.7778vw;
                    &+.txt {margin-top:2.7778vw;}
                }
                &+.txt {margin-top:1.3889vw;}
            }
            .notification {
                &>li {color:$gray05;font-weight:400;}
            }
        }
        .auth-box {margin-bottom:5.5556vw;}
        .auth-conts{
            margin: 4.1667vw 0 5.5556vw 0;
        }
        .auth-ars {
            .ars-num {
                display:flex;justify-content:center;flex-flow:column;align-items:center;width:100%;height:30.4167vw;margin-top:4.1667vw;border-radius:2.7778vw;border:0.2778vw solid $gray02;@include defaultTxt;line-height:5.5556vw;
                b {width:100%;margin-top:2.7778vw;@include subContsTitle;line-height:5.5556vw;text-align:center;}
                &+.bang-notice {margin-top:1.3889vw;}
            }
            .box-btn {margin-top:4.1667vw;}
        }
    }
}
.transfer-telecom {
    .telecom-top{
        width: 100%; padding: 0 5.5556vw 6.9444vw 5.5556vw; background: #fff;
        .corp-tit {display:block;margin-bottom:5.5556vw;@include mainTxt;}
        .corp-list {
            margin-bottom:8.3333vw;
            .btn-radio {
                label{
                    display: flex; align-items: center; justify-content: center;line-height: initial;
                    &::before {
                        content: '';
                        display: inline-block; margin-right: 1.3889vw; width: 5.5556vw; height: 5.5556vw;
                        @include backgroundImg('/assets/images/icon/logo-skt.png', center, 5.5556vw 5.5556vw);
                    }
                }
                &:nth-of-type(2) {
                    label::before {
                        background-image: url('/assets/images/icon/logo-kt.png');
                    }
                }
                &:nth-of-type(3) {
                    label::before {
                        background-image: url('/assets/images/icon/logo-lg.png');
                    }
                }
            }
            & ~ .btn-radio-group {
                .btn-radio{
                    height: 16.1111vw;
                    label {
                        height: 100%; padding: 0; display: flex; justify-content: center; align-items: center;
                    }
                }
            }
        }
    }
    .popup-body {
        overflow-y:auto;max-height:75.0000vw;text-align:left; padding-top: 0 !important;
        .telecom-list {
            .btn-radio {
                label {font-size:3.0556vw; letter-spacing: -0.25vw;}
            }
        }
    }

    .telecom-tit {display:block;margin-bottom:2.2222vw;@include defaultTxt;}
}

// 랜덤박스 기획전 배너
.banner-randombox-wrap{
    position: absolute; z-index: 1; display: none !important;
    .banner-randombox-cont{
        position: relative; width: 69.4444vw; height: auto; visibility: hidden; animation: box-up .8s cubic-bezier(0.65, 0.05, 0.36, 1) .8s both;
        a{display: block; width: 100%; height: auto;}
        .banner-close{
            display: block; width: 7.7778vw; height: 7.7778vw; position: absolute; bottom: 12.9167vw; right: 0;
        }
        &-02{
            width: 42.2222vw; height: 13.8889vw;
            a{display: block; width: 100%; height: auto;}
        }
    }
}

@keyframes box-up {
    0% { 
        opacity: 0;
        transform: translateY(6.9444vw);
    }
    100% {   
        opacity: 1;
        visibility:visible;
        transform: translateY(0);
    }
}

.onechip {
    .directmall-view{
        .product-info {
            margin-bottom:0; padding-bottom:0; border:0;
            .product {margin:0;} 
        }

        .delivery-msg {
            margin-top:8.3333vw;
            p {color:#191919;font-weight: 600;}
            .notification {
                margin-top:1.3889vw;
                li:last-child {color:#191919}
                strong {font-weight: 700;}                
            }
        }

        .deliver-btn {
            margin:8.3333vw 0 8.3333vw 0; padding-top:8.3333vw; border-top:1px solid #ddd;
            p {margin-bottom:2.0833vw; font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: center; color: #191919;}
        }
        h3 {margin-bottom:8.3333vw;}
        .step-list{
            li {
                .step-box .step-cnt {
                    display: flex; align-items: center;  gap: 5.5556vw;
                    img {width:16.6667vw;}
                    p {
                        font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left;  color: #191919;
                        span {display:block;margin-top:2.7778vw; font-size: 4.1667vw; line-height: 1; letter-spacing: -0.0833vw; text-align: left;  color: #666;}}
                }
            }
        }
        .mark-txt {
            position: relative; margin-top:2.7778vw; padding-left:3.4722vw;font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.0833vw;  text-align: left;color: #666;
            &::before {content:'※';position:absolute; left:0; top:0; color:#666;font-size:4.1667vw;}
        }
    }
    .btn-group {
        .btns.white {color:#191919}
        .btns:disabled {color:#afafaf; background: #ddd;}
        .btns.line {display: flex;flex-direction: column;padding:3.4722vw 0;}
        .btns.line span {font-size: 3.3333vw; line-height: 1.67; letter-spacing: -0.0667vw;  text-align: center; color: #afafaf;}
        .tooltip-wrap {
            display: block; width: 100%;
            .box-tooltip {
                display:block; position: relative; z-index: 1; font-size: 0; width: 100%; height: 100%;
                .btns {width:100%;height:100%;background-image: none;}
                .txt-tooltip {
                    left:0; top:auto; bottom:20.8333vw; margin:0 5.5556vw; padding:5.5556vw;
                    strong {
                        display:inline-block;position: relative; margin-bottom:2.7778vw;  padding-right:6.3889vw; font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.1vw; text-align: left;color: #191919;
                        &::after {content: ''; position:absolute; right:0;top:0; width: 4.8611vw; height:4.8611vw; background:url('/assets/images/icon/ico-search.svg') no-repeat 0 0; background-size: 4.8611vw;}
                    }
                }
            }
        }
    }
    .directmall {
        .box-address .btn-check {
            justify-content: space-between;
        }
    }

}

.now-delivery{
    .box-address {
        .btn-check {justify-content: space-between;}
        & + .box-btn {margin-top:4.1667vw;}
    }
    .delivery-result {
        margin-top:11.1111vw; padding-top:8.3333vw; border-top:1px solid #ddd;
        &.result-view {padding:0; border-top:0;}
        p { font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: center; color: #191919;}
        .box-btn {margin-top:4.1667vw;}
        .result-inner {
            padding: 8.3333vw 5.5556vw; border-radius: 1.9444vw;  background-color: #eff1f5; text-align: center;
            .txt-result {
                display: inline-block; position: relative; padding-left:8.3333vw; font-size: 4.4444vw; font-weight: 600; line-height: 1.19; letter-spacing: -0.0444vw; text-align: center; color: #1baa46;
                &::before {content: ''; position:absolute; left:0;top:50%;width:5.5556vw;height:5.5556vw; margin-top:-2.7778vw; background:url('/assets/images/icon/ico-check-red.svg') no-repeat 50%; background-size: 5.5556vw;}
                &.unable {
                    color:#e34140;
                    &::before {content: '';background:url('/assets/images/icon/ico-close-red.svg') no-repeat 50%;background-size: 5.5556vw;}
                }
            }
            .btn-group {margin:5.5556vw 0 0 0;}
        }
    }  
}

.sub-conts.opening-self {padding:0;}
.selfusim {
    .top-banner {      
        width:100%;margin-bottom:8.3333vw; background: #f9f5ff;  
        .obj-img {
            display: flex; align-items: center; justify-content: space-between; padding:0 0 0 5.5556vw;
           .txt {font-size: 5vw; font-weight: 600; line-height: 1.28; letter-spacing: -0.1vw; text-align: left; color: #191919;}
           img {width:52.7778vw;}
        }
    }
    .selfusim-wrp {
        padding:0 5.5556vw;
        .notification-wrap {
            h3 {font-size: 4.4444vw;font-weight: 600; line-height: 1.25;letter-spacing: -0.0889vw; text-align: left; color: #191919;}
            .notification {margin-top:1.3889vw;}
            .btn-group {margin-top:5.5556vw;}
        }
        .self-usim {
            .title {margin-bottom:5.5556vw;font-size: 5vw; font-weight: 600; line-height: 1.17; letter-spacing: -0.1vw; text-align: left; color: #191919;}
            section {margin-top:11.1111vw;}
            .buy-card-list {
                .txt { font-size: 4.4444vw; font-weight: 600; line-height: 1.25;letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                .buy-list {
                    display: flex;flex-wrap: wrap; gap:2.7778vw; margin-top:4.1667vw;
                    li {
                        width:43.0556vw;padding:3.0556vw 0;  border-radius: 1.9444vw; border: solid 0.2778vw #ddd; background-color: #fff;text-align: center;
                        .tit {display:block; font-size: 4.1667vw; font-weight: 600;line-height: 1.2;letter-spacing: -0.0833vw;text-align: center;color: #666;}
                        .txt {font-size: 4.1667vw; font-weight: normal;line-height: 1.2;letter-spacing: -0.0833vw;text-align: center;color: #666;}
                    }
                }
                .notification {margin-top:2.7778vw;}
            }
            .slick-count {
                position:relative;margin-top:5.5556vw;
                .slick-control {
                    position:absolute; right:4.1667vw;bottom:4.1667vw;
                    .slick-cunt {
                        padding: 0.8333vw 2.2222vw; border-radius: 13.8889vw; background-color: #dadbe4;
                        .first-page { font-size: 3.3333vw;font-weight: 600; line-height: 1.21; letter-spacing: -0.0667vw; text-align: center; color: #666;}
                        .end-page {
                            position: relative; padding-left:2.3611vw; font-size: 3.3333vw; font-weight: 600; line-height: 1.21; letter-spacing: -0.0667vw; text-align: center;color: #959595;
                            &::before {content:'/'; position:absolute;left:0;top:0.2778vw;font-size: 3.3333vw; font-weight: 600; line-height: 1.1; color:#bfbfc5}
                        }
                    }
                }
            }

            .guide-view {
                a + a {margin-top:4.1667vw;}
            }

            .accordian-list{
                width:100%;
                .accordian{
                    border-bottom:0.2778vw solid $gray02;
                    .acc-trigger{
                        height: 13.8889vw; padding-bottom:0; margin-bottom: 0;  font-size: 4.4444vw; font-weight: 600;line-height: 1.5;letter-spacing: -0.0889vw; text-align: left; color: #191919;
                    }
                    .acc-conts{
                        background:$white; padding:5.5556vw 0 11.1111vw;border-top:0.2778vw solid $gray02; border-radius: 0;
                    }
                    .conts-wrap{
                        & + .conts-wrap {margin-top:8.3333vw;}
                        .main-txt{
                            display: block; margin-bottom: 2.7778vw;
                        }
                        .qr-img{
                            display: block; margin: 0 auto; width: 41.6667vw;
                        }
                        .small-txt {margin-bottom: 2.7778vw;}
                        .link-list {
                            display:block;
                            dt {width:100%;margin-bottom:1.3889vw;padding:0; font-size: 4.1667vw;font-weight: 600; line-height: 1.33; letter-spacing: -0.6px;text-align: left;color: #191919;}
                            dd {
                                  width:100%;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;
                                  & + dt {margin-top:4.1667vw;}
                            }
                        }
                    }
                    .slick-default {
                        padding-bottom:11.1111vw;
                        .slide-desc{
                            @include smallTxt; margin-top: 2.7778vw;
                        }
                        .slick-dots {bottom:0}
                    }                 
                }
            }

            .customer-guide {
                margin-top:5.5556vw; padding: 5.5556vw;border-radius: 2.7778vw;border: solid 0.1389vw #ddd; background-color: #fff;
                .guide-box {
                    display:flex;
                    img {width:16.6667vw;}
                    dl {
                        margin-left:2.7778vw;
                        dt {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        dd {margin-top:1.3889vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.125vw;text-align: left;color: #666;}
                        .box-btn {
                            a + a {margin-top:2.0833vw;}
                        }
                    }
                    & + .guide-box {margin-top:5.5556vw;padding-top:5.5556vw; border-top:1px solid #ddd;}                    
                }
            }

            .guide-video-wrp {
                .title {margin-bottom:1.3889vw;}
                .guide-video {
                    margin-top:5.5556vw;
                    .video {width:100%;height:50vw;}
                }
            }

            .qa-bx {
                position: relative;
                a{display: flex;gap:20px;align-items: center; padding: 5.5556vw; border-radius: 2.7778vw; border: solid 0.2778vw #ddd; background-color: #fff;}
                .img-qa {width:16.6667vw;}
                dl {                   
                    dt {margin-bottom:0.6944vw; font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    dd {font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #666;}
                }
                .ico-arrow-right {position:absolute; right:5.5556vw;top:50%;transform: translateY(-50%);}
            }        
           
        }        
    }
    & + .btn-group{
        .banner-randombox-wrap{bottom: 18.0556vw; left: 5.5556vw;}
    }
}

.self-use-tiem {
    .use-msg {
        dt {font-size: 4.1667vw; font-weight: 600;line-height: 1.2;letter-spacing: -0.0556vw;text-align: left;color: #191919;}
        dd {margin-top:1.3889vw; font-size: 16px;line-height: 1.5;letter-spacing: -0.0444vw;text-align: left;color: #666;}
        & +.use-msg {margin-top:5.5556vw;}        
    }    
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  { // 가로화면 대응
.box-address {
    .box-flex {
        padding-top:0;
        .box-input {margin-top:0;}
    }
    .box-input {margin-top:1.7143vw;}
    .btn-check {
        margin-top:1.4286vw;justify-content:flex-end;
        label {width:auto;}
    } /*08.26 우측정렬 변경*/
    .today-noti {
        margin-top:1.4286vw;padding-left:3.5714vw;color:$purple;
        @include backgroundImg('/assets/images/icon/ico-delivery-car.png', left top, 2.8571vw 2.8571vw);
    }
}

.box-agree {
    &+.btn-check {
        margin-top:4.2857vw;
        label {
            span {font-weight:400;}
        }
    }
}

.btn-mark {
    position:relative;width:100%;
    .mark {
        position:absolute;left:50%;top:-2.1429vw;z-index:1;height:4.0000vw;padding:0 2.1429vw;background:#eb068b;white-space:nowrap;transform:translateX(-50%);
        font-style:normal;color:$white;line-height:4.0000vw;
        &::before {content:'';display:block;position:absolute;left:50%;bottom:-0.5000vw;width:1.0714vw;height:1.0714vw;background:#eb068b;transform:rotate(45deg);}
    }
}

/*** usim 단독 ***/
.usim {
    .point {color:$purple;font-size:inherit;font-weight:inherit;line-height:inherit;}
    .box-tooltip {
        .txt-tooltip {
            bottom:auto;top:4.0000vw;left:-13.7143vw;z-index:5;
            &>span {

                &::after {bottom:auto;top:-1.2857vw;transform:rotate(-135deg);}
            }
        }
    }
    .unit-item{
        margin-top: 4.2857vw;
    }
    .link-box {
        display:block;margin-top:1.4286vw;font-style:normal;font-size:0;
        &+.txt-dot {margin-top:2.1429vw;}
    }
    .notification {
        &>li {
           line-height:1.33;
            &>b {display:block;margin-bottom:0.7143vw;}
        }
    }
    .box-btn {
        &.result-more {padding:2.8571vw 0;text-align:center;}
    }
    .bang-notice {line-height:1.33;}

    // USIM/eSIM 가입
    .usim-esim-join{
        .payment-imgCard-btn{
            .cardList{
                padding: 4.2857vw 2.8571vw;
                .item {
                    &-top{
                        display: flex; gap: 2.8571vw;
                        img{display: block; width: 14.2857vw;}
                        .txt-top{
                            strong{
                                display: block;
                            }
                            p{
                                 margin-top: 0.7143vw;
                            }
                        }
                    }
                    &-bottom{
                        padding-top: 2.8571vw; border-top: 0.1429vw solid $gray02;
                        .box-btn{
                            display: inline-flex;flex-direction: column; gap: 1.4286vw;
                            .btn-text-arrow{
                                border: none; padding: 0 3.7143vw 0 0;
                            }
                        }
                        .btns{
                            margin-top: 4.2857vw;
                        }
                    }
                }
            }
        }
    }
    .usim-esim-phone-info {
        .radio-group-wrap{
            .btn-radio{
                label{
                    padding: 1.5714vw 2.8571vw; text-align: right;
                    &.icon-iphone{
                        @include backgroundImg('/assets/images/icon/logo-apple-radio.png', 1.4286vw center, 8.5714vw 5.7143vw);
                    }
                    &.icon-galaxy{
                        @include backgroundImg('/assets/images/icon/logo-galaxy.svg', 1.4286vw center, 8.5714vw 5.7143vw);
                    }
                }
            }
        }
        .no-result{
            margin-top: 5.7143vw;
        }
        .box-table{
            .table-title {font-size: 2.2857vw; line-height: 1.25; }
            .table-type-04 {
                th{
                    padding: 2.8571vw 0;
                }
                td{
                    padding: 2.8571vw 0;
                }
                tbody{
                    .btn-radio-type2{
                        label{
                            strong{
                                display: block; flex-shrink: 0;
                                span{display: inline-block; margin-right: 0;}
                                &:nth-of-type(1) {
                                    width: 20%; text-align: center;
                                }
                                &:nth-of-type(2) {
                                    width: 40%; color: $black;
                                }
                                &:nth-of-type(3) {
                                    width: 40%;
                                }
                            }
                        }
                        input[type=radio] {
                            &:checked + label {
                                strong{
                                    color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }
        .box-input {
            & > .box-btn{margin-top: 1.4286vw;}
        }
    }
    .usim-esim-phone-choose{
        .radio-list{
            display: flex; flex-direction: column; gap: 2.8571vw;
        }
    }
    .usim-esim-unique-num{
        .bg-box{
            & + .box-btn{
                margin-top: 2.1429vw;
                .btn-text-arrow{margin-top: 2.8571vw;}
            }
        }
    }

    .usim-esim-unique-check{
        section{
            & + section{margin-top: 5.7143vw;}
        }
        .desc-txt{
            & + .desc-txt{margin-top: 1.4286vw;}
            & + section{margin-bottom: 2.8571vw;}           
        }     
        .small-txt{
            & + section{margin-top: 2.8571vw;}
        }
        .main-txt {
            & + p{margin-top: 0.7143vw;}
        }
        .notification{margin-top: 0.7143vw;}
        .img-wrap{
            margin: 2.1429vw 0;
            img{display: block;}
            &.type2{
                margin-bottom: 0;
                img{max-width: 34.2857vw; margin: 0 auto;}
            }
        }
        .cardList-wrap{
            .cardList{
                & > div{
                    & + div{margin-top: 2.1429vw;}
                }
            }
        }
    }
    .usim-esim-support{
        .cardList{
            .phone-list{
                display: flex; flex-wrap: wrap; gap: 1.4286vw 0;
                li{
                    width: 50%;
                }
            }
        }
    }

    .subscribe-adress {
        .buy-finish {margin-bottom:2.1429vw;padding-top:0;}
        .ligt-gray-box {
            width:100%; background:$bg; padding:2.8571vw; box-sizing: border-box; border-radius:1.4286vw; margin-bottom: 5.7143vw; margin-top: 1.4286vw;
            .desc {
                margin-top:1.1429vw;
            }
            .desc-ex {
                margin-top:1.1429vw;
            }
        }
        &.today {
            .box-flex {margin-bottom:2.1429vw;}
            .search-input-warp {margin-bottom:5.7143vw;}
            .bang-notice {margin-top:2.8571vw;}
        }
        .search-result {
            margin-top:5.7143vw;
            .search-result-title{
                display: flex; justify-content: space-between;
                .img{
                    width:16.7143vw;
                }
            }
            .search-result-location{
                li{
                    border-top:0.1429vw solid $gray02; border-bottom:0.1429vw solid $gray02;  padding:2.8571vw 2.1429vw;
                    p{
                        font-weight: 400;
                    }

                }
            }
            .result-noti {margin-bottom:2.1429vw;}
            .wide-box {
                margin-bottom:2.1429vw;border-bottom:0.1429vw solid #ddd;
                label {background:none;color:$black;}
                .ico-radio {display:none;}
            }
            .box-btn {margin-bottom:2.8571vw;}
        }
    }
    .btn-dark-gray {
        &.h-sm {padding:1.7143vw 0;color:$white;}
    }
    .ualmo-point {
        .noti {margin-top:1.4286vw;}
    }

    .directmall {
        section {
            &+section {margin-top:5.7143vw;}
        }
        .txt-tooltip{
            &.bottom{
                left:-7.2857vw;
            }
        }
        h2 {
            display:block;margin:2.8571vw 0 1.1429vw;
            &:first-of-type {margin-top:0;}
            &+.box-flex {padding-top:0;}
        }
        .box-flex {
            &+.box-flex {padding-top:1.1429vw;}
            .success {
                &+.box-btn {display:none;}
            }
        }
        .box-input{
            &.error{
                padding-bottom: 3.5714vw;
                .txt-error{
                    position:absolute;
                }
            }
        }
        .select-input-wrap {
            &+.box-btn {
                margin-top:1.4286vw;
                button, a {width:100%;}
            }
        }
        .box-input {
            .txt-description {display:block;margin-top:1.1429vw;color:$black;line-height:1.33;}
            &.success {
                .input-wrap {display:none;}
                .txt-description {color:#1baa46;}
            }
        }
        .box-btn {
            &+.box-flex {padding-top:1.4286vw;}
        }
        .btn-telecom {width:10.7143vw;padding:2.2857vw 0;}
        .box-present-corp {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 30%;align-self:center;margin-bottom:2.1429vw;}
            &>li.btn-combo {
                display:flex;justify-content:flex-start;
                .btn-radio-type2 {align-self:center;width:auto;height:2.8571vw;}
                .box-select {
                    align-self:center;width:auto;min-width:13.2857vw;
                    select {width:auto;min-width:13.2857vw;}
                }
            }
        }
        .buy-finish {
            h2 {
                margin-bottom:5.7143vw;
                &+.txt {margin:-5.0000vw 0 5.7143vw;line-height:1.33;}
            }
            .cardList-wrap {
                &+.cardList-wrap {margin-top:2.8571vw;}
                .cardList {
                    dl {
                        dt {width:15.9286vw;}
                        dd {width:calc(100% - 15.9286vw);}
                    }
                    .box-btn {
                        margin-top:2.1429vw;
                        button {width:100%;}
                    }
                }
            }

        }
        .purchase-num {
            display:flex;justify-content:space-between;margin-bottom:2.8571vw;
            .box-num {
                display:flex;justify-content:space-between;width:15.3571vw;
                .num {
                    width:5.7143vw;border:none;text-align:center;
                    &:disabled {background:$white;}
                }
                button {
                    display:block;overflow:hidden;width:4.2857vw;height:4.2857vw;font-size:0;text-indent:-5.0000vw;
                    &.btn-minus {
                        @include backgroundImg('/assets/images/icon/ico-btn-minus.svg', center center,contain);
                        &.disabled{
                            background-image: url('/assets/images/icon/ico-btn-minus-disabled.svg');
                        }
                    }
                    &.btn-plus {
                        @include backgroundImg('/assets/images/icon/ico-btn-plus.svg', center center, contain);
                        &.disabled{
                            background-image: url('/assets/images/icon/ico-btn-plus-disabled.svg');
                        }
                    }

                }
            }
        }
        .ualmo-point {margin-bottom:2.8571vw;}
        .payment-method {
            margin-bottom:2.8571vw;
            .btn-radio {
                label {
                    display:flex;justify-content:center;align-items:center;min-height:5.7143vw;padding:0;
                    img {max-height:3.5714vw;}
                }
            }
        }
        .btn-sum {
            display:flex;flex-wrap:wrap;border-top:0.1429vw solid $gray02;background:$white;
            .payment-sum {
                display:flex;justify-content:space-between;align-self:stretch;width:100%;height:7.7143vw;padding:0 2.8571vw;
                h2 {align-self:center;margin-bottom:0;}
                .sum {
                    align-self:center;
                    line-height:1.33;text-align:right;
                    strong {color:$purple;line-height:2.8571vw;}
                }
            }
            button {flex:1 1 40%;}
        }
        &-view {
            padding-top:0;
            .product-info {
                margin-bottom:5.7143vw;padding-bottom:4.2857vw;border-bottom:0.1429vw solid $gray02;
                .product {
                    display:flex;justify-content:center;align-items:center;position:relative;height:37.1429vw;margin:0 0 4.2857vw;background:$bg;
                    img {width:28.5714vw;}
                }
                .btn-share {
                    position:absolute;right:2.8571vw;top:2.8571vw;width:4.5714vw;height:4.5714vw;border-radius:50%;border:0.1429vw solid #e6e6e6;font-size:0;text-indent:-5.1429vw;
                    @include backgroundImg('/assets/images/icon/ico-share.svg', center center, 4.5714vw 4.5714vw);background-color:$white;
                }
                dl {
                    display:flex;justify-content:space-between;padding:0 2.8571vw;
                    dt {display:block;margin-bottom:0.7143vw;}
                    dd {
                        line-height:2.8571vw;
                        strong {color:$purple;line-height:2.8571vw;}
                    }
                }
            }
            h2 {display:block;margin:5.7143vw 0 2.8571vw;@include subContsTitle;}
            h3 {display:block;margin:0 0 1.4286vw;}
            .txt {
                line-height:2.8571vw;
                &+.txt {margin-top:0.7143vw;}
            }
            .slide-box {
                margin:0 0 5.7143vw;
                .slick-default {
                    .slick-slide{
                        height: 22.8571vw;
                        img{
                            height: 100%; object-fit: contain;
                        }
                    }
                    &>div {
                        a {
                            &.btn-play {
                                position:relative;
                                &::before {
                                    content:'';display:block;position:absolute;left:50%;top:50%;width:10.0000vw;height:10.0000vw;margin:-5.0000vw 0 0 -5.0000vw;
                                    @include backgroundImg('/assets/images/icon/ico-cir-play.png', center center, 10.0000vw 10.0000vw);
                                }
                            }
                        }
                    }
                    .slick-dots{
                        position: static; margin-top: 2.1429vw; font-size: 0; line-height: 0;
                    }
                }
            }
            .step-list {
                margin-bottom:5.7143vw;padding:2.8571vw;border:0.1429vw solid $gray02;border-radius:1.4286vw;
                li {
                    &+li {margin-top:4.2857vw;}
                }
                .step-box {
                    .step {
                        display:inline-block;height:3.5714vw;margin-bottom:1.4286vw;padding:0 1.6429vw;border-radius:1.7857vw;background:$black;vertical-align:top;
                        color:$white;line-height:3.5714vw;
                    }
                    .tit {display:block;}
                    .txt {display:block;margin-top:1.4286vw;line-height:1.33;}
                    .logo {
                        display:flex;justify-content:flex-start;gap:1.4286vw;margin-top:2.8571vw;
                        img {height:2.1429vw;}
                    }
                    .link-box {
                        margin-top:2.1429vw;
                        a {font-weight:400;}
                    }
                }
                &+h3 {margin-bottom:0.7143vw;}
            }

            .openmarket-list-noti {margin-bottom:2.1429vw;}
            .openmarket-list {
                .cardList-wrap {
                    &+.cardList-wrap {margin-top:2.1429vw;}
                    .cardList {
                        transition:0.3s;;
                        &:hover {border-color:$purple;}
                    }
                    .box-btn{
                        margin-top: 2.1429vw;
                    }
                }
            }
            .store-list-noti {
                margin-bottom:1.0416vw;
                + .unit-txt{
                    margin-bottom:2.1429vw;
                }
            }
        }
    }
    //팝업
    .map-view {
        .box-btn {margin-top:2.1429vw;}
        .map-wrap {margin-top:2.8571vw;}
    }
    .delivery {
        &-tit {
            margin-bottom:2.1429vw;
            h2 {display:inline-block;vertical-align:top;}
            .tooltip-wrap {display:inline-flex;margin:0.3571vw 0 0 1.4286vw;vertical-align:top;}
        }
        &-way {
            margin-bottom:5.7143vw;
            .btn-radio {
                &+.btn-radio {margin-top:2.1429vw;}
                label {
                    display:flex;justify-content:center;align-items:center;line-height:normal;
                    img {
                        display:inline-block;margin-right:0.7143vw;vertical-align:middle;
                        &.mark-ico {height:3.2143vw;}
                        &.mark-logo {height:2.5000vw;}
                    }
                }
            }
        }
        &-place {
            margin-bottom:5.7143vw;
            button, a {width:100%;}
        }
        &-request {
            margin-top:2.8571vw;
            h2 {display:block;margin-bottom:1.1429vw;}
            .textarea-wrap {margin-top:1.4286vw;}
        }
    }
    .pickup {
        .search-opt {
            .serach-input-warp {padding-top:1.4286vw;}
            .opt-subway {
                display:flex;flex-wrap:wrap;gap:1.4286vw;padding-top:1.4286vw;
                .box-select {flex:1 1 40%;}
            }
        }
    }
    .used-phone {
        .popup-header {text-align:center;}
        .notification {
            li {display:block !important;text-align:left;}
        }
    }
    .delivery-guide {
        .popup-header {text-align:center;}
        .notification {
            li {
                display:block !important;text-align:left;
                .tit {display:block;margin-bottom:0.7143vw;}
            }
        }
    }
    //유심장착 방법 안내
    .usim-install {
        .unit-sec-tit {
            margin-bottom:2.1429vw;
        }
    }
    //유심개통안내
    .opening-guide{
        .sec{
            margin-top:5.7143vw;
            &:first-child {margin-top:0;}
            &.sec-slide {padding-top:5.7143vw;border-top:0.1429vw solid #ececec;}
            &.sec-bnr {
                a {
                    display:flex;justify-content:space-between;padding:2.5714vw 0 2.5714vw 2.8571vw;border-radius:1.4286vw;background:#f8f4ff;
                    .bnr-txt {
                        display: flex; align-items: center; flex-wrap: wrap;
                        .page-tit {
                            margin-bottom: 0;
                            h2{
                                font-size: 2.8571vw; font-weight: 400;
                            }
                            &+span {margin-top:2.1429vw;}
                        }
                    }
                    .bnr-img {max-width:21.8571vw;}
                }
            }
        }
        .page-tit {
            margin-bottom: 2.8571vw;
            p {margin-top: 0.7143vw;}
        }
        .tab-btn-wrap {
            width:100%;
            li {
                width: 48%;
                a { width:100%; margin:0; }
            }
        }
        .tab-desc {
            .page-tit {
                margin-bottom: 0;
                &+.page-tit{padding-top: 2.8571vw;}
                p,
                .notification {margin-top: 0.8571vw;}
            }
            .cardList-wrap {
                margin-top:1.4286vw;
                .cardList-title {display:block;margin-bottom:1.4286vw;}
                .underLine {
                    padding:0 2.8571vw;
                    dl {
                        width:100%; display: flex;align-items: center; border-bottom:0.1429vw solid #ddd; padding:2.8571vw 0;
                        &:last-child { border-bottom:0;}
                        dt {width:8.5714vw;}
                        dd {
                            padding-left: 1.4286vw; width:calc(100% - 8.5714vw);
                            strong {
                                display: block;
                                &+p {margin-top:0.7143vw;}
                            }
                            p {
                                &+p {margin-top:0.7143vw;}
                            }
                            .btn-text-arrow {margin-top:1.4286vw;}
                        }
                    }
                }
                .cardList {
                    &>p {
                        line-height:1.33;
                        &+p {margin-top:0.7143vw;}
                    }
                }
            }
           .btn-text-arrow { margin-top: 2.1429vw;}
        }
        .slick-default {padding-bottom:3.2857vw;}
        .slick-dots {bottom:0;}

        &.esim-guide{
            .sec-title{
                & + .box-btn{margin-top: 2.8571vw;}
            }
            .sec-desc{margin-top: 0.7143vw; }
            .flex-box{
                display: flex; align-items: center; gap: 2.8571vw;
            }
            .qr-wrap{
                margin-top: 1.4286vw;
                .qr {
                    &-img {
                        width: 12.8571vw; height: 12.8571vw; border-radius:0.7143vw; border: 0.1429vw solid $gray02; padding: 2.1429vw; flex-shrink: 0;
                        img{display: block; width: 100%;}
                    }
                }
            }
            .cardList-wrap {
                margin-top: 2.8571vw;
                .cardList {
                    padding: 2.8571vw;
                    & + .cardList {margin-top: 2.1429vw;}
                    &.underLine{
                        .icon-wrap{
                            width: 8.5714vw; height: 8.5714vw;
                            img{display: block; width: 100%;}
                        }
                        .line-top{
                            flex-wrap: nowrap; align-items: center; gap: 1.4286vw;
                        }
                        .line-bottom{
                            padding-top: 2.8571vw; margin-top: 2.8571vw;  border-top: 0.1429vw solid $gray02;
                            &.flex-box{
                                gap: 1.4286vw;
                            }
                            p {
                                & + p {margin-top:1.4286vw;}
                            }
                        }
                    }
                    .link-list{
                        display: block;
                        dt {
                             padding: 0; margin-bottom: 0.7143vw;width: 100%;
                        }
                        dd {
                            width: 100%; padding-bottom: 0;
                            .btn-text-arrow{font-weight: 400;}
                        }
                        dd + dt {margin-top: 2.1429vw;}
                    }
                }
            }
            .num-process-wrap{
                margin-top: 2.8571vw;
                li{
                    padding-bottom: 5.0000vw;
                    &:last-of-type{padding-bottom: 0;}
                }
            }
            .accordian-list{
                width:100%; margin-top: 5.7143vw;
                .accordian{
                    border-bottom:0.1429vw solid $gray02;
                    .acc-trigger{
                        height: 8.5714vw; padding-bottom:0; margin-bottom: 0;
                    }
                    .acc-conts{
                        background:$white; padding:2.8571vw 0 5.7143vw;border-top:0.1429vw solid $gray02; border-radius: 0;
                    }
                    .conts-wrap{
                        & + .conts-wrap {margin-top: 7.1429vw;}
                        .main-txt{
                            display: block; margin-bottom: 1.4286vw;
                        }
                        .qr-img{
                            display: block; margin: 0 auto; width: 21.4286vw;
                        }
                    }
                    .slick-default {
                        .slide-desc{
                             margin-top: 1.4286vw;
                        }
                    }
                }
            }
            .item-wrap{
                margin-top: 2.8571vw;
                li{
                    &+li{
                        margin-top: 2.8571vw;
                    }
                }
            }
            .item {
                .video-img{
                    width:100%; height:auto; overflow:hidden; border-radius:1.4286vw;
                    iframe{
                        width:100%; height:28.5714vw; display: block;
                    }
                }
                .video-title {
                    text-align: center; margin-top:1.4286vw;
                }
            }
        }
    }
}

// 통신사 바텀시트
.transfer {
    &-pre-agree {
        .txt {line-height:2.8571vw;}
        .point {color:$purple;}
        .popup-body {
            text-align:left;
            .txt {
                &+.notification {
                    margin-top:1.4286vw;
                    &+.txt {margin-top:1.4286vw;}
                }
                &+.txt {margin-top:0.7143vw;}
            }
            .notification {
                &>li {color:$gray05;font-weight:400;}
            }
        }
        .auth-box {margin-bottom:2.8571vw;}
        .auth-conts{
            margin: 2.1429vw 0 2.8571vw 0;
        }
        .auth-ars {
            .ars-num {
                display:flex;justify-content:center;flex-flow:column;align-items:center;width:100%;height:15.6429vw;margin-top:2.1429vw;border-radius:1.4286vw;border:0.1429vw solid $gray02;line-height:2.8571vw;
                b {width:100%;margin-top:1.4286vw;line-height:2.8571vw;text-align:center;}
                &+.bang-notice {margin-top:0.7143vw;}
            }
            .box-btn {margin-top:2.1429vw;}
        }
    }
}
.transfer-telecom {
    .telecom-top{
        width: 100%; padding: 0 2.8571vw 3.5714vw 2.8571vw; background: #fff;
        .corp-tit {display:block;margin-bottom:2.8571vw;}
        .corp-list {
            margin-bottom:4.2857vw;
            .btn-radio {
                label{
                    display: flex; align-items: center; justify-content: center;line-height: initial;
                    &::before {
                        content: '';
                        display: inline-block; margin-right: 0.7143vw; width: 2.8571vw; height: 2.8571vw;
                        @include backgroundImg('/assets/images/icon/logo-skt.png', center, 2.8571vw 2.8571vw);
                    }
                }
                &:nth-of-type(2) {
                    label::before {
                        background-image: url('/assets/images/icon/logo-kt.png');
                    }
                }
                &:nth-of-type(3) {
                    label::before {
                        background-image: url('/assets/images/icon/logo-lg.png');
                    }
                }
            }
            & ~ .btn-radio-group {
                .btn-radio{
                    height: 8.2857vw;
                    label {
                        height: 100%; padding: 0; display: flex; justify-content: center; align-items: center;
                    }
                }
            }
        }
    }
    .popup-body {overflow-y:auto;max-height:38.5714vw;text-align:left; padding-top: 0 !important;}

    .telecom-tit {display:block;margin-bottom:1.1429vw;}
}
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .usim{
        .usim-esim-join{
            .payment-imgCard-btn{
                .cardList{
                    padding: 30px 20px;
                    .item-top{
                        gap: 15px; padding-bottom: 15px;
                        img{width: 100px;}
                    }
                    .item-bottom{
                        padding-top: 20px; border-top-width: 1px;
                        .box-btn{gap: 10px;
                            .btn-text-arrow{padding-right: 30px;}
                        }
                        .btns{margin-top: 30px;}
                    }
                }
            }
        }
        .usim-esim-support{
            .cardList{
                .phone-list{gap: 10px 0;}
            }
        }
        .usim-install{
            .unit-sec-tit{margin-bottom: 15px;}
        }
        .directmall{
            h2{margin: 20px 0 8px;}
            .btn-telecom{width: 75px; padding: 16px 0;}
            .select-input-wrap{
                & + .box-btn{margin-top: 10px;}
            }
            .box-btn{
                & + .box-flex{padding-top: 10px;}
            }
            section{
                & + section{margin-top: 40px;}
            }
            .txt-tooltip{
                &.bottom{left: -52px;}
            }
            .purchase-num{margin-bottom: 20px;
                .box-num{
                    width: 110px;
                    button{width: 20px; height: 20px;}
                }
            }
            .box-input{
                &.error{padding-bottom: 25px;}
            }
            .payment-method{
                margin-bottom: 20px;
                .btn-radio{ 
                    label{min-height: 40px;
                        img{max-height: 25px;}
                    }
                }
            }
            .ualmo-point{margin-bottom: 35px;}
            .btn-sum{border-top-width: 1px;
                .payment-sum{height: 55px; padding: 0 20px;
                    .dc-price{font-size: 18px;}
                    .sum{
                        strong{color: #662d91;}
                    }
                }
            }
            .buy-finish{
                h2{margin-bottom:40px;
                    & + .txt{margin: -35px 0 40px;}
                }
                .cardList-wrap{
                    & + .cardList-wrap{margin-top: 20px;}
                    .cardList{
                        .box-btn{margin-top: 15px;}
                    }
                }
            }
        }
        .directmall-view{
            .product-info{
                padding-bottom: 30px; margin-bottom: 30px; border-width: 1px;
                .product{
                   height: 260px; margin: 0 0 30px;
                   img{width: 200px;}
                }
                .btn-share{width: 30px; height: 30px; right: 20px; top: 20px; background-size: 31px 31px; border-width: 2px;}
                dl{padding: 0 20px;
                    dd{
                        .dc-price{font-size: 18px;}
                        strong{color: #662d91;}
                    }
                }
                .usim-buy-notice{padding: 0 20px;}
            }
            .banner-box{margin: 55px 0; text-align: center;}
            h2{margin: 40px 0 20px;}
            h3{margin: 0 0 10px;}
            .step-list{
                margin-bottom: 40px; padding: 20px; border-width: 1px; border-radius: 8px;
                .step-box{
                    .step{height: 25px; margin-bottom: 10px; padding: 0 11px; line-height: 25px; color: #fff; border-radius: 20px;} 
                    .link-box{margin-top: 15px;}
                    .txt{margin-top: 10px;}
                    .logo{margin-top: 20px; gap: 20px;
                        img{height: 15px;}
                    }
                }
                li{
                    &+li{margin-top: 30px;}
                }
            }
            .slide-box{
                margin: 0 0 40px;
                .slick-default{
                    .slick-slide{height: 165px;}
                    .slick-dots{margin-top: 15px;
                        li{
                            button{width: 8px; height: 8px;}
                            & + li{margin-left: 5px;}
                            &.slick-active{
                                button{width: 20px;}
                            }
                        }
                    }
                }
            }
            .openmarket-list{
                .cardList-wrap{
                    & + .cardList-wrap{margin-top: 15px;}
                    .box-btn{
                        &.col02{gap: 15px;
                            .btns{width: 50%; min-height: 50px;}
                        }
                        .btns{min-height: 50px;}
                    }
                }
            }
            .store-list-noti{
                margin-bottom: 10px;
                & + .unit-txt{margin-bottom: 15px;}
            }
            .btn-group{
                .btn-mark{
                    .banner-randombox-wrap{left: 20px; bottom: 70px;}
                }
            }
        }
        .ualmo-point{
            .noti{margin-top: 5px;}
        }
        .delivery-tit{margin-bottom: 15px;
            .tooltip-wrap{margin: 2.5px 0 0 10px;}
        }
        .delivery-way{
            margin-bottom: 40px;
            .btn-radio{
                label{
                    img{
                        margin-right: 5px;
                        &.mark-ico{height: 22px;}
                        &.mark-logo{height: 17px;}
                    }
                }
                & + .btn-radio{margin-top: 15px;}
            }
        }
        .delivery-guide{
            .notification{
                li{
                    &.tit{margin-bottom: 5px;}
                }
            }
        }
        .delivery-place{margin-bottom: 40px;}
        .delivery-request{margin-top: 20px;
            .textarea-wrap{margin-top: 10px;}
        }
        .pickup{
            .search-opt{
                .serach-input-warp{padding-top: 10px;}
            }
        }
        .map-view{
            .box-btn{margin-top: 20px;}
        }
        .opening-guide{
            .page-tit{
                margin-bottom: 20px;
                p{margin-top: 5px;}
            }
            .sec{
                margin-top: 40px;
                &.sec-slide{padding-top: 40px; border-width: 1px;}
            }
            .tab-desc{
                .cardList-wrap{
                    .underLine{
                        padding: 0 20px;
                        dl{border-bottom-width: 1px; padding: 20px 0;
                            dt{width: 60px;}
                        }
                    }
                }
            }
            .slick-default{padding-bottom: 20px;}
            .sec{
                & + .sec-bnr{
                    a{padding: 18px 18px 20px;
                        .bnr-txt{
                            flex-direction: column; justify-content: center;
                            .page-tit{
                                h2{font-size: 20px;}
                                & + span{margin-top: 15px;}
                            }
                        }
                        .bnr-img{max-width: 155px;}
                    } 
                }
                .banner-randombox-wrap{
                    bottom: 55px;
                    .banner-close{
                        top: 30px; right: -40px;
                    }
                }
            }
            &.esim-guide{
                .qr-wrap{
                    margin-top: 10px;
                    .qr-img{width: 95px; height: 95px; border-width: 1px; border-radius: 8px; padding: 15px;}
                }
                .flex-box{gap: 15px;}
                .cardList-wrap{margin-top: 20px;
                    .cardList{padding: 20px;
                        &.underLine{
                            .line-top{gap: 15px;}
                            .line-bottom{padding-top: 20px; margin-top: 20px; border-width: 1px;
                                p{
                                    & + p{margin-top: 10px;}
                                }
                            }
                            .icon-wrap{width: 60px; height: 60px;}
                        }
                        .link-list{
                            dd{
                                & + dt{margin-top: 15px;}
                            }
                            dt{margin-bottom: 5px;}
                        }
                    }
                }
                .num-process-wrap{margin-top: 20px;
                    li{padding-bottom: 35px;}
                }
                .accordian-list{margin-top: 40px;
                    .accordian{border-width: 1px;
                        .acc-trigger{height: 60px;
                            &::after{width: 20px; height: 20px;}
                        }
                        .conts-wrap{
                            .qr-img{width: 155px;}
                            & + .conts-wrap{margin-top: 50px;}
                        }
                        .acc-conts{border-width: 1px; padding: 20px 0 40px;}
                    }
                }
                .sec-title{
                    & + .box-btn{margin-top: 15px;}
                }
                .item-wrap{margin-top: 20px;}
                .item{
                    .video-img{
                        iframe{height: 200px;}
                    }
                    .video-title{margin-top: 10px;}
                }
            }
        
        }
        .box-tooltip{
            .txt-tooltip{top: 28px; left: -96px;}
        }
        .box-address{
            .box-input, .btn-check{margin-top: 10px;}
            .box-flex{
                .box-input{margin-top: 0;}
            }
            .today-noti{color: #662d91; margin-top: 10px; padding-left: 25px; background-size: 20px 20px;}
        }
        .box-btn{
            &.result-more{padding: 10px 0;}
        }
        .subscribe-adress{
            .search-result{
                margin-top: 40px;
                .search-result-title{
                    justify-content: flex-start;
                    .img{width: 120px;}
                }
                .search-result-location{
                    li{
                        border-width: 1px; padding: 20px 15px;
                        p{font-weight: 400;}
                    }
                }
            }
            .ligt-gray-box{padding: 20px; border-radius: 8px; margin: 10px 0 40px 0;}
        }     
        .notification{
            & > li{
                & > b{margin-bottom: 5px;}
            }
        }
        .btn-dark-gray{
            &.h-sm{padding: 12px 0;}
        }
        .selfusim{
            & + .btn-group{
                .banner-randombox-wrap{bottom: 70px; left: 20px;}
            }
        }
        .usim-buy-notice{font-size: 16px; margin-top: 10px;}
    }
    .btn-mark{
        .mark{height: 28px; line-height: 28px; padding: 0 15px; top: -15px; color: #fff;}
    }
    .transfer-telecom{
        .layer-popup-item{max-height: 90vh !important;}
        .telecom-top{padding: 0 20px 15px 20px;
            .corp-tit{margin-bottom: 10px;}
            .corp-list{margin-bottom: 15px;
                .btn-radio{
                    label{
                        &::before{width: 20px; height: 20px; background-size: 20px 20px;}
                    }
                }
                & ~ .btn-radio-group{
                    .btn-radio{height: 50px;}
                }
            }
        }
        .popup-body{min-height: 100px; max-height: 150px;}
    }
    // 랜덤박스 기획전 배너
    .banner-randombox-wrap{
        display: none !important; 
        .banner-randombox-cont{
            width: 300px;
            a{width: 100%; height: auto;}
            .banner-close{
                width: 35px; height: 35px; top: -25px;
            }
            &-02{
                width: 200px; height: auto;
            }
        }
    }
}
