@charset "UTF-8";

iframe[title="메시징 창을 시작하는 버튼"]{
// @include backgroundImg('/assets/images/icon/ico-onsale-floating.svg',center center,15vw 15vw);
 z-index: 999999999 !important;
    background-image: url(/assets/images/icon/ico-onsale-floating.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 15vw 15vw !important;
    .iPDnea.iPDnea.iPDnea{
        display: none !important;
    }
}

/*** 공통 ***/
.customer {
    .box-tab {
        .tab-btn-wrap {margin-bottom: 0 !important;}
    }
    .round-tab {
        overflow:hidden;height:18.0556vw;
        .tab-btn-wrap {
            display:flex;justify-content:flex-start;flex-wrap:wrap;margin-bottom: 11.1111vw;padding:3.8889vw 5.5556vw 0;
            li{
                flex-shrink: 0;margin-bottom:3.8889vw;padding:0 1.1111vw;
                a{
                    display: block;padding: 2.22225vw 6.9444vw;border-radius:5.1389vw;background:$bg;
                    @include normalTxt; font-weight: 600;
                    &.on{
                        background:$purple;color:$white;
                    }
                }
            }
        }
    }
    .page-tit {
        &.tit-bang {margin-bottom: 5.5556vw;}
        &+.bang-notice {
            margin-bottom: 11.1111vw;
        }
    }
    .txt {display:block;/*@include normalTxt;*/}
    .cardList-wrap{padding: 0;}

    //고객상담 리스트
    .counseling-list{
        .chat_bot{display: none;}
        .counseling-list-icoWrap{
            display: flex; flex-wrap: wrap; width:100%; justify-content: space-between; gap:4.1667vw;
            li{
                width:calc(50% - 2.0833vw);  text-align: center;
                a{
                    display: block; padding:5vw; border:0.2778vw solid $gray02; border-radius:2.7778vw;
                    .ico-img{
                        width:16.6667vw; height:16.6667vw; margin:0 auto;
                    }
                    .ico-tit{
                        margin-top:2.7778vw; @include defaultTxt;
                    }
                    &:active{
                        border:0.2778vw solid $purple;
                    }
                }
            }
        }
        .banner-img{
            margin-top:8.3333vw;
            a{
                display: block; width:100%;
                img{
                    width:100%; display: block;
                }
            }
            & + .banner-img{
                margin-top: 30px;
            }
        }
        .btn-list{
            margin-top: 8.3333vw;
            li{
                & + li{
                    margin-top: 4.1667vw;
                }
            }
        }
    }
    //ARS 안내
    .counseling-ARS{
        section{
            & + section{
                margin-top:8.3333vw;
            }
        }
        .page-tit{
            margin-bottom: 2.7778vw;
            h2{@include mainTxt;}
        }
        .main-txt{
            margin-bottom: 2.7778vw;
        }
        .notification{
            margin-top:4.1667vw;
        }
        .icon-left-card{
            dd{
                .box-btn{
                    & + .box-btn{
                        margin-top: 2.7778vw;
                    }
                }
            }
        }
    }
    //신청서류 양식 받기
    .counseling-document{
        .page-tit{
            @include hidden;
        }
        .info-txt{
            @include smallTxt; margin-top:5.5556vw;
        }
        .box-btn{
            margin-top: 11.1111vw;
        }
    }
    //대리점 개설 상담 신청
    .counseling-agency-open{
        .inner{
           min-height: auto;
        }
        .page-tit{
            @include hidden;
        }
        .appli-ct{
            margin-bottom: 4.1667vw;
        }
        .box-textarea{
            height:69.4444vw;
            textarea{
                width:100%;
            }
        }
    }
    //매장 찾기
    .counseling-find-shop{
        padding:11.1111vw 5.5556vw 0 5.5556vw;
        .search-opt{
            margin-top: 4.1667vw;
            .opt-subway{
                display: flex; flex-wrap: wrap; gap: 2.7778vw;
                .box-select{
                    flex: 1 1 40%;
                }
            }
        }
        .box-flex{
            padding-top:0; margin-top:4.1667vw;
        }
        .place-search-result{
            margin-top: 8.3333vw;
            .result-list{
                dt{
                    a{
                        @include defaultTxt;
                    }
                }
            }
        }
        .shop-num{
            display: block; margin-top: 1.3889vw; padding-left: 4.8611vw;
            @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,4.1667vw 4.1667vw);
        }
    }
    //위치 정보 활용 동의
    .counseling-loaction-info-agree{

        .content{
            width:100%; margin-bottom:8.3333vw;
        }
    }
    //지점보기
    .counseling-store-map{
       .place-address{
            dl{
                padding-right:0;
            }
       }
       .location-map{
        width:100%; margin:5.5556vw 0; background: $white; min-height:96.5278vw; border-radius:2.7778vw;
       }
       .shop-num{
        display: block; margin-top: 1.3889vw; padding-left: 4.8611vw;
        @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,4.1667vw 4.1667vw);
        }
    }
    //제휴 문의
    .counseling-store-inquiry{
        .cardList{
            li{
                & + li{margin-top: 2.7778vw;}
                strong{
                    @include mainTxt;
                }
            }
        }
        .info-txt{
            @include smallTxt; margin-top: 4.1667vw;
        }
        .appli-ct{
            margin-top:5.5556vw;
        }
        .textarea-wrap{
            margin-bottom: 0; margin-top: 11.1111vw;
            .box-textarea{height: 62.5vw;}
        }
    }
    //분실신고
    .reward-loss-report{
        .cardList-wrap{
            & + .cardList-wrap{
                 margin-top:5.5556vw;
            }
            .txt {
                 margin-bottom:2.7778vw;line-height: 1.2;
                .btn-text-arrow {margin-top:1.3889vw;}
            }
        }
        .report-state{
            margin-top: 11.1111vw;
            .report-tit{
                .title{@include subContsTitle; margin-bottom: 0;}
                .desc{margin-top: 1.3889vw;}
                .bang-notice{ margin-top: 1.3889vw}
                &.page-tit{margin-bottom: 5.5556vw;}
            }
            .btn-list{
                li{
                    & + li{
                        margin-top: 4.1667vw;
                    }
                }
                &.mb40{
                    margin-bottom: 5.5556vw;
                }
            }
            .notification{
                margin:5.5556vw 0;
            }
        }

    }
    //휴대폰 분실 신고 -step
    .reward-loss-report-step{
        .input-wrap{
            & + .input-wrap{
                margin-top:5.5556vw;
            }
        }
        &.step02{
            .btn-time-group{
                display: flex; justify-content: space-between; align-items: center;
                .box-btn{
                    width:40.5556vw
                }
            }
            .btn-text-arrow{
                margin-top: 8.3333vw;
            }
        }
        &.step03{
            .accordian{
                margin-top: 11.1111vw;
            }
        }
    }

    //휴대폰 분실 신고 완료
    .reward-loss-report-finish{
        .info-txt{
            @include mainTxt; margin-top: 4.1667vw;
        }
        .bang-notice{
            margin-top: 1.3889vw;
        }
        .btn-list{
            margin-top: 5.5556vw;
            li{
                & + li{
                    margin-top: 4.1667vw;
                }
            }
        }
        .notification{
            margin-top: 5.5556vw;
        }
    }

    // 분실폰 위치 찾기
    .reward-phone-find {
        .default-txt{
            @include mainTxt;
        }
        .box-loading{margin-top: 8.3333vw;}
        .phone-num{
            border: 0.2778vw solid $gray02; padding: 4.4444vw 4.1667vw; border-radius: 1.9444vw; @include defaultTxt; font-weight: 400;
        }
        .notification{margin-top: 2.7778vw;}
        .map-wrap{
            margin-top: 2.7778vw; height: 52.7778vw; border-radius: 2.7778vw; overflow: hidden; background-color: $gray03;
            // 지도 핀 가운데 위치
            & > div:first-of-type{left: 50%;}
        }
        .contents-wrap{
            margin-top: 8.3333vw;
            .default-txt{display: block; margin-bottom: 1.3889vw;}

            &.table-area {
                .table-list-wrap{
                    margin-top: 2.7778vw;
                    .table-list {
                        th{
                            padding: 5.5556vw 4.1667vw; border-bottom: none;
                            &:last-of-type{padding-left: 0;}
                        }
                        td{
                            padding: 5.5556vw 4.1667vw; color: $black;
                            &:last-of-type{padding-left: 0;}
                        }
                    }
                    .box-btn {
                        margin-top: 2.7778vw; display: flex; justify-content: center;
                    }
                }
            }
            p{
                @include smallTxt;
                span{@include fontInherit;}
            }
        }
        .page-tit {
            & + .contents-wrap{margin-top: 0;}
        }
        .no-result{
            margin-top: 8.3333vw;
            background-image: url('/assets/images/icon/ico-try-again.svg');background-position: top;
        }
        .txt {
           line-height:1.2;
            .btn-text-arrow {margin-top:4.1667vw;}
        }
    }
    //분실 신고 해제
    .reward-cancel-regi{
        .cardList-wrap{
            &.mt60{
                margin-top: 8.3333vw;
                .notification{
                    margin-top: 4.1667vw;
                }
            }

        }
        .unregi{
            margin-top:8.3333vw;
            h2{
                @include mainTxt;
            }
            .bang-notice{
                margin-top: 1.3889vw;
            }
        }
    }
    .reward-cancel-agree{
        .page-tit{
            h2{
               @include mainTxt;
            }
        }
        .agree-area{
            margin-top: -2.7778vw;
        }
        .third-party-agree {
            margin-bottom:5.5556vw;padding:4.5556vw;border-radius:2.7778vw;border:0.1389vw solid $gray02;
            .tit {@include defaultTxt;}
            p {@include defaultTxt;}
            .notification {margin:2.7778vw 0;}
            .info-txt{
                @include smallTxt;
            }
        }
    }

    //앱소개
    .app-intro{
        .box-intro{
            & + .box-intro {
                margin-top: 12.5vw;
            }
            p{@include smallTxt; margin-top: 1.3889vw;}
            .intro-img{
                width: 100%; height: 83.3333vw; overflow: hidden;border-radius: 2.7778vw; margin-top: 2.7778vw;
                img{width: 100%; height: 100%; object-fit: contain;}
            }
        }
        .accordian{
            margin-top: 11.1111vw;
        }
    }
    //분실파손 잡수 및 보상안내 공통
    .reward{
        .icon-left-card{
            dd{
                .box-btn{
                    & + .box-btn{
                        margin-top: 2.7778vw;
                    }
                }
            }
        }
        .img-list {
            padding:0 5.5556vw;border-radius:2.7778vw;border:solid 0.2778vw $gray02;
            li {
                a{
                    display: flex; padding:5.5556vw 0; align-items: center; width:100%;
                }
                &+li {border-top:solid 0.2778vw $gray02;}
                .img {
                    flex-shrink:0; overflow:hidden;width:16.6667vw;
                    img {display: block;width: 100%;}
                    &.img-round {border:0.2778vw solid $gray02;border-radius:1.9444vw;}
                }
                .info {
                    flex-grow:1; padding-left:2.7778vw; width: calc(100% - 25vw);
                    .tit {display:block;@include defaultTxt;}
                    .txt {display:block; margin-top:0.6944vw; @include smallTxt;}
                }
                .ico-arrow-right{width:5.5556vw; height: 5.5556vw; display: block;}
                &.none{
                    display: flex; padding:5.5556vw 0; align-items: center; width:100%;
                }
            }
        }
        .box {
            &-callcenter,&-insurance {
                a{ display: block; padding:5.5556vw;}
                position:relative;margin-top:2.7778vw;border-radius:2.7778vw;border:solid 0.2778vw $gray02;
                b {display:block;margin-bottom:2.7778vw;@include defaultTxt;}
                strong {display:block;@include subContsTitle;}
                .txt {margin-top:4.1667vw;}
                &::after {content:'';display:block;position:absolute;right:5.0000vw;top:50%;width:5.5556vw;height:5.5556vw;margin-top:-2.7778vw; z-index: -1;}
            }
            &-callcenter {
                &::after {@include backgroundImg('/assets/images/icon/ico-call-bk.svg', center center, 5.5556vw 5.5556vw);}
            }
            &-insurance {
                &::after {@include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right center, 5.5556vw 5.5556vw);}
            }
        }
        .c-unit{
            .unit-tit{
                margin-bottom: 2.7778vw;
                & + .bang-notice{
                    margin-top: -1.3889vw;
                }
                & +.unit-sec-tit{margin-top: 5.5556vw;}
            }
            .unit-sec-tit{
                & > h3{
                    margin-bottom: 2.7778vw;
                    & + .notification{
                        margin-top: -1.3889vw;
                    }
                    & + .bang-notice{
                        margin-top: -1.3889vw;
                    }
                }
                & + .unit-sec-tit{ margin-top: 8.3333vw;}
            }
        }
        .reward-card-wrap{
            margin-top: 2.7778vw;
            & + .reward-card-wrap{
                margin-top: 4.1667vw;
            }
        }
        .bg-gy{background: $gray01; padding:5.5556vw; border-radius: 2.7778vw;}
    }
    //휴대폰 분실
    .reward-info-loss{
        .accordian{
            & + .accordian{
                margin-top: 11.1111vw;
            }
        }
        .no-list{
            padding: 5.5556vw;
        }
        .acc01,.acc02{
            .acc-conts{display: block;}
        }
        .acc-conts{
            margin-top:2.7778vw; padding-top:2.7778vw;
            .btn-text-arrow {margin-top:1.6667vw;}
        }
    }
    //휴대폰 파손
    .reward-info-destroy{
        .one-title{
            border-radius: 2.7778vw; border:0.2778vw solid $gray02; padding:5.5556vw;
        }
        .arro-rt{
            margin-top: 2.7778vw;
        }
    }
}
//대리점 개설 상담신청 - 개인정보수집 / 이용동의 - 바텀시트
.counseling-bottomSheet{
    .popup-body{
        .notification{
            margin-top:2.7778vw;
        }
    }

}

.icon-txt-card{
    text-align: center; padding:5.5556vw 0;
    li{
        width:50%; margin:0 auto;
        a{display: block; width: 100%; height: 100%;}
        p{
            @include defaultTxt;
        }
        &:last-child{
            border-left:0.2778vw solid $gray02;
        }
    }
}

.search-ing {margin-top:4.1667vw;@include defaultTxt;}

.agree-area {
    .txt {display:block;margin-bottom:11.1111vw;@include normalTxt;}
}
.list-wrap.type-ul {
    .siblings-article {
        width:100vw;margin-bottom:5.5556vw;margin-left:-5.5556vw;border-top:0.1389vw solid $gray02;
        li {
            display:flex;flex-direction:row;padding:4.1667vw 5.5556vw;border-bottom:0.1389vw solid $gray02;
            &>b {width:16.6667vw;@include defaultTxt; font-weight: 600; text-align:left;}
            &>a {overflow:hidden;text-overflow:ellipsis;width:calc(100% - 16.6667vw);@include smallTxt; text-align:left;white-space:nowrap;}
            .no-article {width:calc(100% - 16.6667vw);@include smallTxt;text-align:left;}
        }
    }
    .no-result {
        @include normalTxt; text-align:center;
        .img {display:block;width:25.0000vw;height:25.0000vw;margin:15.2778vw auto 5.5556vw;}
        &>strong {display:block;margin-bottom:1.2500vw;@include subContsTitle;}
    }
}
.phone-reward {
    .phone-num {display:block;margin-bottom: 4.1667vw;@include mainEmphasize; font-weight: 400;}
}
.customer-protect {
    .under-link {color:#4673f1;text-decoration:underline; word-break: break-all;}
    .thumb-list {margin-bottom:11.1111vw;}
    .movie-damage-prevention {
        .thumb-list {margin-bottom:2.7778vw; }
        .txt {margin-top:5.5556vw;line-height:1.43;}
    }
    .blue-color{
        @include mainTxt;  color:#4673f1;
    }
    &.illegal {
        .visual-box {margin:2.7778vw 0;}
        .visual-msafer {margin:5.5556vw 0 11.1111vw;}
        .txt-auth {
            display:inline-block; padding:0.6944vw 0;color:$black;vertical-align:middle;
            img {display:inline-block;width:5.5556vw;height:5.5556vw;margin-right:1.3889vw;vertical-align:middle;}
        }
    }
    &.communication{
        .notification{
            margin-top:2.7778vw; 
            &.etc{
                li{font-size:4.4444vw; color:#191919;}
            }
        }
    }
    .training-list {
        border-top:1px solid #191919;
         li{
            border-bottom:1px solid #ddd;
            a {position:relative; display:block; width:100%; height:13.8889vw;line-height:13.8889vw; color:#191919; font-size:4.4444vw;}
            a::after {content:''; position: absolute; right:4.1667vw; top:50%; width:5.5556vw; height:5.5556vw; transform: translateY(-50%); background: url('/assets/images/icon/ico-arrow-right02.png') no-repeat 0 0; background-size: 5.5556vw;}
        }
    }
    .ico-red-phone{width:4.1667vw; height:4.1667vw; display:inline-block; vertical-align:middle; background: url('/assets/images/icon/ico-call-red.png') no-repeat 0 0; background-size: contain;}
    .protect-img-info{
        padding:8.3333vw 5.5556vw; border-radius:2.7778vw; background-color:#f7f8fa;
        img{width:16.6667vw; height:auto; display:block; margin:0 auto;}
        h2{display:block; margin:5.5556vw 0 1.3889vw; @include subContsTitle;}
    }
    .next-box{
        li{
            border:1px solid #ddd; border-radius: 2.7778vw; padding: 5.5556vw 0; text-align:center; font-size:4.1667vw; font-weight:bold; color:#191919; position:relative; margin-bottom:8.3333vw;
            &:after{content:''; display:block; width:5.5556vw; height:5.5556vw; position:absolute; bottom:-6.9444vw; left:50%; transform:translateX(-50%); background:url(/assets/images/icon/ico-arrow-bottom.svg) no-repeat center; background-size: 100%;}
            &:last-child{
                margin:0;
                &::after{display:none;}
            }
            
        }
    }
}
.list-num{
    li{
        @include smallTxt;
    }
}
.customer-list {
    .box-input {margin-bottom:5.5556vw;}
}
.icon-img-error{
    width:36.1111vw;height: 36.1111vw; margin:0 auto;
}
.protect-damage-webtoon{
    .thumb-list{
        li{
            a{
                display: flex; align-items: center; gap: 4.1667vw;
                img{
                    max-width: auto; width: 41.6667vw;
                }
                strong{
                    margin-top: 0;
                }
            }
        }
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
    iframe[title="메시징 창을 시작하는 버튼"]{
// @include backgroundImg('/assets/images/icon/ico-onsale-floating.svg',center center,7.7143vw 7.7143vw);
 z-index: 999999999 !important;
    background-image: url(/assets/images/icon/ico-onsale-floating.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 7.7143vw 7.7143vw !important;
    .iPDnea.iPDnea.iPDnea{
        display: none !important;
    }
}

/*** 공통 ***/
.customer {
    .box-tab {
        .tab-btn-wrap {margin-bottom: 0 !important;}
    }
    .round-tab {
        overflow:hidden;height:9.2857vw;
        .tab-btn-wrap {
            display:flex;justify-content:flex-start;flex-wrap:wrap;margin-bottom: 5.7143vw;padding:2.0000vw 2.8571vw 0;
            li{
                flex-shrink: 0;margin-bottom:2.0000vw;padding:0 0.5714vw;
                a{
                    display: block;padding: 1.1429vw 3.5714vw;border-radius:2.6429vw;background:$bg;
                   font-weight: 600;
                    &.on{
                        background:$purple;color:$white;
                    }
                }
            }
        }
    }
    .page-tit {
        &.tit-bang {margin-bottom: 2.8571vw;}
        &+.bang-notice {
            margin-bottom: 5.7143vw;
        }
    }
    .txt {display:block;/*@include normalTxt;*/}
    .cardList-wrap{padding: 0;}

    //고객상담 리스트
    .counseling-list{
        .chat_bot{display: none;}
        .counseling-list-icoWrap{
            display: flex; flex-wrap: wrap; width:100%; justify-content: space-between; gap:2.1429vw;
            li{
                width:calc(50% - 1.0714vw);  text-align: center;
                a{
                    display: block; padding:2.5714vw; border:0.1429vw solid $gray02; border-radius:1.4286vw;
                    .ico-img{
                        width:8.5714vw; height:8.5714vw; margin:0 auto;
                    }
                    .ico-tit{
                        margin-top:1.4286vw;
                    }
                    &:active{
                        border:0.1429vw solid $purple;
                    }
                }
            }
        }
        .banner-img{
            margin-top:4.2857vw;
            a{
                display: block; width:100%;
                img{
                    width:100%; display: block;
                }
            }
            & + .banner-img{
                margin-top: 2.1429vw;
            }
        }
        .btn-list{
            margin-top: 4.2857vw;
            li{
                & + li{
                    margin-top: 2.1429vw;
                }
            }
        }
    }
    //ARS 안내
    .counseling-ARS{
        section{
            & + section{
                margin-top:4.2857vw;
            }
        }
        .page-tit{
            margin-bottom: 1.4286vw;
        }
        .main-txt{
            margin-bottom: 1.4286vw;
        }
        .notification{
            margin-top:2.1429vw;
        }
        .icon-left-card{
            dd{
                .box-btn{
                    & + .box-btn{
                        margin-top: 1.4286vw;
                    }
                }
            }
        }
    }
    //신청서류 양식 받기
    .counseling-document{
        .page-tit{
            @include hidden;
        }
        .info-txt{
            margin-top:2.8571vw;
        }
        .box-btn{
            margin-top: 5.7143vw;
        }
    }
    //대리점 개설 상담 신청
    .counseling-agency-open{
        .inner{
           min-height: auto;
        }
        .page-tit{
            @include hidden;
        }
        .appli-ct{
            margin-bottom: 2.1429vw;
        }
        .box-textarea{
            height:35.7143vw;
            textarea{
                width:100%;
            }
        }
    }
    //매장 찾기
    .counseling-find-shop{
        padding:5.7143vw 2.8571vw 0 2.8571vw;
        .search-opt{
            margin-top: 2.1429vw;
            .opt-subway{
                display: flex; flex-wrap: wrap; gap: 1.4286vw;
                .box-select{
                    flex: 1 1 40%;
                }
            }
        }
        .box-flex{
            padding-top:0; margin-top:2.1429vw;
        }
        .place-search-result{
            margin-top: 4.2857vw;

        }
        .shop-num{
            display: block; margin-top: 0.7143vw; padding-left: 2.5000vw;
            @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,2.1429vw 2.1429vw);
        }
    }
    //위치 정보 활용 동의
    .counseling-loaction-info-agree{

        .content{
            width:100%; margin-bottom:4.2857vw;
        }
    }
    //지점보기
    .counseling-store-map{
       .place-address{
            dl{
                padding-right:0;
            }
       }
       .location-map{
        width:100%; margin:2.8571vw 0; background: $white; min-height:49.6429vw; border-radius:1.4286vw;
       }
       .shop-num{
        display: block; margin-top: 0.7143vw; padding-left: 2.5000vw;
        @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,2.1429vw 2.1429vw);
        }
    }
    //제휴 문의
    .counseling-store-inquiry{
        .cardList{
            li{
                & + li{margin-top: 1.4286vw;}
            }
        }
        .info-txt{
            margin-top: 2.1429vw;
        }
        .appli-ct{
            margin-top:2.8571vw;
        }
        .textarea-wrap{
            margin-bottom: 0; margin-top: 5.7143vw;
            .box-textarea{height: 32.1429vw;}
        }
    }
    //분실신고
    .reward-loss-report{
        .cardList-wrap{
            & + .cardList-wrap{
                 margin-top:2.8571vw;
            }

        }
        .report-state{
            margin-top: 5.7143vw;
            .report-tit{
                .title{margin-bottom: 0;}
                .desc{margin-top: 0.7143vw;}
                .bang-notice{ margin-top: 0.7143vw}
                &.page-tit{margin-bottom: 2.8571vw;}
            }
            .btn-list{
                li{
                    & + li{
                        margin-top: 2.1429vw;
                    }
                }
                &.mb40{
                    margin-bottom: 2.8571vw;
                }
            }
            .notification{
                margin:2.8571vw 0;
            }
        }

    }
    //휴대폰 분실 신고 -step
    .reward-loss-report-step{
        .input-wrap{
            & + .input-wrap{
                margin-top:2.8571vw;
            }
        }
        &.step02{
            .btn-time-group{
                display: flex; justify-content: space-between; align-items: center;
                .box-btn{
                    width:20.8571vw
                }
            }
            .btn-text-arrow{
                margin-top: 4.2857vw;
            }
        }
        &.step03{
            .accordian{
                margin-top: 5.7143vw;
            }
        }
    }

    //휴대폰 분실 신고 완료
    .reward-loss-report-finish{
        .info-txt{
            margin-top: 2.1429vw;
        }
        .bang-notice{
            margin-top: 0.7143vw;
        }
        .btn-list{
            margin-top: 2.8571vw;
            li{
                & + li{
                    margin-top: 2.1429vw;
                }
            }
        }
        .notification{
            margin-top: 2.8571vw;
        }
    }

    // 분실폰 위치 찾기
    .reward-phone-find {
        .box-loading{margin-top: 4.2857vw;}
        .phone-num{
            border: 0.1429vw solid $gray02; padding: 2.2857vw 2.1429vw; border-radius: 1.0000vw; font-weight: 400;
        }
        .notification{margin-top: 1.4286vw;}
        .map-wrap{
            margin-top: 1.4286vw; height: 27.1429vw; border-radius: 1.4286vw; overflow: hidden; background-color: $gray03;
            // 지도 핀 가운데 위치
            & > div:first-of-type{left: 50%;}
        }
        .contents-wrap{
            margin-top: 4.2857vw;
            .default-txt{display: block; margin-bottom: 0.7143vw;}

            &.table-area {
                .table-list-wrap{
                    margin-top: 1.4286vw;
                    .table-list {
                        th{
                            padding: 2.8571vw 2.1429vw; border-bottom: none;
                            &:last-of-type{padding-left: 0;}
                        }
                        td{
                            padding: 2.8571vw 2.1429vw; color: $black;
                            &:last-of-type{padding-left: 0;}
                        }
                    }
                    .box-btn {
                        margin-top: 1.4286vw; display: flex; justify-content: center;
                    }
                }
            }
        }
        .page-tit {
            & + .contents-wrap{margin-top: 0;}
        }
        .no-result{
            margin-top: 4.2857vw;
            background-image: url('/assets/images/icon/ico-try-again.svg');background-position: top;
        }
    }
    //분실 신고 해제
    .reward-cancel-regi{
        .cardList-wrap{
            &.mt60{
                margin-top: 4.2857vw;
                .notification{
                    margin-top: 2.1429vw;
                }
            }

        }
        .unregi{
            margin-top:4.2857vw;
            .bang-notice{
                margin-top: 0.7143vw;
            }
        }
    }
    .reward-cancel-agree{
        .agree-area{
            margin-top: -1.4286vw;
        }
        .third-party-agree {
            margin-bottom:2.8571vw;padding:2.3571vw;border-radius:1.4286vw;border:0.0714vw solid $gray02;
            .notification {margin:1.4286vw 0;}

        }
    }

    //앱소개
    .app-intro{
        .box-intro{
            & + .box-intro {
                margin-top: 6.4286vw;
            }
            p{ margin-top: 0.7143vw;}
            .intro-img{
                width: 100%; height: 42.8571vw; overflow: hidden;border-radius: 1.4286vw; margin-top: 1.4286vw;
                img{width: 100%; height: 100%; object-fit: contain;}
            }
        }
        .accordian{
            margin-top: 5.7143vw;
        }
    }
    //분실파손 잡수 및 보상안내 공통
    .reward{
        .icon-left-card{
            dd{
                .box-btn{
                    & + .box-btn{
                        margin-top: 1.4286vw;
                    }
                }
            }
        }
        .img-list {
            padding:0 2.8571vw;border-radius:1.4286vw;border:solid 0.1429vw $gray02;
            li {
                a{
                    display: flex; padding:2.8571vw 0; align-items: center; width:100%;
                }
                &+li {border-top:solid 0.1429vw $gray02;}
                .img {
                    flex-shrink:0; overflow:hidden;width:8.5714vw;
                    img {display: block;width: 100%;}
                    &.img-round {border:0.1429vw solid $gray02;border-radius:1.0000vw;}
                }
                .info {
                    flex-grow:1; padding-left:1.4286vw; width: calc(100% - 12.8571vw);
                    .tit {display:block;}
                    .txt {display:block; margin-top:0.3571vw; }
                }
                .ico-arrow-right{width:2.8571vw; height: 2.8571vw; display: block;}
                &.none{
                    display: flex; padding:2.8571vw 0; align-items: center; width:100%;
                }
            }
        }
        .box {
            &-callcenter,&-insurance {
                a{ display: block; padding:2.8571vw;}
                position:relative;margin-top:1.4286vw;border-radius:1.4286vw;border:solid 0.1429vw $gray02;
                b {display:block;margin-bottom:1.4286vw;}
                strong {display:block;}
                .txt {margin-top:2.1429vw;}
                &::after {content:'';display:block;position:absolute;right:2.5714vw;top:50%;width:2.8571vw;height:2.8571vw;margin-top:-1.4286vw; z-index: -1;}
            }
            &-callcenter {
                &::after {@include backgroundImg('/assets/images/icon/ico-call-bk.svg', center center, 2.8571vw 2.8571vw);}
            }
            &-insurance {
                &::after {@include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right center, 2.8571vw 2.8571vw);}
            }
        }
        .c-unit{
            .unit-tit{
                margin-bottom: 1.4286vw;
                & + .bang-notice{
                    margin-top: -0.7143vw;
                }
                & +.unit-sec-tit{margin-top: 2.8571vw;}
            }
            .unit-sec-tit{
                & > h3{
                    margin-bottom: 1.4286vw;
                    & + .notification{
                        margin-top: -0.7143vw;
                    }
                    & + .bang-notice{
                        margin-top: -0.7143vw;
                    }
                }
                & + .unit-sec-tit{ margin-top: 4.2857vw;}
            }
        }
        .reward-card-wrap{
            margin-top: 1.4286vw;
            & + .reward-card-wrap{
                margin-top: 2.1429vw;
            }
        }
        .bg-gy{background: $gray01; padding:2.8571vw; border-radius: 1.4286vw;}
    }
    //휴대폰 분실
    .reward-info-loss{
        .accordian{
            & + .accordian{
                margin-top: 5.7143vw;
            }
        }
        .no-list{
            padding: 2.8571vw;
        }
        .acc01,.acc02{
            .acc-conts{display: block;}
        }
        .acc-conts{
            margin-top:1.4286vw; padding-top:1.4286vw;
        }
    }
    //휴대폰 파손
    .reward-info-destroy{
        .one-title{
            border-radius: 1.4286vw; border:0.1429vw solid $gray02; padding:2.8571vw;
        }
        .arro-rt{
            margin-top: 1.4286vw;
        }
    }
}
//대리점 개설 상담신청 - 개인정보수집 / 이용동의 - 바텀시트
.counseling-bottomSheet{
    .popup-body{
        .notification{
            margin-top:1.4286vw;
        }
    }

}

.icon-txt-card{
    text-align: center; padding:2.8571vw 0;
    li{
        width:50%; margin:0 auto;
        a{display: block; width: 100%; height: 100%;}

        &:last-child{
            border-left:0.1429vw solid $gray02;
        }
    }
}

.search-ing {margin-top:2.1429vw;}

.agree-area {
    .txt {display:block;margin-bottom:5.7143vw;}
}
.list-wrap.type-ul {
    .siblings-article {
        width:51.4286vw;margin-bottom:2.8571vw;margin-left:-2.8571vw;border-top:0.0714vw solid $gray02;
        li {
            display:flex;flex-direction:row;padding:2.1429vw 2.8571vw;border-bottom:0.0714vw solid $gray02;
            &>b {width:8.5714vw;font-weight: 600; text-align:left;}
            &>a {overflow:hidden;text-overflow:ellipsis;width:calc(100% - 8.5714vw); text-align:left;white-space:nowrap;}
            .no-article {width:calc(100% - 8.5714vw);text-align:left;}
        }
    }
    .no-result {
        @include normalTxt; text-align:center;
        .img {display:block;width:12.8571vw;height:12.8571vw;margin:7.8571vw auto 2.8571vw;}
        &>strong {display:block;margin-bottom:0.6429vw;}
    }
}
.phone-reward {
    .phone-num {display:block;margin-bottom: 2.1429vw;font-weight: 400;}
}
.customer-protect {
    .under-link {color:#4673f1;text-decoration:underline;}
    .thumb-list {margin-bottom:5.7143vw;}
    .movie-damage-prevention {
        .thumb-list {margin-bottom:1.4286vw; }
        .txt {margin-top:2.8571vw;line-height:1.43;}
    }
    .blue-color{
         color:#4673f1;
    }
    &.illegal {
        .visual-box {margin:1.4286vw 0;}
        .visual-msafer {margin:2.8571vw 0 5.7143vw;}
        .txt-auth {
            display:inline-block; padding:0.3571vw 0;color:$black;vertical-align:middle;
            img {display:inline-block;width:2.8571vw;height:2.8571vw;margin-right:0.7143vw;vertical-align:middle;}
        }
    }
}

.customer-list {
    .box-input {margin-bottom:2.8571vw;}
}
.icon-img-error{
    width:18.5714vw;height: 18.5714vw; margin:0 auto;
}
.protect-damage-webtoon{
    .thumb-list{
        li{
            a{
                display: flex; align-items: center; gap: 2.1429vw;
                img{
                    max-width: auto; width: 21.4286vw;
                }
                strong{
                    margin-top: 0;
                }
            }
        }
    }
}

}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .customer{
        .counseling-list{
            .counseling-list-icoWrap{
                gap: 15px;
                li{
                    width: calc(50% - 7.5px);
                    a{
                        border-width: 1px; border-radius: 8px; padding: 20px;
                        .ico-img{width: 60px; height: 60px;}
                        .ico-tit{margin-top: 10px;}
                    }
                }
            }
            .banner-img{
                margin-top: 30px;
                & + .banner-img{margin-top: 15px;}
            }
            .btn-list{
                margin-top: 30px;
                li{
                    & + li{margin-top: 15px;}
                }
            }
        }
        .counseling-ARS{
            .main-txt{margin-bottom: 10px;}
        }
        .counseling-agency-open{
            .appli-ct{margin-bottom: 15px;}
            .box-textarea{height: 300px;}
        }
        .counseling-store-inquiry{
            .cardList{
                li{
                    & + li{margin-top: 10px;}
                }
            }
            .info-txt{margin-top: 15px;}
            .textarea-wrap{margin-top: 35px;}
        }
        .counseling-store-inquiry{
            .textarea-wrap{
                .box-textarea{height: 230px;}
            }
            .appli-ct{margin-top: 20px;}
        }
        .counseling-find-shop{
            padding: 40px 20px 0 20px;
            .search-opt{
                margin-top: 15px;
                .opt-subway{gap: 10px;}
            }
            .box-flex{margin-top: 15px;}
            .place-search-result{margin-top: 30px;}
        }
        .app-intro{
            .box-intro{
                p{margin-top: 5px;}
                .intro-img{margin-top: 10px; height: 600px; border-radius: 8px;}
                & + .box-intro{margin-top: 45px;}
            }
            .accordian{margin-top: 40px;}
        }
        .reward-info-loss{
            .acc-conts{margin-top: 10px; padding-top: 10px;}
            .no-list{padding: 20px;}
            .accordian{
                & + .accordian{margin-top: 40px;}
            }
        }
        .reward{
            .c-unit{
                .unit-tit{margin-bottom: 10px;}
                & + .unit-sec-tit{margin-top: 20px;}
                .unit-sec-tit{
                    margin-bottom: 10px;
                    & > h3{
                        margin-bottom: 10px;
                        & + .notification{margin-top: -5px;}
                    }
                    & + .unit-sec-tit{margin-top: 30px;}
                }
                .bg-gy{padding: 20px; border-radius: 8px;}
            }
            .img-list{
                padding: 20px; border-width: 1px; border-radius: 8px;
                li{
                    a{padding: 20px 0;}
                    .img{
                        width: 60px;
                        &.img-round{border-radius: 8px; border-width: 1px;}
                    }
                    .info{padding-left: 10px; width: calc(100% - 60px);}
                    .ico-arrow-right{width: 20px; height: 20px;}
                    & + li{border-width: 1px;}
                    &.none{padding: 20px 0;}
                }
            }
            .box-callcenter, .box-insurance{
                margin-top: 10px; border-radius: 8px; border-width: 1px;
                a{padding: 20px;}
                b{margin-bottom: 10px;}
                &::after{background-size: 20px 20px;}
            }
            .reward-card-wrap{
                margin-top: 10px;
                & + .reward-card-wrap{margin-top: 15px;}
            }
            .reward-info-destroy{
                .one-title{border-radius: 8px; border-width: 1px; padding: 20px;}
            }
        }
        .reward-loss-report{
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 20px;}
            }
            .report-state{
                margin-top: 40px;
                .report-tit{
                    &.page-tit{margin-bottom: 20px;}
                    .desc{margin-top: 5px;}
                    .bang-notice{margin-top: 5px;}
                }
                .btn-list{
                    li{
                        & + li{margin-top: 20px;}
                    }
                    &.mb40{margin-bottom: 20px;}
                }
                .notification{margin: 20px 0;}
            }
        }
        .reward-loss-report-step{
            .input-wrap{
                & + .input-wrap{margin-top: 20px;}
            }
            &.step02{
                .btn-text-arrow{margin-top: 30px;}
            }
            &.step03{
                .accordian{margin-top: 40px;}
            }
        }
        .reward-loss-report-finish{
            .info-txt{margin-top: 20px;}
            .btn-list{
                margin-top: 20px;
                li{
                    & + li{margin-top: 20px;}
                }
            }
            .notification{margin-top: 20px;}
        }
        .reward-cancel-regi{
            .unregi{margin-top: 25px;}
            .cardList-wrap{
                &.mt60{
                    margin-top: 30px;
                    .notification{margin-top: 20px;}
                }
            }
        }
        .reward-phone-find{
            .phone-num{border-width: 1px; border-radius: 8px; padding: 15px 20px;}
            .contents-wrap{
                margin-top: 30px;
                .default-txt{margin-bottom: 5px;}
                &.table-area{
                    .table-list-wrap{
                        margin-top: 10px;
                        .table-list{
                            th, td{padding: 20px 15px;}
                        }
                        .btn-btn{margin-top: 10px;}
                    }
                }
            }
            .map-wrap{margin-top: 10px; height: 185px; border-radius: 8px;}
            .notification{margin-top: 10px;}
            .no-result{margin-top: 30px;}
            .box-loading{margin-top: 30px;}
            .box-select{margin-top: 30px;}
        }
        .reward-cancel-agree{
            .agree-area{margin-top: -10px;}
            .third-party-agree{margin-bottom: 20px; border-width: 1px; border-radius: 8px; padding: 15px;}
        }
    }
    .counseling-bottomSheet{
        .popup-body{
            .notification{
                margin-top: 10px;
                & + .reward-card-wrap{margin-top: 15px;}
            }
        }
    }
    .icon-img-error{width: 135px; height: 135px;}
    .customer-protect{
        &.illegal{
            .visual-msafer{margin: 20px 0 40px;}
            .txt-auth{
                img{width: 20px; height: 20px; margin-right: 5px;}
            }
        }
        &.communication{
            .notification{
                margin-top: 10px;
                &.etc{
                    li{
                        font-size: 18px;
                        &::before{top: 10px;}
                    }
                }
            }
        }
        .thumb-list{
            margin-bottom: 40px;
            li{
                a{
                    gap: 15px;
                    img{width: 155px; border-radius: 8px;}
                }
            }
            & > li{
                & + li{margin-top: 40px;}
            }
        }
        .movie-damage-prevention{
            .thumb-list{margin-bottom: 10px;}
        }
        .protect-img-info{
            img{width: 90px;}
            padding: 30px;
        }
        .next-box{
            li{
                font-size:16px; padding: 30px 0; border-radius: 8px; margin-bottom: 40px;
                &::after{width: 25px; height: 25px; bottom: -35px;}
            }
        }
        .ico-red-phone{width: 25px; height: 25px;}
    }
}

.customer{
    .self-serive {
        padding: 11.1111vw 0 0 0;
        .box-tab {padding-left:5.5556vw;}
        .page-tit  {margin-bottom:8.3333vw; padding:0 5.5556vw}
        .selfserive-view {
            padding:0 5.5556vw;
            .service-box {
                padding:8.3333vw 0; border-radius: 2.7778vw;background-color: #f6f6f6;text-align:cneter; 
                .tit {margin-bottom:1.3889vw; font-size: 5vw;font-weight: bold;line-height: 1.19;letter-spacing: -0.1vw;text-align: center;color: #191919;}
                .txt { font-size: 4.1667vw;line-height: 1.1;letter-spacing: -0.125vw;text-align: center;color: #666;}
                .img-bx {
                    margin-top:2.7778vw;text-align:center;
                    img {width:100%;}
                }
                .notification-wrp {
                    margin-top:8.3333vw;padding:0 5.5556vw;
                    .noti-tit {display:block;margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                }
                & + .service-box {margin-top:4.1667vw;}
                .box-btn {
                    padding: 0 5.5556vw;
                    .btns + .btns {margin-top:2.7778vw;}
                    .btns.white { border: solid 1px #ddd;background:#fff;color:#666}
                    .btn-text-arrow + .btn-text-arrow {margin-left:3.5556vw;}
                    & +  .box-btn {margin-top:4.1667vw;}
                }
            } 
        }
        
    }
}