@charset "UTF-8";


// alert popup
.alert-popup{
    display: flex;align-items: center;justify-content: center;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: rgba(25, 25, 25, 0.85);z-index: 200;
    .layer-popup-item {
        display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;max-width: 88.8889vw;border-radius:2.7778vw;overflow: hidden;opacity: 1;transform: translateY(-6.9444vw);transition: all 0.3s ease-out; -webkit-mask-image: -webkit-radial-gradient(white, black);
        .txt {
            &-main{
                display: block; margin-bottom: 1.3889vw; @include subContsTitle;
                & > span{font-size: inherit; font-weight: inherit; color: inherit;}
                & > em{font-style: normal; font-size: inherit; font-weight: inherit; color: #f60019;}
            }
            &-sub{display: block; @include smallTxt;}
        }

        .popup {
            &-header {
                width: 100%;
                padding: 0 5.5556vw;
                & + .popup-body {
                    padding-top: 5.5556vw;
                }
                .box-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16.6667vw; height: 16.6667vw;
                }
            }
            &-body {
                width: 100%;  padding: 8.3333vw 5.5556vw; background: #fff; text-align: center;
            }
            &-footer {
                width: 100%; background: rgba(25, 25, 25, 0.85);
            }
        }

    }
}

// toast popup
.pop-toast{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    opacity: 0;
    z-index: 999;
    visibility: hidden;
    transition: all 0.3s;
    left: 50%;
    bottom: 1.9444vw;
    transform: translateX(-50%);
    padding: 2.3611vw 0 ;
    border-radius: 2.7778vw;
    width:100%;
    max-width: 88.8889vw;
    @include smallTxt;
    color: #fff;
    background-color: rgba(25, 25, 25,0.67);
    box-shadow: 0 0.4167vw 4.1667vw 0 rgba(0, 0, 0, 0.16);
    &.on{
        opacity: 1;
        visibility: visible;
        bottom:8.3333vw;
    }
}

// Layer popup
.layer-popup {
    display: none;align-items: center;justify-content: center;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: rgba(25, 25, 25, 0.7);z-index: 200;

    &.show {
        .layer-popup-item {
            opacity: 1;transform: translateY(0);transition-delay: 0.2s;
        }
    }


    .layer-popup-item {
        display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;max-width: 88.8889vw;border-radius: 2.7778vw;overflow: hidden;opacity: 0;transform: translateY(-6.9444vw);transition: all 0.3s ease-out;
        .txt {
            &-main{
                display: block; margin-bottom: 1.3889vw; @include subContsTitle; font-weight: 700;text-align: center;
                & > span{font-size: inherit; font-weight: inherit; color: inherit;}
                & > em{font-style: normal; font-size: inherit; font-weight: inherit; color: #f60019;}
                &.left{text-align: left;}
            }
            &-sub{
                display: block; @include smallTxt; word-break: keep-all; letter-spacing: -0.2083vw;
                & + .default-txt{display: block; margin-top: 2.7778vw;}
                &+.txt-sub{display: block; margin-top: 2.7778vw;}
            }
        }

        .popup {
            &-header {
                background: #fff;
                width: 100%;
                padding: 8.3333vw 5.5556vw 0 5.5556vw;
                & + .popup-body {padding-top: 5.5556vw;}
                .box-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:16.6667vw; height: 16.6667vw; margin:0 auto;
                }
                .btn-close{
                    width: 5vw; height: 5vw; background: transparent url(/assets/images/common/header-btn-close.png) center center no-repeat; background-size: 100%; font-size: 0; position: absolute; right: 5.5556vw;
                }
            }
            &-body {
                width: 100%; padding: 8.3333vw 5.5556vw; background: #fff; text-align: center; margin-top: -1px;
                .notification {
                    li{display: inline-block;}
                }
                .round-box{
                    text-align: left; border:0.2778vw solid $gray02; padding:5.5556vw; margin-top: 5.5556vw; border-radius:2.7778vw;
                    p{
                        @include defaultTxt;
                        & + .notification{
                            margin-top: 2.7778vw;
                        }
                    }
                    .notification{
                        word-break: break-all;
                        a{color: #0054ff;}
                    }
                }
                .txt-sub{
                    &.data-notice{
                        li{
                            margin-bottom: 2.7778vw;
                            &:last-child{margin-bottom: 0;}
                        }
                    }
                }

            }
            &-footer {
                width: 100%; background: rgba(25, 25, 25, 0.85);
            }
        }

        .box-btn{font-size: 0;}

        // 정보 존재하지 않습니다 팝업
        &.type-info{
            .popup{
                &-header{
                    .txt{
                        &-main{margin-bottom: 1.3889vw; text-align: center;}
                        &-sub{
                            text-align: center;
                            & > a{color:#0054ff;}
                        }
                    }
                }
                &-body {
                    text-align: left; padding: 5.5556vw 5.5556vw 8.3333vw 5.5556vw;
                    .txt-sub{
                        .icon-title{
                            display: block; position: relative; padding-left: 2.2222vw;
                            @include defaultTxt;
                            &::before {
                                content: ''; display: inline-block; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 0.8333vw; height: 0.8333vw; border-radius: 50%; background-color: $black;
                            }
                            & ~ .icon-title{margin-top: 2.7778vw;}
                        }
                        .desc{
                            display: block;margin-top: 1.3889vw;padding-left: 2.2222vw;
                            @include smallTxt;
                            &.call-center{
                                & > a{color:#0054ff;}
                            }
                        }

                    }
                }
            }
        }
    }

    // bottom sheet
    &.bottom-sheet-wrap {
        align-items: flex-end;
        &.show {
            .layer-popup-item {
                transform: translateY(0);
            }
        }
        .layer-popup-item {
            width: 100%;
            max-width: 100%;
            max-height: 80vh;
            border-radius: 2.7778vw 2.7778vw 0 0;
            transform: translateY(100%);
            .popup {
                &-header {
                    display: flex;justify-content: space-between;align-items: center; gap : 4.1667vw;padding-bottom: 4.1667vw;
                    .txt-main{
                        margin-bottom: 0;
                        @include subContsTitle; text-align: left;
                    }
                    .popup-close{
                        flex-shrink: 0;
                        width: 4.4444vw;
                        height: 4.4444vw;
                        @include backgroundImg('/assets/images/icon/ico-close.svg', center, contain);
                    }
                }
                &-body {
                    // max-height: 60vh;
                    padding-bottom: 8.3333vw; text-align: left;
                    @include defaultTxt; font-weight: 600;

                    &.type-01{
                        height: 56vh;
                    }
                    .is-scroll{height: 100%; overflow-y: scroll; overflow-x: hidden;}
                }
                &-footer {
                    padding-top: 2.7778vw; background: #fff;
                }
            }
        }
    }

}

// full popup
//06.29 : head fixed 수정
.full-popup{
    left: 0; top: 0; z-index: 102; width: 100vw; min-height: 100vh; background: #fff;
    &.on{
        display: block !important; position: fixed;
        .inner{
            height: 100vh; overflow-x: hidden; overflow-y: auto;
        }
    }
    &.layer-type{
        display: none;
    }
    &.search-pop{
        position: absolute; z-index: 120 !important; height: 100%; overflow-y: scroll;
    }

    header{
        display: flex; align-items: center; position:fixed; left: 0; top: 0; z-index: 102; width:100%; height: 16.6667vw; padding-left:5.5556vw; border-bottom: 0.2778vw solid #ddd; box-sizing: border-box; background: #fff;
        h2{
            text-align: center; display: block; @include contsTitle;
        }
        button{
            display: inline-flex; width: 11.1111vw; height: 11.1111vw;  @include backgroundImg('/assets/images/common/header-btn-close.png', 50%, 4.4444vw); font-size: 0;position: absolute; top: 50%; right:  2.7778vw;  transform: translateY(-50%);
        }
        &.tit-lft {padding-left:5.5556vw; justify-content: flex-start;}
    }
    .full-popup-conts{
        .inner{
            min-height: 100%; padding: 27.7778vw 5.5556vw 27.7778vw 5.5556vw;
        }
        .inner.scroll-tpy01 {padding-bottom:46vw;}
    }
    .btns.sticky{
        position: fixed; left: 0; bottom: 0;
    }
    .btn-group.sticky{
        position: fixed; left: 0; bottom: 0;
    }
    .agree-box{
        .tit{
            font-size: 5vw; color: #191919; font-weight: 600; margin-bottom: 8.3333vw; display: block;
            b{font-size: inherit; font-weight: inherit;}
        }
    }
}

.dcment-guide{
    .dment-tab {flex-wrap: wrap;}
    .gide-bx {margin-top:2.7778vw; padding: 5.5556vw; border-radius: 2.7778vw; border: solid 1px #ddd;background-color: #fff;}
    .gide-bx h4 {margin:5.5556vw 0 2.7778vw 0; font-size: 4.4444vw; font-weight: 600;  line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
    .gide-bx h4:first-child {margin-top:0;}
    .gide-bx ul {margin-top:2.7778vw;}
    .gide-bx ul li {font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}
}

.step-process{
    position: sticky; left: 0; top: 16.6667vw; z-index: 101; width: 100vw; margin-left: -5.5556vw; background: #e6e6e6;
    ol{
        display: flex;
        li{
            flex: 1; position: relative; height:1.3889vw; font-size: 0;
            &.complete{
                background: #7c40ce;
                &:after{
                    content: ''; position: absolute; right: -0.6944vw; top: 0; width: 1.3889vw; height: 1.3889vw; border-radius: 50%; background: #7c40ce;
                }
            }
        }
    }
}
//링크 공유 하기 바텀시트
.sns-share-bottomSheet{
    .popup-body{
        .url-link-wrap{
            margin-bottom: 8.3333vw; margin-top: 5.5556vw;
        }
        .list-sns{
            display: flex; justify-content: space-between; gap:2.2222vw;
            li{
                text-align: center;
                img{
                    width:20.5556vw; height: 20.5556vw; display: block;
                }
                span{display: block; @include defaultTxt;}
            }
            &.even { justify-content:space-evenly;}
        }
    }

}
 
.open-modal-popup-wrap {
    .open-modal-popup.pop-img {
        border-radius:0;
        a {display:block;width:100%;}
         img {vertical-align: top;}
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    // alert popup
    .alert-popup{
        display: flex;align-items: center;justify-content: center;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: rgba(25, 25, 25, 0.85);z-index: 200;
        .layer-popup-item {
            display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;max-width: 45.7143vw;border-radius:1.4286vw;overflow: hidden;opacity: 1;transform: translateY(-3.5714vw);transition: all 0.3s ease-out; -webkit-mask-image: -webkit-radial-gradient(white, black);
            .txt {
                &-main{
                    display: block; margin-bottom: 0.7143vw;
                    & > span{font-size: inherit; font-weight: inherit; color: inherit;}
                    & > em{font-style: normal; font-size: inherit; font-weight: inherit; color: #f60019;}
                }
                &-sub{display: block; }
            }

            .popup {
                &-header {
                    width: 100%;
                    padding: 0 2.8571vw;
                    & + .popup-body {
                        padding-top: 2.8571vw;
                    }
                    .box-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 8.5714vw; height: 8.5714vw;
                    }
                }
                &-body {
                    width: 100%;  padding: 4.2857vw 2.8571vw; background: #fff; text-align: center;
                }
                &-footer {
                    width: 100%; background: rgba(25, 25, 25, 0.85);
                }
            }

        }
    }

    // toast popup
    .pop-toast{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        opacity: 0;
        z-index: 999;
        visibility: hidden;
        transition: all 0.3s;
        left: 50%;
        bottom: 1.0000vw;
        transform: translateX(-50%);
        padding: 1.2143vw 0 ;
        border-radius: 1.4286vw;
        width:100%;
        max-width: 45.7143vw;
        color: #fff;
        background-color: rgba(25, 25, 25,0.67);
        box-shadow: 0 0.2143vw 2.1429vw 0 rgba(0, 0, 0, 0.16);
        &.on{
            opacity: 1;
            visibility: visible;
            bottom:4.2857vw;
        }
    }

    // Layer popup
    .layer-popup {
        display: none;align-items: center;justify-content: center;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: rgba(25, 25, 25, 0.7);z-index: 200;

        &.show {
            .layer-popup-item {
                opacity: 1;transform: translateY(0);transition-delay: 0.2s;
            }
        }


        .layer-popup-item {
            display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;max-width: 45.7143vw;border-radius: 1.4286vw;overflow: hidden;opacity: 0;transform: translateY(-3.5714vw);transition: all 0.3s ease-out;
            .txt {
                &-main{
                    display: block; margin-bottom: 0.7143vw;font-weight: 700;text-align: center;
                    & > span{font-size: inherit; font-weight: inherit; color: inherit;}
                    & > em{font-style: normal; font-size: inherit; font-weight: inherit; color: #f60019;}
                }
                &-sub{
                    display: block;
                    & + .default-txt{display: block; margin-top: 1.4286vw;}
                    &+.txt-sub{display: block; margin-top: 1.4286vw;}
                }
            }

            .popup {
                &-header {
                    background: #fff;
                    width: 100%;
                    padding: 4.2857vw 2.8571vw 0 2.8571vw;
                    & + .popup-body {padding-top: 2.8571vw;}
                    .box-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:8.5714vw; height: 8.5714vw; margin:0 auto;
                    }
                }
                &-body {
                    width: 100%; padding: 4.2857vw 2.8571vw; background: #fff; text-align: center;
                    .notification {
                        li{display: inline-block;}
                    }
                    .round-box{
                        text-align: left; border:0.1429vw solid $gray02; padding:2.8571vw; margin-top: 2.8571vw; border-radius:1.4286vw;
                        p{
                            & + .notification{
                                margin-top: 1.4286vw;
                            }
                        }
                        .notification{
                            word-break: break-all;
                            a{color: #0054ff;}
                        }
                    }

                }
                &-footer {
                    width: 100%; background: rgba(25, 25, 25, 0.85);
                }
            }

            .box-btn{font-size: 0;}

            // 정보 존재하지 않습니다 팝업
            &.type-info{
                .popup{
                    &-header{
                        .txt{
                            &-main{margin-bottom: 0.7143vw; text-align: center;}
                            &-sub{
                                text-align: center;
                                & > a{color:#0054ff;}
                            }
                        }
                    }
                    &-body {
                        text-align: left; padding: 2.8571vw 2.8571vw 4.2857vw 2.8571vw;
                        .txt-sub{
                            .icon-title{
                                display: block; position: relative; padding-left: 1.1429vw;
                                &::before {
                                    content: ''; display: inline-block; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 0.4286vw; height: 0.4286vw; border-radius: 50%; background-color: $black;
                                }
                                & ~ .icon-title{margin-top: 1.4286vw;}
                            }
                            .desc{
                                display: block;margin-top: 0.7143vw;padding-left: 1.1429vw;
                                &.call-center{
                                    & > a{color:#0054ff;}
                                }
                            }

                        }
                    }
                }
            }
        }

        // bottom sheet
        &.bottom-sheet-wrap {
            align-items: flex-end;
            &.show {
                .layer-popup-item {
                    transform: translateY(0);
                }
            }
            .layer-popup-item {
                width: 100%;
                max-width: 100%;
                max-height: 100vh;
                border-radius: 1.4286vw 1.4286vw 0 0;
                transform: translateY(100%);
                .popup {
                    &-header {
                        display: flex;justify-content: space-between;align-items: center; gap : 2.1429vw;padding-bottom: 2.1429vw;
                        .txt-main{
                            margin-bottom: 0;
                            text-align: left;
                        }
                        .popup-close{
                            flex-shrink: 0;
                            width: 2.2857vw;
                            height: 2.2857vw;
                            @include backgroundImg('/assets/images/icon/ico-close.svg', center, contain);
                        }
                    }
                    &-body {
                        padding-bottom: 4.2857vw; text-align: left;
                        font-weight: 600;

                        &.type-01{
                            height: 65vh;
                        }
                        .is-scroll{height: 100%; overflow-y: scroll; overflow-x: hidden;}
                    }
                    &-footer {
                        padding-top: 1.4286vw; background: #fff;
                    }
                }
            }
        }

    }

    // full popup
    //06.29 : head fixed 수정
    .full-popup{
        left: 0; top: 0; z-index: 102; width: 100%; min-height: 100vh; background: #fff;
        &.on{
            display: block !important; position: fixed;
            .inner{
                height: 100vh; overflow-x: hidden; overflow-y: auto;
            }
        }
        &.layer-type{
            display: none;
        }
        &.search-pop{
            position: absolute; z-index: 120 !important; height: 100%; overflow-y: scroll;
        }

        header{
            display: flex; justify-content: center; align-items: center; position:fixed; left: 0; top: 0; z-index: 102; width:100%; height: 8.5714vw; border-bottom: 0.1429vw solid #ddd; box-sizing: border-box; background: #fff;
            h2{
                text-align: center; display: block;
                max-width: calc(100% - 13.4286vw );
            }
            button{
                display: inline-flex; width: 5.7143vw; height: 5.7143vw;  @include backgroundImg('/assets/images/common/header-btn-close.png', 50%, 2.2857vw); font-size: 0;position: absolute; top: 50%; right:  1.4286vw;  transform: translateY(-50%);
            }
        }
        .full-popup-conts{
            .inner{
                min-height: 100%; padding: 14.2857vw 2.8571vw 14.2857vw 2.8571vw;
            }
        }
        .btns.sticky{
            position: fixed; left: 0; bottom: 0;
        }
        .btn-group.sticky{
            position: fixed; left: 0; bottom: 0;
        }
    }

    .step-process{
        position: sticky; left: 0; top: 8.5714vw; z-index: 101; width: 51.4286vw; margin-left: -2.8571vw; background: #e6e6e6;
        ol{
            display: flex;
            li{
                flex: 1; position: relative; height:0.7143vw; font-size: 0;
                &.complete{
                    background: #7c40ce;
                    &:after{
                        content: ''; position: absolute; right: -0.3571vw; top: 0; width: 0.7143vw; height: 0.7143vw; border-radius: 50%; background: #7c40ce;
                    }
                }
            }
        }
    }
    //링크 공유 하기 바텀시트
    .sns-share-bottomSheet{
        .popup-body{
            .url-link-wrap{
                margin-bottom: 4.2857vw; margin-top: 2.8571vw;
            }
            .list-sns{
                display: flex; justify-content: space-between; gap:1.1429vw;
                li{
                    text-align: center;
                    img{
                        width:10.5714vw; height: 10.5714vw; display: block;
                    }
                    span{display: block; }
                }
            }
        }

    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .full-popup{
        header{
            height: 60px; border-bottom-width: 1px;
            button{
                width: 40px; height: 40px; right: 10px; background-size: 16px;
            }
            &.tit-lft{padding-left: 20px;}
        }
        .full-popup-conts{
            .inner{
                padding: 80px 20px;
                .unit-img{text-align: center;}
            }
        }
    }
    .layer-popup{
        .layer-popup-item{
            max-width: 60%; border-radius: 8px;
            .popup-header{padding: 30px 20px 0 20px;
                & + .popup-body{padding-top: 20px;}
                .box-icon{width: 60px; height: 60px;}
                .btn-close{width: 25px; height: 25px; right: 20px;}
            }
            .popup-body{
                padding: 30px 20px;
                .round-box{border-width: 1px; border-radius: 8px; padding: 20px; margin-top: 20px;} 
            }
            .txt-main{margin-bottom: 5px;}
            .txt-sub{
                & + .txt-sub{margin-top: 10px;}
            }
            &.type-info{
                .popup-body{
                    .txt-sub{
                        .desc{margin-top: 5px; padding-left: 8px;}
                    }
                    .txt-sub{
                        .icon-title{
                            padding-left: 8px;
                            &::before{width: 3px; height: 3px;}
                            & ~ .icon-title{margin-top: 15px;}
                        }
                    }
                }
            }
        }
        &.bottom-sheet-wrap{
            .layer-popup-item{
                .popup-header{
                    padding-bottom: 15px;
                    .popup-close{width: 16px; height: 16px;}
                }
                .popup-body{
                    padding-bottom: 30px;
                    &.type-01{height: 285px;}
                }
                .popup-footer{padding-top: 10px;}
            }
        }
    }
    .sns-share-bottomSheet{
        .popup-body{
            .list-sns{
                justify-content: space-around; gap: 0;
                li{
                    img{width: 70px; height: 70px;}
                }
            }
            .url-link-wrap{margin-top: 20px; margin-bottom: 20px;}
        }
    }
    .step-process{
        top: 60px; height: 5px;
        ol{
            li{
                &.complete{
                    height: 5px;
                    &::after{height: 5px;}
                }
            }
        }
    }
}