
.point-info-card{
    .money-right{
        dl{
            dt{
                width:60%;
                em{
                    display: block; @include subContsTitle; margin-top: 1.3889vw;
                }
            }
            dd{width:40%;}
            &:first-child{align-items: flex-end;}
        }
    }
}
.point-sort-card{
    .cardList{
        padding:8.3333vw 5.5556vw;
        dt{width:50.6944vw!important; padding-bottom:0;}
        dd{ width:calc(100% - 50.6944vw)!important; padding-bottom:0;}

        .middle{
            margin-top: 4.1667vw;
            dl{padding-top: 1.3889vw;}
            .btns{margin-top:4.1667vw;}
            .strong{@include subContsTitle;}
        }
        .bottom{
            margin-top:5.5556vw; border-top:0.2778vw solid $gray02; padding-top:5.5556vw;
            .notification{
                margin-top: 4.1667vw;
            }
        }
    }
}
.list{
    border-top:0.2778vw solid $gray02; margin-top: 11.1111vw;  margin-bottom: 2.7778vw;
    li,div{
        &.notice {
            strong{@include mainTxt;}
        }
        a{
            display: block;
            width:100%;
            padding:5.5556vw 0;
            border-bottom:0.2778vw solid $gray02;
            @include mainEmphasize;
            /*strong{
               @include fontInherit; @include ellipsis;
            }*/
            strong {
                width:100%;overflow:hidden;text-overflow:ellipsis;white-space:normal;
                display:-webkit-box !important;-webkit-line-clamp:2;-webkit-box-orient:vertical;
            }
            .date{
                    margin-top:1.3889vw; display: flex; justify-content: space-between; align-items: center;
                p{
                    @include smallDepthTxt;
                    span{
                         @include fontInherit;
                    }
                }
            }

        }
    }
}
.list-wrap {
    .list-opt {
        display:flex;justify-content:space-between;margin-top:11.1111vw;margin-bottom:2.7778vw;
        .info {@include defaultTxt;}
        &+.list {margin-top:0;}
    }
}
 .list-view{
    margin-bottom: 11.1111vw;
    dt{
        padding-bottom:5.5556vw;
        strong{
            display:block;@include mainTxt;
        }
        .date{
            display:block;margin-top: 2.7778vw;@include smallDepthTxt;
        }
    }
    dd{
        border-bottom:0.2778vw solid $gray02; border-top:0.2778vw solid $gray02; padding:8.3333vw 0;
        @include mainEmphasize;
    }
    .view-img{
        margin:5.5556vw 0; display: block;
    }
    .use_m{
        position: relative; width: 100%;height: 55.5556vw; overflow: hidden;border-radius: 2.7778vw;
        iframe {
            position: absolute;top: 0;left: 0;width: 100%;height: 100%;
        }
    }
}

.cardList-wrap{
    width:100%; margin:0 auto; box-sizing: border-box;
    // padding:0 5.5556vw;
    .cardList-title{
        @include mainTxt;
        margin-bottom:2.7778vw;
    }
    .list-header{
        display: flex; justify-content: space-between; align-items: center;margin-bottom: 3.8889vw;
        strong{display: block; @include contsTitle; }
        span{display: block; @include smallTxt;}
    }
    .cardList{
        border:0.2778vw solid $gray02;
        border-radius:2.7778vw;
        padding:5.5556vw;
        width:100%;
        dl{
            display: flex;
            flex-wrap: wrap;
            dd{
                @include defaultTxt;
                width:calc(100% - 30.5556vw);
                text-align: left;
                padding-bottom:2.2222vw;
                word-break: break-all;
                &:last-of-type{padding-bottom:0;}
                &.purple{color: $purple;}
            }
            dt{
                @include smallTxt;
                width:30.5556vw;
                text-align: left;
                padding-bottom:2.2222vw;
                &:last-of-type{padding-bottom:0;}
            }
        }
        &.no-list{
            padding: 10.8333vw 5.5556vw;
            dt{width: 100%; text-align: center;}
        }
        &.complete{
            .list-header{
                span{color: $purple;}
            }
        }
        &.cancel{
            .list-header{
                span{color: $gray05;}
            }
        }
        &.notice-card{
            .title{ @include mainTxt;}
            strong{
                @include subContsTitle; margin-top: 2.7778vw; display: block; text-align: right;
                em{
                    @include fontInherit;
                }
            }
            .bang-notice{
                margin-top: 1.3889vw;
            }
        }
        &.money-right{
            dl{
                dt{width:30.5556vw;}
                dd{
                    width:calc(100% - 30.5556vw); text-align: right;
                }
                & + dl{
                    border-top:0.2778vw solid #ddd; margin-top:5.5556vw; padding-top:5.5556vw;
                }
            }
        }
        &.one-title{
            padding:4.1667vw;
            dl{
                display: block;
                dt{
                    width:100%; @include smallTxt;
                    strong{
                        @include defaultTxt;
                    }
                }

            }
        }
        &.icon-left-card{
            dl{
                display: flex; align-items: center; width:100%; padding-bottom:5.5556vw; gap:2.7778vw;
                dt{width:16.6667vw}
                dd{width:calc(100% - 19.4445vw);}
                strong{
                    @include defaultTxt; display: block;
                    & + strong{
                        margin-top: 1.3889vw;
                    }
                    & + .box-btn{
                        margin-top: 1.3889vw;
                        & + .box-btn{margin-top: 1.3889vw;}
                    }
                }
                p{@include smallTxt; margin-top: 1.3889vw;}
                & + dl{
                    border-top:0.2778vw solid $gray02; padding-top:5.5556vw; padding-bottom: 0;
                }
            }
        }
    }
    .underLine{
        padding:4.4444vw 5.5556vw;
        dl{
            dt{
                font-weight: 600;
                border-bottom:0.2778vw solid $gray03;
                padding:3.3333vw 0;
                &:first-of-type{padding-top:0;}
                &:last-of-type{border-bottom:0;}
                width:25vw;
            }
            dd{
                border-bottom:0.2778vw solid $gray03;
                padding:3.3333vw 0;
                text-align: left;
                width:calc(100% - 25vw);
                @include smallTxt;
                &:first-of-type{padding-top:0;}
                &:last-of-type{border-bottom:0;}
            }
        }
    }

    & + .cardList-wrap{
        margin-top:5.5556vw;
    }
    .cardList-img{
        width:100%; height:55.5556vw; overflow: hidden;
        background: $gray01;
        border-radius: 2.7778vw;
        position: relative;
    }
    .cardList-desc{
        position: relative; margin-top:4.1667vw; padding-right: 13.8889vw;
        .main-title{
             @include subContsTitle;
             @include ellipsis2;
        }
        .sub-title{
            @include mainEmphasize;
            @include ellipsis;
        }
        .period{
            margin-top:1.3889vw;
            @include smallTxt; font-weight: 600;
        }
        .date{
            @include chipTxt; color: $white;
            position: absolute;
            top:0;
            right:2.7778vw;
            background: $black;
            border-radius:0.6944vw;
            padding:0 1.6667vw;
        }
    }
    .btn-radio-type2, .btn-check{
        &.card-check{
            border-bottom: 0; margin-bottom: 0;
        }
    }
}
//구독서비스 리스트
.life-list{
    display: block; margin-top: 4.1667vw;
    &+.life-list{
        margin-top: 8.3333vw;
    }
    &+button{
        margin-top: 2.7778vw;
    }
    .life-list-img{
        width:100%; height: 100%; min-height:55.5556vw; border-radius:2.7778vw; box-sizing: border-box; position: relative; overflow:hidden; background: #ddd;
        img{
            width:100%; min-width: 55.5556vw; display: block; height:100%;
            @include imgSize; object-fit: cover !important;
        }
        .badge{
            position:absolute; top:4.1667vw; right:4.1667vw;
        }
    }
    figcaption{
        margin-top:4.1667vw;
        .life-list-text{
            display: flex; flex-wrap: wrap; margin-top:2.7778vw; align-items: center;
            dt{
                width:calc(100% - 30.5556vw); text-align: left;
            }
            dd{
                width:30.5556vw; text-align: right;
            }
            &.tit{
                dt{
                    @include mainTxt;
                }
                dd{
                    @include mainTxt;
                }
            }
            &.desc{
                dt{
                    @include smallTxt;
                    position: relative;
                }
                dd{
                    @include defaultTxt; color: $purple;
                }
            }
            & + .life-list-text{
                margin-top:1.3889vw;
            }
        }
    }
}
.card-check{
    border-bottom:0.2778vw solid $gray02;
    margin-bottom:4.1667vw;
    padding-bottom:4.1667vw;
    label{
        span{
            font-size:3.8889vw;
        }
    }
}
.item-top{
    display: flex; width:100%; padding-bottom:4.1667vw; align-items: center; gap:4.1667vw;
    .item-img{
        width:25vw; height:28.0556vw;
        img{
            width:100%;
        }
    }
    .item-txt{
        width:calc(100% - 25.5556vw);
        .item-txt-tit {
            @include mainEmphasize; margin-bottom:1.25vw; display: inline-block;
        }
        .item-txt-desc{
            @include smallDepthTxt;
        }
    }
}
.product-imgCard-btn{
    .cardList{
        border-radius:2.7778vw 2.7778vw 0 0; padding:0; border-bottom:0;
        .item-top{
            padding:5.5556vw;
            .item-img{
                width:25vw; height:28.0556vw;
                img{
                    width:100%;
                }
            }
        }
        dl{
            background:$gray01; padding:5.5556vw;
            dt{
                width:41.9722vw;
            }
            dd{
                width:calc(100% - 41.9722vw); text-align: right;
                span.c-red{
                    @include defaultTxt; color: $pinkpurple; font-weight: 600;
                }
            }
        }

    }
}
.product-imgCard{
    & + .product-imgCard{margin-top: 4.1667vw;}
    .cardList{display: flex; justify-content: space-between; flex-wrap: wrap;gap: 4.1667vw;}
    .card-list-header{display: flex;justify-content: flex-end; position: relative; width: 100%;}
    .chip-wrap{
        position: absolute; left: 0;
    }
    .btn-check{
        label {
            span{
                padding-right: 8.3333vw; padding-left: 0; flex-shrink: 0;
                &::before{right: 0; left: auto;}
                &::after{right: 0; left: auto;}
            }
        }
    }
    .item-left {
        display: flex; align-items: center; width: 100%; max-width: 25vw;
        .item-img{
            position: relative; width: 27.7778vw; height: 27.7778vw;flex-shrink: 0;overflow: hidden;
            img{
                position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; height: 100%; object-fit: contain;
            }
        }
    }
    .item-right {
        width: calc(100% - 29.1667vw);
        .item-txt {
            position: relative;height: 100%;

        }
        .item-txt-wrap{
            display: flex; align-items: flex-start; margin-bottom: 1.3889vw;
            .item-txt-tit{
                @include mainTxt;
            }
            .product-badge{
                margin-top: 0.4167vw;
            }
        }
        .desc{
            @include smallTxt; @include ellipsis2;
        }
        .bang-notice{
            margin-top: 2.7778vw;
            li{
                margin-top: 0;
            }
        }
        .item-price {
           display: flex; justify-content: space-between; align-items: center; width: 100%; margin-top: 3.3333vw;
            & > span{@include defaultTxt;}
            & > strong{
                @include mainTxt;color: $purple;
                em{@include fontInherit;}
            }
        }
    }

}
.payment-imgCard-btn{
    .cardList{
        .item-top{
            display: block;
        }
        .payment-desc{
            display: block; width:100%;
            dt{
                display: flex; justify-content: space-between; width: 100%; align-items: flex-start; margin-top: 2.7778vw;
                .emphasis-txt{
                    @include mainTxt;
                }
                .pay-amount{
                    flex-direction: column;gap:0; flex-shrink: 0; padding: 0;
                    @include mainTxt; color: $purple;
                    .discount-pay {@include mainTxt;color:$purple;}
                    .origin-pay {@include smallDepthTxt;}
                }
            }
            dd{
                width: 100%; margin-top:3.3333vw;
                .notification{
                    margin-top: 4.4444vw;
                    li{
                        @include smallTxt;
                    }
                }
            }
        }
        .item-bottom{

            .btn-text-arrow{
                padding-top:4.1667vw; padding-bottom:4.1667vw; border-top:0.2778vw solid $gray02;
            }
            // .btns{margin-top:5.5556vw;}
        }
    }

    // 하단버튼없고 라디오 기능인경우
    &.payment-list{
        .cardList{
            & + .cardList{margin-top: 4.1667vw;}
            position: relative; border: none;
            label {display:block;position:absolute;left:0;top:0;width:100%;height:100%;overflow:hidden;border:0.2778vw solid $gray02;border-radius:2.7778vw;text-indent:-999vw;transition:0.3s;cursor:pointer;}
            input[type=radio] {
                position:absolute;left:0;top:0;opacity:0;
                &:checked + label {border-color: $purple;}
            }

        }
    }
}
.notification{
    &>li{
        position: relative;
        padding-left:1.9444vw;
        @include smallTxt;
        & + li{margin-top: 1.3889vw;}
        &::before{
            content:'';
            width:0.8333vw;
            height:0.8333vw;
            background: $gray05;
            border-radius: 50%;
            position: absolute;
            top:2.0833vw;
            left:0;
        }
        &:last-child{
            margin-bottom:0;
        }
        span{
            color:$purple; font-size: inherit; font-weight: inherit;
        }
        &.no-dots{
            &::before{display: none;}
        }
    }
    &.txt-rd{
        li{
            color: $red;
            &::before{
                content:'';
                width:0.8333vw;
                height:0.8333vw;
                background: $red;
                border-radius: 50%;
                position: absolute;
                top:2.0833vw;
                left:0;
            }
        }
    }
    li.txt-rd{
        &::before{
            content:'';
            width:0.8333vw;
            height:0.8333vw;
            background: $red;
            border-radius: 50%;
            position: absolute;
            top:2.0833vw;
            left:0;
        }
    }
}
.acc-conts{
    .notification{
        li{@include smallTxt;}
        .txt-eps {color:#ff0000; font-weight: 600;}
    }   
}

.no-result{
    padding-top: 40.2778vw; @include backgroundImg('/assets/images/icon/ico-search-dot.svg',center top,36.1111vw 36.1111vw);
    .no-result-txt{
        @include mainTxt;text-align: center;
        & + .small-txt {display: block; text-align: center; margin-top: 1.3889vw;}
    }
    .small-txt {@include smallTxt; display: block; text-align: center; }
}
.no-search-result{
    padding-top:40.2778vw;@include backgroundImg('/assets/images/icon/ico-no-inquiry.svg',center top, 36.1111vw 36.1111vw);
    .no-result-txt{
        @include mainTxt;text-align: center;
        & + .small-txt {display: block; text-align: center;margin-top:1.3889vw;}
    }
    .small-txt {@include smallTxt; display: block; text-align: center; }
}

.other-list{
    width:100%;
    li{
        padding-bottom:4.1667vw; display: flex; justify-content: space-between; align-items: center; gap:4.1667vw;
        &:last-child{
            border-bottom:0;
        }
        .other-list-img{
            width:27.7778vw; height:17.3611vw; overflow:hidden; border-radius:1.9444vw;
            img{
                width: 100%; display: block;
            }
        }
        dl{
            width:calc(100% - 27.7778vw);
            dt{
                @include mainTxt; margin-bottom: 0.9722vw; @include ellipsis;
            }
            dd{
                @include smallTxt; @include ellipsis;
            }
        }
    }
}
//요금제 아코디언 카드 리스트
.payment-accordian-card-wrap{
    margin-top:8.3333vw;
    .title{
        @include subContsTitle;
    }
    & + .payment-accordian-card-wrap{
        margin-top: 11.1111vw;
    }
    .payment-accordian-card{
        /*margin-top:4.1667vw;*/margin-top:2.7778vw;border:0.2778vw solid $gray02; border-radius:2.7778vw;/*padding:5.5556vw;*/padding:0 5.5556vw;/*08.29 수정*/
        .chip-wrap{
            margin-bottom:2.7778vw;
        }
        .accordian{
            /*padding:4.1667vw 0;*/padding:8.3333vw 0;border-bottom:0.2778vw solid #ddd;/*08.29 수정*/
            &>.acc-trigger{
                border-bottom:0; padding-bottom:0; @include mainTxt;
            }
            .acc-conts{
                margin-top:4.1667vw; padding:4.1667vw;
                .btns{
                    margin-top: 5.5556vw;
                }
            }
            /*&:first-child{
                padding-top:0;
            }*//*08.29 수정*/
            &:last-child{
                border-bottom:0;/*padding-bottom:0;*//*08.29 수정*/
            }
        }
        /*.notification{
            li{
                @include smallDepthTxt;
            }
        }*//*08.29 수정*/
    }
}

//요금제 - 카드 리스트
.payment-list-card-wrap{
    .title{
        @include mainTxt; margin-bottom: 4.1667vw;
    }
    .payment-list-card{
        border:0.2778vw solid #ddd; border-radius:2.7778vw; padding:8.3333vw 5.5556vw;
        .sub-title{@include mainTxt;}
        .notification{margin-top: 2.7778vw;}
        & + .payment-list-card{
            margin-top: 4.1667vw;
        }
    }

}

//금액
.pay-amount{
    padding-top:2.7778vw; display: flex; align-items: flex-end; justify-content: flex-end; gap:2.7778vw;
    .origin-pay{ @include smallDepthTxt;display: inline-block;text-decoration:line-through;}
    .discount-pay{ @include contsTitle; color:$purple !important;}
    .other-pay {@include contsTitle;}
    .undeter-pay{ @include mainTxt;}
}
//물음표 버튼
.qos-popup{
    display: inline-block; width: 4.1667vw;height: 4.1667vw;@include backgroundImg('/assets/images/icon/ico-tip.svg', center,contain); margin-left:1.3889vw; vertical-align: top; margin-top: 0.2vw;
}
// 요금제 디테일 카드
.payment-detail-card-wrap{
    padding: 5.5556vw;border: solid 0.2778vw $gray02; border-radius: 2.7778vw;
    .txt-vt{@include fontInherit;}
    & > ul{
        & > li:not(:first-of-type){
            border-top: solid 0.1389vw $gray02; padding-top: 5.5556vw;margin-top: 5.5556vw;
        }
    }
    .detail-title{
        @include defaultTxt; display: block; margin-bottom: 2.7778vw;
        & + em{
            @include subContsTitle; display: block; text-align: right;
        }
    }
    .notification{
        * {
            @include smallTxt;
        }
        li{
            display: flex; justify-content: space-between;
            &.gift{
                justify-content: flex-start;
                span{
                     display: block;
                     &:first-child{width:40%;}
                     &:last-child{width:60%;}
                }
            }
        }
        .total-price{@include smallTxt; font-weight: 600;}
    }

}

// 아이콘과 텍스트있는 카드
.icon-txt-card{
    img{width: 16.6667vw;}
    display: flex; gap: 2.7778vw; align-items: center; border: solid 0.2778vw $gray02;border-radius: 2.7778vw; padding: 5.8333vw; @include smallTxt;
}

// 이용중인 상품 리스트
.using-goods{
    & + .using-goods{margin-top: 4.1667vw;}
    .item {
        &-top{
            flex-direction: column; align-items: flex-start; padding-bottom: 5.5556vw;
            a{width: 100%;}
            .btn-box{
                display: flex; justify-content: space-between;
                .goods-title{
                    .goods {
                        &-name{
                            display: block; @include mainTxt;
                        }
                        &-date{
                            display: block; @include smallTxt; margin-top: 1.3889vw;
                        }
                    }
                }
            }
            .txt-vt{@include defaultTxt;}
            .choose-agree{
                display: flex; justify-content: space-between; align-items: center;
            }
        }
        &-bottom {
            display: flex; justify-content: space-between; border-top: 0.2778vw solid $gray02; padding-top: 5.5556vw;
            .category-tit{
                @include smallTxt; font-weight: 600;
            }
            .box-pay{
                display: flex; gap: 4.1667vw;
                * {@include defaultTxt;}
            }
            a{
                display: flex; justify-content: space-between; width: 100%;
                .box-pay{
                    gap: 0;
                    b{
                        &+b{
                            margin: 0 1.3889vw 0 4.1667vw;
                        }
                    }
                }
            }
        }
    }
}

.using-market {
    .item-top {
        display:block;padding-bottom:0;
        .btn-box {
            display:flex;justify-content:space-between;
            .market {
                .logo {
                    display:block;font-size:0;
                    img {height:4.1667vw;vertical-align:top;}
                }
                .info {display:block;margin-top:2.7778vw;@include mainTxt;}
            }
        }
        .point {
            margin-top:1.3889vw;@include smallTxt;color:$purple;line-height:1.33;
            * {@include fontInherit;}
        }
    }
    .item-bottom {
        display:flex;justify-content:flex-end;padding-top:5.5556vw;
        .discount {
            @include subContsTitle;color:$purple;text-align:right;
            span {margin-right:1.3889vw;@include smallDepthTxt;text-decoration:line-through;}
        }
    }
}


//매장 찾기
.place-search-result {
    margin-top:11.1111vw;
    .info {
        padding-bottom:4.1667vw;@include defaultTxt;
        .txt-vt{
            @include fontInherit; font-weight: 600;
        }
    }
    .result-list {
        padding-top:5.5556vw; border-top:0.5556vw solid $gray02; border-bottom:0.2778vw solid $gray02;
        .place-address {padding-bottom:8.3333vw;}

        &.cms {
            padding-top:0;border-top:0.2778vw solid $gray02;
            .place-address {
                padding:5.5556vw 0;
                &.loc {
                    .btn-loc {top:5.5556vw;}
                }
            }
        }
    }
    .result-more {padding:5.5556vw 0;text-align:center;}
}

.place-address {
    position:relative;
    dt {@include defaultTxt;}
    dd {
        margin-top: 1.3889vw;
        li {@include smallTxt;
        }
    }
    .box-btn {margin-top:4.1667vw;}
    &.loc {
        dl {padding-right:13.8889vw;}
        .btn-loc {
            display:block;position:absolute;right:0;top:0;overflow:hidden;width:11.1111vw;height:11.1111vw;border-radius:50%;font-size:0;text-indent:-20vw;
            @include backgroundImg('/assets/images/icon/ico-location.svg', center, 11.1111vw 11.1111vw);
        }
    }
}
.cms {
    .place-address {
        &:before {content:'';display:block;position:absolute;left:-5.5556vw;top:0;z-index:0;width:100vw;height:100%;background:$white;transition:0.3s;}
        .chip-wrap {position:relative;z-index:1;margin-bottom:2.7778vw;}
        dl {
            position:relative;z-index:1;
            dt {margin-bottom:1.3889vw;@include defaultTxt;color:$gray05;}
        }
        .stock {
            margin-top:2.7778vw;@include defaultTxt;color:$gray05;
            &.on {color:$purple}
        }
        .box-btn {position:relative;margin-top:5.5556vw;z-index:1;}
        .btn-loc {z-index:1;transition:0.3s;}

        &:hover, &:focus {
            dl {
                dt {color:#191919 !important;}
                dd {
                    li{
                        color:#191919 !important;
                        &.stock {
                            &.on {color:#7c40ce !important;}
                        }
                    }
                }
            }
            &.loc {
                &:before {background:#f9f5ff;}
                .btn-loc {
                    @include backgroundImg('/assets/images/icon/ico-location-white.svg', center, 11.1111vw 11.1111vw);
                }
            }
        }
    }
}

//원형숫자 step
.num-process-wrap{
    border:0.2778vw solid $gray02;border-radius:1.9444vw;padding:6.4444vw;
    & > li {
        display:flex;gap:4.1667vw;position:relative;padding-left:6.9444vw;padding-bottom:6.25vw;margin-left:2.7778vw;
        &::before {content:'';display:block;position:absolute;left:0;top:0;z-index:0;width:0.2778vw;height:100%;background:$purple;}
        &:first-child::after {display:none !important;}
        &:last-child {
            padding-bottom: 0;
            &::before {left:-0.2778vw;top:-2.7778vw;z-index:1;width:0.8333vw;height:2.7778vw;background:$white;}
            &::after {
                content:'';display:block;position:absolute;left:0;top:0;z-index:1;z-index:1;width:5.5556vw;height:5.5556vw;margin:-5.9556vw 0 0 -2.6778vw;
                @include backgroundImg('/assets/images/icon/ico-arrow-purple.svg', center top, 5.5556vw 5.5556vw);
            }
        }
        &.location{
            .txt-wrap{
                strong{
                    color:$purple;
                }
            }
        }
        &.disabled {
            .number{background-color: $gray02;}
            .txt-wrap{
                *{
                    color: $gray04;
                }
            }

        }
        .number {
            display:inline-block;position:absolute;left:-3.1778vw;width:6.3889vw;height:6.3889vw;border-radius:50%;background-color:$purple;
            @include defaultTxt;color:$white;line-height: 6.3889vw;text-align: center;
        }
        .txt-wrap {
            strong{display:block;@include defaultTxt;}
            p {@include smallTxt;margin-top:1.3889vw;}
        }
    }
}

//회색상단 카드리스트
.reward-card-wrap {
    overflow:hidden;border:0.2778vw solid $gray02;border-radius:2.7778vw;
    dl {
        dt {
            padding:4.1667vw 5.5556vw;background:$gray01;
            strong {@include defaultTxt;}
            .txt {margin-top:1.3889vw;@include smallTxt;line-height:1.33;}
        }
        dd {
            padding:4.1667vw 5.5556vw;
            b {@include mainTxt;}
            .txt {margin-top:2.7778vw;@include smallTxt;line-height:1.33;}
        }
    }
}

.thumb-list {
    & > li {
        position:relative;
        &+li {margin-top:11.1111vw;}
        .tit {
            display:block;margin:5.5556vw 0 0;@include subContsTitle; @include ellipsis2; word-break: break-all;
        }
        .date {
            display:block; margin:1.3889vw 0 0; @include smallDepthTxt;
            span {@include fontInherit;}
        }
        .movie {
            width:100%;height:55.5556vw;
            a {
                display:block;position:absolute;left:0;top:0;overflow:hidden;z-index:1;width:100%;height:55.5556vw;text-indent:-999px;
                @include backgroundImg('/assets/images/icon/ico-cir-play.png', center center, 11.1111vw 11.1111vw);
            }
            img {position:relative;z-index:0;}
        }
        img {border-radius:2.7778vw;}
    }
}

.back-thumb-list{
    display: flex; flex-wrap: wrap; flex-direction: column;
    .back-thumb-card{
        position: relative; flex: 0 1 auto;  width: 100%; height: 88.8889vw; border-radius: 2.7778vw; overflow: hidden; transform: translate3d(0,0,0);
        & > a{
            position: relative; width: 100%;  height: 100%; padding: 8.3333vw 5.5556vw; display: block;
            .back-conts{
                display: flex; flex-direction: column; justify-content: space-between; position: relative; z-index: 2; width: 100%; height: 100%;
                .back-header{
                    * {color:$white !important;}
                    h3 {display:flex;justify-content:space-between;@include smallTxt;}
                    .categ {
                        img {height:4.1667vw;}
                    }
                    p {margin:1.3889vw 0 2.7778vw;@include mainContsTitle;font-weight:600;}
                    .feature {
                        span {@include smallDepthTxt;font-size:3.8889vw;}
                    }
                    .pay-amount {
                        padding-top:5.5556vw;
                        .origin-pay {@include smallTxt;opacity:0.5;}
                        .discount-pay {@include mainContsTitle;font-weight:600;}
                    }
                }
            }
            .back-bg{
                    display: flex; align-items: center; justify-content: center;  position: absolute;  top: 0; left: 0; width: 100%;  height: 100%;  overflow: hidden; border-radius: 2.7778vw;
                img{
                    width: 100%; height: 100%; object-fit: cover;
                }
            }
            &::before{
                content: ""; position: absolute; top: 0; left: 0;  z-index: 1; display: block; width: 100%; height: 100%; background-color: #a1a1a1; opacity: .2; border-radius: 2.7778vw;
            }
        }
        & + .back-thumb-card{
            margin-top: 4.1667vw;
        }
    }
}


//요금제 라디오 카드 리스트
.payment-radio-card-wrap{
    margin-top:8.3333vw;
    .title{
        @include mainTxt;margin-bottom:4.1667vw;
    }
    & + .payment-radio-card-wrap{
        margin-top: 11.1111vw;
    }
    .payment-radio-card{
        & + .payment-radio-card {margin-top: 4.1667vw;}
        .chip-wrap{
            margin-bottom:2.7778vw;
        }
        input[type="radio"] {
            @include hidden;
            &:checked + label{
                border-color: $purple;
            }
        }
        .payment-conts-wrap{
            display: block; padding:5.5556vw;border:0.2778vw solid $gray02; border-radius:2.7778vw; transition: border-color 0.3s;
            .chip-warp{
                margin-bottom:2.7778vw;
            }
            .payment-title{
                border-bottom:0; padding-bottom:0; @include mainTxt;
            }
        }
        .notification{
            li{
                @include smallDepthTxt;
            }
        }
        .payment-conts{
            margin-top: 4.1667vw;
            padding: 4.1667vw;border-radius: 2.7778vw;background: $gray01;
        }
    }
}
.card-line-gray{
    border:0.2778vw solid $gray02; text-align: center; padding:5.5556vw;  border-radius: 2.7778vw;
    p{
        @include smallTxt; margin-top: 1.3889vw;
    }
    .btn-set{margin-top: 5.5556vw;}
}

.num-list {
    li {
       padding-left:3.4722vw; font-size: 4.1667vw; line-height: 1.5;letter-spacing: -0.0444vw;text-align: left;color: #666;
       .num {display: inline-block; text-indent: -4.1667vw;}
       & + li {margin-top:0.6944vw;}
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .point-info-card{
        .money-right{
            dl{
                dt{
                    width:60%;
                    em{
                        display: block;  margin-top: 0.7143vw;
                    }
                }
                dd{width:40%;}
                &:first-child{align-items: flex-end;}
            }
        }
    }
    .point-sort-card{
        .cardList{
            padding:4.2857vw 2.8571vw;
            dt{width:26.0714vw!important; padding-bottom:0;}
            dd{ width:calc(100% - 26.0714vw)!important; padding-bottom:0;}

            .middle{
                margin-top: 2.1429vw;
                dl{padding-top: 0.7143vw;}
                .btns{margin-top:2.1429vw;}
            }
            .bottom{
                margin-top:2.8571vw; border-top:0.1429vw solid $gray02; padding-top:2.8571vw;
                .notification{
                    margin-top: 2.1429vw;
                }
            }
        }
    }
    .list{
        border-top:0.1429vw solid $gray02; margin-top: 5.7143vw;  margin-bottom: 1.4286vw;
        li,div{
            a{
                display: block;
                width:100%;
                padding:2.8571vw 0;
                border-bottom:0.1429vw solid $gray02;
                strong {
                    width:100%;overflow:hidden;text-overflow:ellipsis;white-space:normal;
                    display:-webkit-box !important;-webkit-line-clamp:2;-webkit-box-orient:vertical;
                }
                .date{
                        margin-top:0.7143vw; display: flex; justify-content: space-between; align-items: center;
                }

            }
        }
    }
    .list-wrap {
        .list-opt {
            display:flex;justify-content:space-between;margin-top:5.7143vw;margin-bottom:1.4286vw;
            &+.list {margin-top:0;}
        }
    }
    .list-view{
        margin-bottom: 5.7143vw;
        dt{
            padding-bottom:2.8571vw;
            strong{
                display:block;
            }
            .date{
                display:block;margin-top: 1.4286vw;
            }
        }
        dd{
            border-bottom:0.1429vw solid $gray02; border-top:0.1429vw solid $gray02; padding:4.2857vw 0;
        }
        .view-img{
            margin:2.8571vw 0; display: block;
        }
        .use_m{
            position: relative; width: 100%;height: 28.5714vw; overflow: hidden;border-radius: 1.4286vw;
            iframe {
                position: absolute;top: 0;left: 0;width: 100%;height: 100%;
            }
        }
    }

    .cardList-wrap{
        width:100%; margin:0 auto; box-sizing: border-box;
        .cardList-title{
            margin-bottom:1.4286vw;
        }
        .list-header{
            display: flex; justify-content: space-between; align-items: center;margin-bottom: 2.0000vw;
            strong{display: block; }
            span{display: block;}
        }
        .cardList{
            border:0.1429vw solid $gray02;
            border-radius:1.4286vw;
            padding:2.8571vw;
            width:100%;
            dl{
                display: flex;
                flex-wrap: wrap;
                dd{
                    width:calc(100% - 15.7143vw);
                    text-align: left;
                    padding-bottom:1.1429vw;
                    word-break: break-all;
                    &:last-of-type{padding-bottom:0;}
                    &.purple{color: $purple;}
                }
                dt{
                    width:15.7143vw;
                    text-align: left;
                    padding-bottom:1.1429vw;
                    &:last-of-type{padding-bottom:0;}
                }
            }
            &.no-list{
                padding: 5.5714vw 2.8571vw;
                dt{width: 100%; text-align: center;}
            }
            &.complete{
                .list-header{
                    span{color: $purple;}
                }
            }
            &.cancel{
                .list-header{
                    span{color: $gray05;}
                }
            }
            &.notice-card{
                strong{
                    margin-top: 1.4286vw; display: block; text-align: right;
                }
                .bang-notice{
                    margin-top: 0.7143vw;
                }
            }
            &.money-right{
                dl{
                    dt{width:15.7143vw;}
                    dd{
                        width:calc(100% - 15.7143vw); text-align: right;
                    }
                    & + dl{
                        border-top:0.1429vw solid #ddd; margin-top:2.8571vw; padding-top:2.8571vw;
                    }
                }
            }
            &.one-title{
                padding:2.1429vw;
                dl{
                    display: block;
                    dt{
                        width:100%;
                    }

                }
            }
            &.icon-left-card{
                dl{
                    display: flex; align-items: center; width:100%; padding-bottom:2.8571vw; gap:1.4286vw;
                    dt{width:8.5714vw}
                    dd{width:calc(100% - 10.0000vw);}
                    strong{
                        display: block;
                        & + strong{
                            margin-top: 0.7143vw;
                        }
                        & + .box-btn{
                            margin-top: 0.7143vw;
                            & + .box-btn{margin-top: 0.7143vw;}
                        }
                    }
                    p{ margin-top: 0.7143vw;}
                    & + dl{
                        border-top:0.1429vw solid $gray02; padding-top:2.8571vw; padding-bottom: 0;
                    }
                }
            }
        }
        .underLine{
            padding:2.2857vw 2.8571vw;
            dl{
                dt{
                    font-weight: 600;
                    border-bottom:0.1429vw solid $gray03;
                    padding:1.7143vw 0;
                    &:first-of-type{padding-top:0;}
                    &:last-of-type{border-bottom:0;}
                    width:12.8571vw;
                }
                dd{
                    border-bottom:0.1429vw solid $gray03;
                    padding:1.7143vw 0;
                    text-align: left;
                    width:calc(100% - 12.8571vw);
                    &:first-of-type{padding-top:0;}
                    &:last-of-type{border-bottom:0;}
                }
            }
        }

        & + .cardList-wrap{
            margin-top:2.8571vw;
        }
        .cardList-img{
            width:100%; height:28.5714vw; overflow: hidden;
            background: $gray01;
            border-radius: 1.4286vw;
            position: relative;
        }
        .cardList-desc{
            position: relative; margin-top:2.1429vw; padding-right: 7.1429vw;
            .main-title{
                @include ellipsis2;
            }
            .sub-title{
                @include ellipsis;
            }
            .period{
                margin-top:0.7143vw;
                font-weight: 600;
            }
            .date{
                color: $white;
                position: absolute;
                top:0;
                right:1.4286vw;
                background: $black;
                border-radius:0.3571vw;
                padding:0 0.8571vw;
            }
        }
        .btn-radio-type2, .btn-check{
            &.card-check{
                border-bottom: 0; margin-bottom: 0;
            }
        }
    }
    //구독서비스 리스트
    .life-list{
        display: block; margin-top: 2.1429vw;
        &+.life-list{
            margin-top: 4.2857vw;
        }
        &+button{
            margin-top: 1.4286vw;
        }
        .life-list-img{
            width:100%; height: 100%; min-height:28.5714vw; border-radius:1.4286vw; box-sizing: border-box; position: relative; overflow:hidden; background: #ddd;
            img{
                width:100%; min-width: 28.5714vw; display: block; height:100%;
                @include imgSize; object-fit: cover !important;
            }
            .badge{
                position:absolute; top:2.1429vw; right:2.1429vw;
            }
        }
        figcaption{
            margin-top:2.1429vw;
            .life-list-text{
                display: flex; flex-wrap: wrap; margin-top:1.4286vw; align-items: center;
                dt{
                    width:calc(100% - 15.7143vw); text-align: left;
                }
                dd{
                    width:15.7143vw; text-align: right;
                }
                &.desc{
                    dt{
                        position: relative;
                    }
                    dd{
                        color: $purple;
                    }
                }
                & + .life-list-text{
                    margin-top:0.7143vw;
                }
            }
        }
    }
    .card-check{
        border-bottom:0.1429vw solid $gray02;
        margin-bottom:2.1429vw;
        padding-bottom:2.1429vw;
        label{
            span{
                font-size:2.0000vw;
            }
        }
    }
    .item-top{
        display: flex; width:100%; padding-bottom:2.1429vw; align-items: center; gap:2.1429vw;
        .item-img{
            width:12.8571vw; height:14.4286vw;
            img{
                width:100%;
            }
        }
        .item-txt{
            width:calc(100% - 13.1429vw);
            .item-txt-tit {
                margin-bottom:0.6429vw; display: inline-block;
            }
        }
    }
    .product-imgCard-btn{
        .cardList{
            border-radius:1.4286vw 1.4286vw 0 0; padding:0; border-bottom:0;
            .item-top{
                padding:2.8571vw;
                .item-img{
                    width:12.8571vw; height:14.4286vw;
                    img{
                        width:100%;
                    }
                }
            }
            dl{
                background:$gray01; padding:2.8571vw;
                dt{
                    width:21.5714vw;
                }
                dd{
                    width:calc(100% - 21.5714vw); text-align: right;
                    span.c-red{
                        color: $pinkpurple; font-weight: 600;
                    }
                }
            }

        }
    }
    .product-imgCard{
        & + .product-imgCard{margin-top: 2.1429vw;}
        .cardList{display: flex; justify-content: space-between; flex-wrap: wrap;gap: 2.1429vw;}
        .card-list-header{display: flex;justify-content: flex-end; position: relative; width: 100%;}
        .chip-wrap{
            position: absolute; left: 0;
        }
        .btn-check{
            label {
                span{
                    padding-right: 4.2857vw; padding-left: 0; flex-shrink: 0;
                    &::before{right: 0; left: auto;}
                    &::after{right: 0; left: auto;}
                }
            }
        }
        .item-left {
            display: flex; align-items: center; width: 100%; max-width: 12.8571vw;
            .item-img{
                position: relative; width: 14.2857vw; height: 14.2857vw;flex-shrink: 0;overflow: hidden;
                img{
                    position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; height: 100%; object-fit: contain;
                }
            }
        }
        .item-right {
            width: calc(100% - 15.0000vw);
            .item-txt {
                position: relative;height: 100%;

            }
            .item-txt-wrap{
                display: flex; align-items: flex-start; margin-bottom: 0.7143vw;
                .product-badge{
                    margin-top: 0.2143vw;
                }
            }
            .desc{
                @include ellipsis2;
            }
            .bang-notice{
                margin-top: 1.4286vw;
                li{
                    margin-top: 0;
                }
            }
            .item-price {
            display: flex; justify-content: space-between; align-items: center; width: 100%; margin-top: 1.7143vw;
                & > strong{
                    color: $purple;
                    em{@include fontInherit;}
                }
            }
        }

    }
    .payment-imgCard-btn{
        .cardList{
            .item-top{
                display: block;
            }
            .payment-desc{
                display: block; width:100%;
                dt{
                    display: flex; justify-content: space-between; width: 100%; align-items: flex-start; margin-top: 1.4286vw;
                    .pay-amount{
                        flex-direction: column;gap:0; flex-shrink: 0; padding: 0;
                        color: $purple;
                        .discount-pay {color:$purple;}
                    }
                }
                dd{
                    width: 100%; margin-top:1.7143vw;
                    .notification{
                        margin-top: 2.2857vw;
                    }
                }
            }
            .item-bottom{

                .btn-text-arrow{
                    padding-top:2.1429vw; padding-bottom:2.1429vw; border-top:0.1429vw solid $gray02;
                }
                // .btns{margin-top:2.8571vw;}
            }
        }

        // 하단버튼없고 라디오 기능인경우
        &.payment-list{
            .cardList{
                & + .cardList{margin-top: 2.1429vw;}
                position: relative; border: none;
                label {display:block;position:absolute;left:0;top:0;width:100%;height:100%;overflow:hidden;border:0.1429vw solid $gray02;border-radius:1.4286vw;text-indent:-513.7857vw;transition:0.3s;cursor:pointer;}
                input[type=radio] {
                    position:absolute;left:0;top:0;opacity:0;
                    &:checked + label {border-color: $purple;}
                }

            }
        }
    }
    .notification{
        &>li{
            position: relative;
            padding-left:1.0000vw;
            & + li{margin-top: 0.7143vw;}
            &::before{
                content:'';
                width:0.4286vw;
                height:0.4286vw;
                background: $gray05;
                border-radius: 50%;
                position: absolute;
                top:1.0714vw;
                left:0;
            }
            &:last-child{
                margin-bottom:0;
            }
            span{
                color:$purple; font-size: inherit; font-weight: inherit;
            }
            &.no-dots{
                &::before{display: none;}
            }
        }
        &.txt-rd{
            li{
                color: $red;
                &::before{
                    content:'';
                    width:0.4286vw;
                    height:0.4286vw;
                    background: $red;
                    border-radius: 50%;
                    position: absolute;
                    top:1.0714vw;
                    left:0;
                }
            }
        }
        li.txt-rd{
            &::before{
                content:'';
                width:0.4286vw;
                height:0.4286vw;
                background: $red;
                border-radius: 50%;
                position: absolute;
                top:1.0714vw;
                left:0;
            }
        }
    }

    .no-result{
        padding-top: 20.7143vw; @include backgroundImg('/assets/images/icon/ico-search-dot.svg',center top,18.5714vw 18.5714vw);
        .no-result-txt{
            text-align: center;
            & + .small-txt {display: block; text-align: center; margin-top: 0.7143vw;}
        }
        .small-txt {display: block; text-align: center; }
    }
    .no-search-result{
        padding-top:20.7143vw;@include backgroundImg('/assets/images/icon/ico-no-inquiry.svg',center top, 18.5714vw 18.5714vw);
        .no-result-txt{
            text-align: center;
            & + .small-txt {display: block; text-align: center;margin-top:0.7143vw;}
        }
        .small-txt {display: block; text-align: center; }
    }

    .other-list{
        width:100%;
        li{
            padding-bottom:2.1429vw; display: flex; justify-content: space-between; align-items: center; gap:2.1429vw;
            &:last-child{
                border-bottom:0;
            }
            .other-list-img{
                width:14.2857vw; height:8.9286vw; overflow:hidden; border-radius:1.0000vw;
                img{
                    width: 100%; display: block;
                }
            }
            dl{
                width:calc(100% - 14.2857vw);
                dt{
                    margin-bottom: 0.5000vw; @include ellipsis;
                }
                dd{
                    @include ellipsis;
                }
            }
        }
    }
    //요금제 아코디언 카드 리스트
    .payment-accordian-card-wrap{
        margin-top:4.2857vw;
        & + .payment-accordian-card-wrap{
            margin-top: 5.7143vw;
        }
        .payment-accordian-card{
            /*margin-top:2.1429vw;*/margin-top:1.4286vw;border:0.1429vw solid $gray02; border-radius:1.4286vw;/*padding:2.8571vw;*/padding:0 2.8571vw;/*08.29 수정*/
            .chip-wrap{
                margin-bottom:1.4286vw;
            }
            .accordian{
                padding:4.2857vw 0;border-bottom:0.1429vw solid #ddd;
                &>.acc-trigger{
                    border-bottom:0; padding-bottom:0;
                }
                .acc-conts{
                    margin-top:2.1429vw; padding:2.1429vw;
                    .btns{
                        margin-top: 2.8571vw;
                    }
                }
                &:last-child{
                    border-bottom:0;
                }
            }
        }
    }

    //요금제 - 카드 리스트
    .payment-list-card-wrap{
        .title{
            margin-bottom: 2.1429vw;
        }
        .payment-list-card{
            border:0.1429vw solid #ddd; border-radius:1.4286vw; padding:4.2857vw 2.8571vw;
            .notification{margin-top: 1.4286vw;}
            & + .payment-list-card{
                margin-top: 2.1429vw;
            }
        }

    }

    //금액
    .pay-amount{
        padding-top:1.4286vw; display: flex; align-items: flex-end; justify-content: flex-end; gap:1.4286vw;
        .origin-pay{display: inline-block;text-decoration:line-through;}
        .discount-pay{ color:$purple;}
    }
    //물음표 버튼
    .qos-popup{
        display: inline-block; width: 2.1429vw;height: 2.1429vw;@include backgroundImg('/assets/images/icon/ico-tip.svg', center,contain); margin-left:0.7143vw; vertical-align: top; margin-top: 0.0714vw;
    }
    // 요금제 디테일 카드
    .payment-detail-card-wrap{
        padding: 2.8571vw;border: solid 0.1429vw $gray02; border-radius: 1.4286vw;
        .txt-vt{@include fontInherit;}
        & > ul{
            & > li:not(:first-of-type){
                border-top: solid 0.0714vw $gray02; padding-top: 2.8571vw;margin-top: 2.8571vw;
            }
        }
        .detail-title{
            display: block; margin-bottom: 1.4286vw;
            & + em{
                display: block; text-align: right;
            }
        }
        .notification{
            li{
                display: flex; justify-content: space-between;
                &.gift{
                    justify-content: flex-start;
                    span{
                        display: block;
                        &:first-child{width:40%;}
                        &:last-child{width:60%;}
                    }
                }
            }
            .total-price{font-weight: 600;}
        }

    }

    // 아이콘과 텍스트있는 카드
    .icon-txt-card{
        img{width: 8.5714vw;}
        display: flex; gap: 1.4286vw; align-items: center; border: solid 0.1429vw $gray02;border-radius: 1.4286vw; padding: 3.0000vw;
    }

    // 이용중인 상품 리스트
    .using-goods{
        & + .using-goods{margin-top: 2.1429vw;}
        .item {
            &-top{
                flex-direction: column; align-items: flex-start; padding-bottom: 2.8571vw;
                a{width: 100%;}
                .btn-box{
                    display: flex; justify-content: space-between;
                    .goods-title{
                        .goods {
                            &-name{
                                display: block;
                            }
                            &-date{
                                display: block; margin-top: 0.7143vw;
                            }
                        }
                    }
                }
                .choose-agree{
                    display: flex; justify-content: space-between; align-items: center;
                }
            }
            &-bottom {
                display: flex; justify-content: space-between; border-top: 0.1429vw solid $gray02; padding-top: 2.8571vw;
                .category-tit{
                    font-weight: 600;
                }
                .box-pay{
                    display: flex; gap: 2.1429vw;
                }
                a{
                    display: flex; justify-content: space-between; width: 100%;
                    .box-pay{
                        gap: 0;
                        b{
                            &+b{
                                margin: 0 0.7143vw 0 2.1429vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .using-market {
        .item-top {
            display:block;padding-bottom:0;
            .btn-box {
                display:flex;justify-content:space-between;
                .market {
                    .logo {
                        display:block;font-size:0;
                        img {height:2.1429vw;vertical-align:top;}
                    }
                    .info {display:block;margin-top:1.4286vw;}
                }
            }
            .point {
                margin-top:0.7143vw;color:$purple;line-height:1.33;
                * {@include fontInherit;}
            }
        }
        .item-bottom {
            display:flex;justify-content:flex-end;padding-top:2.8571vw;
            .discount {
                color:$purple;text-align:right;
                span {margin-right:0.7143vw;text-decoration:line-through;}
            }
        }
    }


    //매장 찾기
    .place-search-result {
        margin-top:5.7143vw;
        .info {
            padding-bottom:2.1429vw;
            .txt-vt{
                @include fontInherit; font-weight: 600;
            }
        }
        .result-list {
            padding-top:2.8571vw; border-top:0.2857vw solid $gray02; border-bottom:0.1429vw solid $gray02;
            .place-address {padding-bottom:4.2857vw;}

            &.cms {
                padding-top:0;border-top:0.1429vw solid $gray02;
                .place-address {
                    padding:2.8571vw 0;
                    &.loc {
                        .btn-loc {top:2.8571vw;}
                    }
                }
            }
        }
        .result-more {padding:2.8571vw 0;text-align:center;}
    }

    .place-address {
        position:relative;
        dd {
            margin-top: 0.7143vw;
        }
        .box-btn {margin-top:2.1429vw;}
        &.loc {
            dl {padding-right:7.1429vw;}
            .btn-loc {
                display:block;position:absolute;right:0;top:0;overflow:hidden;width:5.7143vw;height:5.7143vw;border-radius:50%;font-size:0;text-indent:-10.2857vw;
                @include backgroundImg('/assets/images/icon/ico-location.svg', center, 5.7143vw 5.7143vw);
            }
        }
    }
    .cms {
        .place-address {
            &:before {content:'';display:block;position:absolute;left:-2.8571vw;top:0;z-index:0;width:51.4286vw;height:100%;background:$white;transition:0.3s;}
            .chip-wrap {position:relative;z-index:1;margin-bottom:1.4286vw;}
            dl {
                position:relative;z-index:1;
                dt {margin-bottom:0.7143vw;color:$gray05;}
            }
            .stock {
                margin-top:1.4286vw;color:$gray05;
                &.on {color:$purple}
            }
            .box-btn {position:relative;margin-top:2.8571vw;z-index:1;}
            .btn-loc {z-index:1;transition:0.3s;}

            &:hover, &:focus {
                dl {
                    dt {color:#191919 !important;}
                    dd {
                        li{
                            color:#191919 !important;
                            &.stock {
                                &.on {color:#7c40ce !important;}
                            }
                        }
                    }
                }
                &.loc {
                    &:before {background:#f9f5ff;}
                    .btn-loc {
                        @include backgroundImg('/assets/images/icon/ico-location-white.svg', center, 5.7143vw 5.7143vw);
                    }
                }
            }
        }
    }

    //원형숫자 step
    .num-process-wrap{
        border:0.1429vw solid $gray02;border-radius:1.0000vw;padding:3.2857vw;
        & > li {
            display:flex;gap:2.1429vw;position:relative;padding-left:3.5714vw;padding-bottom:3.2143vw;margin-left:1.4286vw;
            &::before {content:'';display:block;position:absolute;left:0;top:0;z-index:0;width:0.1429vw;height:100%;background:$purple;}
            &:first-child::after {display:none !important;}
            &:last-child {
                padding-bottom: 0;
                &::before {left:-0.1429vw;top:-1.4286vw;z-index:1;width:0.4286vw;height:1.4286vw;background:$white;}
                &::after {
                    content:'';display:block;position:absolute;left:0;top:0;z-index:1;z-index:1;width:2.8571vw;height:2.8571vw;margin:-3.0714vw 0 0 -1.3571vw;
                    @include backgroundImg('/assets/images/icon/ico-arrow-purple.svg', center top, 2.8571vw 2.8571vw);
                }
            }
            &.location{
                .txt-wrap{
                    strong{
                        color:$purple;
                    }
                }
            }
            &.disabled {
                .number{background-color: $gray02;}
                .txt-wrap{
                    *{
                        color: $gray04;
                    }
                }

            }
            .number {
                display:inline-block;position:absolute;left:-1.6429vw;width:3.2857vw;height:3.2857vw;border-radius:50%;background-color:$purple;
                color:$white;line-height: 3.2857vw;text-align: center;
            }
            .txt-wrap {
                strong{display:block;}
                p {@include smallTxt;margin-top:0.7143vw;}
            }
        }
    }

    //회색상단 카드리스트
    .reward-card-wrap {
        overflow:hidden;border:0.1429vw solid $gray02;border-radius:1.4286vw;
        dl {
            dt {
                padding:2.1429vw 2.8571vw;background:$gray01;
                .txt {margin-top:0.7143vw;line-height:1.33;}
            }
            dd {
                padding:2.1429vw 2.8571vw;
                .txt {margin-top:1.4286vw;line-height:1.33;}
            }
        }
    }

    .thumb-list {
        & > li {
            position:relative;
            &+li {margin-top:5.7143vw;}
            .tit {display:block;margin:2.8571vw 0 0;}
            .date {
                display:block; margin:0.7143vw 0 0;
                span {@include fontInherit;}
            }
            .movie {
                width:100%;height:28.5714vw;text-align:center;
                a {
                    display:block;position:absolute;left:0;top:0;overflow:hidden;z-index:1;width:100%;height:28.5714vw;text-indent:-71.3571vw;font-size:0;
                    @include backgroundImg('/assets/images/icon/ico-cir-play.png', center center, 5.7143vw 5.7143vw);
                }
                img {position:relative;z-index:0;height:100%;}
            }
            img {border-radius:1.4286vw;}
        }
    }

    .back-thumb-list{
        display: flex; flex-wrap: wrap; flex-direction: column;
        .back-thumb-card{
            position: relative; flex: 0 1 auto;  width: 100%; height: 45.7143vw; border-radius: 1.4286vw; overflow: hidden; transform: translate3d(0,0,0);
            & > a{
                position: relative; width: 100%;  height: 100%; padding: 4.2857vw 2.8571vw; display: block;
                .back-conts{
                    display: flex; flex-direction: column; justify-content: space-between; position: relative; z-index: 2; width: 100%; height: 100%;
                    .back-header{
                        * {color:$white !important;}
                        h3 {display:flex;justify-content:space-between;}
                        .categ {
                            img {height:2.1429vw;}
                        }
                        p {margin:0.7143vw 0 1.4286vw;font-weight:600;}
                        .feature {
                            span {font-size:2.0000vw;}
                        }
                        .pay-amount {
                            padding-top:2.8571vw;
                            .origin-pay {opacity:0.5;}
                            .discount-pay {font-weight:600;}
                        }
                    }
                }
                .back-bg{
                        display: flex; align-items: center; justify-content: center;  position: absolute;  top: 0; left: 0; width: 100%;  height: 100%;  overflow: hidden; border-radius: 1.4286vw;
                    img{
                        width: 100%; height: 100%; object-fit: cover;
                    }
                }
                &::before{
                    content: ""; position: absolute; top: 0; left: 0;  z-index: 1; display: block; width: 100%; height: 100%; background-color: #161616; opacity: .2; border-radius: 1.4286vw;
                }
            }
            & + .back-thumb-card{
                margin-top: 2.1429vw;
            }
        }
    }


    //요금제 라디오 카드 리스트
    .payment-radio-card-wrap{
        margin-top:4.2857vw;
        .title{
            margin-bottom:2.1429vw;
        }
        & + .payment-radio-card-wrap{
            margin-top: 5.7143vw;
        }
        .payment-radio-card{
            & + .payment-radio-card {margin-top: 2.1429vw;}
            .chip-wrap{
                margin-bottom:1.4286vw;
            }
            input[type="radio"] {
                @include hidden;
                &:checked + label{
                    border-color: $purple;
                }
            }
            .payment-conts-wrap{
                display: block; padding:2.8571vw;border:0.1429vw solid $gray02; border-radius:1.4286vw; transition: border-color 0.3s;
                .chip-warp{
                    margin-bottom:1.4286vw;
                }
                .payment-title{
                    border-bottom:0; padding-bottom:0;
                }
            }
            .payment-conts{
                margin-top: 2.1429vw;
                padding: 2.1429vw;border-radius: 1.4286vw;background: $gray01;
            }
        }
    }
    .card-line-gray{
        border:0.1429vw solid $gray02; text-align: center; padding:2.8571vw;  border-radius: 1.4286vw;
        p{
            margin-top: 0.7143vw;
        }
        .btn-set{margin-top: 2.8571vw;}
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .list{
        border-width: 1px; margin-top: 40px; margin-bottom: 10px;
        div{
            a{
                padding: 20px 0; border-width: 1px;
                .date{margin-top: 5px;}
            }
        }
        li{
            a{
                padding: 20px 0; border-width: 1px;
            }
        }
    }
    .list-view{
        margin-bottom: 40px;
        dt{
            padding-bottom: 20px;
            .date{margin-top: 10px;}
        }
        dd{
            border-width: 1px; padding: 30px 0; font-weight: 400;
        }
        .view-img{margin: 20px 0;}
    }
    .pay-amount{padding-top: 10px;}
    .cardList-wrap{
        & .cardList-wrap{margin-top: 20px;}
        & + .cardList-wrap{margin-top: 20px;}
        .list-header{margin-bottom: 15px;}
        .cardList{
            border-width: 1px; border-radius: 8px; padding: 20px;
            dl{
                dt{
                    width: 120px; padding-bottom: 10px;
                }
                dd{padding-bottom: 8px; width: calc(100% - 120px);}
            }
            &.icon-left-card{
                dl{
                    gap: 5px; padding-bottom: 20px;
                    dt{width: 60px;}
                    & + dl{border-top-width: 1px;}
                }
            }
            &.one-title{padding: 15px;}
            &.icon-left-card{
                dl{
                    & + dl{padding-top: 20px;}
                }
            }
            &.money-right{
                dl{
                    & + dl{border-width: 1px; margin-top: 20px; padding-top: 20px;}
                }
            }
            &.no-list{padding: 40px 20px;}
            &.notice-card{
                strong{margin-top: 10px;}
            }
        }
        .cardList-title{margin-bottom: 10px;}
        .cardList-img{height: 400px; border-radius: 8px;}
        & > di{
            & + div{margin-top: 15px;}
        }
        .cardList-desc{
            padding-right: 50px; margin-top: 15px;
            .date{right: 0; padding: 0 6px;}
            .period{margin-top: 5px;}
        }
        .underLine{
            dl{
                dt{padding: 12px 0;}
            }
        }
    }
    .using-goods{
        .item-top{padding-bottom: 20px;}
        .item-bottom{padding-top: 20px; border-top-width: 1px;}
        & + .using-goods{margin-top: 15px;}
    }
    .notification{
        & > li{
            padding-left: 7px;
            &::before{width: 3px; height: 3px; top: 7px;}
            & + li{margin-top: 5px;}
            &.txt-rd{
                &::before{width: 3px; height: 3px; top: 7px;}
            }
        }
    }
    .no-search-result{
        background-size: 140px 140px; padding-top: 150px;
        .no-result-txt{
            & + .small-txt{margin-top: 5px;}
        }
    }
    .place-search-result{
        margin-top: 40px;
        .info{padding-bottom: 15px;}
        .result-list{
            border-width: 1px; padding-top: 20px; 
            &.cms{
                border-width: 1px;
                .place-address{padding: 20px 0;
                    &.loc{
                        .btn-loc{top: 20px;}
                    }
                }
            }
            .place-address{padding-bottom: 30px;}
        }
    }
    .place-address{
        dd{margin-top: 5px;}
        &.loc{
            dl{padding-right: 50px;}
        }
        .box-btn{margin-top: 15px;}
    }
    .cms{
        .place-address{
            .stock{margin-top: 10px;}
            .box-btn{margin-top: 20px;}
        }
    }
    .place-address{
        &.loc{
            .btn-loc{width: 40px; height: 40px; background-size: 40px 40px;}
        }
    }
    .using-market{
        .item-top{
            .btn-box{
                .market{
                    .logo{
                        img{height: 15px;}
                    }
                    .info{margin-top: 10px;}
                }
            }
            .point{margin-top: 5px; color: #662d91;}
        }
        .item-bottom{padding-top: 20px;
            .discount{color: #662d91;}
        }
    }
    .num-process-wrap{
        border-width: 1px; border-radius: 8px; padding: 20px;
        & > li{padding-left: 25px; padding-bottom: 25px; margin-left: 10px; gap: 15px;
            .number{width: 23px; height: 23px; left: -11px; line-height: 23px; color: #fff;}
            &:last-child{
                &::after{width: 20px; height: 20px; background-size: 20px 20px; margin: -22px 0 0 -10px;}
                &::before{left: -1px; top: -10px; height: 10px;}
            }
            &::before{width: 1px;}
            .txt-wrap{
                margin-top: 5px;
            }
        }
    }
    .payment-imgCard-btn{
        &.payment-list{
            .cardList{
                label{border-width: 1px; border-radius: 8px;}
                .payment-desc{
                    dt{margin-top: 10px;}
                    dd{margin-top: 15px;}
                }
                & + .cardList{margin-top: 15px;}
            }
        }
        .cardList{
            .payment-desc{
                dt{margin-top: 10px;}
            }
        }
    }
    .product-imgCard{
        .item-left{
            max-width: 100px;
            .item-img{width: 100px; height: 100px;}
        }
        .item-right{
            width: calc(100% - 120px);
            .bang-notice{margin-top: 10px;}
            .item-price{margin-top: 15px;
                & > strong{color: #662d91;}
            }
            .item-txt-wrap{margin-bottom: 5px;}
        }
        .cardList{gap: 10px;}
        & + .product-imgCard{margin-top: 15px;}
    }
    .item-top{
        .item-txt{width: calc(100% - 13.1429vw);}
    }
    .payment-imgCard-btn{
        .cardList{
            .payment-desc{
                dd{
                    margin-top: 10px;
                    .notification{margin-top: 15px;}
                }
                dt{
                    .pay-amount{color: #662d91;}
                }
            }
        }
    }
    .payment-accordian-card-wrap{
        .payment-accordian-card{
            margin-top: 10px; border-width: 1px; border-radius: 8px; padding: 0 20px;
            .chip-wrap{margin-bottom: 10px;}
            .accordian{
                padding: 30px 0; border-width: 1px;
                .acc-conts{
                    margin-top: 15px; padding: 15px;
                    .btns{margin-top: 20px;}
                }
            }
        }
    }
    .payment-list-card-wrap{
        .payment-list-card{
            border-width: 1px; border-radius: 8px; padding: 30px 20px;
            & + .payment-list-card{margin-top: 15px;}
        }
    }
    .qos-popup{width: 15px; height: 15px; margin-left: 5px; margin-top: 0;}
    .back-thumb-list{
        .back-thumb-card{
            & + .back-thumb-card{margin-top: 15px;}
            height: 330px; border-radius: 8px;
            & > a{padding: 30px 20px;
                .back-conts{
                    .back-header{
                        p{margin: 5px 0 10px; font-weight: 600;}
                        .pay-amount{padding-top: 20px;}
                    }
                }
            }
        }
    }
    .life-list{
        margin-top: 15px;
        figcaption{
            margin-top: 15px;
            .life-list-text{margin-top: 10px;
                & + .desc{
                    dd{color: #662d91;}
                }
            }
            & + .life-list-text{margin-top: 5px;}
        }
        & + .life-list{margin-top: 30px;}
        & + button{margin-top: 10px;}
    }
    .item-top{padding-bottom: 20px;}
    .payment-detail-card-wrap{padding: 20px; border-width: 1px; border-radius: 8px;}
    .thumb-list{
        & > li{
            .tit{margin: 20px 0 0;}
            .mobie{
                img{height: 100%;}
            }
            .date{margin: 5px 0 0;}
            & + li{margin-top: 40px;}
        }
    }
    .no-result{padding-top: 150px; background-size: 135px 135px;}
    .card-line-gray{
        border-radius: 8px; padding: 20px; border-width: 1px;
        p{margin-top: 5px;}
        .btn-set{margin-top: 20px;}
    }
    .reward-card-wrap{
        border-width: 1px; border-radius: 8px;
        dl{
            dt{
                padding: 15px 20px;
                .txt{margin-top: 5px;}
            }
            dd{
                padding: 15px 20px;
                .txt{margin-top: 5px;}
            }
        }
    }
    .list-wrap{
        .list-opt{margin-top: 40px; margin-bottom: 10px;}
    }
    .card-check{padding-bottom: 15px;}
    .payment-radio-card-wrap{
        .title{margin-bottom: 15px;}
        .payment-radio-card{
            .payment-conts-wrap{padding: 20px; border-width: 1px; border-radius: 8px;}
            .payment-conts{margin-top: 20px; padding: 20px; border-radius: 8px;}
            & + .payment-radio-card{margin-top: 20px;}
        }
        & + .payment-radio-card-wrap{margin-top: 40px;}
    }
    .rate-plan-no-change{
        .cardList-wrap{
            margin-bottom: 40px;
        }
    }
    .point-sort-card{
        .cardList-wrap{
            & + .box-btn{margin-top: 10px;}
        }
        .cardList{
            .middle{margin-top: 15px;}
            .bottom{
                margin-top: 20px; padding-top: 20px; border-width: 1px;
                .notification{margin-top: 15px;}
            }
        }
    }
}