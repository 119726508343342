/* 개인정보취급방침 */
.footer{
  .terms-privacy{
      .cardList-wrap{
          .agree-cont{
              margin-top: 11.1111vw;
          }
      }
      .terms-cont{margin-top: 6.9444vw;}
        .person_rule{
          width: 100%;
            & + .person_rule{margin-top: 6.9444vw;}
            .title{
                @include mainTxt; margin-bottom: 1.3889vw;
            }
            .indent_list{
                & + .title{margin-top: 4.1667vw;}
                li{
                    @include smallTxt;
                    & + li{margin-top: 1.3889vw;}
                }
            }
            .section_desc{
              @include smallTxt;
            }

            .table_privacy {
                margin-top:2.7778vw;
                caption{display: none;}
                thead{
                    th{
                        text-align: center;
                        @include defaultTxt;vertical-align: middle;
                    }
                }
                tbody{
                    th,td{
                        padding: 2.7778vw 1.3889vw; border-bottom: 0.1389vw solid $gray02; vertical-align: middle;text-align: center; background: $white;
                        @include smallTxt;
                    }
                }
            }
            #con6{
                & ~ .table_privacy_box{
                    .table_privacy {
                        tbody{
                            th{
                                text-align: center;
                                @include normalTxt;vertical-align: middle;
                            }
                        }
                    }
                }
            }
            .indent {margin-left:1.3889vw;}
        }
  }
  .terms-service{
    .agree-cont{margin-top: 8.3333vw;}
  }
  .terms-info-history{
    .mt0{
      margin-top: 0;
    }
  }
//기존 as-is css 가져온 내용들
    .pat10 {
      padding-top: 1.3889vw !important;
    }
    .privacy {
      position: relative; font-size: 1.6667vw; color: #666;line-height: 2.2222vw;
      h4 {
        font-size: 2.0833vw !important; padding: 1.3889vw 0 0.6944vw !important;
      }
      h5 {
        font-size: 1.9444vw !important;  padding: 0.6944vw 0 !important;
      }
      p {
        margin: 0.6944vw 0; @include smallTxt;
        &.img{
          img{
            width:100%;
          }
        }
      }
      ol {
        margin: 0.6944vw 0 0.6944vw;
      }
      ul {
        margin: 0.6944vw 0 0.6944vw;
      }
      ol li {
        span.first_letter {
          margin: 0 0.5556vw 0 -2.2222vw;
        }

        ol li span.first_letter {
          margin: 0 0.5556vw 0 -2.5vw;
        }
      }
      ul li span {
        &.first_letter {
          margin: 0 0.5556vw 0 -1.3889vw;
        }
        &.first_letter01 {
          margin: 0 0.5556vw 0 -2.2222vw;
        }
      }
      ol li span.first_letter01 {
        margin: 0 0.5556vw 0 -2.2222vw;
      }

      ul li span.first_letter02, ol li span.first_letter02 {
        margin: 0 0.5556vw 0 -2.6389vw;
      }

      div {
        &.letter {
          margin: 0.6944vw 0 1.3889vw 0;
        }

        &.letter01 {
          margin: 0.6944vw 0 1.3889vw 0.5556vw;
        }

        &.letter02 {
          margin: 0.6944vw 0 1.3889vw 1.25vw;
        }
      }
    }

    // .table_privacy {
    //   width: 100%;
    //   margin: 1.3889vw 0;
    //   border-top: 0.1389vw solid #333;

    //   th, td {
    //     /*text-align:left;*/

    //     padding: 0.6944vw !important;
    //     border-left: 0.1389vw solid #ddd;
    //     border-bottom: 0.1389vw solid #ddd;
    //   }

    //   th:first-child, td:first-child {
    //     border-left: none;
    //   }

    //   thead tr:first-child th, tbody tr:first-child th {
    //     padding: 1.25vw 1.1111vw 1.3889vw !important;
    //   }

    //   border-top: 0.2778vw solid #222;

    //   &.type01 {
    //     th {
    //       text-align: center;
    //       padding: 0.6944vw 0 !important;
    //     }

    //     td {
    //       padding: 0.6944vw 0 !important;
    //     }
    //   }
    // }

    .quickPolicy ol li {
      float: left;
      line-height: 3.4722vw;
      width: 50%;

      a {
        color: #dd0682;
      }
    }
    .person_rule {
      margin-top: 8.3333vw;
      .indent_list{
          li{
            @include smallTxt;
            & + li{
              margin-top: 1.3889vw;
            }
          }
      }
      .title {
        @include mainTxt; margin-bottom: 2.7778vw;
      }
      .kor_ord {
        margin-top: 1.3889vw; @include smallTxt;
      }
    }
    .table-list{
      margin-top: 4.1667vw;
      caption{
        display: none;
      }
      thead{
        th{
          text-align: center;
        }
      }
      tbody{
        th{
          background-color: #fff !important; border-bottom: 0.2778vw solid $gray02; @include smallTxt; text-align: center;
        }
      }
    }
    .table-list-wrap{
      & + .kor_ord{
        margin-top: 1.3889vw;
      }
    }

    .pagenavi {
      text-align: center;
      display: none;

      &:after {
        clear: both;
      }

      margin: 2.7778vw 0 0;
      position: relative;
      text-align: center;
    }

    .roundtype04 {
      background-color: #f5f5f5;
      border-radius: 0.6944vw;
      position: relative;
      border-radius: 2.7778vw;
      margin-top: 2.7778vw;
    }

    .person_admin_wrap {
      overflow: hidden;
      padding: 5.5556vw;
      width: 100%;

      .first_cont {
        margin: 0 0 1.3889vw 0;

      }
    }

    .person_admin {
      width: 100%;
      text-indent: 0;
      margin-top: 8.3333vw;
      & > span{
          @include defaultTxt;
      }
    }

    .person_admin_wrap .person_admin_info {
      margin-top:1.3889vw;
    }

    .person_admin_info li {
      @include smallTxt;
    }

    /* (기존약관 클래스명) tbl_list -가로형 목록 */

    // .table_st01 {
    //   border-top: 0.2778vw solid #222;
    //   border-bottom: 0.1389vw solid #666;

    //   thead {
    //     th {
    //       border-top: 0.1389vw solid #ccc;
    //       color: #666;
    //     }

    //     tr:first-child th {
    //       border-top: none;
    //     }
    //   }

    //   tbody {
    //     tr:first-child td {
    //       border-top: 0.1389vw solid #000;
    //     }

    //     td {
    //       position: relative;
    //       color: #666;
    //     }

    //     th, td {
    //       border-top: 0.1389vw solid #ccc;
    //       text-align: center;
    //     }

    //     tr:first-child th {
    //       border-top: 0.1389vw solid #000;
    //     }
    //   }

    //   th {
    //     padding: 1.25vw 1.1111vw 1.3889vw;
    //     font-size: 1.8056vw;
    //     line-height: 2.5vw;
    //     vertical-align: middle;
    //     border-left: 0.1389vw solid #ccc;
    //     background-color: #fcfcfc;
    //   }

    //   td {
    //     padding: 0.6944vw 1.1111vw 0.6944vw;
    //     height: 4.1667vw;
    //     box-sizing: border-box;
    //     font-size: 1.6667vw;
    //     line-height: 2.5vw;
    //     vertical-align: middle;
    //     border-left: 0.1389vw solid #ccc;
    //   }
    //   tbody tr {
    //     th:first-child, td:first-child {
    //       border-left: 0;
    //     }
    //   }
    // }
    /* (기존약관 클래스명) tbl_detail - 세로형 목록 & 폼 */
    // .table_st02 {
    //   border-top: 0.2778vw solid #222; border-bottom: 0.1389vw solid #666;
    //   th {
    //     padding: 1.25vw 2.2222vw 1.3889vw;
    //     font-size: 1.8056vw;
    //     background-color: #fcfcfc;
    //   }

    //   td {
    //     padding: 0.6944vw 2.2222vw 0.6944vw;
    //     height: 4.1667vw;
    //     box-sizing: border-box;
    //     font-size: 1.8056vw;
    //   }

    //   tbody {
    //     th {
    //       position: relative;
    //       border-top: 0.1389vw solid #ccc;
    //       color: #666;
    //       text-align: left;
    //     }

    //     td {
    //       border-top: 0.1389vw solid #ccc;
    //       color: #666;
    //       text-align: left;
    //     }

    //     tr:first-child {
    //       th, td {
    //         border-top: none;
    //       }
    //     }
    //   }

    //   .vt_align {
    //     position: absolute;
    //     top: 1.1111vw;
    //     display: block;
    //   }
    // }

    // .table_privacy_box {
    //   height: auto;
    //   overflow: auto;

    //   /*border:0.1389vw solid #ddd;*/

    //   table {
    //     /*border:0;*/

    //     margin: 0;

    //     td {
    //       border-top: 0.1389vw solid #ddd;
    //     }

    //     th span.accent {
    //       color: #555;
    //     }
    //   }
    // }

    /* 이용약관 테이블 */

    // .policy_tbl_wrap {
    //   width: 100%;
    //   position: relative;
    //   display: inline-block;

    //   /*border:0.1389vw solid #dddddd;*/

    //   margin-top: 2.0833vw;

    //   table {
    //     width: 100%;

    //     tr {
    //       th {
    //         background-color: #fcfcfc;
    //         padding: 0.9722vw 0vw;
    //         border-bottom: 0.1389vw solid #dddddd;

    //         &:first-child {
    //           border-right: 0.1389vw solid #dddddd;
    //         }
    //       }

    //       td:first-child {
    //         border-right: 0.1389vw solid #dddddd;
    //       }
    //     }

    //     div ul li {
    //       border-bottom: 0.1389vw solid #dddddd;
    //       padding: 0.6944vw 0 !important;

    //       &:first-child {
    //         height: 15.4167vw !important;
    //       }

    //       &:last-child {
    //         border: none;
    //       }
    //     }
    //   }

    //   &.bord_none {
    //     border: none;
    //   }
    // }

    /*멤버십이용약관 테이블*/

    .member_tbl_wrap {
      padding-top: 0.6944vw;
    }

    /* 이용약관 */

    .terms_cont {
      .stitle01 {
        margin-top: 8.3333vw; margin-bottom: 2.7778vw;
        @include subContsTitle;
        & + .txt03{
          margin-bottom: 2.7778vw;
        }
      }

      .stitle02 {
        @include subContsTitle;
        margin-top: 11.1111vw;
      }

      .stitle05 {
        margin:8.3333vw 0 2.7778vw;
        @include mainTxt;
        & + .txt03{
          margin-bottom: 2.7778vw;
        }
      }

      .txt03 {
        margin-bottom: 8.3333vw;
        @include smallTxt;
        + {
          .txt03, ol {
            margin-top: 1.6667vw;
          }
        }
      }

      ol,ul {
        margin: 0.9722vw 0 1.3889vw;
        @include smallTxt;
      }

      ol{
        li{
          @include smallTxt;
          & + li{
            margin-top: 1.3889vw;
          }
        }

      }

      ul {
        li {
          line-height:1.2;
        }

        padding-left: 1.6667vw;
      }
    }

    /*.terms_cont ol li span.first_letter {margin:0 0.5556vw 0 -2.2222vw}*/

    .terms_btm {
      padding-top: 1.8056vw;
      color: #666;
      line-height: 2.2222vw;
      font-size: 1.5278vw;

      p {
        margin-top: 0.5556vw;
      }

      div {
        margin-top: 1.6667vw;

        .btn {
          margin-left: 1.1111vw;
        }
      }
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
/* 개인정보취급방침 */
.footer{
  .terms-privacy{
      .cardList-wrap{
          .agree-cont{
              margin-top: 5.7143vw;
          }
      }
      .terms-cont{margin-top: 3.5714vw;}
        .person_rule{
          width: 100%;
            & + .person_rule{margin-top: 3.5714vw;}
            .title{
                margin-bottom: 0.7143vw;
            }
            .indent_list{
                & + .title{margin-top: 2.1429vw;}
                li{

                    & + li{margin-top: 0.7143vw;}
                }
            }
            .table_privacy {
                margin-top:1.4286vw;
                caption{display: none;}
                thead{
                    th{
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                tbody{
                    th,td{
                        padding: 1.4286vw 0.7143vw; border-bottom: 0.0714vw solid $gray02; vertical-align: middle;text-align: center; background: $white;
                    }
                }
            }
            #con6{
                & ~ .table_privacy_box{
                    .table_privacy {
                        tbody{
                            th{
                                text-align: center;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
  }
  .terms-service{
    .agree-cont{margin-top: 4.2857vw;}
  }
  .terms-info-history{
    .mt0{
      margin-top: 0;
    }
  }
//기존 as-is css 가져온 내용들
    .pat10 {
      padding-top: 0.7143vw !important;
    }
    .privacy {
      position: relative; font-size: 0.8571vw; color: #666;line-height: 1.1429vw;
      h4 {
        font-size: 1.0714vw !important; padding: 0.7143vw 0 0.3571vw !important;
      }
      h5 {
        font-size: 1.0000vw !important;  padding: 0.3571vw 0 !important;
      }
      p {
        margin: 0.3571vw 0;
        &.img{
          img{
            width:100%;
          }
        }
      }
      ol {
        margin: 0.3571vw 0 0.3571vw;
      }
      ul {
        margin: 0.3571vw 0 0.3571vw;
      }
      ol li {
        span.first_letter {
          margin: 0 0.2857vw 0 -1.1429vw;
        }

        ol li span.first_letter {
          margin: 0 0.2857vw 0 -1.2857vw;
        }
      }
      ul li span {
        &.first_letter {
          margin: 0 0.2857vw 0 -0.7143vw;
        }
        &.first_letter01 {
          margin: 0 0.2857vw 0 -1.1429vw;
        }
      }
      ol li span.first_letter01 {
        margin: 0 0.2857vw 0 -1.1429vw;
      }

      ul li span.first_letter02, ol li span.first_letter02 {
        margin: 0 0.2857vw 0 -1.3571vw;
      }

      div {
        &.letter {
          margin: 0.3571vw 0 0.7143vw 0;
        }

        &.letter01 {
          margin: 0.3571vw 0 0.7143vw 0.2857vw;
        }

        &.letter02 {
          margin: 0.3571vw 0 0.7143vw 0.6429vw;
        }
      }
    }
    .quickPolicy ol li {
      float: left;
      line-height: 1.7857vw;
      width: 50%;

      a {
        color: #dd0682;
      }
    }
    .person_rule {
      margin-top: 4.2857vw;
      .indent_list{
          li{
            & + li{
              margin-top: 0.7143vw;
            }
          }
      }
      .title {
        margin-bottom: 1.4286vw;
      }
      .kor_ord {
        margin-top: 0.7143vw;
      }
    }
    .table-list{
      margin-top: 2.1429vw;
      caption{
        display: none;
      }
      thead{
        th{
          text-align: center;
        }
      }
      tbody{
        th{
          background-color: #fff !important; border-bottom: 0.1429vw solid $gray02;text-align: center;
        }
      }
    }
    .table-list-wrap{
      & + .kor_ord{
        margin-top: 0.7143vw;
      }
    }

    .pagenavi {
      text-align: center;
      display: none;

      &:after {
        clear: both;
      }

      margin: 1.4286vw 0 0;
      position: relative;
      text-align: center;
    }

    .roundtype04 {
      background-color: #f5f5f5;
      border-radius: 0.3571vw;
      position: relative;
      border-radius: 1.4286vw;
      margin-top: 1.4286vw;
    }

    .person_admin_wrap {
      overflow: hidden;
      padding: 2.8571vw;
      width: 100%;

      .first_cont {
        margin: 0 0 0.7143vw 0;

      }
    }

    .person_admin {
      width: 100%;
      text-indent: 0;
      margin-top: 4.2857vw;
    }

    .person_admin_wrap .person_admin_info {
      margin-top:0.7143vw;
    }
    .member_tbl_wrap {
      padding-top: 0.3571vw;
    }

    /* 이용약관 */

    .terms_cont {
      .stitle01 {
        margin-top: 4.2857vw; margin-bottom: 1.4286vw;
        & + .txt03{
          margin-bottom: 1.4286vw;
        }
      }

      .stitle02 {
        margin-top: 5.7143vw;
      }

      .stitle05 {
        margin:4.2857vw 0 1.4286vw;
        & + .txt03{
          margin-bottom: 1.4286vw;
        }
      }

      .txt03 {
        margin-bottom: 4.2857vw;
        + {
          .txt03, ol {
            margin-top: 0.8571vw;
          }
        }
      }

      ol,ul {
        margin: 0.5000vw 0 0.7143vw;
      }

      ol{
        li{
          & + li{
            margin-top: 0.7143vw;
          }
        }

      }

      ul {
        li {
          line-height:1.2;
        }

        padding-left: 0.8571vw;
      }     
    }

    /*.terms_cont ol li span.first_letter {margin:0 0.2857vw 0 -1.1429vw}*/

    .terms_btm {
      padding-top: 0.9286vw;
      color: #666;
      line-height: 1.1429vw;
      font-size: 0.7857vw;

      p {
        margin-top: 0.2857vw;
      }

      div {
        margin-top: 0.8571vw;

        .btn {
          margin-left: 0.5714vw;
        }
      }
    }
}


}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
  .footer{
    .terms_cont{
      .stitle02{margin-top: 40px;}
      .stitle05{
        margin: 30px 0 10px;
        & + .txt03{margin-bottom: 10px;}
      }
      ol{
        li{
          & + li{margin-top: 5px;}
        }
      }
    }
    .table-list{margin-top: 15px;}
    .person_rule{margin-top: 30px;}
    .terms-privacy{
      .terms-cont{margin-top: 30px;}
      .person_rule{
        .title{margin-bottom: 5px;}
        & + .person_rule{margin-top: 25px;}
        .indent_list{
          li{
            & + li{margin-top: 5px;}
          }
          & + .title{margin-top: 15px;}
        }
        .table_privacy{margin-top: 10px;}
        .indent {margin-left:25px;}
      }
    }
    .privacy{
      ul{margin: 2px 0 2px;}
    }
    .roundtype04{border-radius: 8px; margin-top: 10px;}
    .person_admin_wrap{padding: 20px;}
    .person_admin{margin-top: 30px;}
  }
}