.table-defult{
    border-top:0.2778vw solid $gray02;
    width:100%;
    margin:0 auto;
    box-sizing: border-box;
    .col1{
        width: 19.4444vw;
    }
    .col3{
        width: 29.8611vw;
    }
    tr.ranking{
        background-color: #f9f5ff;
        td{
            &:first-child{
              background-repeat: no-repeat; background-position:center;background-size: 11.1111vw 11.1111vw; font-size:0;
            }
            &:nth-child(2){
                *{@include smallTxt; font-weight: 600;}
                p{margin-bottom: 0;}
            }
            &:last-child{
                p{
                    @include subContsTitle; color: $purple;
                }
            }
        }
        &:nth-child(1){
            td{
                &:first-child{
                    background-image: url('/assets/images/icon/ico-ranking-gold.png');
                }
            }
        }
        &:nth-child(2){
            td{
                &:first-child{
                    background-image: url('/assets/images/icon/ico-ranking-silver.png');
                }
            }
        }
        &:nth-child(3){
            td{
                &:first-child{
                    background-image: url('/assets/images/icon/ico-ranking-bronze.png');
                }
            }
        }
    }
    td{
        padding:5.5556vw 0;
        &:first-child{
            padding: 0 4.1667vw 0 5.5556vw; text-align: center;@include defaultTxt;
        }
        &:nth-child(2){
            p{margin-bottom: 1.3889vw;}
        }
        &:last-child{
            text-align: right; padding-right:5.5556vw;
            p{margin-bottom:1.3889vw;@include defaultTxt;}
        }
        border-bottom:0.2778vw solid $gray02;
        vertical-align: middle;
    }
}

p.bang-notice{
    @include smallTxt;
    margin-top:4.1667vw;
}

ul.bang-notice{
    margin-top:4.1667vw;
    li{
        @include smallTxt;
         & + li{
            margin-top: 1.3889vw;
         }
    }
}

.table-list-wrap{
    &.table-scroll {
        overflow-x: auto;
        .table-list {
            min-width:208.3333vw;
        }
    }
    .table-list-title{
        @include defaultTxt;
        margin-bottom:2.0833vw;
    }
    .table-list{
        width:100%;
        margin:0 auto;
        box-sizing: border-box;
        text-align: left;
        th{
            padding:2.7778vw 4.1111vw;
            background: $gray06;
            @include defaultTxt;
            vertical-align: middle;
        }
        td{
            padding:2.7778vw 4.1667vw;
            border-bottom:0.2778vw solid #ddd;
            @include smallTxt;
            vertical-align: middle;
        }

    }
    .center{
        text-align: center;
        th{
            border-right:none;
        }
        td.in_use{color:$black; font-weight: 600;}
    }
    & + .table-list-wrap{
        margin-top:5.5556vw;
    }
}
.c-black{color:$black; font-weight: 600;}
.c-gray{color:$gray05;}
.c-purple{color:$purple; font-weight: 600;}


// table 새로운버전
.table-title{
    @include subContsTitle;
    //margin-bottom: 2.7778vw;
}
.table-default2{
    width: 100%;border-top: 2px solid $gray05;
    *{
        @include mainEmphasize;
    }

    tr{
        display: block; padding: 5.5556vw;
    }
    th{
        display: block;width: 100%;text-align: left;
    }
    td{
        display: block;width: 100%;
    }
    dl{
        display: flex; align-items: center; flex-wrap: wrap; width: 100%;
        dt{
            width: 50%;
            &:nth-of-type(n+2){margin-top: 1.3889vw;}
        }
        dd{
            width: 50%; text-align: right;
            &:nth-of-type(n+2){margin-top: 1.3889vw;}
        }
    }
    .item-title{
        * {
            @include subContsTitle;font-weight: 700; color: $purple;
        }
        &:not(:first-of-type){border-top: 2px solid #ddd;}
    }
    .bg-gray {background-color: $gray01;}
    .depth-02{
        padding: 0 5.5556vw;
        td{
            border-top: 2px dashed #ddd;padding: 5.5556vw 0;
        }
        *{@include normalTxt;}
    }
    tfoot{
        border-top: 2px solid #ddd;
    }
    .table-result{
        border-top: 2px solid $black;border-bottom: 2px solid $black;
        *{@include mainTxt;}
    }
}
.c-hotpink{
    * {@include subContsTitle; font-weight: 700; color: $hotpink;}
}

// Table 정의
.box-table{
    .table-title{
        @include subContsTitle; padding: 0 0 2.7778vw 0;
    }
    // Table Type01(Basic)
    .table-type-01{
        width:100%;border-bottom: 0.2778vw solid $gray02; border-top: 0.2778vw solid $gray02;
        tr{
            &.table-result{
                background-color: #eff1f5;
                *{padding: 5.5556vw 2.7778vw; @include mainTxt;}
                .txt-vt{@include contsTitle;}
            }
            th{
                @include defaultTxt; text-align: left; padding: 2.7778vw;
            }
            td{
                @include defaultTxt; text-align: right; padding: 2.7778vw;
                *{@include fontInherit;}
            }
            // 두번째 뎁스
            &.is-depth{
                *:not(.txt-tooltip > span) {@include smallTxt; padding-top:0;}
            }
            // 세번째 뎁스
            &.depth{
                * {
                    @include smallTxt; padding-top:0.8333vw;
                }
                th{
                    padding-left: 8.3333vw;
                    @include backgroundImg('/assets/images/icon/ico-table-depth.svg',5.5556vw 1.6667vw,1.6667vw 1.6667vw);
                }
            }
            &.emp-txt{
                * {color: $red;}
            }
            &:first-child{
                th{padding-top: 5.5556vw;}
                td{padding-top: 5.5556vw;}
            }
            &:last-child{
                th{padding-bottom: 5.5556vw;}
                td{padding-bottom: 5.5556vw;}
            }
        }
        tfoot{
            tr{
                background-color: #eff1f5;
                *{padding: 5.5556vw 2.7778vw;}
                .txt-vt{@include subContsTitle;}
            }
        }
        & + .table-type-01{
            margin-top: 5.5556vw;
        }
        .minus {
            color: $purple !important;
        }
    }
    // Table Type03
    .table-type-03{
        max-width: 90%; width: 100%; margin: 0 auto;
        tr{
            border-bottom: 0.2778vw solid $gray02;
            th{
                @include smallTxt; text-align: left; padding: 5.5556vw 4.1667vw;
            }
            td{
                @include smallTxt; text-align: right; padding: 5.5556vw 4.1667vw; vertical-align: middle;
            }
            &.bg-gray{
                border: none;
                td,th{padding: 3.0556vw 4.1667vw; @include defaultTxt;background-color: $gray01;}
                th{border-radius: 1.9444vw 0 0 1.9444vw;}
                td{border-radius: 0 1.9444vw 1.9444vw 0;}
            }
        }
        .ball {
            display: inline-block; width: 6.6667vw; height: 6.6667vw; line-height: 6.6667vw; border-radius: 50%; color:$white; text-align: center;
            &-purple{
                display: inline-block; width: 6.6667vw; height: 6.6667vw; line-height: 6.6667vw; border-radius: 50%; color:$white; text-align: center; background-color: $purple;
            }
            &-gray{
                display: inline-block; width: 6.6667vw; height: 6.6667vw; line-height: 6.6667vw; border-radius: 50%; color:$white; text-align: center; background-color: $gray04; position: relative;margin-left: 6.9444vw;
                &::before{
                    content: ''; display: inline-block; position: absolute; left: -6.9444vw; top: 50%; width: 5.5556vw; height: 5.5556vw; @include backgroundImg('/assets/images/icon/ico-arrow-gray.svg',center,contain); transform: translateY(-50%);
                }
            }
        }
    }

    // Table Type04
    .table-title.no-border{margin-bottom: 4.1667vw; border: none; padding: 0;}
    .table-type-04{
        width: 100%;
        th {
            background-color: $gray06; padding: 5.5556vw 4.1667vw; text-align: center;
            @include defaultTxt;vertical-align: middle;
        }
        td{
            padding: 5.5556vw 4.1667vw; border-bottom: 0.2778vw solid $gray02; vertical-align: middle; text-align: center;
            &:first-of-type{@include defaultTxt; font-weight: 400;}
            &:nth-of-type(2){@include smallTxt;}
        }
        &.center{
            th, td{
                text-align: center !important;
            }
            td{ 
                padding: 5.5556vw 4.1667vw !important;
            }
        }
    }
    .table-type-05 {
        width: 100%;border-top: 0.2778vw solid $gray02;
        td {
            vertical-align: middle; padding: 5.5556vw 0; border-bottom: 0.2778vw solid $gray02;
            strong{
                display: block; @include defaultTxt; font-weight: 400;
                & + strong{margin-top:1.3889vw; }
                & + span{margin-top:1.3889vw; }
            }
            span{display: block;@include smallDepthTxt;}
            button{@include smallDepthTxt; text-decoration: underline;}
            &:last-of-type{text-align: right;}
        }
    }
}
.tbl_list{
    width: 100%; max-width:100%;
    th {
        background-color: $gray06; padding: 5.5556vw 4.1667vw; text-align: center;
        @include defaultTxt;vertical-align: middle;
    }
    td{
        padding: 5.5556vw 4.1667vw; border-bottom: 0.2778vw solid $gray02; vertical-align: middle; text-align: center;
        &:first-of-type{@include defaultTxt; font-weight: 400;}
        &:nth-of-type(2){@include smallTxt;}
    }
}
// Table Type02
.radio-block{
    .default-txt{
        padding-left: 5.5556vw; margin-bottom: 4.1667vw;
        .txt-vt{@include fontInherit;}
    }
    ul{
        border-top: 0.2778vw solid $gray02;
        // li{height: 27.7778vw;}
        .btn-radio-type2{
            height: 100%;
            label{
                /*align-items: flex-start;*/align-items:center;padding: 5.5556vw; transition: background-color 0.3s, color 0.3s;
            } /*08.26 라디오버튼 수직중앙정렬*/
            input:checked ~ label{
                background-color: #f9f5ff;
                * {color: $black;}
            }
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .table-defult{
        border-top:0.1429vw solid $gray02;
        width:100%;
        margin:0 auto;
        box-sizing: border-box;
        .col1{
            width: 10.0000vw;
        }
        .col3{
            width: 15.3571vw;
        }
        tr.ranking{
            background-color: #fff8ff;
            td{
                &:first-child{
                  background-repeat: no-repeat; background-position:center; background-size: 5.7143vw 5.7143vw;font-size:0;
                }
                &:nth-child(2){
                    *{font-weight: 600;}
                    p{margin-bottom: 0;}
                }
                &:last-child{
                    p{
                        color: $purple;
                    }
                }
            }
            &:nth-child(1){
                td{
                    &:first-child{
                        background-image: url('/assets/images/icon/ico-ranking-gold.png');
                    }
                }
            }
            &:nth-child(2){
                td{
                    &:first-child{
                        background-image: url('/assets/images/icon/ico-ranking-silver.png');
                    }
                }
            }
            &:nth-child(3){
                td{
                    &:first-child{
                        background-image: url('/assets/images/icon/ico-ranking-bronze.png');
                    }
                }
            }
        }
        td{
            padding:2.8571vw 0;
            &:first-child{
                padding: 0 2.1429vw 0 2.8571vw; text-align: center;
            }
            &:nth-child(2){
                p{margin-bottom: 0.7143vw;}
            }
            &:last-child{
                text-align: right; padding-right:2.8571vw;
                p{margin-bottom:0.7143vw;}
            }
            border-bottom:0.1429vw solid $gray02;
            vertical-align: middle;
        }
    }

    p.bang-notice{
        margin-top:2.1429vw;
    }

    ul.bang-notice{
        margin-top:2.1429vw;
        li{
             & + li{
                margin-top: 0.7143vw;
             }
        }
    }

    .table-list-wrap{
        &.table-scroll {
            overflow-x: auto;
            .table-list {
                min-width:107.1429vw;
            }
        }
        .table-list-title{
            margin-bottom:1.0714vw;
        }
        .table-list{
            width:100%;
            margin:0 auto;
            box-sizing: border-box;
            text-align: left;
            th{
                padding:1.4286vw 2.1429vw;
                background: $gray06;
                vertical-align: middle;
            }
            td{
                padding:1.4286vw 2.1429vw;
                border-bottom:0.1429vw solid #ddd;
                vertical-align: middle;
            }

        }
        .center{
            text-align: center;
            th{
                border-right:none;
            }
            td.in_use{color:$black; font-weight: 600;}
        }
        & + .table-list-wrap{
            margin-top:2.8571vw;
        }
    }
    .c-black{color:$black; font-weight: 600;}
    .c-gray{color:$gray05;}
    .c-purple{color:$purple; font-weight: 600;}


    // table 새로운버전
    .table-default2{
        width: 100%;border-top: 0.1429vw solid $gray05;

        tr{
            display: block; padding: 2.8571vw;
        }
        th{
            display: block;width: 100%;text-align: left;
        }
        td{
            display: block;width: 100%;
        }
        dl{
            display: flex; align-items: center; flex-wrap: wrap; width: 100%;
            dt{
                width: 50%;
                &:nth-of-type(n+2){margin-top: 0.7143vw;}
            }
            dd{
                width: 50%; text-align: right;
                &:nth-of-type(n+2){margin-top: 0.7143vw;}
            }
        }
        .item-title{
            * {
                font-weight: 700; color: $purple;
            }
            &:not(:first-of-type){border-top: 0.1429vw solid #ddd;}
        }
        .bg-gray {background-color: $gray01;}
        .depth-02{
            padding: 0 2.8571vw;
            td{
                border-top: 0.1429vw dashed #ddd;padding: 2.8571vw 0;
            }
        }
        tfoot{
            border-top: 0.1429vw solid #ddd;
        }
        .table-result{
            border-top: 0.1429vw solid $black;border-bottom: 0.1429vw solid $black;
        }
    }
    .c-hotpink{
        * {font-weight: 700; color: $hotpink;}
    }

    // Table 정의
    .box-table{
        .table-title{
            padding: 0 0 1.4286vw 0;
        }
        // Table Type01(Basic)
        .table-type-01{
            width:100%;border-bottom: 0.1429vw solid $gray02; border-top: 0.1429vw solid $gray02;
            tr{
                &.table-result{
                    background-color: #eff1f5;
                    *{padding: 2.8571vw 1.4286vw; }
                }
                th{
                    text-align: left; padding: 1.4286vw;
                }
                td{
                    text-align: right; padding: 1.4286vw;
                    *{@include fontInherit;}
                }
                // 두번째 뎁스
                &.is-depth{
                    *:not(.txt-tooltip > span) {padding-top:0;}
                }
                // 세번째 뎁스
                &.depth{
                    * {
                        padding-top:0.4286vw;
                    }
                    th{
                        padding-left: 4.2857vw;
                        @include backgroundImg('/assets/images/icon/ico-table-depth.svg',2.8571vw 0.8571vw,0.8571vw 0.8571vw);
                    }
                }
                &.emp-txt{
                    * {color: $red;}
                }
                &:first-child{
                    th{padding-top: 2.8571vw;}
                    td{padding-top: 2.8571vw;}
                }
                &:last-child{
                    th{padding-bottom: 2.8571vw;}
                    td{padding-bottom: 2.8571vw;}
                }
            }
            tfoot{
                tr{
                    background-color: #eff1f5;
                    *{padding: 2.8571vw 1.4286vw;}
                }
            }
            & + .table-type-01{
                margin-top: 2.8571vw;
            }
            .minus {
                color: $purple !important;
            }
        }
        // Table Type03
        .table-type-03{
            max-width: 90%; width: 100%; margin: 0 auto;
            tr{
                border-bottom: 0.1429vw solid $gray02;
                th{
                    text-align: left; padding: 2.8571vw 2.1429vw;
                }
                td{
                    text-align: right; padding: 2.8571vw 2.1429vw; vertical-align: middle;
                }
                &.bg-gray{
                    border: none;
                    td,th{padding: 1.5714vw 2.1429vw;background-color: $gray01;}
                    th{border-radius: 1.0000vw 0 0 1.0000vw;}
                    td{border-radius: 0 1.0000vw 1.0000vw 0;}
                }
            }
            .ball {
                display: inline-block; width: 3.4286vw; height: 3.4286vw; line-height: 3.4286vw; border-radius: 50%; color:$white; text-align: center;
                &-purple{
                    display: inline-block; width: 3.4286vw; height: 3.4286vw; line-height: 3.4286vw; border-radius: 50%; color:$white; text-align: center; background-color: $purple;
                }
                &-gray{
                    display: inline-block; width: 3.4286vw; height: 3.4286vw; line-height: 3.4286vw; border-radius: 50%; color:$white; text-align: center; background-color: $gray04; position: relative;margin-left: 3.5714vw;
                    &::before{
                        content: ''; display: inline-block; position: absolute; left: -3.5714vw; top: 50%; width: 2.8571vw; height: 2.8571vw; @include backgroundImg('/assets/images/icon/ico-arrow-gray.svg',center,contain); transform: translateY(-50%);
                    }
                }
            }
        }

        // Table Type04
        .table-title.no-border{margin-bottom: 2.1429vw; border: none; padding: 0;}
        .table-type-04{
            width: 100%;
            th {
                background-color: $gray06; padding: 2.8571vw 2.1429vw; text-align: center;
                vertical-align: middle;
            }
            td{
                padding: 2.8571vw 2.1429vw; border-bottom: 0.1429vw solid $gray02; vertical-align: middle; text-align: center;
                &:first-of-type{ font-weight: 400;}
            }
        }
        .table-type-05 {
            width: 100%;border-top: 0.1429vw solid $gray02;
            td {
                vertical-align: middle; padding: 2.8571vw 0; border-bottom: 0.1429vw solid $gray02;
                strong{
                    display: block; font-weight: 400;
                    & + strong{margin-top:0.7143vw; }
                    & + span{margin-top:0.7143vw; }
                }
                span{display: block;}
                button{text-decoration: underline;}
                &:last-of-type{text-align: right;}
            }
        }
    }
    .tbl_list{
        width: 100%; max-width:100%;
        th {
            background-color: $gray06; padding: 2.8571vw 2.1429vw; text-align: center;
            vertical-align: middle;
        }
        td{
            padding: 2.8571vw 2.1429vw; border-bottom: 0.1429vw solid $gray02; vertical-align: middle; text-align: center;
            &:first-of-type{ font-weight: 400;}
        }
    }
    // Table Type02
    .radio-block{
        .default-txt{
            padding-left: 2.8571vw; margin-bottom: 2.1429vw;
            .txt-vt{@include fontInherit;}
        }
        ul{
            border-top: 0.1429vw solid $gray02;
            // li{height: 14.2857vw;}
            .btn-radio-type2{
                height: 100%;
                label{
                    /*align-items: flex-start;*/align-items:center;padding: 2.8571vw; transition: background-color 0.3s, color 0.3s;
                } /*08.26 라디오버튼 수직중앙정렬*/
                input:checked ~ label{
                    background-color: #f9f5ff;
                    * {color: $black;}
                }
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .table-defult{
        .col1{width: 10%;}
        .col3{width: 15px;}
        border-width: 1px;
        tr{
            &.ranking{
                td{
                    &:first-child{background-size: 40px 40px;}
                    &:nth-child(2){
                        *{font-weight: 600;}
                        p{margin-bottom: 0;}
                    }
                    &:last-child{
                        p{color: #662d91;}
                    }
                }
            }
        }
        td{
            padding: 20px 0; border-width: 1px;
            &:first-child{padding: 0 15px 0 20px;}
            &:last-child{
                padding-right: 20px;
            }
        }
    }
    .radio-block{
        .default-txt{
            padding-left: 20px; margin-bottom: 10px;
        }
        ul{
            border-top-width: 1px;
            .btn-radio-type2{
                label{padding: 20px;}
            }
        }
    }
    p{
        &.bang-notice{margin-top: 10px;}
    }
    ul{
        &.bang-botice{
            margin-top: 10px;
            li{
                & + li{margin-top: 5px;}
            }
        }
    }
    .table-list-wrap{
        .table-list{
            th{padding: 20px 30px;}
            td{border-width: 1px; padding: 20px 30px;}
        }
        & + .table-list-wrap{margin-top: 20px;}
    }
    .box-table{
        .table-title{padding: 0 0 10px 0;}
        .table-type-01{
            border-width: 1px;
            tr{
                th, td{padding: 10px;}
                &:first-child{
                    th, td{padding-top: 20px;}
                }
                &:last-child{
                    th, td{padding-bottom: 20px;}
                }
                &.depth{
                    *{padding-top: 3px;}
                    th{padding-left: 30px; background-size: 6px 6px; background-position: 20px 6px;}
                }
            }
            & + .table-type-01{margin-top: 20px;}
        }
        .table-type-04{
            th, td{padding: 15px 10px;}
            td{border-width: 1px;}
        }
        .table-type-05{
            border-width: 1px;
            td{
                padding: 20px 0; border-width: 1px;
                strong{font-weight: 400;}
            }
        }
    }
}