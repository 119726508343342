.slick-slider{
    position: relative;
    .slick-list{
        .slick-slide{
            img{width: 100%;}
        }
    }
    .slick-dots{
        position: absolute; left: 0; bottom:4.2222vw; width: 100%; font-size: 0; text-align: center;
        li{
            display: inline-block;
            & + li{
                margin-left: 1.3889vw;
            }
            &.slick-active{
                button{
                    background: $black; width:5.5556vw; border-radius:8.3333vw;
                }
            }
            button{
                width: 2.2222vw; height:2.2222vw; border-radius: 50%; background: #edeff2; font-size: 0; transition: width 0.3s;
            }
        }
    }

    .slick-prev{
        @include arrows;
    }
    .slick-next{
        @include arrows;
        right: 0;
    }

}
.slick-center-mode{
    .slick-slide{
        transform: scale(0.8); transition: transform 0.3s; border-radius:2.7778vw; overflow: hidden; max-height:55.5556vw;
        img{
            width:100%;
        }
        &.slick-active{
            transform: scale(1); border-radius:2.7778vw; overflow: hidden;
        }

    }
    .slick-dots{
        bottom: -2.1667vw;
    }
}
// video slider
.video-slider{
    padding-bottom: 6.3889vw;
    .slick-item{
        position: relative; overflow: hidden; border-radius: 1.9444vw; height: 55.5556vw;
        a{display: block; width: 100%; height: 100%;}
        img{@include imgSize;}
        &::before{
            content: ''; display: block; position: absolute; top: 50%; left: 50%; z-index: 1; width: 16.6667vw; height: 16.6667vw; @include backgroundImg('/assets/images/icon/ico-video-play.png',center center,contain); transform: translate(-50%,-50%);
        }
    }
    .slick-dots{
        bottom: 0;
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
    .slick-slider{
        position: relative;
        .slick-list{
            .slick-slide{
                img{width: 100%;}
            }
        }
        .slick-dots{
            position: absolute; left: 0; bottom:2.1429vw; width: 100%; font-size: 0; text-align: center;
            li{
                display: inline-block;
                & + li{
                    margin-left: 0.7143vw;
                }
                &.slick-active{
                    button{
                        background: $black; width:2.8571vw; border-radius:4.2857vw;
                    }
                }
                button{
                    width: 1.1429vw; height:1.1429vw; border-radius: 50%; background: #edeff2; font-size: 0; transition: width 0.3s;
                }
            }
        }

        .slick-prev{
            @include arrows;
        }
        .slick-next{
            @include arrows;
            right: 0;
        }

    }
    .slick-center-mode{
        .slick-slide{
            transform: scale(0.8); transition: transform 0.3s; border-radius:1.4286vw; overflow: hidden; max-height:28.5714vw;
            img{
                width:100%;
            }
            &.slick-active{
                transform: scale(1); border-radius:1.4286vw; overflow: hidden;
            }

        }
        .slick-dots{
            bottom: -1.1429vw;
        }
    }
    // video slider
    .video-slider{
        padding-bottom: 3.2857vw;
        .slick-item{
            position: relative; overflow: hidden; border-radius: 1.0000vw; height: 28.5714vw;
            a{display: block; width: 100%; height: 100%;}
            img{@include imgSize;}
            &::before{
                content: ''; display: block; position: absolute; top: 50%; left: 50%; z-index: 1; width: 8.5714vw; height: 8.5714vw; @include backgroundImg('/assets/images/icon/ico-video-play.png',center center,contain); transform: translate(-50%,-50%);
            }
        }
        .slick-dots{
            bottom: 0;
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .slick-slider{
        .slick-dots{
            li{
                button{width: 8px; height: 8px;}
                &.slick-active{
                    button{width: 20px;}
                }
                &+li{margin-left: 5px;}
            }
        }
    }
    .video-slider{padding-bottom: 25px;}
}