@charset "UTF-8";

#footer {
    padding:11.1111vw 5.5556vw 25vw 5.5556vw;background:#191919;
    .f-info{
        dl{
            padding:0 0 5.5556vw;
            &+dl{
                padding-top:5.5556vw;border-top:0.1389vw solid rgba(255,255,255,0.2);
            }
            dt{
                display:block;@include mainEmphasize;color:#fff;font-weight: 400;margin-bottom: 0.8333vw;
            }
            dd{
                span{
                    display:block;@include defaultTxt;color:#666;font-weight:normal;
                    &:first-child {margin-bottom:2.5vw;}
                }
            }
        }
    }
    .f-corp{
        margin-bottom:4.1667vw;padding-top:5.5556vw;
        .btn-corp{
            position: relative; margin-bottom:4.1667vw; padding-right: 8.3333vw;@include normalTxt;color:#fff; padding-bottom: 0;
            &:after{
                content: ''; position: absolute; right: 0; top: 50%; width: 3.4722vw; height: 2.2222vw; @include backgroundImg('/assets/images/common/footer-arro-rt.png', 0 0, 100%); transform: translateY(-50%);
            }
            &.on:after{transform: translateY(-50%) rotate(180deg);}

        }
        address{
            margin-bottom:4.1667vw;
            p,a{
                @include smallTxt;font-style:normal;
            }
        }
        .f-menu{
            display:flex;justify-content:space-between;flex-wrap:wrap;
            li{
                flex:1 1 40%;margin-bottom:1.3889vw;
                a{
                    @include smallTxt;color:#ddd;
                }
                &.strong{
                    a{
                        @include defaultTxt;color:#fff;font-weight: 700;
                    }
                }
            }
        }

    }
    .f-etc{
        .sns{
            display:flex;justify-content:flex-start;flex-direction:row;margin-bottom:4.1667vw;
            li{
                a{
                    display:inline-flex;justify-content:center;align-items:center;overflow:hidden;width:9.7222vw;height:9.7222vw;margin-right:2.7778vw;border-radius:50%; background-position: 0 0; background-repeat: no-repeat; background-size: 100%; font-size: 0;
                }
                &:nth-child(1) a{
                    background-image: url('/assets/images/common/footer-ico-kakao.png');
                }
                &:nth-child(2) a{
                    background-image: url('/assets/images/common/footer-ico-youtube.png');
                }
                &:nth-child(3) a{
                    background-image: url('/assets/images/common/footer-ico-insta.png');
                }
            }
        }
        .prove-logo {
            display: flex;align-items: center;color:#666;font-size:4.1667vw;line-height:1.2;
            img {width:13.8889vw;margin-right:2.7778vw;}
            & + .prove-logo {margin-top:5.5556vw;}
        }
        
        .copyright{
            display:block;@include smallTxt;font-size: 3.6111vw;
        }
    }
    .app-download {
        margin-bottom:9.7222vw;
        img {vertical-align: top;}
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    #footer {
        padding:5.7143vw 2.8571vw 12.8571vw 2.8571vw;background:#191919;
        .f-info{
            dl{
                padding:0 0 2.8571vw;
                &+dl{
                    padding-top:2.8571vw;border-top:0.0714vw solid rgba(255,255,255,0.2);
                }
                dt{
                    display:block;color:#fff;font-weight: 400;margin-bottom: 0.4286vw;
                }
                dd{
                    span{
                        display:block;color:#999;
                    }
                }
            }
        }
        .f-corp{
            margin-bottom:2.1429vw;padding-top:2.8571vw;
            .btn-corp{
                position: relative; margin-bottom:2.1429vw; padding-right: 4.2857vw;color:#fff; padding-bottom: 0;
                &:after{
                    content: ''; position: absolute; right: 0; top: 50%; width: 1.7857vw; height: 1.1429vw; @include backgroundImg('/assets/images/common/footer-arro-rt.png', 0 0, 100%); transform: translateY(-50%);
                }
                &.on:after{transform: translateY(-50%) rotate(180deg);}

            }
            address{
                margin-bottom:2.1429vw;
                p,a{
                    font-style:normal;
                }
            }
            .f-menu{
                display:flex;justify-content:space-between;flex-wrap:wrap;
                li{
                    flex:1 1 40%;margin-bottom:0.7143vw;
                    a{
                        color:#fff;
                    }
                    &.strong{
                        a{color:#fff;}
                    }
                }
            }

        }
        .f-etc{
            .sns{
                display:flex;justify-content:flex-start;flex-direction:row;margin-bottom:2.1429vw;
                li{
                    a{
                        display:inline-flex;justify-content:center;align-items:center;overflow:hidden;width:5.0000vw;height:5.0000vw;margin-right:1.4286vw;border-radius:50%; background-position: 0 0; background-repeat: no-repeat; background-size: 100%; font-size: 0;
                    }
                    &:nth-child(1) a{
                        background-image: url('/assets/images/common/footer-ico-kakao.png');
                    }
                    &:nth-child(2) a{
                        background-image: url('/assets/images/common/footer-ico-youtube.png');
                    }
                    &:nth-child(3) a{
                        background-image: url('/assets/images/common/footer-ico-insta.png');
                    }
                }
            }
            .prove-logo{
                font-size: 2.1429vw;
                img{width: 7vw; margin-right: 2.7778vw;}
            }
            .copyright{
                display:block;font-size: 1.8571vw;
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    #footer{
        padding: 40px 20px 90px 20px;
        .f-info{
            dl{
                padding: 0 0 20px;
                &+dl{
                    padding-top: 20px;
                }
                dt{
                    font-size: 16px; letter-spacing: -1px;
                }
                dd{
                    span{
                        font-size: 15px; letter-spacing: -1px; color: #999;
                    }
                }
            }
        }
        .f-corp{
            margin-bottom: 15px; padding-top: 20px;
            .btn-corp{
                margin-bottom: 15px; padding-right: 30px; font-size: 15px; letter-spacing: -1px;
                &:after{
                    width: 12px; height: 12px;
                }
            }
            address{
                margin-bottom: 15px;
                p,a{
                    font-size: 15px; letter-spacing: -1px;
                }
            }
            .f-menu{
                li{
                    margin-bottom: 5px;
                    a{
                        font-size: 15px; letter-spacing: -1px; color: #fff;
                    }
                    &.strong{
                        a{
                            font-size: 15px; letter-spacing: -1px; color: #fff;
                        }
                    }
                }
            }
        }
        .f-etc{
            .sns{
                margin-bottom: 15px;
                li{
                    a{
                        width: 35px; height: 35px; margin-right: 10px;
                    }
                }
            }
            .prove-logo{
                font-size: 15px; letter-spacing: -1px;
                img{width: 50px; margin-right: 10px;}
            }
            .copyright{
                font-size: 13px; letter-spacing: -1px;
            }
        }
        .app-download {width:320px;margin-bottom:35px;}
    }
   
}