

@charset "UTF-8";

:root {
    --safe-top: 0px;
    --safe-bottom: 0px;

    // 11.2 미만
    @supports (--safe-top: constant(safe-area-inset-top)) {
        --safe-top: constant(safe-area-inset-top);
        --safe-bottom: constant(safe-area-inset-bottom);
    }

    // 11.2 이상
    @supports (--safe-top: env(safe-area-inset-top)) {
        --safe-top: env(safe-area-inset-top);
        --safe-bottom: env(safe-area-inset-bottom);
    }

}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, button,figure,figcaption,textarea, header, footer, main{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-family: 'Pretendard', 'Malgun Gothic', Sans-serif;
	font-weight: 400;
	font-size: 4.1667vw;
	color: $gray05;
	vertical-align: baseline;
	background: transparent;
	box-sizing: border-box;
}
body {
	line-height: 1;
	background: $white;
  -webkit-text-size-adjust: none;
}
// hr{
// 	display: none;
// }
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
// sup{font-size: 60%;}
em{font-style: normal;}
input{
	display: inline-block;
	box-sizing: border-box;
	font-family: 'Pretendard';
	background-color: $white;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
select{
	font-family: 'Pretendard', 'Malgun Gothic', Sans-serif;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
}
button{
	border:none;
	cursor:pointer;
	font-family: 'Pretendard';
}
a {
	text-decoration: none;
	font-family: 'Pretendard';
	font-weight: 400;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
	text-decoration: none;
}
del {
	text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img{max-width: 100%;}

.scrOff {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.hidden{@include hidden;}
input, textarea, button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}
/* input box color */
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset ; -webkit-text-fill-color: #000; }
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { transition: background-color 5000s ease-in-out 0s; }
input:-webkit-autofill:disabled {-webkit-box-shadow: 0 0 0 30px #f6f6f6 inset; }


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, font, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td, button,figure,figcaption,textarea, header, footer, main{
		font-size: 2.1429vw;
	}
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, font, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td, button,figure,figcaption,textarea, header, footer, main{
		font-size: 15px;
	}
}