@charset "UTF-8";

// 휴대폰 공통 요소
.phone {
    .product-imgCard{
        .cardList{
            align-items: flex-start; padding: 8.3333vw 5.5556vw;
        }
        .card-list-header{
            justify-content: flex-start;
            .chip-wrap{
                position: static;max-width: none !important; width:100% !important;
                img{
                    display: block;
                }
             }
        }
    }
    .filter-item-wrap{
        dl{
            display: flex;
            flex-wrap: wrap;
            width:100%;
            gap:4.1667vw 0;
            dt{
                width:30%; text-align:left; @include mainTxt;
            }
            dd{
                width:70%; text-align: left;
                .filter-item{
                    display: flex;
                }
                .c-purple{
                    margin-top:4.1667vw;
                }
                .gap-label {margin:4.1667vw 0 0 0;}
            }
        }
    }
    // 휴대폰 목록
    .phone-list {
        padding-top: 0 !important;
        .box-slide{
            height: 100vw; overflow: hidden;
        }
        .box-filter{
            .btn-extend {
                display: block; width: 100%; padding: 3.6111vw 5.5556vw; border-bottom: 0.2778vw solid $gray02; text-align: left;@include mainTxt; position: relative;
                &::after{
                    position: absolute; content:''; width:5.5556vw; height:5.5556vw; top:50%; transform: translateY(-50%); right:5.5556vw;
                    @include backgroundImg('/assets/images/icon/ico-right-diagonal.svg',center right,contain);
                }
            }
        }
        .box-select{margin: 5.5556vw 0;}
        .accordian {
            border-bottom: 0.2778vw solid $gray02;
            transition: border-color 0.3s ease;
            & > button {
                &::after{right: 5.5556vw;}
            }
            .acc-trigger{
                padding: 3.6111vw 5.5556vw; margin-bottom: 0;@include mainTxt;
            }
            .acc-conts{
                padding: 8.3333vw 5.5556vw;background-color: $bg;border-top: 0.2778vw solid $gray02; display: block;
            }
        }
        .box-btn{
            margin-top: 11.1111vw;
        }

        .product-imgCard .item-right .item-price>strong {text-align: right;}
        .product-imgCard .item-right .item-price {align-items:flex-end;}
        .product-imgCard .item-right .item-price .dc-prc {display:block; line-height: 1.43; font-size:3.8889vw;letter-spacing: -0.0778vw; text-align: right; color: #666; text-decoration: line-through;}

    }

    .info-detail {
        padding-bottom:11.1111vw;
        h3 {
            display:block;margin-bottom:2.0833vw; @include mainTxt;
        }
        .review-slide {
            position: relative; width:100%; height: 79.7222vw;
            .slick-default{
                width:100%;  height: 62.9167vw;
                .slick-list{

                    .slick-slide{
                        a{
                            display: block;
                            img{
                                display: block; width: 62.9167vw; height: 62.9167vw; margin: 0 auto; object-fit: contain;
                            }
                        }
                    }
                }
                .slick-dots{
                    bottom: -6.9444vw;
                }
            }
            .btn-share {
                display:block;position:absolute;right:0;top:0;width:8.8889vw;height:8.8889vw;border:0.2778vw solid #e6e6e6; border-radius:50%;background:$white;
                @include backgroundImg('/assets/images/icon/ico-share.svg',center,contain);
            }
        }
        .info-wrap {
            margin-top:11.1111vw;
            &.mt0{
                margin-top: 0;
            }
            .division {
                margin-bottom:-2.0833vw;
                li {margin-bottom:2.0833vw;}
            }
            .main-txt{
                & + p + .box-btn{margin-top: 5.5556vw;}
            }
            .banner-wrap {
                display:block;
                & + .banner-wrap {margin-top:8.3333vw;}
            }
        }
        .division {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 40%;}
        }
        .radio-group-wrap{
            & + .box-btn{margin-top: 5.5556vw;}
        }
        .btn-radio-group {
            .btn-radio {
                input[type=radio]:checked+label>span {color: $purple;}
                label {
                    display:flex;justify-content:center;flex-direction:column;min-height:12.2222vw;padding:0;line-height:5vw;cursor:pointer;
                    &>span {align-items:center;padding:0.8333vw; @include smallTxt; font-weight: 600;}
                }
            }
        }
        .payment-list{
            & + .box-btn{margin-top: 2.7778vw;}
        }
        .btn-radio{
            input[type="radio"]:checked{
                & + label{
                    // color: $black;
                    & > em {
                        color: $purple;                       
                    }
                }
            }
            label {
                cursor:pointer; @include mainTxt; color: $gray05;
                & > em {
                    display: block; margin-top: 1.3889vw; @include mainTxt; color: $gray05;
                    span {color: $purple; font-weight: 600;}
                }
            }
        }
    }
    .payment-detail {
        position:relative;padding:0 0 11.1111vw;
        h2 {margin-bottom:1.6667vw; font-weight: 700;}
        .pay-month-bill {
            display:flex;justify-content:space-between;margin-bottom:3.8889vw;
            .emphasis-txt{@include subContsTitle; font-weight: 700;}
            .sum{@include subContsTitle; font-weight: 700; flex-shrink: 0; padding-right: 5.5556vw}
        }
        .accordian{
            .acc-conts{
                .acc-header{
                    display: flex; justify-content: space-between; margin-bottom: 2.7778vw;
                    * {@include mainEmphasize;}
                }
                dl {
                    padding: 4.1667vw; background-color:$gray01; border-radius: 2.7778vw;
                    display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:-1.2500vw;
                    dt {flex: 1 1 50%;margin-bottom:9px;color:$gray05;text-align:left;}
                    dd {flex-shrink:0;width:22.7778vw;margin-bottom:9px;color:$black;text-align:right;}
                }
                .category {
                    border-bottom: 0.2778vw solid $gray02; padding: 4.1667vw 0;
                    &.pay-installment-plan{padding-top: 0;}
                }
                .red {color:$red !important;background:none;}
                .discount-card {
                    .acc-header{margin-bottom: 0;}
                }
            }
        }
        .box-table{
            & + .bang-notice{margin-top: 2.7778vw;}
        }
    }
    .bnr-img {
        position:relative; width:100vw;height: 27.7778vw; margin-left:-5.5556vw;
        img{
            @include imgSize;
        }
    }
    .bnr-rolling {width:100vw;;margin-left:-5.5556vw;}
    //(팝업) 요금제 선택
    .rate-plan-choose {
        .month-data {
            margin-bottom:13.8889vw;
            &>ul>li {
                &+li {margin-top:5.5556vw;}
                &>h2 {display:block;margin-bottom:4.1667vw; @include mainTxt;}
            }
        }
        .plan-list {
            legend {display:block;width:0.1389vw;height:0.1389vw;overflow:hidden;position:absolute;top:-1000em;left:0;}

            .box-select {
                margin-bottom:4.1667vw;
            }
        }
        .info-detail {
            padding-bottom:5.5556vw;
            dt{
                b {
                    padding-right:2.0833vw;
                    @include mainTxt;
                }
                strong{
                    @include mainTxt; flex-shrink: 0; color: $purple;
                }
            }
            dd{
                .notification{
                    li{
                        @include smallTxt;
                        &.tooltip-wrap{
                            z-index: 1;
                        }
                    }
                }
            }
            .pay-amount{
                @include mainTxt; color: $purple; padding-top: 5.5556vw;
            }
        }
    }
    //[팝업]요금제 선택
    .range-slider {
        .irs--flat {height:14.5833vw !important; }
        .irs--flat .irs-min,
        .irs--flat .irs-max,
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single {display:none;}
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single,
        .irs--flat .irs-bar {background:$gray02;}
        .irs--flat .irs-bar{top:2.7778vw;height:0;}
        .irs--flat .irs-handle {
            top:-1.1111vw;width:8.3333vw;height:8.3333vw;
            @include backgroundImg('/assets/images/icon/ico-range-slider.svg', center, contain);

        }
        .irs--flat .irs-line{
            background: none; height: 7.7778vw; top:-0.4167vw; z-index: 1; position: relative;
            &::after{
                position:absolute; top:3.0833vw; width:100%; height: 0.5556vw; background:$gray02; content: '';
            }
        }
        .irs{
            & >.irs{
                 height: 2.7778vw;
            }
        }
        .irs--flat .irs-handle>i {background:none !important;}
        .irs-grid{
            &-pol {display: none;}
            &-text{
                @include text(600,3.8889vw,1.71,$black,-0.0778vw); color: $black !important;
            }
        }
        .slider-dots{
            position: absolute;top: 1.9444vw;width: 2.2222vw; height: 2.2222vw; background-color: $gray02; border-radius: 50%;
            &.on{background-color: $purple;}
        }
    }
    //휴대폰 가입안내 상세
    .join-info {
        .page-tit{
           .box-btn{
            margin-top: 5.5556vw;
           }
        }
        .notification{
            .box-btn{
                margin-top: 1.3889vw; margin-bottom: 2.7778vw;
            }

        }
        .step-list {
            display:flex;flex-wrap:wrap;gap:4.1667vw; margin-top: 4.1667vw;
            & > li {
                width: calc(50% - 2.0833vw); border-radius:2.7778vw;background:$gray01; text-align: center; padding:5.5556vw;
                .ico-img{
                    width:16.6667vw; height: 16.6667vw; margin:0 auto;
                }
                .step {
                    display: block; margin-bottom: 1.3889vw; @include mainTxt; color: $purple;text-align:center;
                }
                &>span {display:block;text-align:center; @include smallTxt;}
            }
            & + .notification {margin:4.1667vw 0;}
        }
        .notification{
            &.round-box{
                margin-top: 4.1667vw; border: 0.2778vw solid $gray02; padding: 5.5556vw; border-radius: 2.7778vw;
            }
        }
        .bang-notice{
            & + .box-btn{
                margin-top: 4.1667vw;
            }
        }
        .num-process-wrap{
            margin: 4.1667vw 0 8.3333vw;
        }

    }
    //전화신청하기-기본정보입력
    .tel-join {
        h2 {margin-bottom:5.5556vw;letter-spacing:-.180vw;text-align:center;}
        .tel-join-step {
            display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:11.1111vw;
            &>li {
                display:flex;justify-content:center;flex-direction:column;position:relative;min-width:25.0000vw;min-height:15.2778vw;padding:2.3611vw;border-radius:2.7778vw;background:$gray01;
                &::after {content:'';display:block;position:absolute;right:-3.7833vw;top:50%;width:2.7778vw;height:2.7778vw;margin-top:-1.3889vw;background:url('/assets/images/icon/ico-arrow-right.svg') no-repeat center;background-size:auto 2.7778vw;}
                &:last-child::after {display:none;}
                &>span {display:inline-block;align-items:center;width:100%;@include mainEmphasize; text-align:center;}
            }
        }
        .tel-join-info {
            &>h3 {margin:4.1667vw 0 2.0833vw;}
        }
        .product-imgCard {
            .item-txt-color {display:block;margin-top:1.2500vw;@include smallTxt;}
            .item-txt-desc {margin-top:1.2500vw;}
        }
        .box-present-corp {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 30%;margin-bottom:4.1667vw;}
            &>li.btn-combo {
                display:flex;justify-content:flex-start;
                .btn-radio-type2 {align-self:center;width:auto;height:5.5556vw;}
                .box-select {
                    align-self:center;width:auto;min-width:25.8333vw;
                    select {width:auto;min-width:25.8333vw;}
                }
            }
        }
        .counsel-date {
            display:flex;flex-wrap:wrap;gap:2.7778vw;
            .box-select {flex:1 1 40%;}
        }
    }
    //작성한 신청서 확인 팝업
    .completed-application{
        .product-imgCard-btn{
            .cardList{
                padding:0;
                .item-head{
                    padding:4.1667vw 5.5556vw; display: flex; justify-content: space-between; align-items: center; border-bottom:0.2778vw solid $gray02;
                    strong{
                        @include mainEmphasize;
                    }
                    span{
                        @include smallTxt;
                    }
                }
                .item-top{
                    margin-bottom:0; padding:5.5556vw;
                    .item-txt-list{
                        li{
                            margin-bottom:1.25vw;
                        }
                    }
                }
            }
            &.noBtn{
                .cardList{
                    border-radius: 5.5556vw;
                    border-bottom:0.2778vw solid $gray02;
                    .item-top{
                        border-bottom:0;
                    }
                }
            }
        }
        .box-btn{
            .white{
                margin-top:80px;
            }
        }
    }
    //단말별 지원금 목록
    .support-device-filter{
        margin-top:11.1111vw; text-align: right; padding:0 5.5556vw;
    }
    .support-device-list{
        padding-top: 0;
        .detail-sec{
            border: none; padding-bottom: 0;
            & + .box-btn {margin-top: 2.7778vw;}
        }
        .filter-item-wrap{
            padding: 8.3333vw 5.5556vw; background-color:$bg;border-bottom: 0.2778vw solid $gray02;
        }
        .support-device-filter{padding: 0;}
        .cardList-wrap{
            margin-top:4.1667vw;
            .cardList {
                padding: 8.3333vw 5.5556vw; border: 0.2778vw solid $gray02;border-radius: 2.7778vw;
                .item-top{
                    padding: 0 0 6.9444vw 0; gap: 0;
                    .item {
                        &-img {
                            position: relative; width: 27.7778vw; height: 27.7778vw;flex-shrink: 0;
                            img{
                                @include imgSize;
                            }
                        }
                        &-txt {
                            &-tit {
                                @include defaultTxt; margin-bottom: 1.3889vw;
                            }
                            &-desc {
                                @include smallTxt;
                            }
                        }
                    }
                }
                dl{
                    background-color: $white; padding: 6.9444vw 0 0 0; border-top: 0.2778vw solid $gray02;
                    dt{
                        width: 60%; padding-bottom: 2.7778vw;
                        &:last-of-type{padding-bottom: 0;}
                    }
                    dd{
                        width: 40%; @include defaultTxt;
                        padding-bottom: 2.7778vw;
                        &:last-of-type{padding-bottom: 0;}
                    }
                }
                .box-btn{margin-top:6.9444vw;}
            }
        }
        .acc-conts{
            .btn-text-arrow{
                margin-top: 2.7778vw;
            }
        }
        .notice{
            margin-top:5.5556vw;
            .accordian.type-01{
                & + .btn-text-arrow{margin-top: 2.7778vw;}
            }
        }
    }
    //휴대폰 추천 팝업 - 결과
    .phone-recommend-result{
        section {
            & + .box-btn{
                margin-top:11.1111vw;
            }
        }
        .result-item-wrap{
            margin-bottom: 5.5556vw;
            & + .box-btn{
                margin-bottom: 11.1111vw;
            }
        }

        .find-no-data {
            @include backgroundImg('/assets/images/icon/ico-no-data.svg', center 8.75vw, 22.2222vw 21.3889vw); padding-top: 41.6667vw;
            .result {
                b{ letter-spacing: -0.1333vw; color: $gray05;}
            }
        }
    }

    .product-info { 
        .full-tab {
            padding:0;
            .tab-btn-wrap {
                overflow:hidden; margin-bottom:8.3333vw; flex-direction: column;
                li a {padding:4.4444vw 0;border-radius: 1.9444vw; }
            }
        }
        .tab-contents-wrap {
            .info-wrap + .payment-detail {margin-top:11.1111vw}
            .payment-desc .sb-txt {display:block;margin-top:0.6944vw; text-align: right;font-size: 3.6111vw; line-height: 1.54; letter-spacing: -0.0722vw; color: #959595;}
            .chip-wrap {max-width: calc(100% - 7.3333vw);}
        }
        .banner-randombox-wrap{
          left: 5.5556vw; bottom: 33.3333vw;
        }
    }

    .product-detailbox {
        margin-top:19.4444vw;
        .box-tab { 
            .tab-btn-wrap {
                margin-left:-5.5556vw; margin-right:-5.5556vw; gap:2.0833vw;
                li:first-child {margin-left:5.5556vw;}
            }
            .tab-contents-wrap {
               & > div:nth-child(3) {min-height:166.6667vw;}
            }
        }
        .table-list-wrap {          
            table {
                border-top: .2778vw solid #ddd ;
                th {border-bottom:.2778vw solid #ddd; text-align: center;}
                td {color:#191919}
            }
        }
    }
}

// 입고 알림 신청하기
.stock-noti {
    .item-title{
        display: block; @include mainEmphasize; margin-bottom: 2.0833vw;
    }
    .detail-sec{border-top: none;}
    section + section {padding-top: 8.3333vw;}
    .box-input + .box-flex{ margin-top: 5.5556vw;}
    .box-flex.column {
        margin-top: 5.5556vw;
        .box-btn{
            margin-top: 4.1667vw;
        }
    }
    .cardList-wrap{
        margin-top: 8.3333vw;
        .cardList{
            dt{width: 40%;}
            dd{width: 60%;}
        }
    }
    .check-wrap{
        margin-top: 8.3333vw;
        .main-txt{
            display: block; margin-bottom: 2.2222vw;
        }
    }

}
//신청 안내 및 유의사항
.notice-guide{
    .contents-wrap{
        & + .contents-wrap{margin-top: 11.1111vw;}
        .sub-contents-title{display: block; margin-bottom: 1.3889vw;}
        .small-txt{margin-bottom: 4.1667vw;}
        .txt-vt{display: block; @include fontInherit;}
    }
    .detail-sec{
        margin-top: 11.1111vw;
    }
}

//휴대폰 상품정보 상세
.container .sub-conts.product-info{
    padding-top: 5.5556vw;
    .img-product {
        padding-bottom:11.8056vw;
    }
    .info-wrap {
        & + .cardList-wrap {
            margin-top: 8.3333vw;
        }
        .model-name{
             @include subContsTitle;
        }
        .model-num{
           margin:9px 0 8.3333vw; @include smallTxt;
        }
    }

    .info-detail {
        .full-tab.plan-tpye{
            margin: 4.1667vw 0;
            .tab-btn-wrap {
                margin:0; flex-direction: row;
                li a {display: block; padding: 2.5vw 5.1389vw; border-radius: 5vw;}
            }
        }
    }

    .pay-amount {
        .origin-pay {font-size:3.8889vw; color:#666;}
    }

    .btn-sum {
        display:flex;flex-wrap:wrap;border-top:0.2778vw solid $gray02;background:$white;
        .payment-sum {
            display:flex;justify-content:space-between;align-items: flex-end; align-self:stretch;width:100%;min-height:15.0000vw;padding:4.8611vw 5.5556vw;
            h2 {@include mainTxt; margin-bottom:0;}
            .sum {
                display: inline-flex; align-items: flex-end; gap: 1.3889vw;
                @include smallTxt;line-height:1.33;text-align:right;
                strong {@include subContsTitle;color:$purple;line-height:5.5556vw;}
                .dc-prc {display:block;font-size: 3.8889vw;line-height: 1.71;letter-spacing: -0.0778vw;text-align: right;color: #666; text-decoration:line-through;}
                
            }
            .ico-anchor{
                display: inline-block; flex-shrink: 0; width: 5.5556vw; height: 5.5556vw; transform: rotate(180deg);
                @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center,contain);
            }
        }
        button {flex:1 1 40%;}
        .sello-btn {
            position: relative; width:100%;margin-bottom:4.8611vw; padding:0 5.5556vw;
            .btn-sello {
                display: block; padding:4.4444vw 4.1667vw 4.4444vw 5.5556vw;  border-radius: 1.9444vw;border: solid 0.2778vw #662d91;background-color: #fff;
                span {
                    position:relative;display:block;width:100%;font-size: 4.1667vw;font-weight: 600;text-align: left;color: #662d91;
                    &::after {content: '';position:absolute;right:0;top:50%; width: 5.5556vw;height: 5.5556vw;transform: translateY(-50%); background: url(/assets/images/icon/icon-arrow-right-purple.png) no-repeat 50%; background-size: 5.5556vw;}
                }
                &.on {
                    border-color: #191919;
                    span {
                        padding-left:8.3333vw; color:#191919;
                        &::after {content: ''; left:0;  background: url('/assets/images/icon/icon-sello-check.png') no-repeat 50%; background-size: 5.5556vw;}
                    }
                }
            }
            .sello-bubble{
                position:absolute;left:50%;top:-5.5556vw;min-width: 44.4444vw; transform: translateX(-50%);  padding: 1.3889vw 2.7778vw;border-radius: 1.1111vw;background: #eb068b;font-size: 4.1667vw; letter-spacing: -0.0833vw; text-align: center; box-sizing: border-box; color:#fff; z-index: 10; animation: MoveUpDown 3s linear infinite;
                &::before {content: ''; position: absolute; left:50%; bottom: 0;transform: translateX(-13%); height: 0px;border: 1.1111vw solid transparent;border-top-color: #eb068b;border-bottom: 0;margin-left: -0.8333vw;margin-bottom: -0.8333vw;}
            } 
            @keyframes MoveUpDown {
                0%, 100% {
                   top:-3.4722vw;
                }
               50% {
                    top:-2.7778vw;
                }
            }
        }
    }
    .payment-detail{
        padding-bottom: 0;
        .accordian{
            .acc-conts-type2{
                & > ul{
                    display: flex; flex-direction: column; gap: 5.5556vw; border-top: 0.2778vw solid $gray02;border-bottom: 0.2778vw solid $gray02; padding-top: 5.5556vw;
                    & > li{
                        display: flex; justify-content: space-between; align-items: flex-end; flex-wrap: wrap; padding: 0 4.1667vw;
                        strong, b{
                            @include defaultTxt;
                            text-align: right;
                            span{@include fontInherit;}
                            span.dc-prc {display:block; font-size: 3.8889vw; letter-spacing: -0.0778vw; text-align: right; color: #666; text-decoration: line-through; font-weight:normal}
                        }
                        &.result-bill{
                            padding: 5.5556vw 4.1667vw;
                            background-color:#eff1f5;
                            strong{
                                @include mainTxt;
                            }
                            b{
                                @include contsTitle;
                                span{@include fontInherit;}
                                span.dc-prc {display:block; font-size: 3.8889vw; letter-spacing: -0.0778vw; text-align: right; color: #666; text-decoration: line-through; font-weight:normal}
                            }                           
                        }
                        .acc-conts{
                            width: 100%;
                            li{
                                display: flex; justify-content: space-between; align-items: flex-end; margin-top: 2.7778vw;
                                strong, b{
                                    @include smallTxt;
                                    span{@include fontInherit;}                                    
                                }
                            }
                        }
                    }
                }
                .minus{color: $purple !important;}
            }
            .bang-notice{margin-top: 2.7778vw;}
        }
        .tooltip-wrap{
            .box-tooltip {
                .txt-tooltip{
                    left: -20.6667vw; @include defaultTxt;
                }
            }
        }
    }

    .txt-guide {margin-top:2.7778vw;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
    .radio-group-wrap{
        .group-tit{display: flex; justify-content: space-between; align-items: center;}
        .box-tit {
            display: flex; align-items: flex-start;
            .tooltip-wrap {
                margin:0.6944vw 0 0 2.7778vw;
                 .ucash-tip{
                    display: inline-block; width: 4.1667vw;height: 4.1667vw;@include backgroundImg('/assets/images/icon/ico-tip.svg', center,contain);  
                }
                .box-tooltip{
                     strong {display:block; margin-bottom:2.0833vw; font-size: 5vw; font-weight: 600; line-height: 1.11; letter-spacing: -0.1vw; text-align: left; color: #191919;}
                     p {margin-top:5.5556vw;font-size:4.1667vw; color:#666;} 
                }
            }
        }
        .btn-radio-group.ucash {
             .btn-radio label {min-height:31.25vw}
        }
        .txt-box {
            height: 30.5556vw; padding: 10.2778vw 0; border-radius: 1.9444vw; border: solid 0.2778vw #ddd; background-color: #fff; text-align: center;
            p {
                font-size: 4.1667vw;font-weight: 600; line-height: 1.2; letter-spacing: -0.0833vw; text-align: center; color: #666;
                strong {font-size: 4.1667vw;font-weight: 600; line-height: 1.2; letter-spacing: -0.0833vw; color:#662d91}
            }

        }

    }
    .btn-radio-group{
        &.group6{
            .btn-radio{
                label{min-height: 16.6667vw;}
            }
        }
    }
    .color-type{
        justify-content: flex-start;
    }
    .box-color-name{
        display: flex; justify-content: space-between; align-items: center; margin-top: 4.1667vw;
        strong{@include defaultTxt; font-weight: 400;}
    }
    .payment {
        .pay-amount{
            @include mainTxt; color: $purple; padding-top: 5.5556vw;
        }
    }
    .partner-card{
        dl{
            align-items: flex-end;
            dt{
                width: 50%; @include defaultTxt;font-weight: 400;
            }
            dd{
                width: 50%; @include mainTxt;
                & > span{@include fontInherit;}
            }
        }
    }
    .banner-wrap{
        img{width: 100%;}
    }
}

//휴대폰 추천 팝업
.phone-recommend{
    .radio-group{
        li{
            margin-bottom:2.7778vw;
        }
    }
}



//휴대폰 목록 비교 레이어
.toggle-layer-popup{
    position: fixed;left:0; bottom:0; width:100%;background: none; opacity: 1; display: block !important;
    &.on{
        .popup-header{
            .popup-close{
                transform: rotate(-180deg);
            }
        }
        .popup-body{
            max-height:0;
            padding:0;
        }
        .popup-footer{
            max-height:0;
        }
    }
}

.comparison-result-list{
    border:0.2778vw solid $gray02; border-bottom:0;  border-radius: 5.5556vw 5.5556vw 0 0;
    .popup-header{
        display: flex; justify-content: space-between; padding: 4.7222vw 5.5556vw;
        .txt-main{@include subContsTitle;}
        .popup-close{
            width:5.5556vw; height:5.5556vw; transition: all 0.5s;
            @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', center , contain);
        }
    }
    .popup-body{
        transition: all 0.5s; padding:2.7778vw 5.5556vw 8.3333vw; max-height:55.5556vw;
        .comparison-result-item{
            display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; gap: 4.1667vw;
            li{
                position: relative; display: block;
                border:0.2778vw solid $gray02; border-radius:2.2222vw;
                width: calc(50% - 2.0833vw); height: 44.4444vw;
                &.btn-check-products{
                    .ico-delete{
                        display: block; position:absolute;
                        top:2.7778vw; right:2.7778vw; transform: translateY(0); background-size: contain; width:5.5556vw; height:5.5556vw;
                        @include backgroundImg('/assets/images/icon/ico-delete.svg', center , 5.5556vw 5.5556vw);

                    }
                    .result-item-img{
                        display: block; position: relative; width: 22.2222vw; height: 22.2222vw;margin:0 auto; margin:8.3333vw auto; margin-bottom:0;
                        img{
                            @include imgSize;
                        }
                    }
                    .result-item-title{
                        text-align: center; padding:4.1667vw 0;
                        @include mainTxt;
                    }

                    &.no-item{
                        background-color: $gray01; min-height:44.4444vw; display: flex; justify-content: center; align-items: center; cursor: pointer;
                        @include backgroundImg('/assets/images/icon/ico-plus-big.svg', center , 11.1111vw 11.1111vw);
                    }
                }
            }

        }
    }
    .popup-footer{
        transition: all 0.5s; margin-top:0 !important;
    }
}

// (팝업) 사은품안내
.phone-gifts{
    .main-txt{
            display: block; margin-bottom: 4.1667vw; @include mainTxt;
            span{@include fontInherit;}
        }
    .gift-list{
        & + .gift-list{margin-top: 8.3333vw;}
        
        .small-txt{margin: -2.7778vw 0 4.1667vw;}
        & > ul {
            display: flex; flex-direction: column; gap: 4.1667vw;
            & > li{
                display: flex; flex-direction: column; align-items: center; gap: 2.7778vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw; padding: 5.5556vw 0;
                .item{
                    &-img{
                        position: relative; width: 35vw; height: 17.2222vw;
                        img{@include imgSize;}
                    }
                    &-txt{
                        @include smallTxt; font-weight: 600;
                    }
                }
            }
            &.notification{
                li{
                    border:none;  display: block; gap:0;
                }
            }
        }
    }
    .notification-wrap{
        margin-top: 8.3333vw;
        .main-txt {margin-bottom:1.3889vw;}
        p {font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
    }
}

// (팝업) 제휴카드 선택
.card-affiliate {
    .box-btn{
        display: flex; flex-direction: column; gap: 4.1667vw;
    }
    .btn-radio-type2{
        padding: 5.5556vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;
        &.card-radio {
            padding: 8.3333vw 5.5556vw;
            .card-detail{
                display: flex; gap: 4.1667vw;
                .card {
                    &-thumb {
                        max-width: 25vw; width: 100%;
                        img{width: 100%;}
                    }
                    &-txt {
                        display: flex; flex-direction: column; gap:1.3889vw; width: calc(100% - 27.7778vw);
                        .card-title{
                            display: block; @include mainTxt;
                        }
                        .card-sale{
                            @include defaultTxt;
                        }
                    }
                }
            }
        }
    }
    .notification{
        padding-top: 2.7778vw; margin-top: 5.5556vw; border-top: 0.2778vw solid $gray02;
        li{
            @include smallTxt;
        }
    }
    .bottom-btn{
        margin-top: 11.1111vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw; padding: 5.5556vw;
        .info-txt{
            @include text(400,3.8889vw,1.43,$gray05,-0.1167vw); margin-bottom: 1.3889vw; display: flex; justify-content: space-between; align-items: flex-end;
            .ico-arrow-right{margin-bottom: 1.3889vw;}
        }
        strong{@include mainTxt; font-weight: 700;}
    }
}

// (팝업) 무이자 할부 카드 안내
.interest-free{
    .card-desc-list{
        margin-bottom: 5.5556vw; padding: 5.5556vw; background-color: $gray01; border-radius: 2.7778vw;
        li{
            & + li{
                margin-top: 1.3889vw;
            }
        }
        * {@include smallTxt; font-weight: 400;}
    }
}
//(팝업) 휴대폰 비교하기
.phone-comparison{
    .product-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex:1;
        gap:5.5556vw;
        li{
            width:100%; text-align: center;
            .item-img{
                position: relative; width:25vw; height:25vw; margin:0 auto;
                img{
                    @include imgSize;
                }
            }
            .item-tit{
                @include mainTxt; display: block; margin:4.1667vw 0 5.5556vw;
            }
            .btns{
                padding:4.4444vw 0; @include defaultTxt; color: $white;
            }
        }
    }
    .detail-sec{
        margin-top:5.5556vw; border: none;
        .sub-conts{
            padding: 0;
            & + .sub-conts{margin-top: 10.6944vw;}
            .detail-sec-tit{
                text-align: left; margin-bottom: 5.5556vw;
            }
            .detail-sec-desc{
                display: flex;
                justify-content:center; gap:4.1667vw; flex: 1;

                dl{
                    padding:6.9444vw 5.8333vw;
                    background: $gray01;
                    border-radius:2.7778vw;
                    text-align: center;
                    width:100%;
                    dt{
                        @include defaultTxt;
                    }
                    dd{
                        @include smallTxt; margin:1.3889vw 0 6.9444vw;
                        .txt-vt{@include defaultTxt;}
                        ul{
                            li{
                                @include smallTxt;
                            }
                        }
                        .btn-box{
                            display: flex; flex-wrap: wrap; gap:4.1667vw; margin-top:4.1667vw;
                            .btns.white{
                                @include defaultTxt; padding:3.0556vw 0;
                                background: $white; border: 0.2778vw solid $black;
                            }
                        }

                    }
                }
            }
        }
    }
}

// (팝업) 지원금 반환금 및 정산금 안내
.refund-guide{
    .text-wrap{
        & + .text-wrap{margin-top: 13.8889vw;}
    }
    .contents-title{
        margin-bottom: 5.5556vw; @include subContsTitle;
    }
    .notification{
        margin-top: 2.7778vw;
    }
    ol{
        display: flex; flex-direction: column; gap: 8.3333vw;
    }
}

// (팝업) 오늘배송 서비스 지역안내
.delivery-today{
    .bang-notice{margin-top: 2.7778vw;}
    .table-more{
        .box-table{
            margin-top: 8.3333vw; height: 179.7778vw; overflow: hidden;
            &.on {
                height: auto;
            }
        }
        .box-btn{
            display: block; margin-top: 2.7778vw;
            .btn-text-more{
                &.on{display: none;}
            }
        }

    }
}

.sello-compenstin {
    .page-tit {
        .txt {
            margin-top:1.3889vw;padding:0; font-size: 4.1667vw;letter-spacing: -0.6px;text-align: left;color: #666;
            &::before {display:none;}
        }
    }
    .benefit-box {
        padding: 5.5556vw 6.9444vw;border-radius: 1.9444vw;background-color: #f9f5ff;text-align:center;
        img + .benefit-view {margin-top:3.4722vw;}
        .benefit-view {
            .view-tit {display: inline-block;height:5.8333vw; line-height:5.9722vw;   padding: 0 2.7778vw;border-radius: 13.8889vw; background-color: #662d91; font-size: 3.6111vw;font-weight: 600;letter-spacing: -0.0722vw;text-align: center;color: #fff;}
            p {
                margin-top:3.4722vw; font-size: 3.8889vw;font-weight: 600;line-height: 1.21;letter-spacing: -0.0778vw;text-align: center;color: #191919;
                span {font-weight: 700;}
            }
        }
    }
    .tit {  font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.1333vw;text-align: left;color: #191919;}
    .price-option {
        margin-top:5.5556vw;
        .option-list {
            margin-top:2.0833vw;
            .box-select {
                & + .box-select {margin-top:2.7778vw;}
            }
        }
        .not-result {
            margin-top:2.0833vw; padding:8.3333vw 5.5556vw;border-radius: 2.7778vw;border: solid 0.2778vw #ddd;background-color: #fff;
            p {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.125vw;text-align: center;color: #191919;}            
        }
    }
    .btn-wrp {
        margin-top:4.1667vw;
        .btns:disabled {background:#ddd;}
    }
    .price-view {
        margin-top:8.3333vw;
        .view-box {
            margin-top:2.0833vw; padding: 8.3333vw 5.5556vw;border-radius: 2.7778vw;border: solid 0.2778vw #ddd;background-color: #fff;
            .box-tit { font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.15vw;text-align: center;color: #191919;}
            .box-table {
                margin-top:5.5556vw;
                .table-top {
                    display: flex;justify-content: space-between;align-items: center;
                    p {font-size: 4.1667vw;font-weight: 600;letter-spacing: -0.125vw;text-align: left;color: #191919;}
                }
                .table-type-04 {
                    margin-top:2.0833vw;
                    border-top:1px solid #ddd;
                    tbody {
                        th {border-bottom:1px solid #ddd}
                    }
                }
                .notification {margin-top:2.7778vw;}
                .btn-wrp{
                    .btns {
                        & +.btns {margin-top:2.7778vw;}
                    }
                }
            }
        }
    }
    .notice {
        margin-top:11.1111vw;
        .acc-trigger {margin-bottom:8.3333vw;}
        .acc-conts {margin-top:-4.1667vw}
    }
}

.full-popup {
    .box-table.sello-table {
        overflow-x: auto;  width: 100%;
        table {width:200%;}
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
.phone {
    .product-imgCard{
        .cardList{
            align-items: flex-start; padding: 4.2857vw 2.8571vw;
        }
        .card-list-header{
            justify-content: flex-start;
            .chip-wrap{
                position: static;max-width: none !important; width:100% !important;
                img{
                    display: block;
                }
             }
        }
    }
    .filter-item-wrap{
        dl{
            display: flex;
            flex-wrap: wrap;
            width:100%;
            gap:2.1429vw 0;
            dt{
                width:30%; text-align:left;
            }
            dd{
                width:70%; text-align: left;
                .filter-item{
                    display: flex;
                }
                .c-purple{
                    margin-top:2.1429vw;
                }
            }
        }
    }
    // 휴대폰 목록
    .phone-list {
        padding-top: 0 !important;
        .box-slide{
            height: 51.4286vw; overflow: hidden;
        }
        .box-filter{
            .btn-extend {
                display: block; width: 100%; padding: 1.8571vw 2.8571vw; border-bottom: 0.1429vw solid $gray02; text-align: left;position: relative;
                &::after{
                    position: absolute; content:''; width:2.8571vw; height:2.8571vw; top:50%; transform: translateY(-50%); right:2.8571vw;
                    @include backgroundImg('/assets/images/icon/ico-right-diagonal.svg',center right,contain);
                }
            }
        }
        .box-select{margin: 2.8571vw 0;}
        .accordian {
            border-bottom: 0.1429vw solid $gray02;
            transition: border-color 0.3s ease;
            & > button {
                &::after{right: 2.8571vw;}
            }
            .acc-trigger{
                padding: 1.8571vw 2.8571vw; margin-bottom: 0;
            }
            .acc-conts{
                padding: 4.2857vw 2.8571vw;background-color: $bg;border-top: 0.1429vw solid $gray02; display: block;
            }
        }
        .box-btn{
            margin-top: 5.7143vw;
        }

    }

    .info-detail {
        padding-bottom:5.7143vw;
        h3 {
            display:block;margin-bottom:1.0714vw;
        }
        .review-slide {
            position: relative; width:100%; height: 41.0000vw;
            .slick-default{
                width:100%;  height: 32.3571vw;
                .slick-list{

                    .slick-slide{
                        a{
                            display: block;
                            img{
                                display: block; width: 32.3571vw; height: 32.3571vw; margin: 0 auto; object-fit: contain;
                            }
                        }
                    }
                }
                .slick-dots{
                    bottom: -3.5714vw;
                }
            }
            .btn-share {
                display:block;position:absolute;right:0;top:0;width:4.5714vw;height:4.5714vw;border:0.1429vw solid #e6e6e6; border-radius:50%;background:$white;
                @include backgroundImg('/assets/images/icon/ico-share.svg',center,contain);
            }
        }
        .info-wrap {
            margin-top:5.7143vw;
            &.mt0{
                margin-top: 0;
            }
            .division {
                margin-bottom:-1.0714vw;
                li {margin-bottom:1.0714vw;}
            }
            .main-txt{
                & + p + .box-btn{margin-top: 2.8571vw;}
            }
        }
        .division {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 40%;}
        }
        .radio-group-wrap{
            & + .box-btn{margin-top: 2.8571vw;}
        }
        .btn-radio-group {
            .btn-radio {
                input[type=radio]:checked+label>span {color: $purple;}
                label {
                    display:flex;justify-content:center;flex-direction:column;min-height:6.2857vw;padding:0;line-height:2.5714vw;cursor:pointer;
                    &>span {align-items:center;padding:0.4286vw; font-weight: 600;}
                }
            }
        }
        .payment-list{
            & + .box-btn{margin-top: 1.4286vw;}
        }
        .btn-radio{
            input[type="radio"]:checked{
                & + label{
                    // color: $black;
                    & > em {color: $purple;}
                }
            }
            label {
                cursor:pointer;  color: $gray05;
                & > em {display: block; margin-top: 0.7143vw;  color: $gray05;}
            }
        }
    }
    .payment-detail {
        position:relative;padding:0 0 5.7143vw;
        h2 {margin-bottom:0.8571vw; font-weight: 700;}
        .pay-month-bill {
            display:flex;justify-content:space-between;margin-bottom:2.0000vw;
            .emphasis-txt{ font-weight: 700;}
            .sum{ font-weight: 700; flex-shrink: 0; padding-right: 2.8571vw}
        }
        .accordian{
            .acc-conts{
                .acc-header{
                    display: flex; justify-content: space-between; margin-bottom: 1.4286vw;
                }
                dl {
                    padding: 2.1429vw; background-color:$gray01; border-radius: 1.4286vw;
                    display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:-0.6429vw;
                    dt {flex: 1 1 50%;margin-bottom:0.6429vw;color:$gray05;text-align:left;}
                    dd {flex-shrink:0;width:11.7143vw;margin-bottom:0.6429vw;color:$black;text-align:right;}
                }
                .category {
                    border-bottom: 0.1429vw solid $gray02; padding: 2.1429vw 0;
                    &.pay-installment-plan{padding-top: 0;}
                }
                .red {color:$red !important;background:none;}
                .discount-card {
                    .acc-header{margin-bottom: 0;}
                }
            }
        }
        .box-table{
            & + .bang-notice{margin-top: 1.4286vw;}
        }
    }
    .bnr-img {
        position:relative; width:51.4286vw;height: 14.2857vw; margin-left:-2.8571vw;
        img{
            @include imgSize;
        }
    }
    .bnr-rolling {width:51.4286vw;;margin-left:-2.8571vw;}
    //(팝업) 요금제 선택
    .rate-plan-choose {
        .month-data {
            margin-bottom:7.1429vw;
            &>ul>li {
                &+li {margin-top:2.8571vw;}
                &>h2 {display:block;margin-bottom:2.1429vw;}
            }
        }
        .plan-list {
            legend {display:block;width:0.0714vw;height:0.0714vw;overflow:hidden;position:absolute;top:-1000em;left:0;}

            .box-select {
                margin-bottom:2.1429vw;
            }
        }
        .info-detail {
            padding-bottom:2.8571vw;
            dt{
                b {
                    padding-right:1.0714vw;
                }
                strong{
                     flex-shrink: 0; color: $purple;
                }
            }
            dd{
                .notification{
                    li{
                        &.tooltip-wrap{
                            z-index: 1;
                        }
                    }
                }
            }
            .pay-amount{
                color: $purple; padding-top: 2.8571vw;
            }
        }
    }
    //[팝업]요금제 선택
    .range-slider {
        .irs--flat {height:7.5000vw !important; }
        .irs--flat .irs-min,
        .irs--flat .irs-max,
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single {display:none;}
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single,
        .irs--flat .irs-bar {background:$gray02;}
        .irs--flat .irs-bar{top:1.4286vw;height:0;}
        .irs--flat .irs-handle {
            top:-0.5714vw;width:4.2857vw;height:4.2857vw;
            @include backgroundImg('/assets/images/icon/ico-range-slider.svg', center, contain);

        }
        .irs--flat .irs-line{
            background: none; height: 4.0000vw; top:-0.2143vw; z-index: 1; position: relative;
            &::after{
                position:absolute; top:1.5714vw; width:100%; height: 0.2857vw; background:$gray02; content: '';
            }
        }
        .irs{
            & >.irs{
                 height: 1.4286vw;
            }
        }
        .irs--flat .irs-handle>i {background:none !important;}
        .irs-grid{
            &-pol {display: none;}
            &-text{
                @include text(600,2.0000vw,1.71,$black,-0.0714vw); color: $black !important;
            }
        }
        .slider-dots{
            position: absolute;top: 1.0000vw;width: 1.1429vw; height: 1.1429vw; background-color: $gray02; border-radius: 50%;
            &.on{background-color: $purple;}
        }
    }
    //휴대폰 가입안내 상세
    .join-info {
        .page-tit{
           .box-btn{
            margin-top: 2.8571vw;
           }
        }
        .notification{
            .box-btn{
                margin-top: 0.7143vw; margin-bottom: 1.4286vw;
            }

        }
        .step-list {
            display:flex;flex-wrap:wrap;gap:2.1429vw; margin-top: 2.1429vw;
            & > li {
                width: calc(50% - 1.0714vw); border-radius:1.4286vw;background:$gray01; text-align: center; padding:2.8571vw;
                .ico-img{
                    width:8.5714vw; height: 8.5714vw; margin:0 auto;
                }
                .step {
                    display: block; margin-bottom: 0.7143vw; color: $purple;text-align:center;
                }
                &>span {display:block;text-align:center;}
            }
            & + .notification {margin:2.1429vw 0;}
        }
        .notification{
            &.round-box{
                margin-top: 2.1429vw; border: 0.1429vw solid $gray02; padding: 2.8571vw; border-radius: 1.4286vw;
            }
        }
        .bang-notice{
            & + .box-btn{
                margin-top: 2.1429vw;
            }
        }
        .num-process-wrap{
            margin: 2.1429vw 0 4.2857vw;
        }

    }
    //전화신청하기-기본정보입력
    .tel-join {
        h2 {margin-bottom:2.8571vw;letter-spacing:-0.0714vw;text-align:center;}
        .tel-join-step {
            display:flex;flex-wrap:wrap;justify-content:space-between;margin-bottom:5.7143vw;
            &>li {
                display:flex;justify-content:center;flex-direction:column;position:relative;min-width:12.8571vw;min-height:7.8571vw;padding:1.2143vw;border-radius:1.4286vw;background:$gray01;
                &::after {content:'';display:block;position:absolute;right:-1.9286vw;top:50%;width:1.4286vw;height:1.4286vw;margin-top:-0.7143vw;background:url('/assets/images/icon/ico-arrow-right.svg') no-repeat center;background-size:auto 1.4286vw;}
                &:last-child::after {display:none;}
                &>span {display:inline-block;align-items:center;width:100%; text-align:center;}
            }
        }
        .tel-join-info {
            &>h3 {margin:2.1429vw 0 1.0714vw;}
        }
        .product-imgCard {
            .item-txt-color {display:block;margin-top:0.6429vw;}
            .item-txt-desc {margin-top:0.6429vw;}
        }
        .box-present-corp {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 30%;margin-bottom:2.1429vw;}
            &>li.btn-combo {
                display:flex;justify-content:flex-start;
                .btn-radio-type2 {align-self:center;width:auto;height:2.8571vw;}
                .box-select {
                    align-self:center;width:auto;min-width:13.2857vw;
                    select {width:auto;min-width:13.2857vw;}
                }
            }
        }
        .counsel-date {
            display:flex;flex-wrap:wrap;gap:1.4286vw;
            .box-select {flex:1 1 40%;}
        }
    }
    //작성한 신청서 확인 팝업
    .completed-application{
        .product-imgCard-btn{
            .cardList{
                padding:0;
                .item-head{
                    padding:2.1429vw 2.8571vw; display: flex; justify-content: space-between; align-items: center; border-bottom:0.1429vw solid $gray02;
                }
                .item-top{
                    margin-bottom:0; padding:2.8571vw;
                    .item-txt-list{
                        li{
                            margin-bottom:0.6429vw;
                        }
                    }
                }
            }
            &.noBtn{
                .cardList{
                    border-radius: 2.8571vw;
                    border-bottom:0.1429vw solid $gray02;
                    .item-top{
                        border-bottom:0;
                    }
                }
            }
        }
        .box-btn{
            .white{
                margin-top:5.7143vw;
            }
        }
    }
    //단말별 지원금 목록
    .support-device-filter{
        margin-top:5.7143vw; text-align: right; padding:0 2.8571vw;
    }
    .support-device-list{
        padding-top: 0;
        .detail-sec{
            border: none; padding-bottom: 0;
            & + .box-btn {margin-top: 1.4286vw;}
        }
        .filter-item-wrap{
            padding: 4.2857vw 2.8571vw; background-color:$bg;border-bottom: 0.1429vw solid $gray02;
        }
        .support-device-filter{padding: 0;}
        .cardList-wrap{
            margin-top:2.1429vw;
            .cardList {
                padding: 4.2857vw 2.8571vw; border: 0.1429vw solid $gray02;border-radius: 1.4286vw;
                .item-top{
                    padding: 0 0 3.5714vw 0; gap: 0;
                    .item {
                        &-img {
                            position: relative; width: 14.2857vw; height: 14.2857vw;flex-shrink: 0;
                            img{
                                @include imgSize;
                            }
                        }
                        &-txt {
                            &-tit {
                              margin-bottom: 0.7143vw;
                            }
                        }
                    }
                }
                dl{
                    background-color: $white; padding: 3.5714vw 0 0 0; border-top: 0.1429vw solid $gray02;
                    dt{
                        width: 60%; padding-bottom: 1.4286vw;
                        &:last-of-type{padding-bottom: 0;}
                    }
                    dd{
                        width: 40%;
                        padding-bottom: 1.4286vw;
                        &:last-of-type{padding-bottom: 0;}
                    }
                }
                .box-btn{margin-top:3.5714vw;}
            }
        }
        .acc-conts{
            .btn-text-arrow{
                margin-top: 1.4286vw;
            }
        }
        .notice{
            margin-top:2.8571vw;
            .accordian.type-01{
                & + .btn-text-arrow{margin-top: 1.4286vw;}
            }
        }
    }
    //휴대폰 추천 팝업 - 결과
    .phone-recommend-result{
        section {
            & + .box-btn{
                margin-top:5.7143vw;
            }
        }
        .result-item-wrap{
            margin-bottom: 2.8571vw;
            & + .box-btn{
                margin-bottom: 5.7143vw;
            }
        }

        .find-no-data {
            @include backgroundImg('/assets/images/icon/ico-no-data.svg', center 4.5000vw, 11.4286vw 11.0000vw); padding-top: 21.4286vw;
            .result {
                b{ letter-spacing: -0.0714vw; color: $gray05;}
            }
        }
    }
}

// 입고 알림 신청하기
.stock-noti {
    .item-title{
        display: block; margin-bottom: 1.0714vw;
    }
    .detail-sec{border-top: none;}
    section + section {padding-top: 4.2857vw;}
    .box-input + .box-flex{ margin-top: 2.8571vw;}
    .box-flex.column {
        margin-top: 2.8571vw;
        .box-btn{
            margin-top: 2.1429vw;
        }
    }
    .cardList-wrap{
        margin-top: 4.2857vw;
        .cardList{
            dt{width: 40%;}
            dd{width: 60%;}
        }
    }
    .check-wrap{
        margin-top: 4.2857vw;
        .main-txt{
            display: block; margin-bottom: 1.1429vw;
        }
    }

}
//신청 안내 및 유의사항
.notice-guide{
    .contents-wrap{
        & + .contents-wrap{margin-top: 5.7143vw;}
        .sub-contents-title{display: block; margin-bottom: 0.7143vw;}
        .small-txt{margin-bottom: 2.1429vw;}
        .txt-vt{display: block; @include fontInherit;}
    }
    .detail-sec{
        margin-top: 5.7143vw;
    }
}

//휴대폰 상품정보 상세
.container .sub-conts.product-info{
    padding-top: 2.8571vw;
    .img-product {
        padding-bottom:6.0714vw;
    }
    .info-wrap {
        & + .cardList-wrap {
            margin-top: 4.2857vw;
        }
        .model-num{
           margin:0.6429vw 0 4.2857vw;
        }
    }

    .btn-sum {
        display:flex;flex-wrap:wrap;border-top:0.1429vw solid $gray02;background:$white;
        .payment-sum {
            display:flex;justify-content:space-between;align-items: center; align-self:stretch;width:100%;height:7.7143vw;padding:0 2.8571vw;
            h2 { margin-bottom:0;}
            .sum {
                display: inline-flex; align-items: center; gap: 0.7143vw;
                line-height:1.33;text-align:right;
                strong {color:$purple;line-height:2.8571vw;}
            }
            .ico-anchor{
                display: inline-block; flex-shrink: 0; width: 2.8571vw; height: 2.8571vw; transform: rotate(180deg);
                @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center,contain);
            }
        }
        button {flex:1 1 40%;}
    }
    .payment-detail{
        padding-bottom: 0;
        .accordian{
            .acc-conts-type2{
                & > ul{
                    display: flex; flex-direction: column; gap: 2.8571vw; border-top: 0.1429vw solid $gray02;border-bottom: 0.1429vw solid $gray02; padding-top: 2.8571vw;
                    & > li{
                        display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; padding: 0 2.1429vw;
                        strong, b{
                            span{@include fontInherit;}
                        }
                        &.result-bill{
                            padding: 2.8571vw 2.1429vw;
                            background-color:#eff1f5;
                            b{
                                span{@include fontInherit;}
                            }
                        }
                        .acc-conts{
                            width: 100%;
                            li{
                                display: flex; justify-content: space-between; align-items: center; margin-top: 1.4286vw;
                                strong, b{
                                    span{@include fontInherit;}
                                }
                            }
                        }
                    }
                }
                .minus{color: $purple !important;}
            }
            .bang-notice{margin-top: 1.4286vw;}
        }
        .tooltip-wrap{
            .box-tooltip {
                .txt-tooltip{
                    left: -10.6429vw;
                }
            }
        }
    }
    .radio-group-wrap{
        .group-tit{display: flex; justify-content: space-between; align-items: center;}
    }
    .btn-radio-group{
        &.group6{
            .btn-radio{
                label{min-height: 8.5714vw;}
            }
        }
    }
    .color-type{
        justify-content: flex-start;
    }
    .box-color-name{
        display: flex; justify-content: space-between; align-items: center; margin-top: 2.1429vw;
        strong{ font-weight: 400;}
    }
    .payment {
        .pay-amount{
            color: $purple; padding-top: 2.8571vw;
        }
    }
    .partner-card{
        dl{
            align-items: flex-end;
            dt{
                width: 50%;font-weight: 400;
            }
            dd{
                width: 50%;
                & > span{@include fontInherit;}
            }
        }
    }
    .banner-wrap{
        img{width: 100%;}
    }
}

//휴대폰 추천 팝업
.phone-recommend{
    .radio-group{
        li{
            margin-bottom:1.4286vw;
        }
    }
}



//휴대폰 목록 비교 레이어
.toggle-layer-popup{
    position: fixed;left:0; bottom:0; width:100%;background: none; opacity: 1; display: block !important;
    &.on{
        .popup-header{
            .popup-close{
                transform: rotate(-180deg);
            }
        }
        .popup-body{
            max-height:0;
            padding:0;
        }
        .popup-footer{
            max-height:0;
        }
    }
}

.comparison-result-list{
    border:0.1429vw solid $gray02; border-bottom:0;  border-radius: 2.8571vw 2.8571vw 0 0;
    .popup-header{
        display: flex; justify-content: space-between; padding: 2.4286vw 2.8571vw;
        .popup-close{
            width:2.8571vw; height:2.8571vw; transition: all 0.5s;
            @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', center , contain);
        }
    }
    .popup-body{
        transition: all 0.5s; padding:1.4286vw 2.8571vw 4.2857vw; max-height:28.5714vw;
        .comparison-result-item{
            display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; gap: 2.1429vw;
            li{
                position: relative; display: block;
                border:0.1429vw solid $gray02; border-radius:1.1429vw;
                width: calc(50% - 1.0714vw); height: 22.8571vw;
                &.btn-check-products{
                    .ico-delete{
                        display: block; position:absolute;
                        top:1.4286vw; right:1.4286vw; transform: translateY(0); background-size: contain; width:2.8571vw; height:2.8571vw;
                        @include backgroundImg('/assets/images/icon/ico-delete.svg', center , 2.8571vw 2.8571vw);

                    }
                    .result-item-img{
                        display: block; position: relative; width: 11.4286vw; height: 11.4286vw;margin:0 auto; margin:4.2857vw auto; margin-bottom:0;
                        img{
                            @include imgSize;
                        }
                    }
                    .result-item-title{
                        text-align: center; padding:2.1429vw 0;
                    }

                    &.no-item{
                        background-color: $gray01; min-height:22.8571vw; display: flex; justify-content: center; align-items: center; cursor: pointer;
                        @include backgroundImg('/assets/images/icon/ico-plus-big.svg', center , 5.7143vw 5.7143vw);
                    }
                }
            }

        }
    }
    .popup-footer{
        transition: all 0.5s; margin-top:0 !important;
    }
}

// (팝업) 사은품안내
.phone-gifts{
    .gift-list{
        & + .gift-list{margin-top: 4.2857vw;}
        .main-txt{
            display: block; margin-bottom: 2.1429vw;
            span{@include fontInherit;}
        }
        .small-txt{margin: -1.4286vw 0 2.1429vw;}
        ul {
            display: flex; flex-direction: column; gap: 2.1429vw;
            li{
                display: flex; flex-direction: column; align-items: center; gap: 1.4286vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw; padding: 2.8571vw 0;
                .item{
                    &-img{
                        position: relative; width: 18.0000vw; height: 8.8571vw;
                        img{@include imgSize;}
                    }
                    &-txt{
                       font-weight: 600;
                    }
                }
            }
        }
    }
}

// (팝업) 제휴카드 선택
.card-affiliate {
    .box-btn{
        display: flex; flex-direction: column; gap: 2.1429vw;
    }
    .btn-radio-type2{
        padding: 2.8571vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;
        &.card-radio {
            padding: 4.2857vw 2.8571vw;
            .card-detail{
                display: flex; gap: 2.1429vw;
                .card {
                    &-thumb {
                        max-width: 12.8571vw; width: 100%;
                        img{width: 100%;}
                    }
                    &-txt {
                        display: flex; flex-direction: column; gap:0.7143vw; width: calc(100% - 14.2857vw);
                        .card-title{
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .notification{
        padding-top: 1.4286vw; margin-top: 2.8571vw; border-top: 0.1429vw solid $gray02;

    }
    .bottom-btn{
        margin-top: 5.7143vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw; padding: 2.8571vw;
        .info-txt{
            @include text(400,2.0000vw,1.43,$gray05,-0.0714vw); margin-bottom: 0.7143vw; display: flex; justify-content: space-between; align-items: flex-end;
            .ico-arrow-right{margin-bottom: 0.7143vw;}
        }
        strong{font-weight: 700;}
    }
}

// (팝업) 무이자 할부 카드 안내
.interest-free{
    .card-desc-list{
        margin-bottom: 2.8571vw; padding: 2.8571vw; background-color: $gray01; border-radius: 1.4286vw;
        li{
            & + li{
                margin-top: 0.7143vw;
            }
        }
        * { font-weight: 400;}
    }
}
//(팝업) 휴대폰 비교하기
.phone-comparison{
    .product-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex:1;
        gap:2.8571vw;
        li{
            width:100%; text-align: center;
            .item-img{
                position: relative; width:12.8571vw; height:12.8571vw; margin:0 auto;
                img{
                    @include imgSize;
                }
            }
            .item-tit{
               display: block; margin:2.1429vw 0 2.8571vw;
            }
            .btns{
                padding:2.2857vw 0; color: $white;
            }
        }
    }
    .detail-sec{
        margin-top:2.8571vw; border: none;
        .sub-conts{
            padding: 0;
            & + .sub-conts{margin-top: 5.5000vw;}
            .detail-sec-tit{
                text-align: left; margin-bottom: 2.8571vw;
            }
            .detail-sec-desc{
                display: flex;
                justify-content:center; gap:2.1429vw; flex: 1;

                dl{
                    padding:3.5714vw 3.0000vw;
                    background: $gray01;
                    border-radius:1.4286vw;
                    text-align: center;
                    width:100%;
                    dd{
                         margin:0.7143vw 0 3.5714vw;
                        .btn-box{
                            display: flex; flex-wrap: wrap; gap:2.1429vw; margin-top:2.1429vw;
                            .btns.white{
                                padding:1.5714vw 0;
                                background: $white; border: 0.1429vw solid $black;
                            }
                        }

                    }
                }
            }
        }
    }
}

// (팝업) 지원금 반환금 및 정산금 안내
.refund-guide{
    .text-wrap{
        & + .text-wrap{margin-top: 7.1429vw;}
    }
    .contents-title{
        margin-bottom: 2.8571vw;
    }
    .notification{
        margin-top: 1.4286vw;
    }
    ol{
        display: flex; flex-direction: column; gap: 4.2857vw;
    }
}

// (팝업) 오늘배송 서비스 지역안내
.delivery-today{
    .bang-notice{margin-top: 1.4286vw;}
    .table-more{
        .box-table{
            margin-top: 4.2857vw; height: 92.4286vw; overflow: hidden;
            &.on {
                height: auto;
            }
        }
        .box-btn{
            display: block; margin-top: 1.4286vw;
            .btn-text-more{
                &.on{display: none;}
            }
        }

    }
}
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .container{
        .sub-conts{
            &.product-info{padding-top: 40px;
                .info-wrap{
                    .model-num{margin: 5px 0 30px;}
                    & + .cardList-wrap{margin-top: 30px;}
                }
                .box-color-name{margin-top: 15px;
                    strong{font-weight: 400;}
                }
                .btn-radio-group{
                    &.group6{
                        .btn-radio{
                            label{min-height: 60px;}
                        }
                    }
                }
                .payment{
                    .pay-amount{padding-top: 20px;}
                }
                .pay-amount{
                    color: #662d91 !important;
                    .origin-pay{font-size: 15px;}
                }
                .payment-detail{
                    .accordian{
                        .acc-conts-type2{
                            & > ul{border-width: 1px; padding-top: 20px; gap: 20px;
                                & > li{
                                    padding: 0 15px;
                                    &.result-bill{padding: 20px 15px;
                                        b{
                                            span{
                                                &.dc-prc{font-size:22px; letter-spacing: -0.5px;}
                                            }
                                        }
                                    }
                                    b{
                                        span{
                                            &.dc-prc{font-size:16px; letter-spacing: -0.5px;}
                                        }
                                    }
                                }
                            }
                        }
                        .bang-notice{margin-top: 10px;}
                        .tooltip-wrap{
                            .box-tooltip{
                                .txt-tooltip{left: -76px;}
                            }
                        }
                    }
                }
                .btn-sum{
                    border-width: 1px;
                    .payment-sum{padding: 0 20px; min-height: 85px; align-items: center;
                        .sum{
                            align-items: center;
                            strong{color: #662d91;}
                            .dc-prc{font-size: 22px; letter-spacing: -0.5px;}
                        }
                        .ico-anchor{width: 20px; height: 20px;}
                    }
                }
                .banner-randombox-wrap{
                    left: 20px; bottom: 150px;
                }
            }
        }
    }
    .phone{
        .info-detail{
            h3{margin-bottom: 10px;}
            .review-slide{
                height: 300px;
                .btn-share{
                    width: 30px; height: 30px; border-width: 2px;
                }
                .slick-default{
                    height: 230px;
                    .slick-list{
                        .slick-slide{
                            a{
                                img{width: 230px; height: 230px;}
                            }
                        }
                    }
                }
            }
            .info-wrap{margin-top: 40px;}
            .payment-list{
                & + .box-btn{margin-top: 10px;}
            }
            .btn-radio-group{
                .btn-radio{
                    label{min-height: 45px; line-height: 18px;
                        & > em{margin-top: 5px;}
                    }
                }
            }
        }

        .sub-conts.product-info {
             .radio-group-wrap {
                 .btn-radio-group.ucash{
                    .btn-radio label {
                        min-height: 115px;
                        line-height: 18px;        
                    }
                 }
                 .txt-box {
                    height: 110px;  padding: 35px 0;border:1px solid #ddd;
                    p {
                        font-size:16px;
                        strong {font-size:16px;}
                    }
                }
            }
        }


        .payment-detail{
            .pay-month-bill{margin-bottom: 15px;}
        }
        .join-info{
            .page-tit{
                .box-btn{margin-top: 20px;}
            }
            .step-list{margin-top:15px; gap: 15px;
                & > li{
                    border-radius: 8px; padding: 20px; width: calc(50% - 10px);
                    .ico-img{width: 60px; height: 60px;}
                    .step{color: #662d91; margin-bottom: 5px;}
                }
            }
            .bang-notice{
                margin-top: 10px;
                & + .box-btn{margin-top: 15px;}
            }
            .num-process-wrap{margin: 15px 0 30px;}
            .notification{
                &.round-box{border-radius: 8px; border-width: 1px; padding: 20px; margin-top: 15px;}
            }
        }
        .phone-list{
            .box-slide{height: auto; margin-bottom: 10px;}
            .box-filter{
                .btn-extend{border-width: 1px; padding: 13px 20px;
                    &::after{width: 20px; height: 20px; right: 20px;}
                }
            } 
            .accordian{
                border-width: 1px;
                .acc-trigger{padding: 13px 20px;}
                & > button{
                    &::after{right: 20px;}
                }
                .acc-conts{border-width: 1px; padding: 30px 20px;}
            }
            .box-select{margin: 20px 0;}
            .product-imgCard{
                .cardList{padding: 30px 20px;}
                .item-right{
                    .item-price{
                        .dc-prc{font-size: 20px;}
                    }
                }
            }
            .box-btn{margin-top: 40px;}
        }
        .support-device-filter{
            margin-top: 40px;
        }
        .support-device-list{
            .filter-item-wrap{padding: 30px 20px; border-width: 1px;}
            .cardList-wrap{margin-top: 15px;
                .cardList{padding: 30px 20px; border-radius: 8px; border-width: 1px;
                    .item-top{
                        padding: 0 0 25px 0;
                        .item-img{width: 100px; height: 100px;}
                    }
                    dl{border-width: 1px; padding: 25px 0 0 0;
                        dt, dd{padding-bottom: 10px;}
                    }
                    .box-btn{margin-top: 25px;}
                }
            }
            .notice{
                .accordian{
                    &.type-01{
                        & + .btn-text-arrow{margin-top: 10px;}
                    }
                }
            }
        }
        .filter-item-wrap{
            dl{gap: 15px 0;
                dd{
                    .c-purple{margin-top: 15px;}
                }
            }
        }
        .phone-recommend-result{
            .result-item-wrap{
                margin-bottom: 20px;
                & + .box-btn{margin-bottom: 40px;}
            }
            section{
                & + .box-btn{margin-top: 40px;}
            }
        }
        .product-imgCard{
            .cardList{padding: 30px 20px;}
        }
        .rate-plan-choose{
            .month-data{
                margin-bottom: 50px;
                & > ul{
                    & > li{
                        & > h2{margin-bottom: 15px;}
                    }
                }
            }
            .info-detail{
                .pay-amount{padding-top: 20px;}
            }
        }
        .range-slider{
            .irs{
                & > .irs{height: 10px;}
            }
            .irs--flat{
                height: 54px !important;
                .irs-line{
                    height: 30px; top: -1.5px;
                    &::after{top: 11px; height: 2px;}
                }
                .irs-handle{width: 30px; height: 30px; top: -5px;}
            }
            .slider-dots{width: 8px; height: 8px; top: 6px;}
            .irs-grid-text{font-size: 14px;}
        }
    }
    .phone-recommend{
        .radio-group{
            li{margin-bottom: 10px;}
        }
    }
    .phone-gifts{
        .gift-list{
            & + .gift-list{margin-top: 30px;}
            .main-txt{margin-bottom: 15px;}
            .small-txt{margin: -10px 0 15px;}
            & > ul{gap: 15px;}
            ul{
                li{
                    gap: 10px; border-width: 1px; border-radius: 8px; padding: 20px 0;
                    .item-img{width: 130px; height: 65px;}
                }
            }
        }
        .main-txt{margin-bottom: 15px;}
        .notification-wrap{margin-top: 30px;}
    }
    .refund-guide{
        .notification{margin-top: 10px;}
        ol{gap: 30px;}
        .text-wrap{
            & + .text-wrap{margin-top: 30px;}
        }
        .contents-title{margin-bottom: 15px;}
    }
    .stock-noti{
        .box-input{
            & + .box-flex{margin-top: 20px;}
        }
        .box-flex{
            &.colum{margin-top: 20px;}
        }
        .cardList-wrap, .check-wrap{margin-top: 30px;}
    }
    .notice-guide{
        .detail-sec{margin-top: 40px;}
    }
    .interest-free{
        .card-desc-list{padding: 20px; border-radius: 8px; margin-bottom: 20px;}
    }
  
}
.vreview-overlay-modal.open {z-index: 110 !important;}