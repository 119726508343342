@charset "UTF-8";


// 내정보 공통
.my {
    .mb80{
        margin-bottom: 11.1111vw;
    }
    .box-my-use {
        padding: 0 5.5556vw 11.1111vw;
        .box-select{
            margin-bottom: 8.3333vw;
        }
        .user-txt{
            @include usageTitle;
            & > span{@include fontInherit; color: $purple;}
        }
        .box-btn{
            margin-top: 0.8333vw;
            .user-product {
                display: flex; align-items: center; justify-content: space-between; margin-bottom: 4.1667vw;
                @include subContsTitle;font-weight: 700;
            }
        }
        .ico-wrap{
            display: flex;gap: 4.1667vw;
            span{
                display: block;position: relative; padding-left: 7.6389vw;
                @include smallTxt; font-weight: 600;
                &.ico-call {
                    @include backgroundImg('/assets/images/icon/ico-call.png', left center,4.1667vw 4.1667vw); padding-right: 4.1667vw; width: auto;height:auto;
                    &::after {
                        content: ''; display: block; position: absolute; top: 50%; right: 0; width: 0.2778vw; height: 4.1667vw; background-color: $gray02; transform: translateY(-50%);
                    }
                }
                &.ico-sms {
                    @include backgroundImg('/assets/images/icon/ico-sms.png', left center,4.1667vw 4.1667vw);width: auto;height:auto;
                }
            }
        }
    }
    .access-error-type{
        .popup-header{padding-bottom: 0;}
        .popup-body{
            padding-top: 0 !important;
            p{
                @include smallTxt;
                & + p{
                    margin-top: 1.3889vw;
                }
            }
        }
    }
    // 요금제변경
    .rate-plan{
        &-change {
            .is-filter-warp{
                .is-filter-list{padding: 0 5.5556vw 0 5.5556vw;width: 100vw;}
            }
            .cardList-wrap{
                & + section {
                    margin-top: 11.1111vw;
                    .main-txt{margin-bottom: 4.1667vw;}
                    .is-filter-list{width: 100%;}
                }
                .one-title{
                    dt{
                        strong{
                            display: block; margin-bottom: 1.3889vw;
                        }
                    }
                    .basic-charge {margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    .dot-txt {
                        position: relative; margin-top:1.3889vw;padding-left:2.0833vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #666;
                        &::before {content:''; position: absolute;left:0;top:1.6667vw;width:0.8333vw;height:0.8333vw;background: #666;border-radius: 50%;}
                        a {display:inline-block;width:auto; margin-top:2.7778vw;}
                    }
                }
                .box-table {
                    margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;
                   .table-title {font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left; color: #666;}
                   table td {color:#666;}
                   table +.notification {margin-top:2.7778vw;}
                }
            }
            .box-tab {margin:2.7778vw 0 5.5556vw 0;}
        }
        &-no-change {
            .cardList-wrap{
                margin-bottom: 11.1111vw;
                .one-title{
                    dt{
                        strong{
                            display: block; margin-bottom: 1.3889vw;
                        }
                    }
                }
            }          
        }
        &-popup{
            &-01 {
                .c-unit{
                    p{@include smallTxt;}
                }
            }
            &-02 {
                .c-unit{
                    p{@include smallTxt;}
                }
            }
            &-05{
                .table-title {
                    @include mainTxt; margin-bottom: 2.7778vw;
                }
                .table-type-04{
                    .center{ text-align: center; @include defaultTxt;}
                    .discount-pay{
                        font-size: 5.5556vw;
                    }
                }
                .pay-amount{
                    padding-top: 0;
                }
            }
            &-06{
                .c-unit{
                    &:first-child{
                        border-bottom:0.2778vw solid $gray02; padding-bottom: 8.3333vw; margin-bottom: 8.3333vw;
                    }
                }
               .unit-sec-tit{
                    @include smallTxt;
               }
            }
            &-barogo{
                .c-unit{
                    & + .c-unit{margin-top: 6.9444vw;}
                    .unit-sec-tit{margin-bottom: 0;}
                }
            }
        }
    }

    .rate-plan-no-change{
        .cardList-wrap{          
            .cardList{
                .dot-txt {
                    position: relative; margin-top:1.3889vw;padding-left:2.0833vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #666;
                    &::before {content:''; position: absolute;left:0;top:1.6667vw;width:0.8333vw;height:0.8333vw;background: #666;border-radius: 50%;}
                    a {display:inline-block;width:auto; margin-top:2.7778vw;}
                }
            }
        }            
    }

    //명의변경
    .subscript-name-change{
        .box-select{margin-bottom: 11.1111vw;}
        & > section {
            margin-top: 8.3333vw;
        }
        .sub-contents-title{
            display: block; margin-bottom: 1.3889vw;
            & + .main-txt{margin-top: 5.5556vw;}
        }
        .main-txt{
            display: block; margin-bottom: 1.3889vw;
        }
        p{
            @include smallTxt;
            & + .num-process-wrap{margin-top: 2.7778vw;}
        }
        .num-process-wrap{
            & + .notification{margin-top: 2.7778vw;}
            & + .btns {margin-top: 4.1667vw;}
        }
    }
    .subscript-name-certi{
        .input-group {
            margin-top: 5.5556vw;
        }
    }
    .subscript-name-exist{
        .box-input {
            & + .box-input {margin-top: 5.5556vw;}
        }
    }
    .subscript-info-set{
        .is-filter-warp{
            .is-filter {
                .is-filter-list{
                    width: 100%; padding: 0 5.5556vw 0 5.5556vw;
                }
            }
        }
    }
    .subscript-name-complete{
        .page-tit{
            padding-bottom: 40.2778vw; margin-bottom: 5.5556vw;
            @include backgroundImg('/assets/images/icon/ico-member-change.svg', center bottom,36.1111vw 36.1111vw);
        }
        .cardList-wrap{
            .cardList {
                dt{width: 50%;}
                dd{width: 50%;}
            }
            & + .cardList-wrap{margin-top: 8.3333vw;}
        }
        .notification{margin-top: 11.1111vw;}
    }
    .subscript-name-new{
        .box-input {
            & + .box-input {margin-top: 5.5556vw;}
        }
        .radio-group-wrap{margin-top: 5.5556vw;}
    }
    .phone-installment-bottomsheet{
        .cardList-wrap{
            .cardList {
                dt{width: 30%;}
                dd{width: 70%;}
            }
            p{font-weight: 400;margin-top: 2.7778vw;}
        }
    }
    .subscript-name-assignee{
        .agree-wrap{
            margin-top: 0; padding-bottom: 8.3333vw;margin-bottom: 8.3333vw; border-bottom: 0.2778vw solid $gray02;
        }
    }
    .subscript-name-assignee-new{
        & > .box-input {
            & + .box-input {margin-top: 5.5556vw;}
        }
        .input-depth{margin-top: 5.5556vw;}
    }
    .subscript-name-assignee-exist{
        & > .box-input {
            & + .box-input {margin-top: 5.5556vw;}
        }
    }
    .subscript-write-info{
        & > section{
            & + section {margin-top: 5.5556vw;}
        }
        .notification{margin-top: 11.1111vw;}
        .info-card{
            .box-input {
                & + .box-input{margin-top: 5.5556vw;}
            }
            .input-group{margin-top: 5.5556vw;}
            .box-btn{margin-top: 4.1667vw;}
        }
        .info-account{
            display: none;
            .box-select {
                & + .box-input{margin-top: 5.5556vw;}
            }
            .box-btn{margin-top: 4.1667vw;}
        }
    }

    //번호변경
    .num-change-info{
        & > section{
            margin-top: 11.1111vw;
            .sub-contents-title{display: block; margin-bottom: 1.3889vw;}
            .main-txt{display: block;margin-bottom: 2.7778vw;}
        }
        .notification{
            .box-btn{margin-bottom: 2.7778vw;}
        }
    }
    .num-change-want{
        & > section {
            margin-top: 5.5556vw;
        }
        .box-input-phone{
            .input-wrap{
                &:nth-of-type(1){
                    width: 21.6667vw;
                }
                &:nth-of-type(n+2){
                    margin-left: 4.1667vw;max-width: none;width: calc((100% - 30vw) / 2);
                    input{padding: 4.4444vw 3.9667vw;}
                }
            }
        }
        .radio-group-wrap{
            .btn-radio {
                & + .btn-radio{
                    margin-top: 2.7778vw;
                }
            }
            .box-btn{
                margin-top: 2.7778vw;
            }
        }
    }
    .num-change-complete{
        & > section {
            margin-top: 8.3333vw;
            .main-txt {
                display: block; margin-bottom: 1.3889vw;
                & + p{
                    @include smallTxt;
                }
            }
            .num-process-wrap{
                margin-top: 5.5556vw;
                & + p{
                    margin-top: 2.7778vw;@include smallTxt;
                    & + .box-btn{margin-top: 4.1667vw;}
                }
            }
        }
        .box-ico-txt{
            background-image: url('/assets/images/icon/ico-num-change.svg');
            & + p{margin-top: 11.1111vw;}
        }
        .box-btn.revert {
            margin-top:13.8889vw;text-align:center;
            .change-revert {font-size: 4.1667vw;line-height: 1.5;letter-spacing: -0.0444vw;color: #666; text-decoration: underline;}
        }
        .txt {font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
    }
    .box-note {
        &>button {display:block;width:100%;position:relative;margin-bottom:2.7778vw;@include defaultTxt; text-align:left;}
        .acc-conts {
            padding:4.4444vw 5.5556vw;border-radius:2.7778vw;background:$gray01;
            .notification {
                li {
                    .btn-text-arrow {margin-top:1.3889vw;line-height: 1.4; font-size:4.1667vw;}
                }
            }
        }
    }
    .cardList-wrap{
        padding: 0;
    }

    .cont-tit{
        @include mainEmphasize;
        margin-bottom: 2.0833vw;
    }
    // 휴대폰 소액결제내역
    .bill-history{
        .cardList-wrap{margin-top: 2.0833vw;}
        .page-title{
            & + .box-select{margin-top: 2.2222vw;}
        }
    }
    .bill-plan{
        margin: 0 0 11.1111vw 0;
        h1{
            margin: 5.5556vw 0 4.1667vw 0; font-weight: 700;
            span{
                display: block; margin-bottom: 1.3889vw;
                @include defaultTxt; font-weight: 600;
            }
        }
        ul{
            li{
                display: inline-flex; padding-left: 5.5556vw;
                @include defaultTxt; color: $gray05;
                &:nth-child(1){
                    @include backgroundImg('/assets/images/icon/ico-call-fill.svg', 0 50%, 5.5556vw)
                }
                &:nth-child(2){
                    @include backgroundImg('/assets/images/icon/ico-sms-fill.svg', 0 50%, 5.5556vw)
                }
            }
        }
        .bill-plan-top{
            display: flex; justify-content: space-between; align-items: center; margin-bottom: 4.1667vw;
            .chip-wrap {
                img{height: 5vw;}
            }
            .button-wrap{
                display: flex; gap: 2.7778vw;
                button{
                    width: 5.5556vw; height: 5.5556vw;
                }
                .ico-num-setting{
                    @include backgroundImg('/assets/images/icon/ico-num-setting.svg',center center,contain);
                }
                .ico-num-plus{
                    @include backgroundImg('/assets/images/icon/ico-num-plus.svg',center center,contain);
                }
            }
        }
    }
    // 이용한도 설정
    .bill-setting-limit{
        padding-top: 11.1111vw;
        .money-right{
            margin-top: 2.7778vw;
            dt{
                width:60% !important;
            }
            dd{
                width:40% !important;
            }
        }
        .main-txt{margin-bottom: 1.3889vw;}
        section + section{margin-top: 11.1111vw;}
        .box-select{
            & + section{margin-top: 5.5556vw;}
        }
        .box-input{
            margin-top: 5.5556vw;
            .input-unit{
                top:15.2944vw;
            }
            .bang-notice{
                margin-top: 2.7778vw;
            }
        }
        .accordian{
            margin-top: 11.1111vw;

        }
    }
    //청구요금 조회
    .bill-inquiry{
        .month{
            margin-bottom: 11.1111vw; padding: 5.2778vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;
            .loading-txt{
                border: 0;
            }
            .period{
                margin-bottom: 4.1667vw;
                dt{
                    margin-bottom: 1.3889vw;
                    @include mainTxt;
                }
                dd{
                    color: $gray04;
                }
            }
            .detail{
                display: flex; justify-content: space-between; margin-bottom: 4.1667vw;
                strong{
                    @include contsTitle; color: $purple;
                    span{
                        @include contsTitle;
                    }
                }
                a{
                    display: inline-flex; align-items: center;
                }
            }
            .how{
                display: flex; flex-wrap: wrap; margin-bottom: 5.5556vw; padding-top: 5.5556vw; border-top: 0.2778vw solid $gray02;
                dt{
                   display: inline-flex; align-items: center; width: 30.5556vw;
                   &:first-of-type{
                    margin-bottom: 2.7778vw;
                   }
                }
                dd{
                    display: inline-flex;
                    &:first-of-type{
                        margin-bottom: 2.7778vw;
                    }
                    a{
                        display: inline-flex; align-items: center; width: 47.2222vw; height: auto;
                        @include defaultTxt;
                        &.ico-arrow-right{
                            background-size: 5.5556vw 5.5556vw; padding-right: 6.9444vw;
                        }
                    }
                }
            }
        }
        .past{
            margin-bottom: 11.1111vw;
            h2{
                margin-bottom: 4.1667vw;
                @include defaultTxt;
            }
            ul{

                li{
                    border-top: 0.2778vw solid $gray02;
                    a{
                        width: 100%; height: auto; background-position: 81.9444vw 50%;
                        background-size: 5.5556vw 5.5556vw; padding-right: 8.3333vw; display: flex; justify-content: space-between; align-items: center;
                        span{
                            display: inline-block;
                            @include defaultTxt; line-height:16.3889vw; text-align: center;
                            &:nth-child(1){
                                width: 29.1667vw;
                            }
                            &:nth-child(2){
                                width: 20.8333vw;
                                // margin-right: 6.9444vw;
                            }
                            &:nth-child(3){
                                text-align: right; flex-shrink: 0;
                            }
                        }
                    }
                    &.txt-rd{
                        a{
                            span{
                                color: $red;
                            }
                        }
                    }
                    &:last-child{
                        border-bottom:0.2778vw solid $gray02;
                    }
                }
            }
        }
    }

    //요금 상세내역
    .bill-detail{
        .page-tit {
            .default-txt{display: block; margin-bottom: 1.3889vw;}
        }
        .table-title{
            margin-bottom: 8.3333vw;
            h2{
                @include mainTxt;
                span{ @include fontInherit;}
            }
            .bang-notice{
                margin-top: 1.3889vw; @include smallDepthTxt;
               span{@include fontInherit;}
            }
        }
       .btn-box{
         margin-top: 11.1111vw;
         li{
            & + li{
                margin-top: 4.1667vw;
            }
         }
       }
        .accordian{
            margin-top: 11.1111vw;
            .notification {
                .box-btn{margin: 1.6667vw 0;}
            }
        }
        .cardList-wrap {
            margin-top: 2.7778vw;
            .table-result {
                dl {
                    align-items: center;
                    dt{
                        @include mainTxt; width: 50%;
                    }
                    dd {
                        @include contsTitle; width: 50%; text-align: right;
                        *{@include fontInherit;}
                    }
                }
            }
        }
    }

    // 청구서 수신방법 변경
    .bill-method-change{
        .contents-title{
            margin-bottom: 5.5556vw; font-weight: 700;
        }
        .cardList-wrap{
            margin-bottom: 11.1111vw;
        }
    }

    // 청구서 재발행 신청
    .bill-reissue{
        .notification{
            margin-top: 2.7778vw;
        }
        .box-select{
            margin-bottom: 11.1111vw;
        }
        .inquir-box{
            .default-txt{
                margin-bottom: 2.2222vw; @include mainTxt;
            }

            & + .inquir-box {margin-top:8.3333vw;}
        }
        .receive-invoice {
            margin:8.3333vw 0 11.1111vw 0;
            .box-flex {
                margin-top:8.3333vw;
                & + .box-input {margin-top: 2.2222vw;}
            }
        }
    }

    // 청구서 재발행 신청서 작성
    .bill-reissue-form{
        .box-select{
            margin-bottom: 11.1111vw;
        }
        .inquir-box{
            .default-txt{
                margin-bottom: 2.2222vw; @include mainTxt;
            }
        }
    }

    .bill-reissue-phone{
        .page-tit{
            & + .box-flex{
                margin-bottom: 11.1111vw;
            }
        }
    }

    // 요금 즉시 납부
    .bill-immediate{
        .info-txt{
            @include defaultTxt; margin-top: 8.3333vw;
        }
        .month{
            padding: 5.2778vw; padding-top:8.3333vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;
            .total{
                display: flex; justify-content: space-between; margin-bottom: 5.5556vw; padding:0 5.1389vw; border-bottom: 0.2778vw solid $gray02; @include backgroundImg('/assets/images/icon/ico-plus-big.svg', 50% 0 , 11.1111vw);
                dl{
                   margin-bottom: 8.3333vw;  text-align: center;
                    dt{
                        margin-bottom: 1.3889vw; @include defaultTxt;
                    }
                    dd{
                        @include mainTxt;
                        span{
                            @include fontInherit;
                        }
                    }
                }
            }
            h2{
               display: block; margin-bottom: 5.5556vw; @include mainTxt;
            }
            .period{
                margin-bottom: 5.5556vw;
                dt{
                    margin-bottom: 1.3889vw;
                    @include mainEmphasize;
                }
                dd{
                    color: $gray05;
                }
            }
        }
        .detail{
            display: flex; justify-content: space-between;
            strong{
                @include contsTitle; color: $purple;
                span{@include fontInherit;}
            }
            a{
                display: inline-flex; align-items: center;
            }
        }
        .accordian{
            margin-top: 5.5556vw;
        }
        .instantly {
            padding:6.25vw 5.5556vw;
            .detail  .tit-detail {
                display: inline-flex;
                align-items: center;
                line-height: 1.3;
                color:#191919;
                font-size:4.4444vw;
                font-weight: 600;
                letter-spacing: -.0833vw;
            }
        }
    }

    //요금 즉시 납부 카드 소유주 정보 입력
    .bill-immediate-payment{
        fieldset{
            margin-bottom: 6.9444vw;
            > div{
                margin-bottom:5.5556vw;
            }
            .inquir-box{
                h2{
                    margin-bottom: 2.2222vw;
                }
                .txt{margin-top:2.7778vw;}
            }
        }
        .default-txt{@include mainTxt;}

        .credit-card-certified {
            margin:11.1111vw 0 0 0;
            .sb-tite {  font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}            
            .txt-box {
                margin-top:2.0833vw;
                p{
                    font-size: 4.1667vw;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;
                    & + p {margin-top:0.6944vw;}
                }
                .sb-txt {
                    color:#666;font-weight: normal;
                }
            }
            .btn-check  {
                margin:5.5556vw 0 8.3333vw 0;
                label {width:100%; 
                    span {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                }
            }
            .appli-identification {   
                margin-top:2.0833vw;            
                .identifi-box {                 
                    .ico-list {
                        justify-content: center;align-items: center;gap:2.7778vw;
                        li {width:9.1667vw;height:100%;}
                    }
                }
            }
        }

    }

    // 신용카드 결제 정보 입력
    .bill-credit-form{
        fieldset{
            .box-input{
                .input-unit{
                    top: auto; bottom: 4.4444vw; transform: translateY(0);
                }
                .input-label{
                    span{
                        display: block; color: $gray05;
                    }
                }
                .bang-notice{
                    margin-top: 1.3889vw;
                }
            }
            .box-select{
                .select-label{
                    span{
                        color: $gray05;
                    }
                }
                .bang-notice{
                    margin-top:2.7778vw;
                }
            }
            > div{margin-bottom: 4.1667vw;}
        }
        .default-txt{@include mainTxt;}
    }
    .bill-change-data{
        .list-header{
            strong{
                @include subContsTitle;
            }
        }
    }
    // 앱마켓 결제 내역
    .bill-app {
        .page-tit{
            & > .notification{
                margin-top: 1.3889vw;
            }
        }
        .default-txt{margin-bottom: 2.7778vw; @include mainTxt;}
        .select-label{@include mainTxt;}
        .inquir-box{
            margin-bottom:5.5556vw;
            .btn-radio label{
                display: flex; align-items: center; justify-content: center;min-height: 12.2222vw; padding: 0; font-size: 3.8889vw;
            }
        }
        .box-total {
            margin-top: 2.7778vw;
            .total-wrap {
                display: flex; justify-content: space-between; align-items: center; border-radius: 1.9444vw; border: 0.2778vw solid $gray02; padding: 5.5556vw;
                & > strong{@include mainTxt;}
                & > span{
                    @include mainTxt;
                }
            }
        }
        .box-list{margin-top: 8.3333vw}
        .cardList-wrap{
            .cardList{
                dl{
                    dt{
                        width:35%;
                    }
                    dd{
                        width:65%;
                        span{@include fontInherit; display: block;}
                    }
                }
            }
            & + .box-btn {
                margin-top: 2.7778vw;
            }

        }
        .accordian{margin-top: 11.1111vw;}
    }

    //휴대폰 소액 결제 차단 / 해제 안내
    .bill-release-info{
        section{
            margin-top:11.1111vw;
        }
        .text-btn-gray{
            p{
                @include smallTxt;
                .agree{
                @include smallTxt; color:$black; font-weight: 600; margin-left:4.1667vw;
                }
            }

        }
        .cardList{
            margin-top:4.1667vw;
        }
        .cardList-title{
            @include mainTxt;
        }
        .inquir-box{
            margin-top:4.1667vw;
            .default-txt{
                margin-bottom:2.2222vw; @include mainTxt;
            }
        }
    }

    // 휴대폰 정보 변경 안내
    .subscript-phone-change{
        .speech-bubble{
            margin-bottom: 8.3333vw; padding: 5.5556vw; background-color: $bg; border-radius: 2.7778vw;
            strong{@include defaultTxt; display: block; margin-bottom: 1.3889vw;}
        }
        .process-box{
            .default-txt{margin-bottom: 2.7778vw;}
        }
        .num-process-wrap{
            li{
                padding-bottom: 8.3333vw;
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
        .no-regist{
            .img{
                @include backgroundImg('/assets/images/icon/ico-calender-60.svg',center center, contain); width: 36.1111vw; height: 36.1111vw;  margin:0 auto 4.1667vw;
            }
            p{
                margin-bottom: 2.7778vw; text-align: center; @include smallTxt;
            }
            dl{
                text-align: center;
                dt{
                    margin-bottom: 1.3889vw;
                }
            }
        }
    }

    // 내 가입정보 안내
    .my-join-info{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 8.3333vw;}
            .cardList{
                dl{gap: 4.1667vw 2.7778vw;}
                dt{width: calc(40% - 1.3889vw); padding: 0;}
                dd{width: calc(60% - 1.3889vw); padding: 0;}
                & + .box-btn{
                    margin-top: 2.7778vw;
                }
                &.using-goods {
                    .box-table {
                        margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;
                       .table-title {font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left; color: #666;}
                       table td {color:#666;}
                       table +.notification {margin-top:2.7778vw;}
                    }
                    .goods-title {
                        margin-bottom:1.3889vw;
                        .goods-name { font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                    }
                    .item-top {
                        gap:1.3889vw;
                        .btn-box {
                            margin-bottom: 1.3889vw;
                            .btn-text-arrow {
                                & + .btn-text-arrow {margin-left:2.7778vw;}
                            }
                        }
                    }
                    .basic-charge {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #191919;}
                    .dot-txt {
                        position: relative; margin-top:1.3889vw;padding-left:2.0833vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #666;
                        &::before {content:''; position: absolute;left:0;top:1.6667vw;width:0.8333vw;height:0.8333vw;background: #666; border-radius: 50%;}
                        a {display:inline-block;width:auto; margin-top:2.7778vw;}
                    }
                }
            }
            .item-bottom{
                .btns{
                    background: none; @include mainTxt; padding: 0;
                }                
            }
          
            .no-list {
                padding: 5.5556vw;
                .item-top{padding: 0; align-items: center;}
                .goods-title{
                    @include mainEmphasize;
                }
            }
            .box-btn.btn-group  {
                display: flex; justify-content: space-between;margin-top:3.0556vw;
                .btn-text-arrow {letter-spacing:-.3883vw;}
                .btn_link {
                    display: flex; width: 53%; flex-direction: row-reverse; flex-wrap: nowrap; align-items: center; 
                    a {font-size:4.1667vw;}
                }
            }
        }
        .box-my-info{
            margin-bottom: 11.1111vw;
            * {@include defaultTxt;}
            .contents-title{
                display: block; @include subContsTitle; margin: 1.3889vw 0 2.7778vw;
                .txt-vt{@include fontInherit;}
            }
            .date{@include smallTxt;}
        }
        .bang-notice{margin-top: 1.3889vw;}
    }
    // 휴대폰 정보 등록
    .subscript-phone-info{
        .page-tit{
            margin-bottom: 5.5556vw;
        }
        h2{
          margin-top: 11.1111vw;  @include subContsTitle;
        }
        .os-wrap{
            margin-top: 5.5556vw;
            .default-txt{margin-bottom: 2.2222vw;}
        }
        .phone{
            &-ios{display: none;}
        }
        .box-input{
            & ~ .box-input{margin-top: 5.5556vw;}
            .btn-text-arrow{@include defaultTxt; margin-top: 3.0556vw;}
            .bang-notice{
                margin-top: 2.7778vw;
            }
        }
        .box-select{margin-top: 5.5556vw;}
        .btn-radio-group{
            & + .box-input,& + .box-select {margin-top: 5.5556vw;}
        }
        .serial-form {
            .serial-guide {
                margin:4.1667vw 0 8.3333vw 0;
                .tit {display: block; font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                .btn-link {margin-top:3.0556vw}
            }
            .box-input + .box-btn {margin-top:4.1667vw;}
            .box-btn + .os-wrap {margin-top:11.1111vw;}
            .box-select + .box-input {margin-top:5.5556vw;}
        }

    }

    // 휴대폰 정보 등록 완료
    // .subscript-complete{
    //     .icon-img{
    //         display: block; width:20.8333vw; height:20.8333vw; margin:13.1944vw auto 0;
    //     }
    //     .end-box{
    //          margin-top: 5.5556vw; padding: 5.5556vw; background-color: $bg; border-radius: 2.7778vw;
    //         strong{
    //             display: block; margin-bottom: 1.3889vw; @include defaultTxt;
    //         }
    //     }
    // }
    //청구서 재발행 휴대폰 번호 얼럿창
    .bill-reissue-layerPopup{
        text-align: center;
    }
    .bill-inquiry-layerPopup{
        text-align: center;
        .one-title{
            ul{
                li{
                    margin-bottom: 1.3889vw;
                    a{color:#0054ff;}
                }
            }
        }
    }
    // 모델명, IMIE 확인
    .subscript-serial-number{
        &>.inner{
            padding-top: 20vw;
            .box-tab {
                .tab-btn-wrap {margin-bottom:11.1111vw;}
            }
            .cardList-wrap {
                &+.cardList-wrap {margin-top:11.1111vw;}
                .cardList {
                    &.one-title {padding:5.5556vw;}
                    dl {
                        dt {

                            &+dt {margin-top:4.1667vw;}
                        }
                    }
                }
            }
            h2 {
                display:block;margin:11.1111vw 0 2.7778vw;@include subContsTitle;
                &:first-of-type {margin-top:0;}
            }
            .c-unit{
                padding: 5.5556vw; border: 0.2778vw solid $gray02; border-radius:2.7778vw;
                .unit-tit {
                    margin-bottom:0;
                    &+.unit-tit {margin-top:4.1667vw;}
                    h3 {display:block;@include mainTxt;}
                    p {margin-top:1.3889vw;}
                }
                .unit-img {margin-top:4.1667vw;}
            }
        }
    }

    //지정번호 등록 / 변경
    .subscript-change{
        .phone-desc{
            margin-bottom: 8.3333vw;
            h2{
               @include defaultTxt;
            }
            p{
                @include subContsTitle; margin-top: 1.3889vw;
            }
        }
    }
    //선택약정 가입 신청서
    .subscript-select-discount{
        .payment-list-card{
            margin-top: 11.1111vw;
            .sub-title{
                @include defaultTxt;
                strong{
                    display: block; margin: 1.3889vw 0; @include subContsTitle;
                }
                span{
                    @include smallTxt;
                }
            }
            .pay-amount{
                justify-content: space-between; padding-top: 5.5556vw;
                span{
                    text-decoration: none;
                }
            }
        }
       .cardList{
        margin-top: 4.1667vw;
            dl{
                dt{width:30.5556vw; font-weight: 500;}
                dd{width:calc(100% - 30.5556vw);text-align: right;}
            }
       }
       .content{
            margin-top: 8.3333vw;
            .btn-text-arrow{
                margin-bottom:3.0556vw;
            }
       }
    }
    //선택약정
    .subscript-installment-info{
        .cardList-wrap{
            margin-top: 8.3333vw;
        }
        .money-right{
            dl{
                dd{
                    strong{
                        &.txt-vt{
                            @include contsTitle;
                        }
                    }
                }
            }
            & + .money-right{
                margin-top: 4.1667vw;
            }
            & + .notification {margin-top: 2.7778vw;}
        }
        .no-list{
            & + .notification {margin-top: 2.7778vw;}
        }
        .btn-wrap{
            margin-top: 11.1111vw;
            li{
                & + li{
                    margin-top: 4.1667vw;
                }
            }
        }
    }
     //상세 가입 및 이용조건
    .subscript-terms-popup{
        .notification{
            li{
                margin-bottom:2.7778vw;
            }
        }
    }
     // 일시정지
    .pause-application{
        .page-tit {
            p{margin-top: 1.3889vw;}
            h1 + .notification{margin-top: 1.3889vw;}
        }
        .contents-wrap{
            & + .contents-wrap{margin-top: 11.1111vw;}
        }
        .contents-title{margin-bottom:1.3889vw;}
        .notification{
            .default-txt{
                margin-bottom: 2.7778vw;
            }
        }
        .cardList-wrap{
            & + .box-btn{margin-top: 2.7778vw;}
        }
        .cardList{
            & + .cardList{margin-top: 4.1667vw;}
            .btns{margin-top: 6.9444vw;}
            .card-check {
                @include mainTxt; color: $purple;
                .box-info{
                    width: 100%;
                    .info-top{
                        display: flex;justify-content: space-between; align-items: center;
                        // strong{@include subContsTitle; font-weight: 600;}
                        span{@include mainTxt;}
                    }
                    .product-name{
                        display: block; margin-top: 1.3889vw;
                        @include text(400,3.8889vw,1.43,$gray05,-0.0778vw)
                    }
                }
            }
            &.pause{
                .card-check{
                    @include mainTxt; color: $gray05;
                    .box-info .info-top{
                        span{color: $purple;}
                    }

                }
            }
        }
        .box-ico-txt{
            margin-top: 0;
            & + .cardList-wrap{margin-top: 11.1111vw;}
        }

        // 일시정지 신청팝업
        &.full-popup-conts {
            .contents-wrap{margin-top: 8.3333vw;}
            .main-txt{display: block; margin-bottom: 2.7778vw;}
            .radio-group-wrap{
                & + .notification{margin-top: 2.7778vw;}
                .group-tit{@include mainTxt; margin-bottom: 2.7778vw;}
                .btn-radio {
                    label{
                        display: flex; align-items: center; justify-content: center; padding: 0; height: 16.6667vw;
                    }
                }
            }
            .bang-notice{
                margin-top: 2.7778vw;
                li{@include smallTxt;}
            }
            .box-input{
                & + .box-input{margin-top: 2.7778vw;}
            }
            .date-list{
                display: flex; gap: 4.1667vw;
                li{
                    * {@include subContsTitle;}
                }
            }
            .round-gray-box{
                background-color: $bg; padding: 4.1667vw;border-radius: 2.7778vw;
                .btn-check{
                    margin-bottom: 3.0556vw;
                    label > span{@include defaultTxt;}
                    & + p{
                        @include smallTxt;
                    }
                }
            }
            .accordian{
                margin-top: 11.1111vw;
            }
        }
    }
     //부가서비스 변경
    .serviceChange-list{
        .page-tit {
            display: flex; justify-content: space-between; position: relative; background-color: #f8f4ff; padding: 6.9444vw 5.5556vw; border-radius: 2.7778vw; margin-bottom: 4.4444vw;
            & + .box-btn{margin-bottom: 8.3333vw;}
            .txt-wrap {
                .desc{
                    margin-top:4.1667vw; display: flex; gap:4.1667vw;
                    li{
                        strong{
                            @include defaultTxt; margin-left: 1.3889vw;
                            em{
                                @include fontInherit;
                            }
                        }
                    }
                }
            }
            .img-wrap {
                position: absolute; top: 50%;right: 2.7778vw; width: 33.3333vw; height: 33.3333vw; transform: translateY(-50%);
                img{width: 100%;}
            }
        }
        .money-right{
            & + .money-right{
                margin-top: 4.1667vw;
            }
        }
        .cardList-wrap{
            &.free-service{
                min-height: 70vh; margin-top: 11.1111vw; background: #fff;
                .money-right{
                    dl{
                        dt{width: 100%;}
                    }
                }
            }
            .money-right{
                dl{
                    dt{
                        width: calc(100% - 30.5556vw);
                        strong{@include mainTxt;}
                        p{@include smallTxt;}
                        .txt-period {
                            p {margin-top:2.7778vw;   font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}
                            strong { font-size: 4.1667vw;color:#662d91; }
                        }

                        .detail-view {   
                            min-width:75.6944vw;                      
                            li {
                                display:flex;align-content: center;align-items: center; margin-top:2.7778vw;
                                .tit {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                                .txt {margin-left:1.3889vw;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}                          
                                &.dte {
                                    display:block;
                                    .txt {margin:0;}
                                }
                                .dte-view {
                                    span {
                                        display:block;margin-top:1.3889vw;padding-left:2.7778vw;text-indent: -2.7778vw;    
                                        em {margin-right:0.6944vw;}                                                                       
                                        strong {display:block;margin-left:13.1944vw; font-size: 4.1667vw;font-weight: normal;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
                                    }
                                }
                            }
                        }
                    }
                    dd{
                        width: 30.5556vw;
                        strong{@include mainTxt; letter-spacing: -0.2111vw;}
                        p{@include smallTxt;}
                    }
                }
            }
            & + .cardList-wrap{
                min-height: 70vh;
                margin-top: 11.1111vw;
                background:#fff;
            }
        }
        .box-btn{margin-top: 5.5556vw;}
        .btn-group{
            margin-top: 5.5556vw; gap:2.7778vw;
            .white{
                padding:0;
            }
            .btns{
                padding: 3.3333vw 0; border-radius: 1.9444vw;
            }
        }
        .accordian{
            margin-top: 11.1111vw;
        }
    }
    //발신자 미표시 번호 차단 서비스 설정
    .serviceChange-blocking{
        .phone-desc{
            h2{
                @include mainTxt;
            }
            p{
                width:100%; margin-top: 2.3611vw; @include subContsTitle;
            }
        }
        .radio-group-wrap{
            margin-top:8.3333vw;
        }
        .accordian{
            margin-top: 8.3333vw;
            .acc-conts{
                h3{
                    @include defaultTxt;
                }
                .notification{
                    margin-top: 2.7778vw;
                    li{
                        strong{
                            @include defaultTxt; font-weight: 500;
                        }
                        p{
                            @include backgroundImg('/assets/images/icon/ico-sound.svg',left top,5.5556vw 5.5556vw);
                            padding-left: 6.9444vw; margin-top: 1.3889vw;
                        }
                    }
                }
            }
        }
    }
    // 사용량조회
    .use-amount{
        &.use-fee-time{
            .notice-card{
                p{ @include smallDepthTxt; margin-top: 1.3889vw; }
            }
            .cardList-wrap{
                margin-bottom: 8.3333vw;
            }
             .loading-txt{
                margin-bottom: 8.3333vw;
            }
            .btn-box{
                margin-top: 11.1111vw;
                li{
                    &:last-child{
                        margin-top: 4.1667vw;
                    }
                }
            }
            .box-table{
                .table-title{@include mainTxt;}
            }
        }
        .wide-box{
            border-bottom:2.222vw solid $gray01; padding-bottom:11.1111vw;
            &.no-border{
                border:none; padding:0;
            }
        }
        .page-tit{
            h1{
                & > span{@include fontInherit; color: $purple !important;}
            }
        }
        .progress-amount{
            margin-bottom: 5.5556vw;
            .title-wrap{
                strong{@include usageTitle; margin-bottom: 0.8333vw;}
                span{@include smallDepthTxt;}
                & + .big-txt{
                    display: block; @include subContsTitle; font-weight: 700; margin-bottom: 5.5556vw;
                }
            }
            // .progress-wrap{
            //     position: relative; width: 100%; height: 2.7778vw; background-color: $gray01;  border-radius: 6.9444vw; margin-bottom: 16.6667vw;
            //     .progress-bar{
            //         position: absolute; top: 0; right: 0; width: 0; height: 100%; background-color: $purple; border-radius: 6.9444vw;transition: all 1s ease-in-out;
            //     }
            //     .progress-txt{
            //         display: flex; justify-content: space-between; position: absolute; bottom: -8.0556vw;width: 100%;
            //         em{
            //             @include smallTxt;
            //             &.left-date{color: $purple;}
            //         }
            //     }
            // }
        }

        .box-usage-wrap{
            .usage-title{
                @include usageTitle;
                display: flex; align-items: center; gap: 2.7778vw;
                &::before{
                    content: ''; display: block; width: 6.6667vw; height: 6.6667vw;
                    @include backgroundImg('/assets/images/icon/ico-my-data.svg',center, contain);
                }
                .qos-popup{margin: 0;}
            }
            & + .box-usage-wrap{margin-top: 8.3333vw;}

            .circle-wrap{
                position: relative; width: 28.3333vw; height: 28.3333vw;flex-shrink: 0;
                svg {
                    width: 28.3333vw; height: 28.3333vw;
                    transform: rotate(-0.25turn) rotateX(180deg);
                    position: absolute;  top: 0;  left: 0;  bottom: 0;  right: 0;  margin: auto;  height: 100%;
                }
                .background {
                    fill: none;
                    stroke: $gray01;
                    stroke-width: 3;
                }
                .progress {
                    position: relative;
                    fill: none;
                    stroke: $purple;
                    stroke-dasharray: 100 100;
                    stroke-dashoffset: 100;
                    stroke-linecap: round;
                    stroke-width: 3;
                    transition: stroke-dashoffset 1s ease-in-out;
                }
                // .bar-circle{
                //     position: absolute; left: 0; top: 50%; transform: translateY(-50%) rotate(-90deg); width: 28.3333vw; height: 2vw;transition: 1s ease-in-out;
                //     &.on{transform: translateY(-50%) rotate(233deg);}
                //     span{position: absolute; right: 0.6778vw; top: 0; width: 1.9444vw; height: 1.9444vw; background: #fff; border-radius: 50%;}
                // }
                .txt-data {
                    position: absolute; top:50%; left: 50%; width:100%; @include subContsTitle; font-weight: 600; color: $purple; transform: translate(-50%,-50%);font-size:4.7222vw;text-align:center;
                    & > span{ @include fontInherit;}
                    &.not-txt {color:#666;font-weight:600;}
                }
            }

            .box-usage{
                display: flex;flex-direction: column; gap: 8.3333vw; padding: 5.5556vw;  border: solid 0.2778vw $gray02; border-radius: 2.7778vw;
                .usage-wrap{display: flex; display: flex; align-items: center; gap: 4.1667vw; }

                .txt-wrap {
                    .tooltip-wrap{
                        .txt-tooltip{
                            left: -63.4667vw;
                        }
                    }
                    .usage {
                        &-desc{
                            display: block; @include defaultTxt; margin-bottom: 0.9722vw;
                        }
                        &-amount{
                            @include smallDepthTxt; font-weight: 600;
                            .highlight{
                                @include smallDepthTxt; font-weight: 600;color: $purple;
                            }
                        }
                        &-detail{
                            @include smallDepthTxt; margin-top: 0.9722vw;
                        }
                    }
                    
                     .usage-tit {display:block; font-size: 4.4444vw; font-weight: 600;  line-height: 1.25;  letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                     .usage-view>div {position:relative;margin-top:1.3889vw; padding-left:2.2222vw; font-size: 4.1667vw; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                     .usage-view>div::before {content:''; position:absolute; left:0; top:7px; width:0.8333vw; height:0.8333vw; border-radius: 50%; background:#666;}
                     .usage-view>div.usage-amount {font-size:4.1667vw; color:#666;font-weight:600;}
                     .usage-view>div.usage-amount .highlight {color:#662d91;font-size:4.1667vw;}  
                     .usage-tit .tooltip-wrap { display: inline-flex; vertical-align: middle;}
                     .usage-tit .tooltip-wrap .txt-tooltip {left:-72.4667vw;}
                }
                .tooltip-wrap{
                    display: inline-flex; vertical-align: middle;
                    .box-tooltip {
                        .btn-tooltip{width: 4.1667vw; height: 4.1667vw;@include backgroundImg('/assets/images/icon/ico-notice.svg',center, contain);}
                    }
                }
            }
            &.call {
                .usage-title::before{
                    background-image: url('/assets/images/icon/ico-my-call.svg');
                }
            }
            &.sms {
                .usage-title::before{
                    background-image: url('/assets/images/icon/ico-my-sms.svg');
                }
            }
        }
        .box-chart-wrap{
            padding: 0 0 20vw;
            .chart-info{
                margin-bottom: 8.3333vw;
                span {
                    @include mainTxt;
                    &.date {
                        display: block;
                        @include smallDepthTxt;margin-top: 0.8333vw;
                    }
                }

            }
            .chart {
                &-title {
                    @include mainTxt;
                    display: flex; align-items: center; gap: 2.7778vw; margin-bottom: 9.7222vw;
                    &::before{
                        content: ''; display: block; width: 6.6667vw; height: 6.6667vw;
                        @include backgroundImg('/assets/images/icon/ico-my-data.svg',center, contain);
                    }
                }
            }
            &.call {
                .chart-title::before{
                    background-image: url('/assets/images/icon/ico-my-call.svg');
                }
            }
            &.sms {
                .chart-title::before{
                    background-image: url('/assets/images/icon/ico-my-sms.svg');
                }
            }
            .box-chart{
                position: relative;
                .chart {
                    &-line{
                        display: flex; flex-direction: column;gap: 9.7222vw;
                        hr{
                            display: block;border: 0;margin: 0; border-top: 0.1389vw solid $gray02; width: 100%;
                            &:last-of-type{color: $gray05;}
                        }
                    }
                    &-wrap{
                        display: flex;justify-content: space-around;
                        position: absolute; bottom: 0;width: 100%;height: 100%;
                        .chart-conts {
                            position: relative;width: 13.8889vw;height: 100%;
                            .chart-bar {
                                position: absolute; bottom: 0; width: 15.2778vw; height: 0;
                                transition: height 0.5s ease; border-radius: 0.6944vw 0.6944vw 0 0; background-color:#f9f5ff;
                                .bar {
                                    display: flex; justify-content: center; align-items: center; @include defaultTxt; color: $gray05; width: 100%; height: 100%; opacity: 0; visibility: hidden;
                                    transition: opacity 0.3s, visibility 0.3s;
                                }
                                &.on {
                                    .bar{opacity: 1; visibility: visible;}
                                }
                                // .shadow{
                                //     display: block; position: absolute; bottom: 0;left: 15%;z-index: -1;width: 100%; height: 90%; background-color: $gray02; opacity:0.4;
                                //     transform: skew(-10deg);
                                // }
                            }
                            .chart-month {
                                display: block; position: absolute; bottom: -8.8889vw; width: 100%; @include defaultTxt; margin-top: 2.7778vw; text-align: center;
                            }
                        }
                        .box-average {
                            position: absolute;
                            bottom: 0%; left: 0; width: 100%; z-index: 2; opacity: 0; visibility: hidden;
                            transition: all 0.5s ease;
                            &.on{
                                opacity: 1; visibility: visible;
                            }

                            hr{display: block;border: 0;margin: 0; border-top: 0.2778vw dashed $purple; width: 100%; }
                            .average-txt{
                                display: inline-block; position: absolute; top: -10.8333vw; left: 50%; padding: 1.5278vw 2.7778vw; border-radius: 6.25vw;background-color: $purple;@include defaultTxt; color: #fff; transform: translateX(-50%);opacity: 0; visibility: hidden;text-align: center;
                                transition: opacity 0.5s ease, visibility 0.5s ease;
                                &.on{
                                    opacity: 1; visibility: visible;
                                }
                                &::after{
                                    content: ''; display: block;width:2.3611vw;height: 2.3611vw;
                                    position: absolute; bottom: -1.1806vw; left: 50%; transform: translateX(-50%) rotate(45deg); background-color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }

        .find-no-data{
            @include backgroundImg('/assets/images/icon/ico-no-data.svg', center top, 36.1111vw 36.1111vw); padding-top:41.6667vw;

            .round-box {
                margin-top: 0; padding: 8.3333vw 5.5556vw; background-color: $white; border: 0.2778vw solid $gray03;
                .main-txt{
                    display: block; text-align: center; margin-bottom: 2.7778vw;
                }
                p{
                    text-align: center;
                    @include defaultTxt;
                }
                .box-btn{
                    margin-top: 5.5556vw;
                }
            }
        }
        .box-fee{
            display: flex; justify-content: space-between; align-items: center; padding: 5.5556vw; background-color: $purple; border-radius: 2.7778vw; margin-top: 11.1111vw;
            .txt{
                span{
                    display: block; @include mainEmphasize; color: #fff;
                    &.date{margin-top: 0.8333vw;}
                }
            }
            .fee{
                flex-shrink: 0;
                @include subContsTitle; font-weight: 700; color: #fff;
                em{@include fontInherit; font-size: 7.2222vw; letter-spacing: -0.2167vw;}
            }
        }
        .table-list-wrap{
            margin: 8.3333vw 0;
        }
        .accordian{
            margin-top: 6.9444vw;
            & + .accordian{margin-top: 2.7778vw;}
            .notification {
                li{
                    .txt-vt{display: block;}
                    .box-btn{margin: 1.3889vw 0 2.7778vw;}
                }
            }
        }
    }
    .call-history {
        .inquir-box {
            margin-top:11.1111vw;
            .btn-radio-tit {display:block;margin-bottom:2.2222vw;}
            .btn-radio-group {
                margin-bottom:5.5556vw;
                label {cursor:pointer;}
            }
            .period-set {
                &>strong {display:block;margin-bottom:4.1667vw;}
                .box-input+.box-input {margin-top:4.1667vw;}

            }
        }
    }

    .call-history-reason {
        padding-bottom: 12.5vw;
        .box-input {margin-bottom:4.1667vw;}
    }
    .puk-browse{
        .puk-num{color: $purple !important;}
    }
    .communi-data{
        .box-input + .textarea-wrap{margin: 4.1667vw 0 11.1111vw 0;}
    }
    // 가입신청서 열람
    .appli-admission{
        .box-input{
            & + .textarea-wrap{margin: 4.1667vw 0 11.1111vw 0;}
        }
        .card-check{border-radius: 0;}
    }
    //발급신청 발급용도 입력
    .appli-purpose{
        .etc-input{
            margin-top:2.7778vw; display: none;

        }
        .email-input{
            margin-top:11.1111vw;
        }
        .cont-tit{
            @include mainTxt;
        }
    }
    //통화내역 열람신청 조회기간 선택
    .call-history-select-receiving{
        .bang-notice {line-height:1.33;}
        .cont-tit {margin-bottom:2.7778vw;@include mainTxt;}
        .period-set{
            margin-top:5.5556vw;
            & > .box-input{
                margin-top:4.1667vw;
            }
        }
    }
    //통화내역 열람신청 수신방법 선택
    .call-history-appli-inquir{
        .cont-tit {margin-bottom:2.7778vw;@include mainTxt;}
        .cont-sec {
            margin-top:11.1111vw;
            .box-input {margin-top:2.7778vw;}
        }
        .faxBox {display: none;}
    }
    //개인정보열람 신청내역목록
    .privacy-info-list{
        .privacy-info-item{
            width:100%; border-radius:2.7778vw; border:0.2778vw solid $gray02; display: flex; justify-content: space-between; align-items: center; padding:5.5556vw;
            .item-desc{
                h2{
                    @include mainTxt; font-weight: 700;
                }
                .process-txt{
                    display: block; margin-bottom: 1.3889vw; @include text(600,3.8889vw,1.43,$black,-0.1167vw );
                }
                ul{
                    display: flex; margin-top: 2.7778vw;
                    li{
                        position: relative; padding-right:5.5556vw; @include smallTxt;
                        &::after{
                            content: ''; width:0.2778vw; height:3.0556vw; background: $gray02; position:absolute; top:50%; transform: translateY(-50%); right:2.7778vw; display: block;
                        }
                        &:last-child{
                            &::after{display: none;}
                        }
                    }
                }
            }
            & + .privacy-info-item{
                margin-top: 4.1667vw;
            }
        }
        .box-btn{margin-top: 4.1667vw;}
    }
    .privacy-info-no-list{
        .img{
            margin: 11.1111vw 0 4.1667vw !important;
            & + .bang-notice{
                text-align: center;
            }
        }
    }
    //요금납부조회
    .my-payment {
        // .payment-img-tit {
        //     margin-bottom:11.1111vw;padding-top:40.2778vw;background:url('/assets/images/icon/ico-payment-way-finish.svg') no-repeat center top;background-size:36.1111vw 36.1111vw;
        //     h1 {@include subContsTitle;text-align:center;}
        // }
        .btns {
            font-size:4.4444vw;
        }
        .default-txt{@include mainTxt;}
    }
    .payment-inquiry{
        .list-header{
            span{
                color: #666; font-size: 4.1667vw; font-weight: 400;line-height: 1.3;letter-spacing: -.1833vw;
            }
            strong{
                @include contsTitle;
            }
        }
        .txt-point {
            margin-bottom:2.7778vw;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;
            .link {display:block;max-width:25.9722vw;margin-top:1.3889vw;}
        }
    }
    // 납부확인서
    .payment-confirm-appli{
        .self-write-wrap{
            display: none; margin-top: 2.7778vw;
        }
        .date-select-wrap{
            display: flex; gap: 4.1667vw;
            .box-select{
                flex: 1;
            }
        }
    }
    .payment-confirm-check{
        .cardList-wrap {
            & + .unit-sec-tit{
                margin-top: 11.1111vw;
            }
        }
        .table-list-wrap{
            .table-list {
                th{padding-right: 0;}
                td{padding-right: 0;}
            }
        }
        .sign{
            text-align: center;
            .normal-txt{
                @include mainTxt;
            }
            .official-seal {
                margin-top: 1.3889vw;display: flex;justify-content: center;
                align-items: center;gap: 2.7778vw;
                em {
                    @include mainTxt;
                }
                .seal-wrap{
                    width: 10.4167vw;height: 10.4167vw; margin-left: -5vw;
                    img{display: block;}
                }
            }
            .bang-notice{
                text-align: left; margin-top: 11.1111vw;
            }
        }
    }
    //납부 방법 변경 완료
    .payment-way-finish{
        .ico-img{
            width:36.1111vw; height: 36.1111vw; margin:0 auto;
        }
        .payment-wrap{
            margin-top: 11.1111vw;
        }
    }

    .withdraw-guide  {
        margin:5.5556vw 0 0 0;padding-top:5.5556vw;border-top:1px solid #ddd;
        p{ font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.1167vw;text-align: left; color: #662d91;}
    }

    //구독 중 서비스 목록
    .subscribe-list{
        .chip-wrap{
            .chip{
                &:first-child{
                    background-color:$lightpurple;
                }
                &:nth-child(2){
                    background-color:$purpleblue;
                }
            }
        }
        .box-my-use{
            padding: 0; margin-bottom: 2.7778vw;
            .box-btn{margin-top: 0;}
        }
        .cardList-wrap{
            .cardList{
                padding: 8.3333vw 5.5556vw;
                & + .cardList {margin-top: 4.1667vw;}
                & > a{display: block;}
                .card-check{
                    border: none; padding: 0;
                    .box-info{
                        width: 100%; margin-bottom: 4.1667vw;
                        .info {
                            display: flex; justify-content: space-between; align-items: center;
                            &-top {
                                display: flex; justify-content: space-between; align-items: center;
                                .payment-state{
                                    @include defaultTxt; color:$purple;
                                    &.unpaid{color: #cf192b;}
                                }
                            }
                            &-product{
                                display: flex; justify-content: space-between; align-items: center;
                                margin-top: 2.7778vw;
                                strong{@include mainTxt;}
                                span{@include mainTxt;}
                            }
                            &-point{
                                display: flex; justify-content: space-between; align-items: center;
                                margin-top: 1.3889vw;
                            }
                        }
                    }
                }
                dl{
                    padding: 4.1667vw; background-color: $gray01; border-radius: 2.7778vw;
                }
                .btns{
                    margin-top: 4.1667vw;
                }
            }
        }
        .accordian{margin-top: 11.1111vw}
        .box-select{
            margin-bottom: 11.1111vw;
        }
    }
    .subscribe-detail-layerPopup{
        a{
            text-decoration: underline;
        }
    }
    //구독서비스 상세
    .subscribe-detail {
        .box-tooltip .txt-tooltip{left: -20.9389vw;}
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 8.3333vw;}
            .cardList{
                dd {
                    & > span{@include fontInherit; display: block;}
                    .old-price{@include smallDepthTxt; text-decoration: line-through;}
                    .discount-price{
                        & > span{@include fontInherit;}
                    }
                    a{
                        @include fontInherit;
                        &.link-txt{color: $purple;}
                    }
                }
                .btn-group{
                    gap: 2.7778vw; margin-top: 6.9444vw;
                    button{border-radius: 1.9444vw;}
                }
            }
        }
        .box-table{
            margin-top: 8.3333vw;
            .table-header{
                display: flex; justify-content: space-between; align-items: center; margin-bottom: 4.1667vw;
                .table-title{border: none; padding: 0; margin: 0;}
            }
            .table-list{
                width: 100%; border-top: 0.2778vw solid $gray02;
                th,td{
                    padding: 5.5556vw 4.1667vw;border-bottom: 0.2778vw solid $gray02;
                    @include smallTxt; color: $black;
                }
                td{
                    &:nth-of-type(1) {padding:5.5556vw 0;}
                    &.complete {color: $purple;}
                    .btn-box{
                        .btn-text{
                            display: flex;padding: 0;align-items: center; gap: 1.3889vw;justify-content: flex-end;
                            @include smallTxt;color: $black;
                        }
                        .ico-arrow-right{flex-shrink: 0;}
                    }
                }
            }
            & + .box-btn{margin-top: 5.5556vw;}
        }
        .accodian-wrap{
            margin-top: 8.3333vw;
            .accordian-list{
                margin-top: 2.7778vw; border-top:0.2778vw solid $gray02; border-bottom:0.2778vw solid $gray02;
                li{
                    & >.title{
                        display: flex; justify-content: space-between; padding:5.5556vw 8.3333vw 4.1667vw 0; margin-bottom: 0;
                    }
                    .acc-conts{
                        background: $white; padding:0 0 5.5556vw 0;
                    }
                    .mb60{
                            margin-bottom: 8.3333vw !important;
                        }
                }
            }
        }
        .box-btn{
            margin-top: 5.5556vw;
        }
        .money-right{
            dl{
                dt{
                    width: 33.75vw;
                }
                dd{
                    width:calc(100% - 33.75vw);
                   &.big{
                        font-size: 5.5556vw;
                    }
                }
            }
        }
        .under-line-wrap{
            border:0.2778vw solid $gray02; border-radius: 2.7778vw; padding: 5.5556vw;
           &>li{
                border-bottom:0.2778vw solid $gray02; padding-bottom: 5.5556vw;
                .btn-box{
                    margin-top: 5.5556vw;
                }
                dl{
                    display: flex;  width: 100%; flex-wrap: wrap;
                    dt{
                        width:25vw; padding-bottom: 2.7778vw;
                    }
                    dd{
                        width:calc(100% - 25vw); @include defaultTxt;
                    }
                }
                & + li{
                    padding-top: 5.5556vw; padding-bottom: 0;
                }
                &:last-child{
                    border-bottom:0; padding-bottom: 0;
                }
                .notification{
                    margin-top: 2.7778vw;
                }
                a{
                    color:$purple; text-decoration: underline;
                }
                .bang-notice-bt{
                    @include smallTxt;
                }
            }
        }
        .pl30{
            padding-left: 4.1667vw; margin-bottom: 2.7778vw; @include defaultTxt;
        }
    }
    .notice-change-date{
        .btn-radio{
            & + .btn-radio{
                margin-top: 4.1667vw;
            }
        }
    }
    .notice-change-date-complete{
        .no-search-result {
            background-image: url('/assets/images/icon/ico-date-change.svg');
        }
    }
    //결제 상세 내역
    .subscribe-payment{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 8.3333vw;}
            .cardList{
                .list-header {
                    @include subContsTitle;
                    // span{@include smallTxt;}
                    // strong{@include text(700,5.8333vw,1.14,$purple,-0.1167vw);}
                }
                dl{
                    & + .btns{margin-top: 5.5556vw;}
                    & + p{
                        @include smallTxt; word-break: keep-all; padding-top: 5.5556vw; margin-top: 5.5556vw; border-top: 0.2778vw solid $gray02;
                        & + .notification{margin-top: 2.7778vw;}
                    }
                }
                &.money-right{
                    dl{
                        dt{width: 50%;}
                        dd{width: 50%;}
                        .pay-date{
                            @include contsTitle; color: $purple;
                            span{@include fontInherit;}
                        }
                        .point-num{
                            @include defaultTxt; color: $purple;
                            span{@include fontInherit;}
                        }
                    }
                }
            }
        }
    }
    //구매정보변경
    .subscribe-purchase{
        .content {
            & + .content{margin-top: 5.5556vw;}
        }
        .box-flex{
            & + .box-flex{margin-top: 5.5556vw;}
            & + .btns{margin-top: 4.1667vw;}
        }
        .textarea-wrap {
            display: none; margin-top: 2.2222vw;
        }
    }
    //전체결제내역
    .subscribe-whole{
        .accordian{
            &.type-03 {
                &:last-of-type{
                    .acc-trigger{border-bottom: 0.2778vw solid $gray02;}
                    .cardList-wrap{
                        .cardList{
                            border: 0;border-bottom: 0.2778vw solid $gray02;
                        }
                    }
                }
                .acc-trigger{
                    display: flex; justify-content: space-between; align-items: center; border-top: 0.2778vw solid $gray02;padding: 5.5556vw 4.1667vw; margin: 0;
                    * {@include smallTxt; color: $black;}
                    &::after{
                        position: static; transform: translateY(0);
                    }
                    &.on{
                        &::after{transform: rotate(180deg);}
                    }
                }
                .cardList-wrap{
                    .cardList{
                        border: 0; border-radius: 0;border-top: 0.2778vw solid $gray02;
                    }
                }
                & + .box-btn{margin-top: 2.7778vw;}
            }
        }
    }
    //할인반환금 예상 금액
    .subscript-return-amount{
       .cardList-wrap{
            margin-top: 11.1111vw;
            .bang-notice{
                margin-top: 1.3889vw;
            }
        }
        .box-table{
            margin-top: 8.3333vw; margin-bottom: 11.1111vw;
        }
        .table-title{margin-bottom: 0;}
        .table-type-01{
            & + .table-type-01{
                margin-top:8.3333vw;
            }
            tbody{
                tr:first-child{
                    td,th{padding-top:5.5556vw;}
                }
                tr:last-child{
                    th,td{padding-bottom:5.5556vw;}
                }
            }

        }
        .box-ico-txt{
            & + p{
                margin-top: 11.1111vw;
            }
        }
    }
    //위약금 안내
    .subscript-cancellation-charge-popup{
        h3{
            margin-top: 8.3333vw; @include mainTxt;
            &:first-child{margin: 8.3333vw 0 2.7778vw 0;}
        }
        .notification{
            margin-top: 2.7778vw;
        }
        .table-type-04{
                tr{
                    td:first-child{
                        @include normalTxt; font-weight: 500;
                    }
                }
            & + .table-type-04{
                margin-top: 8.3333vw;
            }
        }
    }
    //휴대폰 인증
    .order-phone-auth {
        .page-tit{
            .box-btn {margin-top: 4.1667vw;}
        }
        .box-flex{
            margin-top: 5.5556vw;
        }
    }
    //최근 가입신청 이력
    .order-list {
        .notification{margin-bottom: 11.1111vw;}
        .box-select{margin-bottom: 11.1111vw;}
        section {
            & + section{margin-top: 8.3333vw;}
            & + .box-btn {margin-top:11.1111vw}
        }
        .cardList{
            & + .box-btn{
                margin-top: 4.1667vw;
                &.mt80{
                    margin-top: 11.1111vw;
                }
                // .btns {
                //     & + .btns{margin-top: 4.1667vw;}
                // }
            }
            dt{width: 30%;}
            dd{
                width: 70%;
                span{@include fontInherit;}
                .btn-link {display:block; width:25.9722vw; height:5.5556vw; margin-top:1.3889vw; border-radius: 1.3889vw;  border: solid 0.1389vw #191919; font-size: 3.8889vw; font-weight: 600; line-height: 1.43;  letter-spacing: -0.0778vw; color: #191919; text-align: center;}
            }            
            .box-table {margin-top:5.5556vw;}
            .accordian {margin-top:5.5556vw;border-bottom: 1px solid #ddd;}
            .accordian .acc-trigger {margin:0; padding:5.5556vw 0; border-top:0.1389vw solid #ddd; border-bottom:0;}  
        }
        .box-banner{
            margin-top: 11.1111vw;
            img{width: 100%;}
            & + .box-banner{
                margin-top: 8.3333vw;
            }
        }
        .num-process-budle{
           border: 0.2778vw solid #ddd; border-radius: 1.9444vw; padding: 6.4444vw;
            .sub-contents-title{
                display: block; margin-bottom: 5.5556vw;
                span{@include fontInherit;}
            }
            .num-process-wrap{
                border:none; border-radius: 0; padding: 0;
                & > li{
                    &::before{top: -100%; z-index: -1;}
                    &:first-child{
                        &::before{display: none;}
                    }
                    &:last-child{
                        &::before{
                            left: 0;top: -100%;z-index: -1;width: 0.2778vw;height: 100%; background: $purple;
                        }
                        &::after{display: none;}
                    }
                    &.disabled::before{
                        background: $gray02;
                    }
                }
            }
        }
        .no-search-result{
            .small-txt{
                margin-top: 0;
            }
        }
    }
    //무선서비스 계약 표준 안내서
    .order-wireless{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 11.1111vw;}
            .signiture-txt{
                @include smallTxt;
                display: flex; justify-content: flex-end; align-items: center; gap: 2.7778vw; margin-top: 3.3333vw;
                img{
                    display: inline-block; width: 10.4167vw; height: 10.4167vw;
                }
            }
            .cardList-title{
                display: flex; justify-content: space-between; align-items: center;
                @include subContsTitle;
                strong{@include subContsTitle;}
            }
            .cardList {
                & + .cardList {margin-top: 5.5556vw;}
                & + .notification{margin-top: 2.7778vw;}
                .title{@include mainTxt;}
                dt{width: 40%;}
                dd{
                    width: 60%;
                    .month-txt{display: block; @include fontInherit;}
                }

                &.is-title{
                    dd{@include smallTxt;}
                    .bold-txt{
                        @include defaultTxt;
                        & + dd{@include defaultTxt;}
                    }
                    .card-title{
                        display: block; @include mainTxt; padding-bottom: 5.5556vw; margin-bottom: 5.5556vw; border-bottom: 0.2778vw solid $gray02;
                    }
                }
            }
        }
        .noti-box{
            margin-top: 11.1111vw;
            .main-txt{display: block; margin-bottom: 2.7778vw;}
            .bg-gray{
                background-color: $bg; padding: 4.1667vw; border-radius: 2.7778vw; margin: 5.5556vw 0 4.1667vw;
                .btn-check{
                    & + p{
                        margin-top: 2.7778vw; @include smallTxt;
                    }
                }
            }
        }
        .accordian{
            margin-top: 11.1111vw;
            .acc-trigger{
                padding-bottom: 0;
            }
            .acc-conts{
                background: none; padding: 0; margin-top: 4.1667vw;
                .cardList{
                    .w-100{width: 100%;}
                }
            }
        }
        .btn-check{
            input[type=checkbox]:disabled+label span{
                color: $black;
                &::after {top: 2.7778vw;}
            }
        }
    }
    //가입신청서 상세보기
    .order-list-detail{
        .cardList-wrap{
            .notice-card{
                .title{@include mainTxt;}
                .txt-vt{@include contsTitle;}
            }
        }
        .payment-detail-card-wrap{
            margin-bottom: 11.1111vw;
            li{
                .detail-title{@include mainTxt;}
                p{@include smallTxt;}
                .txt-vt{display: block; @include smallTxt; text-align: right;margin-top: 4.1667vw;}
                &:first-of-type{
                    .detail-title{margin-bottom: 0;}
                    .txt-vt{@include contsTitle;}
                }
            }
            .phone-thumbnail{
                position: relative; width: 27.7778vw; height: 27.7778vw; margin: 0 auto 5.5556vw; overflow: hidden;
                img{@include imgSize;}
            }
            .btn-set{
                margin-top: 8.3333vw;
            }
        }
        .accordian {
            & > .title{
                @include subContsTitle;
            }
            .acc-conts{
                padding:0; background: $white;
                .notification{
                    margin-top: 4.1667vw;
                    li{
                        margin-bottom: 1.3889vw;
                    }
                }
                .box-table{
                    margin-top: 4.1667vw;
                    tr{
                        .date-txt{white-space: nowrap;}
                    }
                    & + .cardList-wrap{
                        margin-top: 4.1667vw;
                        p{
                            @include smallTxt; margin-top: 2.7778vw;
                        }
                    }
                }
            }
        }
    }
    //배송지변경
    .order-address{
        .box-input{
            & + .box-input{margin-top: 5.5556vw;}
        }
        .input-depth{
            margin-top: 5.5556vw;
            .box-input{
                & + .box-input{margin-top: 0;}
            }
        }
        .box-select{margin-top: 5.5556vw;}
        .textarea-wrap{
            display: none; margin-top: 2.2222vw;
        }
    }
    //입고 알림 신청 내역
    .order-goods-application{
        section + section{margin-top: 5.5556vw;}
        .cardList-wrap{
            .box-btn{margin-top: 2.7778vw;}
            .cardList {
                gap: 5.5556vw 4.1667vw ;
                & + .cardList {margin-top: 4.1667vw;}
                & > .box-btn{
                    width: 100%;
                }
                .item-txt{
                    display: flex;flex-direction: column;justify-content: center;
                }
            }
        }
        .notification{margin-top: 1.3889vw;}
        .no-search-result {
            background-image: url('/assets/images/icon/ico-alarm-no-result.svg');
        }
    }

    //내 포인트 조회
    .point-lookup{
        .radio-group-wrap{
            margin-top: 8.3333vw;
            .btns{
                margin-top: 4.1667vw;
            }
        }
        .full-tab {padding:0;}
        .is-filter-list{
            padding:0; overflow-x: hidden;
        }
        .datepicker-wrap{display: none;}
        .cardList-wrap {
            & + .box-btn{margin-top: 2.7778vw;}
        }     
        
        .number-recmnd  {
            margin:11.1111vw 0 4.1667vw 0;
            p {
                font-size: 4.4444vw; font-weight: 600;  line-height: 1.25; letter-spacing: -0.0889vw;  text-align: left;  color: #191919;
                span {font-weight: normal; color:#191919;}
            }
            .btn {margin-top:2.7778vw;}
        }

        .u-cach-cnt {
            .page-tit {
                margin-bottom:5.5556vw;
            }
            .cash-inquiry {
                .cash-usable {
                    padding: 4.0278vw 0; border-radius: 1.9444vw;background-color: #f9f5ff;text-align: center;
                    span {
                        font-size: 5vw;font-weight: 600;letter-spacing: -0.1vw;text-align: center;color: #662d91;
                        span {color:#191919;}
                    }
                }
            }
            section + section {margin-top:11.1111vw;}
            .sctn-tit {margin-bottom:2.0833vw; font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
            .radio-group-wrap {
                margin:0;
                .btn-radio-group{
                    flex-wrap: wrap; 
                    li {width: calc(50% - 1.3889vw)}
                }
            }
            .box-input {margin-top:5.5556vw;}
            .guide-msg {
                margin-top:2.7778vw;
                li {
                    padding-left:4.1667vw; text-indent: -3.1667vw; font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: left;color: #666;
                    & + li {margin-top:1.3889vw;}
                }
            }
            .box-btn {margin-top:5.5556vw;}
            .u-cach-aplcatin {
                padding: 5.5556vw;border-radius: 2.7778vw; border: solid 1px #ddd;
                dl{
                    display: flex;align-items: flex-start;
                    dt {width:30.5556vw;font-size: 4.1667vw; letter-spacing: -0.0833vw;text-align: left;color: #666;}
                    dd {font-size: 4.1667vw;font-weight: 600;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                    & + dl {margin-top:2.7778vw;}                   
                }
                & + .btn-check {margin-top:5.5556vw;}
            }
            .notification {margin-top:5.5556vw;}
        }
    }

    .point-inquiry {
        dl {
            dt {
                p {margin-bottom:1.3889vw;}
            }
            dd {
                em {font-size: 5vw;font-weight: 600; line-height: 1.17; letter-spacing: -0.1vw; text-align:right; color: #662d91}
            }
        }
        dl.point-dtil {
            justify-content: space-between;
            dt {
                width:60%;                   
                .btn { font-size: 4.1667vw; font-weight:normal; line-height: 1.6; letter-spacing: -0.125vw;text-align: left; color: #666;}
            }
            dd {
                width:40%;                    
            }
        }
    }

    .money-right  {
        .middle {
            .txt-cancel {text-decoration: line-through; color:#959595 !important; font-weight: 600;}
        }
    }

    // 포인트 사용
    .point-use-info{
        .c-unit{
            .unit-tit{margin-bottom: 1.3889vw;}           
        }
        .sb-tit {font-size: 4.1667vw;font-weight: 600;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
        .btn-flex{
            display: flex; gap:4.1667vw; margin-top: 5.5556vw;
            flex-direction: column;
            li{
                flex:1;
            }
        }
        .num-process-wrap {
            li {
                padding-bottom: 9.7222vw;
                &:last-child {padding-bottom:0;}
            }
        }
    }
    //포인트 사용 신청
    .point-use-application{
        .detail-sec-tit{margin-bottom: 5.5556vw;}
        .detail-sec-desc{
            .box-flex{
                // .input-default{padding: 3.8889vw 16vw 3.8889vw 4.4444vw; }
                & > .box-btn{
                    button{width: 27.7778vw;}
                }
                & + .box-flex{
                    padding-top: 2.2222vw;
                }
            }
            &.all-point-inputWrap{
                input{
                    color:$purple;
                }
            }
        }
    }
    //최근 본 상품 - 요금제
    .view-prodcuts-rate{
        .font0{
            font-size: 0;
        }
        .cardList{
            gap:5.5556vw;
            padding: 8.3333vw 5.5556vw;
        }
        .card-list-header{
                .main-txt{
                    width:calc(100% - 5.5556vw);
                }
        }
       .pay-amount{
            text-align: right; width:100%;
        }
       .btns{
        em{ @include mainTxt; color:$white; }
       }
    }
    //최근 본 상품 - 휴대폰
    .view-prodcuts-phone{
        .cardList {padding: 8.3333vw 5.5556vw;}
       .btns{
        em{ @include mainTxt; color:$white;}
       }
    }
    //유심구매내역
    .order-usim{
        .cardList{
            dt{width: 40%;}
            dd{width: 60%;}
        }
        .point-sort-card{
            .middle{
                margin-top: 5.5556vw;
                dl{
                    gap:4.1667vw; align-items: center;
                    dt{
                        width:25vw !important;
                        img{
                            width: 100%;
                        }
                    }
                    dd{
                        text-align: left;
                        .title{
                            @include mainTxt; margin-bottom: 1.3889vw;
                            width:46.6111vw;
                        }
                    }
                }
                .amount{
                    @include mainTxt; text-align: right; display: block;
                }
            }
        }
        .box-select{
            margin-top: 11.1111vw;
        }
        .mt60{
            margin-top: 8.3333vw;
        }
        .box-table{
            .table-title{
                @include mainTxt; padding-bottom: 2.7778vw; margin-bottom: 0;
            }
            .emp-txt{
                *{color: $purple;}
            }
        }
        .btn-list{
            li{
                margin-top: 4.1667vw;
            }
        }
        .no-search-result{
            background-image: url('/assets/images/icon/ico-usim-no-result.svg');
        }
    }
    // eSim 활성화 방법 안내
    .order-esim-active-popup{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 8.3333vw;}
            .cardList-title {
                & + p{
                    @include smallTxt;
                    * {@include fontInherit;}
                    & + .one-title{margin-top: 5.5556vw;}
                }
            }
        }
        .one-title{
            & + .one-title{margin-top: 4.1667vw;}
            .unit {
                & + .unit{margin-top: 4.1667vw;}
                strong{
                    @include defaultTxt; display: block; margin-bottom: 1.3889vw;
                }
                span{
                    display: block; @include smallTxt;
                    & + span{margin-top: 2.7778vw;}
                }
                .btns{margin-top: 4.1667vw;}
            }
        }
        .unit-img{
            margin-top: 5.5556vw;
            img{
                display: block; margin:0 auto; width: 41.6667vw;
            }
        }
    }
    .bill-immediate .payment-type {
        margin:8.3333vw 0;
        .inquir-box {margin-bottom:2.7778vw;}
       .is-unit {padding-right:8.3333vw; text-align:right;}
    }

    .u-cash {
       p + dl {margin-top:1.3889vw}
       dl dt em { font-size: 5vw;font-weight: 600;line-height: 1.17;letter-spacing: -0.1vw; text-align: left;color: #662d91;}
       .devcechg-txt {margin-top:2.7778vw; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: right; color: #666;}
    }

    .u-cash.cardList-wrap {
        .cardList{
            dl.top-box {
                 dt {width: 36.5556vw;}
                 dd {width: calc(100% - 36.5556vw);}
                 &.lnk-txt  {
                    dt {width:100%; margin-bottom:4.1667vw}
                    dd {
                        width:100%; padding:0;
                        & + dd {margin-top:2.7778vw;}
                    }
                 }
            }
            dl.bttm-box {
                margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;
                dt {width:48.6111vw;}
                dd {width: calc(100% - 48.6111vw);font-size: 4.1667vw; font-weight: 600; line-height: 1.33;letter-spacing: -0.0833vw;text-align: right; color: #191919;}
            }
            .total-cash {
                dt {width:30.5556vw; font-size: 4.1667vw; font-weight: 600; line-height: 1.33;letter-spacing: -0.125vw; text-align: left;color: #191919;}
                dd {width: calc(100% - 30.5556vw); font-size: 4.4444vw;font-weight: bold; line-height: 1.25; letter-spacing: -0.0889vw;text-align: right;color: #662d91;}
            }
            
        }
    }
    .u-cash.cardList-wrap + .radio-group-wrap {margin-top:5.5556vw}
    .is-filter-box {margin-top:11.1111vw;}
    .c-unit + .u-cash.cardList-wrap {margin-top:11.1111vw}
    .u-cash-list {
        margin-top:11.1111vw;
        .cardList-wrap {
            .cardList.u-cash  {
                .top {
                    dl + dl {margin-top:2.7778vw;}
                    dl {
                        dt {width:25vw!important; color:#666;}
                        dd {width: calc(100% - 25vw)!important;color:#666;font-weight:normal; text-align: left;}
                    }
                    dl.first {
                        dt {font-size: 4.1667vw; font-weight: 600; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        dd {font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #662d91;}
                    }
                    dl.date {
                        dt {font-size: 4.1667vw; font-weight: 600; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        dd {font-size: 4.1667vw; font-weight: 600; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919}
                    }
                    dl + dl.date {margin-top:5.5556vw;}
                }
                .bttm {margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;}
            }

        }
    }
    // 유심 변경 신청
    .subscript-usim-change{
        .cardList-wrap{
            & + .cardList-wrap{margin: 8.3333vw 0;}
            h3{margin-bottom: 2.2222vw; color: #191919; font-size: 4.4444vw; font-weight: 600; line-height: 1.25;}
        }
        .usim-register{
            .img-modelcode{width: 56.9444vw; height: auto; display: block; margin: 2.7778vw auto;}
            .btn-text-arrow{margin: 5.5556vw 0 11.1111vw;}
            .select-input-wrap{
                margin-bottom: 4.1667vw;
                .select-label{color: #191919; font-size: 4.4444vw; font-weight: 600; line-height: 1.25;}
            }
            .box-btn{
                button{width: 100%;}
            }
        }
        .call-back{
            position: relative;
            .btn-check{
                position: absolute; top: 0; right: 0;
                label{width: auto;}
            }
        }
        .change-reason{
            .textarea-wrap{
                margin-top: 2.0833vw;
                .box-textarea{height: 18.75vw;}
            }
        }
        .detail-sec-desc{
            & + .btn-check-type2{margin-top: 2.0833vw;}
            .btn-text-arrow{margin-top: 1.3889vw;}
        }
        .appli-identification{
            .identifi-box{
                .ico-list{
                    margin-bottom: 0;
                    li{
                        width: 9.1667vw; height: 18.8889vw;
                        img{width: 9.1667vw; height: 9.1667vw;}
                    }
                }
            }
            .identifi-complete{
                border: 1px solid #ddd; padding: 6.9444vw 0 8.3333vw; text-align: center; border-radius: 2.7778vw;
                img{width: 20.8333vw; height: 20.8333vw;}
                p{font-size: 4.4444vw; font-weight: 600; line-height: 1.19; color: #191919;}
            } 
        }
        .msg-box{
            padding-bottom: 11.1111vw;
            .msg-icon{width: 36.1111vw; height: auto; margin: 0 auto 4.1667vw; display: block;}
            .msg-txt{font-size: 4.4444vw; text-align: center; font-weight: 600; color: #191919; line-height: 1.25;}
            .txt{font-size: 4.1667vw; text-align: center; line-height: 1.4; margin-top: 1.3889vw;}
        }
    }

    .plan-slider{    
        position: relative;
        .count-slide { overflow: hidden;height:44.1944vw; margin:0 -5.5556vw 8.3333vw -5.5556vw;}
        .slide {
            padding:6.9444vw 5.5556vw; background:#f8f4ff;
            .plan-dtil {      
                .plan-tit {display:block;  font-size: 4.4444vw;font-weight: bold;letter-spacing: -0.0889vw;text-align: left;color: #191919;}     
                .spc {
                  position:relative; margin-top:4.1667vw;padding-bottom: 6.9444vw;
                    .spc-view {
                        display:flex;
                        span{
                            position: relative; margin:0; padding-left: 6.3889vw; font-size: 3.8889vw;line-height: 1.43;letter-spacing: -0.0778vw; text-align: left;color: #191919;
                            & + span {margin-left:3.4722vw; }      
                            &::before {content:'';position:absolute;left:0;top:50%; transform: translateY(-50%);  width:5.5556vw;height:100%;}
                        }
                       span.vol::before {background:url('/assets/images/icon/ico-blk-call-fill.svg') no-repeat center;background-size:5.5556vw;}
                       span.limit::before {background:url('/assets/images/icon/ico-blk-data.svg') no-repeat center; background-size:5.5556vw;}
                       span.supply::before {background:url('/assets/images/icon/ico-blk-sms-fill.svg') no-repeat center;background-size:5.5556vw;}
                    }
                    .txt {
                        position: absolute;left: 0;bottom: 0;margin-top:2.0833vw;padding-left:2.2222vw; font-size: 3.8889vw;letter-spacing: -0.0778vw;text-align: left;color: #959595;
                        &::before {content: ''; position: absolute;left:0;top:0.8333vw; width:0.8333vw;height:0.8333vw;border-radius: 50%; background: #959595;}
                    }
                }    
                .price-box {
                    display: flex;justify-content: flex-end; align-items: flex-end; margin-top:4.1667vw;text-align: right;    
                    .price {margin-right:1.3889vw; font-size: 4.1667vw;letter-spacing: -0.0833vw;text-align: right;color: #959595; text-decoration: line-through;}
                    .dc-price {
                        .dc-txt {font-size: 6.1111vw;font-weight: bold; letter-spacing: -0.1222vw;text-align: right;color: #662d91;}
                    }
                }    
            }
            &01 { background-color: #f8f4ff;}
            &02 { background-color: #f1f7ff;}
            &03 { background-color: #f3fff9;}
        }
        .slick-control {
            position: absolute;left:0;bottom:6.9444vw;
            .slick-cunt {
                display: flex;align-items: center; padding: 0.6944vw 1.6667vw;border-radius: 13.8889vw;background-color: #dbe6e5;
                .first-page {min-width: 2.5vw; font-size: 3.3333vw;font-weight: 600;letter-spacing: -0.0667vw;text-align: center;color: #666;}
                .end-page {
                    position:relative;min-width: 2.5vw; margin-left:1.1111vw;padding-left:1.6667vw;  font-size: 3.3333vw;font-weight: 600;letter-spacing: -0.0667vw;text-align: center;color: #959595; opacity: 0.6;
                    &::before {content:''; position:absolute; left:0.1778vw;top:50%;width:0.2778vw;height:2.0833vw; background: #959595; transform: translateY(-50%) rotate(20deg);opacity: 0.6;}
                }
            }
        }
    }

    .num-change-info {
        .num-process-wrap {
            li {
                .normal-txt {font-weight: normal;}
                .blt-list {
                   margin-top:1.3889vw;
                    li {
                        position: relative; padding-left:2.0833vw;line-height: 1.36;font-size: 3.6111vw;color:#666;letter-spacing:-0.1389vw;text-align:left;
                        &::before {content:'*'; position: absolute; left:0; top:0.2778vw;}
                        & + li {margin-top:0.6944vw;}
                    }
                }
            }
        }
        .change-nberview {
            padding: 8.3333vw 0; border-radius: 10px;border: solid 1px #ddd;background-color: #fff;text-align: center;
            .txt01 {
                font-size: 4.4444vw;font-weight: 600; line-height: 1.2; letter-spacing: -0.0556vw; text-align: center;color: #191919;
                strong {color:#662d91;font-size:4.4444vw;font-weight:600;}
            }
            .txt02 {
                margin-top:2.7778vw; font-size: 4.1667vw; line-height: 1.19; letter-spacing: -0.0444vw;text-align: center;color: #191919;text-align: center;
                strong {color:#662d91;font-size:4.1667vw;font-weight: 600;}
            }
            & + .btns {margin-top:4.1667vw;}
        }  
    }
}

.layer-popup{
    &.my{
        .usim-change{
            .box-tit{font-size: 4.1667vw; font-weight: 600; margin-bottom: 2.7778vw; color: #191919; text-align: left;}
            .usim-change-info{
                border: 1px solid #ddd; border-radius: 2.7778vw; padding: 5.5556vw; text-align: left; 
                ul{
                    li{color: #191919;}
                }
            }
            .txt-sub{
                & + .txt-sub{margin-top: 1.3889vw;}
            }
        }
    }
}

// 유심카드 촬영
.ocr-view{
    position: fixed; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5);
    .inner{
        margin-top: 22.2222vw;
    }
    .ocr {
        &-frame {
            width:100%; height: 58.3333vw; background-color: $white; margin: 0 auto;border-radius: 2.7778vw;
        }
        &-txt {
            margin-top: 11.1111vw;
            strong{@include subContsTitle; color: $white;}
            p{@include smallTxt; color: $gray02;margin-top: 2.7778vw;}
        }
    }
}

.point-layer-wrp {
    overflow-y: auto;height:50vh;
    .point-view {
        padding-bottom:5.5556vw;
        .view-line {
            padding:5.5556vw 5.5556vw 4.1667vw 5.5556vw;border-radius: 2.7778vw;border:1px solid #ddd;
            .tite {display: block; font-size: 4.4444vw;font-weight: 600;line-height: 1.5;letter-spacing: -0.0444vw;text-align: left;color: #191919;}
            .box-select {width:100%; margin-top:2.0833vw;}
            .box-select + dl {margin-top:5.5556vw;}
        }
        dl {
            display: flex; flex-wrap: wrap;
            dt {width:50%; margin-bottom:2.7778vw; font-size:4.1667vw; line-height:1; letter-spacing: -0.0444vw; text-align: left; color: #666;}
            dd {width:50%;margin-bottom:2.77789vw; font-size:4.1667vw; font-weight: 600; line-height: 1; letter-spacing: -0.0444vw;text-align: right; color: #191919;}
        }            
    }
    .txt {font-size:4.1667vw;line-height: 1.5;letter-spacing: -0.0444vw; color: #666; text-align: left;}
    .itm-choice {
        margin-top:8.3333vw;
        p { font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}
        ul {
            display: flex;margin-top:4.1667vw;
            li + li {margin-left:24.1667vw;}
        }

    }
    .sb-tit { font-size: 4.1667vw; font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #191919;}
    .sb-txt {margin:2.7778vw 0 8.3333vw 0; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}
    .point-sum {
        dl {
            display:flex; flex-wrap: wrap;justify-content: space-between;align-items: center;
            dt {font-size: 5vw; line-height: 1.11; letter-spacing: -0.1vw; text-align: left; color: #666;}
            dd {font-size: 5vw; font-weight: 600; line-height: 1.11; letter-spacing: -0.1vw;text-align: right; color: #662d91;}
        }
        .txt-sum { margin-top:8.3333vw;font-size: 4.1667vw;line-height: 1.33;  letter-spacing: -0.0833vw;text-align: left;color: #666;}
    }


}
/*** 마이메인 ***/
.mymain {
    .page-tit-s {margin:16.6667vw 0 4.1667vw;@include contsTitle;font-weight:600;}
    .bill-plan {margin-bottom:11.1111vw;}

    //그래프
    #usageInfo{
        min-height: 66.6667vw;
    }    
    .box-usage-wrap {
        min-height: 66.6667vw; margin-bottom:8.3333vw;text-align:center;
        .circle-wrap{
            position:relative;width:55.5556vw;height:55.5556vw;flex-shrink:0;margin:0 auto;
            svg {
                width:55.5556vw;height:55.5556vw;
                transform: rotate(-0.25turn) rotateX(180deg);
                position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;height:100%;
            }
            .background {
                fill: none;
                stroke: $gray01;
                stroke-width:1;
            }
            .progress {
                position: relative;
                fill: none;
                stroke: $purple;
                stroke-dasharray: 100 100;
                stroke-dashoffset: 100;
                stroke-linecap: round;
                stroke-width:1;
                transition: stroke-dashoffset 1s ease-in-out;
            }
            .txt-data {
                position:absolute;top:50%;left:50%;@include mainContsTitle;transform: translate(-50%,-50%);font-size:0;
                &>span {font-size:3.6111vw;color:$black;}
                &>a {
                    display: block; white-space: nowrap;
                    @include mainContsTitle;
                    .ico-arrow-right {margin-top:0.9722vw;vertical-align:top;}
                }
            }
        }
        .feature {
            display:block;margin-top:5.5556vw;text-align:center;
            &>span {
                font-size:3.6111vw;
                em {@include defaultTxt;}
                &+span {margin-left:11.1111vw;}
            }
        }
    }
    // 실시간 요금 안내
    .btn-box{
        .btn-text-gray {
            & > span:not(.ico-arrow-right){@include defaultTxt;}
        }
    }
    .my-charge {
        position: relative; height:13.8889vw;
        .btn {width:88.8889vw; height:13.8889vw;  border-radius: 1.9444vw; background-color: #191919; text-align: center; color:#fff; font-size: 4.1667vw; font-weight: 600;  line-height: 1.2;} 
        .charge-txt {visibility: hidden; position: absolute; bottom:-7.9444vw; opacity: 0; transition: all .5s; z-index: -1;}
        &.open .btn {visibility: hidden; position:relative; opacity: 0; z-index: -2;} 
        &.open .charge-txt {visibility: visible; bottom:0; opacity: 1; z-index: 1;transition: all .5s ease-in-out; z-index:1;} 
        .dot-loading {
            position: absolute;left:0; right:0; top: 0; display: flex; width: 100%; height: 13.8889vw;  justify-content: center; align-items: center; background:#ffffffa3; z-index: -1; opacity: 0; 
            &.loading  {opacity: 1; z-index: 2;}
            .dot-flashing {
                position: relative; width: 2.7778vw; height: 2.7778vw; border-radius: 50%; background-color: #5903f8; color: #5903f8; animation: dot-flashing 1s infinite linear alternate;  animation-delay: 0.5s;
                &::before, &::after {content: ""; display: inline-block; position: absolute; top: 0; }
                &::before { left: -5.5556vw; width: 2.7778vw; height: 2.7778vw; border-radius: 50%; background-color: #5903f8; color: #5903f8; animation: dot-flashing 1s infinite alternate; animation-delay: 0s;}
                &::after {left: 5.5556vw;width: 2.7778vw;height: 2.7778vw;border-radius: 50%;background-color: #5903f8;color: #5903f8;animation: dot-flashing 1s infinite alternate;animation-delay: 1s; }
            }
        }    
        @keyframes dot-flashing {
            0% {
                background-color: #240053;
            }
            50%, 100% {
                background-color: rgba(114, 83, 250, 0.5);
            }
        }     
    }

    .cardList-wrap {
        margin-top:16.6667vw;
        //청구요금 정보
        &.point-info-card  {
            min-height: 83.3333vw;
            dl {
                dt {width:auto;color:$black;}
                dd {width:auto;font-weight:normal;}
            }
            .money-right{
                min-height: 58.75vw;               
            }
            .btn-group {margin-top:4.1667vw;}
        }
        .cardList {
            position: relative; overflow: hidden;
            &.money-right {
                dl {
                    justify-content:space-between;border-top:none;
                    dt {
                        color:$black;
                        em {
                            margin-top:0;
                            &+p {margin-top:1.1111vw;}
                        }                        
                    }
                    dd {
                        font-weight:normal;
                        .ico-arrow-right {vertical-align:top;}
                    }
                    &:first-of-type {align-items:center;margin-bottom:5.5556vw;padding-bottom:5.5556vw;border-bottom:0.2778vw solid $gray02;}
                    &+dl {margin-top:3.3333vw;padding-top:0;}
                }
            }
        //나의 가입정보
            &.using-goods {
                min-height: 91.1111vw;
                dl {
                    dt {padding-bottom:3.3333vw;}
                    dd {
                        padding-bottom:3.3333vw;text-align:right;
                        .ico-arrow-right {vertical-align:top;}
                    }
                }
                .item-top {
                    .btn-box {
                        .btn-text-arrow {
                            & + .btn-text-arrow {margin-left:2.7778vw;}
                        }
                    }
                }
                .btns {margin-top:4.7222vw;}
            }
        //내가 이용중인 상품
            &.using-service {
                dl {
                    margin-bottom:0 !important;padding-bottom:0 !important;border-bottom:none !important;
                    dt {width:25.0000vw;}
                    dd {
                        width:calc(100% - 25.0000vw);
                        &>span {font-weight:600;}
                    }
                }
                &+.box-btn {margin-top:8.3333vw;text-align:center;}
            }
        }
    }
    //제휴카드 안내
    .use-card-list {
        .card-list {
            &>li {
                a {justify-content:flex-start;align-items:center;}
                .card-img-wrap {
                    flex:1 0 8.1944vw;width:8.1944vw;
                    img {width:8.1944vw;height:12.7778vw}
                }
                .card-desc {width:calc(100% - 8.1944vw);margin-left:5.5556vw;}
            }
        }
        .box-btn {
            margin-top:8.3333vw;text-align:center;
            a {@include mainTxt;}
        }
    }
    //배너(slide)
    .banner-slide {
        .page-tit-s {margin-bottom:10.5556vw;}
        .my-main-slick {
            .slick-slide{
                margin: 0 1.3889vw; background-color: #EFF1F5; border-radius: 2.7778vw; overflow: hidden; transition: 0.3s;
                &.slick-active{
                    background-color: $purple;
                    *{
                        color: $white; transition: 0.3s;
                    }
                }
                a{
                    display: block; height: 70.4167vw; padding: 6.9444vw 0; background-position: 50% 17.7778vw; background-repeat: no-repeat; background-size: 30.5556vw; text-align: center;
                    * {
                        color: $black;
                    }
                    &.market{
                        background-image: url('/assets/images/main/icon-mymain-market.png');
                    }
                    &.park{
                        background-image: url('/assets/images/main/icon-mymain-park.png');
                    }
                    &.sale{
                        background-image: url('/assets/images/main/icon-mymain-sale.png');
                    }
                    &.service{
                        background-image: url('/assets/images/main/icon-mymain-service.png');
                    }
                    &.buy{
                        background-image: url('/assets/images/main/icon-mymain-buy.png');
                    }
                    &.join{
                        background-image: url('/assets/images/main/icon-mymain-join.png');
                    }
                    p{
                        @include smallTxt;
                    }
                    dl{
                        dt{
                            margin-bottom: 39.8611vw; @include mainTxt;
                        }
                        dd{
                            @include chipTxt; color: $black;
                        }
                    }
                }
            }
            .slick-dots {
                bottom:-11.9444vw;
            }
        }
    }

    //프리미엄 휴대폰 안내
    .product-imgCard {
        margin-top:7.9167vw;
        .cardList {
            gap:7.3611vw;border:0;border-radius:0;padding:0;
            .item-left {
                max-width:31.5278vw;
                .item-img {width:31.5278vw;height:37.5000vw;}
            }
            .item-right {
                width:calc(100% - 38.8889vw);
                .item-txt {
                    &>p {margin-bottom:4.1667vw;@include mainTxt;}
                    .item-txt-tit {@include defaultTxt;}
                    .desc {margin-top:1.3889vw;@include smallTxt;}
                    .box-btn {margin-top:5.8333vw;}
                }
            }
        }
    }
    //친구추천
    .my-recommend {
        margin-top:16.6667vw;background:#f1f1fe;
        .recommend-tit {
            position:relative;min-height:75.0000vw;padding:11.1111vw 0 0 8.3333vw;
            @include backgroundImg('/assets/images/subpage/mymain-recommend-bg.png', center top, 100% 59.4444vw);
            &::after {
                content:'';display:block;position:absolute;right:0;top:7.2222vw;width:80.2778vw;height:67.7778vw;
                @include backgroundImg('/assets/images/subpage/mymain-recommend-img.png', right top, 80.2778vw 67.7778vw);
            }
            .tit {position:relative; @include contsTitle;font-weight:600;color:$white; z-index: 10;}
            .txt {margin-top:2.7778vw;@include smallTxt;color:$white;}
            .img-alpha {display:inline-block;width:6.6667vw;vertical-align: middle;}
        }
        .recommend-info {
            padding:9.7222vw 5.5556vw 8.3333vw;
            .card-box {
                padding:8.3333vw 5.5556vw;border-radius:1.9444vw;background:$white;
                dl {
                    display:flex;justify-content:flex-start;flex-wrap:wrap;align-items:center;
                    dt {
                        width:45vw;padding-bottom:3.3333vw;@include smallTxt;color:$black;
                        &:last-of-type {padding:0;}
                    }
                    dd {
                        width:calc(100% - 45vw);padding-bottom:3.3333vw;@include defaultTxt;text-align:right;
                        &:last-of-type {padding:0;}
                        .ico-arrow-right {margin-left:4.1667vw;vertical-align:top;}
                    }
                }
                .invitation {
                    margin-top:5.5556vw;padding-top:5.5556vw;border-top:0.2778vw solid $gray02;
                    .share {
                        display:flex;justify-content:flex-end;gap:2.7778vw;
                        img {width:11.1111vw;height:11.1111vw;}
                    }
                }
                .invitation + .btn-wrp {margin-top:5.5556vw; padding-top:5.5556vw;border-top: 0.2778vw solid #ddd;}
            }
        }
    }
    //배너(텍스트+이미지)
    .banner-mix {
        display:flex;flex-direction:column;gap:4.1667vw;margin-top:23.1944vw;
        .bnr {
            display:flex;justify-content:space-between;align-items:center;padding:8.3333vw 3.0556vw 8.3333vw 6.9444vw;border-radius:2.7778vw;
            .txt {
                width:calc(100% - 30.0000vw);
                strong {
                    @include subContsTitle;
                    &+a {margin-top:5.5556vw;}
                }
            }
            .img {
                width:30.0000vw;height:30.0000vw;
                img {width:100%;}
            }
        }
    }
    //배너(통이미지)
    .banner-img {
        display:flex;justify-content:space-between;gap:0 3.3333vw;
        a {
            border-radius:2.7778vw;
            img {width:100%;}
        }
    }
    //준회원 로그인시
    .association-guide {
        display:flex;justify-content:flex-start;align-items:flex-start;padding:8.3333vw 2.7778vw 0;border-radius:1.9444vw;background:#fff7e6;
        .img {
            width:32.7778vw;align-self:flex-end;box-sizing:border-box;
            img {vertical-align:bottom;}
        }
        .info {
            width:calc(100% - 32.7778vw);box-sizing:border-box;padding-left:5.4167vw;
            strong {display:block;@include subContsTitle;}
            p {margin-top:2.7778vw;@include smallTxt;}
            a {margin-top:13.3333vw; @include mainTxt;}
        }
    }
    //내가 보유한 포인트
    .my-point {
        .box-point {
            padding:5.5556vw 4.1667vw;border-radius:1.9444vw;background:#f8f8f8;
            dl {
                display:flex;justify-content:space-between;align-items:center;
                dt {@include defaultTxt;font-weight:normal;}
                dd {@include defaultTxt;text-align:right;}
            }
        }
        .btn-group {
            display:flex;justify-content:space-between;align-items:flex-start;margin-top:4.1667vw;
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
.my {
    .mb80{
        margin-bottom: 5.7143vw;
    }
    .box-my-use {
        padding: 0 2.8571vw 5.7143vw;
        .box-select{
            margin-bottom: 4.2857vw;
        }
        .user-txt{
            & > span{@include fontInherit; color: $purple;}
        }
        .box-btn{
            margin-top: 0.4286vw;
            .user-product {
                display: flex; align-items: center; justify-content: space-between; margin-bottom: 2.1429vw;
                font-weight: 700;
            }
        }
        .ico-wrap{
            display: flex;gap: 2.1429vw;
            span{
                display: block;position: relative; padding-left: 3.9286vw;
                font-weight: 600;
                &.ico-call {
                    @include backgroundImg('/assets/images/icon/ico-call.png', left center,2.1429vw 2.1429vw); padding-right: 2.1429vw; width: auto;height:auto;
                    &::after {
                        content: ''; display: block; position: absolute; top: 50%; right: 0; width: 0.1429vw; height: 2.1429vw; background-color: $gray02; transform: translateY(-50%);
                    }
                }
                &.ico-sms {
                    @include backgroundImg('/assets/images/icon/ico-sms.png', left center,2.1429vw 2.1429vw);width: auto;height:auto;
                }
            }
        }
    }
    .access-error-type{
        .popup-body{
            p{
                & + p{
                    margin-top: 0.7143vw;
                }
            }
        }
    }
    // 요금제변경
    .rate-plan{
        &-change {
            .is-filter-warp{
                .is-filter-list{padding: 0 2.8571vw 0 2.8571vw;width: 51.4286vw;}
            }
            .cardList-wrap{
                & + section {
                    margin-top: 5.7143vw;
                    .main-txt{margin-bottom: 2.1429vw;}
                    .is-filter-list{width: 100%;}
                }
                .one-title{
                    dt{
                        strong{
                            display: block; margin-bottom: 0.7143vw;
                        }
                    }
                }
            }
        }
        &-no-change {
            .cardList-wrap{
                margin-bottom: 5.7143vw;
                .one-title{
                    dt{
                        strong{
                            display: block; margin-bottom: 0.7143vw;
                        }
                    }
                }
            }
        }
        &-popup{
            &-05{
                .table-title {
                    margin-bottom: 1.4286vw;
                }
                .table-type-04{
                    .center{ text-align: center; }
                    .discount-pay{
                        font-size: 2.8571vw;
                    }
                }
                .pay-amount{
                    padding-top: 0;
                }
            }
            &-06{
                .c-unit{
                    &:first-child{
                        border-bottom:0.1429vw solid $gray02; padding-bottom: 4.2857vw; margin-bottom: 4.2857vw;
                    }
                }
            }
            &-barogo{
                .c-unit{
                    & + .c-unit{margin-top: 3.5714vw;}
                    .unit-sec-tit{margin-bottom: 0;}
                }
            }
        }
    }
    //명의변경
    .subscript-name-change{
        .box-select{margin-bottom: 5.7143vw;}
        & > section {
            margin-top: 4.2857vw;
        }
        .sub-contents-title{
            display: block; margin-bottom: 0.7143vw;
            & + .main-txt{margin-top: 2.8571vw;}
        }
        .main-txt{
            display: block; margin-bottom: 0.7143vw;
        }
        p{
            & + .num-process-wrap{margin-top: 1.4286vw;}
        }
        .num-process-wrap{
            & + .notification{margin-top: 1.4286vw;}
            & + .btns {margin-top: 2.1429vw;}
        }
    }
    .subscript-name-certi{
        .input-group {
            margin-top: 2.8571vw;
        }
    }
    .subscript-name-exist{
        .box-input {
            & + .box-input {margin-top: 2.8571vw;}
        }
    }
    .subscript-info-set{
        .is-filter-warp{
            .is-filter {
                .is-filter-list{
                    width: 100%; padding: 0 2.8571vw 0 2.8571vw;
                }
            }
        }
    }
    .subscript-name-complete{
        .page-tit{
            padding-bottom: 20.7143vw; margin-bottom: 2.8571vw;
            @include backgroundImg('/assets/images/icon/ico-member-change.svg', center bottom,18.5714vw 18.5714vw);
        }
        .cardList-wrap{
            .cardList {
                dt{width: 50%;}
                dd{width: 50%;}
            }
            & + .cardList-wrap{margin-top: 4.2857vw;}
        }
        .notification{margin-top: 5.7143vw;}
    }
    .subscript-name-new{
        .box-input {
            & + .box-input {margin-top: 2.8571vw;}
        }
        .radio-group-wrap{margin-top: 2.8571vw;}
    }
    .phone-installment-bottomsheet{
        .cardList-wrap{
            .cardList {
                dt{width: 30%;}
                dd{width: 70%;}
            }
            p{font-weight: 400;margin-top: 1.4286vw;}
        }
    }
    .subscript-name-assignee{
        .agree-wrap{
            margin-top: 0; padding-bottom: 4.2857vw;margin-bottom: 4.2857vw; border-bottom: 0.1429vw solid $gray02;
        }
    }
    .subscript-name-assignee-new{
        & > .box-input {
            & + .box-input {margin-top: 2.8571vw;}
        }
        .input-depth{margin-top: 2.8571vw;}
    }
    .subscript-name-assignee-exist{
        & > .box-input {
            & + .box-input {margin-top: 2.8571vw;}
        }
    }
    .subscript-write-info{
        & > section{
            & + section {margin-top: 2.8571vw;}
        }
        .notification{margin-top: 5.7143vw;}
        .info-card{
            .box-input {
                & + .box-input{margin-top: 2.8571vw;}
            }
            .input-group{margin-top: 2.8571vw;}
            .box-btn{margin-top: 2.1429vw;}
        }
        .info-account{
            display: none;
            .box-select {
                & + .box-input{margin-top: 2.8571vw;}
            }
            .box-btn{margin-top: 2.1429vw;}
        }
    }

    //번호변경
    .num-change-info{
        & > section{
            margin-top: 5.7143vw;
            .sub-contents-title{display: block; margin-bottom: 0.7143vw;}
            .main-txt{display: block;margin-bottom: 1.4286vw;}
        }
        .notification{
            .box-btn{margin-bottom: 1.4286vw;}
        }
    }
    .num-change-want{
        & > section {
            margin-top: 2.8571vw;
        }
        .box-input-phone{
            .input-wrap{
                &:nth-of-type(1){
                    width: 11.1429vw;
                }
                &:nth-of-type(n+2){
                    margin-left: 2.1429vw;max-width: none;width: calc((100% - 15.4286vw) / 2);
                    input{padding: 2.2857vw 2.0714vw;}
                }
            }
        }
        .radio-group-wrap{
            .btn-radio {
                & + .btn-radio{
                    margin-top: 1.4286vw;
                }
            }
            .box-btn{
                margin-top: 1.4286vw;
            }
        }
    }
    .num-change-complete{
        & > section {
            margin-top: 4.2857vw;
            .main-txt {
                display: block; margin-bottom: 0.7143vw;
            }
            .num-process-wrap{
                margin-top: 2.8571vw;
                & + p{
                    margin-top: 1.4286vw;
                    & + .box-btn{margin-top: 2.1429vw;}
                }
            }
        }
        .box-ico-txt{
            background-image: url('/assets/images/icon/ico-num-change.svg');
            & + p{margin-top: 5.7143vw;}
        }
    }
    .box-note {
        &>button {display:block;width:100%;position:relative;margin-bottom:1.4286vw; text-align:left;}
        .acc-conts {padding:2.2857vw 2.8571vw;border-radius:1.4286vw;background:$gray01;}
    }
    .cardList-wrap{padding: 0;}
    .cont-tit{

        margin-bottom: 1.0714vw;
    }
    // 휴대폰 소액결제내역
    .bill-history{
        .cardList-wrap{margin-top: 1.0714vw;}
        .page-title{
            & + .box-select{margin-top: 1.1429vw;}
        }
    }
    .bill-plan{
        margin: 0 0 5.7143vw 0;
        h1{
            margin: 2.8571vw 0 2.1429vw 0; font-weight: 700;
            span{
                display: block; margin-bottom: 0.7143vw;
                font-weight: 600;
            }
        }
        ul{
            li{
                display: inline-flex; padding-left: 2.8571vw;
                color: $gray05;
                &:nth-child(1){
                    @include backgroundImg('/assets/images/icon/ico-call-fill.svg', 0 50%, 2.8571vw)
                }
                &:nth-child(2){
                    @include backgroundImg('/assets/images/icon/ico-sms-fill.svg', 0 50%, 2.8571vw)
                }
            }
        }
        .bill-plan-top{
            display: flex; justify-content: space-between; align-items: center; margin-bottom: 2.1429vw;
            .chip-wrap {
                img{height: 2.5714vw;}
            }
            .button-wrap{
                display: flex; gap: 1.4286vw;
                button{
                    width: 2.8571vw; height: 2.8571vw;
                }
                .ico-num-setting{
                    @include backgroundImg('/assets/images/icon/ico-num-setting.svg',center center,contain);
                }
                .ico-num-plus{
                    @include backgroundImg('/assets/images/icon/ico-num-plus.svg',center center,contain);
                }
            }
        }
    }
    // 이용한도 설정
    .bill-setting-limit{
        padding-top: 5.7143vw;
        .money-right{
            margin-top: 1.4286vw;
            dt{
                width:60% !important;
            }
            dd{
                width:40% !important;
            }
        }
        .main-txt{margin-bottom: 0.7143vw;}
        section + section{margin-top: 5.7143vw;}
        .box-select{
            & + section{margin-top: 2.8571vw;}
        }
        .box-input{
            margin-top: 2.8571vw;
            .input-unit{
                top:7.8571vw;
            }
            .bang-notice{
                margin-top: 1.4286vw;
            }
        }
        .accordian{
            margin-top: 5.7143vw;

        }
    }
    //청구요금 조회
    .bill-inquiry{
        .month{
            margin-bottom: 5.7143vw; padding: 2.7143vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;
            .period{
                margin-bottom: 2.1429vw;
                dt{
                    margin-bottom: 0.7143vw;
                }
                dd{
                    color: $gray04;
                }
            }
            .detail{
                display: flex; justify-content: space-between; margin-bottom: 2.1429vw;
                strong{
                    color: $purple;
                }
                a{
                    display: inline-flex; align-items: center;
                }
            }
            .how{
                display: flex; flex-wrap: wrap; margin-bottom: 2.8571vw; padding-top: 2.8571vw; border-top: 0.1429vw solid $gray02;
                dt{
                   display: inline-flex; align-items: center; width: 15.7143vw;
                   &:first-of-type{
                    margin-bottom: 1.4286vw;
                   }
                }
                dd{
                    display: inline-flex;
                    &:first-of-type{
                        margin-bottom: 1.4286vw;
                    }
                    a{
                        display: inline-flex; align-items: center; width: 24.2857vw; height: auto;
                        &.ico-arrow-right{
                            background-size: 2.8571vw 2.8571vw; padding-right: 3.5714vw;
                        }
                    }
                }
            }
        }
        .past{
            margin-bottom: 5.7143vw;
            h2{
                margin-bottom: 2.1429vw;
            }
            ul{

                li{
                    border-top: 0.1429vw solid $gray02;
                    a{
                        width: 100%; height: auto; background-position: 42.1429vw 50%;
                        background-size: 2.8571vw 2.8571vw; padding-right: 4.2857vw; display: flex; justify-content: space-between; align-items: center;
                        span{
                            display: inline-block;
                           line-height:8.4286vw; text-align: center;
                            &:nth-child(1){
                                width: 15.0000vw;
                            }
                            &:nth-child(2){
                                width: 10.7143vw;
                                // margin-right: 3.5714vw;
                            }
                            &:nth-child(3){
                                text-align: right; flex-shrink: 0;
                            }
                        }
                    }
                    &.txt-rd{
                        a{
                            span{
                                color: $red;
                            }
                        }
                    }
                    &:last-child{
                        border-bottom:0.1429vw solid $gray02;
                    }
                }
            }
        }
    }

    //요금 상세내역
    .bill-detail{
        .page-tit {
            .default-txt{display: block; margin-bottom: 0.7143vw;}
        }
        .table-title{
            margin-bottom: 4.2857vw;
            h2{
                span{ @include fontInherit;}
            }
            .bang-notice{
                margin-top: 0.7143vw;
               span{@include fontInherit;}
            }
        }
       .btn-box{
         margin-top: 5.7143vw;
         li{
            & + li{
                margin-top: 2.1429vw;
            }
         }
       }
        .accordian{
            margin-top: 5.7143vw;
            .notification {
                .box-btn{margin: 0.8571vw 0;}
            }
        }
        .cardList-wrap {
            margin-top: 1.4286vw;
            .table-result {
                dl {
                    align-items: center;
                    dt{
                        width: 50%;
                    }
                    dd {
                        width: 50%; text-align: right;
                        *{@include fontInherit;}
                    }
                }
            }
        }
    }

    // 청구서 수신방법 변경
    .bill-method-change{
        .contents-title{
            margin-bottom: 2.8571vw; font-weight: 700;
        }
        .cardList-wrap{
            margin-bottom: 5.7143vw;
        }
    }

    // 청구서 재발행 신청
    .bill-reissue{
        .notification{
            margin-top: 1.4286vw;
        }
        .box-select{
            margin-bottom: 5.7143vw;
        }
        .inquir-box{
            .default-txt{
                margin-bottom: 1.1429vw;
            }
        }
    }

    // 청구서 재발행 신청서 작성
    .bill-reissue-form{
        .box-select{
            margin-bottom: 5.7143vw;
        }
        .inquir-box{
            .default-txt{
                margin-bottom: 1.1429vw;
            }
        }
    }

    .bill-reissue-phone{
        .page-tit{
            & + .box-flex{
                margin-bottom: 5.7143vw;
            }
        }
    }

    // 요금 즉시 납부
    .bill-immediate{
        .info-txt{
           margin-top: 4.2857vw;
        }
        .month{
            padding: 2.7143vw; padding-top:4.2857vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;
            .total{
                display: flex; justify-content: space-between; margin-bottom: 2.8571vw; padding:0 2.6429vw; border-bottom: 0.1429vw solid $gray02; @include backgroundImg('/assets/images/icon/ico-plus-big.svg', 50% 0 , 5.7143vw);
                dl{
                   margin-bottom: 4.2857vw;  text-align: center;
                    dt{
                        margin-bottom: 0.7143vw;
                    }
                    dd{
                        span{
                            @include fontInherit;
                        }
                    }
                }
            }
            h2{
               display: block; margin-bottom: 2.8571vw;
            }
            .period{
                margin-bottom: 2.8571vw;
                dt{
                    margin-bottom: 0.7143vw;
                }
                dd{
                    color: $gray05;
                }
            }
        }
        .detail{
            display: flex; justify-content: space-between;
            strong{
                color: $purple;
                span{@include fontInherit;}
            }
            a{
                display: inline-flex; align-items: center;
            }
        }
        .accordian{
            margin-top: 2.8572vw;
        }
    }

    //요금 즉시 납부 카드 소유주 정보 입력
    .bill-immediate-payment{
        fieldset{
            margin-bottom: 3.5714vw;
            > div{
                margin-bottom:2.8571vw;
            }
            .inquir-box{
                h2{
                    margin-bottom: 1.1429vw;
                }
            }
        }
    }

    // 신용카드 결제 정보 입력
    .bill-credit-form{
        fieldset{
            .box-input{
                .input-unit{
                    top: auto; bottom: 2.2857vw; transform: translateY(0);
                }
                .input-label{
                    span{
                        display: block; color: $gray05;
                    }
                }
                .bang-notice{
                    margin-top: 0.7143vw;
                }
            }
            .box-select{
                .select-label{
                    span{
                        color: $gray05;
                    }
                }
                .bang-notice{
                    margin-top:1.4286vw;
                }
            }
            > div{margin-bottom: 2.1429vw;}
        }
    }

    // 앱마켓 결제 내역
    .bill-app {
        .page-tit{
            & > .notification{
                margin-top: 0.7143vw;
            }
        }
        .default-txt{margin-bottom: 1.4286vw;}
        .inquir-box{
            margin-bottom:2.8571vw;
            .btn-radio label{
                display: flex; align-items: center; justify-content: center;min-height: 6.2857vw; padding: 0; font-size: 2.0000vw;
            }
        }
        .box-total {
            margin-top: 1.4286vw;
            .total-wrap {
                display: flex; justify-content: space-between; align-items: center; border-radius: 1.0000vw; border: 0.1429vw solid $gray02; padding: 2.8571vw;

            }
        }
        .box-list{margin-top: 4.2857vw}
        .cardList-wrap{
            .cardList{
                dl{
                    dt{
                        width:35%;
                    }
                    dd{
                        width:65%;
                        span{@include fontInherit; display: block;}
                    }
                }
            }
            & + .box-btn {
                margin-top: 1.4286vw;
            }

        }
        .accordian{margin-top: 5.7143vw;}
    }

    //휴대폰 소액 결제 차단 / 해제 안내
    .bill-release-info{
        section{
            margin-top:5.7143vw;
        }
        .text-btn-gray{
            p{
                .agree{
                color:$black; font-weight: 600; margin-left:2.1429vw;
                }
            }

        }
        .cardList{
            margin-top:2.1429vw;
        }
        .inquir-box{
            margin-top:2.1429vw;
            .default-txt{
                margin-bottom:1.1429vw;
            }
        }
    }

    // 휴대폰 정보 변경 안내
    .subscript-phone-change{
        .speech-bubble{
            margin-bottom: 4.2857vw; padding: 2.8571vw; background-color: $bg; border-radius: 1.4286vw;
            strong{display: block; margin-bottom: 0.7143vw;}
        }
        .process-box{
            .default-txt{margin-bottom: 1.4286vw;}
        }
        .num-process-wrap{
            li{
                padding-bottom: 4.2857vw;
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
        .no-regist{
            .img{
                @include backgroundImg('/assets/images/icon/ico-calender-60.svg',center center, contain); width: 18.5714vw; height: 18.5714vw;  margin:0 auto 2.1429vw;
            }
            p{
                margin-bottom: 1.4286vw; text-align: center;
            }
            dl{
                text-align: center;
                dt{
                    margin-bottom: 0.7143vw;
                }
            }
        }
    }

    // 내 가입정보 안내
    .my-join-info{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 4.2857vw;}
            .cardList{
                dl{gap: 2.1429vw 1.4286vw;}
                dt{width: calc(40% - 0.7143vw); padding: 0;}
                dd{width: calc(60% - 0.7143vw); padding: 0;}
                & + .box-btn{
                    margin-top: 1.4286vw;
                }
            }
            .item-bottom{
                .btns{
                    background: none; padding: 0;
                }                        
            }
            .no-list {
                padding: 2.8571vw;
                .item-top{padding: 0; align-items: center;}
            }
        }
        .box-my-info{
            margin-bottom: 5.7143vw;
            .contents-title{
                display: block;  margin: 0.7143vw 0 1.4286vw;
                .txt-vt{@include fontInherit;}
            }
        }
        .bang-notice{margin-top: 0.7143vw;}               
    }

    // 휴대폰 정보 등록
    .subscript-phone-info{
        .page-tit{
            margin-bottom: 2.8571vw;
        }
        h2{
          margin-top: 5.7143vw;
        }
        .os-wrap{
            margin-top: 2.8571vw;
            .default-txt{margin-bottom: 1.1429vw;}
        }
        .phone{
            &-ios{display: none;}
        }
        .box-input{
            & ~ .box-input{margin-top: 2.8571vw;}
            .btn-text-arrow{ margin-top: 1.5714vw;}
            .bang-notice{
                margin-top: 1.4286vw;
            }
        }
        .box-select{margin-top: 2.8571vw;}
        .btn-radio-group{
            & + .box-input,& + .box-select {margin-top: 2.8571vw;}
        }
    }

    //청구서 재발행 휴대폰 번호 얼럿창
    .bill-reissue-layerPopup{
        text-align: center;
    }
    .bill-inquiry-layerPopup{
        text-align: center;
        .one-title{
            ul{
                li{
                    margin-bottom: 0.7143vw;
                    a{color:#0054ff;}
                }
            }
        }
    }
    // 모델명, IMIE 확인
    .subscript-serial-number{
        &>.inner{
            padding-top: 10.2857vw;
            .box-tab {
                .tab-btn-wrap {margin-bottom:5.7143vw;}
            }
            .cardList-wrap {
                &+.cardList-wrap {margin-top:5.7143vw;}
                .cardList {
                    &.one-title {padding:2.8571vw;}
                    dl {
                        dt {

                            &+dt {margin-top:2.1429vw;}
                        }
                    }
                }
            }
            h2 {
                display:block;margin:5.7143vw 0 1.4286vw;
                &:first-of-type {margin-top:0;}
            }
            .c-unit{
                padding: 2.8571vw; border: 0.1429vw solid $gray02; border-radius:1.4286vw;
                .unit-tit {
                    margin-bottom:0;
                    &+.unit-tit {margin-top:2.1429vw;}
                    h3 {display:block;}
                    p {margin-top:0.7143vw;}
                }
                .unit-img {margin-top:2.1429vw;}
            }
        }
    }

    //지정번호 등록 / 변경
    .subscript-change{
        .phone-desc{
            margin-bottom: 4.2857vw;
            p{
                 margin-top: 0.7143vw;
            }
        }
    }
    //선택약정 가입 신청서
    .subscript-select-discount{
        .payment-list-card{
            margin-top: 5.7143vw;
            .sub-title{

                strong{
                    display: block; margin: 0.7143vw 0;
                }

            }
            .pay-amount{
                justify-content: space-between; padding-top: 2.8571vw;
                span{
                    text-decoration: none;
                }
            }
        }
       .cardList{
        margin-top: 2.1429vw;
            dl{
                dt{width:15.7143vw; font-weight: 500;}
                dd{width:calc(100% - 15.7143vw);text-align: right;}
            }
       }
       .content{
            margin-top: 4.2857vw;
            .btn-text-arrow{
                margin-bottom:1.5714vw;
            }
       }
    }
    //선택약정
    .subscript-installment-info{
        .cardList-wrap{
            margin-top: 4.2857vw;
        }
        .money-right{
            & + .money-right{
                margin-top: 2.1429vw;
            }
            & + .notification {margin-top: 2.1429vw;}
        }
        .no-list{
            & + .notification {margin-top: 2.1429vw;}
        }
        .btn-wrap{
            margin-top: 5.7143vw;
            li{
                & + li{
                    margin-top: 2.1429vw;
                }
            }
        }
    }
     //상세 가입 및 이용조건
    .subscript-terms-popup{
        .notification{
            li{
                margin-bottom:1.4286vw;
            }
        }
    }
     // 일시정지
    .pause-application{
        .page-tit {
            p{margin-top: 0.7143vw;}
            h1 + .notification{margin-top: 0.7143vw;}
        }
        .contents-wrap{
            & + .contents-wrap{margin-top: 5.7143vw;}
        }
        .contents-title{margin-bottom:0.7143vw;}
        .notification{
            .default-txt{
                margin-bottom: 1.4286vw;
            }
        }
        .cardList-wrap{
            & + .box-btn{margin-top: 1.4286vw;}
        }
        .cardList{
            & + .cardList{margin-top: 2.1429vw;}
            .btns{margin-top: 3.5714vw;}
            .card-check {
               color: $purple;
                .box-info{
                    width: 100%;
                    .info-top{
                        display: flex;justify-content: space-between; align-items: center;
                    }
                    .product-name{
                        display: block; margin-top: 0.7143vw;
                        @include text(400,2.0000vw,1.43,$gray05,-0.0714vw)
                    }
                }
            }
            &.pause{
                .card-check{
                    color: $gray05;
                    .box-info .info-top{
                        span{color: $purple;}
                    }

                }
            }
        }
        .box-ico-txt{
            margin-top: 0;
            & + .cardList-wrap{margin-top: 5.7143vw;}
        }

        // 일시정지 신청팝업
        &.full-popup-conts {
            .contents-wrap{margin-top: 4.2857vw;}
            .main-txt{display: block; margin-bottom: 1.4286vw;}
            .radio-group-wrap{
                & + .notification{margin-top: 1.4286vw;}
                .group-tit{margin-bottom: 1.4286vw;}
                .btn-radio {
                    label{
                        display: flex; align-items: center; justify-content: center; padding: 0; height: 8.5714vw;
                    }
                }
            }
            .bang-notice{
                margin-top: 1.4286vw;
            }
            .box-input{
                & + .box-input{margin-top: 1.4286vw;}
            }
            .date-list{
                display: flex; gap: 2.1429vw;
            }
            .round-gray-box{
                background-color: $bg; padding: 2.1429vw;border-radius: 1.4286vw;
                .btn-check{
                    margin-bottom: 1.5714vw;
                }
            }
            .accordian{
                margin-top: 5.7143vw;
            }
        }
    }
     //부가서비스 변경
    .serviceChange-list{
        .page-tit {
            display: flex; justify-content: space-between; position: relative; background-color: #f8f4ff; padding: 3.5714vw 2.8571vw; border-radius: 1.4286vw; margin-bottom: 2.2857vw;
            & + .box-btn{margin-bottom: 4.2857vw;}
            .txt-wrap {
                .desc{
                    margin-top:2.1429vw; display: flex; gap:2.1429vw;
                    li{
                        strong{  margin-left: 0.7143vw;
                        }
                    }
                }
            }
            .img-wrap {
                position: absolute; top: 50%;right: 1.4286vw; width: 17.1429vw; height: 17.1429vw; transform: translateY(-50%);
                img{width: 100%;}
            }
        }
        .money-right{
            & + .money-right{
                margin-top: 2.1429vw;
            }
        }
        .cardList-wrap{
            &.free-service{  
                background: #fff;           
                .money-right{
                    dl{
                        dt{width: 100%;}
                    }
                }
            }
            .money-right{
                dl{
                    dt{
                        width: calc(100% - 15.7143vw);
                    }
                    dd{
                        width: 15.7143vw;
                    }
                }
            }
            & + .cardList-wrap{
                margin-top: 5.7143vw;
            }
        }
        .box-btn{margin-top: 2.8571vw;}
        .btn-group{
            margin-top: 2.8571vw; gap:1.4286vw;
            .white{
                padding:0;
            }
            .btns{
                padding: 1.7143vw 0; border-radius: 1.0000vw;
            }
        }
        .accordian{
            margin-top: 5.7143vw;
        }
    }
    //발신자 미표시 번호 차단 서비스 설정
    .serviceChange-blocking{
        .phone-desc{
            p{
                width:100%; margin-top: 1.2143vw;
            }
        }
        .radio-group-wrap{
            margin-top:4.2857vw;
        }
        .accordian{
            margin-top: 4.2857vw;
            .acc-conts{
                .notification{
                    margin-top: 1.4286vw;
                    li{
                        strong{
                            font-weight: 500;
                        }
                        p{
                            @include backgroundImg('/assets/images/icon/ico-sound.svg',left top,2.8571vw 2.8571vw);
                            padding-left: 3.5714vw; margin-top: 0.7143vw;
                        }
                    }
                }
            }
        }
    }
    // 사용량조회
    .use-amount{
        &.use-fee-time{
            .notice-card{
                p{ margin-top: 0.7143vw; }
            }
            .cardList-wrap{
                margin-bottom: 4.2857vw;
            }
            .btn-box{
                margin-top: 5.7143vw;
                li{
                    &:last-child{
                        margin-top: 2.1429vw;
                    }
                }
            }
        }
        .wide-box{
            border-bottom:1.1429vw solid $gray01; padding-bottom:5.7143vw;
            &.no-border{
                border:none; padding:0;
            }
        }
        .page-tit{
            h1{
                & > span{@include fontInherit; color: $purple !important;}
            }
        }
        .progress-amount{
            margin-bottom: 2.8571vw;
            .title-wrap{
                strong{margin-bottom: 0.4286vw;}
                & + .big-txt{
                    display: block; font-weight: 700; margin-bottom: 2.8571vw;
                }
            }
        }
        .box-usage-wrap{
            .usage-title{
                display: flex; align-items: center; gap: 1.4286vw;
                &::before{
                    content: ''; display: block; width: 3.4286vw; height: 3.4286vw;
                    @include backgroundImg('/assets/images/icon/ico-my-data.svg',center, contain);
                }
            }
            & + .box-usage-wrap{margin-top: 4.2857vw;}

            .circle-wrap{
                position: relative; width: 14.5714vw; height: 14.5714vw;flex-shrink: 0;
                svg {
                    width: 14.5714vw; height: 14.5714vw;
                    transform: rotate(-0.25turn) rotateX(180deg);
                    position: absolute;  top: 0;  left: 0;  bottom: 0;  right: 0;  margin: auto;  height: 100%;
                }
                .background {
                    fill: none;
                    stroke: $gray01;
                    stroke-width: 3;
                }
                .progress {
                    position: relative;
                    fill: none;
                    stroke: $purple;
                    stroke-dasharray: 100 100;
                    stroke-dashoffset: 100;
                    stroke-linecap: round;
                    stroke-width: 3;
                    transition: stroke-dashoffset 1s ease-in-out;
                }
                .txt-data {
                    position: absolute; top:50%; left: 50%; font-weight: 700; color: $purple; transform: translate(-50%,-50%);
                    & > span{ @include fontInherit;}
                }

            }

            .box-usage{
                display: flex;flex-direction: column; gap: 5.1429vw; padding: 2.8571vw;  border: solid 0.1429vw $gray02; border-radius: 1.4286vw;
                .usage-wrap{display: flex; display: flex; align-items: center; gap: 2.8571vw; }

                .txt-wrap {
                    .tooltip-wrap{
                        .txt-tooltip{
                            left: -32.6429vw;
                        }
                    }
                    .usage {
                        &-desc{
                            display: block;margin-bottom: 0.5000vw;
                        }
                        &-amount{
                             font-weight: 600;
                            .highlight{
                                font-weight: 600;color: $purple;
                            }
                        }
                        &-detail{
                            margin-top: 0.5000vw;
                        }
                    }
                }
                .tooltip-wrap{
                    display: inline-flex; vertical-align: middle;
                    .box-tooltip {
                        .btn-tooltip{width: 2.1429vw; height: 2.1429vw;@include backgroundImg('/assets/images/icon/ico-notice.svg',center, contain);}
                    }
                }
            }
            &.call {
                .usage-title::before{
                    background-image: url('/assets/images/icon/ico-my-call.svg');
                }
            }
            &.sms {
                .usage-title::before{
                    background-image: url('/assets/images/icon/ico-my-sms.svg');
                }
            }
        }
        .box-chart-wrap{
            padding: 0 0 10.2857vw;
            .chart-info{
                margin-bottom: 4.2857vw;
                span {
                    &.date {
                        display: block;
                        margin-top: 0.4286vw;
                    }
                }

            }
            .chart {
                &-title {

                    display: flex; align-items: center; gap: 1.4286vw; margin-bottom: 5.0000vw;
                    &::before{
                        content: ''; display: block; width: 3.4286vw; height: 3.4286vw;
                        @include backgroundImg('/assets/images/icon/ico-my-data.svg',center, contain);
                    }
                }
            }
            &.call {
                .chart-title::before{
                    background-image: url('/assets/images/icon/ico-my-call.svg');
                }
            }
            &.sms {
                .chart-title::before{
                    background-image: url('/assets/images/icon/ico-my-sms.svg');
                }
            }
            .box-chart{
                position: relative;
                .chart {
                    &-line{
                        display: flex; flex-direction: column;gap: 5.0000vw;
                        hr{
                            display: block;border: 0;margin: 0; border-top: 0.0714vw solid $gray02; width: 100%;
                            &:last-of-type{color: $gray05;}
                        }
                    }
                    &-wrap{
                        display: flex;justify-content: space-around;
                        position: absolute; bottom: 0;width: 100%;height: 100%;
                        .chart-conts {
                            position: relative;width: 7.1429vw;height: 100%;
                            .chart-bar {
                                position: absolute; bottom: 0; width: 7.8571vw; height: 0;
                                transition: height 0.5s ease; border-radius: 0.3571vw 0.3571vw 0 0; background-color:#f9f5ff;
                                .bar {
                                    display: flex; justify-content: center; align-items: center;  color: $gray05; width: 100%; height: 100%; opacity: 0; visibility: hidden;
                                    transition: opacity 0.3s, visibility 0.3s;
                                }
                                &.on {
                                    .bar{opacity: 1; visibility: visible;}
                                }
                            }
                            .chart-month {
                                display: block; position: absolute; bottom: -4.5714vw; width: 100%;  margin-top: 1.4286vw; text-align: center;
                            }
                        }
                        .box-average {
                            position: absolute;
                            bottom: 0%; left: 0; width: 100%; z-index: 2; opacity: 0; visibility: hidden;
                            transition: all 0.5s ease;
                            &.on{
                                opacity: 1; visibility: visible;
                            }

                            hr{display: block;border: 0;margin: 0; border-top: 0.1429vw dashed $purple; width: 100%; }
                            .average-txt{
                                display: inline-block; position: absolute; top: -5.5714vw; left: 50%; padding: 0.7857vw 1.4286vw; border-radius: 3.2143vw;background-color: $purple; color: #fff; transform: translateX(-50%);opacity: 0; visibility: hidden;text-align: center;
                                transition: opacity 0.5s ease, visibility 0.5s ease;
                                &.on{
                                    opacity: 1; visibility: visible;
                                }
                                &::after{
                                    content: ''; display: block;width:1.2143vw;height: 1.2143vw;
                                    position: absolute; bottom: -0.6429vw; left: 50%; transform: translateX(-50%) rotate(45deg); background-color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }

        .find-no-data{
            @include backgroundImg('/assets/images/icon/ico-no-data.svg', center top, 18.5714vw 18.5714vw); padding-top:21.4286vw;

            .round-box {
                margin-top: 0; padding: 4.2857vw 2.8571vw; background-color: $white; border: 0.1429vw solid $gray03;
                .main-txt{
                    display: block; text-align: center; margin-bottom: 1.4286vw;
                }
                p{
                    text-align: center;
                }
                .box-btn{
                    margin-top: 2.8571vw;
                }
            }
        }
        .box-fee{
            display: flex; justify-content: space-between; align-items: center; padding: 2.8571vw; background-color: $purple; border-radius: 1.4286vw; margin-top: 5.7143vw;
            .txt{
                span{
                    display: block;  color: #fff;
                    &.date{margin-top: 0.4286vw;}
                }
            }
            .fee{
                flex-shrink: 0;
               font-weight: 700; color: #fff;
                em{ font-size: 3.7143vw; letter-spacing: -0.1429vw;}
            }
        }
        .table-list-wrap{
            margin: 4.2857vw 0;
        }
        .accordian{
            margin-top: 3.5714vw;
            & + .accordian{margin-top: 1.4286vw;}
            .notification {
                li{
                    .txt-vt{display: block;}
                    .box-btn{margin: 0.7143vw 0 1.4286vw;}
                }
            }
        }
    }
    .call-history {
        .inquir-box {
            margin-top:5.7143vw;
            .btn-radio-tit {display:block;margin-bottom:1.1429vw;}
            .btn-radio-group {
                margin-bottom:2.8571vw;
                label {cursor:pointer;}
            }
            .period-set {
                &>strong {display:block;margin-bottom:2.1429vw;}
                .box-input+.box-input {margin-top:2.1429vw;}

            }
        }
    }

    .call-history-reason {
        padding-bottom: 6.4286vw;
        .box-input {margin-bottom:2.1429vw;}
    }
    .puk-browse{
        .puk-num{color: $purple !important;}
    }
    .communi-data{
        .box-input + .textarea-wrap{margin: 2.1429vw 0 5.7143vw 0;}
    }
    // 가입신청서 열람
    .appli-admission{
        .box-input{
            & + .textarea-wrap{margin: 2.1429vw 0 5.7143vw 0;}
        }
        .card-check{border-radius: 0;}
    }
    //발급신청 발급용도 입력
    .appli-purpose{
        .etc-input{
            margin-top:1.4286vw; display: none;

        }
        .email-input{
            margin-top:5.7143vw;
        }
    }
    //통화내역 열람신청 조회기간 선택
    .call-history-select-receiving{
        .bang-notice {line-height:1.33;}
        .cont-tit {margin-bottom:1.4286vw;}
        .period-set{
            margin-top:2.8571vw;
            & > .box-input{
                margin-top:2.1429vw;
            }
        }
    }
    //통화내역 열람신청 수신방법 선택
    .call-history-appli-inquir{
        .cont-tit {margin-bottom:1.4286vw;}
        .cont-sec {
            margin-top:5.7143vw;
            .box-input {margin-top:1.4286vw;}
        }
        .faxBox {display: none;}
    }
    //개인정보열람 신청내역목록
    .privacy-info-list{
        .privacy-info-item{
            width:100%; border-radius:1.4286vw; border:0.1429vw solid $gray02; display: flex; justify-content: space-between; align-items: center; padding:2.8571vw;
            .item-desc{
                h2{
                  font-weight: 700;
                }
                .process-txt{
                    display: block; margin-bottom: 0.7143vw; @include text(600,2.0000vw,1.43,$black,-0.0714vw );
                }
                ul{
                    display: flex; margin-top: 1.4286vw;
                    li{
                        position: relative; padding-right:2.8571vw;
                        &::after{
                            content: ''; width:0.1429vw; height:1.5714vw; background: $gray02; position:absolute; top:50%; transform: translateY(-50%); right:1.4286vw; display: block;
                        }
                        &:last-child{
                            &::after{display: none;}
                        }
                    }
                }
            }
            & + .privacy-info-item{
                margin-top: 2.1429vw;
            }
        }
        .box-btn{margin-top: 2.1429vw;}
    }
    .privacy-info-no-list{
        .img{
            margin: 5.7143vw 0 2.1429vw !important;
            & + .bang-notice{
                text-align: center;
            }
        }
    }
    //요금납부조회
    .my-payment {
        .btns {
            font-size:2.2857vw;
        }
    }

    // 납부확인서
    .payment-confirm-appli{
        .self-write-wrap{
            display: none; margin-top: 1.4286vw;
        }
    }
    .payment-confirm-check{
        .cardList-wrap {
            & + .unit-sec-tit{
                margin-top: 5.7143vw;
            }
        }
        .table-list-wrap{
            .table-list {
                th{padding-right: 0;}
                td{padding-right: 0;}
            }
        }
        .sign{
            text-align: center;
            .official-seal {
                margin-top: 0.7143vw;display: flex;justify-content: center;
                align-items: center;gap: 1.4286vw;

                .seal-wrap{
                    width: 5.3571vw;height: 5.3571vw;
                    img{display: block;}
                }
            }
            .bang-notice{
                text-align: left; margin-top: 5.7143vw;
            }
        }
    }
    //납부 방법 변경 완료
    .payment-way-finish{
        .ico-img{
            width:18.5714vw; height: 18.5714vw; margin:0 auto;
        }
        .payment-wrap{
            margin-top: 5.7143vw;
        }
    }
    //구독 중 서비스 목록
    .subscribe-list{
        .chip-wrap{
            .chip{
                &:first-child{
                    background-color:$lightpurple;
                }
                &:nth-child(2){
                    background-color:$purpleblue;
                }
            }
        }
        .box-my-use{
            padding: 0; margin-bottom: 1.4286vw;
            .box-btn{margin-top: 0;}
        }
        .cardList-wrap{
            .cardList{
                padding: 4.2857vw 2.8571vw;
                & + .cardList {margin-top: 2.1429vw;}
                & > a{display: block;}
                .card-check{
                    border: none; padding: 0;
                    .box-info{
                        width: 100%; margin-bottom: 2.1429vw;
                        .info {
                            display: flex; justify-content: space-between; align-items: center;
                            &-top {
                                display: flex; justify-content: space-between; align-items: center;
                                .payment-state{
                                    color:$purple;
                                    &.unpaid{color: #cf192b;}
                                }
                            }
                            &-product{
                                display: flex; justify-content: space-between; align-items: center;
                                margin-top: 1.4286vw;
                            }
                            &-point{
                                display: flex; justify-content: space-between; align-items: center;
                                margin-top: 0.7143vw;
                            }
                        }
                    }
                }
                dl{
                    padding: 2.1429vw; background-color: $gray01; border-radius: 1.4286vw;
                }
                .btns{
                    margin-top: 2.1429vw;
                }
            }
        }
        .accordian{margin-top: 5.7143vw}
        .box-select{
            margin-bottom: 5.7143vw;
        }
    }
    .subscribe-detail-layerPopup{
        a{
            text-decoration: underline;
        }
    }
    //구독서비스 상세
    .subscribe-detail {
        .box-tooltip .txt-tooltip{left: -10.7857vw;}
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 4.2857vw;}
            .cardList{
                dd {
                    & > span{@include fontInherit; display: block;}
                    .old-price{text-decoration: line-through;}
                    .discount-price{
                        & > span{@include fontInherit;}
                    }
                    a{
                        @include fontInherit;
                        &.link-txt{color: $purple;}
                    }
                }
                .btn-group{
                    gap: 1.4286vw; margin-top: 3.5714vw;
                    button{border-radius: 1.0000vw;}
                }
            }
        }
        .box-table{
            margin-top: 4.2857vw;
            .table-header{
                display: flex; justify-content: space-between; align-items: center; margin-bottom: 2.1429vw;
                .table-title{border: none; padding: 0; margin: 0;}
            }
            .table-list{
                width: 100%; border-top: 0.1429vw solid $gray02;
                th,td{
                    padding: 2.8571vw 2.1429vw;border-bottom: 0.1429vw solid $gray02;
                    color: $black;
                }
                td{
                    &:nth-of-type(1) {padding:2.8571vw 0;}
                    &.complete {color: $purple;}
                    .btn-box{
                        .btn-text{
                            display: flex;padding: 0;align-items: center; gap: 0.7143vw;justify-content: flex-end;
                            color: $black;
                        }
                        .ico-arrow-right{flex-shrink: 0;}
                    }
                }
            }
            & + .box-btn{margin-top: 2.8571vw;}
        }
        .accodian-wrap{
            margin-top: 4.2857vw;
            .accordian-list{
                margin-top: 1.4286vw; border-top:0.1429vw solid $gray02; border-bottom:0.1429vw solid $gray02;
                li{
                    & >.title{
                        display: flex; justify-content: space-between; padding:2.8571vw 4.2857vw 2.1429vw 0; margin-bottom: 0;
                    }
                    .acc-conts{
                        background: $white; padding:0 0 2.8571vw 0;
                    }
                    .mb60{
                            margin-bottom: 4.2857vw !important;
                        }
                }
            }
        }
        .box-btn{
            margin-top: 2.8571vw;
        }
        .money-right{
            dl{
                dt{
                    width: 17.3571vw;
                }
                dd{
                    width:calc(100% - 17.3571vw);
                   &.big{
                        font-size: 2.8571vw;
                    }
                }
            }
        }
        .under-line-wrap{
            border:0.1429vw solid $gray02; border-radius: 1.4286vw; padding: 2.8571vw;
           &>li{
                border-bottom:0.1429vw solid $gray02; padding-bottom: 2.8571vw;
                .btn-box{
                    margin-top: 2.8571vw;
                }
                dl{
                    display: flex;  width: 100%; flex-wrap: wrap;
                    dt{
                        width:12.8571vw; padding-bottom: 1.4286vw;
                    }
                    dd{
                        width:calc(100% - 12.8571vw);
                    }
                }
                & + li{
                    padding-top: 2.8571vw; padding-bottom: 0;
                }
                &:last-child{
                    border-bottom:0; padding-bottom: 0;
                }
                .notification{
                    margin-top: 1.4286vw;
                }
                a{
                    color:$purple; text-decoration: underline;
                }
            }
        }
        .pl30{
            padding-left: 2.1429vw; margin-bottom: 1.4286vw;
        }
    }
    .notice-change-date{
        .btn-radio{
            & + .btn-radio{
                margin-top: 2.1429vw;
            }
        }
    }
    .notice-change-date-complete{
        .no-search-result {
            background-image: url('/assets/images/icon/ico-date-change.svg');
        }
    }
    //결제 상세 내역
    .subscribe-payment{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 4.2857vw;}
            .cardList{
                dl{
                    & + .btns{margin-top: 2.8571vw;}
                    & + p{
                        word-break: keep-all; padding-top: 2.8571vw; margin-top: 2.8571vw; border-top: 0.1429vw solid $gray02;
                        & + .notification{margin-top: 1.4286vw;}
                    }
                }
                &.money-right{
                    dl{
                        dt{width: 50%;}
                        dd{width: 50%;}
                        .pay-date{
                          color: $purple;
                        }
                        .point-num{
                            color: $purple;
                        }
                    }
                }
            }
        }
    }
    //구매정보변경
    .subscribe-purchase{
        .content {
            & + .content{margin-top: 2.8571vw;}
        }
        .box-flex{
            & + .box-flex{margin-top: 2.8571vw;}
            & + .btns{margin-top: 2.1429vw;}
        }
        .textarea-wrap {
            display: none; margin-top: 1.1429vw;
        }
    }
    //전체결제내역
    .subscribe-whole{
        .accordian{
            &.type-03 {
                &:last-of-type{
                    .acc-trigger{border-bottom: 0.1429vw solid $gray02;}
                    .cardList-wrap{
                        .cardList{
                            border: 0;border-bottom: 0.1429vw solid $gray02;
                        }
                    }
                }
                .acc-trigger{
                    display: flex; justify-content: space-between; align-items: center; border-top: 0.1429vw solid $gray02;padding: 2.8571vw 2.1429vw; margin: 0;
                    * { color: $black;}
                    &::after{
                        position: static; transform: translateY(0);
                    }
                    &.on{
                        &::after{transform: rotate(180deg);}
                    }
                }
                .cardList-wrap{
                    .cardList{
                        border: 0; border-radius: 0;border-top: 0.1429vw solid $gray02;
                    }
                }
                & + .box-btn{margin-top: 1.4286vw;}
            }
        }
    }
    //할인반환금 예상 금액
    .subscript-return-amount{
       .cardList-wrap{
            margin-top: 5.7143vw;
            .bang-notice{
                margin-top: 0.7143vw;
            }
        }
        .box-table{
            margin-top: 4.2857vw; margin-bottom: 1.4286vw;
        }
        .table-title{margin-bottom: 0;}
        .table-type-01{
            & + .table-type-01{
                margin-top:4.2857vw;
            }
            tbody{
                tr:first-child{
                    td,th{padding-top:2.8571vw;}
                }
                tr:last-child{
                    th,td{padding-bottom:2.8571vw;}
                }
            }

        }
        .box-ico-txt{
            & + p{
                margin-top: 5.7143vw;
            }
        }
    }
    //위약금 안내
    .subscript-cancellation-charge-popup{
        h3{
            margin-top: 4.2857vw;
            &:first-child{margin: 4.2857vw 0 1.4286vw 0;}
        }
        .notification{
            margin-top: 1.4286vw;
        }
        .table-type-04{
                tr{
                    td:first-child{
                       font-weight: 500;
                    }
                }
            & + .table-type-04{
                margin-top: 4.2857vw;
            }
        }
    }
    //휴대폰 인증
    .order-phone-auth {
        .page-tit{
            .box-btn {margin-top: 2.1429vw;}
        }
        .box-flex{
            margin-top: 2.8571vw;
        }
    }
    //최근 가입신청 이력
    .order-list {
        .notification{margin-bottom: 5.7143vw;}
        .box-select{margin-bottom: 5.7143vw;}
        section {
            & + section{margin-top: 4.2857vw;}
        }
        .cardList{
            & + .box-btn{
                margin-top: 2.1429vw;
                &.mt80{
                    margin-top: 5.7143vw;
                }
                // .btns {
                //     & + .btns{margin-top: 2.1429vw;}
                // }
            }
            dt{width: 30%;}
            dd{
                width: 70%;
                span{@include fontInherit;}
            }
        }
        .box-banner{
            margin-top: 5.7143vw;
            img{width: 100%;}
            & + .box-banner{
                margin-top: 4.2857vw;
            }
        }
        .num-process-budle{
           border: 0.1429vw solid #ddd; border-radius: 1.0000vw; padding: 3.2857vw;
            .sub-contents-title{
                display: block; margin-bottom: 2.8571vw;
                span{@include fontInherit;}
            }
            .num-process-wrap{
                border:none; border-radius: 0; padding: 0;
                & > li{
                    &::before{top: -100%; z-index: -1;}
                    &:first-child{
                        &::before{display: none;}
                    }
                    &:last-child{
                        &::before{
                            left: 0;top: -100%;z-index: -1;width: 0.1429vw;height: 100%; background: $purple;
                        }
                        &::after{display: none;}
                    }
                    &.disabled::before{
                        background: $gray02;
                    }
                }
            }
        }
        .no-search-result{
            .small-txt{
                margin-top: 0;
            }
        }
    }
    //무선서비스 계약 표준 안내서
    .order-wireless{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 5.7143vw;}
            .signiture-txt{
                display: flex; justify-content: flex-end; align-items: center; gap: 1.4286vw; margin-top: 1.7143vw;
                img{
                    display: inline-block; width: 5.3571vw; height: 5.3571vw;
                }
            }
            .cardList-title{
                display: flex; justify-content: space-between; align-items: center;
            }
            .cardList {
                & + .cardList {margin-top: 2.8571vw;}
                & + .notification{margin-top: 1.4286vw;}
                dt{width: 40%;}
                dd{
                    width: 60%;
                    .month-txt{display: block; @include fontInherit;}
                }

                &.is-title{
                    .card-title{
                        display: block;padding-bottom: 2.8571vw; margin-bottom: 2.8571vw; border-bottom: 0.1429vw solid $gray02;
                    }
                }
            }
        }
        .noti-box{
            margin-top: 5.7143vw;
            .main-txt{display: block; margin-bottom: 1.4286vw;}
            .bg-gray{
                background-color: $bg; padding: 2.1429vw; border-radius: 1.4286vw; margin: 2.8571vw 0 2.1429vw;
                .btn-check{
                    & + p{
                        margin-top: 1.4286vw;
                    }
                }
            }
        }
        .accordian{
            margin-top: 5.7143vw;
            .acc-trigger{
                padding-bottom: 0;
            }
            .acc-conts{
                background: none; padding: 0; margin-top: 2.1429vw;
                .cardList{
                    .w-100{width: 100%;}
                }
            }
        }
        .btn-check{
            input[type=checkbox]:disabled+label span{
                color: $black;
                &::after {top: 1.4286vw;}
            }
        }
    }
    //가입신청서 상세보기
    .order-list-detail{
        .payment-detail-card-wrap{
            margin-bottom: 5.7143vw;
            li{
                .txt-vt{display: block;  text-align: right;margin-top: 2.1429vw;}
                &:first-of-type{
                    .detail-title{margin-bottom: 0;}
                }
            }
            .phone-thumbnail{
                position: relative; width: 14.2857vw; height: 14.2857vw; margin: 0 auto 2.8571vw; overflow: hidden;
                img{@include imgSize;}
            }
            .btn-set{
                margin-top: 4.2857vw;
            }
        }
        .accordian {
            .acc-conts{
                padding:0; background: $white;
                .notification{
                    margin-top: 2.1429vw;
                    li{
                        margin-bottom: 0.7143vw;
                    }
                }
                .box-table{
                    margin-top: 2.1429vw;
                    tr{
                        .date-txt{white-space: nowrap;}
                    }
                    & + .cardList-wrap{
                        margin-top: 2.1429vw;
                        p{
                            margin-top: 1.4286vw;
                        }
                    }
                }
            }
        }
    }
    //배송지변경
    .order-address{
        .box-input{
            & + .box-input{margin-top: 2.8571vw;}
        }
        .input-depth{
            margin-top: 2.8571vw;
            .box-input{
                & + .box-input{margin-top: 0;}
            }
        }
        .box-select{margin-top: 2.8571vw;}
        .textarea-wrap{
            display: none; margin-top: 1.1429vw;
        }
    }
    //입고 알림 신청 내역
    .order-goods-application{
        section + section{margin-top: 2.8571vw;}
        .cardList-wrap{
            .box-btn{margin-top: 1.4286vw;}
            .cardList {
                gap: 2.8571vw 2.1429vw ;
                & + .cardList {margin-top: 2.1429vw;}
                & > .box-btn{
                    width: 100%;
                }
                .item-txt{
                    display: flex;flex-direction: column;justify-content: center;
                }
            }
        }
        .notification{margin-top: 0.7143vw;}
        .no-search-result {
            background-image: url('/assets/images/icon/ico-alarm-no-result.svg');
        }
    }

    //내 포인트 조회
    .point-lookup{
        .radio-group-wrap{
            margin-top: 4.2857vw;
            .btns{
                margin-top: 2.1429vw;
            }
        }
        .is-filter-list{
            padding:0; overflow-x: hidden;
        }
        .datepicker-wrap{display: none;}
        .cardList-wrap {
            & + .box-btn{margin-top: 1.4286vw;}
        }
    }

    // 포인트 사용
    .point-use-info{
        .c-unit{
            .unit-tit{margin-bottom: 0.7143vw;}
        }
        .btn-flex{
            display: flex; gap:2.1429vw; margin-top: 2.8571vw;
            flex-direction: column;
            li{
                flex:1;
            }
        }
    }
    //포인트 사용 신청
    .point-use-application{
        .detail-sec-tit{margin-bottom: 2.8571vw;}
        .detail-sec-desc{
            .box-flex{
                // .input-default{padding: 2.0000vw 8.2143vw 2.0000vw 2.2857vw; }
                & > .box-btn{
                    button{width: 14.2857vw;}
                }
                & + .box-flex{
                    padding-top: 1.1429vw;
                }
            }
            &.all-point-inputWrap{
                input{
                    color:$purple;
                }
            }
        }
    }
    //최근 본 상품 - 요금제
    .view-prodcuts-rate{
        .font0{
            font-size: 0;
        }
        .cardList{
            gap:2.8571vw;
            padding: 4.2857vw 2.8571vw;
        }
        .card-list-header{
                .main-txt{
                    width:calc(100% - 2.8571vw);
                }
        }
       .pay-amount{
            text-align: right; width:100%;
        }
       .btns{
        em{  color:$white; }
       }
    }
    //최근 본 상품 - 휴대폰
    .view-prodcuts-phone{
        .cardList {padding: 4.2857vw 2.8571vw;}
       .btns{
        em{  color:$white;}
       }
    }
    //유심구매내역
    .order-usim{
        .cardList{
            dt{width: 40%;}
            dd{width: 60%;}
        }
        .point-sort-card{
            .middle{
                margin-top: 2.8571vw;
                dl{
                    gap:2.1429vw; align-items: center;
                    dt{
                        width:12.8571vw !important;
                        img{
                            width: 100%;
                        }
                    }
                    dd{
                        text-align: left;
                        .title{
                            margin-bottom: 0.7143vw;
                        }
                    }
                }
                .amount{
                   text-align: right; display: block;
                }
            }
        }
        .box-select{
            margin-top: 5.7143vw;
        }
        .mt60{
            margin-top: 4.2857vw;
        }
        .box-table{
            .table-title{
                padding-bottom: 1.4286vw; margin-bottom: 0;
            }
            .emp-txt{
                *{color: $purple;}
            }
        }
        .btn-list{
            li{
                margin-top: 2.1429vw;
            }
        }
        .no-search-result{
            background-image: url('/assets/images/icon/ico-usim-no-result.svg');
        }
    }
    // eSim 활성화 방법 안내
    .order-esim-active-popup{
        .cardList-wrap{
            & + .cardList-wrap{margin-top: 4.2857vw;}
            .cardList-title {
                & + p{
                    & + .one-title{margin-top: 2.8571vw;}
                }
            }
        }
        .one-title{
            & + .one-title{margin-top: 2.1429vw;}
            .unit {
                & + .unit{margin-top: 2.1429vw;}
                strong{display: block; margin-bottom: 0.7143vw;
                }
                span{
                    display: block;
                    & + span{margin-top: 1.4286vw;}
                }
                .btns{margin-top: 2.1429vw;}
            }
        }
        .unit-img{
            margin-top: 2.8571vw;
            img{
                display: block; margin:0 auto; width: 21.4286vw;
            }
        }
    }   
}

/*** 마이메인 ***/
.mymain {
    .page-tit-s {margin:8.5714vw 0 2.1429vw;font-weight:600;}
    .bill-plan {margin-bottom:5.7143vw;}
    //그래프
    .box-usage-wrap {
        min-height: 34.2857vw; margin-bottom:4.2857vw;text-align:center;
        .circle-wrap{
            position:relative;width:28.5714vw;height:28.5714vw;flex-shrink:0;margin:0 auto;
            svg {
                width:28.5714vw;height:28.5714vw;
                transform: rotate(-0.25turn) rotateX(180deg);
                position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;height:100%;
            }
            .background {
                fill: none;
                stroke: $gray01;
                stroke-width:1;
            }
            .progress {
                position: relative;
                fill: none;
                stroke: $purple;
                stroke-dasharray: 100 100;
                stroke-dashoffset: 100;
                stroke-linecap: round;
                stroke-width:1;
                transition: stroke-dashoffset 1s ease-in-out;
            }
            .txt-data {
                position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);font-size:0;
                &>span {font-size:1.8571vw;color:$black;}
                &>a {
                    display: block; white-space: nowrap;

                    .ico-arrow-right {margin-top:0.5000vw;vertical-align:top;}
                }
            }
        }
        .feature {
            display:block;margin-top:2.8571vw;text-align:center;
            &>span {
                font-size:1.8571vw;
                &+span {margin-left:5.7143vw;}
            }
        }
    }
    // 실시간 요금 안내

    .cardList-wrap {
        margin-top:8.5714vw;
        //청구요금 정보
        &.point-info-card  {
            min-height: 42.8571vw;
            dl {
                dt {width:auto;color:$black;}
                dd {width:auto;font-weight:normal;}
            }
            .money-right{
                min-height: 30.2143vw;
            }
            .btn-group {margin-top:2.1429vw;}
        }
        .cardList {
            &.money-right {
                dl {
                    justify-content:space-between;border-top:none;
                    dt {
                        color:$black;
                        em {
                            margin-top:0;
                            &+p {margin-top:0.5714vw;}
                        }
                    }
                    dd {
                        font-weight:normal;
                        .ico-arrow-right {vertical-align:top;}
                    }
                    &:first-of-type {align-items:center;margin-bottom:2.8571vw;padding-bottom:2.8571vw;border-bottom:0.1429vw solid $gray02;}
                    &+dl {margin-top:1.7143vw;padding-top:0;}
                }
            }
        //나의 가입정보
            &.using-goods {
                min-height: 30.2143vw;
                dl {
                    dt {padding-bottom:1.7143vw;}
                    dd {
                        padding-bottom:1.7143vw;text-align:right;
                        .ico-arrow-right {vertical-align:top;}
                    }
                }
                .btns {margin-top:2.4286vw;}
            }
        //내가 이용중인 상품
            &.using-service {
                dl {
                    margin-bottom:0 !important;padding-bottom:0 !important;border-bottom:none !important;
                    dt {width:12.8571vw;}
                    dd {
                        width:calc(100% - 12.8571vw);
                        &>span {font-weight:600;}
                    }
                }
                &+.box-btn {margin-top:4.2857vw;text-align:center;}
            }
        }
    }
    //제휴카드 안내
    .use-card-list {
        .card-list {
            &>li {
                a {justify-content:flex-start;align-items:center;}
                .card-img-wrap {
                    flex:1 0 4.2143vw;width:4.2143vw;
                    img {width:4.2143vw;height:6.5714vw}
                }
                .card-desc {width:calc(100% - 4.2143vw);margin-left:2.8571vw;}
            }
        }
        .box-btn {
            margin-top:4.2857vw;text-align:center;
        }
    }
    //배너(slide)
    .banner-slide {
        .page-tit-s {margin-bottom:5.4286vw;}
        .my-main-slick {
            .slick-slide{
                margin: 0 0.7143vw; background-color: #EFF1F5; border-radius: 1.4286vw; overflow: hidden; transition: 0.3s;
                &.slick-active{
                    background-color: $purple;
                    *{
                        color: $white; transition: 0.3s;
                    }
                }
                a{
                    display: block; height: 36.2143vw; padding: 3.5714vw 0; background-position: 50% 9.1429vw; background-repeat: no-repeat; background-size: 15.7143vw; text-align: center;
                    * {
                        color: $black;
                    }
                    &.market{
                        background-image: url('/assets/images/main/icon-mymain-market.png');
                    }
                    &.park{
                        background-image: url('/assets/images/main/icon-mymain-park.png');
                    }
                    &.sale{
                        background-image: url('/assets/images/main/icon-mymain-sale.png');
                    }
                    &.service{
                        background-image: url('/assets/images/main/icon-mymain-service.png');
                    }
                    &.buy{
                        background-image: url('/assets/images/main/icon-mymain-buy.png');
                    }
                    &.join{
                        background-image: url('/assets/images/main/icon-mymain-join.png');
                    }

                    dl{
                        dt{
                            margin-bottom: 20.5000vw;
                        }
                        dd{
                            color: $black;
                        }
                    }
                }
            }
            .slick-dots {
                bottom:-6.1429vw;
            }
        }
    }

    //프리미엄 휴대폰 안내
    .product-imgCard {
        margin-top:4.0714vw;
        .cardList {
            gap:3.7857vw;border:0;border-radius:0;padding:0;
            .item-left {
                max-width:16.2143vw;
                .item-img {width:16.2143vw;height:19.2857vw;}
            }
            .item-right {
                width:calc(100% - 20.0000vw);
                .item-txt {
                    &>p {margin-bottom:2.1429vw;}
                    .desc {margin-top:0.7143vw;}
                    .box-btn {margin-top:3.0000vw;}
                }
            }
        }
    }
    //친구추천
    .my-recommend {
        margin-top:8.5714vw;background:#f1f1fe;
        .recommend-tit {
            position:relative;min-height:38.5714vw;padding:5.7143vw 0 0 4.2857vw;
            @include backgroundImg('/assets/images/subpage/mymain-recommend-bg.png', center top, 100% 30.5714vw);
            &::after {
                content:'';display:block;position:absolute;right:0;top:3.7143vw;width:41.2857vw;height:34.8571vw;
                @include backgroundImg('/assets/images/subpage/mymain-recommend-img.png', right top, 41.2857vw 34.8571vw);
            }
            .tit {font-weight:600;color:$white;}
            .txt {margin-top:1.4286vw;color:$white;}
        }
        .recommend-info {
            padding:5.0000vw 2.8571vw 4.2857vw;
            .card-box {
                padding:4.2857vw 2.8571vw;border-radius:1.0000vw;background:$white;
                dl {
                    display:flex;justify-content:flex-start;flex-wrap:wrap;align-items:center;
                    dt {
                        width:17.8571vw;padding-bottom:1.7143vw;color:$black;
                        &:last-of-type {padding:0;}
                    }
                    dd {
                        width:calc(100% - 17.8571vw);padding-bottom:1.7143vw;text-align:right;
                        &:last-of-type {padding:0;}
                        .ico-arrow-right {margin-left:2.1429vw;vertical-align:top;}
                    }
                }
                .invitation {
                    margin-top:2.8571vw;padding-top:2.8571vw;border-top:0.1429vw solid $gray02;
                    .share {
                        display:flex;justify-content:flex-end;gap:1.4286vw;
                        img {width:5.7143vw;height:5.7143vw;}
                    }
                }
            }
        }
    }
    //배너(텍스트+이미지)
    .banner-mix {
        display:flex;flex-direction:column;gap:2.1429vw;margin-top:11.9286vw;
        .bnr {
            display:flex;justify-content:space-between;align-items:center;padding:4.2857vw 1.5714vw 4.2857vw 3.5714vw;border-radius:1.4286vw;
            .txt {
                width:calc(100% - 15.4286vw);
                strong {
                    &+a {margin-top:2.8571vw;}
                }
            }
            .img {
                width:15.4286vw;height:15.4286vw;
                img {width:100%;}
            }
        }
    }
    //배너(통이미지)
    .banner-img {
        display:flex;justify-content:space-between;gap:0 1.7143vw;
        a {
            border-radius:1.4286vw;
            img {width:100%;}
        }
    }
    //준회원 로그인시
    .association-guide {
        display:flex;justify-content:flex-start;align-items:flex-start;padding:4.2857vw 1.4286vw 0;border-radius:1.0000vw;background:#fff7e6;
        .img {
            width:16.8571vw;align-self:flex-end;box-sizing:border-box;
            img {vertical-align:bottom;}
        }
        .info {
            width:calc(100% - 16.8571vw);box-sizing:border-box;padding-left:2.7857vw;
            strong {display:block;font-size:3.0000vw;font-weight:600;}
            p {margin-top:1.4286vw;}
            a {margin-top:6.8571vw;}
        }
    }
    //내가 보유한 포인트
    .my-point {
        .box-point {
            padding:2.8571vw 2.1429vw;border-radius:1.0000vw;background:#f8f8f8;
            dl {
                display:flex;justify-content:space-between;align-items:center;
                dt {font-weight:normal;}
                dd {text-align:right;}
            }
        }
        .btn-group {
            display:flex;justify-content:space-between;align-items:flex-start;margin-top:2.1429vw;
        }
    }
}
}


.roaming-details {
    section {
        .form-box {
           & + .form-box {margin-top:8.3333vw;}
           & + p {margin-top:2.7778vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666}
        }
        & +section {margin-top:8.3333vw;}
        .cardList {
            & + .cardList {margin-top:4.1667vw;}
        }
        .box-btn {margin-top:2.7778vw;}       
        .no-result-msg {
            text-align: center;
            img {width:36.1111vw;}
            p {margin-top:30px; font-size: 4.4444vw;line-height: 1.31;letter-spacing: -0.0889vw;text-align: center;color: #666;}
        }
        .cardList-wrap {
            .call-nber {
                display: flex;justify-content: flex-start;gap: 1.3889vw;
                strong, span {
                    color:#191919;font-size:4.1667vw;font-weight:600 ;
                }
                & + .call-nber {margin-top:1.3889vw;}
            }
            .btn-text-arrow {height:4.4444vw;}
        }
        &.notice {
            .main-txt {margin-bottom:2.0833vw;}
            .txt-line {text-decoration: underline;}
        }
        .amount-used {
            display:flex;align-items: center;justify-content: space-between; padding: 5.5556vw;border-radius: 1.3889vw;background-color: #f9f5ff;
            .amount-txt {
                font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0556vw;text-align: left;color: #191919;
                span {display: inline-block; margin-top:2px; font-size: 3.8889vw;font-weight: normal;letter-spacing: -0.0444vw;text-align: left;color: #666; vertical-align: top;}
            }
            .amount-price {font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0667vw;text-align: right;color: #662d91}
            & + .btn-group {margin-top:4.1667vw;}
        }

        .box-table {
            table {
                th, td {padding:5.5556vw 0;}
                tfoot {
                    th {padding:5.5556vw 0; background: none;border-bottom: 1px solid #ddd;}
                    td {padding-left:4.6944vw;font-size: 4.1667vw;font-weight: 600 !important; line-height: 1.13;letter-spacing: -0.0444vw;text-align: left;color: #191919;}
                }
            }  
            & + .box-table {margin-top:5.5556vw;}
        }
        .table-title {margin-bottom:2.0833vw; padding:0;border:0;font-size: 4.4444vw;font-weight: 600;letter-spacing: -0.0444vw;text-align: left;color: #191919;}
       
    }
}


.service-cancel {
        .txt {margin-top:1.3889vw;font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; color: #666;}
        .num-process-wrap {margin:2.7778vw 0 11.1111vw 0;
            li.location::before {width:0.1389vw; background:#662d91;}
            li.pass {
                .txt-wrap strong {color:#191919}
            }
            li.location.first::before {background:#ddd}
            li.location.pass + .location.disabled::before {background:#ddd;}
            li.location + .disabled::before {background:#ddd;}
            li.location.disabled::before {width:0.1389vw; background:#662d91;}
            li.location.disabled .number {background-color: #662d91;color:#fff;}
            li.disabled.location:last-child::before {width:0.1389vw; background:#662d91}
            li.disabled + li.disabled::before {width:0.1389vw; background:#ddd;}
            li:last-child:after, li:last-child::before {display:none;}
            & + .checking-msg {margin-top:0;}
        }
        .agreement-box {margin-top:2.7778vw;padding:5.5556vw 4.1667vw; border:1px solid #ddd;border-radius: 1.9444vw;
            .agreement-inner {/*overflow-y: auto;height:69.4444vw*/}
            .agreement-inner .tite {font-size: 4.4444vw;  font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; color: #191919;}
            .agreement-inner .tite + .tite {margin-top:2.7778vw}
            .agreement-inner .notification,
            .agreement-inner .notification + .tite {margin-top:2.7778vw;}
            & + .sub-contents-title {margin-top:2.7778vw; font-size: 4.4444vw;}
        }
     .btn-check {margin-top:2.7778vw;
        .t-vt {color:#662d91;}
        & + .sub-contents-title {margin-top:11.1111vw;}
     }
    .identifi-box {
        .ico-list li {width:13.75vw}
    }

    .form-bx {
        .box-input, .box-select {margin-top:5.5556vw;}
        .box-input .btn-dark-gray {width:100%;}
        .inquire-view {
            margin-top:2.0833vw;
            li {padding:5vw 0 5vw 4.1667vw; border-radius: 1.9444vw ;background:#eff1f5; font-size:4.1667vw; }
            li + li {margin-top:2.0833vw;}
            li strong {display:block; position:relative; padding:0 0 0 2.5vw;color:#191919;}
            li strong::before {content:'';position:absolute;left:0;top:0.9722vw;width:0.8333vw;height:0.8333vw; border-radius: 50%; background: #666;}
            li span {font-weight:600; color:#662d91;}
        }
        .txt {margin-top:2.7778vw; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; color: #666;}
        .box-textarea.box-input {margin-top:2.0833vw;}
        .penalty-view {
            margin-top:2.2222vw; padding:4.1667vw;  border-radius: 1.9444vw; background:#eff1f5;
            ul li  
                {
                  strong {color:#191919;}
                  span {font-weight: 600; color:#662d91;}                
                }
            .date {display:block; margin-top:4.1667vw; font-size:3.8889vw; color:#662d91}
        }
         
    }

    .checking-msg {
        margin-top:4.1667vw;text-align: center ;
        img {width:36.1111vw;}
        .msg-txt {font-size: 4.4444vw; font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; color: #191919;}
        .txt {margin-top:1.1111vw; font-size: 4.1667vw; line-height: 1.4; letter-spacing: -0.0833vw; color: #666;}
    }
}

.layer-popup{
    .service-cancel{
        .round-box{
            .notification{
            & > li {
                font-size:4.1667vw; letter-spacing: -0.1111vw; color:#191919; text-align: left;
                span.txt-gy {color:#666;}
                strong {font-weight:600;}
                }
            }
        }   
        .notification{
            margin-top:2.7778vw;
            li {text-align: left; color:#666;}     
        }
        .btn-check {
            margin-top:5.5556vw;
            span {font-weight: 600;text-align: left;}
        }
    }
    .layer-popup-item.point-recmnd {
        .txt-main {
            margin:0;
            span {display:block; color:#191919;font-weight:normal}
        }             
        .recmnd-change  {
            text-align: left;
            .notification-wrp {
                margin-top:5.5556vw;
                h3 {margin-bottom:2.7778vw; font-size: 4.4444vw;font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
            }
        }                
    }

    .roaming-receipt {
        .txt {margin-bottom:5.5556vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.31;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
    }
}


.change-plan-conts{
    .round-box {
        padding:3.8889vw 4.1667vw;border:0.1389vw solid #ddd;border-radius: 1.3889vw;
        .notification {
            text-align: center;
            li {
                display: inline-block;
                text-align: center;
                color:#191919;
                .txt-vt {font-weight:600;}
            }
        }     
    }
}

.meun-link {
    overflow:hidden;position:relative;height:8.3333vw;margin:11.1111vw 0;
    &.open {height:100%;}
    .btn-open {position:absolute;right:0;top:0.5556vw; width:5.5556vw; transform: rotate(0deg);}
    &.open .btn-open {transform: rotate(180deg);}
    .meun-list {
        overflow:hidden;margin:-2.7778vw 0 0 -2.7778vw;
        li {display:inline-block;margin:2.7778vw 0 0 2.7778vw}
        li.on a {color:#fff;background:#662d91}
        li a {display:block;padding:0 4.1667vw;line-height:8.0556vw;border-radius: 8.3333vw;color:#666;font-size:3.8889vw;font-weight:600;background:#f6f6f6;}
    }
    
}




@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .mymain{
        .bill-plan{margin-bottom: 40px;
            .bill-plan-top{
                margin-bottom: 15px;
                .chip-wrap{
                    img{height: 20px;}
                }
                .button-wrap{
                    button{width: 20px; height: 20px;}
                }
            }           
        }

        .my-charge {
            height:100px;
            .btn {width:100%; height:50px;  border-radius: 14px; font-size: 20px;} 
            .charge-txt {bottom:-50px; }      
            .dot-loading {           
                height:50px ;
                .dot-flashing {
                    position: relative; width: 10px; height: 10px;                     
                    &::before { left: -20px; width: 10px; height: 10px;}
                    &::after {left: 20px;width: 10px;height: 10px;}
                }
            }     
             .btn-text-gray>span:not(.ico-arrow-right)  {width:100%;}                        
        }

        #usageInfo{
            min-height: 480px;
        }
        .box-usage-wrap{
            min-height:240px; margin-bottom: 30px; 
            .circle-wrap{
                width: 200px; height: 200px;
                svg{width: 200px; height: 200px;}
                .txt-data{
                    & > span{font-size: 15px;}
                    & > a{font-size: 26px;}
                }
            }
            .feature{
                margin-top: 20px;
                & > span{font-size: 15px;}
            }
        }
        .btn-box{
            .btn-text-gray{justify-content: flex-start;}
        }
        .cardList-wrap{
            margin-top:60px;
            &.point-info-card{min-height: 300px;
                .money-right{
                    min-height: 220px;
                }
                .btn-group{margin-top: 15px;}
            }
            .cardList-title{margin-bottom: 10px;}
            .cardList{
                border-width: 1px; border-radius: 8px; padding: 20px;
                &.money-right{
                    dl{
                        &:first-of-type{border-width: 1px; margin-bottom: 20px; padding-bottom: 20px;}
                        & + dl{margin-top: 10px;}
                        dt{
                            em{
                                & + p{margin-top: 5px; font-size: 15px;}
                            }
                        }
                    }
                }
                &.using-goods{
                    min-height: 220px;
                    dl{
                        dt, dd{
                            padding-bottom: 12px;
                        }
                    }
                    .btns{margin-top: 15px;}
                }
                &.using-service{
                    dl{
                        dd{
                            & > span{font-size: 15px;}
                        }
                    }
                    & + .box-btn{margin-top: 30px;}
                }
            }
        }
        .page-tit-s{margin: 60px 0 15px; font-weight: 600;}
        .banner-img{
            a{width: 100%;}
        }
        .use-card-list{
            .card-list{
                & > li{
                    .card-img-wrap{
                        flex: 1 0 30px; width: 30px;
                        img{width:30px; height: auto;}
                    }
                    .card-desc{
                        width: calc(100% - 30px); margin-left: 20px;
                    }
                }
            }
            .box-btn{
                margin-top: 20px;
            }
        }
        .my-recommend{
            width: 100%; margin-left: 0; margin-top: 60px;
            .recommend-tit{
                min-height: 280px; padding: 40px 0 0 30px; background-size: 100% 220px;
                &::after{
                    width: 300px; height: 250px; top: 26px; background-size: 297px 250px;
                }
                .tit{color: #fff; font-weight: 600;}
                .txt{color: #fff; margin-top: 10px;}
            }
            .recommend-info{
                padding: 36px 20px 30px;
                .card-box{
                    padding: 30px 20px; border-radius: 8px;
                    dl{
                        dt, dd{padding-bottom: 12px;}
                    }
                    .invitation{border-width: 1px; padding-top: 20px;  margin-top: 20px;
                        .share{
                            gap: 10px;
                            img{width: 41px; height: 41px;}
                        }
                    }
                }
            }
        }
        .banner-slide{
            .page-tit-s{margin-bottom: 40px;}
            .my-main-slick{
                .slick-slide{
                    border-radius: 8px; margin: 0 5px;
                    a{
                        height: 260px; padding: 25px 0; background-size: 110px; background-position: 50% 75px;
                        dl{
                            dt{margin-bottom: 150px;}
                        }
                    }
                }
                .slick-dots{bottom: -44px;}
            }
        }
        .product-imgCard{margin-top: 0;}
        .association-guide{
            padding: 40px 20px 0; border-radius: 8px;
            .img{width: 120px;}
            .info{
                width: calc(100% - 120px); padding-left: 20px;
                strong{font-size: 22px;}
                a{margin-top: 50px;}
            }
        }
        .my-point{
            .box-point{padding: 20px 15px; border-radius: 8px;}
            .btn-group{margin-top: 15px;}
        }
        .banner-mix{
            margin-top: 85px; gap: 10px;
            .bnr{padding: 30px 10px 30px 25px; border-radius: 8px;
                .txt{
                    strong{
                        display: block;
                        & + a{margin-top: 20px;}
                    }
                }
                .img{width: 111px; height: 111px;}
            }
        }
        .my-recommend{
            .recommend-tit{
                .img-alpha{width: 30px;}
            }
        }
    } // mymain
    .my{
        .bill-plan{margin: 0 0 40px 0;}
        .use-amount{
            .progress-amount{
                margin-bottom: 20px;
                .title-wrap{
                    strong{margin-bottom: 5px;}
                }
            }
            .box-usage-wrap{
                & + .box-usage-wrap{margin-top: 30px;}
                .usage-title{
                    gap: 5px; margin-bottom: 10px;
                    &::before{width: 25px; height: 25px;}
                }
                .box-usage{
                    border-width: 1px; border-radius: 8px; gap: 20px; padding: 20px;
                    .usage-wrap{gap: 20px;}
                    .txt-wrap{
                        .usage-amount{
                            font-size: 16px;
                            .highlight{font-size: 16px;}
                        }
                        .usage-view{
                            & > div{
                                &::before{width: 3px; height: 3px;}
                                margin-top: 3px; padding-left: 10px; font-size: 16px;
                                &.usage-amount{
                                    font-size: 16px;
                                    .highlight{font-size: 16px; font-weight: 600;}
                                }
                            }
                        }
                        .usage-tit{
                            font-size: 16px;
                            .tooltip-wrap{
                                .txt-tooltip{left: 0;}
                            }
                        }
                        .usage-desc{
                            font-size: 16px;
                        }
                    }
                    .tooltip-wrap{
                        .box-tooltip{
                            .btn-tooltip{width: 15px; height: 15px;}
                        }
                    }
                }
                .circle-wrap{
                    width: 100px; height: 100px;
                    svg{width: 100px; height: 100px;}
                }
            }
            .accordian{
                margin-top: 30px;
                .notification{
                    li{
                        .box-btn{margin: 5px 0 10px;}
                    }
                }
            }
            .find-no-data{
                background-size: 130px 130px; padding-top: 160px;
                .round-box{
                    padding: 30px 20px; border-width: 1px;
                    .main-txt{margin-bottom: 10px;}
                    .box-btn{margin-top: 20px;}
                }
            }
            .box-chart-wrap{
                padding: 0 0 75px;
                .chart-info{
                    margin-bottom: 30px;
                    span{
                        &.date{margin-top: 5px;}
                    }
                }
                .chart-title{
                    margin-bottom: 35px; gap: 10px;
                    &::before{width: 25px; height: 25px;}
                }
                .box-chart{
                    .chart-wrap{
                        .box-average{
                            .average-txt{border-radius: 20px; padding: 5px 10px; color: #fff;}
                        }
                        .chart-conts{
                            .chart-month{margin-top: 10px; bottom: -30px;}
                        }
                    }
                    .chart-line{gap: 35px;}
                }
            }
            &.use-fee-time{
                .cardList-wrap{margin-bottom: 30px;}
                .loading-txt{margin-bottom: 60px;}
                .btn-box{
                    margin-top: 40px;
                    li{
                        &:last-child{margin-top: 15px;}
                    }
                }
            }
            .cont-tit{margin-bottom: 15px;}
        }
        .subscript-serial-number{
            & > .inner{padding-top: 80px;
                .box-tab{
                    .tab-btn-wrap{
                        margin-bottom: 35px;
                    }
                }
                h2{margin-bottom: 10px;}
                .c-unit{
                    border-width: 1px; border-radius: 8px; padding: 20px;
                    .unit-tit{
                        & + .unit-tit{margin-top: 15px;}
                    }
                    .unit-img{
                        margin-top: 15px; text-align: center;
                        img{width: 50%;}
                    }
                }
            }
        }
        .appli-purpose{
            .email-input{margin-top: 40px;}
        }
        .appli-admission{
            .box-input{
                & + .textarea-wrap{margin: 15px 0 30px 0;}
            }
        }
        .box-note{
            .acc-conts{padding: 15px 20px; border-radius: 8px;}
            & > button{margin-bottom: 10px;}
        }
        .call-history-select-receiving{
            .cont-tit{margin-bottom: 10px;}
            .period-set{
                margin-top: 20px;
                & > .box-input{margin-top: 15px;}
            }
        }
        .call-history-appli-inquir{
            .cont-tit{margin-bottom: 10px;}
            .cont-sec{
                margin-top: 20px;
                .box-input{margin-top: 15px;}
            }
        }
        .call-history-reason{
            .box-input{margin-bottom: 15px;}
        }
        .communi-data{
            .box-input{
                & + .textarea-wrap{margin: 15px 0 40px 0;}
            }
        }
        .privacy-info-list{
            .privacy-info-item{
                & + .privacy-info-item{margin-top: 20px;}
                border-width: 1px; border-radius: 8px; padding: 20px;
                .item-desc{
                    .process-txt{font-size: 14px; margin-bottom: 5px;}
                    ul{
                        margin-top: 10px;
                        li{
                            padding-right: 20px;
                            &::after{width: 1px; height: 10px; right: 10px;}
                        }
                    }
                }
            }
            .box-btn{margin-top: 20px;}
        }
        .privacy-info-no-list{
            .img{margin: 40px 0 15px !important;}
        }
        .bill-inquiry{
            .month{
                border-width: 1px; border-radius: 8px; padding: 20px; margin-bottom: 40px;
                .period{
                    margin-bottom: 15px;
                    dt{margin-bottom: 5px;}
                }
                .detail{
                    margin-bottom: 15px;
                    strong{color: #662d91;}
                }
                .how{
                    border-width: 1px; margin-bottom: 20px; padding-top: 20px;
                    dd{
                        a{
                            &.ico-arrow-right{background-size: 20px 20px; padding-right: 25px;}
                        }
                    }
                }
            }
            .past{
                margin-bottom: 40px;
                h2{margin-bottom: 20px;}
                ul{
                    li{
                        border-width: 1px;
                        a{
                            background-size: 20px 20px; padding-right: 40px; background-position: right center;
                            span{line-height: 60px;
                                &:nth-child(1){width: 110px;}
                                &:nth-child(3){min-width: 60px;}
                            }
                        }
                        &:last-child{border-width: 1px;}
                    }
                }
            }
        }
        .bill-detail{
            .page-tit{
                .default-txt{margin-bottom: 5px;}
            }
            .table-title{
                margin-bottom: 30px;
                .bang-notice{margin-top: 5px;}
            }
            .cardList-wrap{margin-top: 10px;}
            .btn-box{
                margin-top: 40px;
                li{
                    & + li{margin-top: 15px;}
                }
            }
            .accordian{margin-top: 40px;}
        }
        .bill-method-change{
            .cardList-wrap{margin-bottom: 40px;}
        }
        .bill-reissue{
            .notification{margin-top: 10px;}
            .box-select{margin-bottom: 40px;}
        }
        .bill-reissue-phone{
            .page-tit{
                & + .box-flex{margin-bottom: 40px;}
            }
        }
        .bill-immediate{
            .month{
                border-width: 1px; border-radius: 8px; padding: 20px; padding-top: 30px;
                .total{
                    border-width: 1px; background-size: 40px; margin-bottom: 20px; padding: 0 20px;
                    dl{margin-bottom: 30px;}
                }
                h2{margin-bottom: 20px;}
            }
            .payment-type{
                margin: 60px 0;
                .inquir-box{margin-bottom: 20px;}
            }
            .info-txt{margin-top: 40px;}
            .accordian{margin-top: 20px;}
            .instantly{
                padding-top: 20px;
                .detail{
                    .tit-detail{font-size: 20px;}
                    strong{color: #662d91;}
                }
            }
        }
        .bill-immediate-payment{
            fieldset{
                margin-bottom: 25px;
                & > div{margin-bottom: 30px;}
                .inquir-box{
                    h2{margin-bottom: 10px;}
                    .txt{margin-top: 10px;}
                }
            }
        }
        .bill-credit-form{
            fieldset{
                & > div{margin-bottom: 30px;}
            }
        }
        .payment-confirm-check{
            .cardList-wrap{
                & + .unit-sec-tit{margin-top: 40px;}
            }
            .sign{
                .bang-notice{margin-top: 40px;}
            }
        }
        .payment-way-finish{
            .ico-img{width: 135px; height: 135px;}
            .payment-wrap{margin-top: 40px;}
        }
        .notice-change-date{
            .btn-radio{
                & + .btn-radio{margin-top: 15px;}
            }
        }
        .my-payment{
            .btns{font-size: 16px;}
        }
        .bill-app{
            .default-txt{margin-bottom: 10px;}
            .inquir-box{
                .btn-radio{
                    label{min-height: 45px; font-size: 14px;}
                }
            }
            .inquir-box{margin-bottom: 20px;}
            .box-total{
                margin-top: 10px;
                .total-wrap{padding: 20px; border-width: 1px; border-radius: 8px;}
            }
            .box-list{margin-top: 30px;}
            .page-tit{
                & > .notification{margin-top: 5px;}
            }
            .accordian{margin-top: 40px;}
        }
        .bill-release-info{
            section{margin-top: 40px;}
            .cardList{margin-top: 15px;}
            .inquir-box{
                margin-top: 15px;
                .default-txt{margin-bottom: 10px;}
            }
        }
        .bill-setting-limit{
            padding: 30px 20px 0 20px;
            .main-txt{margin-bottom: 5px;}
            .money-right{margin-top: 10px;}
            section{
                & + section{margin-top: 40px;}
            }
            .box-input{
                margin-top: 20px;
                .bang-notice{margin-top: 10px;}
                .input-unit{
                    top: 57px;
                    &.on{right: 41px;}
                }
            }
            .accordian{margin-top: 40px;}
        }
        .mb80{margin-bottom: 40px;}
        .bill-history{
            .cardList-wrap{margin-top: 8px;}
        }
        .my-join-info{
            .cardList-wrap{
                .cardList{
                    dl{gap: 15px 10px;}
                    & + .box-btn{margin-top: 10px;}
                }
                .box-btn{
                    &.btn-group{
                        .btn_link{
                            a{font-size: 15px;}
                        }
                    }
                }
                & + .cardList-wrap{
                    margin-top: 30px;
                }
                .no-list{padding: 20px;}
            }
        }
        .subscript-change{
            .phone-desc{
                margin-bottom: 30px;
                p{margin-top: 5px;}
            }
        }
        .subscript-select-discount{
            .payment-list-card{margin-top: 40px;}
            .cardList{margin-top: 15px;}
            .content{
                margin-top: 30px;
                .btn-text-arrow{margin-bottom: 10px;}
            }
            .payment-list-card{
                .pay-amount{padding-top: 20px;}
            }
        }
        .subscript-terms-popup{
            .notification{
                li{margin-bottom: 10px;}
            }
        }
        .subscript-cancellation-charge-popup{
            h3{
                margin-top: 30px;
                &:first-child{margin: 30px 0 10px 0;}
            }
            .notification{margin-top: 10px;}
            .table-type-04{
                & +.table-type-04{margin-top: 30px;}
            }
        }
        .subscript-installment-info{
            .cardList-wrap{margin-top: 30px;}
            .money-right{
                & + .money-right{margin-top: 15px;}
            }
            .btn-wrap{margin-top: 40px;}
            .no-list{
                & + .notification{margin-top: 15px;}
            }
        }
        .subscript-return-amount{
            .cardList-wrap{
                margin-top: 40px;
                .bang-notice{margin-top: 5px;}
            }
            .box-table{margin-top: 30px; margin-bottom: 10px;}
            .table-type-01{
                tbody{
                    tr{
                        &:first-child{
                            th, td{padding-top: 20px;}
                        }
                        &:last-child{
                            th, td{padding-bottom: 20px;}
                        }
                    }
                }
                & + .table-type-01{margin-top: 30px;}
            }
        }
        .subscript-phone-change{
            .speech-bubble{padding: 20px; margin-bottom: 30px; border-radius: 8px;}
            .no-regist{
                .img{width: 135px; height: 135px; margin: 0 auto 15px;}
                p{margin-bottom: 10px;}
            }
        }
        .subscript-phone-info{
            .page-tit{margin-bottom: 40px;}
            .box-select{margin-top: 20px;}
            .box-input{
                & ~ .box-input{margin-top: 20px;}
                .btn-text-arrow{margin-top: 10px;}
                .bang-notice{margin-top: 10px;}
            }
            .btn-radio-group{
                & + .box-input{margin-top: 20px;}
            }
            h2{margin-top: 40px;}
            .os-wrap{
                margin-top: 20px;
                .default-txt{margin-bottom: 8px;}
            }
        }
        .subscript-serial-number{
            & > .inner{
                .c-unit{
                    .unit-tit{
                        p{margin-top: 5px;}
                    }
                }
            }
        }
        .rate-plan-change{
            .cardList-wrap{
                & + section{
                    margin-top: 40px;
                    .main-txt{margin-bottom: 15px;}
                }
            }
        }
        .rate-plan-no-change{
            .cardList-wrap{
                margin-bottom: 40px;
                .cardList{
                    .dot-txt {
                        position: relative; margin-top:1.3889vw;padding-left:2.0833vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #666;
                        &::before {content:''; position: absolute;left:0;top:1.6667vw;width:0.8333vw;height:0.8333vw;background: #666;border-radius: 50%;}
                        a {display:inline-block;width:auto; margin-top:2.7778vw;}
                    }
                }
            }            
        }
        .rate-plan-popup-05{
            .table-title{margin-bottom: 10px;}
            .table-type-04{
                .discount-pay{font-size: 20px;}
            }
        }
        .rate-plan-popup-06{
            .c-unit{
                &:first-child{border-width: 1px; padding-bottom: 20px; margin-bottom: 20px;}
            }
        }
        .rate-plan-popup-barogo{
            .c-unit{
                & + .c-unit{margin-top: 30px;}
            }
        }
        .subscript-name-change{
            .box-select{margin-bottom: 30px;}
            & > section{margin-top: 30px;}
            .num-process-wrap{
                & + .notification{margin-top: 10px;}
                & + .btns{margin-top: 15px;}
            }
            .main-txt{margin-bottom: 5px;}
            .sub-contents-title{
                & + .main-txt{margin-top: 20px;}
            }
            p{
                & + .num-process-wrap{margin-top: 10px;}
            }
        }
        .subscript-name-certi{
            .input-group{margin-top: 20px;}
        }
        .subscript-name-exist{
            .box-input{
                & + .box-input{margin-top: 20px;}
            }
        }
        .phone-installment-bottomsheet{
            .cardList-wrap{
                p{margin-top: 10px;}
            }
        }
        .subscript-name-new{
            .box-input{
                & + .box-input{margin-top: 20px;}
            }
            .radio-group-wrap{margin-top: 20px;}
        }
        .subscript-name-complete{
            .page-tit{padding-bottom: 150px; margin-bottom: 20px; background-size: 135px 135px;}
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 30px;}
            }
            .notification{margin-top: 40px;}
        }
        .subscript-name-assignee{
            .agree-wrap{border-width: 1px; padding-bottom: 30px; margin-bottom: 30px;}
        }
        .subscript-name-assignee-new{
            & > .box-input{
                & + .box-input{margin-top: 20px;}
            }
            .input-depth{margin-top: 20px;}
        }
        .subscript-name-assignee-exist{
            & > .box-input{
                & + .box-input{margin-top: 20px;}
            }
        }
        .subscript-write-info{
            & > section{
                & + section{margin-top: 20px;}
            }
            .info-card{
                .box-input{
                    & + .box-input{margin-top: 20px;}
                }
                .input-group{margin-top: 20px;}
                .box-btn{margin-top: 15px;}
            }
            .notification{margin-top: 40px;}
        }
        .num-change-info{
            & > section{
                margin-top: 40px;
                .main-txt{margin-bottom: 10px;}
            }
        }
        .num-change-want{
            & > section{margin-top: 20px;}
            .box-input-phone{
                .input-wrap{
                    &:nth-of-type(1){
                        width: 80px;
                    }
                    &:nth-of-type(n+2){
                        margin-left: 15px;
                        width: calc((100% - 110px)/2);
                        input{padding: 16px 15px;}
                        &::before{left: -10px;}
                    }
                }
            }
            .radio-group-wrap{
                .btn-radio{
                    & + .btn-radio{margin-top: 10px;}
                }
                .box-btn{margin-top: 10px;}
            }
        }
        .num-change-complete{
            .box-ico-txt{
                & + p{margin-top: 40px;}
            }
            & > section{
                margin-top: 30px;
                .main-txt{margin-bottom: 5px;}
                .num-process-wrap{
                    margin-top: 20px;
                    & + p{
                        margin-top: 10px;
                        & + .box-btn{margin-top: 15px;}
                    }
                }
            }
        }
        .serviceChange-list{
            .page-tit{
                padding: 25px 20px;
                .img-wrap{width: 120px; height: 120px; right: 10px;}
                .txt-wrap{
                    .desc{margin-top: 15px; gap: 15px;}
                }
                & + .box-btn{margin-bottom: 30px;}
            }
            .box-btn{margin-top: 20px;}
            .money-right{
                & + .money-right{margin-top: 15px;}
            }
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 40px;}
            }
            .btn-group{
                margin-top: 20px; gap: 10px;
                .btns{padding: 12px 0; border-radius: 8px;}
            }
            .accordian{margin-top: 40px;}
        }
        .serviceChange-blocking{
            .phone-desc{
                p{margin-top: 10px;}
            }
            .radio-group-wrap{margin-top: 30px;}
            .accordian{
                margin-top: 30px;
                .acc-conts{
                    .notification{
                        li{
                            p{background-size: 20px 20px; padding-left: 25px; margin-top: 5px;}
                        }
                    }
                }
            }
        }
        .pause-application{
            .page-tit{
                p{margin-top: 5px;}
            }
            .contents-title{margin-bottom: 5px;}
            .contents-wrap{
                & + .contents-wrap{margin-top: 40px;}
            }
            .cardList{
                .card-check{color: #662d91;}
                & + .cardList{margin-top: 15px;}
            }
            &.full-popup-conts{
                .radio-group-wrap{
                    .group-tit{margin-bottom: 10px;}
                    .btn-radio{
                        label{height: 60px;}
                    }
                    & + .notification{margin-top: 10px;}
                }
                .contents-wrap{margin-top: 30px;}
                .main-txt{margin-bottom: 10px;}
                .box-input{
                    & + .box-input{margin-top: 10px;}
                }
                .round-gray-box{
                    border-radius: 8px; padding: 20px;
                    .btn-check{margin-bottom: 10px;}
                }
                .accordian{margin-top: 40px;}
            }
            .page-tit{
                h1{
                    .notification{margin-top: 10px;}
                }
            }
        }
        .subscribe-list{
            .box-my-use{margin-bottom: 10px;}
            .cardList-wrap{
                .cardList{
                    & + .cardList{margin-top: 15px;}
                    padding: 30px 20px;
                    .card-check{
                        .box-info{
                            margin-bottom: 15px;
                            .info-product{margin-top: 10px;}
                            .info-point{margin-top: 5px;}
                        }
                    }
                    dl{padding: 15px; border-radius: 8px;}
                    .btns{margin-top: 15px;}
                }
            }
            .accordian{margin-top: 40px;}
            .box-select{margin-bottom: 40px;}
        }
        .subscribe-detail{
            & + .cardList-wrap{margin-top: 30px;}
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 30px;}
                .cardList{
                    .btn-group{gap: 10px; margin-top: 25px;}
                }
            }
            .accodian-wrap{
                margin-top: 30px;
                .accordian-list{
                    margin-top: 10px; border-width: 1px;
                    & > li{
                        .title{padding: 20px 30px 15px 0;}
                    }
                }
            }
            .box-btn{margin-top: 20px;}
            .money-right{
                dl{
                    dd.big{font-size: 20px;}
                }
            }
            .pl30{margin-bottom: 10px; padding-left: 15px;}
            .under-line-wrap{
                border-width: 1px; padding: 20px; border-radius: 8px;
                & > li{
                    padding-bottom: 20px; border-width: 1px;
                    .btn-box{margin-top: 20px;}
                    & + li{padding-top: 20px;}
                }
            }
            .accodian-wrap{
                .accordian-list{
                    li{
                        .acc-conts{padding: 0 0 20px 0;}
                    }
                }
            }
        }
        .subscribe-purchase{
            .content{
                & + .content{margin-top: 20px;}
            }
            .box-flex{
                & + .brns{margin-top: 15px;}
            }
        }
        .order-phone-auth{
            .page-tit{
                .box-btn{margin-top: 15px;}
            }
            .box-flex{margin-top: 20px;}
        }
        .order-list{
            .notification{margin-bottom: 40px;}
            .box-select{margin-bottom: 40px;}
            .num-process-budle{
                border-width: 1px; border-radius: 8px; padding: 20px;
                .sub-contents-title{margin-bottom: 20px;}
            }
            section{
                & + section{margin-top: 30px;}
            }
            .cardList{
                & + .box-btn{
                    margin-top: 15px;
                    &.mt80{margin-top: 40px;}
                }
            }
            .box-banner{
                margin-top: 40px;
                & + .box-banner{margin-top: 30px;}
            }
        }
        .order-esim-active-popup{
            .one-title{
                & + .one-title{margin-top: 15px;}
                .unit{
                    strong{margin-bottom: 5px;}
                    & + .unit{margin-top: 15px;}
                    span{
                        & + span{margin-top: 10px;}
                    }
                    .btns{margin-top: 15px;}
                }
            }
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 30px;}
                .cardList-title{
                    & + p{
                        & + .one-title{margin-top: 20px;}
                    }
                }
            }
            .unit-img{
                margin-top: 20px;
                img{width: 150px;}
            }
        }
        .order-wireless{
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 40px;}
                .cardList{
                    & + .cardList{margin-top: 20px;}
                    &.is-title{
                        .card-title{padding-bottom: 20px; margin-bottom: 20px; border-width: 1px;}
                    }
                    dt{width: 25%;}
                    dd{width: 75%;}
                }
                & + .notification{margin-top: 10px;}
                .signiture-txt{
                    margin-top: 10px;
                    img{width: 35px; height: 35px;}
                }
            }
            .noti-box{
                margin-top: 40px;
                .main-txt{margin-bottom: 10px;}
                .bg-gray{
                    padding: 15px; border-radius: 8px; margin: 20px 0 15px;
                    .btn-check{
                        & + p{margin-top: 10px;}
                    }
                }
            } 
            .btn-check{
                input[type=checkbox]:disabled+label span{
                    &::after{width: 20px; height: 20px; background-size: 10px 10px; top: 10px;}
                }
            }
            .accordian{
                margin-top: 40px;
                .acc-conts{margin-top: 15px;}
            }
        }
        .order-list-detail{
            .payment-detail-card-wrap{
                margin-bottom: 40px;
                & > ul{
                    & > li{
                        &:not(:first-of-type){border-width: 1px; padding-top: 20px; margin-top: 20px;}
                    }
                }
                li{
                    .txt-vt{margin-top: 15px;}
                }
                .phone-thumbnail{width: 100px; height: 100px; margin: 0 auto 20px;}
                .detail-title{margin-bottom: 10px;}
            }
            .accordian{
                .acc-conts{
                    .notification{
                        margin-top: 15px;
                        li{margin-bottom: 5px;}
                    }
                    .box-table{
                        margin-top: 15px;
                        & + .cardList-wrap{
                            margin-top: 15px;
                            p{margin-top: 10px;}
                        }
                    }
                }
            }
        }
        .order-usim{
            .box-select{margin-top: 40px;}
            .mt60{margin-top: 30px;}
            .point-sort-card{
                .middle{
                    margin-top: 20px;
                    dl{
                        gap: 15px;
                        dt{width: 90px !important;}
                    }
                }
            }
            .box-table{
                .table-title{padding-bottom: 10px;}
            }
            .btn-list{
                li{margin-top: 15px;}
            }
        }
        .order-address{
            .box-input{
                & + .box-input{margin-top: 20px;}
            }
            .box-select{margin-top: 20px;}
        }
        .order-goods-application{
            .cardList-wrap{
                .cardList{
                    gap: 20px 15px;
                    & + .cardList{margin-top: 15px;}
                }
                .box-btn{margin-top: 10px;}
            }
            section{
                & + section{margin-top: 20px;}
            }
        }
        .view-prodcuts-rate{
            .cardList{
                gap: 20px; padding: 30px 20px;
            }
        }
        .view-prodcuts-phone{
            .cardList{padding: 30px 20px;}
        }
        .point-lookup{
            .radio-group-wrap{margin-top: 30px;}
        }
        .point-use-info{
            .c-unit{
                .unit-tit{margin-bottom: 5px;}
            }
            .btn-flex{
                gap: 15px; margin-top: 20px;
            }
        }
        .point-use-application{
            .detail-sec-tit{margin-bottom: 20px;}
            .detail-sec-desc{
                .box-flex{
                    & + .box-flex{padding-top: 8px;}
                    & > .box-btn{
                        button{width: 100px;}
                    }
                }
            }
        }
        .subscript-usim-change{
            .cardList-wrap{
                h3{font-size: 16px; margin-bottom: 8px;}
                & + .cardList-wrap{margin: 30px 0;}
            }
            .usim-register{
                .img-modelcode{
                    width: 200px;
                }
                .btn-text-arrow{margin: 20px 0 40px;}
                .select-input-wrap{
                    margin-bottom: 15px;
                    .select-label{font-size: 16px;}
                }
            }
            .appli-identification{
                .identifi-box{
                    .ico-list{
                        li{
                            width: 60px; height: 90px;
                            img{width: 60px; height: 60px;}
                        }
                    }
                }
                .identifi-complete{
                    padding: 20px 0; border-radius: 8px;
                    img{width: 100px; height: 100px;}
                    p{font-size: 16px;}
                }
            }
            .msg-box{
                .msg-icon{width: 150px; margin: 0 auto;}
                .msg-txt{font-size: 18px;}
                .txt{font-size: 16px; margin-top: 5px;}
            }
        }
    } // my
    .layer-popup{
        &.my{
            .usim-change{
                .box-tit{font-size: 16px; margin-bottom: 10px;}
                .usim-change-info{
                    padding: 20px; border-radius: 8px;
                }
            }
        }
    }
    .ocr-view{
        .inner{margin-top: 80px;}
        .ocr{
            &-frame{height: 300px; border-radius: 8px;}
            &-txt{
                margin-top: 30px; 
                strong, p{color: #fff;}
            }
        }
    }
    .meun-link{
        margin: 40px 0; height: 40px;
        .meun-list{
            width: calc(100% - 30px); margin: 0;
            li{
                margin: 0 10px 15px 0;
                a{font-size: 16px; line-height: 40px; padding: 0 20px;}
            }
        }
        .btn-open{top: 5px; width: 30px;}
    }
}


.guide-document {
    .guide-box {
        padding:8.3333vw 5.5556vw; border:1px solid #ddd; border-radius: 2.7778vw;
        .tit {font-size: 5vw; font-weight: 600;  line-height: 1.17; letter-spacing: -0.1vw;  text-align: left;  color: #191919;}
        .btn-group {margin-top:5.5556vw; gap:2.7778vw;}
        .btns {width:36.1111vw; padding:4.4444vw 0; border-radius: 1.9444vw; font-size:4.1667vw;}
        .btns.white {width:38.8889vw;}
        h4 {margin:6.9444vw 0 2.7778vw 0; font-size: 4.1667vw;  font-weight: 600; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #191919;}
        .notification {margin:0;}
    }
}

.full-popup .docment-write {
     .cardList-wrap {
        margin-top:11.1111vw;
        .cardList-title {margin-bottom: 4.1667vw}
        .cardList dl dt {width:25vw;}
        .cardList dl dd {    width: calc(100% - 25vw);}
        .normal-txt {display:block; margin-bottom:1.3889vw;}
        .rq-form.docment {margin-top:5.5556vw;padding-top:5.5556vw; border-top:1px solid #ddd;}
        .rq-form .box-flex + .box-flex {margin-top:5.5556vw;}
        .rq-form.docment .box-flex p {margin-top:2.7778vw;}
        .rq-form .box-flex + .box-btn {margin-top:4.1667vw;}
     }
     .basic-certificate {
        margin-bottom:5.5556vw;
        .input-label {display: block; margin-bottom: 2.2222vw;  color: #191919;  font-size: 4.4444vw;  font-weight: 600;   line-height: 1.25;  letter-spacing: -.0889vw; text-align: left;}
        .btn-del {border-radius:1.9444vw;background-color: #dee1e5; font-size: 4.4444vw;font-weight: 600;line-height: 1.31; letter-spacing: -0.0889vw; text-align: center;color: #191919;}
        .txt {margin-top:2.7778vw; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;}
        .box-flex {
            margin:0; padding:0;
            &  + .box-flex {margin-top:2.0833vw;padding-top:0;}
        }
        .btn-add {
            margin-top:4.1667vw; padding-bottom:5.5556vw;
            .btns {height:13.8889vw; border-radius: 1.9444vw;  border: solid 1px #ddd;background-color: #fff;  font-size: 4.4444vw; font-weight: 600; line-height: 1.31; letter-spacing: -0.0889vw; text-align: center; color: #191919;}
        }
    }
}
.full-popup.case-ios{
    .full-popup-conts{
         .inner {padding-bottom: 55.5556vw}
    }
}

.full-popup.my {
    header {
        h2 {letter-spacing: -0.3889vw;}
    }
}

.layer-popup-item.change-nber{
    .popup-header {
        .txt-main {margin:0;}
    }
    .cardList-wrap{ 
        margin-bottom:2.7778vw;
        .cardList{
            li {
                font-size: 4.1667vw;  line-height: 1.33; letter-spacing: -0.0833vw; text-align: center; color: #191919;
                & + li {margin-top:2.7778vw;}
                strong {color:#662d91;}
            }
        }
    }
    p {line-height:1.33;}
}

.full-popup.splus {
    header {
                padding-left:5.5556vw; justify-content: start; text-align: left;
        img {display: inline-block; margin-right:1.3889vw; height:5.2778vw; vertical-align: top;}
    }
}

.full-popup-conts.order-address {
    .box-input {
        & + .box-btn {margin-top:4.1667vw;}
        .txt-description {margin-top:1.3889vw;}
    }
   
    .box-flex {
        padding-top:5.5556vw;
        .box-input {
            & + .box-btn {margin-top:0;}
        }
    }
    .input-depth {
        .box-flex {padding-top:6.9444vw;}
    }
   
}

.cardList-wrap {
    .txt-box {
        margin-top:8.3333vw;padding-top:5.5556vw;border-top:1px solid #ddd;
        p {
            font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: left;color: #666;
        &+p {margin-top:1.3889vw;}
        }
    }  
}

