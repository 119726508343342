
.c-unit{
    .unit-tit{
        @include subContsTitle; margin-bottom: 5.5556vw;
    }
    .unit-sec-tit{
        .mt40{
            margin-top: 5.5556vw;
        }
        margin-bottom: 2.7778vw;
        h2{@include subContsTitle;}
        h3{@include mainTxt;}
        p{margin-top: 1.3889vw; @include smallTxt;}
        .notification{margin-top: 2.7778vw;}
        .bang-notice{margin-top: 2.7778vw;}
        & + .unit-sec-tit{
            margin-top: 8.3333vw;
        }
    }
    .unit-img{
        & + .btn-text-arrow{
            margin-top: 5.5556vw;
        }
    }
    & + .c-unit{
           margin-top: 11.1111vw;
    }
}
.page-tit{
    & > .btn-text-arrow{
        margin-top: 4.1667vw;
    }
}
.detail-sec{
   padding-bottom:11.1111vw;
    .detail-sec-tit{
        @include subContsTitle;
    }
    .detail-sec-desc{
        h3{
            @include mainTxt; margin-top: 8.3333vw; margin-bottom:1.3889vw;
        }
        & + .detail-sec-desc{
            margin-top:5.5556vw;
        }
    }
    .btn-text-arrow{
        margin-top:5.5556vw;
    }
}
.icon-img{
    width:36.1111vw; height: 36.1111vw; margin:2.7778vw auto 0;
}
.end-box{
    background: $bg; padding:5.5556vw; border-radius: 2.7778vw; margin-top: 5.5556vw;
    .notification{
        margin-top:1.3889vw;
    }
    .bang-notice{
        margin-top: 1.3889vw;
    }
    a{
        color: #0054ff; text-decoration: underline;
    }
}


// 배너
.new-application{
    a{
        width:100%; border-radius: 2.7778vw; background:#EFF1F5; padding:6.9444vw 10.5556vw  6.9444vw 5.5556vw; display: flex; justify-content: space-between; align-items: center;
        .txt{
            h3{
                @include mainTxt;
            }
            p{
                @include defaultTxt; font-weight: 400; margin-top: 4.1667vw;
                span{width:5.5556vw; height: 5.5556vw; border-radius: 50%; background: $purple; color:$white; display: inline-flex;  align-items: center; justify-content: center;}
            }
        }
        .img{
            width:19.5833vw;
        }
    }
}
//상단 스텝
.step-staus-list{
    display: flex; justify-content: flex-end; align-items: center; gap: 4.1667vw; position: absolute;
    top: 16.6667vw; width: 100%; margin-top: 4.1667vw;padding-right: 5.5556vw;
    li{
        width: 6.3889vw;height: 6.3889vw; overflow: hidden;
        .number{
            display: inline-block; width: 6.3889vw; height: 6.3889vw; border-radius: 50%; background-color: $gray02; color: $white; font-weight: 600; line-height: 6.3889vw; text-align: center;
        }
        .page-txt{
            @include defaultTxt; margin-left: 1.3889vw;
        }
        &.on{
            width: auto;
            .number {background-color: $purple;}
        }
    }
    & + .inner {
        padding-top: 38.8889vw !important;
    }
}

// bg 박스
.bg-box{
    padding: 5.5556vw; background-color: $bg;border-radius: 1.9444vw;
    .mainTxt{
        @include mainTxt; display: block;
    }
    p{
        @include smallTxt; margin-top: 1.3889vw;
    }
    .box-btn {margin-top: 2.7778vw;}
}


// 피해예방안내 - 고정 간격 컨텐츠
.fix-ct-group {
    &>h2 {
        display:block;margin:11.1111vw 0 1.3889vw;@include subContsTitle;
        &+h3 {margin-top:5.5556vw;}
        &>a {@include subContsTitle;}
    }
    &>h3 {display:block;margin:8.3333vw 0 1.3889vw;@include mainTxt;;}
    &>h4 {display:block;margin:2.7778vw 0 1.3889vw;@include defaultTxt;color:$gray05;}
    .txt {
        margin-top:1.3889vw;@include smallTxt;color:#666 !important;font-weight:400 !important;
        &+.notification,
        &+.txt-dot {margin-top:2.2222vw;}
    }
    .under-link {color:#4673f1;text-decoration:underline;}
    .btn-tit-arrow {
        padding-right:6.9444vw;
        @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right center, 5.5556vw 5.5556vw);
    }
    .img-box {margin:2.7778vw 0;}
    .visual-box {
        margin:11.1111vw 0 5.5556vw;
        &+h2 {margin-top:5.5556vw;}
    }
    .cardList-group {
        margin-top:5.5556vw;
        .cardList-dl {
            &+.cardList-dl {margin-top:4.1667vw;}
        }
    }
    .cardList-dl {
        overflow:hidden;border:0.2778vw solid $gray02;border-radius:2.7778vw;
        dt {padding:4.1667vw 5.5556vw;background:$gray01;@include defaultTxt;}
        dd {
            padding:5.5556vw;
            .link-box {margin-top:4.1667vw;}
        }
    }
    .mark-call {width:4.1667vw;height:4.1667vw;vertical-align:middle;}
    .link-box {
        display:block;margin-top:4.1667vw;font-style:normal;
        &>a {
            display:inline-block;padding-right:7.2222vw;
            @include backgroundImg('/assets/images/icon/ico-arrow-circle.svg', right 0.1389vw top 0.5556vw, 4.4444vw 4.4444vw);
            @include defaultTxt;
        }
    }
    .slide-box {
        margin:5.5556vw 0 11.1111vw;padding:8.3333vw 4.7222vw 0;background:$gray01;border-radius:2.7778vw;
        .tit {display:block;margin-bottom:8.3333vw;@include defaultTxt;text-align:center;}
        .step-box {
            font-size:0;text-align:center; margin-bottom: 6.9444vw;
            .step {display:inline-block;height:6.9444vw;padding:0.6944vw 4.5833vw;border-radius:3.4722vw;background:$purple;@include defaultTxt;color:$white;}
        }
        .list {
            margin:0 auto 18.8889vw;border-top:none;
            .slick-slide {
                @include smallTxt;text-align:center;
                img {width:55.5556vw; margin:0 auto;}
                .txt {display:block;margin-top:4.1667vw;text-align:center;}
            }
            .slick-dots {position:relative;left:auto;bottom:-7.9167vw;}
        }
    }
    .example-box {
        margin-top:8.3333vw;@include smallTxt;
        li {
            &+li {margin-top:8.3333vw;}
        }
        &>strong {display:block;margin-bottom:5.5556vw;@include defaultTxt;}
        .rolling-box {width:auto;margin-top:2.7778vw;}
        .info-box {margin-top:4.1667vw;}
    }
    .list-num {
        margin-top:5.5556vw;
        li {
            &+li {margin-top:5.5556vw;}
        }
    }
    .list-line {
        margin:5.5556vw 0 4.1667vw;border-top:0.2778vw solid $gray02;
        li {
            display:flex;justify-content:flex-start;align-items:center;padding:5.5556vw 4.1667vw;border-bottom:0.2778vw solid $gray02;
            b {width:33.8889vw;@include defaultTxt;font-weight:normal;}
            span {width:calc(100% - 33.8889vw);@include smallTxt;font-weight:normal;}

        }
    }
    .notification {
        li {
            margin-bottom:1.3889vw;
            .link-box {margin-top:1.3889vw;}
        }
    }

    /*.noti-inner {
        h3 {display:block;margin-top:4.1667vw;color:$black;font-weight: 600;}
    }*/

    .rolling-box {
        display:flex;justify-content:flex-start;flex-wrap:nowrap;gap:3.8889vw;overflow-x:auto;width:76.1111vw;margin:0 auto;
        p {display:block;flex-shrink:0;width:61.1111vw;min-height:61.1111vw;padding:4.1667vw;border:0.1389vw solid $gray02;border-radius:1.3889vw;@include smallTxt;;word-break:break-all;word-wrap:break-word;}

    }
    .app_list {
        margin-top:5.5556vw;padding:5.5556vw;border:0.2778vw solid $gray02;border-radius:2.7778vw;
        li {
            display:flex;
            &+li {margin-top:4.1667vw;padding-top:4.1667vw;border-top:0.1389vw solid $gray02;}
            .app-img {display:block;overflow:hidden;width:22.2222vw;height:22.2222vw;border-radius:1.9444vw;}
            .app-info {
                display:flex;justify-content:space-between;gap:5.8333vw;width:calc(100% - 22.2222vw);padding-left:2.7778vw;
                dl {
                    display:flex;justify-content:center;flex-direction:column;
                    dt {display:block;margin-bottom:1.3889vw;@include defaultTxt;}
                    dd {display:block;@include smallTxt;}
                }
                .app-link {
                    display:flex;justify-content:center;flex-direction:column;gap:2.7778vw;
                    a {display:block;overflow:hidden;width:6.6667vw;}
                }
            }
        }
    }
    .box-input {margin-bottom:5.5556vw;}
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .c-unit{
        .unit-tit{
            margin-bottom: 2.8571vw;
        }
        .unit-sec-tit{
            .mt40{
                margin-top: 2.8571vw;
            }
            margin-bottom: 1.4286vw;
            p{margin-top: 0.7143vw; }
            .notification{margin-top: 1.4286vw;}
            .bang-notice{margin-top: 1.4286vw;}
            & + .unit-sec-tit{
                margin-top: 4.2857vw;
            }
        }
        .unit-img{
            & + .btn-text-arrow{
                margin-top: 2.8571vw;
            }
        }
        & + .c-unit{
            margin-top: 5.7143vw;
        }
    }
    .page-tit{
        & > .btn-text-arrow{
            margin-top: 2.1429vw;
        }
    }
    .detail-sec{
    padding-bottom:5.7143vw;
        .detail-sec-desc{
            h3{
                margin-top: 4.2857vw; margin-bottom:0.7143vw;
            }
            & + .detail-sec-desc{
                margin-top:2.8571vw;
            }
        }
        .btn-text-arrow{
            margin-top:2.8571vw;
        }
    }
    .icon-img{
        width:18.5714vw; height: 18.5714vw; margin:1.4286vw auto 0;
    }
    .end-box{
        background: $bg; padding:2.8571vw; border-radius: 1.4286vw; margin-top: 2.8571vw;
        .notification{
            margin-top:0.7143vw;
        }
        .bang-notice{
            margin-top: 0.7143vw;
        }
        a{
            color: #0054ff; text-decoration: underline;
        }
    }


    // 배너
    .new-application{
        a{
            width:100%; border-radius: 1.4286vw; background:#EFF1F5; padding:3.5714vw 5.4286vw  3.5714vw 2.8571vw; display: flex; justify-content: space-between; align-items: center;
            .txt{
                p{
                    font-weight: 400; margin-top: 2.1429vw;
                    span{width:2.8571vw; height: 2.8571vw; border-radius: 50%; background: $purple; color:$white; display: inline-flex;  align-items: center; justify-content: center;}
                }
            }
            .img{
                width:10.0714vw;
            }
        }
    }
    //상단 스텝
    .step-staus-list{
        display: flex; justify-content: flex-end; align-items: center; gap: 2.1429vw; position: absolute;
        top: 8.5714vw; width: 100%; margin-top: 2.1429vw;padding-right: 2.8571vw;
        li{
            width: 3.2857vw;height: 3.2857vw; overflow: hidden;
            .number{
                display: inline-block; width: 3.2857vw; height: 3.2857vw; border-radius: 50%; background-color: $gray02; color: $white; font-weight: 600; line-height: 3.2857vw; text-align: center;
            }
            .page-txt{
                margin-left: 0.7143vw;
            }
            &.on{
                width: auto;
                .number {background-color: $purple;}
            }
        }
        & + .inner {
            padding-top: 20.0000vw !important;
        }
    }

    // bg 박스
    .bg-box{
        padding: 2.8571vw; background-color: $bg;border-radius: 1.0000vw;
        .mainTxt{
            display: block;
        }
        p{
            margin-top: 0.7143vw;
        }
        .box-btn {margin-top: 1.4286vw;}
    }


    // 피해예방안내 - 고정 간격 컨텐츠
    .fix-ct-group {
        &>h2 {
            display:block;margin:5.7143vw 0 0.7143vw;
            &+h3 {margin-top:2.8571vw;}
        }
        &>h3 {display:block;margin:4.2857vw 0 0.7143vw;}
        &>h4 {display:block;margin:1.4286vw 0 0.7143vw;color:$gray05;}
        .txt {
            margin-top:0.7143vw;color:#666 !important;font-weight:400 !important;
            &+.notification,
            &+.txt-dot {margin-top:1.1429vw;}
        }
        .under-link {color:#4673f1;text-decoration:underline;}
        .btn-tit-arrow {
            padding-right:3.5714vw;
            @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right center, 2.8571vw 2.8571vw);
        }
        .img-box {margin:1.4286vw 0;}
        .visual-box {
            margin:5.7143vw 0 2.8571vw;
            &+h2 {margin-top:2.8571vw;}
        }
        .cardList-group {
            margin-top:2.8571vw;
            .cardList-dl {
                &+.cardList-dl {margin-top:2.1429vw;}
            }
        }
        .cardList-dl {
            overflow:hidden;border:0.1429vw solid $gray02;border-radius:1.4286vw;
            dt {padding:2.1429vw 2.8571vw;background:$gray01;}
            dd {
                padding:2.8571vw;
                .link-box {margin-top:2.1429vw;}
            }
        }
        .mark-call {width:2.1429vw;height:2.1429vw;vertical-align:middle;}
        .link-box {
            display:block;margin-top:2.1429vw;font-style:normal;
            &>a {
                display:inline-block;padding-right:3.7143vw;
                @include backgroundImg('/assets/images/icon/ico-arrow-circle.svg', right 0.0714vw top 0.2857vw, 2.2857vw 2.2857vw);
            }
        }
        .slide-box {
            margin:2.8571vw 0 5.7143vw;padding:4.2857vw 2.4286vw 0;background:$gray01;border-radius:1.4286vw;
            .tit {display:block;margin-bottom:4.2857vw;text-align:center;}
            .step-box {
                font-size:0;text-align:center; margin-bottom: 3.5714vw;
                .step {display:inline-block;height:3.5714vw;padding:0.3571vw 2.3571vw;border-radius:1.7857vw;background:$purple;color:$white;}
            }
            .list {
                margin:0 auto 9.7143vw;border-top:none;
                .slick-slide {
                    text-align:center;
                    img {width:28.5714vw; margin:0 auto;}
                    .txt {display:block;margin-top:2.1429vw;text-align:center;}
                }
                .slick-dots {position:relative;left:auto;bottom:-4.0714vw;}
            }
        }
        .example-box {
            margin-top:4.2857vw;
            li {
                &+li {margin-top:4.2857vw;}
            }
            &>strong {display:block;margin-bottom:2.8571vw;}
            .rolling-box {width:auto;margin-top:1.4286vw;}
            .info-box {margin-top:2.1429vw;}
        }
        .list-num {
            margin-top:2.8571vw;
            li {
                &+li {margin-top:2.8571vw;}
            }
        }
        .list-line {
            margin:2.8571vw 0 2.1429vw;border-top:0.1429vw solid $gray02;
            li {
                display:flex;justify-content:flex-start;align-items:center;padding:2.8571vw 2.1429vw;border-bottom:0.1429vw solid $gray02;
                b {width:17.4286vw;font-weight:normal;}
                span {width:calc(100% - 17.4286vw);font-weight:normal;}

            }
        }
        .notification {
            li {
                margin-bottom:0.7143vw;
                .link-box {margin-top:0.7143vw;}
            }
        }

        /*.noti-inner {
            h3 {display:block;margin-top:2.1429vw;color:$black;font-weight: 600;}
        }*/

        .rolling-box {
            display:flex;justify-content:flex-start;flex-wrap:nowrap;gap:2.0000vw;overflow-x:auto;width:39.1429vw;margin:0 auto;
            p {display:block;flex-shrink:0;width:31.4286vw;min-height:31.4286vw;padding:2.1429vw;border:0.0714vw solid $gray02;border-radius:0.7143vw;word-break:break-all;word-wrap:break-word;}

        }
        .app_list {
            margin-top:2.8571vw;padding:2.8571vw;border:0.1429vw solid $gray02;border-radius:1.4286vw;
            li {
                display:flex;
                &+li {margin-top:2.1429vw;padding-top:2.1429vw;border-top:0.0714vw solid $gray02;}
                .app-img {display:block;overflow:hidden;width:11.4286vw;height:11.4286vw;border-radius:1.0000vw;}
                .app-info {
                    display:flex;justify-content:space-between;gap:3.0000vw;width:calc(100% - 11.4286vw);padding-left:1.4286vw;
                    dl {
                        display:flex;justify-content:center;flex-direction:column;
                        dt {display:block;margin-bottom:0.7143vw;}
                        dd {display:block;}
                    }
                    .app-link {
                        display:flex;justify-content:center;flex-direction:column;gap:1.4286vw;
                        a {display:block;overflow:hidden;width:3.4286vw;}
                    }
                }
            }
        }
        .box-input {margin-bottom:2.8571vw;}
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .detail-sec{
        padding-bottom: 40px;
        .detail-sec-desc{
            h3{margin-top: 30px; margin-bottom: 5px;}
            & + .detail-sec-desc{margin-top: 20px;}
        }
    }
    .c-unit{
        & + .c-unit{margin-top: 30px;}
        .unit-sec-tit{
            margin-bottom: 10px;
            p{margin-top: 5px;}
            & + .unit-sec-tit{margin-top: 30px;}
            .notification{margin-top: 10px;}
            .mt40{margin-top: 40px;}
            .bang-notice{margin-top: 10px;}
        }
        .unit-tit{margin-bottom: 20px;}
        .unit-img{
            & + .btn-text-arrow{margin-top: 20px;}
        }
    }
    .page-tit{
        & > .btn-text-arrow{margin-top: 15px;}
    }
    .fix-ct-group{
        .txt{margin-top: 5px;}
        .link-box{margin-top: 20px;}
    }
    .fix-ct-group{
        & > h2{margin: 40px 0 5px;}
        & > h3{margin: 40px 0 5px;}
        & > h2{
            & + h3{margin-top: 20px;}
        }     
        & > h4{margin: 9px 0 5px;}
        .link-box{
            & > a{background-size: 15px 15px; background-position: right 2px top 1px; padding-right: 25px;}
        }
        .cardList-group{
            margin-top: 20px;
            .cardList-dl{
                & + .cardList-dl{margin-top: 15px;}
            }
        }
        .cardList-dl{
            border-width: 1px; border-radius: 8px;
            dt{padding: 15px 20px;}
            dl{padding: 20px;}
            dd{
                padding: 20px;
                .link-box{margin-top: 15px;}
            }
        }
        .mark-call{width: 15px; height: 15px;}
        .slide-box{
            margin: 20px 0 40px; padding: 30px 15px 0; border-radius: 8px;
            .tit{margin-bottom: 30px;}
            .list{margin: 0 auto 60px;}
            .step-box{
                margin-bottom: 25px;
                .step{padding: 2px 15px; height: 25px; border-radius: 20px; line-height: 25px; color: #fff;}
            }
            .list{
                .slick-slide{
                    img{width: 195px;}
                }
                .slick-dots{bottom: -30px;}
            }
        }
        .notification{
            li{
                margin-bottom: 5px;
                .link-box{margin-top: 5px;}
            }
        }
        .app_list{
            margin-top: 20px; padding: 20px; border-width: 1px; border-radius: 8px;
            li{
                & + li{margin-top: 15px; padding-top: 15px; border-width: 1px;}
                .app-img{width: 80px; height: 80px; border-radius: 8px;}
                .app-info{
                    padding-left: 10px; width: calc(100% - 80px);
                    dl{
                        dt{margin-bottom: 5px;}
                    }
                    .app-link{
                        gap: 10px;
                        a{width: 25px;}
                    }
                }
            }
        }
        .example-box{
            margin-top: 30px;
            & > strong{margin-bottom: 20px;}
            .rolling-box{
                margin-top: 10px; gap: 10px;
                p{width: 225px; min-height: 225px; padding: 15px; border-radius: 8px; border-width: 1px;}
            }
            li{
                & + li{margin-top: 30px;}
            }
        }
        .list-num{
            margin-top: 20px;
            li{
                & + li{margin-top: 20px}
            }
        }
        .img-bot{margin: 10px 0;}
        .visual-box{
            margin: 0 0 20px;
            img{width: 100%;}
            & + h2{margin-top: 20px;}
        }
        .btn-tit-arrow{padding-right: 25px; background-size: 20px 20px;}
        .list-line{
            margin: 20px 0 15px; border-width: 1px;
            li{
                padding: 20px 15px; border-width: 1px;
                b{width: 125px; font-weight: 400;}
            }
        }
    }
    .icon-img{width: 135px; height: 135px; margin: 10px auto 0;}
    .end-box{padding: 20px; border-radius: 8px; margin-top: 20px;}
}