.ui-menu {
  list-style: none;
  margin: 0 0 $ui-menu-spacing;
  display: block;
  outline: none;
  min-width: $ui-menu-min-width;
  padding: $ui-menu-padding;
  @include ui-border-radius($ui-menu-border-radius);
  border-color: $ui-menu-border-color;
  border-width: $ui-menu-border-border-width;

  & & {
    position: absolute;
    z-index: $ui-zindex-menu;
  }

  &-item {
    margin: 0;
    cursor: pointer;
    position: relative;
    padding: $ui-menu-item-padding-v $ui-menu-item-padding-h;
    border: $ui-border-width solid transparent;
    @include ui-transition($ui-menu-item-transition);

    &.ui-state-active,
    &.ui-state-focus {
      border-color: transparent;
      background: $ui-menu-item-active-background;
      @include ui-state-link($ui-menu-item-active-color);
      outline: none;

    }
  }

  &-divider {
    margin: $ui-menu-divider-margin-v 0;
    border: 0;
    border-bottom: $ui-menu-divider-border;
  }

  &-icon {
    float: right;
  }
}
