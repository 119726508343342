.chip-wrap{
    display: flex;
    align-items: center;
    gap:0.8333vw;
    flex-wrap: wrap;
    max-width: calc(100% - 8.3333vw);
    .chip{
        display: inline-flex;
        border-radius:0.6944vw;
        height:5vw;
        padding:0vw 1.6667vw;
        text-align: center;
        @include chipTxt;
        &:first-child{
            background-color:$lightpurple;
        }
        &:nth-child(2){
            background-color:$purpleblue;
        }
    }
    img{height: 4.4444vw;}
}

.badge{
    @include text(400,3.0556vw,1.36,#fff,-0.0917vw); width:16.6667vw; height:16.6667vw; border-radius: 50%; display: flex; justify-content: center; align-items: center;
}
.product-badge{
    // display: inline-block; padding:0.4167vw 1.25vw 0.5556vw 1.3889vw; border-radius:1.1111vw;
    // @include text(500,3.0556vw,1.36,#fff,-0.1222vw); margin-right:1.3889vw;background-color: $black;
    flex-shrink: 0;
    img{height: 4.5833vw; margin-right: 1.3889vw; vertical-align: middle;}
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .chip-wrap{
        display: flex;
        align-items: center;
        gap:0.4286vw;
        flex-wrap: wrap;
        max-width: calc(100% - 4.2857vw);
        .chip{
            display: inline-flex;
            border-radius:0.3571vw;
            height:2.5714vw;
            padding:0.0000vw 0.8571vw;
            text-align: center;
            &:first-child{
                background-color:$lightpurple;
            }
            &:nth-child(2){
                background-color:$purpleblue;
            }
        }
        img{height: 2.2857vw;}
    }

    .badge{
        @include text(400,1.5714vw,1.36,#fff,-0.0714vw); width:8.5714vw; height:8.5714vw; border-radius: 50%; display: flex; justify-content: center; align-items: center;
    }
    .product-badge{
        flex-shrink: 0;
        img{height: 2.3571vw; margin-right: 0.7143vw; vertical-align: middle;}
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .chip-wrap{
        gap: 5px;
        .chip{height: 18px; padding: 0 5px;}
        img{height: 18px;}
    }
    .product-badge{
        img{height: 15px; margin-right: 5px;}
    }
}