@charset "UTF-8";

.util {
    // 검색
    .search{
        .inner{
            display: flex;flex-direction: column;
        }
        .search-bundle {
            & ~ .search-bundle{margin-top: 13.8889vw;}
            .movie-damage-prevention{margin-top:0;}
        }
        .search-title{
            @include contsTitle; margin-bottom: 5.5556vw;
        }
        .result-not-found{
            display: flex;width: 100%;height: 50vh;justify-content: center;align-items: center;
            p{
                @include mainTxt; text-align: center; margin:5.5556vw 0;
            }
        }

        .search-result {
            margin-top:11.1111vw;
            .result-msg {
                font-size: 5vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.1vw;text-align: center;color: #191919;
                span {
                    font-size: 5vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.1vw;color:#662d91;
                }
            }
        }

        .recmd-search{
            margin-top:60px;           
            .tit {display: block; margin-bottom:2.7778vw; font-size: 5vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.1vw;text-align: left;color: #191919;}
            .recmd-list {
                display: flex;flex-wrap: wrap; gap :2.7778vw 4.1667vw;
                a {display:inline-block; font-size: 4.1667vw;line-height: 1.2;letter-spacing: -0.0833vw;text-align: left;color: #666; text-decoration: underline;}
            }   
        }

        .search-category {
            margin-top:8.3333vw; padding: 8.3333vw 2.7778vw 8.3333vw 5.5556vw;border-radius: 2.7778vw;border: solid 0.1389vw #ddd;background-color: #fafafa;
            .category-total {
                margin-bottom:5.5556vw;
                p {
                    font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.1vw;text-align: left;color: #191919;
                    span {display: inline-block;font-size: 5vw;font-weight: 600;line-height: 1.11;letter-spacing: -0.1vw; color:#ec008c;}
                }
            }
            .category-list {
                display: flex;flex-wrap: wrap; gap :2.7778vw 2.0833vw;
                li {
                    a {
                        font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #191919;
                        span {display: inline-block; margin-left:0.4167vw; font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color:#ec008c;}
                    }
                }
            }
        }

        .search-faq {
            padding: 8.3333vw 5.5556vw;border-radius: 2.7778vw;border: solid 0.1389vw #ddd;background-color: #fff;
            .box-faq {
                display: flex;
                img {width:16.6667vw;}
                .wrap {
                    margin-left:5.5556vw;
                    .tit {display:block;margin-bottom:1.3889vw; font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919; }
                    .txt {display:block;margin-bottom:4.1667vw; font-size: 4.1667vw;line-height: 1.2;letter-spacing: -0.0833vw;text-align: left;color: #666;}
                }
                & +.box-faq {margin-top:8.3333vw;padding-top:8.3333vw;border-top:1px solid #ddd;}
            }
        }

        .seacrh-cmnt {
            margin-top:11.1111vw;
            .tit {display:block;  font-size: 4.4444vw;font-weight: 600;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
            .btn-cmnt {margin-top:4.1667vw;}
        }

        .search-adcn {
            border-bottom:1px solid #ddd;           
            .accordian {
                .acc-trigger {
                    margin:0; padding:5.5556vw 5.5556vw 5.5556vw 0; border-top:1px solid #ddd;font-size: 4.4444vw;line-height: 1.25;letter-spacing: -0.0889vw;text-align: left;color: #666;                  
                }
                .acc-conts {
                    padding:5.5556vw 0; border-top:1px solid #ddd;
                    p {font-weight: 400;letter-spacing: -0.0417vw;line-height: 1.5;}
                    img {padding:2.7778vw;}
                    iframe {width:100%;padding:2.7778vw;}
                }
            }
        }

        .search-wrong {
            p {font-size: 4.1667vw;line-height: 1.4;letter-spacing: -0.0833vw;text-align: left;color: #666;}
            .categry {margin-top:5.5556vw;}
        }

        .search-bundle {
            .search-title {
                span {display: inline-block;margin-left:0.2778vw; color:#ec008c; font-size: 5vw;font-weight: bold;line-height: 1.05;letter-spacing: -0.1111vw;text-align: left;vertical-align: middle; }
            }
        }

        .search-more{
            margin-top: 4.1667vw;
        }
        .box-tab {
            margin-top: 11.1111vw;
            .tab-btn-wrap{
                li{
                    a{
                        &.on{background-color: $black;}
                    }
                }
            }
            .tab-contents-wrap{
                .rank-list {
                    display: flex; flex-direction: column; gap: 2.7778vw;
                    li{
                        display: flex; align-items: center; gap: 2.7778vw;
                        &.rank-top{
                            .rank-num{color: $purple;}
                        }
                        .rank{
                            &-num{
                                display: inline-block; width: 5.5556vw; height: 5.5556vw;
                                @include mainTxt;
                            }
                            &-txt{
                                @include mainTxt;
                            }
                        }
                    }
                }
            }
        }
        .search-menu,
        .search-wrong {
            display: flex; flex-direction: column;
            div{
                & ~ div{
                    margin-top: 5.5556vw;
                }
                strong{
                    @include defaultTxt; display: block;
                }
                p{
                    @include defaultTxt; font-weight: 400; margin-top: 1.3889vw;
                }
            }
        }
        .cardList-wrap{
            display: block;
            & > div{
                & + div{margin-top: 4.1667vw;}
            }
            &.card-desc{
                .cardList{
                    padding: 8.3333vw 5.5556vw;
                }
            }
            .cardList{
                display: flex;
                &:focus,&:active,&:hover {
                    border-color: $purple;
                }
                & ~ .cardList,& ~ div{
                    margin-top: 4.1667vw;
                }
                .payment-desc {
                    dt{margin-top: 0;}
                    .pay-amount {
                        display: block;
                        span{@include fontInherit;}
                    }
                }
                .item-right{
                    .item-txt {
                        display: flex;flex-direction: column;justify-content: space-between;
                        .item-price {
                            align-items: flex-end;
                            strong {text-align: right;}    
                            .dc-prc {display:block; font-size: 3.8889vw; letter-spacing: -0.0778vw; text-align: right; color: #666; text-decoration: line-through;}
                        }
                    }
                }
            }
        }
        .use-card-list{
            .card-list {
                gap: 0;
                div{
                    & ~ div{
                        margin-top: 4.1667vw;
                    }
                    a{
                        &:focus,&:active,&:hover {
                            border-color: $purple;
                        }
                    }
                }
                .card-desc{margin-top: 0;}
            }
        }
        .event-wrap{
            & > div{
                & + div{margin-top: 11.1111vw;}
            }
            .cardList-wrap {
                & ~ a, & ~ div {margin-top: 11.1111vw;}
            }
            .cardList-img{
                img{
                    width: 100%; height:100%; object-fit: cover;
                }
            }
        }
        .downLoad-list{
            & > div{
                & + div{margin-top: 4.1667vw;}
                & > div:not(:first-of-type){margin-top: 4.1667vw;}
            }
            .downLoad-tit{font-weight: 600;}
        }
        .list{
            margin: 0 !important;
        }
        .search-banner{
            a{
                display: block;
                img{display: block; width: 100%;}
            }
        }
    }
    .full-popup-conts.search {
        position: relative;      
    }
    .corporate-no-result{
        .popup-body{
            margin-bottom: 0 !important;
        }
        .desc{
            @include smallTxt;
            a{
                color:#0054ff; text-decoration: underline;
            }
        }
        .txt-sub{
            margin-top: 5.5556vw;
        }
        .shop-num{
            display: block; margin-top: 1.3889vw; padding-left: 4.8611vw !important;
            @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,4.1667vw 4.1667vw);
        }
    }
    .box-radio{
        .radio-title{
            @include defaultTxt;
            display: block; font-weight: 600; margin-bottom: 2.2222vw;
        }
    }
    //회원가입
    .mem-type {
        .type-list {
            margin-bottom:12.5000vw;
            & > li {
                &:nth-of-type(n+2) {margin-top: 4.1667vw;}
                .text-btn-gray{
                    @include mainTxt;
                    @include backgroundImg('/assets/images/icon/ico-14less.svg',5.5556vw center, 11.1111vw 11.1111vw);
                    padding: 7.7778vw 5.5556vw 7.7778vw 22.2222vw;
                }
                &:nth-of-type(2) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-14over.svg');}
                }
                &:nth-of-type(3) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-personal.svg');}
                }
                &:nth-of-type(4) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-corp.svg');}
                }
            }
        }
        &.type2{
            .type-list > li{
                &:nth-of-type(1) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-14over.svg');}
                }
                &:nth-of-type(2) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-personal.svg');}
                }
                &:nth-of-type(3) {
                    .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-corp.svg');}
                }
            }
        }
    }
    // 회원정보수정
    .mem-info{
        .cardList-wrap{
            padding: 0;
            dl {
                gap: 2.7778vw 0;
                dt{width: 40%; padding-bottom: 0;}
                dd{width: 60%; padding-bottom: 0;}
            }
            .sns-wrap{
                .accordian{
                    .acc-trigger{padding-bottom: 0;}
                    .acc-conts{padding-top: 4.1667vw;}
                    .sns-button{
                        display: flex; flex-wrap: wrap; gap: 2.7778vw;
                        .icon-sns {
                            display: inline-block; width: 11.1111vw; height: 11.1111vw;
                            &.naver{@include backgroundImg('/assets/images/icon/ico-sns-naver.png', center top,contain);}
                            &.google{@include backgroundImg('/assets/images/icon/ico-sns-google.png', center top,contain);}
                            &.apple{@include backgroundImg('/assets/images/icon/ico-sns-apple.png', center top,contain);}
                            &.kakao{@include backgroundImg('/assets/images/icon/ico-sns-kakao02.png', center top,contain);}
                        }
                    }
                }
                .txt-vt{
                    display: block;
                }
                span{
                    display: block;margin-top: 1.3889vw;
                    @include defaultTxt;
                }
                .box-btn{
                    margin-top: 4.1667vw;
                }
            }
        }
    }
    .join{
        .box-input{
            & + .box-input{margin-top: 5.5556vw;}
            .btn-check{
                justify-content: space-between; margin-top: 2.0833vw;
            }
            .btn-check-type2{
                & + .btn-check{
                    padding-left: 4.1667vw;margin-top: 5.5556vw;
                }
            }
            .gray-box{
                background-color: $gray01; border-radius: 2.7778vw; padding: 5.5556vw; margin-top: 2.7778vw;
                .check-top {
                    margin-top: 0; padding-bottom: 5.5556vw; border-bottom: 0.2778vw solid $gray02; margin-bottom: 5.5556vw;
                }
                .btn-check {
                    label{width: auto;}
                }
                p{
                    @include smallTxt; margin-bottom: 4.1667vw;
                }
                .receive-wrp + .btn-check  {margin:5.5556vw 0 0; padding:5.5556vw 0 0;border-top:1px solid #ddd;border-bottom:0;}
            }
        }
        .btn-column{
            display: flex; flex-direction: column; gap: 8.3333vw; margin-top: 11.1111vw;
            .btn-underline{
                display: block; text-align: center; @include smallTxt; text-decoration: underline;
            }
        }
        .phone-complete{
            & + .cardList-wrap{
                margin-top: 8.3333vw;
            }
        }
        .tel-radio-wrap{margin-bottom: 11.1111vw;}
        .tel-group{
            flex-wrap: wrap;
            .btn-radio {
                width: 27.7778vw;
                .ico {
                    &::before{
                        content: '';
                        display: inline-block; margin-right: 1.3889vw; width: 5.5556vw; height: 5.5556vw;
                        @include backgroundImg('/assets/images/icon/logo-skt.png', center, contain);
                    }
                }
                label{
                    display: flex; align-items: center; justify-content: center;line-height: initial;
                    &.ico-skt {
                        &::before{
                            content: '';
                            display: inline-block; margin-right: 1.3889vw; width: 5.5556vw; height: 5.5556vw;
                            @include backgroundImg('/assets/images/icon/logo-skt.png', center, contain);
                        }
                    }
                    &.ico-kt {
                        &::before{
                            content: '';
                            display: inline-block; margin-right: 1.3889vw; width: 5.5556vw; height: 5.5556vw;
                            @include backgroundImg('/assets/images/icon/logo-kt.png', center, contain);
                        }
                    }
                    &.ico-lg {
                        &::before{
                            content: '';
                            display: inline-block; margin-right: 1.3889vw; width: 5.5556vw; height: 5.5556vw;
                            @include backgroundImg('/assets/images/icon/logo-lg.png', center, contain);
                        }
                    }
                }
            }
        }
        .save-phone-radio-wrap{
            display: none; margin-top: 6.9444vw;
        }
    }
    // 동의철회, 회원탈퇴
    .mem-withdraw{
        .page-tit{
            h1{
                span{font-weight: inherit; font-size: inherit; line-height: inherit; letter-spacing: inherit; color: $gray05;}
            }
        }
        .cardList-wrap{
            .cardList{
                &.is-scroll{
                    max-height: 59.7222vw; overflow-y: scroll;
                }
            }
            & + .cardList-wrap{
                margin-top: 8.3333vw;
            }
            & + .btn-check{
                margin-top: 11.1111vw;
                label{
                    span{@include defaultTxt;}
                }
            }
        }
        .withdraw-text-wrap{
            word-break: keep-all;
            @include smallTxt;
            strong{@include defaultTxt;}
            & + .btn-check{
                margin-top: 34.7222vw;
            }
        }
    }

    .agree-list {
        .agree-wrap {
            padding-bottom:5.5556vw;
            .btn-check-type2 {margin-bottom:5.5556vw;}
            .agree-group {
                padding:0 4.1667vw;

                .btn-check {width:100%;}
                .btn-check+.btn-check {margin-top:4.4444vw;}
            }
        }
    }
    .identification {
        .cardList-wrap {
            margin-bottom:11.1111vw;padding:0;
            .cardList dl dd {text-align:left;}
        }
    }

    // 법인사업자
    .corporate{
        .box-input{
            & + .box-input{margin-top:4.1667vw;}
        }
        .check-wrap{
            display: flex;justify-content: space-between;align-items: center;margin: 2.7778vw 0 4.1667vw;
            .bang-notice{height: 5.5556vw;margin-top: 0;}
            .btn-check{
                label{width: auto;}
            }
        }
    }
    // 아이디찾기
    .find-id {
        .cardList-wrap {
            padding:0;
            .text-btn-gray {
                height:22.2222vw;padding-left:22.2222vw;border:0.1389vw solid #ddd;background:#fff;font-size:4.4444vw;line-height:8.3333vw;
                &+.text-btn-gray {margin-top:4.1667vw;}
                @include backgroundImg('/assets/images/icon/ico-find-id.svg', 5.5556vw center,11.1111vw 11.1111vw);
                &:nth-of-type(2){background-image: url('/assets/images/icon/ico-find-pw.svg');}
            }
        }
        .btn-group {
            padding-bottom:11.1111vw;font-size:0;text-align:center;
            .btn-other-log {display:inline-block;margin:0 auto; @include smallTxt; text-decoration: underline;}
        }
    }
    .find-finish {
        .cardList-wrap {
            padding:0;
            .cardList {margin-bottom:5.5556vw;border-radius:2.7778vw;}
            .id-txt{color: $purple;}
        }
    }
    // 로그인
    .login{
        .page-tit{
            & + .box-input {
                & + .box-input {
                    margin-top: 2.2222vw;
                }
            }
        }
        .login-other{
            text-align: center; margin-top: 11.1111vw;
            button {
                @include smallTxt; text-decoration: underline;
            }
        }
        .box-radio {
            & + .box-radio {margin-top: 5.5556vw;}
            & + .box-input {margin-top: 5.5556vw;}
        }
        .box-input {
            & + .box-input{margin-top: 5.5556vw;}
            & + .box-flex{margin-top: 5.5556vw;}
        }
        .tooltip-wrap{
            margin: 2.7778vw 0 5.5556vw;
            .btn-check{
                label{padding-left: 0;}
            }
        }
        .box-btn{
            .text-btn-gray{
                margin-top: 11.1111vw;
                & + .text-btn-gray{margin-top: 4.1667vw;}
            }
        }
        .box-sns{
            padding-bottom: 18.0556vw;margin-top: 11.1111vw; text-align: center;
            .icon-list {
                display: flex;justify-content: center;align-items: center; gap: 3.3333vw;
                li{
                    & > a{display: block; width: 13.8889vw; height: 13.8889vw;}
                }
                .icon {
                    &-sns-naver{@include backgroundImg('/assets/images/icon/ico-sns-naver.png', center,contain);}
                    &-sns-kakao{@include backgroundImg('/assets/images/icon/ico-sns-kakao02.png', center,contain);}
                    &-sns-google{@include backgroundImg('/assets/images/icon/ico-sns-google.png', center,contain);}
                    &-sns-apple{@include backgroundImg('/assets/images/icon/ico-sns-apple.png', center,contain);}
                }
            }
            .txt-desc{
                display: block; margin-bottom: 4.1667vw;
                @include defaultTxt; font-weight: 400;
            }
        }
        .btn-check-type2 {
            & + .btn-check{margin-top: 5.5556vw;}
        }
        .btn-check{
            label{
                width: auto;
                span{@include defaultTxt;}
            }
        }
        .box-flex.column{margin-top: 5.5556vw;}

        // 간편 로그인
        &.simple{
            padding-top: 0;
            .phone-num-box{
                display: flex; justify-content: center; align-items: center; background-color: $gray01; height:100%; min-height:15vw; margin-bottom: 11.1111vw;
                @include defaultTxt;
                .box-select { height:100%; padding:4.8611vw 5.5556vw}
            }
            .page-tit{
                p{margin-top: 1.3889vw;}
            }
            .birth-input{
                display: flex; justify-content: center; margin-bottom: 11.1111vw;
                input{
                    display: block;position: absolute;overflow: hidden;margin: -1px;padding: 0;width: 1px;height: 1px;line-height: 1px;font-size: 1px;border: 0;clip: rect(0 0 0 0);
                }
                .birth-dots{
                    display: flex; gap: 5.5556vw;
                    span{
                        display: inline-block; width: 4.4444vw; height: 4.4444vw; background-color: $gray02; border-radius: 50%;
                        &.on{
                            background-color: $purple;
                        }
                    }
                }
            }
            .box-noti {
                padding: 4.1667vw; background-color: $bg; border-radius: 2.7778vw; margin-bottom: 11.1111vw;
                & + .box-btn{
                    position: absolute; bottom: 13.8889vw; left: 50%; transform: translateX(-50%);
                    button{@include smallTxt; text-decoration: underline;}
                }
                .no-dots{
                    .box-btn{margin-top: 4.4444vw;}
                }
            }
            .cardList-wrap{
                .cardList{
                    strong{display: block; @include mainTxt; margin-bottom: 1.3889vw;}
                    p{@include smallTxt; margin-bottom: 5.5556vw;}
                }
            }
        }
    }
    //[바텀시트] 로그인방법선택
    .login-other-list {
        .popup-body{
            ul {
                li {
                    &:last-of-type{
                        a{
                            border: 0; padding-bottom: 0; margin-bottom: 0;
                        }
                    }
                    a{
                        display: flex; align-items: center; gap: 3.3333vw; padding-bottom: 4.1667vw; margin-bottom: 4.1667vw;border-bottom: 0.2778vw solid $gray02;
                        @include mainTxt;
                        .box-ico{
                            width: 11.1111vw; height: 11.1111vw;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    // 법인회선
    .corp-line{
        .table-type-04{
            .btn-check{
                input[type=checkbox] {
                    & + label{
                        span:before,span:after {
                            left: 50%; transform: translate(-50%, -50%);
                        }
                    }
                }
            }
            .ico-delete{vertical-align: middle;}
            tr{
                th,td{
                    padding: 5.5556vw 1.3889vw;
                    &:first-of-type{padding-left: 4.1667vw;}
                    &:last-of-type{padding-right: 4.1667vw;}
                }
                td:nth-of-type(2){
                    @include defaultTxt; font-weight: 400;
                }
                .chip-wrap{
                    display: inline-block; margin-right: 1.3889vw;
                    img{height: 5vw;vertical-align: middle;}
                }
            }
        }
        .no-search-result{
            background-image: url('/assets/images/icon/ico-corp.svg');
        }
        .detail-sec{
            margin-top: 11.1111vw;
        }
        .box-radio{
            margin-top: 5.5556vw;
        }
    }
    //sns 유알모
    .introduce-sns{
        .back-thumb-card{
            height: auto;
            a{
                padding:0;
                .back-bg{
                    position: relative;
                    top:0; left:0;
                    .back-conts{
                        position:absolute; top:0; left:0;
                        .back-header{
                            position:absolute; bottom:0;left:50%; transform: translateX(-50%);  width:100%; padding:5.5556vw;
                            h3{
                                margin-bottom:0; text-align: center; width:100%; display: block;
                                *{@include fontInherit;}
                            }
                        }
                    }
                }
                &:before{
                    display: none;
                }
            }
            &.txt-only{
                .back-bg{
                    width:100%;
                    .back-conts{
                        position: static;
                        .back-header{
                           bottom:auto; background: none; position: static; left:0; transform: none;
                           h3{
                               color:$black !important;
                               * {color:$black !important;}
                           }
                        }
                    }
                }
            }
            .back-header{
               background-color:rgba(0,0,0,0.5);
            }
        }
        .box-btn{
            margin-top: 2.7778vw;
        }
    }
    //영상
    .introduce-video{
        .movie-damage-prevention{
            margin-top: 11.1111vw;
            .thumb-list{
                & > li{
                    .movie{
                        height: 49.8611vw;
                        a{
                            height: 49.8611vw;
                        }
                        img{
                            object-fit: cover; height: 49.8611vw; display: block; width: 100%;
                        }
                    }
                }
            }
        }
    }
    // 브랜드
    &.pb0{padding-bottom: 0;overflow: hidden;}
    .introduce-ualmo{
        .brand-banner{
            display: flex; justify-content: center; align-items: center; height:145.4167vw;
            @include backgroundImg('/assets/images/subpage/introduce-ualmo/ualmo-visual.jpg',center, cover);
        }
        .brand-main-txt{
            @include mainVisualTxt;          
        }       
        .brand-sub-txt{
            @include defaultTxt; font-weight: 400; margin-top:4.1667vw;
        }
        .img-wrap{
            img{display: block;}
        }
        .main-visual{
            .txt-wrap{
                *{text-align: center;}
                p{
                    @include mainTxt; color: $white;
                }
                .brand-main-txt{
                    margin-top:4.1667vw; color: $white;
                }
                p.sm-txt {font-size: 4.1667vw;font-weight: normal;line-height: 1.2;letter-spacing: -0.0417vw;color: #fff;}
                p.md-txt {margin-top:0.6944vw;font-size: 6.1111vw;font-weight: 600; line-height: 1.2; letter-spacing: -0.1222vw; color: #fff;}
                p.big-txt {font-size: 11.8056vw;font-weight: bold; line-height: 1; letter-spacing: -0.2361vw;color: #fff; }
            }
        }
        .app-capture{
            display: flex; flex-direction: column; justify-content: space-between; margin-top: 13.8889vw; gap: 9.7222vw;
            .img-wrap{
                width: 100%;
            }
        }
        .easy-ualmo{
            background-image: url('/assets/images/subpage/introduce-ualmo/easy-ualmo.jpg'); height: 142.7778vw;align-items: flex-start; padding-top: 13.8889vw;
            .txt-wrap {
                *{
                    color: $white; text-align: center;
                }
            }
        }
        .trust-ualmo{
            background-image: url('/assets/images/subpage/introduce-ualmo/trust-ualmo.jpg'); height: 142.7778vw;align-items: flex-start; padding-top: 13.8889vw;
            .txt-wrap {
                *{
                    color: $white; text-align: center;
                }
                .notification {
                    margin-top:4.1667vw;
                    li {
                        padding-left:2.0833vw; font-size: 4.1667vw; font-weight: 100; line-height: 1.2; letter-spacing: -0.0833vw; text-align: left; color: #fff;
                        &::before {
                            content: "";
                            width: 0.5556vw;
                            height: 0.5556vw;
                            background: #fff;
                            border-radius: 50%;
                            position: absolute;
                            top: 1.6667vw;
                            left: 0;
                        }
                    }
                }
            }
        }
        .rolling-area{
            display: flex; flex-direction: column; position: relative;  padding: 13.8889vw 0;
            .txt-wrap{
                padding: 0 5.5556vw; margin-bottom: 13.8889vw;
            }
            .rolling-banner{
                overflow: visible; padding-left: 13.8889vw;
                .slick-list,.slick-track{
                    overflow: visible;
                }
                .rolling-item{
                    display: flex;flex-direction: column;align-items: center; position: relative; width: 72.2222vw; height: 76.3889vw; border-radius: 13.3333vw; padding:8.3333vw 0 0;background-color: #e748aa;overflow: visible; margin-right:5.5556vw;
                    &.slick-active{
                        box-shadow: 2.2222vw 2.2222vw 4.1667vw rgba(0,0,0,0.2);
                    }
                    .rolling-txt{
                        text-align: center;
                        .item-chip{
                            display: inline-block; min-width: 21.25vw; padding: 1.6667vw 6.1111vw; @include chipTxt; font-weight: 700; color: $white;text-align: center; background-color: #c6387f; border-radius: 3.8889vw;
                        }
                        p{
                            margin-top: 4.1667vw; font-size: 5.2778vw; color: $white; line-height: 1.3; font-weight: 600; letter-spacing: -0.0972vw; text-align: center;
                        }
                    }
                    .rolling-icon{
                        position: absolute; bottom: 0; z-index: 1;
                    }
                    &.item-02 {
                        background-color: #575757;
                        .item-chip{background-color: #4c4c4c;}
                        .rolling-icon{bottom: -7.9167vw;}
                    }
                    &.item-03 {
                        background-color: #1240aa;
                        .item-chip{background-color: #103892;}
                        .rolling-icon{bottom: -7.9167vw;}
                    }
                    &.item-04 {
                        background-color: #7a3ece;
                        .item-chip{background-color: #874fd5;}
                        .rolling-icon{bottom: -7.9167vw;}
                    }
                    &.item-05 {
                        background-color: #fc5d4a;
                        .item-chip{background-color: #db5140;}
                    }
                }
            }
        }
        .qr-area{
            background-color: $purple; padding: 11.1111vw 0;
            .unit-origin{
                padding: 0 5.5556vw;
            }
            .txt-wrap{
                strong{
                    @include defaultTxt; color: $white;
                }
                p{@include defaultTxt; color: $white; margin-top: 8.3333vw;}
                .brand-main-txt{margin-top: 2.7778vw; color: $white;}
            }
            .flex-box{display: flex; justify-content: space-between; margin-top: 4.1667vw;}
            .app-download{
                display: flex;flex-direction: column;  gap:2.7778vw;
                a{
                    display: block; width: 47.2222vw; height: 11.1111vw; display: flex; justify-content: center; align-items: center; background-color: $white; border-radius: 5.5556vw;
                    img{display: block; width: 24.4444vw;}
                }
            }
            .qr-wrap{
                width: 25vw; height: 25vw;
                img{
                    display: block;
                }
            }
        }
        .txt-wrap{
            opacity: 0; transform: translateY(20%); transition: opacity 0.7s,transform 0.5s;
            &.on{
                opacity: 1; transform: translateY(0);
            }
        }
    }
    .widget-number{
        li {
            & + li {margin-top:4.1667vw}    
            .btn-radio-type2 {display:flex; align-items: center;}
            label {font-size:4.1667vw;}
            .txt-represent {display:inline-block;height:5vw;line-height:5.5vw; margin-left:1.3889vw; padding:0 1.6667vw;color:#fff;font-size:3.4722vw;background: #ec008c;border-radius:0.6944vw;}
        }
    }
    .layer-popup {
        .widget-pop {
            .btn-box {margin-top:3.0556vw;}
        }
    }

    .mem-info{
        .cardList-wrap {
             dl .user-name {text-align: right;}
             dl .user-name .txt-user {display: inline-block; width:56%; text-align: left; color: #191919; font-weight:600; vertical-align: top;}
             dl .user-name .btns-line {display: inline-block; min-width: 17.6389vw; height: 5.5556vw; line-height:5.5556vw ; padding: 0 2.2222vw; border-radius: 1.3889vw; border: solid 0.1389vw #707070;  background-color: #fff;  font-size: 3.8889vw; font-weight: 600; letter-spacing: -0.0778vw; color: #191919;}
             dl dd.user-mdify {
                 width:100%;
                 .box-input {display:inline-block; width:75%; vertical-align: top;}
                 .box-btn {display:inline-block;  width:23%; text-align: right; vertical-align: top}
                 .box-btn button {width:100%;}
                 .box-input .input-default {padding: 3.9001vw 2.1667vw;}
                 .box-input .input-default.is-delete {padding-right:10.4733vw ;}
             }
        }
    }
    .mem-info {
        .ico-list{
            display: flex; justify-content: space-evenly;  align-items: center;  margin-bottom:8.3333vw;
            li{
                width:16.6667vw;height:16.6667vw;
                img {display:inline-block; width: 100%;}
            }
        }
        .contents-wrap {
            .content + .content {margin-top:5.5556vw;}
        }
    }

    .receive-consent {
        .agremt {
            display:block; margin-bottom:1.3889vw; font-size: 4.1667vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.2033vw;text-align: left;color: #191919;
            span {display:inline-block;margin-left:1.3889vw; color:#662d91;font-size:4.1667vw;font-weight: 600;}
        }
        a {margin-top:4.1667vw;}
    }
    .receive-agree {
        .txt-dt { font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
        .agree-chk {
            margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;
            li {
                & + li {margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;}
            }
        }
        .txt {
            position: relative;
            a {position:absolute;right:0;top:50%;transform: translateY(-50%);}
        }
        .btn-radio-type2 {
            display: flex; margin-top:2.7778vw;
            .radio-bx {
                & +.radio-bx {margin-left:5.5556vw;}
            }
        }
    }

}

.error-wrap{
    display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;
    .error-conts{
        width: 88.8889vw; padding-top: 23.0556vw; @include backgroundImg('/assets/images/subpage/img-error.png', 50% 0, 26.25vw 17.5vw); text-align: center;
        h1{
            margin-bottom: 5.5556vw; color: $purple; font-size: 6.3889vw; font-weight: 700; letter-spacing: -0.1278vw;
        }
        h2{
            margin-bottom: 8.3333vw; font-size: 3.6111vw; font-weight: 600;;
        }
        > .box-btn{
            margin-bottom: 13.8889vw;
        }
    }
}

// 차세대 유큐브 시스템 점검 안내 페이지
.out-service-wrap{ 
    display: flex; justify-content: center; width: 100%; background-color: #f4f6ff; padding: 80px 0;
    .out-service-conts{
        width: 900px;
        .img-area{
            img{
                display: block; margin: 0 auto;
                &.img-out-service{width: 200px; margin-bottom: 20px;}
                &.img-logo{width: 220px;}
            }
        }
        h1{
            width: 100%; text-align: center; font-size: 36px; color: #662d91; line-height: 43px; margin-bottom: 30px; letter-spacing: -0.88px;
            span{font-size: 36px; color: #662d91; font-weight: 700;}
        }
        .date-area{
            width: 544px; line-height: 66px; border-radius: 100px; background-color: #191919; text-align: center; margin: 0 auto 40px; color: #fff; font-size: 22px; letter-spacing: -0.44px;
            span{color: #fff; font-size: 22px;}
        }
        .txt-area{
            width: 100%; text-align: center; margin: 40px 0; padding-top:40px; border-top:1px solid #ddd;
            .txt-bld {font-weight:600; font-size:20px;}
            p.txt{
                margin-top:20px; font-size: 18px; line-height: 1.56; letter-spacing: -0.36px; text-align: center; color: #191919;                
            }
            &.bottom{
                p{
                    font-size: 18px; font-weight: normal;color:#191919;
                &:last-child {margin-top:20px;}
                }                
            }
        }
        .notice-area{
            width: 100%; background-color: #fff; border-radius: 20px; padding: 50px; margin-bottom: 40px;
            dl{
                margin-bottom: 20px;
                dt{color: #191919; letter-spacing: -0.3px; font-size: 18px; font-weight: 600; margin-bottom: 10px;}
                dd{
                    color: #191919; font-weight: normal; letter-spacing: -0.3px; line-height: 22px;
                    ul{
                        counter-reset: number 0;
                        li{
                            color: #191919; font-weight: normal; letter-spacing: -0.3px; line-height: 22px; padding-left: 20px; position: relative;
                            &::before{counter-increment: number 1; content: counter(number)')'; display: block; width: 20px; position: absolute; left: 0; top: 0;}
                        }
                        &.etc{
                            counter-reset: circle 0;
                            li{
                                &::before{counter-increment: circle 1; content: counter(circle); width: 12px; height: 12px; border: 1px solid #191919; border-radius: 50%; line-height: 12px; font-size: 10px; top: 3px; text-align: center;}
                            }
                        }
                    }
                }
            }
            p{color: #191919; font-weight: normal; letter-spacing: -0.3px; font-size: 16px; line-height: 24px;}
            .call-area{
                text-align: center;
                p{ font-size: 20px; font-weight: 600; line-height: 1.4; letter-spacing: -0.4px;text-align: center;color: #191919; margin-bottom: 10px;}
                .sm-txt {font-size: 16px; line-height: 1.38; letter-spacing: -0.32px; color: #191919; font-weight:normal}
                .btns {width:260px; height:58px; margin-top:10px; line-height:1.2; font-size:20px;}
            }
        }
        .pc{display: block;}
        .mo{display: none;}
    }
}

.setting-inner {
    .tp-tit {
        margin-top:11.1111vw;
        dt {margin-bottom:1.3889vw;font-size: 4.4444vw;font-weight: 600; line-height: 1.25; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
        dd {font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.6px; text-align: left; color: #666;}
    }
    .guide-bx {
        margin-top:4.1667vw; 
        .line-box {padding: 5.5556vw; border-radius: 2.7778vw; border: solid 0.2778vw #ddd;background-color: #fff;}
        .guide-cnt {
            display:flex;
            img {width:11.1111vw;}
            dl {
                margin-left:3.3333vw;
                dt {margin-bottom:1.1111vw; font-size: 4.1667vw;font-weight: 500; line-height: 1.33; letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                dd {font-size: 4.1667vw; line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
            }           
        }
        .guide-list {
            margin-top:2.7778vw;
            li {
                padding-left:3.0556vw; text-indent: -3.0556vw; font-size: 4.1667vw; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #666;
                &+ li {margin-top:0.6944vw;}
                &::before {content:'※';margin-right:0.6944vw; font-size: 4.1667vw; line-height: 1.33; color: #666;}
            }
        }
    }
}

.search-top {
    visibility: hidden; position:fixed;right:2.7778vw;bottom:2.7778vw; width: 15vw;height:15vw; background:url('/assets/images/icon/ico-top.svg') no-repeat 50%;background-size: 15vw; z-index: -1;opacity: 0; transition: all 1s;
    &.on {visibility: visible; opacity: 1;z-index:125 ; transition: all 1.5s;}
} 

.terms {
    .age_view {
        .age_table {
            table {
                min-width: 208.3333vw;
                caption {display: none !important;overflow: hidden;padding: 0 !important;margin: 0 !important;width: 1px;height: 1px;line-height: 1px;font-size: 1px;border: 0;clip: rect(0 0 0 0);}
                th {padding: 2.7778vw 4.1111vw;background: #eff1f5;color: #191919;font-size: 4.1667vw;font-weight: 600;line-height: 1.3;letter-spacing: -.0833vw;vertical-align: middle;}
                td {
                    padding: 2.7778vw 4.1667vw;  border-bottom: .2778vw solid #ddd; color: #666; font-size: 4.1667vw; font-weight: 400; line-height: 1.3; letter-spacing: -.0833vw; vertical-align: middle;text-align:center;
                    p {line-height: 1.4;}
                }
            }
            &.table-scroll {overflow-x: auto;}
        }
        p {margin-top: 10px;color: #666;font-size: 16px;font-weight: 400;line-height: 1.5;letter-spacing: -.3px;}
    }
}

@media (max-width: 950px) {
    .out-service-wrap{
        .out-service-conts{
            width: 90%;
        }
    }
}

@media (max-width: 768px) {
    .out-service-wrap{
        display: flex; justify-content: center; width: 100%; background-color: #f4f6ff; padding: 11.1111vw 0;
        .out-service-conts{
            width: 100%; padding: 0 2.7778vw;
            .img-area{
                img{
                    display: block; margin: 0 auto;
                    &.img-out-service{width: 38.8889vw; margin-bottom: 5.5556vw;}
                    &.img-logo{width: 48.6111vw; height: 15.2778vw;}
                }
            }
            h1{
                width: 100%; text-align: center; font-size: 6.9444vw; color: #662d91; line-height: 5.9722vw; margin-bottom: 5.5556vw; letter-spacing: -0.1222vw;
                span{font-size: 6.9444vw; color: #662d91; font-weight: 700;}
            }
            .date-area{
                width: 100%; line-height: 10.2778vw; border-radius: 13.8889vw; background-color: #191919; text-align: center; margin: 0 auto 5.5556vw; color: #fff; font-size: 3.8889vw; letter-spacing: -0.0611vw;
                span{color: #fff; font-size: 3.8889vw;}
            }
            .txt-area{
                width: 100%; text-align: center; margin: 6.9444vw 0; padding-top:6.9444vw;
                .txt-bld { font-size: 4.4444vw; font-weight: 600; line-height: 1.31; letter-spacing: normal; text-align: center; color: #191919;}
                p.txt{
                    margin-top:4.1667vw;  font-size: 4.4444vw; font-weight: normal; line-height: 1.31; letter-spacing: normal; text-align: center;  color: #191919;  
                }
                & p:last-child {margin-top:2.7778vw; margin-top:4.1667vw;  font-size: 4.4444vw; font-weight: normal; line-height: 1.31; letter-spacing: normal; text-align: center;  color: #191919;   }
                &.bottom{
                    p{font-size: 4.1667vw;}
                }
            }
            .notice-area{
                width: 100%; background-color: #fff; border-radius: 2.7778vw; padding: 5.5556vw; margin-bottom: 5.5556vw;
                dl{
                    margin-bottom: 4.1667vw;
                    dt{color: #191919; letter-spacing: -.0889vw; font-size: 4.1667vw; font-weight: 600; margin-bottom: 1.3889vw;}
                    dd{
                        color: #191919; letter-spacing: -.0889vw; font-size: 3.8889vw; line-height: 1.25; word-break: keep-all;
                        ul{
                            counter-reset: number 0;
                            li{
                                color: #191919; letter-spacing: -.0889vw; font-size: 3.8889vw; padding-left: 4.1667vw; position: relative; word-break: keep-all; line-height: 1.25;
                                &::before{counter-increment: number 1; content: counter(number)')'; display: block; width: 4.1667vw; position: absolute; left: 0; top: 0;}
                            }
                            &.etc{
                                counter-reset: circle 0;
                                li{
                                    &::before{counter-increment: circle 1; content: counter(circle); width: 2.7778vw; height: 2.7778vw; border: 0.1389vw solid #191919; border-radius: 50%; line-height: 2.7778vw; font-size: 2.2222vw; top: 0.6944vw; text-align: center;}
                                }
                            }
                        }
                    }
                }
                p{color: #191919; letter-spacing: -.0889vw; font-size: 3.8889vw; line-height: 1.25; word-break: keep-all;}
                .call-area{
                    text-align: center; border-top: 0.1389vw solid #ddd; margin-top: 4.1667vw; padding-top: 4.1667vw;
                    p{color: #191919; letter-spacing: -.0889vw; font-size: 4.1667vw; margin-bottom: 2.7778vw; word-break: keep-all; line-height: 1.25;}
                    .sm-txt {margin-top:1.3889vw; font-size: 3.8889vw; font-weight: normal; line-height: 1.29; letter-spacing: normal; text-align: center; color: #191919;}
                    .btns {width:100%; height:13.8889vw;}
                }
            }
            .pc{display: none;}
            .mo{display: block;}
        }
    }
}
// 차세대 유큐브 시스템 점검 안내 페이지

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .util {
        // 검색
        .search{
            .inner{
                display: flex;flex-direction: column;
            }
            .search-bundle {
                & ~ .search-bundle{margin-top: 7.1429vw;}
            }
            .search-title{
                margin-bottom: 2.8571vw;
            }
            .result-not-found{
                display: flex;width: 100%;height: 50vh;justify-content: center;align-items: center;
                p{
                    text-align: center;
                }
            }
            .search-more{
                margin-top: 2.1429vw;
            }
            .box-tab {
                margin-top: 5.7143vw;
                .tab-btn-wrap{
                    li{
                        a{
                            &.on{background-color: $black;}
                        }
                    }
                }
                .tab-contents-wrap{
                    .rank-list {
                        display: flex; flex-direction: column; gap: 1.4286vw;
                        li{
                            display: flex; align-items: center; gap: 1.4286vw;
                            &.rank-top{
                                .rank-num{color: $purple;}
                            }
                            .rank{
                                &-num{
                                    display: inline-block; width: 2.8571vw; height: 2.8571vw;
                                }
                            }
                        }
                    }
                }
            }
            .search-menu{
                display: flex; flex-direction: column;
                div{
                    & ~ div{
                        margin-top: 2.8571vw;
                    }
                    strong{
                        display: block;
                    }
                    p{
                        font-weight: 400; margin-top: 0.7143vw;
                    }
                }
            }
            .cardList-wrap{
                display: block;
                & > div{
                    & + div{margin-top: 2.1429vw;}
                }
                &.card-desc{
                    .cardList{
                        padding: 4.2857vw 2.8571vw;
                    }
                }
                .cardList{
                    display: flex;
                    &:focus,&:active,&:hover {
                        border-color: $purple;
                    }
                    & ~ .cardList,& ~ div{
                        margin-top: 2.1429vw;
                    }
                    .payment-desc {
                        dt{margin-top: 0;}
                        .pay-amount {
                            display: block;
                            span{@include fontInherit;}
                        }
                    }
                    .item-right{
                        .item-txt {
                            display: flex;flex-direction: column;justify-content: space-between;
                        }
                    }
                }
            }
            .use-card-list{
                .card-list {
                    gap: 0;
                    div{
                        & ~ div{
                            margin-top: 2.1429vw;
                        }
                        a{
                            &:focus,&:active,&:hover {
                                border-color: $purple;
                            }
                        }
                    }
                    .card-desc{margin-top: 0;}
                }
            }
            .event-wrap{
                & > div{
                    & + div{margin-top: 5.7143vw;}
                }
                .cardList-wrap {
                    & ~ a, & ~ div {margin-top: 5.7143vw;}
                }
                .cardList-img{
                    img{
                        width: 100%; height:100%; object-fit: cover;
                    }
                }
            }
            .downLoad-list{
                & > div{
                    & + div{margin-top: 2.1429vw;}
                    & > div:not(:first-of-type){margin-top: 2.1429vw;}
                }
                .downLoad-tit{font-weight: 600;}
            }
            .list{
                margin: 0 !important;
            }
            .search-banner{
                a{
                    display: block;
                    img{display: block; width: 100%;}
                }
            }
        }
        .corporate-no-result{
            .popup-body{
                margin-bottom: 0 !important;
            }
            .desc{
                a{
                    color:#0054ff; text-decoration: underline;
                }
            }
            .txt-sub{
                margin-top: 2.8571vw;
            }
            .shop-num{
                display: block; margin-top: 0.7143vw; padding-left: 2.5000vw !important;
                @include backgroundImg('/assets/images/icon/ico-shop-phone.svg',left center,2.1429vw 2.1429vw);
            }
        }
        .box-radio{
            .radio-title{
                display: block; font-weight: 600; margin-bottom: 1.1429vw;
            }
        }
        //회원가입
        .mem-type {
            .type-list {
                margin-bottom:6.4286vw;
                & > li {
                    &:nth-of-type(n+2) {margin-top: 2.1429vw;}
                    .text-btn-gray{
                        @include backgroundImg('/assets/images/icon/ico-14less.svg',2.8571vw center, 5.7143vw 5.7143vw);
                        padding: 4.0000vw 2.8571vw 4.0000vw 11.4286vw;
                    }
                    &:nth-of-type(2) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-14over.svg');}
                    }
                    &:nth-of-type(3) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-personal.svg');}
                    }
                    &:nth-of-type(4) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-corp.svg');}
                    }
                }
            }
            &.type2{
                .type-list > li{
                    &:nth-of-type(1) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-14over.svg');}
                    }
                    &:nth-of-type(2) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-personal.svg');}
                    }
                    &:nth-of-type(3) {
                        .text-btn-gray{background-image: url('/assets/images/icon/ico-mem-corp.svg');}
                    }
                }
            }
        }
        // 회원정보수정
        .mem-info{
            .cardList-wrap{
                padding: 0;
                dl {
                    gap: 1.4286vw 0;
                    dt{width: 40%; padding-bottom: 0;}
                    dd{width: 60%; padding-bottom: 0;}
                }
                .sns-wrap{
                    .accordian{
                        .acc-trigger{padding-bottom: 0;}
                        .acc-conts{padding-top: 2.1429vw;}
                        .sns-button{
                            display: flex; gap: 1.4286vw;
                            .icon-sns {
                                display: inline-block; width: 5.7143vw; height: 5.7143vw;
                                &.naver{@include backgroundImg('/assets/images/icon/ico-sns-naver.png', center top,contain);}
                                &.google{@include backgroundImg('/assets/images/icon/ico-sns-google.png', center top,contain);}
                                &.apple{@include backgroundImg('/assets/images/icon/ico-sns-apple.png', center top,contain);}
                            }
                        }
                    }
                    .txt-vt{
                        display: block;
                    }
                    span{
                        display: block;margin-top: 0.7143vw;
                    }
                    .box-btn{
                        margin-top: 2.1429vw;
                    }
                }
            }
        }
        .join{
            .box-input{
                & + .box-input{margin-top: 2.8571vw;}
                .btn-check{
                    justify-content: space-between; margin-top: 1.0714vw;
                }
                .btn-check-type2{
                    & + .btn-check{
                        padding-left: 2.1429vw;margin-top: 2.8571vw;
                    }
                }
                .gray-box{
                    background-color: $gray01; border-radius: 1.4286vw; padding: 2.8571vw; margin-top: 1.4286vw;
                    .check-top {
                        margin-top: 0; padding-bottom: 2.8571vw; border-bottom: 0.1429vw solid $gray02; margin-bottom: 2.8571vw;
                    }
                    .btn-check {
                        label{width: auto;}
                    }
                    p{
                        margin-bottom: 2.1429vw;
                    }
                }
            }
            .btn-column{
                display: flex; flex-direction: column; gap: 4.2857vw; margin-top: 5.7143vw;
                .btn-underline{
                    display: block; text-align: center; text-decoration: underline;
                }
            }
            .phone-complete{
                & + .cardList-wrap{
                    margin-top: 4.2857vw;
                }
            }
            .tel-radio-wrap{margin-bottom: 5.7143vw;}
            .tel-group{
                flex-wrap: wrap;
                .btn-radio {
                    width: calc(50% - 1.4286vw);
                    .ico {
                        &::before{
                            content: '';
                            display: inline-block; margin-right: 0.7143vw; width: 2.8571vw; height: 2.8571vw;
                            @include backgroundImg('/assets/images/icon/logo-skt.png', center, contain);
                        }
                    }
                    label{
                        display: flex; align-items: center; justify-content: center;line-height: initial;
                        &.ico-skt {
                            &::before{
                                content: '';
                                display: inline-block; margin-right: 0.7143vw; width: 2.8571vw; height: 2.8571vw;
                                @include backgroundImg('/assets/images/icon/logo-skt.png', center, contain);
                            }
                        }
                        &.ico-kt {
                            &::before{
                                content: '';
                                display: inline-block; margin-right: 0.7143vw; width: 2.8571vw; height: 2.8571vw;
                                @include backgroundImg('/assets/images/icon/logo-kt.png', center, contain);
                            }
                        }
                        &.ico-lg {
                            &::before{
                                content: '';
                                display: inline-block; margin-right: 0.7143vw; width: 2.8571vw; height: 2.8571vw;
                                @include backgroundImg('/assets/images/icon/logo-lg.png', center, contain);
                            }
                        }
                    }
                }
            }
            .save-phone-radio-wrap{
                display: none; margin-top: 3.5714vw;
            }
        }
        // 동의철회, 회원탈퇴
        .mem-withdraw{
            .page-tit{
                h1{
                    span{font-weight: inherit; font-size: inherit; line-height: inherit; letter-spacing: inherit; color: $gray05;}
                }
            }
            .cardList-wrap{
                .cardList{
                    &.is-scroll{
                        max-height: 30.7143vw; overflow-y: scroll;
                    }
                }
                & + .cardList-wrap{
                    margin-top: 4.2857vw;
                }
                & + .btn-check{
                    margin-top: 5.7143vw;
                }
            }
            .withdraw-text-wrap{
                word-break: keep-all;
                & + .btn-check{
                    margin-top: 17.8571vw;
                }
            }
        }

        .agree-list {
            .agree-wrap {
                padding-bottom:2.8571vw;
                .btn-check-type2 {margin-bottom:2.8571vw;}
                .agree-group {
                    padding:0 2.1429vw;

                    .btn-check {width:100%;}
                    .btn-check+.btn-check {margin-top:2.2857vw;}
                }
            }
        }
        .identification {
            .cardList-wrap {
                margin-bottom:5.7143vw;padding:0;
                .cardList dl dd {text-align:left;}
            }
        }

        // 법인사업자
        .corporate{
            .box-input{
                & + .box-input{margin-top:2.1429vw;}
            }
            .check-wrap{
                display: flex;justify-content: space-between;align-items: center;margin: 1.4286vw 0 2.1429vw;
                .bang-notice{height: 2.8571vw;margin-top: 0;}
                .btn-check{
                    label{width: auto;}
                }
            }
        }
        // 아이디찾기
        .find-id {
            .cardList-wrap {
                padding:0;
                .text-btn-gray {
                    height:11.4286vw;padding-left:11.4286vw;border:0.0714vw solid #ddd;background:#fff;font-size:2.2857vw;line-height:4.2857vw;
                    &+.text-btn-gray {margin-top:2.1429vw;}
                    @include backgroundImg('/assets/images/icon/ico-find-id.svg', 2.8571vw center,5.7143vw 5.7143vw);
                    &:nth-of-type(2){background-image: url('/assets/images/icon/ico-find-pw.svg');}
                }
            }
            .btn-group {
                padding-bottom:5.7143vw;font-size:0;text-align:center;
                .btn-other-log {display:inline-block;margin:0 auto; text-decoration: underline;}
            }
        }
        .find-finish {
            .cardList-wrap {
                padding:0;
                .cardList {margin-bottom:2.8571vw;border-radius:1.4286vw;}
                .id-txt{color: $purple;}
            }
        }
        // 로그인
        .login{
            .page-tit{
                & + .box-input {
                    & + .box-input {
                        margin-top: 1.1429vw;
                    }
                }
            }
            .login-other{
                text-align: center; margin-top: 5.7143vw;
                button {
                    text-decoration: underline;
                }
            }
            .box-radio {
                & + .box-radio {margin-top: 2.8571vw;}
                & + .box-input {margin-top: 2.8571vw;}
            }
            .box-input {
                & + .box-input{margin-top: 2.8571vw;}
                & + .box-flex{margin-top: 2.8571vw;}
            }
            .tooltip-wrap{
                margin: 1.4286vw 0 2.8571vw;
                .btn-check{
                    label{padding-left: 0;}
                }
            }
            .box-btn{
                .text-btn-gray{
                    margin-top: 5.7143vw;
                    & + .text-btn-gray{margin-top: 2.1429vw;}
                }
            }
            .box-sns{
                padding-bottom: 9.2857vw;margin-top: 5.7143vw; text-align: center;
                .icon-list {
                    display: flex;justify-content: center;align-items: center; gap: 1.7143vw;
                    li{
                        & > a{display: block; width: 7.1429vw; height: 7.1429vw;}
                    }
                    .icon {
                        &-sns-naver{@include backgroundImg('/assets/images/icon/ico-sns-naver.png', center,contain);}
                        &-sns-google{@include backgroundImg('/assets/images/icon/ico-sns-google.png', center,contain);}
                        &-sns-apple{@include backgroundImg('/assets/images/icon/ico-sns-apple.png', center,contain);}
                    }
                }
                .txt-desc{
                    display: block; margin-bottom: 2.1429vw;
                    font-weight: 400;
                }
            }
            .btn-check-type2 {
                & + .btn-check{margin-top: 2.8571vw;}
            }
            .btn-check{
                label{
                    width: auto;
                }
            }
            .box-flex.column{margin-top: 2.8571vw;}

            // 간편 로그인
            &.simple{
                padding-top: 0;
                .phone-num-box{
                    display: flex; justify-content: center; align-items: center; background-color: $gray01; height: 7.7143vw; margin-bottom: 5.7143vw;
                }
                .page-tit{
                    p{margin-top: 0.7143vw;}
                }
                .birth-input{
                    display: flex; justify-content: center; margin-bottom: 5.7143vw;
                    input{
                        display: block;position: absolute;overflow: hidden;margin: -0.0714vw;padding: 0;width: 0.0714vw;height: 0.0714vw;line-height: 0.0714vw;font-size: 0.0714vw;border: 0;clip: rect(0 0 0 0);
                    }
                    .birth-dots{
                        display: flex; gap: 2.8571vw;
                        span{
                            display: inline-block; width: 2.2857vw; height: 2.2857vw; background-color: $gray02; border-radius: 50%;
                            &.on{
                                background-color: $purple;
                            }
                        }
                    }
                }
                .box-noti {
                    padding: 2.1429vw; background-color: $bg; border-radius: 1.4286vw; margin-bottom: 5.7143vw;
                    & + .box-btn{
                        position: absolute; bottom: 7.1429vw; left: 50%; transform: translateX(-50%);
                        button{text-decoration: underline;}
                    }
                    .no-dots{
                        .box-btn{margin-top: 2.2857vw;}
                    }
                }
                .cardList-wrap{
                    .cardList{
                        strong{display: block; margin-bottom: 0.7143vw;}
                        p{margin-bottom: 2.8571vw;}
                    }
                }
            }
        }
        //[바텀시트] 로그인방법선택
        .login-other-list {
            .popup-body{
                ul {
                    li {
                        &:last-of-type{
                            a{
                                border: 0; padding-bottom: 0; margin-bottom: 0;
                            }
                        }
                        a{
                            display: flex; align-items: center; gap: 1.7143vw; padding-bottom: 2.1429vw; margin-bottom: 2.1429vw;border-bottom: 0.1429vw solid $gray02;
                            .box-ico{
                                width: 5.7143vw; height: 5.7143vw;
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        // 법인회선
        .corp-line{
            .table-type-04{
                .btn-check{
                    input[type=checkbox] {
                        & + label{
                            span:before,span:after {
                                left: 50%; transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
                .ico-delete{vertical-align: middle;}
                tr{
                    th,td{
                        padding: 2.8571vw 0.7143vw;
                        &:first-of-type{padding-left: 2.1429vw;}
                        &:last-of-type{padding-right: 2.1429vw;}
                    }
                    td:nth-of-type(2){
                        font-weight: 400;
                    }
                    .chip-wrap{
                        display: inline-block; margin-right: 0.7143vw;
                        img{height: 2.5714vw;vertical-align: middle;}
                    }
                }
            }
            .no-search-result{
                background-image: url('/assets/images/icon/ico-corp.svg');
            }
            .detail-sec{
                margin-top: 5.7143vw;
            }
            .box-radio{
                margin-top: 2.8571vw;
            }
        }
        //sns 유알모
        .introduce-sns{
            .back-thumb-card{
                height: auto;
                a{
                    padding:0;
                    .back-bg{
                        position: relative;
                        top:0; left:0;
                        .back-conts{
                            position:absolute; top:0; left:0;
                            .back-header{
                                position:absolute; bottom:0;left:50%; transform: translateX(-50%);  width:100%; padding:2.8571vw;
                                h3{
                                    margin-bottom:0; text-align: center; width:100%; display: block;
                                }
                            }
                        }
                    }
                    &:before{
                        display: none;
                    }
                }
                &.txt-only{
                    .back-bg{
                        width:100%;
                        .back-conts{
                            position: static;
                            .back-header{
                               bottom:auto; background: none; position: static; left:0; transform: none;
                               h3{
                                color:$black !important;
                               }
                            }
                        }
                    }
                }
                .back-header{
                   background-color:rgba(0,0,0,0.5);
                }
            }
            .box-btn{
                margin-top: 1.4286vw;
            }
        }
        //영상
        .introduce-video{
            .movie-damage-prevention{
                margin-top: 5.7143vw;
                .thumb-list{
                    & > li{
                        .movie{
                            height: 25.6429vw;
                            a{
                                height: 25.6429vw;
                            }
                            img{
                                object-fit: cover; height: 25.6429vw; display: block; width: 100%;
                            }
                        }
                    }
                }
            }
        }
        // 브랜드
        &.pb0{padding-bottom: 0;overflow: hidden;}
        .introduce-ualmo{
            .brand-banner{
                display: flex; justify-content: center; align-items: center; height:74.7857vw;
                @include backgroundImg('/assets/images/subpage/introduce-ualmo/ualmo-visual.jpg',center, cover);
            }
            .brand-sub-txt{
                font-weight: 400; margin-top:2.1429vw;
            }
            .img-wrap{
                img{display: block;}
            }
            .main-visual{
                .txt-wrap{
                    *{text-align: center;}
                    p{
                        color: $white;
                    }
                    .brand-main-txt{
                        margin-top:2.1429vw; color: $white;
                    }
                }
            }
            .app-capture{
                display: flex; flex-direction: column; justify-content: space-between; margin-top: 7.1429vw; gap: 5.0000vw;
                .img-wrap{
                    width: 100%;
                }
            }
            .easy-ualmo{
                background-image: url('/assets/images/subpage/introduce-ualmo/easy-ualmo.jpg'); height: 73.4286vw;align-items: flex-start; padding-top: 7.1429vw;
                .txt-wrap {
                    *{
                        color: $white; text-align: center;
                    }
                }
            }
            .rolling-area{
                display: flex; flex-direction: column; position: relative;  padding: 7.1429vw 0;
                .txt-wrap{
                    padding: 0 2.8571vw; margin-bottom: 7.1429vw;
                }
                .rolling-banner{
                    overflow: visible; padding-left: 7.1429vw;
                    .slick-list,.slick-track{
                        overflow: visible;
                    }
                    .rolling-item{
                        display: flex;flex-direction: column;align-items: center; position: relative; width: 37.1429vw; height: 39.2857vw; border-radius: 6.8571vw; padding:4.2857vw 0 0;background-color: #e748aa;overflow: visible; margin-right:2.8571vw;
                        &.slick-active{
                            box-shadow: 1.1429vw 1.1429vw 2.1429vw rgba(0,0,0,0.2);
                        }
                        .rolling-txt{
                            text-align: center;
                            .item-chip{
                                display: inline-block; min-width: 10.9286vw; padding: 0.8571vw 3.1429vw; font-weight: 700; color: $white;text-align: center; background-color: #c6387f; border-radius: 2.0000vw;
                            }
                            p{
                                margin-top: 2.1429vw; font-size: 2.7143vw; color: $white; line-height: 1.3; font-weight: 600; letter-spacing: -0.0714vw; text-align: center;
                            }
                        }
                        .rolling-icon{
                            position: absolute; bottom: 0; z-index: 1;
                        }
                        &.item-02 {
                            background-color: #575757;
                            .item-chip{background-color: #4c4c4c;}
                            .rolling-icon{bottom: -4.0714vw;}
                        }
                        &.item-03 {
                            background-color: #1240aa;
                            .item-chip{background-color: #103892;}
                            .rolling-icon{bottom: -4.0714vw;}
                        }
                        &.item-04 {
                            background-color: #7a3ece;
                            .item-chip{background-color: #874fd5;}
                            .rolling-icon{bottom: -4.0714vw;}
                        }
                        &.item-05 {
                            background-color: #fc5d4a;
                            .item-chip{background-color: #db5140;}
                        }
                    }
                }
            }
            .qr-area{
                background-color: $purple; padding: 5.7143vw 0;
                .unit-origin{
                    padding: 0 2.8571vw;
                }
                .txt-wrap{
                    strong{
                        color: $white;
                    }
                    p{color: $white; margin-top: 4.2857vw;}
                    .brand-main-txt{margin-top: 1.4286vw; color: $white;}
                }
                .flex-box{display: flex; justify-content: space-between; margin-top: 2.1429vw;}
                .app-download{
                    display: flex;flex-direction: column;  gap:1.4286vw;
                    a{
                        display: block; width: 24.2857vw; height: 5.7143vw; display: flex; justify-content: center; align-items: center; background-color: $white; border-radius: 2.8571vw;
                        img{display: block; width: 12.5714vw;}
                    }
                }
                .qr-wrap{
                    width: 12.8571vw; height: 12.8571vw;
                    img{
                        display: block;
                    }
                }
            }
            .txt-wrap{
                opacity: 0; transform: translateY(20%); transition: opacity 0.7s,transform 0.5s;
                &.on{
                    opacity: 1; transform: translateY(0);
                }
            }
        }
    }

    .error-wrap{
        display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;
        .error-conts{
            width: 45.7143vw; padding-top: 11.8571vw; @include backgroundImg('/assets/images/subpage/img-error.png', 50% 0, 13.5000vw 9.0000vw); text-align: center;
            h1{
                margin-bottom: 2.8571vw; color: $purple; font-size: 3.2857vw; font-weight: 700; letter-spacing: -0.0714vw;
            }
            h2{
                margin-bottom: 4.2857vw; font-size: 1.8571vw; font-weight: 600;;
            }
            > .box-btn{
                margin-bottom: 7.1429vw;
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .util{
        .login{
            .page-tit{
                & + .box-input{
                    & + .box-input{
                        margin-top: 8px;
                    }
                }
            }
            .tooltip-wrap{
                margin: 10px 0 20px 0;
            }
            .box-btn{
                .text-btn-gray{
                    margin-top: 40px;
                    & + .text-btn-gray{
                        margin-top: 15px;
                    }
                } 
            }
            .box-sns{
                padding-bottom: 66px; margin-top: 40px;
                .txt-desc{font-weight: 400; margin-bottom: 15px;}
                .icon-list{
                    gap: 1.7143vw;
                    li{
                        & > a{width: 50px; height: 50px;}
                    }
                }
            }
            .box-flex{
                &.column{margin-top: 40px;}
            }
            &.simple{
                .phone-num-box{
                    height: 50px; margin-bottom: 30px;
                }
                .page-tit{
                    p{margin-top: 5px;}
                }
                .birth-input{
                    margin-bottom: 40px;
                    .birth-dots{
                        gap: 20px;
                        span{width: 15px; height: 15px;}
                    }
                }
                .box-noti{
                    padding: 20px; border-radius: 8px; margin-bottom: 40px;
                    & + .box-btn{bottom: 50px;}
                    .no-dots{
                        .box-btn{margin-top: 15px;}
                    }
                }
                .cardList-wrap{
                    .cardList{
                        p{margin-bottom: 20px;}
                    }
                }
            }
        }
        .find-id{
            .cardList-wrap{
                .text-btn-gray{
                    height: 80px; padding-left:82px; border-width: 1px; font-size: 16px; background-size: 40px 40px; background-position: 20px center;
                    & + .text-btn-gray{margin-top:15px;}
                }
            }
            .btn-group{padding-bottom:40px;}
        }
        .mem-type{
            .type-list{
                & > li{
                    .text-btn-gray{padding: 28px 20px 28px 82px; background-size: 40px 40px; background-position: 20px center; border-width: 1px;}
                    &:nth-of-type(n+2){margin-top: 15px;}
                }
            }
        }
        .agree-list{
            .agree-wrap{
                .btn-check-type2{margin-bottom: 20px;}
                .agree-group{padding:0 15px; margin-top: 20px;
                    .btn-check{margin-bottom: 15px;
                        & + .btn-check{margin-top: 15px;}
                    }
                }
            }
        }
        .join{
            .box-input{
                & + .box-input{margin-top: 20px;}
                .gray-box{
                    padding: 20px; border-radius: 8px; margin-top: 10px;
                    .check-top{padding-bottom: 20px; margin-bottom:20px;border-width:1px;}
                    p{margin-bottom: 15px;}
                }
                .btn-check{margin-top: 15px;}
            }
            .tel-radio-wrap{margin-bottom: 40px;}
            .tel-group{
                .btn-radio{
                    width: calc(50% - 10px);
                    label{
                        &.ico-skt, &.ico-kt, &.ico-lg{
                            &::before{width: 20px; height: 20px; margin-right: 5px;}
                        }
                    }
                }
            }
            .btn-column{margin-top: 40px; gap: 20px;}
            .phone-complete{
                & + .cardList-wrap{margin-top: 30px;}
            }
            .save-phone-radio-wrap{
                margin-top: 25px;
            }
        }
        .search{
            .box-tab{
                margin-top: 30px;
                .tab-contents-wrap{
                    .rank-list{
                        gap: 10px;
                        li{
                            gap: 10px;
                            .rank-num{width: 20px; height: 20px;}
                        }
                    }
                }
            }
            .result-not-found{
                height: 200px;
                p{margin: 40px 0;}
            }
            .search-bundle{
                & ~ .search-bundle{margin-top: 50px;}
            }
            .search-title{margin-bottom: 20px;}
            .search-menu{
                div{
                    & ~ div{margin-top: 20px;}
                }
            }
            .search-more{margin-top: 15px;}
            .cardList-wrap{
                &.card-desc{
                    .cardList{padding: 30px 20px;}
                }
                .cardList{
                    & ~ .cardList{margin-top: 15px;}
                    .item-right{
                        .item-txt{
                            .item-price{
                                .dc-prc{
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .event-wrap{
                .cardList-wrap{
                    & ~ a{margin-top: 50px;}
                }
            }
            .downLoad-list{
                & > div{
                    & + div{margin-top: 15px;}
                }
            }
        } // search
        .identification{
            .cardList-wrap{margin-bottom: 20px;}
        }
        .corporate{
            .box-input{
                & + .box-input{margin-top: 15px;}
            }
            .check-wrap{
                margin: 10px 0 20px;
                .bang-notice{height: 20px;}
            }
        }
        .corporate-no-result{
            .txt-sub{margin-top: 20px;}
        }
        .login-other-list{
            .popup-body{
                ul{
                    li{
                        a{
                            gap: 10px; padding-bottom: 15px; margin-bottom: 15px; border-width: 1px;
                            .box-ico{width: 35px; height: 35px;}
                        }
                    }
                }
            }
        }
        .box-radio{
            & + .box-radio{margin-top: 20px;}
            .radio-title{margin-bottom: 10px;}
        }
        .find-finish{
            .cardList-wrap{
                .cardList{margin-bottom: 20px; border-radius: 8px;}
            }
        }
        .mem-withdraw{
            .cardList-wrap{
                & + .cardList-wrap{margin-top: 30px;}
                & + .btn-check{margin-top: 40px;}
            }
            .withdraw-text-wrap{
                & + .btn-check{margin-top: 130px;}
            }
        }
        .corp-line{
            .table-type-04{
                tr{
                    th, td{padding: 20px 5px;}
                    td{
                        font-weight: 400;
                        &:nth-of-type(2){font-weight: 400;}
                        .btn-check{
                            justify-content: center;  margin-right: 10px;
                        }
                    }
                    .chip-wrap{
                        img{height: 20px;}
                    }
                }
            }
            .detail-sec{margin-top: 40px;}
            .box-radio{margin-top: 20px;}
        }
        .introduce-ualmo{
            .main-visual{
                .txt-wrap{
                    p{color: #fff;}
                    .brand-main-txt{margin-top: 15px;}
                }
            }
            .brand-banner{height: 500px;}
            .app-capture{margin-top: 50px; gap: 30px;}
            .brand-sub-txt{margin-top: 15px;}
            .easy-ualmo{padding-top: 100px;}
            .rolling-area{
                padding: 80px 0;
                .txt-wrap{padding: 0 20px; margin-bottom: 50px;}
                .rolling-banner{
                    .rolling-item{
                        width: 270px; height: 280px; border-radius: 50px; padding: 30px 0; margin-right: 20px;
                        .rolling-txt{
                            p{margin-top: 20px; font-size: 20px;}
                            .item-chip{min-width: 80px; border-radius: 20px; padding: 5px 20px;}
                        }
                    }
                }
            }
            .qr-area{
                padding: 40px 0;
                .txt-wrap{
                    strong{color:#fff;}
                    .brand-main-txt{margin-top: 5px;}
                    p{color: #fff; margin-top: 30px;}
                }
                .flex-box{margin-top: 15px;}
                .app-download{
                    gap: 10px;
                    a{
                        width: 175px; height: 40px; border-radius: 20px;
                        img{width: 90px;}
                    }
                }
                .qr-wrap{width: 90px; height: 90px;}
                .unit-origin{padding: 0 20px;}
            }
        }
        .introduce-video{
            .movie-damage-prevention{margin-top: 40px;}
        }
        .introduce-sns{
            .back-thumb-card{
                a{
                    .back-bg{
                        .back-conts{
                            .back-header{padding: 20px;}
                        }
                    }
                }
            }
        }
    } // util
    .error-wrap{
        align-items: normal; height: auto;
        .error-conts{
            width: 90%; padding-top: 200px; background-position: 50% 10%; margin-bottom: 10%; background-size: 189px;
            h1{
                margin-bottom: 20px; font-size: 30px;
            }
            h2{
                margin-bottom: 30px; font-size: 20px;
            }
            & > .box-btn{margin-bottom: 50px;}
        }
    }
}