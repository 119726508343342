@charset "UTF-8";
body{
    #gnb{
        .alram{
            display: none !important;
        }
    }
    &.app{
        #gnb{
            .alram{
                display: block !important;
            }
        }
    }
}

#header{
    position: fixed;top: 0;left: 0; width:100%;height:16.6667vw;box-sizing:border-box;border-bottom:0.2778vw solid #ddd;z-index:101;transition:0.2s;background-color:#fff;padding-top: var(--safe-top);

    > .inner{
       display: flex; justify-content: space-between; padding: 2.7778vw;font-size:0;overflow: hidden;
    }
    .header-top{
         display:flex;justify-content:space-between;position: relative;width: 100%;
        .sub-tit{
             .p-clor { font-size: 5.5556vw;font-weight: 600;color:#e60033}
        }
    }
    .logo{
        display:inline-block;width: 31.3889vw;height: 11.1111vw;box-sizing:border-box;font-size:0; @include backgroundImg('/assets/images/common/header-logo.png', 0, 100%);

        img{
            width: 100%;
        }
    }
    .btn-search {
        display: block;width:11.1111vw; height: 11.1111vw;margin-right:1.3889vw;
        img{
            width:100%;
        }
     }
    .sub-tit{
        display: flex; align-items: center;
        button{display: block; width: 11.1111vw; height: 11.1111vw; @include backgroundImg('/assets/images/common/header-btn-back.png', 50%, 2.9167vw 5.2778vw); font-size: 0;    flex-shrink: 0;}
        h2{
            display: block; @include contsTitle; position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 100%;padding: 0 13.8889vw;text-align: center; z-index: -1;
        }
    }

    .top-util{
        display:flex;justify-content:space-between;flex-direction:row;flex-wrap:nowrap;
    }
    .btn-menu{
        display:block; position: relative; width:11.1111vw;height:11.1111vw; background: none; font-size: 0; z-index: 10; flex-shrink: 0;
        span{
            position: absolute;
            left: 50%;
            width: 5.5556vw;
            height: 0.5556vw;
            border-radius: 0.2778vw;
            background-color: $black;
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%);
            transform-origin: 0% 50%;
            transition: all 0.3s;
            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                  top: 1.3889vw + ($i * 1.9444vw);
                }
            }
        }
        &.on{
            span {
                background-color: $black !important;
                &:nth-of-type(1) {
                    transform: rotate(45deg);
                }
                &:nth-of-type(2) {
                    transform: translateX(100%);
                    opacity: 0;
                    visibility: hidden;
                }
                &:nth-of-type(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &.on{
        background-color: transparent;
        .logo{
            background-image: url('/assets/images/common/header-logo-wh.png');
        }
        .btn-menu{
            span{
                background-color: $white;
            }
        }
    }
   &.tit-type {
        .inner {
            height:100%;
            .header-top {
                height:100%;
                .sub-tit {height:100%;}
            }
        }
    }
}

body{
    &.black{
        #header{
            &.on{
                .logo{
                    background-image: url('/assets/images/common/header-logo.png');
                }
                .btn-menu{
                    span{
                        background-color: $black;
                    }
                }
            }
        }
    }
    &.white{
    }
}

#gnb{
    /* display:none;  */background: $white; width:100%; height: 100%;  overflow-y: scroll; position: fixed; overflow-x: hidden; top:0; left:100%; opacity: 0; visibility: hidden;
    transition: left 0.3s, opacity 0.3s, visibility 0.3s;
    &.on{
        left: 0; opacity: 1; visibility: visible;
    }
    .inner{
        padding: 2.7778vw;
        .menu-header{
                padding-bottom:8.3333vw;  border-bottom:2.7778vw solid  $gray01;
            &-ico{
                display: flex; gap:1.3889vw; margin-bottom: 6.9444vw; position:fixed; top:0; left:0; padding:2.7778vw; background: $white; width:100%; z-index: 2;
                li{
                    a{
                        width:11.1111vw; height: 11.1111vw; display: block;
                        img{
                            width:100%;
                        }
                    }
                    .alram{
                        display:inline-block; position: relative; width:11.1111vw;height:11.1111vw;border:none; @include backgroundImg('/assets/images/common/header-ico-notice.png', 50%, 5.8333vw); font-size: 0;
                        &.on:after{
                            content: ''; position: absolute; right: 1.1111vw; top: 2.2222vw; width: 1.6667vw; height: 1.6667vw; background-color: #f60019; border-radius: 50%;
                        }
                        &.new:after{
                            content: ''; position: absolute; right: 2.1111vw; top: 1.6666vw; width: 3.6111vw; height: 3.6111vw; background: url('/assets/images/icon/ico-alram-new.png') no-repeat 50%; background-size: 3.6111vw;;
                        }
                    }
                    &.setting{position: absolute; right:10.5278vw;}
                }
            }
            &-info{
                background-color: $purple; color:$white; border-radius: 2.7778vw; padding:5.5556vw; margin:0 5.5556vw; margin-top:18.9444vw;
                &-txt{
                    & > a{
                        display: block; width:100%; position: relative; color:$white;
                        &::after{
                            content: ''; width: 5.5556vw; height:5.5556vw; position: absolute; bottom:0; right:0; @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, contain);
                        }
                    }
                    h2{
                        @include subContsTitle; color:$white;
                        span{
                            @include subContsTitle; color:$white;
                        }
                    }
                    .info-tit {
                        @include subContsTitle; color:$white;
                        span{
                            @include subContsTitle; color:$white;
                        }
                    }
                    .info-name{
                        @include smallTxt; color:$white; margin-bottom: 2.2222vw;
                        & > span{
                            @include defaultTxt; color:$white;
                        }
                        .member-modify {
                           position: relative; margin-left:4.1667vw; padding-right:6.9444vw; font-size:3.8889vw;color:#fff;
                           &::after {content:'';position:absolute;right:0;top:50%;width:5.5556vw;height:5.5556vw;background: url('/assets/images/icon/ico-arrow-circle.png') no-repeat 50%;background-size: 5.5556vw; transform: translateY(-50%); }
                        } 
                    }
                    &.logout{
                        a{
                            &::after{bottom:1.8333vw}
                            .info-name{
                                @include defaultTxt; color:$white; margin-bottom: 2.2222vw;
                                span{ @include fontInherit;}
                            }
                            .point-wrap{
                                display: flex; gap:1.3889vw; align-items: center;
                                .ico-point{
                                    @include backgroundImg('/assets/images/common/ico-info-point.svg', right, contain); width: 7.6389vw; height: 7.6389vw;
                                }
                            }
                            h2{
                                font-size: 7.7778vw;
                            }
                        }
                        .point-box{
                            a + a {margin-top:2.7778vw;}
                            a {
                                display:block;position:relative;padding-right:5.5556vw;
                                .point-wrap {
                                    display:flex;align-content: flex-start;
                                    .ico-point {
                                        width:5vw; height:5vw; background-size: 5vw;;
                                        &.u-cash {background: url('/assets/images/common/ico-info-point02.svg') no-repeat 0 50%;background-size:5vw;;}                                        
                                    }
                                    h2 {margin-left:auto;font-size: 5vw; font-weight: bold; line-height: 1.19;letter-spacing: -0.72px;text-align: right;color: #fff;}
                                    .txt {display:inline-block;position:relative; margin-left:1.3889vw;font-size: 3.8889vw;line-height: 1.21;letter-spacing: -0.0778vw;text-align: left; color: #fff;}
                                    &.new {
                                        .txt::after {content:'';position:absolute; right:-2.7778vw;top:-0.2778vw;width:1.6667vw; height:1.6667vw; border-radius: 20px;background-color: #ff3045;}
                                    }
                                }
                                &::after{content:"";position:absolute;right:0;top:0;width:5.5556vw;height:5.5556vw;background: url('/assets/images/common/ico-info-point-arrow.svg') no-repeat 0 50%;background-size:5.5556vw;}
                            }

                        }
                    }
                }
                &-btn{
                    display: flex; gap:9.7222vw; margin-top: 5.5556vw;opacity: 0.7;
                    li{
                        a{
                            @include smallDepthTxt; color:$white; position: relative;
                            &::after{
                                position:absolute; top:50%; right:-3.7778vw; transform: translateY(-50%); content: ''; width:2.7778vw; height:2.7778vw;
                                @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, 100%); display: inline-block;
                            }
                        }
                    }
                }
            }
            &.wide-box{
                margin-left: -2.5556vw;
            }
        }
        .menu-scroll{
           border-bottom:2.7778vw solid  $gray01; box-sizing: border-box;padding:8.3333vw 0; overflow-x: auto;box-sizing: border-box; position:sticky; top:15.6389vw; left:0; background: $white; z-index: 2;
           &::-webkit-scrollbar{
            display: none;
            }
            &.wide-box{
                margin-left: -2.5556vw;
            }
            .menu-scroll-list{
                display: flex; justify-content: flex-start; gap:2.7778vw; margin-left: 5.3889vw;
                li{
                    flex-grow: 1;
                    a{
                        display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 2.3611vw 5.5556vw; @include mainEmphasize; white-space: nowrap; background: $gray01; border-radius:8.3333vw;
                        &.on{
                            background: $black; color:$white;
                        }
                    }
                }
            }
        }
        .gnb-menu{
            padding:2.7778vw;
            & > li{
                margin-top: 11.1111vw;
                 & > a{
                    @include subContsTitle;  display: block; position: relative; padding-left: 8.0556vw; padding-bottom: 2.2222vw; line-height: 6.6667vw;
                    border-bottom:0.2778vw solid $gray02; margin-bottom: 4.1667vw;
                    &::before{
                        content:''; width:6.6667vw; height: 6.6667vw; position: absolute; top:0; left:0; @include backgroundImg('/assets/images/common/ico-gnb-usim.png',center center,100%);
                    }
                }
                &.usim{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-usim.png');
                    }
                }
                &.phone{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-phone.png');
                    }
                }
                &.products{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-products.png');
                    }
                }
                &.customer{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-customer.png');
                    }
                }
                &.my{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-my.png');
                    }
                }
                &.event{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-event.png');
                    }
                }
                &.util{
                    & > a::before{
                        background-image: url('/assets/images/common/ico-gnb-util.png');
                    }
                }
            }
        }
    }


    .gnb-depth1{
        display:block; width:100%; box-sizing:border-box;
        > li{
            > a{
               white-space:nowrap; display: block; @include mainTxt;padding:2.7778vw 0;
            }
            .acc-trigger{
                padding-top:2.7778vw;
            }
            .img-brandlogo {
                img {height:4.1667vw; display: inline-block; margin:0.2778vw 0.4167vw 0 0; vertical-align: top;}
            }
        }
    }
    .gnb-depth2{
        margin-bottom: 2.7778vw; display: block;
        > li{
            > a{
                white-space:nowrap; @include defaultTxt; font-weight: 400; padding:1.3889vw 0; display: block;
                .p-clor {color:#e60033;}
                &.on{
                    color:$purple;
                }
            }
        }
    }
    .acc-conts{
        background: $bg;
    }
    .banner-randombox-wrap{
        position: static; margin-top: 8.3333vw;
        a{
            display: block; width: 88.8889vw; height: auto; margin: 0 auto;
        }
    }
}


#snb {
    position:sticky; top:16.6667vw;z-index:99;overflow-x:auto; box-sizing:border-box; background:#fff;
    &::-webkit-scrollbar {
        display: none;
    }
    .snb-list{
        display:flex;justify-content:flex-start;
        li{
            flex-grow: 1;
            a{
                display:flex;justify-content:center;align-items:center; box-sizing:border-box; padding: 4.1667vw 8.3333vw; border-bottom: 0.5556vw solid $gray02; @include subContsTitle;text-align:center;white-space:nowrap;transition:0.2s;
            }
            &.on a{
                border-bottom: 0.5556vw solid $purple;color: $purple;
            }
            .p-clor {font-size: 5vw; color: #e60033;font-weight:600 ;}
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {

    body{
        #gnb{
            .alram{
                display: none !important;
            }
        }
        &.app{
            #gnb{
                .alram{
                    display: block !important;
                }
            }
        }
    }

    #header{
        position: fixed;top: 0;left: 0; width:100%;height:8.5714vw;box-sizing:border-box;border-bottom:0.1429vw solid #ddd;z-index:101;transition:0.2s;background-color:#fff;padding-top: var(--safe-top);

        > .inner{
        display: flex; justify-content: space-between; padding: 1.4286vw;font-size:0;overflow: hidden;
        }
        .header-top{
            display:flex;justify-content:space-between;position: relative;width: 100%;
        }
        .logo{
            display:inline-block;width: 16.1429vw;height: 5.7143vw;box-sizing:border-box;font-size:0; @include backgroundImg('/assets/images/common/header-logo.png', 0, 100%);

            img{
                width: 100%;
            }
        }
        .sub-tit{
            display: flex; align-items: center;
            button{display: block; width: 5.7143vw; height: 5.7143vw; @include backgroundImg('/assets/images/common/header-btn-back.png', 50%, 1.5000vw 2.7143vw); font-size: 0;    flex-shrink: 0;}
            h2{
                display: block;  position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 100%;padding: 0 7.1429vw;text-align: center; z-index: -1;
            }
        }

        .top-util{
            display:flex;justify-content:space-between;flex-direction:row;flex-wrap:nowrap;
        }
        .btn-menu{
            display:block; position: relative; width:5.7143vw;height:5.7143vw; background: none; font-size: 0; z-index: 10; flex-shrink: 0;
            span{
                position: absolute;
                left: 50%;
                width: 2.8571vw;
                height: 0.2857vw;
                border-radius: 0.1429vw;
                background-color: $black;
                opacity: 1;
                visibility: visible;
                transform: translateX(-50%);
                transform-origin: 0% 50%;
                transition: all 0.3s;
                @for $i from 1 through 3 {
                    &:nth-child(#{$i}) {
                    top: 0.7143vw + ($i * 1.0000vw);
                    }
                }
            }
            &.on{
                span {
                    background-color: $black !important;
                    &:nth-of-type(1) {
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        transform: translateX(100%);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:nth-of-type(3) {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        &.on{
            background-color: transparent;
            .logo{
                background-image: url('/assets/images/common/header-logo-wh.png');
            }
            .btn-menu{
                span{
                    background-color: $white;
                }
            }
        }
    }

    body{
        &.black{
            #header{
                &.on{
                    .logo{
                        background-image: url('/assets/images/common/header-logo.png');
                    }
                    .btn-menu{
                        span{
                            background-color: $black;
                        }
                    }
                }
            }
        }
        &.white{
        }
    }

    #gnb{
        /* display:none;  */background: $white; width:100%; height: 100%;  overflow-y: scroll; position: fixed; overflow-x: hidden; top:0; left:100%; opacity: 0; visibility: hidden;
        transition: left 0.3s, opacity 0.3s, visibility 0.3s;
        &.on{
            left: 0; opacity: 1; visibility: visible;
        }
        .inner{
            padding: 1.4286vw;
            .menu-header{
                    padding-bottom:4.2857vw;  border-bottom:1.4286vw solid  $gray01;
                &-ico{
                    display: flex; gap:0.7143vw; margin-bottom: 3.5714vw; position:fixed; top:0; left:0; padding:1.4286vw; background: $white; width:100%; z-index: 2;
                    li{
                        a{
                            width:5.7143vw; height: 5.7143vw; display: block;
                            img{
                                width:100%;
                            }
                        }
                        .alram{
                            display:inline-block; position: relative; width:5.7143vw;height:5.7143vw;border:none; @include backgroundImg('/assets/images/common/header-ico-notice.png', 50%, 3.0000vw); font-size: 0;
                            &.on:after{
                                content: ''; position: absolute; right: 0.5714vw; top: 1.1429vw; width: 0.8571vw; height: 0.8571vw; background-color: #f60019; border-radius: 50%;
                            }
                        }
                        &.setting{position: absolute; right:5.4286vw;}
                    }
                }
                &-info{
                    background-color: $purple; color:$white; border-radius: 1.4286vw; padding:2.8571vw; margin:0 2.8571vw; margin-top:9.7143vw;
                &-txt{
                        & > a{
                            display: block; width:100%; position: relative; color:$white !important;
                            &::after{
                                content: ''; width: 2.8571vw; height:2.8571vw; position: absolute; bottom:0; right:0; @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, contain);
                            }
                        }
                        h2{
                            color:$white;
                            span{
                                color:$white;
                            }
                        }
                        .info-name{
                            color:$white; margin-bottom: 1.1429vw;
                            & > span{
                                color:$white;
                            }
                        }
                    &.logout{
                            a{
                                &::after{bottom:0.9286vw}
                                .info-name{
                                    color:$white; margin-bottom: 1.1429vw;
                                    span{ @include fontInherit;}
                                }
                                .point-wrap{
                                    display: flex; gap:0.7143vw; align-items: center;
                                    .ico-point{
                                        @include backgroundImg('/assets/images/common/ico-info-point.svg', right, contain); width: 3.9286vw; height: 3.9286vw;
                                    }
                                }

                                h2{
                                    font-size: 4.0000vw;
                                }
                            }

                        }
                    }
                    &-btn{
                        display: flex; gap:5.0000vw; margin-top: 2.8571vw;
                        li{
                            a{
                                color:$white; position: relative;
                                &::after{
                                    position:absolute; top:50%; right:-1.9286vw; transform: translateY(-50%); content: ''; width:1.4286vw; height:1.4286vw;
                                    @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, 100%); display: inline-block;
                                }
                            }
                        }
                    }
                }
                &.wide-box{
                    margin-left: -1.2857vw;
                }
            }
            .menu-scroll{
            border-bottom:1.4286vw solid  $gray01; box-sizing: border-box;padding:4.2857vw 0; overflow-x: auto;box-sizing: border-box; position:sticky; top:8.0714vw; left:0; background: $white; z-index: 2;
            &::-webkit-scrollbar{
                display: none;
                }
                &.wide-box{
                    margin-left: -1.2857vw;
                }
                .menu-scroll-list{
                    display: flex; justify-content: flex-start; gap:1.4286vw; margin-left: 2.7857vw;
                    li{
                        flex-grow: 1;
                        a{
                            display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 1.2143vw 2.8571vw; white-space: nowrap; background: $gray01; border-radius:4.2857vw;
                            &.on{
                                background: $black; color:$white;
                            }
                        }
                    }
                }
            }
            .gnb-menu{
                padding:1.4286vw;
                & > li{
                    margin-top: 5.7143vw;
                    & > a{
                        display: block; position: relative; padding-left: 4.1429vw; padding-bottom: 1.1429vw; line-height: 3.4286vw;
                        border-bottom:0.1429vw solid $gray02; margin-bottom: 2.1429vw;
                        &::before{
                            content:''; width:3.4286vw; height: 3.4286vw; position: absolute; top:0; left:0; @include backgroundImg('/assets/images/common/ico-gnb-usim.png',center center,100%);
                        }
                    }
                    &.usim{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-usim.png');
                        }
                    }
                    &.phone{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-phone.png');
                        }
                    }
                    &.products{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-products.png');
                        }
                    }
                    &.customer{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-customer.png');
                        }
                    }
                    &.my{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-my.png');
                        }
                    }
                    &.event{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-event.png');
                        }
                    }
                    &.util{
                        & > a::before{
                            background-image: url('/assets/images/common/ico-gnb-util.png');
                        }
                    }
                }
            }
        }


        .gnb-depth1{
            display:block; width:100%; box-sizing:border-box;
            > li{
                > a{
                white-space:nowrap; display: block; padding:1.4286vw 0;
                }
                .acc-trigger{
                    padding-top:1.4286vw;
                }
            }
        }
        .gnb-depth2{
            margin-bottom: 1.4286vw; display: block;
            > li{
                > a{
                    white-space:nowrap;  font-weight: 400; padding:0.7143vw 0; display: block;
                    &.on{
                        color:$purple;
                    }
                }
            }
        }
        .acc-conts{
            background: $bg;
        }
    }


    #snb {
        position:sticky; top:8.5714vw;z-index:99;overflow-x:auto; box-sizing:border-box; background:#fff;
        &::-webkit-scrollbar {
            display: none;
        }
        .snb-list{
            display:flex;justify-content:flex-start;
            li{
                flex-grow: 1;
                a{
                    display:flex;justify-content:center;align-items:center; box-sizing:border-box; padding: 2.1429vw 4.2857vw; border-bottom: 0.2857vw solid $gray02; text-align:center;white-space:nowrap;transition:0.2s;
                  }
            &.on a{
                border-bottom: 0.2857vw solid $purple;color: $purple;
            }
        }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    #header{
        height: 60px; border-bottom-width: 1px;
        > .inner{
            padding: 10px;
        }
        .logo{
            width: 113px; height: 40px;
        }
        .btn-menu{
            width: 40px; height: 40px;
            span{
                width: 20px; height: 2px;
                &:nth-child(1){
                    top: 12px;
                }
                &:nth-child(2){
                    top: 19px;
                }
                &:nth-child(3){
                    top: 26px;
                }
            }
        }
        #gnb{
            .inner{
                padding: 10px;
                .menu-header{
                    padding-bottom: 30px; border-bottom-width: 10px;
                }
                .menu-header-ico{
                    gap: 5px; padding: 10px;
                    li{
                        a{
                            width: 40px; height: 40px;
                            &.alram{background-size: 25px;}
                            &:after{width: 12px; height: 12px; top: 5px; right: 5px;}
                        }
                        &.setting{right: 40px;}
                    }
                }
                .menu-header-info{
                    padding: 20px; margin: 0 20px; margin-top: 68px;
                }
                .menu-header-info-txt{
                    h2{font-size: 18px; letter-spacing: -1px; color: #fff;}
                    .info-name{
                        margin-bottom: 8px; font-size: 15px; letter-spacing: -1px; color: #fff;
                        > span{font-size: 15px; letter-spacing: -1px; margin-right: 5px; color: #fff;}
                        .member-modify{
                            margin-left: 29px; font-size: 15px; letter-spacing: -1px; padding-right: 25px;
                            &:after{width: 20px; height: 20px; background-size: 100%;}
                        }
                    }
                    .point-wrap{
                        h2{font-size: 28px; letter-spacing: -2px; color: #fff;}
                        .ico-point{
                            width: 28px; height: 28px;
                        }
                    }
                }
                .menu-header-info-btn{
                    gap: 35px; margin-top: 20px;
                    li{
                        a{
                            color: #fff;
                            &:after{
                                width: 10px; height: 10px; right: -14px;
                            }
                        }
                    }
                }
                .menu-scroll{
                    &.wide-box{
                        padding: 30px 0; top: 56px; border-bottom-width: 10px;
                    }
                    .menu-scroll-list{
                        li{
                            a{
                                padding: 9px 20px; border-radius: 30px; font-size: 16px; letter-spacing: -1px;
                            }
                        }
                    }
                }
                .gnb-menu{
                    & > li{
                        margin-top: 70px;
                        & > a{line-height: 48px; border-width: 1px;}
                        a, .acc-trigger{
                            font-size: 18px; letter-spacing: -1px;
                        }
                        .acc-trigger{
                            padding-top: 10px; margin-bottom: 10px;
                            &:after{width: 20px; height: 20px;}
                        }
                    }
                }
                .gnb-depth1{
                    li{
                        a{padding: 10px 0;}
                    }
                }
                .gnb-depth2{
                    &.acc-conts{padding: 16px 20px;
                        li{
                            a{font-size: 15px; letter-spacing: -1px; padding: 5px 0; font-weight: 400;}
                        }
                    }
                }
            }
        }
        .sub-tit{
            button{
                width: 40px; height: 40px; background-size: 10px 19px;
            }
            h2{
                font-size: 20px;
            }
        }
    }
    #snb{
        top: 60px;
        .snb-list{
            li{
                a{font-size: 18px; padding: 15px 30px; border-bottom-width:1px;}
                &.on a{border-bottom-width:1px;}
            }
        }
    }
}