.ico-share{
    width:8.8889vw; height:8.8889vw; border-radius:50%; border:0.2778vw solid #e6e6e6; font-size:0;
    @include backgroundImg('/assets/images/icon/ico-share.svg',center center, contain);
}
.ico-call{
    width:11.1111vw; height:11.1111vw; font-size:0;
    @include backgroundImg('/assets/images/icon/ico-call.svg',center center,11.1111vw 11.1111vw);
}
.ico-lte{
    width:11.1111vw; height:11.1111vw; font-size:0;
    @include backgroundImg('/assets/images/icon/icon_lte.svg',center center,11.1111vw 11.1111vw);
}
.ico-message{
    width:11.1111vw; height:11.1111vw; font-size:0;
    @include backgroundImg('/assets/images/icon/ico-message.svg',center center,11.1111vw 11.1111vw);
}
.ico-noData{
    width:11.1111vw; height:11.1111vw; font-size:0;
    @include backgroundImg('/assets/images/icon/ico-noData.svg',center center,11.1111vw 11.1111vw);
}
.ico-arrow-right{
    display: inline-block; font-size: 0;
    @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right, contain);
    width: 5.5556vw; height: 5.5556vw;
}
.ico-arrow-right-wh{
    display: inline-block; font-size: 0;
    @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, contain);
    width: 5.5556vw; height: 5.5556vw;
}
.ico-arrow-left{
    display: inline-block; font-size: 0;
    @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', left, contain);
    width: 5.5556vw; height: 5.5556vw; transform: rotate(-180deg);
}
.ico-delete{
    width: 5.5556vw;height: 5.5556vw;@include backgroundImg('/assets/images/icon/ico-delete.svg', center, contain);
}
.ico-call-bk{
    display: inline-block; font-size: 0;
    @include backgroundImg('/assets/images/icon/ico-call-bk.svg', center center, contain); width:5.5556vw; height: 5.5556vw;
}
.ico-pencil{
    display: inline-block; font-size: 0;
    @include backgroundImg('/assets/images/icon/ico-pencil.svg', center center, contain); width:5.5556vw; height: 5.5556vw; vertical-align: middle;
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .ico-share{
        width:4.5714vw; height:4.5714vw; border-radius:50%; border:0.1429vw solid #e6e6e6; font-size:0;
        @include backgroundImg('/assets/images/icon/ico-share.svg',center center, contain);
    }
    .ico-call{
        width:5.7143vw; height:5.7143vw; font-size:0;
        @include backgroundImg('/assets/images/icon/ico-call.svg',center center,5.7143vw 5.7143vw);
    }
    .ico-lte{
        width:5.7143vw; height:5.7143vw; font-size:0;
        @include backgroundImg('/assets/images/icon/icon_lte.svg',center center,5.7143vw 5.7143vw);
    }
    .ico-message{
        width:5.7143vw; height:5.7143vw; font-size:0;
        @include backgroundImg('/assets/images/icon/ico-message.svg',center center,5.7143vw 5.7143vw);
    }
    .ico-noData{
        width:5.7143vw; height:5.7143vw; font-size:0;
        @include backgroundImg('/assets/images/icon/ico-noData.svg',center center,5.7143vw 5.7143vw);
    }
    .ico-arrow-right{
        display: inline-block; font-size: 0;
        @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', right, contain);
        width: 2.8571vw; height: 2.8571vw;
    }
    .ico-arrow-right-wh{
        display: inline-block; font-size: 0;
        @include backgroundImg('/assets/images/icon/ico-arrow-right-wh.svg', right, contain);
        width: 2.8571vw; height: 2.8571vw;
    }
    .ico-arrow-left{
        display: inline-block; font-size: 0;
        @include backgroundImg('/assets/images/icon/ico-arrow-right.svg', left, contain);
        width: 2.8571vw; height: 2.8571vw; transform: rotate(-180deg);
    }
    .ico-delete{
        width: 2.8571vw;height: 2.8571vw;@include backgroundImg('/assets/images/icon/ico-delete.svg', center, contain);
    }
    .ico-call-bk{
        display: inline-block; font-size: 0;
        @include backgroundImg('/assets/images/icon/ico-call-bk.svg', center center, contain); width:2.8571vw; height: 2.8571vw;
    }
    .ico-pencil{
        display: inline-block; font-size: 0;
        @include backgroundImg('/assets/images/icon/ico-pencil.svg', center center, contain); width:2.8571vw; height: 2.8571vw; vertical-align: middle;
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .ico-arrow-right{width: 20px; height: 20px;}
    .ico-arrow-left{width: 20px; height: 20px;}
    .ico-lte, .ico-call, .ico-message{width: 40px; height: 40px; background-size: 40px 40px;}
    .ico-share{width: 35px; height: 35px; border-width: 1px;}
    .ico-delete{width: 20px; height: 20px;}
    .ico-call-bk{width: 20px; height: 20px;}
}