.acc-conts{display: none;}


.accordian {
     .acc-trigger {
        display:block;width:100%;position:relative;margin-bottom:2.7778vw;/* border-bottom:0.2778vw solid $gray02; */ @include mainTxt; text-align:left; font-weight: 600;
        &:after {content: "";position: absolute;right: 0;top:50%;width: 5.5556vw;height: 5.5556vw;background: url(/assets/images/icon/ico-arrow-bottom.svg) no-repeat 0 0;background-size: 100%;transform: translateY(-50%);}
        &.on{
            border-bottom: 0;
            &:after {transform: translateY(-50%) rotate(180deg);}
        }
    }
    &.type-01 {
        .acc-conts {padding:4.4444vw 5.5556vw;border-radius:2.7778vw;background:$gray01;}
    }
    &.type-02 {
        .acc-trigger{margin-bottom: 0; border-bottom:0; padding-bottom:0;}
        background:$gray01; border-radius: 1.9444vw; padding: 4.1667vw;
        .acc-conts {padding-top: 5.5556vw;}
    }

    .txt {font-size: 4.1667vw; font-weight: 500; line-height: 1.33; letter-spacing: -0.0833vw; text-align: left}
    .silent-box {
        margin-top:5.5556vw;
        .silent-tit {display:block;margin-bottom:2.7778vw;font-size: 4.1667vw; font-weight: 600;line-height: 1.33; letter-spacing: -0.0833vw; text-align: left; color: #191919;}
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
    .acc-conts{display: none;}


.accordian {
     .acc-trigger {
        display:block;width:100%;position:relative;margin-bottom:1.4286vw;/* border-bottom:0.1429vw solid $gray02; */ text-align:left; font-weight: 600;
        &:after {content: "";position: absolute;right: 0;top:50%;width: 2.8571vw;height: 2.8571vw;background: url(/assets/images/icon/ico-arrow-bottom.svg) no-repeat 0 0;background-size: 100%;transform: translateY(-50%);}
        &.on{
            border-bottom: 0;
            &:after {transform: translateY(-50%) rotate(180deg);}
        }
    }
    &.type-01 {
        .acc-conts {padding:2.2857vw 2.8571vw;border-radius:1.4286vw;background:$gray01;}
    }
    &.type-02 {
        .acc-trigger{margin-bottom: 0; border-bottom:0; padding-bottom:0;}
        background:$gray01; border-radius: 1.0000vw; padding: 2.1429vw;
        .acc-conts {padding-top: 2.8571vw;}
    }
}
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .accordian{
        .acc-trigger{
            margin-bottom: 10px;
            &::after{width: 20px; height: 20px;}
        }
        &.type-01{
            .acc-conts{padding: 16px 20px; border-radius: 8px;}
        }
    }
}