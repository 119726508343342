.link-underline{
    color: $purple; text-decoration: underline;
}

// 데이터 픽커 레이아웃
.datepicker-wrap{
    margin-top: 5.5556vw;
    .datepicker-title{
        margin-bottom: 2.2222vw;
    }
    .box-input{
        & + .box-input{
            margin-top: 5.5556vw;
        }
    }
}

//필터 리스트
.is-filter-warp{
    position: relative; margin-bottom: 8.3333vw;
    .is-filter{
        overflow:hidden;
        .is-filter-list{
        padding: 0 16.1111vw 0 5.5556vw; overflow-x: auto; overflow-y:hidden; flex-wrap: nowrap; width: calc(100vw - 16.1111vw);
            &::-webkit-scrollbar {
                display: none;
            }

            li{
               width:auto; flex-shrink: 0;
               label{
                padding:1.3889vw 2.7778vw;
               }
            }

        }
        .ico-filter{
            position: absolute; right: 0; top: 0; width:16.1111vw; height:10.4167vw; flex-shrink: 0; font-size:0;
            @include backgroundImg('/assets/images/icon/ico-filter.svg', center , 5.2778vw 4.8611vw);
        }
    }

}

//요금제 - 팝업
.qos-guide {
    text-align:left;
    strong {display:block;margin-bottom:4.8611vw;font-weight:bold;}
    p {display:block;/*margin-bottom:1.3889vw;font-weight:600;*/margin-bottom:2.7778vw;font-weight:normal;}/*08.29수정*/
    dl {
        display:block;min-height:11.1111vw;padding-left:13.8889vw;
        &+dl {margin-top:4.1667vw;}
        dt {@include defaultTxt;font-weight:normal;}
        dd {@include defaultTxt;font-weight:normal;}
        &.type {
            &-kakao { @include backgroundImg('/assets/images/icon/ico-cir-kakao.png',center left, 11.1111vw 11.1111vw);}
            &-youtube { @include backgroundImg('/assets/images/icon/ico-sns-youtube.png',center left, 11.1111vw 11.1111vw);}
        }
    }
    .notification {
        li {@include smallTxt;}
    }
    .bang-notice {margin-bottom:0;}
}
.flex-sb{
    display: flex; justify-content: space-between; align-items: center;
}


//바텀시트
.filter-bottomSheet{
    .layer-popup-item{
        .popup-header{margin-bottom:0 !important;}
    }
    .is-filter-warp{
        background: #fff; padding:0;  border:0;  height:auto;
        h3{
            @include mainTxt;
            margin-top:5.5556vw; margin-bottom: 4.1667vw;
        }
        .btn-radio-group{
            flex-wrap: wrap; gap: 2.2222vw 2.7778vw;
            .btn-radio{
                width:auto; min-width:13.1944vw;
                label{
                    height:9.7222vw; padding:0 3.3333vw; display: flex; align-items: center; font-size:3.8889vw;
                }
            }
        }
        &.vdo-filter {margin-top:5.5556vw;}
    }
}

.type2-agree {
    .btn-check-type2 {
        position:relative;width:100%;
        label {
            width:100%;padding:4.1667vw;border:.2778vw solid $gray02;border-radius:1.9444vw;
            .nec {color:$purple;}
        }
        input[type=checkbox]:checked+label {border: 0.2778vw solid $black;transition: all .3s ease-out;}
    .ico-arrow-right {position:absolute;right:4.1667vw;top:50%;transform:translateY(-50%);}
    }
}

.check-line {
    width: 100%; padding: 4.1667vw; border: .2778vw solid #ddd; border-radius: 1.9444vw;
    .btn-check-type2 {
        position:relative;
        & + .btn-check-type2 {margin-top:4.1667vw;}
        label {
            padding:0;border:0;
            span {font-weight: normal;}
        }
    }
     input[type=checkbox]:checked+label {border:0 !important;}
     .ico-arrow-right {position:absolute;right:0 !important;top:50%;transform:translateY(-50%);}
}

//본인확인 방법 안내
.appli-identification{
    .ico-list{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}
.payment-wrap {
    // .bg-gy {background:$gray01;}
    .cardList {
        dl {
            dt {width:30.5556vw;}
            dd {width:calc(100% - 30.5556vw);}
        }
    }
    .payment-inquiry {
        .no-result {
            position:relative;
            &:before {content:'';display:block;position:absolute;left:-5.5556vw;top:0;width:100vw;height:2.2222vw;background:$gray01;}
        }
    }
    .payment-way {
        .no-result {
            position:relative;
            &:before {content:'';display:block;position:absolute;left:-5.5556vw;top:0;width:100vw;height:2.2222vw;background:$gray01;}
        }
    }
    .way-info {
        h2 {margin:4.1667vw 0 2.0833vw;
            &:first-of-type {margin-top:5.5556vw;}
            &+.box-input {margin-top:0;}
            &+.input-group {margin-top:0;}
            &+.group-input {margin-top:0;}
        }
        .box-input {margin-top:4.1667vw;}
        .box-select {margin-top:4.1667vw;}
        .input-group {margin-top:4.1667vw;}
        .accordian {margin-top:11.1111vw;}
    }
    &.admin {
        .cardList-wrap {margin-bottom:11.1111vw;}
    }
}


// 아이콘페이지
/* 타이틀 밑에 나오는 아이콘 */
.ico-img-wrap{
    img{
        display: block; width: 36.1111vw; height: 36.1111vw; margin: 0 auto;
    }
}
/* 아이콘 밑에 설명나오는 레이아웃 */
.box-ico-txt{
    padding-top: 40.2778vw;
    @include backgroundImg('/assets/images/icon/ico-join-complete.svg', center top,36.1111vw 36.1111vw);
    .ico {
        &-title{
            @include subContsTitle; text-align: center;
            & > span{@include fontInherit;}
        }
        &-txt{@include smallTxt;margin-top: 1.3889vw;text-align: center;}
    }
    .cardList-wrap{margin-top: 5.5556vw;}
    &.pause {
        background-image: url('/assets/images/icon/ico-pause.svg');
    }
    &.pause-cancel{
        background-image: url('/assets/images/icon/ico-pause-unlock.svg');
    }
    &.nodata{
        background-image: url('/assets/images/icon/ico-join-nodata.svg');
    }
    &.set-password{
        background-image: url('/assets/images/icon/ico-set-password-done.svg');
    }
    &.dormant{
        background-image: url('/assets/images/icon/ico-dormant-member.svg');
    }
    &.phone-complete{
        background-image: url('/assets/images/icon/ico-phone-complete.svg');
    }
}
.identifi-box {
    display:block;padding:8.3333vw 5.5556vw;border:0.1389vw solid #ddd;border-radius:2.7778vw;font-size:0;text-align:center;
    & > img{display:inline-block;width: 16.6667vw; height:16.6667vw;margin-bottom: 4.1667vw;}
    .ico-list{
        margin-bottom:8.3333vw;
        li{
            width:16.6667vw;height:16.6667vw;
            img {display:inline-block; width: 100%;}
        }
    }
    b {display:block;@include mainTxt;text-align:center;}
    span {display:block;margin-top:2.7778vw;@include smallTxt;text-align:center;}
    & + .identifi-box {margin-top:5.5556vw;}
    & + .bang-notice {margin-top:2.7778vw;}
}

.find-no-data {
    .page-tit{
        margin-bottom: 3.4722vw;
    }
    .img {
        margin:0 0 4.1667vw;font-size:0;text-align:center;
        img{
            width:100%; display:inline-block;width:36.1111vw; height: 36.1111vw;
        }
    }
    .result {
        >b {display:block;@include subContsTitle; text-align:center;}
        >span {display:block;margin-top:1.3889vw;font-size:3.8889vw;line-height:5.0000vw;letter-spacing:-0.1167vw;text-align:center;}
    }
    .round-box {
        margin-top:8.3333vw;padding:4.5556vw;border-radius:2.7778vw; border: none;
        background-color: $bg;
        > p {@include defaultTxt;}
        > span {display: block; @include smallTxt; margin-top: 1.3889vw;}
        .btn-text-arrow {margin-top:4.1667vw;}
    }
}

//결과 칩모양 리스트
.result-item-wrap{
    display: flex; flex-wrap: wrap; margin-bottom:11.1111vw; gap:2.2222vw;
    li{
        display: inline-block; padding:1.9444vw 3.3333vw; background: #f8f4ff; border-radius:8.3333vw; @include defaultTxt;
    }
}
.feature {
    display:flex; flex-direction:row; margin: 0 0 0 -1.3889vw;
    &>span {
        position:relative;margin-right:1.3889vw;padding-left:6.3889vw; @include smallTxt; white-space:nowrap;
        &::before {
            content:'';position:absolute;left:0;top:50%; transform: translateY(-50%);  width:5.5556vw;height:100%;
        }
        &.vol::before {
            background:url('/assets/images/icon/ico-call-fill.svg') no-repeat center;background-size:auto 4.7222vw;

        }
        &.limit::before {
            background:url('/assets/images/icon/ico-data.svg') no-repeat center; background-size:auto 3.3333vw;
        }
        &.supply::before {
            background:url('/assets/images/icon/ico-sms-fill.svg') no-repeat center;background-size:auto 4.7222vw;
        }
    }
}

//loading spinner
.spinner-wrap{
    
    display: none; position: fixed; width: 100%;height: 100%;background: rgba(25,25,25,.6);top: 0;left: 0;align-items: center;justify-content: center; z-index: 999;
    .spinner{
        position: absolute; left: 50%; top: 50%; margin: -12vw 0 0 -12vw;
        width: 24vw; height:24vw; border-radius: 50%; padding: 0.25vw; background: conic-gradient(#0000 10%,#7c40ce) content-box;
        -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
        radial-gradient(farthest-side,#0000 calc(100% - 3.3vw),#000 calc(100% - 3.3vw));
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: spinner 1.2s infinite steps(10);
    }
    &.box-msg {transform: translateY(30%); background:none; 
        .loading-msg {text-align: center;}
        .loading-msg img {width:54.2222vw;}
        .loading-msg p {margin-top:2.7778vw; font-size: 4.1667vw;line-height: 1.2;letter-spacing: -0.0833vw; color: #666;}
        .spinner {top:11.1111vw; margin:36.4444vw 0 0 -12vw;}
    }
}


// 조회중 화면
.box-loading{
    position: relative;
    .spinner-wrap{
        position: static; margin-bottom: 11.1111vw;
        background: none;
    }
    .main-txt{
        display: block; text-align: center;
    }
    .small-txt{
        display: block; text-align: center; margin-top: 1.3889vw;
    }
}
@keyframes spinner {
    to {
        transform: rotate(1turn);
    }
}
// 로딩중 텍스트
.loading-txt{
    // display: flex; justify-content: center; align-items: center; position: absolute; left: 0; top: 0; z-index: 10; background: #fff; width: 100%; height: 100%;
    @include smallTxt;
    display: flex; justify-content: center; align-items: center; padding: 10.8333vw 5.5556vw;border:0.2778vw solid $gray02;border-radius:2.7778vw;width:100%;
}

// 컬러 선택
.color-type{
    display: flex; gap: 2.2222vw; justify-content: center; margin-top: 2.7778vw;
    li{
        position: relative;width: 8.3333vw; height: 8.3333vw;
        &.on{
            &::before {
                content: ''; display: block; position: absolute; top: 50%; left: 50%; width: 100%; height: 100%; transform: translate(-50%,-50%); border: 0.2778vw solid $black;border-radius: 50%;
            }
        }
        input[type="radio"] {
            @include hidden;
        }
        span,label{
            display: block;  position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 6.3889vw; height: 6.3889vw;border-radius: 50%; overflow: hidden; background-color: #e4f7ff;
        }
    }
}

// 약관상세
.terms{
    .agree-cont {
        @include smallTxt;

        &+.agree-cont {
            margin-top: 8.3333vw;
        }

        h3 {
            @include mainTxt;
        }

        &>p {
            @include smallTxt;
            margin-top: 2.7778vw;
            & + .table-list-wrap{margin-top: 2.7778vw;}
        }

        .box-btn {
            margin-top: 4.1667vw;
        }

        .notification {
            margin-top: 2.7778vw;
        }

        b {
            @include defaultTxt;
            font-weight: 400;
        }

        &+.table-list-wrap {
            margin-top: 4.1667vw;

            &+.agree-cont {
                margin-top: 4.1667vw;
            }
        }

        .txt-left {
            text-align: left;
        }

        .desc-list {
            dt {
                line-height: 1.5;
            }

            dd {
                line-height: 1.5;

                &+dt {
                    margin-top: 10px;
                }
            }
        }
        .txt-bold{font-weight: 600;}
    }

    &.terms-phone-insurance{
    ol{
        margin-top: 2.7778vw;
        li{
        @include defaultTxt; font-weight: 500;
        & > div{
            margin-top: 1.3889vw;
        }
        }
    }
    }
    &.terms-privacy{
        .cardList-wrap{
        margin-top: 11.1111vw;
        ol{
            list-style: decimal;
            padding:5.5556vw;
            li{
            padding:1.3889vw 0; @include defaultTxt;
            a{
                text-decoration: underline; @include defaultTxt;
            }
            }
        }
        }

    }
    &-email-collection {
        @include smallTxt;
    }
    .info-desc {
        &>div {
            margin-bottom:8.3333vw;
            &>h3 {margin-bottom:1.3889vw;@include mainTxt;}
            &>p {
                margin-bottom:1.3889vw;@include defaultTxt;font-weight:normal;
                &+.notification {margin-bottom:4.1667vw;}
            }
            .notification {
                strong {@include defaultTxt;}

            }
        }
    }
}

// 제휴카드 할인 리스트
//목록
.use-card-list {
    .card-list{
        display: flex; flex-direction: column; gap: 4.1667vw;
        li,div{
            a{
                display: flex; justify-content: space-between; border:  0.2778vw solid $gray02; border-radius: 2.7778vw; padding: 5.5556vw;
                .card-img-wrap{
                    flex:1 0 25vw;width: 25vw; overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
                .card-desc{
                    margin-left: 4.1667vw; /*flex-shrink: 0;*/
                    strong{
                        overflow:hidden;text-overflow:ellipsis; display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;
                        @include mainTxt;
                    }
                    span{
                        /*display: block; */overflow:hidden;text-overflow:ellipsis; display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;
                        @include smallTxt;margin-top:1.3889vw;
                    }
                }
                .ico-arrow-right{
                    flex-shrink: 0;
                }
                &:hover{
                    border-color: $black;
                }
            }
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
    .link-underline{
        color: $purple; text-decoration: underline;
    }

    // 데이터 픽커 레이아웃
    .datepicker-wrap{
        margin-top: 2.8571vw;
        .datepicker-title{
            margin-bottom: 1.1429vw;
        }
        .box-input{
            & + .box-input{
                margin-top: 2.8571vw;
            }
        }
    }

    //필터 리스트
    .is-filter-warp{
        position: relative; margin-bottom: 4.2857vw;
        .is-filter{
            overflow:hidden;
            .is-filter-list{
            padding: 0 8.2857vw 0 2.8571vw; overflow-x: auto; overflow-y:hidden; flex-wrap: nowrap; width: 90%;
                &::-webkit-scrollbar {
                    display: none;
                }

                li{
                   width:auto; flex-shrink: 0;
                   label{
                    padding:0.7143vw 1.4286vw;
                   }
                }

            }
            .ico-filter{
                position: absolute; right: 0; top: 0; width:8.2857vw; height:5.3571vw; flex-shrink: 0; font-size:0;
                @include backgroundImg('/assets/images/icon/ico-filter.svg', center , 2.7143vw 2.5000vw);
            }
        }

    }

    //요금제 - 팝업
    .qos-guide {
        text-align:left;
        strong {display:block;margin-bottom:2.5000vw;font-weight:bold;}
        p {display:block;/*margin-bottom:0.7143vw;font-weight:600;*/margin-bottom:1.4286vw;font-weight:normal;}/*08.29수정*/
        dl {
            display:block;min-height:5.7143vw;padding-left:7.1429vw;
            &+dl {margin-top:2.1429vw;}
            dt {font-weight:normal;}
            dd {font-weight:normal;}
            &.type {
                &-kakao { @include backgroundImg('/assets/images/icon/ico-cir-kakao.png',center left, 5.7143vw 5.7143vw);}
                &-youtube { @include backgroundImg('/assets/images/icon/ico-sns-youtube.png',center left, 5.7143vw 5.7143vw);}
            }
        }
        .bang-notice {margin-bottom:0;}
    }
    .flex-sb{
        display: flex; justify-content: space-between; align-items: center;
    }


    //바텀시트
    .filter-bottomSheet{
        .layer-popup-item{
            .popup-header{margin-bottom:0 !important;}
        }
        .is-filter-warp{
            background: #fff; padding:0;  border:0;  height:auto;
            h3{
                margin-top:2.8571vw; margin-bottom: 2.1429vw;
            }
            .btn-radio-group{
                flex-wrap: wrap; gap: 1.1429vw 1.4286vw;
                .btn-radio{
                    width:auto; min-width:6.7857vw;
                    label{
                        height:5.0000vw; padding:0 1.7143vw; display: flex; align-items: center; font-size:2.0000vw;
                    }
                }
            }
        }
    }

    .type2-agree {
        .btn-check-type2 {
            position:relative;width:100%;
            label {
                width:100%;padding:2.1429vw;border:0.1429vw solid $gray02;border-radius:1.0000vw;
                .nec {color:$purple;}
            }
            input[type=checkbox]:checked+label {border: 0.1429vw solid $black;transition: all .3s ease-out;}
        .ico-arrow-right {position:absolute;right:2.1429vw;top:50%;transform:translateY(-50%);}
        }
    }

    //본인확인 방법 안내
    .appli-identification{
        .ico-list{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
    .payment-wrap {
        // .bg-gy {background:$gray01;}
        .cardList {
            dl {
                dt {width:15.7143vw;}
                dd {width:calc(100% - 15.7143vw);}
            }
        }
        .payment-inquiry {
            .no-result {
                position:relative;
                &:before {content:'';display:block;position:absolute;left:-2.8571vw;top:0;width:51.4286vw;height:1.1429vw;background:$gray01;}
            }
        }
        .payment-way {
            .no-result {
                position:relative;
                &:before {content:'';display:block;position:absolute;left:-2.8571vw;top:0;width:51.4286vw;height:1.1429vw;background:$gray01;}
            }
        }
        .way-info {
            h2 {margin:2.1429vw 0 1.0714vw;
                &:first-of-type {margin-top:2.8571vw;}
                &+.box-input {margin-top:0;}
                &+.input-group {margin-top:0;}
                &+.group-input {margin-top:0;}
            }
            .box-input {margin-top:2.1429vw;}
            .box-select {margin-top:2.1429vw;}
            .input-group {margin-top:2.1429vw;}
            .accordian {margin-top:5.7143vw;}
        }
        &.admin {
            .cardList-wrap {margin-bottom:5.7143vw;}
        }
    }


    // 아이콘페이지
    /* 타이틀 밑에 나오는 아이콘 */
    .ico-img-wrap{
        img{
            display: block; width: 18.5714vw; height: 18.5714vw; margin: 0 auto;
        }
    }
    /* 아이콘 밑에 설명나오는 레이아웃 */
    .box-ico-txt{
        padding-top: 20.7143vw;
        @include backgroundImg('/assets/images/icon/ico-join-complete.svg', center top,18.5714vw 18.5714vw);
        .ico {
            &-title{
                text-align: center;
                & > span{@include fontInherit;}
            }
            &-txt{margin-top: 0.7143vw;text-align: center;}
        }
        .cardList-wrap{margin-top: 2.8571vw;}
        &.pause {
            background-image: url('/assets/images/icon/ico-pause.svg');
        }
        &.pause-cancel{
            background-image: url('/assets/images/icon/ico-pause-unlock.svg');
        }
        &.nodata{
            background-image: url('/assets/images/icon/ico-join-nodata.svg');
        }
        &.set-password{
            background-image: url('/assets/images/icon/ico-set-password-done.svg');
        }
        &.dormant{
            background-image: url('/assets/images/icon/ico-dormant-member.svg');
        }
        &.phone-complete{
            background-image: url('/assets/images/icon/ico-phone-complete.svg');
        }
    }
    .identifi-box {
        display:block;padding:4.2857vw 2.8571vw;border:0.0714vw solid #ddd;border-radius:1.4286vw;font-size:0;text-align:center;
        & > img{display:inline-block;width: 8.5714vw; height:8.5714vw;margin-bottom: 2.1429vw;}
        .ico-list{
            margin-bottom:4.2857vw;
            li{
                width:8.5714vw;height:8.5714vw;
                img {display:inline-block; width: 100%;}
            }
        }
        b {display:block;text-align:center;}
        span {display:block;margin-top:1.4286vw;text-align:center;}
        & + .identifi-box {margin-top:2.8571vw;}
        & + .bang-notice {margin-top:1.4286vw;}
    }

    .find-no-data {
        .page-tit{
            margin-bottom: 1.7857vw;
        }
        .img {
            margin:0 0 2.1429vw;font-size:0;text-align:center;
            img{
                width:100%; display:inline-block;width:18.5714vw; height: 18.5714vw;
            }
        }
        .result {
            >b {display:block; text-align:center;}
            >span {display:block;margin-top:0.7143vw;font-size:2.0000vw;line-height:2.5714vw;letter-spacing:-0.0714vw;text-align:center;}
        }
        .round-box {
            margin-top:4.2857vw;padding:2.3571vw;border-radius:1.4286vw; border: none;
            background-color: $bg;
            > span {display: block; margin-top: 0.7143vw;}
            .btn-text-arrow {margin-top:2.1429vw;}
        }
    }

    //결과 칩모양 리스트
    .result-item-wrap{
        display: flex; flex-wrap: wrap; margin-bottom:5.7143vw; gap:1.1429vw;
        li{
            display: inline-block; padding:1.0000vw 1.7143vw; background: #f8f4ff; border-radius:4.2857vw;
        }
    }
    .feature {
        display:flex; flex-direction:row; margin: 0 0 0 -0.7143vw;
        &>span {
            position:relative;margin-right:0.7143vw;padding-left:3.2857vw; white-space:nowrap;
            &::before {
                content:'';position:absolute;left:0;top:50%; transform: translateY(-50%);  width:2.8571vw;height:100%;
            }
            &.vol::before {
                background:url('/assets/images/icon/ico-call-fill.svg') no-repeat center; background-size:auto 1.7143vw;
            }
            &.limit::before {
                background:url('/assets/images/icon/ico-data.svg') no-repeat center;background-size:auto 2.4286vw;
            }
            &.supply::before {
                background:url('/assets/images/icon/ico-sms-fill.svg') no-repeat center;background-size:auto 2.4286vw;
            }
        }
    }

    //loading spinner
    .spinner-wrap{
        display: none; position: fixed; width: 100%;height: 100%;background: rgba(25,25,25,.6);top: 0;left: 0;display: flex;align-items: center;justify-content: center; z-index: 999;
        .spinner{
            width: 12.3571vw; height:12.3571vw; border-radius: 50%; padding: 0.1429vw; background: conic-gradient(#0000 10%,#7c40ce) content-box;
            -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
            radial-gradient(farthest-side,#0000 calc(100% - 1.7143vw),#000 calc(100% - 1.7143vw));
            -webkit-mask-composite: destination-in;
            mask-composite: intersect;
            animation: spinner 1.2s infinite steps(10);
        }
    }
    // 조회중 화면
    .box-loading{
        .spinner-wrap{
            position: static; margin-bottom: 5.7143vw;
            background: none;
        }
        .main-txt{
            display: block; text-align: center;
        }
        .small-txt{
            display: block; text-align: center; margin-top: 0.7143vw;
        }
    }
    @keyframes spinner {
        to {
            transform: rotate(1turn);
        }
    }


    // 컬러 선택
    .color-type{
        display: flex; gap: 1.1429vw; justify-content: center; margin-top: 1.4286vw;
        li{
            position: relative;width: 4.2857vw; height: 4.2857vw;
            &.on{
                &::before {
                    content: ''; display: block; position: absolute; top: 50%; left: 50%; width: 100%; height: 100%; transform: translate(-50%,-50%); border: 0.1429vw solid $black;border-radius: 50%;
                }
            }
            input[type="radio"] {
                @include hidden;
            }
            span,label{
                display: block;  position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 3.2857vw; height: 3.2857vw;border-radius: 50%; overflow: hidden; background-color: #e4f7ff;
            }
        }
    }

    // 약관상세
    .terms{
        .agree-cont{
            & + .agree-cont {margin-top: 4.2857vw;}
           & > p{
                margin-top: 1.4286vw;
            }
            .box-btn{
            margin-top: 2.1429vw;
            }
            .notification{
                margin-top: 1.4286vw;
            }
            b{
                font-weight: 400;
            }
            & + .table-list-wrap{
                margin-top:2.1429vw;
                & + .agree-cont{
                    margin-top:2.1429vw;
                }
            }
        }

        &.terms-phone-insurance{
        ol{
            margin-top: 1.4286vw;
            li{
                font-weight: 500;
            & > div{
                margin-top: 0.7143vw;
            }
            }
        }
        }
        &.terms-privacy{
            .cardList-wrap{
            margin-top: 5.7143vw;
            ol{
                list-style: decimal;
                padding:2.8571vw;
                li{
                padding:0.7143vw 0;
                a{
                    text-decoration: underline;
                }
                }
            }
            }

        }
        .info-desc {
            &>div {
                margin-bottom:4.2857vw;
                &>h3 {margin-bottom:0.7143vw;}
                &>p {
                    margin-bottom:0.7143vw;font-weight:normal;
                    &+.notification {margin-bottom:2.1429vw;}
                }
            }
        }
    }

    // 제휴카드 할인 리스트
    //목록
    .use-card-list {
        .card-list{
            display: flex; flex-direction: column; gap: 2.1429vw;
            li,div{
                a{
                    display: flex; justify-content: space-between; border:  0.1429vw solid $gray02; border-radius: 1.4286vw; padding: 2.8571vw;
                    .card-img-wrap{
                        flex:1 0 12.8571vw;width: 12.8571vw; overflow: hidden;
                        img{
                            width: 100%;
                        }
                    }
                    .card-desc{
                        margin-left: 2.1429vw; /*flex-shrink: 0;*/
                        strong{
                            overflow:hidden;text-overflow:ellipsis; display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;
                        }
                        span{
                            /*display: block; */overflow:hidden;text-overflow:ellipsis; display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;
                            margin-top:0.7143vw;
                        }
                    }
                    .ico-arrow-right{
                        flex-shrink: 0;
                    }
                    &:hover{
                        border-color: $black;
                    }
                }
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .loading-txt{border-width: 1px; border-radius: 8px; padding: 80px 40px;}
    .use-card-list{
        .card-list{
            gap: 15px;
            div{
                a{
                    border-width: 1px; padding: 20px; border-radius: 8px; justify-content: flex-start; position: relative;
                    .card-img-wrap{width: 100px; flex: none;}
                    .ico-arrow-right{position: absolute; top: 20px; right: 20px;}
                }
            }
            li{
                a{border-width: 1px; padding: 20px; border-radius: 8px;
                    .card-desc{
                        span{margin-top: 5px;}
                    }
                }
            }
        }
    }
    .box-ico-txt{
        background-size: 133px 133px; padding-top: 150px;
        .ico-txt{margin-top: 5px;}
    }
    .identifi-box{
        padding: 20px; border-width: 1px; border-radius: 8px;
        & > img{width: 60px; height: 60px; margin-bottom: 15px;}
        span{margin-top: 10px;}
        & + .identifi-box{margin-top: 20px;}
        .ico-list{
            width: 70%; margin: 0 auto 30px;
            li{width: 45px; height: 45px;}
        }
    }
    .terms{
        .agree-cont{
            & + .agree-cont{margin-top: 30px;}
            & > p{margin-top: 10px;}
            & + .table-list-wrap{
                margin-top: 15px;
                & + .agree-cont{margin-top: 30px;}
            }
            .notification{margin-top: 10px;}
        }
        &.terms-privacy{
            .cardList-wrap{
                margin-top: 30px;
                ol{padding: 20px;
                    li{padding: 5px 0;}
                }
            }
        }
        .info-desc{
            & > div{
                margin-bottom: 30px;
                & > h3{margin-bottom: 5px;}
            }
        }
    }
    .color-type{gap: 10px; margin-top: 10px;
        li{width: 30px; height: 30px;
            &.on{
                &::before{border-width: 1px;}
            }
            label, span {width: 23px; height: 23px;}
        }
    }
    .feature{
        margin: 0 0 0 -5px;
        & > span{
            margin-right: 5px; padding-left: 25px;
            &::before{width: 23px;}
            &.limit{
                &::before{background-size: auto 14px;}
            }
            &.vol{
                &::before{background-size: auto 20px;}
            }
            &.supply{
                &::before{background-size: auto 20px;}
            }
        }
    }
    .result-item-wrap{
        gap: 10px; margin-bottom: 40px;
        li{padding: 7px 12px; border-radius: 20px;}
    }
    .type2-agree{
        .btn-check-type2{
            label{padding: 15px; border-width: 1px; border-radius: 8px;}
        }
    }
    .is-filter-warp{
        margin-bottom: 30px;
        .is-filter{
            .is-filter-list{padding: 0 50px 0 20px; width: 90%;
                li{
                    label{padding: 5px 10px;}
                }
            }
            .ico-filter{width: 60px; height: 38px; background-size: 19px 18px;} 
        }
    }
    .qos-guide{
        strong, p{margin-bottom: 20px;}
        dl{
            & + dl{margin-top: 15px;}
            min-height: 40px; padding-left: 50px;
            &.type-kakao{background-size: 40px 40px;}
            &.type-youtube{background-size: 40px 40px;}
            dt, dd{
                font-weight: 400;
            }
        }
    }
    .filter-bottomSheet{
        .is-filter-warp{
            h3{margin-top: 20px; margin-bottom: 15px;}
            .btn-radio-group{
                gap: 10px 8px;
                .btn-radio{
                    min-width: 48px;
                    label{height: 36px; padding: 0 12px; font-size: 14px;}
                }
            }
        }
    }
    .find-no-data{
        .page-tit{margin-bottom: 10px;}
        .img{
            margin: 0 0 15px;
            img{width: 130px; height: 130px;}
        }
        .round-box{
            margin-top: 30px; padding: 20px; border-radius: 8px;
            & > span{margin-top: 5px;}
            .btn-text-arrow{margin-top: 15px;}
        }
    }
    .datepicker-wrap{
        margin-top: 20px;
        .datepicker-title{margin-bottom: 10px;}
        .box-input{
            & + .box-input{margin-top: 20px;}
        }
    }
    .payment-wrap{
        .way-info{
            h2{
                &:first-of-type{margin-top: 20px;}
            }
            .box-select{margin-top: 15px;}
            .box-input{margin-top: 15px;}
            .input-group{margin-top: 15px;}
            .accordian{margin-top: 40px;}
        }
        &.admin{
            .cardList-wrap{margin-bottom: 40px;}
        }
    }
    .ico-img-wrap{
        img{width: 135px; height: 135px;}
    }
}