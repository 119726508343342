.btns{
    @include mainTxt; color: $white;
    position: relative; width:100%; background:$purple; padding:5.5556vw 0; text-align: center; display: inline-block; border-radius:1.9444vw; display: inline-flex; align-items: center; justify-content: center;

    &.w-sm{
        min-width:38.8889vw;
    }
    &.h-sm{
       padding:3.3333vw 0; color:$white; min-height:13.8889vw;
    }
    &.corner-half{
        padding:3.8889vw 0; border-radius:0 0 2.7778vw 2.7778vw;
    }
    &.btns-radio{
        display: block; padding: 3.4722vw 0; border-radius: 1.9444vw; border: .2778vw solid #ddd; background: #fff; color: #666; font-size: 4.1667vw; font-weight: 600; line-height: 1.3; letter-spacing: -.0833vw; text-align: center;transition:0.3s;
        &:hover, &:active, &:focus{
            border-color: $purple; color: $purple;
        }
    }
    &.white{
        color:#666; background: $gray06;
        span{
            position: relative; display: inline-block; padding-right:5.5556vw;
            @include subContsTitle;
            @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', right, 3.4722vw 2.0833vw);
        }
    }

    &.sticky{
        border-radius:0;
        &.white{
            background: $gray06;
        }
    }

    &:disabled{
        background-color:$gray01; color:#afafaf !important; cursor: default; border: none !important;
    }

}

.btn-dark-gray{
    @include defaultTxt; font-weight: 600; color: $white;
    padding:4.1667vw; background:$gray05; border-radius:1.9444vw;
    &:disabled{
        background-color:$gray02; color:#afafaf; cursor: default;
    }
}
.downLoad-list{
    li,div{
        a{
            display: flex; justify-content: space-between; align-items: center; width: 100%; padding:4.1667vw; border-radius: 2.7778vw; border:0.2778vw solid $gray02; gap:4.1667vw;
            .downLoad-tit{
                width:calc(100% - 6.6667vw); @include smallTxt;
            }
            .ico-downLoad{
                width:6.6667vw; height: 6.6667vw; display: block;
            }
        }
        & + li{
            margin-top: 4.1667vw;
        }
    }
}
.btn-text{
    padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt;
    &-arrow{
        @include backgroundImg('/assets/images/icon/ico-arrow-circle.svg', calc(100% - 0.1389vw) center, 4.4444vw 4.4444vw);
        padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt;
    }
    &-plus{
        @include backgroundImg('/assets/images/icon/ico-plus-sm.svg', calc(100% - 0.1389vw) center, 4.4444vw 4.4444vw);
        padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt; padding-right: 5.2222vw;
    }
    &-more{
        display: inline-block; padding: 5.5556vw 0; width:100%;
         position: relative; display: inline-block;  @include defaultTxt; border-radius: 1.9444vw;
        .count{
           padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt; padding-right: 6.2222vw; position: relative;
           &::after{
            content:'';
            display: inline-block;
            @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center center, 5.5556vw 5.5556vw);
            position: absolute; top:50%; transform: translateY(-50%); right:0; width: 3.4444vw; height: 2.4444vw;
           }
        }
        &.type-02 {
            span{
                display: inline-block; position: relative; @include defaultTxt;
                &::after{
                    content: ''; position: absolute; top: 50%; left:calc(100% + 1.3889vw); width: 5.5556vw; height:5.5556vw; transform: translateY(-50%); transform-origin: center;
                    @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center,contain);
                }
            }
            // 접기
            &.fold{
                span{
                    &::after{
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
    }
    &-gray{
        padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt;
        display: flex; justify-content: space-between; align-items: center; width:100%; background:$gray01; padding: 2.9167vw 4.1667vw; border-radius:1.9444vw;
    }
    &-num {
        padding-right:7.2222vw; position: relative; display: inline-block;  @include defaultTxt;font-size:4.1667vw;
        display:flex;justify-content:space-between;align-items:center;width:100%;padding:2.9167vw 4.1667vw;border:1px solid $gray02;border-radius:1.9444vw;
        .text {
            @include smallTxt;
            .num {display:inline-block;width:5.5556vw;height:5.5556vw;margin-left:1.3889vw;border-radius:50%;background:$purple;@include smallTxt;color:$white;font-size:4.1667vw;line-height:5.5556vw;text-align:center;}
        }
    }
    &-line{
        padding: 4.1667vw 5.5556vw 4.1667vw 2.7778vw; border: 0.2778vw solid $gray02; border-radius: 1.9444vw;
    }
}


.text-btn-gray{
    @include defaultTxt;
    display: flex; justify-content: space-between; align-items: center; width:100%; background:$gray01; padding:5.5556vw; border-radius:1.9444vw;
    &.wh{
        background: $white; border: 0.2778vw solid $gray02;
    }
}

.btn-group{
    display: flex; flex:1; width:100%;
    &.btn-fix{
        position:fixed; left:0;bottom:0;width:100%; z-index: 1;
    }
    &.btn-sticky{
        position:sticky;left:0;bottom:0;width:100vw; margin:11.1111vw 0 0 -5.5556vw;
    }
    &.btn-set{
        gap:2.7778vw;
        .btns{
            padding: 3.3333vw 0; border-radius: 1.9444vw; font-size: 4.1667vw;
        }
    }
    .btns{
        border-radius: 0; padding:5.5556vw 0;
       &.txt-rows {padding:0;}
    }
    .white{
        border:none; border-radius: 0; padding:5.5556vw 0; background-color: $gray06;
    }
    .pnk {background:#ec008c}


}
.popup-btn{
    border-radius:0 0 2.7778vw 2.7778vw; overflow: hidden;
}
.box-btn{
    &.btn-flex{
        display: flex; flex-direction: column; gap:  4.1667vw;
    }
}


//툴팁
.tooltip-wrap{display: flex; align-items: center;gap: 0.8333vw;}
.box-tooltip{
    display: inline-flex;align-items: center;  position: relative; z-index: 1; font-size: 0;vertical-align:middle;
    .btn-tooltip{
        display: inline-block; width: 4.1667vw;height: 4.1667vw;@include backgroundImg('/assets/images/icon/ico-tip.svg', center,contain);
    }
    .txt-tooltip{
        position: absolute;top: 6.9444vw;left: -27.6389vw; transform: translateX(50%); max-width: 88.8889vw;min-width: 88.8889vw;width: 100%; background-color: $white; box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);border: solid 0.2778vw $gray02;border-radius: 2.2222vw; transform: translateY(-20%); opacity: 0; visibility: hidden; transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
        &.on{transform: translateY(0); opacity: 1; visibility: visible; }
        & > span{
            position: relative;display: block;padding: 4.1667vw 5vw; @include smallTxt;font-weight: 400;
        }

    }
}

// 날짜 버튼
.month-box{
    display: flex; justify-content: space-between; align-items: center; border: 0.2778vw solid $gray02; border-radius: 1.9444vw;padding: 4.1667vw;
    strong{@include defaultTxt;}
    .btn-month {
        &.disabled{
            &.ico-arrow-left{background-image: url('/assets/images/icon/ico-arrow-left-gray.svg');transform: rotate(0deg);}
            &.ico-arrow-right{background-image: url('/assets/images/icon/ico-arrow-left-gray.svg');transform: rotate(-180deg);}
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .btns{
        color: $white;
        position: relative; width:100%; background:$purple; padding:2.8571vw 0; text-align: center; display: inline-block; border-radius:1.0000vw; display: inline-flex; align-items: center; justify-content: center;

        &.w-sm{
            min-width:20.0000vw;
        }
        &.h-sm{
           padding:1.7143vw 0; color:$white; min-height:7.1429vw;
        }
        &.corner-half{
            padding:2.0000vw 0; border-radius:0 0 1.4286vw 1.4286vw;
        }
        &.btns-radio{
            display: block; padding: 1.7857vw 0; border-radius: 1.0000vw; border: 0.1429vw solid #ddd; background: #fff; color: #666; font-size: 2.1429vw; font-weight: 600; line-height: 1.3; letter-spacing: -0.0714vw; text-align: center;transition:0.3s;
            &:hover, &:active, &:focus{
                border-color: $purple; color: $purple;
            }
        }
        &.white{
            color:$black; background: $gray06;
            span{
                position: relative; display: inline-block; padding-right:2.8571vw;
                @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg', right, 1.7857vw 1.0714vw);
            }
        }

        &.sticky{
            border-radius:0;
            &.white{
                background: $gray06;
            }
        }

        &:disabled{
            background-color:$gray01; color:#afafaf !important; cursor: default; border: none !important;
        }

    }

    .btn-dark-gray{
        font-weight: 600; color: $white;
        padding:2.1429vw; background:$gray05; border-radius:1.0000vw;
        &:disabled{
            background-color:$gray02; color:#afafaf; cursor: default;
        }
    }
    .downLoad-list{
        li,div{
            a{
                display: flex; justify-content: space-between; align-items: center; width: 100%; padding:2.1429vw; border-radius: 1.4286vw; border:0.1429vw solid $gray02; gap:2.1429vw;
                .downLoad-tit{
                    width:calc(100% - 3.4286vw);
                }
                .ico-downLoad{
                    width:3.4286vw; height: 3.4286vw; display: block;
                }
            }
            & + li{
                margin-top: 2.1429vw;
            }
        }
    }
    .btn-text{
        padding-right:3.7143vw; position: relative; display: inline-block;
        &-arrow{
            @include backgroundImg('/assets/images/icon/ico-arrow-circle.svg', calc(100% - 0.0714vw) center, 2.2857vw 2.2857vw);
            padding-right:3.7143vw; position: relative; display: inline-block;
        }
        &-plus{
            @include backgroundImg('/assets/images/icon/ico-plus-sm.svg', calc(100% - 0.0714vw) center, 2.2857vw 2.2857vw);
            padding-right:3.7143vw; position: relative; display: inline-block;  padding-right: 2.7143vw;
        }
        &-more{
            display: inline-block; padding: 2.8571vw 0; width:100%;
            position: relative; display: inline-block; border-radius: 1.0000vw;
            .count{
               padding-right:3.7143vw; position: relative; display: inline-block;padding-right: 3.2143vw; position: relative;
               &::after{
                content:'';
                display: inline-block;
                @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center center, 2.8571vw 2.8571vw);
                position: absolute; top:50%; transform: translateY(-50%); right:0; width: 1.7857vw; height: 1.2857vw;
               }
            }
            &.type-02 {
                span{
                    display: inline-block; position: relative;
                    &::after{
                        content: ''; position: absolute; top: 50%; left:calc(100% + 0.7143vw); width: 2.8571vw; height:2.8571vw; transform: translateY(-50%); transform-origin: center;
                        @include backgroundImg('/assets/images/icon/ico-arrow-bottom.svg',center,contain);
                    }
                }
                // 접기
                &.fold{
                    span{
                        &::after{
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }
        &-gray{
            padding-right:3.7143vw; position: relative; display: inline-block;
            display: flex; justify-content: space-between; align-items: center; width:100%; background:$gray01; padding: 1.5000vw 2.1429vw; border-radius:1.0000vw;
        }
        &-num {
            padding-right:3.7143vw; position: relative; display: inline-block;font-size:2.1429vw;
            display:flex;justify-content:space-between;align-items:center;width:100%;padding:1.5000vw 2.1429vw;border:0.0714vw solid $gray02;border-radius:1.0000vw;
            .text {
                .num {display:inline-block;width:2.8571vw;height:2.8571vw;margin-left:0.7143vw;border-radius:50%;background:$purple;color:$white;font-size:2.1429vw;line-height:2.8571vw;text-align:center;}
            }
        }
        &-line{
            padding: 2.1429vw 2.8571vw 2.1429vw 1.4286vw; border: 0.1429vw solid $gray02; border-radius: 1.0000vw;
        }
    }


    .text-btn-gray{
        display: flex; justify-content: space-between; align-items: center; width:100%; background:$gray01; padding:2.8571vw; border-radius:1.0000vw;
        &.wh{
            background: $white; border: 0.1429vw solid $gray02;
        }
    }

    .btn-group{
        display: flex; flex:1; width:100%;
        &.btn-fix{
            position:fixed; left:0;bottom:0;width:100%; z-index: 1;
        }
        &.btn-sticky{
            position:sticky;left:0;bottom:0;width:51.4286vw; margin:5.7143vw 0 0 -2.8571vw;
        }
        &.btn-set{
            gap:1.4286vw;
            .btns{
                padding: 1.7143vw 0; border-radius: 1.0000vw; font-size: 2.1429vw;
            }
        }
        .btns{
            border-radius: 0; padding:2.8571vw 0;
        }
        .white{
            border:none; border-radius: 0; padding:2.8571vw 0; background-color: $gray06;
        }


    }
    .popup-btn{
        border-radius:0 0 1.4286vw 1.4286vw; overflow: hidden;
    }
    .box-btn{
        &.btn-flex{
            display: flex; flex-direction: column; gap:  2.1429vw;
        }
    }


    //툴팁
    .tooltip-wrap{display: flex; align-items: center;gap: 0.4286vw;}
    .box-tooltip{
        display: inline-flex;align-items: center;  position: relative; z-index: 1; font-size: 0;vertical-align:middle;
        .btn-tooltip{
            display: inline-block; width: 2.1429vw;height: 2.1429vw;@include backgroundImg('/assets/images/icon/ico-tip.svg', center,contain);
        }
        .txt-tooltip{
            position: absolute;top: 3.5714vw;left: -14.2143vw; transform: translateX(50%); max-width: 45.7143vw;min-width: 45.7143vw;width: 100%; background-color: $white; box-shadow: 0 0.2143vw 2.1429vw 0 rgba(0, 0, 0, 0.16);border: solid 0.1429vw $gray02;border-radius: 1.1429vw; transform: translateY(-20%); opacity: 0; visibility: hidden; transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
            &.on{transform: translateY(0); opacity: 1; visibility: visible; }
            & > span{
                position: relative;display: block;padding: 2.1429vw 2.5714vw; font-weight: 400;
            }

        }
    }

    // 날짜 버튼
    .month-box{
        display: flex; justify-content: space-between; align-items: center; border: 0.1429vw solid $gray02; border-radius: 1.0000vw;padding: 2.1429vw;
        .btn-month {
            &.disabled{
                &.ico-arrow-left{background-image: url('/assets/images/icon/ico-arrow-left-gray.svg');transform: rotate(0deg);}
                &.ico-arrow-right{background-image: url('/assets/images/icon/ico-arrow-left-gray.svg');transform: rotate(-180deg);}
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .btns{
        border-radius: 8px; color: #fff; padding: 20px 0;
        &.h-sm{padding: 12px; min-height: 50px;}
        &.btns-radio{border-radius: 8px; border-width: 1px; font-size: 15px; letter-spacing: -0.5px; padding: 12px;}
    }
    .btn-text{
        &-arrow{
            background-size: 16px 16px; background-position: calc(100% - 5px) center; padding-right: 26px;
        }
    }
    .text-btn-gray{
        padding: 20px; border-radius: 8px;
        &.wh{border-width: 1px;}
    }
    .btn-group{
        .btns{padding: 20px 0;}
        &.btn-set{
            gap: 10px;
            .btns{border-radius: 8px; padding: 12px 0; font-size: 15px;}
        }
    }
    .btn-dark-gray{padding: 15px; border-radius: 8px; color: #fff;}
    .btn-text-more{
        .count{
            padding-right: 23px;
            &::after{width: 12px; height: 9px; background-size: 20px 20px;}
        }
    }
    .box-tooltip{
        .btn-tooltip{width: 15px; height: 15px;}
        .txt-tooltip{
            min-width: 320px; max-width: 320px; border-radius: 8px; top: 25px; left: -100px;
            & > span{padding: 15px 18px;}
        }
    }
    .tooltip-wrap{gap: 5px;}
    .btn-text-more{padding: 10px 0;
        &.type-02{
            span{
                &::after{width: 20px; height: 20px;}
            }
        }
    }
    .downLoad-list{
        div{
            a{
                padding: 20px; border-width: 1px; border-radius: 8px;
                .ico-downLoad{
                    width: 25px; height: 25px;
                }
            }
        }
        li{
            & + li{margin-top: 15px;}
            a{
                padding: 20px; border-width: 1px; border-radius: 8px;
                .ico-downLoad{
                    width: 25px; height: 25px;
                }
            }
        }
    }
    .month-box{border-width: 1px; border-radius: 8px; padding: 15px;}
    .btn-text-gray{padding: 10px 15px; border-radius: 8px;}
    .btn-text-line{padding: 15px 20px 15px 10px; border-width: 1px; border-radius: 8px;}
    .box-btn{
        &.btn-flex{gap: 15px;}
    }
}