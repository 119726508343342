@charset "UTF-8";


#wrap {
    position: relative;
    width: 100%;
    height: 100%;
    //contain: paint;

    &.fixed {
        //contain: unset;
        height: 100vh;
        overflow: hidden;
    }
}

.scrollOff{
    position:fixed;
    overflow: hidden;
    width:100%;
    height: 100%;
}

.container {
    position:relative;
    min-height: 70vh;
    padding: 16.6667vw 0 19.4444vw 0;
    margin-top: var(--safe-top);

    .sub-conts {
        padding:11.1111vw 5.5556vw 0 5.5556vw;
    }
    &.pb-big{
        padding-bottom: 33.3333vw !important;
    }
}
.wide-box{
    width:100vw;
    margin-left:-5.5556vw;
}

.page-tit{
    margin-bottom:11.1111vw;
    h1,h2{
        @include subContsTitle;
        > strong{
            @include subContsTitle; color: $purple;
        }
    }
    p{@include smallTxt; margin-top: 1.3889vw}
    .bang-notice{margin-top: 1.3889vw;}
    .notification{margin-top: 1.3889vw;}
    &+.bang-notice{
        margin: 1.3889vw 0 11.1111vw 0 ;
    }
}
.full-popup-conts .page-tit{
     margin-bottom:5.5556vw;
}

// 쳇봇 제어
div[role="presentation"]{
    iframe[style*="webSDKOnLoad"]{
        right:50% !important;bottom:123px !important;width:56px !important;height:56px !important;margin-right:-626px;opacity:0;z-index:0 !important;
    }
}
div{
    > div[role="presentation"]{
        > div{
            bottom:170px !important;
        }
        iframe{
            &+iframe{
                display: none;
            }
        }
    }
}
.chat_msgbox {bottom:170px !important;}
iframe[title='메시징 창을 시작하는 버튼'] {display:none;}
iframe[title='읽지 않은 메시지 수'] {display:none;}
iframe[title="메시징 창을 시작하는 버튼, 대화 진행 중"] {display:none;}

iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 1개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 2개"], 
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 3개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 4개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 5개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 6개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 7개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 8개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 9개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 10개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 11개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 12개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 13개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 14개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 15개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 16개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 17개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 18개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 19개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 20개"],
#launcher {display:none;}

.chat-bot, .main .chat-bot{
    position: fixed; right: 5.5556vw; bottom: 2.3611vw; z-index: 90;
    &.is-btn-fix{
        bottom: 19.0278vw;
    }
    a.open{
        display: block; width: 12.5vw; height: 12.5vw; @include backgroundImg('/assets/images/icon/ico-floating-chatbot.svg', 0, 100%); font-size: 0;
    }
    a.close { display: none; width: 54px; height: 54px; @include backgroundImg('/assets/images/icon/ico-float-btn-close.svg', 0, 100%); font-size: 0;}
}
.chat-bot {
    &.hide {display:none !important; opacity: 0 !important; z-index: -1 !important;}
    &.all-hide {display:none !important;opacity: 0 !important; z-index: -5 !important;}
}

.survey-wrap{
    position: fixed; right: 5.5556vw !important; z-index: 90 !important; width: 15vw; height: 15vw;
    &.is-btn-fix{
        bottom: 36.4722vw !important;
    }
    &.hide {display:none !important; opacity: 0 !important; z-index: -1 !important;}
    &.all-hide {display:none !important;opacity: 0 !important; z-index: -5 !important;}
}
.survey01{
    @include backgroundImg('/assets/images/icon/img_survey01.svg', 0, 100%); font-size: 0;
    .survey-btn01{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey02{
    @include backgroundImg('/assets/images/icon/img_survey02.svg', 0, 100%); font-size: 0;
    .survey-btn02{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey03{
    @include backgroundImg('/assets/images/icon/img_survey03.svg', 0, 100%); font-size: 0;
    .survey-btn03{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey04{
    @include backgroundImg('/assets/images/icon/img_survey04.svg', 0, 100%); font-size: 0;
    .survey-btn04{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey-close{
    position: absolute; left: 0; top: 0; width: 54px; height: 54px; @include backgroundImg('/assets/images/icon/ico-btn-survey-close.svg', 0, 100%); font-size: 0;
}


.main{
    .chat-bot{
        position: fixed; right: 5.5556vw; bottom: 20.1111vw; z-index: 90;
        a{
            display: block; width: 12.5vw; height: 12.5vw; @include backgroundImg('/assets/images/icon/ico-floating-chatbot.svg', 0, 100%); font-size: 0;
        }     
        .all-close {margin-top:2.7778vw; background:url('/assets/images/icon/ico-floating-chatbot-close.png') no-repeat; background-size: 100%; }        
    }
    .survey-wrap{
        position: fixed; right: 5.5556vw !important; bottom: 51.1111vw !important; z-index: 90 !important; width: 12.5vw; height: 12.5vw;
    }
    .survey01{
        @include backgroundImg('/assets/images/icon/img_survey01.svg', 0, 100%); font-size: 0;
        .survey-btn01{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey02{
        @include backgroundImg('/assets/images/icon/img_survey02.svg', 0, 100%); font-size: 0;
        .survey-btn02{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey03{
        @include backgroundImg('/assets/images/icon/img_survey03.svg', 0, 100%); font-size: 0;
        .survey-btn03{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey04{
        @include backgroundImg('/assets/images/icon/img_survey04.svg', 0, 100%); font-size: 0;
        .survey-btn04{
            width: 100%; height: 100%; font-size: 0;
        }
    }

}

.chat-bot {
    position: fixed; right: 5.5556vw; bottom: 2.3611vw; z-index: 90;
    a{
        display: block; width: 12.5vw; height: 12.5vw;  font-size: 0;
    }   
    .all-close {margin-top:2.7778vw; background:url('/assets/images/icon/ico-floating-chatbot-close.png') no-repeat; background-size: 100%; }
}
.survey-wrap{
    position: fixed; right: 5.5556vw !important; bottom: 51.1111vw; z-index: 90 !important; width: 12.5vw; height: 12.5vw;
}
// @media (min-aspect-ratio: 13/9) {
// 	html{
//         zoom: 0.5;
//     }
//     .wide-box{
//         width:100%;
//     }
// }

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {

    #wrap {
        position: relative;
        width: 100%;
        height: 100%;
        //contain: paint;

        &.fixed {
            //contain: unset;
            height: 100vh;
            overflow: hidden;
        }
    }

    .scrollOff{
        position:fixed;
        overflow: hidden;
        width:100%;
        height: 100%;
    }

    .container {
        position:relative;
        min-height: 70vh;
        padding: 8.5714vw 0 10.0000vw 0;
        margin-top: var(--safe-top);

        .sub-conts {
            padding:5.7143vw 2.8571vw 0 2.8571vw;
        }
        &.pb-big{
            padding-bottom: 17.1429vw !important;
        }
    }
    .wide-box{
        width:100% !important;
        margin-left:0 !important;
    }

    .page-tit{
        margin-bottom:5.7143vw;
        h1,h2{
            > strong{
                color: $purple;
            }
        }
        p{margin-top: 0.7143vw}
        .bang-notice{margin-top: 0.7143vw;}
        .notification{margin-top: 0.7143vw;}
        &+.bang-notice{
            margin: 0.7143vw 0 5.7143vw 0 ;
        }
    }
    .full-popup-conts .page-tit{
        margin-bottom:2.8571vw;
    }

    // 쳇봇 제어
    div[role="presentation"]{
        iframe[style*="webSDKOnLoad"]{
            right:50% !important;bottom:8.7857vw !important;width:4.0000vw !important;height:4.0000vw !important;margin-right:-44.7143vw;opacity:0;z-index:0 !important;
        }
    }
    div{
        > div[role="presentation"]{
            > div{
                bottom:12.1429vw !important;
            }
            iframe{
                &+iframe{
                    display: none;
                }
            }
        }
    }
    .chat_msgbox {bottom:12.1429vw !important;}
    iframe[title='메시징 창을 시작하는 버튼'] {display:none;}
    iframe[title='읽지 않은 메시지 수'] {display:none;}

    .chat-bot{
        position: fixed; right: 2.8571vw; bottom: 1.2143vw; z-index: 90;
        &.is-btn-fix{
            bottom: 9.7857vw;
        }
        a{
            display: block; width: 7.7143vw; height: 7.7143vw; @include backgroundImg('/assets/images/icon/ico-floating-chatbot.svg', 0, 100%); font-size: 0;
        }
    }

    .survey-wrap{
        position: fixed; right: 2.8571vw !important; z-index: 90 !important; width: 7.7143vw; height: 7.7143vw;
        &.is-btn-fix{
            bottom: 18.7857vw !important;
        }
    }
    .survey01{
        @include backgroundImg('/assets/images/icon/img_survey01.svg', 0, 100%); font-size: 0;
        .survey-btn01{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey02{
        @include backgroundImg('/assets/images/icon/img_survey02.svg', 0, 100%); font-size: 0;
        .survey-btn02{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey03{
        @include backgroundImg('/assets/images/icon/img_survey03.svg', 0, 100%); font-size: 0;
        .survey-btn03{
            width: 100%; height: 100%; font-size: 0;
        }
    }
    .survey04{
        @include backgroundImg('/assets/images/icon/img_survey04.svg', 0, 100%); font-size: 0;
        .survey-btn04{
            width: 100%; height: 100%; font-size: 0;
        }
    }

    .main{
        .chat-bot{
            position: fixed; right: 2.8571vw; bottom: 10.3571vw; z-index: 90;
            a{
                display: block; width: 7.7143vw; height: 7.7143vw; @include backgroundImg('/assets/images/icon/ico-floating-chatbot.svg', 0, 100%); font-size: 0;
            }
        }
        .survey-wrap{
            position: fixed; right: 2.8571vw !important; bottom: 19.2857vw !important; z-index: 90 !important; width: 7.7143vw; height: 7.7143vw;
        }
        .survey01{
            @include backgroundImg('/assets/images/icon/img_survey01.svg', 0, 100%); font-size: 0;
            .survey-btn01{
                width: 100%; height: 100%; font-size: 0;
            }
        }
        .survey02{
            @include backgroundImg('/assets/images/icon/img_survey02.svg', 0, 100%); font-size: 0;
            .survey-btn02{
                width: 100%; height: 100%; font-size: 0;
            }
        }
        .survey03{
            @include backgroundImg('/assets/images/icon/img_survey03.svg', 0, 100%); font-size: 0;
            .survey-btn03{
                width: 100%; height: 100%; font-size: 0;
            }
        }
        .survey04{
            @include backgroundImg('/assets/images/icon/img_survey04.svg', 0, 100%); font-size: 0;
            .survey-btn04{
                width: 100%; height: 100%; font-size: 0;
            }
        }

    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .container{
        padding: 60px 0 70px 0;     
        .sub-conts{
            padding: 30px 20px 0 20px;
        }
        &.pb-big{padding-bottom: 120px !important;}
    }
    .page-tit{
        margin-bottom: 40px;
        p{margin-top: 5px;}
        .bang-notice{
            margin-top: 5px;
        }
        & + .bang-notice{margin: 5px 0 40px;}
        .notification{margin-top: 5px;}
    }
    .full-popup-conts{
        .page-tit{margin-bottom: 20px;}
    }
    .survey-wrap{
        right: 20px !important; z-index: 90 !important; width: 54px; height: 54px;
    }
    .chat-bot, .main .chat-bot{
        right: 20px; z-index: 90; width: 54px; height: 54px;
         a.open, a.close  {
            width: 54px; height: 54px;
        }
    }
    .wide-box{
        margin-left: -20px;
    }
    .chat-msgbox {bottom: 75px!important; right: 82px!important; height:480px!important;}
    .chat-msgbox.box-close {height:0 !important}
}