@charset "UTF-8";

.goodok{
    &.container{
        .sub-conts{
            padding: 0 5.5556vw 0 5.5556vw;;
        }
    }
    .discount-pay{
        @include mainTxt; color:$purple;
    }
    .info{
        .img{
            width:100vw; margin-left:-5.5556vw; max-height:72.2222vw; overflow:hidden;
            & > img{
                width:100%; display: block;
            }
        }
        .desc{
            .chip-wrap{
                margin-top: 5.5556vw;
                span.chip{
                    &:first-child{
                        background-color: #b728bc;
                    }
                    &:nth-child(2){
                        background-color: #5257a1;
                    }
                }
            }
            dl{
                margin-top: 2.7778vw; display: flex; justify-content: space-between; flex-wrap: wrap; align-items: flex-start;
                dt,dd{
                     &:not(:first-of-type){
                        margin-top: 1.3889vw; @include smallTxt;
                    }
                }
                dt{
                    width:65%; @include mainTxt;

                }
                dd{
                    width:35%; text-align: right;
                }
            }
        }

    }
    .about-service{
        margin-top: 11.1111vw;
        .box-btn{
            margin-top: 5.5556vw;
        }
    }
    .accordian{
        .acc-trigger{
            @include subContsTitle; margin-bottom: 5.5556vw;
            .unit-tit{margin-bottom: 0;}
        }
        .acc-conts{
            background: $white; padding: 0;
        }
    }
    .link-underline{
        color: $purple; text-decoration: underline;
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
.goodok{
    &.container{
        .sub-conts{
            padding: 0 2.8571vw 0 2.8571vw;;
        }
    }
    .discount-pay{
    color:$purple;
    }
    .info{
        .img{
            width:51.4286vw; margin-left:-2.8571vw; max-height:37.1429vw; overflow:hidden;
            & > img{
                width:100%; display: block;
            }
        }
        .desc{
            .chip-wrap{
                margin-top: 2.8571vw;
                span.chip{
                    &:first-child{
                        background-color: #b728bc;
                    }
                    &:nth-child(2){
                        background-color: #5257a1;
                    }
                }
            }
            dl{
                margin-top: 1.4286vw; display: flex; justify-content: space-between; flex-wrap: wrap; align-items: flex-start;
                dt,dd{
                     &:not(:first-of-type){
                        margin-top: 0.7143vw;
                    }
                }
                dt{
                    width:65%;

                }
                dd{
                    width:35%; text-align: right;
                }
            }
        }

    }
    .about-service{
        margin-top: 5.7143vw;
        .box-btn{
            margin-top: 2.8571vw;
        }
    }
    .accordian{
        .acc-trigger{
            margin-bottom: 2.8571vw;
            .unit-tit{margin-bottom: 0;}
        }
        .acc-conts{
            background: $white; padding: 0;
        }
    }
    .link-underline{
        color: $purple; text-decoration: underline;
    }
}

}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .goodok{
        &.container{
            .sub-conts{padding: 0 20px 0 20px;}
        }
        .discount-pay{color: #662d91;}
        .info{
            .img{width: 100vw; margin-left: -20px;}
            .desc{
                .chip-wrap{
                    margin-top: 20px;
                }
                dl{
                    margin-top: 10px;
                    dd{
                        &:not(:first-of-type){margin-top: 5px;}
                    }
                    dt{
                        &:not(:first-of-type){margin-top: 5px;}
                    }
                }
            }
        }
        .about-service{
            margin-top: 40px;
            .box-btn{margin-top: 20px;}
        }
        .accordian{
            .acc-trigger{margin-bottom: 20px;}
        }
    }
}