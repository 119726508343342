@charset "UTF-8";

// Typography 정의

/* 메인 비주얼 타이틀 (H1)
Bold / Font 62px / #191919 / 자간 -1.24px / Line-Height 82px */
@mixin mainVisualTxt {
    @include text(700,8.6111vw,1.32,$black, -0.1722vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(700,4.4286vw,1.32,$black, -0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(700,30px,1.32,$black, -0.5px);
    }
}
/* 컨텐츠 페이지 타이틀 (H2)
Bold / Font 52px / #191919 / 자간 -1.56px / Line-Height 60px */
@mixin mainContsTitle {
    @include text(700,7.2222vw,1.15,$black,-0.2167vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(700,3.7143vw,1.15,$black,-0.1429vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(700,26px,1.15,$black,-0.5px);
    }
}

/* 헤더 GNB 타이틀 & 서브 컨텐츠 타이틀 (H3)
Bold / Font 40px / #191919 / 자간 -0.8px / Line-Height 48px */
@mixin contsTitle {
    @include text(700,5.5556vw,1.2,$black,-0.1111vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(700,2.8571vw,1.2,$black,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(700,20px,1.2,$black,-0.5px);
    }
}
/* 타이틀 & 바텀시트 타이틀 (H4)
SemiBold / Font 36px / #191919 / 자간 -0.72px / Line-Height 42px */
@mixin subContsTitle {
    @include text(600,5vw,1.16,$black,-0.1vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(600,2.5714vw,1.16,$black,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(600,18px,1.16,$black,-0.5px);
    }
}
/* 본문 강조
SemiBold / Font 32px / #191919 / 자간 -0.64px / Line-Height 40px */
@mixin mainTxt {
    @include text(600,4.4444vw,1.25,$black,-0.0889vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(600,2.2857vw,1.25,$black,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(600,16px,1.25,$black,-0.5px);
    }
}
/* 본문(기본)
Regular / Font 32px / #666666 / 자간 -0.64px / Line-Height 40px */
@mixin mainEmphasize {
    @include text(400,4.4444vw,1.25,$gray05,-0.0889vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(400,2.2857vw,1.25,$gray05,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(600,16px,1.25,$gray05,-0.5px);
    }
}
/* 최소 강조-Type01
Bold / Font 30px / #191919 / 자간 -0.6px / Line-Height 40px */
@mixin normalTxt {
    @include text(700,4.1667vw,1.3,$black,-0.0833vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(700,2.1429vw,1.3,$black,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(700,15px,1.3,$black,-0.5px);
    }
}
/* 최소 강조-Type02
SemiBold / Font 30px / #191919 / 자간 -0.6px / Line-Height 40px */
@mixin defaultTxt {
    @include text(600,4.1667vw,1.3,$black,-0.0833vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(600,2.1429vw,1.3,$black,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(600,15px,1.3,$black,-0.5px);
    }
}
/* 최소  본문
Regular / Font 30px / #666666 / 자간 -0.6px / Line-Height 48px */
@mixin smallTxt {
    @include text(400,4.1667vw,1.3,$gray05,-0.0833vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(400,2.1429vw,1.3,$gray05,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(400,15px,1.3,$gray05,-0.5px);
    }
}
/* 최소  본문(하위 레벨)
Regular / Font 30px / #959595 / 자간 -0.6px / Line-Height 48px */
@mixin smallDepthTxt {
    @include text(400,4.1667vw,1.3,$gray04,-0.0833vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(400,2.1429vw,1.3,$gray04,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(400,15px,1.3,$gray04,-0.5px);
    }
}
/* 예외 (Chip에 사용되는 폰트)
Regular / Font 26px / #ffffff / 자간 -0.52px / Line-Height 36px */
@mixin chipTxt {
    @include text(400,3.6111vw,5vw,$white,-0.0722vw);
    @media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9){
        @include text(400,1.8571vw,2.5714vw,$white,-0.0714vw);
    }
    @media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
        @include text(400,13px,18px,$white,-0.5px);
    }
}


.main-visual-title{@include mainVisualTxt;}
.main-contents-title{@include mainContsTitle;}
.contents-title{@include contsTitle;}
.sub-contents-title{@include subContsTitle;}
.main-txt{@include mainTxt;}
.main-emphasize{@include mainEmphasize;}
.normal-txt{@include normalTxt;}
.default-txt{@include defaultTxt;}
.small-txt{@include smallTxt;}
.small-depth-txt{@include smallDepthTxt;}