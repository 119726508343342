// chips color class
.hotpink{background-color: $hotpink;}
.red{background-color: $red;}
.lightbrick{background-color: $lightbrick;}
.brick{background-color: $brick;}
.purple{background-color: $purple;}
.lightpurple{background-color: $lightpurple;}
.pinkpurple{background-color: $pinkpurple;}
.pink{background-color: $pink;}
.deeppink{background-color: $deeppink;}
.lightorange{background-color: $lightorange;}
.yellow{background-color: $yellow;}
.orange{background-color: $orange;}
.blue{background-color: $blue;}
.skyblue{background-color: $skyblue;}
.deepblue{background-color: $deepblue;}
.paleblue{background-color: $paleblue;}
.purpleblue{background-color: $purpleblue;}
.green{background-color: $green;}
.lightgreen{background-color: $lightgreen;}
.deepgreen{background-color: $deepgreen;}
.yellowgreen{background-color: $yellowgreen;}
.mint{background-color: $mint;}
.silver{background-color: $silver;}
.gold{background-color: $gold;}
.jade{background-color: $jade;}
.gray{background-color: $gray05;}
.thinorange{background-color: $thinorange;}
.highpurple{background-color: $highpurple;}
.indipink{background-color: $indipink;}

.txt-rd{color: $red !important;font-size:inherit;font-weight:inherit;line-height:inherit;}
.txt-vt{
      color: $purple !important;font-size:inherit;font-weight:inherit;line-height:inherit;
      &-b{color: $purple !important;font-size:inherit;font-weight:bold !important;line-height:inherit;}
}
.txt-bk{color: $black !important;font-size:inherit;font-weight:inherit;line-height:inherit;}
.txt-bk-b{color: $black !important;font-size:inherit;font-weight:bold !important;line-height:inherit;}
.txt-pnk{
      color:#ec008c !important;font-size:inherit;font-weight:inherit;line-height:inherit;
      &-b {color:#ec008c !important;font-size:inherit; line-height:inherit; font-weight:600;}
}
.txt-gray {color:$gray05 !important;}

.txt-bld {font-weight:bold !important;}

//text-align
.aling-center {text-align: center !important;}
.aling-left {text-align: left !important;}
.aling-right {text-align: right !important;}


//balck
.dspy-bk {display:block !important;}
.dspy-inlie-bk {display:inline-block !important;}

// margin
.mgn-tp5 {margin-top:0.6944vw !important;}
.mgn-tp10 {margin-top:1.3889vw !important;}
.mgn-tp15 {margin-top:2.0833vw !important;}
.mgn-tp20 {margin-top:2.7778vw !important;}
.mgn-tp30 {margin-top:4.1667vw !important;}
.mgn-tp40 {margin-top:5.5556vw !important;}

//padding
.pdg-tp5 {padding-top:0.6944vw !important;}
.pdg-tp10 {padding-top:1.3889vw !important;}
.pdg-tp15 {padding-top:2.0833vw !important;}
.pdg-tp20 {padding-top:2.7778vw !important;}
.pdg-tp30 {padding-top:4.1667vw !important;}
.pdg-tp40 {padding-top:5.5556vw !important;}

