@charset "UTF-8";
// extend

.extra{
    .discount-pay{
        @include mainTxt; color:$purple; display: block;
        & > span{
            @include mainEmphasize; margin-left: 1.3889vw;
        }
    }
    .acc-trigger {
        margin-bottom: 5.5556vw;
        .unit-tit{margin-bottom: 0;}
    }
    .info{
        border:0.2778vw solid $gray02; border-radius: 2.7778vw; padding:8.3333vw 5.5556vw;
        .chip-wrap{
            margin-bottom: 2.7778vw;
        }
        & > dl{
                dt{
                    @include defaultTxt;
                    &:first-of-type{
                        @include subContsTitle;
                    }
                    &:not(:first-of-type)
                    {margin-top: 4.1667vw;}
                }
            dd{@include smallTxt; margin-top: 1.3889vw;}
        }
        .flex-box{
            display: flex; justify-content: space-between; align-items: center; margin-top: 5.5556vw; flex-direction: row-reverse;
        }
        .icon-wrap{
            display: flex; gap: 2.7778vw;
            img{
                display: block; width: 11.1111vw; height: 11.1111vw;
            }
        }

    }
    .roaming-abroad {
        .info {
            margin-bottom:5.5556vw;
            .pay-bx {
                display: flex;justify-content: flex-end; align-items: center; margin-top:5.5556vw;
                .discount-pay {display:inline-block;margin-right:0.6944vw;}
            }
            .spc-bx {
                margin-top:5.5556vw;padding-top:5.5556vw;border-top:1px solid #ddd;
                ul{
                    li{
                        & + li {margin-top:1.3889vw;}
                        dl {
                            display: flex;align-items: center;
                            dt {width:40%;font-size: 4.1667vw;line-height: 1.33;letter-spacing: -0.0833vw;text-align: left;color: #666;}
                            dd {font-size: 4.1667vw;font-weight: 600;line-height: 1.6;letter-spacing: -0.0833vw;text-align: left;color: #191919;}
                        }
                    }
                }                
            }            
        }
        .accordian{
            .acc-trigger  {margin-bottom:1.3889vw;}
            .acc-conts {padding:0; background:none}
            .list{
                margin-top: 2.7778vw; border-top:0;
                li{
                    padding: 5.5556vw 4.1667vw; border-top:0.2778vw solid $gray02;
                    &:last-of-type{
                        border-bottom: 0.2778vw solid $gray02;
                    }
                    & > .default-txt{
                        margin-bottom: 4.1667vw; display: block;
                    }
                    & > dl{
                        display: flex; flex-wrap: wrap; gap:5.5556vw;
                        & + dl{
                            margin-top: 2.7778vw;
                        }
                        dt{
                            width:22.2222vw; @include smallTxt;
                        }
                        dd{
                            width:calc(100% - 27.7778vw); @include smallTxt;
                        }
                    }
                }
            }
       }
       .payment-detail-card-wrap{
            margin-top: 2.7778vw;
            li{
                display: flex; align-items: center; gap:5.5556vw;
                & > .img{
                    width:11.1111vw;
                    img{
                        display: block;width:100%;
                    }
                }
                & > .detail{
                    width:calc(100% - 16.6667vw);
                    strong {
                        @include defaultTxt;
                    }
                    .box-btn{
                        margin-top: 4.1667vw;
                    }
                }
            }
        }
    }

    .about-service{
        margin-top: 11.1111vw;
        .unit-sec-tit{
            .list{
                margin-top: 2.7778vw; border-top:0;
                li{
                    padding: 5.5556vw 4.1667vw; border-top:0.2778vw solid $gray02;
                    &:last-of-type{
                        border-bottom: 0.2778vw solid $gray02;
                    }
                    & > .default-txt{
                        margin-bottom: 4.1667vw; display: block;
                    }
                    & > dl{
                        display: flex; flex-wrap: wrap; gap:5.5556vw;
                        & + dl{
                            margin-top: 2.7778vw;
                        }
                        dt{
                            width:22.2222vw; @include smallTxt;
                        }
                        dd{
                            width:calc(100% - 27.7778vw); @include smallTxt;
                        }
                    }
                }
            }
            // 안내용 이미지
            .info-img {
                display: block;
            }
            .border-img{
                margin-top: 2.7778vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;overflow: hidden;
            }
            // 아이콘 이미지
            .icon{
                display: inline-block; width: 5.5556vw; height: 5.5556vw; margin: 0 1.3889vw;vertical-align: middle;
            }
        }
        .unit-sm{
            margin-top: 2.7778vw;
            strong{
                @include mainTxt;
            }
        }
        .accordian{
            .acc-trigger{
                @include subContsTitle;
            }
            .acc-conts{
                background: $white; padding: 0;
            }
        }
        .payment-detail-card-wrap{
            margin-top: 2.7778vw;
            li{
                display: flex; align-items: center; gap:5.5556vw;
                & > .img{
                    width:11.1111vw;
                    img{
                        display: block;width:100%;
                    }
                }
                & > .detail{
                    width:calc(100% - 16.6667vw);
                    strong {
                        @include defaultTxt;
                    }
                    .box-btn{
                        margin-top: 4.1667vw;
                    }
                }
            }
        }
        .table-list-wrap{
            margin-top: 2.7778vw;
            .table-list {
                tr.now{
                    td{
                        color:$purple;
                    }
                }
            }
            &.table-scroll{
                .table-list {
                    min-width:138.8889vw;
                }
            }
        }
        .thumb-list{
            & > li{
                .tit{
                    @include mainTxt;
                }
                .movie{
                    position: relative; width: 100%; height: 50.6944vw;
                    img {
                        display: block;
                        @include imgSize; object-fit: cover;
                    }
                }
                .notification{
                    margin-top: 1.3889vw;
                }
                & + li{
                    margin-top: 5.5556vw;
                }
            }
        }
        .slick-box{margin-top: 2.7778vw;}
        .step-slick{
            margin-top: 2.7778vw; padding-bottom: 11.9444vw;
            .step{
                display: block; margin-top: 2.7778vw; @include defaultTxt;
            }
            .slick-slide{
                .slide-thumb{background-color: $bg;}
                img{max-width: 48.0556vw; margin: 0 auto;}
            }
            & + h3{margin-top: 13.8889vw !important;}
        }
        .howto-use{
            margin-top: 8.3333vw;
            li{
                padding: 5.5556vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;
                &+li{
                    margin-top: 4.1667vw;
                }
                dl{
                    dt{
                        margin-bottom: 2.7778vw; @include mainTxt;
                    }
                    dd{
                        @include smallTxt;
                    }
                }
            }
        }
        .service-list{
            display: flex; flex-direction: column; gap: 4.1667vw; margin-top: 8.3333vw;
            & > li{
                padding: 5.5556vw; border: 0.2778vw solid $gray02; border-radius: 2.7778vw;
                h3 {
                    display: flex; justify-content: space-between;
                    span{@include fontInherit;}
                }
                .content{
                    margin-top: 2.7778vw;
                    strong{@include defaultTxt; font-weight: 400;}
                }
            }
        }
    }
    .app-wrap{
        border: 0.1389vw solid #ddd; padding: 2.7778vw 5.5556vw; border-radius: 1.3889vw; text-align: center; margin-top: 2.7778vw;
        p{margin: 1.3889vw 0; word-break: keep-all;}
        div{
            & + div{border-top: 0.1389vw solid #ddd; padding-top: 2.7778vw; margin-top: 2.7778vw;}
        }
        ul{
            display: flex; justify-content: space-evenly; margin-top: 2.7778vw;
            li{
                p{font-size: 3.3333vw;}
            }
        }
    }
}

@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9)  {
.extra{
    .discount-pay{
        color:$purple; display: block;
        & > span{
           margin-left: 0.7143vw;
        }
    }
    .acc-trigger {
        margin-bottom: 2.8571vw;
        .unit-tit{margin-bottom: 0;}
    }
    .info{
        border:0.1429vw solid $gray02; border-radius: 1.4286vw; padding:4.2857vw 2.8571vw;
        .chip-wrap{
            margin-bottom: 1.4286vw;
        }
        & > dl{
                dt{
                    &:not(:first-of-type)
                    {margin-top: 2.1429vw;}
                }
            dd{ margin-top: 0.7143vw;}
        }
        .flex-box{
            display: flex; justify-content: space-between; align-items: center; margin-top: 2.8571vw; flex-direction: row-reverse;
        }
        .icon-wrap{
            display: flex; gap: 1.4286vw;
            img{
                display: block; width: 5.7143vw; height: 5.7143vw;
            }
        }

    }
    .about-service{
        margin-top: 5.7143vw;
        .unit-sec-tit{
            .list{
                margin-top: 1.4286vw; border-top:0;
                li{
                    padding: 2.8571vw 2.1429vw; border-top:0.1429vw solid $gray02;
                    &:last-of-type{
                        border-bottom: 0.1429vw solid $gray02;
                    }
                    & > .default-txt{
                        margin-bottom: 2.1429vw; display: block;
                    }
                    & > dl{
                        display: flex; flex-wrap: wrap; gap:2.8571vw;
                        & + dl{
                            margin-top: 1.4286vw;
                        }
                        dt{
                            width:11.4286vw;
                        }
                        dd{
                            width:calc(100% - 14.2857vw);
                        }
                    }
                }
            }
            // 안내용 이미지
            .info-img {
                display: block;
            }
            .border-img{
                margin-top: 1.4286vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;overflow: hidden;
            }
            // 아이콘 이미지
            .icon{
                display: inline-block; width: 2.8571vw; height: 2.8571vw; margin: 0 0.7143vw;vertical-align: middle;
            }
        }
        .unit-sm{
            margin-top: 1.4286vw;
        }
        .accordian{
            .acc-conts{
                background: $white; padding: 0;
            }
        }
        .payment-detail-card-wrap{
            margin-top: 1.4286vw;
            li{
                display: flex; align-items: center; gap:2.8571vw;
                & > .img{
                    width:5.7143vw;
                    img{
                        display: block;width:100%;
                    }
                }
                & > .detail{
                    width:calc(100% - 8.5714vw);
                    .box-btn{
                        margin-top: 2.1429vw;
                    }
                }
            }
        }
        .table-list-wrap{
            margin-top: 1.4286vw;
            .table-list {
                tr.now{
                    td{
                        color:$purple;
                    }
                }
            }
            &.table-scroll{
                .table-list {
                    min-width:71.4286vw;
                }
            }
        }
        .thumb-list{
            & > li{
                .movie{
                    position: relative; width: 100%; height: 26.0714vw;
                    img {
                        display: block;
                         object-fit: cover;
                    }
                }
                .notification{
                    margin-top: 0.7143vw;
                }
                & + li{
                    margin-top: 2.8571vw;
                }
            }
        }
        .slick-box{margin-top: 1.4286vw;}
        .step-slick{
            margin-top: 1.4286vw; padding-bottom: 6.1429vw;
            .step{
                display: block; margin-top: 1.4286vw;
            }
            .slick-slide{
                .slide-thumb{background-color: $bg;}
                img{max-width: 24.7143vw; margin: 0 auto;}
            }
            & + h3{margin-top: 7.1429vw !important;}
        }
        .howto-use{
            margin-top: 4.2857vw;
            li{
                padding: 2.8571vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;
                &+li{
                    margin-top: 2.1429vw;
                }
                dl{
                    dt{
                        margin-bottom: 1.4286vw;
                    }

                }
            }
        }
        .service-list{
            display: flex; flex-direction: column; gap: 2.1429vw; margin-top: 4.2857vw;
            & > li{
                padding: 2.8571vw; border: 0.1429vw solid $gray02; border-radius: 1.4286vw;
                h3 {
                    display: flex; justify-content: space-between;
                }
                .content{
                    margin-top: 1.4286vw;
                    strong{ font-weight: 400;}
                }
            }
        }
    }
}
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응
    .extra{
        .info{
            border-width: 1px; border-radius: 8px; padding: 30px 20px;
            .flex-box{margin-top: 20px;}
            & > dl{
                dd{margin-top: 5px;}
                dt{
                    &:not(:first-of-type){margin-top: 15px;}
                }
            }
            .chip-wrap{margin-bottom: 10px;}
            .icon-wrap{
                img{width: 40px; height: 40px;}
            }
        }
        .discount-pay{color: #662d91;}
        .about-service{margin-top: 40px;}
        .acc-trigger{margin-bottom: 20px;}
        .about-service{
            .table-list-wrap{margin-top: 10px;}
            .payment-detail-card-wrap{
                margin-top: 10px;
                & > ul{
                    & > li{
                        &:not(:first-of-type){border-width: 1px; margin-top: 20px; padding-top: 20px;}
                    }
                }
                li{
                    gap: 20px;
                    > .img{width: 40px;}
                    > .detail{
                        .box-btn{margin-top: 15px;}
                    }
                }
            }
            .unit-sec-tit{
                .list{
                    li{
                        padding: 20px 15px; border-width: 1px;
                        &:last-of-type{border-width: 1px;}
                    }
                }
                .border-img{border-radius: 8px; border-width: 1px; margin-top: 10px;}
            }
            .howto-use{
                margin-top: 40px;
                li{
                    padding: 20px; border-width: 1px; border-radius: 8px;
                    & + li{margin-top: 15px;}
                    dl{
                        dt{margin-bottom: 10px;}
                    }
                }
            }
            .thumb-list{
                & > li{
                    .notification{margin-top: 5px;}
                    & + li{margin-top: 20px;}
                }
            }
            .service-list{
                gap: 10px; margin-top: 30px;
                & > li{
                    padding: 20px; border-width: 1px; border-radius: 8px;
                    .content{
                        margin-top: 10px;
                        strong{font-weight: 400;}
                    }
                }
            }
            .step-slick{
                padding-bottom: 40px; margin-top: 10px;
                .slick-slide{
                    padding-bottom: 15px; margin-top: 10px;
                    img{max-width: 175px;}
                }
                & + h3{margin-top: 50px !important;}
            }
        }
    }
}