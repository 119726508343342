.main{
    &.white{
        .container.main-cnt {
            .main-conts {
                .sec-main-visual {
                    .slick-control {
                        .slick-cunt {background:#fff; opacity: 0.4;}
                        span {color:#000;}
                        .slick-btn .slick-pause {background:url('/assets/images/main/main-visual-pause02.png') no-repeat 50%; background-size: 6.3889vw; }
                        .slick-btn .slick-play {background:url('/assets/images/main/main-visual-play02.png') no-repeat 50%; background-size: 6.3889vw; }
                        .slick-cunt.more-box {
                            .btn-more {color:#000}                        
                        }
                    }
                }
            }
        }
    } 

    .top-banner {
       overflow:hidden; width:100%; height: 16.6667vw; 
        &.show {display:block;}
        &.hide {display:none;}        
        .banner-box {width:100%;position:relative; display: flex; height: 16.6667vw;padding-left:5.5556vw;align-items: center;}
        .banner-inner {width:100%;}
        .strip-banner a {display:block;width:100%; height:100%; line-height: 1.36;font-size:3.8889vw}
        .strip-banner .img {position:absolute; right:16.6667vw; bottom:0; height:100%;}
        .strip-banner .slick-slide img {width:auto;}
        .strip-banner .btn-close {position: absolute; right:2.5556vw; top:50%; width:6.9444vw;height:6.9444vw; background:url('/assets/images/main/banner_close.png') no-repeat 50%;background-size: 6.9444vw; transform: translateY(-50%);}
    }

    // 배너 모아보기
    .container.main-cnt .main-conts .main-sales {
         .banner-more {
            visibility: hidden; position:fixed; top:0; bottom:0; margin:0 !important; padding-top:20.1389vw; background: rgba(25, 25, 25, 0.7); z-index: 200;
            &.on {
                visibility: visible;
                .more-wrp { transform: translateY(0);opacity: 1; transition: all 1s;}
                .more-close {opacity: 1; transition: all 1.3s;}
            }
            .more-wrp {
                overflow: auto; height:100%; transform: translateY(-6.9444vw);opacity: 0;
                .more-list {
                   padding:0 12.5vw;
                    img {vertical-align: top;}
                }
            }
            .more-close {position:absolute;left:50%;top:8.8889vw; width:8.3333vw; height:8.3333vw;  transform: translateX(-50%); border:0.1389vw solid #fff;border-radius: 50%; background: url('/assets/images/icon/ico-close-wh.svg') no-repeat 50%; background-size:3.8889vw; opacity: 0; font-size:0;}
        }
       .slick-control .slick-cunt.more-box {
            width:26.8056vw;padding-left:2.7778vw; text-align: left;
            .btn-more {display:inline-block;font-size: 3.0556vw; font-weight: 500; line-height: 6.3889vw;letter-spacing: -0.0611vw; text-align: left; vertical-align: middle; color: #fff; z-index: 10;} 
        }        
    }

    .header-wrp {
        position:relative; padding-bottom:16.1111vw;
        #header {
            position:absolute;
            &.on {
                border-bottom:0.2778vw solid #ddd;z-index:101;background-color:#fff;
                .logo{display: inline-block; width: 31.3889vw; height: 11.1111vw; box-sizing: border-box; font-size: 0; background: url('/assets/images/common/header-logo.png') no-repeat 0 0;    background-position: 0;background-size: 100%;;}
                .btn-menu{
                    display:block; position: relative; width:11.1111vw;height:11.1111vw; background: none; font-size: 0; z-index: 10; flex-shrink: 0;
                    span{
                        position: absolute;
                        left: 50%;
                        width: 5.5556vw;
                        height: 0.5556vw;
                        border-radius: 0.2778vw;
                        background-color: $black;
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(-50%);
                        transform-origin: 0% 50%;
                    }
                    &.on{
                        span {
                            background-color: $black !important;
                            &:nth-of-type(1) {
                                transform: rotate(45deg);
                            }
                            &:nth-of-type(2) {
                                transform: translateX(100%);
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:nth-of-type(3) {
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
                
            }
        }
        
        #header.gnb-fixd {position:fixed;top:0;}
       
    }
    &.show-strip .header-wrp #header .top-util.open {position: fixed; right:10px;top:10px; z-index:100}

    #header{
        border-bottom: 0;
        .top-util{
            width: auto;
        }
    }
    .personal-message{
        position: fixed; left: 50%; top: 20vw; transform: translateX(-50%); z-index: 15; width: 88.8889vw;
        li{
            position: relative;
            &+li{
                margin-top: 2.7778vw;
            }
            a{
                display: block; padding: 3.0556vw 4.1667vw; background-color: rgba(0,0,0, 0.7); border-radius: 5.5556vw; @include smallTxt; color: $white;
            }
            button{
                position: absolute; right: 2.7778vw; top: 50%; transform: translateY(-50%); width: 5.5556vw; height: 5.5556vw; @include backgroundImg('/assets/images/icon/ico-delete.svg', 0, 100%); font-size: 0;
            }
        }
    }

    .container{
        padding-top: 0;
        &.my{
            /*padding-top: 16.6667vw;*/
            padding-top: 0;
        }
        &.main-cnt {
            .main-conts {
                .sec-main-visual .slick-control {display: flex; width:auto; align-items: center; bottom: 6.9444vw; left:auto; right: 5.5556vw; background: none;}
                .sec-main-visual .slick-control .slick-cunt { width:15.2778vw; height:6.3889vw; line-height: 6.3889vw;  border-radius: 13.8889vw; background:#000;opacity:0.4; transition: all .2s linear}
                .sec-main-visual .slick-control span {font-size:3.3333vw;}
                .sec-main-visual .slick-control .end-page {opacity: 0.6;}
                .sec-main-visual .slick-control .lne {width:0.2778vw}
                .sec-main-visual .slick-control .slick-btn {margin-left:0.6944vw}
                .sec-main-visual .slick-control .slick-btn button {display:none; width:6.3889vw; height:6.3889vw; font-size:0; transition: all .2s linear}
                .sec-main-visual .slick-control .slick-btn button.on {display:block;}
                .sec-main-visual .slick-control .slick-btn .slick-pause {background: url('/assets/images/main/main-visual-pause.png') no-repeat 50%; background-size: 6.3889vw;}
                .sec-main-visual .slick-control .slick-btn .slick-play {background: url('/assets/images/main/main-visual-play.png') no-repeat 50%; background-size: 6.3889vw;}
                .main-sales .main-kv {
                    margin:0;
                    & + .main-plan {margin-top:0;}
                }
                .main-sales>div {margin-top:16.6667vw;}
            }
        }
        .main-conts{
            .main-sales{
                > div{
                  //  overflow: hidden;
                   // height:177.7778vw;
                    margin-bottom: 0;
                    > h2{
                       margin-bottom: 5.5556vw;  @include mainContsTitle;font-size:5.8333vw;
                    }
                    > .box-btn{
                        margin-top: 8.3333vw; text-align: center;
                        .btn-text-arrow{
                            font-size: 4.4444vw;
                        }
                    }
                }
                .main-event{
                    padding: 0 5.5556vw;
                    a{
                        display: block; position: relative; width: 88.8889vw; min-height: 91.6667vw; padding: 8.3333vw 8.3333vw 50.8333vw 8.3333vw; background-color: $gray01;
                        dl{
                            margin-bottom: 5.5556vw;
                            dt{
                                margin-bottom: 10px; @include defaultTxt;
                            }
                            dd{
                                @include mainContsTitle;
                                strong{
                                    margin-right: 1.3889vw; @include fontInherit; color: $purple !important;
                                }
                            }
                        }
                        p{
                            @include smallTxt;
                            strong{
                                display: block; margin-bottom: 1.8056vw; @include defaultTxt;
                            }
                        }
                        span{
                            position: absolute; right: 5.5556vw; bottom: 8.3333vw; width: auto; height: 32.3611vw;
                            img{
                                width: 100%;height: 100%;object-fit: contain;
                            }
                        }
                    }
                }
                .event-time-box {
                    margin:0 2.7778vw; padding: 9.1667vw  7.7778vw  0; border-radius: 2.7778vw; background-color: #f2f4fc;
                    .txt-day {
                        margin-bottom:3.4722vw; text-align: center;
                        strong { font-size: 7.2222vw; font-weight: 700;  line-height: 0.92;letter-spacing: -0.1444vw; text-align: center; color: #7c40ce;}
                    }
                    p {
                        font-size: 3.6111vw; letter-spacing: -0.0722vw; text-align: center; color: #666;
                        strong {display:block;margin-bottom:1.3889vw; font-size: 5.5556vw; font-weight: 700; line-height: 1.1; letter-spacing: -0.1111vw; text-align: center; color: #191919;}
                    }
                    .img-obj {width:47.2222vw; margin:4.1667vw auto 0;}
                }

                .main-event-list{
                    padding: 0 5.5556vw; margin-bottom: 13.8889vw;
                    ul{
                        li{
                            & + li {
                                margin-top: 5.5556vw;
                            }
                        }
                    }
                }
                .main-plan{
                    padding: 15.2778vw 0 9.7222vw 0; background-color: #f2f4f5;
                    .plan-tab{
                        width: calc(100% - 5.5556vw); margin-left: 5.5556vw; padding-right: 5.5556vw; overflow-x: scroll; overflow-y: hidden; white-space: nowrap; font-size: 0; display: flex; align-items: center;
                        li{
                            display: inline-block;
                            & + li{
                                margin-left: 8.3333vw;
                            }
                            a{
                                display: block; padding-bottom: 1.3889vw; @include subContsTitle;
                                &.on{
                                    border-bottom: 0.5556vw solid $purple; color: $purple;
                                }
                            }
                        }
                        &::-webkit-scrollbar{
                                display: none;
                        }
                    }
                    .plan-tab-wrap{
                        .plan-tab-conts{
                            display: none;
                            &:first-child{
                                display: block;
                            }
                            > a{
                                display: block; margin: 11.1111vw 5.5556vw 8.3333vw 5.5556vw;
                                img{
                                    width: 100%;
                                }
                            }
                            .inner{
                                padding-bottom: 7.7778vw;
                                .slick-slide{
                                    max-height: none; padding: 8.3333vw 6.9444vw; background-color: $white; border-radius: 2.7778vw; transform: scale(.85);
                                    &.slick-active{
                                        transform: scale(1);
                                    }
                                    > strong{
                                        display: block;  margin-bottom: 1.3889vw; @include subContsTitle;
                                    }
                                    & > em{
                                        @include smallTxt; height: 9.7222vw; display: block;
                                    }
                                    & > p{
                                        height:15.2778vw;
                                        strong{
                                            display: block; @include contsTitle; 
                                        }
                                    }
                                    > dl{
                                        margin-top:1.3889vw; padding-left: 5.5556vw; background-repeat: no-repeat; background-position: 0 50%; background-size: 5.5556vw;
                                        &.call{
                                            background-image: url('/assets/images/icon/ico-call-fill.svg');
                                        }
                                        &.sms{
                                            background-image: url('/assets/images/icon/ico-sms-fill.svg');
                                        }
                                        dt{
                                            font-size: 0;
                                        }

                                    }
                                    .won{
                                        margin: 8.3333vw 0 6.9444vw 0;
                                        strong{
                                            display: block; @include mainContsTitle; color: $purple;
                                        }
                                        span{
                                            text-decoration: line-through;
                                        }
                                    }
                                    .gift{
                                        padding: 5.5556vw; background-color: #f8f4ff; border-radius: 1.9444vw;
                                        ul{
                                            li{
                                                color: $black;
                                                & + li{
                                                    margin-top: 1.3889vw;
                                                }
                                            }
                                        }
                                    }
                                }
                                .slick-dots{
                                    bottom: 0;
                                    li{
                                        button{
                                            background-color: $white;
                                        }
                                        &.slick-active{
                                            button{
                                                background-color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.renewal-plan {
                        margin-top:16.6667vw;padding:15.6667vw 0;background:#f8f9fc;
                        .box-tab {margin:0; padding:0;}
                        .plan-tab {
                            padding:2.6667vw 5.5556vw 0 0;gap:0;
                            li {
                                position: relative;
                                .new {position:absolute;right:-1.9444vw;top:-3px;width:6px;height:6px;border-radius: 100%;background:#ff0200; box-shadow: 0 0 0 ; animation:2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite  new-pulse; font-size:0;}
                                a {
                                    position:relative; padding: 0 0 1.9444vw 0;border-radius: 0;background:none; font-size: 4.4444vw;font-weight: 700;line-height: 1.19;letter-spacing: -0.0889vw;text-align: left;color: #666;
                                    &.on {color:#191919;border:0;}
                                    &.on::after  {content: '';position:absolute;left:0;bottom:0;width:100%;height:2px;background:#191919;}
                                }

                                @keyframes new-pulse {
                                    0% {
                                      box-shadow: 0 0 0 0 rgba(#ff0200, 0.4);
                                    }
                                    100% {
                                      box-shadow: 0 0 0 1.6667vw rgba(#ff0200, 0);
                                    }
                                  }
                            }
                        }
                        .plan-tab-wrap {
                            margin-top:5.5556vw;padding:0 5.5556vw;
                            .plan-list {
                                li {
                                    & + li {margin-top:4.1667vw;}
                                    a {
                                        display:block;padding: 5.5556vw; border-radius: 2.7778vw; box-shadow: 0.2778vw 0.6944vw 2.7778vw 0 rgba(220, 217, 238, 0.7); background-color: #fff;
                                        .plan-tit {display: block;font-size: 3.8889vw; line-height: 1.21; letter-spacing: -0.0778vw; text-align: left; color: #191919;}
                                        .plan-spc {display: block;margin-top:1.1111vw; font-size: 4.4444vw; font-weight: bold; line-height: 1.63; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                                        .plan-itm {
                                            display: flex; margin-top: 3.4722vw; align-items: center; gap: 8.3333vw;
                                            dl {
                                                display: flex;flex-wrap: nowrap;align-items: center;gap:2.7778vw;
                                                dt {font-size: 3.8889vw; line-height: 1.43;letter-spacing: -0.0778vw;text-align: left;color: #666;}
                                                dd {font-size: 3.8889vw;font-weight: bold;line-height: 1.43;letter-spacing: -0.0778vw;text-align: left;color: #191919;}
                                            }
                                        }
                                        .plan-pric {
                                            display: flex; justify-content: flex-end; align-items: baseline; gap:2.7778vw; margin-top:3.4722vw; 
                                            .cost-price {font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: right;color: #666; text-decoration: line-through;}
                                            .dc-price {
                                                font-size:3.6111vw; color:#662d91;font-weight:700;
                                                strong {font-size: 6.1111vw;font-weight: bold; line-height: 0.7; letter-spacing: -0.1222vw; color: #662d91;}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .box-btn {
                            margin-top:1.3889vw;padding:0 5.5556vw;
                            .btn {display:block;height: 11.1111vw;line-height:11.1111vw; border-radius: 27.7778vw; background-color: #e6e9f2; text-align: center;font-size: 3.8889vw;font-weight: 600; letter-spacing: -0.0778vw; color: #191919;}
                            .btn-custom {margin-bottom:4.1667vw;}
                        }
                    }
                }
                .main-phone{
                    padding: 0 5.5556vw;
                    .phone-slick{
                        padding-bottom: 7.7778vw; margin-bottom: 8.3333vw;
                        .slick-dots{
                            bottom: 0;
                        }
                    }
                    .kind{
                        ul{
                            display: flex; gap: 3.3333vw;
                            li{
                                flex: 1;
                            }
                        }
                    }
                    &.renewal-phone {
                         margin:15.6667vw 0; padding:0;
                        .box-tab {margin:0; padding:0;}
                        .tab-btn-wrap{
                            overflow-x: scroll; overflow-y: hidden; width: calc(100% - 5.5556vw); margin-left: 5.5556vw; padding:2.6667vw 5.5556vw 0 0;  white-space: nowrap; font-size: 0; display: flex; align-items: center;gap:0;
                            li{
                                position:relative; display: inline-block;
                                .new {position:absolute;right:-1.9444vw;top:-3px;width:6px;height:6px;border-radius: 100%;background:#ff0200; box-shadow: 0 0 0 ; animation:2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite  new-pulse; font-size:0;}
                                & + li{
                                    margin-left: 6.25vw;
                                }
                                a{
                                    position:relative; padding: 0 0 1.9444vw 0;border-radius: 0;background:none; font-size: 4.4444vw;font-weight: 700;line-height: 1.19;letter-spacing: -0.0889vw;text-align: left;color: #666;
                                    &.on {color:#191919;border:0;}
                                    &.on::after  {content: '';position:absolute;left:0;bottom:0;width:100%;height:2px;background:#191919;}
                                }
                            }
                            &::-webkit-scrollbar{
                                    display: none;
                            }
                        }
                        .tab-contents-wrap {
                            margin-top:5.5556vw; padding:0 5.5556vw;
                            .phoe-list {
                                li {
                                    & + li {margin-top:4.1667vw;}
                                    a {display: block; padding: 5.5556vw; border-radius: 2.7778vw;box-shadow: 0.2778vw 0.6944vw 2.7778vw 0 rgba(0, 0, 0, 0.1);border: solid 0.1389vw #eff1f5;background-color: #fff;}
                                    .phone-info {
                                        display:flex;gap:3.3333vw;
                                        .phone-img {
                                            width:29.1667vw; 
                                            img {width:100%;}
                                        }
                                        .phone-spc {
                                            width:44.4444vw;
                                            .spc-tit {display: block; font-size: 4.4444vw;font-weight: bold;line-height: 1.19;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                                            .spc-pric {
                                                display:block; margin-top:5.5556vw;font-size: 3.6111vw; font-weight: bold;line-height: 0.7;letter-spacing: -0.1222vw;text-align: right;color: #662d91; 
                                                strong {margin-left:0.6944vw; font-size:6.1111vw;font-weight: bold;color: #662d91;}
                                            }
                                            .itm-spc {display: block;margin-top:1.3889vw;  font-size: 3.6111vw;line-height: 1.54;letter-spacing: -0.0722vw;text-align: left;color: #666;}
                                            .pric {
                                                display: flex;align-items: center;justify-content: space-between; margin-top: 2.7778vw;
                                                span {font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: left;color: #191919;}
                                                strong {font-size: 3.6111vw;font-weight: bold;line-height: 1.19;letter-spacing: -0.0722vw;text-align: right;color: #191919;}
                                            }
                                        }
                                    }
                                }
                            }
                           
                        }
                        .phone-view {
                            display: flex; gap:4.1667vw; margin-top:4.1667vw; padding:0 5.5556vw;                          
                        }
                        .box-btn {
                            margin-top:1.3889vw;padding:0 5.5556vw;
                            .btn {display:block;height: 11.1111vw;line-height:11.1111vw; border-radius: 27.7778vw; background-color: #e6e9f2; text-align: center;font-size: 3.8889vw;font-weight: 600; letter-spacing: -0.0778vw; color: #191919;}
                        }
                    }
                }
                .main-subscribe{
                    h2{
                        padding: 0 5.5556vw;
                        letter-spacing: -0.2888vw;
                    }
                    .slick-slider{
                        padding-bottom: 7.7778vw;
                        .slick-slide{
                            max-height: none; transform: scale(1); margin-right: 4.1667vw;
                        }
                        .slick-dots{
                            bottom: 0;
                        }
                    }

                    .tite { font-size: 5.8333vw; font-weight: bold;line-height: 1.19;letter-spacing: -0.1167vw;text-align: left;color: #191919;}
                    .subscription-list {
                        overflow: hidden; width: 100%;height: 34.7222vw; padding-top:6.9444vw; 
                        .subscription-box {
                            display: flex;column-gap: 4.1667vw;width: 100%;
                            .marquee2 {margin-top:-2.0833vw;}
                            .mq {
                                position:relative; flex-shrink: 0;width: 51.9444vw;height: 27.7778vw;
                                .bubble {
                                    position: absolute;top:-3.4722vw; right:1.3889vw; min-width: 13.8889vw;padding: 1.25vw 2.7778vw; border-radius: 8.3333vw;background: #7c40ce; animation:bubble 3.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
                                    p {font-size: 2.2222vw;line-height: 1.19;letter-spacing: -0.0444vw;text-align: center;color: #fff;;}
                                    &:after{content: '';position: absolute;border-style: solid;border-width: 1.6667vw 1.3889vw 0;border-color: #7c40ce transparent;display: block;width: 0;bottom: -0.9722vw;right:4.1667vw;}
                                }
                            }
                        }                     
                    }

                    .box-btn {
                        margin-top:4.1667vw;padding:0 5.5556vw;
                        .btn {display:block;height: 11.1111vw;line-height:11.1111vw; border-radius: 27.7778vw; background-color: #e6e9f2; text-align: center;font-size: 3.8889vw;font-weight: 600; letter-spacing: -0.0778vw; color: #191919;}
                    }
                                        
                    @keyframes bubble {
                        0% { 
                            top:-3.4722vw;
                        }
                        50% { 
                            top:-4.8611vw;
                        }
                        100% { 
                            top:-3.4722vw;
                        }
                    }
                    
                }
                .main-after{
                    padding: 0 5.5556vw;
                    .box-btn {
                        margin-top:4.1667vw;
                        .btn {display:block;height: 11.1111vw;line-height:11.1111vw; border-radius: 27.7778vw; background-color: #e6e9f2; text-align: center;font-size: 3.8889vw;font-weight: 600; letter-spacing: -0.0778vw; color: #191919;}
                    }
                }
                .main-inquiry{
                    padding: 0 5.5556vw;
                    ul{
                        margin-top: 11.1111vw;
                        li{
                            position: relative; min-height: 16.6667vw;
                            & + li{
                                margin-top: 5.5556vw;
                            }
                            &:before{
                                content: ''; position: absolute; left: 0; top: 0; width: 16.6667vw; height: 16.6667vw; border-radius: 50%; background-position: 50%; background-size: 11.1111vw; background-color: $gray06; background-repeat: no-repeat;
                            }
                            &:nth-child(1){
                                &:before{
                                    background-image: url('/assets/images/main/ico-main01.svg');
                                }
                            }
                            &:nth-child(2){
                                &:before{
                                    background-image: url('/assets/images/main/ico-main02.svg');
                                }
                            }
                            &:nth-child(3){
                                &:before{
                                    background-image: url('/assets/images/main/ico-main03.svg');
                                }
                            }
                            &:nth-child(4){
                                &:before{
                                    background-image: url('/assets/images/main/ico-main04.svg');
                                }
                            }
                            &.q-cha {
                                &:before{
                                    background-image: url('/assets/images/main/ico-main05.svg');
                                }
                            }
                            &.q-gde {
                                &:before{
                                    background-image: url('/assets/images/main/ico-main06.svg');
                                }
                            }
                            a{
                                display: block; padding-left: 22.6389vw;
                                dl{
                                    dt{
                                        margin-bottom: 2.7778vw; @include subContsTitle;
                                    }
                                    dd{
                                        @include smallTxt;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .sec-main-visual {
                position:relative;
                .slick-control {
                    position:absolute;left:5.5556vw;bottom:19.4444vw;
                    width:18.3333vw;
                    text-align:center;
                    height:7.7778vw;
                    line-height:7.4448vw;                   
                    border-radius: 28px;                    
                    background-color: rgba(0, 0, 0, 0.5);                    
                    color:#fff;
                    span {display:inline-block;min-width:3.2222vw;font-size:4.1667vw;color:#fff;letter-spacing: -0.0833vw;vertical-align: middle;}
                    .lne {width:0.6667vw;}
                    button {display:inline-block;width:2.7222vw;height:4.7222vw;background:url('/assets/images/icon/ico-arrow-right-wh.svg') no-repeat 50%; background-size:3.7222vw;;vertical-align:middle;}
                }
            }
            .main-my{
                display: none;
            }
            .main-kv{
                img{width: 100%;}
            }
                        
            .sec-service-menu {
                .meun-list {
                    display:flex;gap:4.4444vw;justify-content: center; margin:9.7222vw 5.5556vw 16.6667vw 5.5556vw; justify-content: space-evenly; flex-wrap: wrap;
                    li {
                            width:18.8889vw;
                            .object-box {
                                overflow:hidden;display:inline-block;position:relative; width:18.8889vw;height:18.8889vw; border-radius: 8.3333vw; background:#f2f4fc; z-index: 10;
                                img {max-width:none;}
                                .object01-01 {position:absolute;left:50%;bottom:-5px;width:10.5556vw;height:14.3056vw; margin-left:-4.8611vw; background: url('/assets/images/main/img-service-object01-01.png') no-repeat 0 0;background-size: 10.5556vw;  z-index:5;}
                                .object01-02 {position:absolute; left:1.8056vw; top:8.3333vw; width:5.1389vw;height:3.75vw;background: url('/assets/images/main/img-service-object01-02.png') no-repeat 0 0;background-size: 5.1389vw;  z-index:1;transition: all 1s;}
                                &.object01.on .object01-02 {transition: all 1s cubic-bezier(0.250, 0.460, 0.450, 0.940); left:4.8611vw;}
                                .object01-03 {
                                    position:absolute; right:2.7778vw;bottom:-6.9444vw;width:5.8333vw;height:5.8333vw; background: url('/assets/images/main/img-service-object01-03.png') no-repeat 0 0;background-size: 5.8333vw; z-index:5; opacity: 0; transform: scale(0);  transition: all 1s;
                                    &::before{content:''; display: block; height: 1.9444vw;  position: absolute; left: 50%; top: 50%; width: 1.25vw;  margin:0 0 0 -1.3889vw;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 0.6944vw solid transparent; border-top: 0.6944vw solid transparent;;}
                                }
                                &.object01.on .object01-03{
                                    transition: all 1s cubic-bezier(0.250, 0.460, 0.450, 0.940); bottom:1.3889vw; transform: scale(1); opacity: 1;
                                    &::before {animation: check .8s alternate forwards 1s;border-color: #fff;z-index: 5; opacity: 0;}  
                                }            
                            }

                            .object02 {
                                background:#b791f8;
                                .object02-01 {animation:  object02-01 2s infinite forwards;  position:absolute;left:2.2222vw;top:4.5833vw;width:10.2778vw;height:11.25vw;background: url('/assets/images/main/img-service-object02-01.png') no-repeat 0 0; background-size: 10.2778vw;}
                                .object02-02 {animation:  object02-01 2s infinite forwards .2s; position:absolute;right:2.2222vw;top:6.3889vw;width:9.8611vw;height:10.1389vw;background: url('/assets/images/main/img-service-object02-02.png') no-repeat 0 0; background-size:9.8611vw;}
                                .object02-03 {position:absolute; left:50%; top: 1.8056vw; width:16.5278vw; height:7.9167vw; margin-left:-8.3333vw; background: url('/assets/images/main/img-service-object02-03.png') no-repeat 0 0; background-size: 16.5278vw; opacity:0; animation:object02-02 2s ease infinite 1s; }
                            }

                            .object03  {
                                background:#3c64fb;  background-image: linear-gradient(#699eff, #3b63fb);
                                .object03-01 {
                                    position:absolute; left:50%;top:50%;width:13.8889vw;height:7.9167vw; margin:-3.8889vw 0 0 -6.9444vw; background: url('/assets/images/main/img-service-object03-01.png') no-repeat 0 0; background-size: 13.8889vw;
                                    &.on {transform:scale(1.1);}
                                }
                                .object03-02 {
                                    position:absolute; right:-6.25vw;bottom:1.6667vw;width:11.9444vw;height:6.25vw;background: url('/assets/images/main/img-service-object03-02.png') no-repeat 0 0; background-size: 11.9444vw;
                                    &.on {transform:translateX(-3.0556vw) scale(1.1);}
                                }
                                .object03-03 {
                                    position:absolute; right:-1.6667vw;top:1.25vw;width:11.9444vw;height:6.25vw;background: url('/assets/images/main/img-service-object03-03.png') no-repeat 0 0; background-size: 11.9444vw;
                                    &.on {transform:translateX(-1.3889vw)  scale(1.1);}
                                }
                                .object03-04 {
                                    position:absolute; left:-4.1667vw;bottom:1.3889vw;width:11.9444vw;height:6.25vw;background: url('/assets/images/main/img-service-object03-04.png') no-repeat 0 0; background-size: 11.9444vw;
                                    &.on {transform:translateX(3.0556vw)  scale(1.1);}
                                }
                                span {transition:transform .5s  cubic-bezier(0.390, 0.575, 0.565, 1.000); z-index: 1;}
                                span.on {transition:transform .5s  cubic-bezier(0.390, 0.575, 0.565, 1.000) ; z-index: 5; }
                            }

                            .object04 {
                                .object04-01 {position:absolute; left:3.3333vw;top:4.0278vw;width:12.5vw;height:19.5833vw; background: url('/assets/images/main/img-service-object04-01.png') no-repeat 0 0;background-size: 12.5vw; transition: all 1.8s cubic-bezier(0.165, 0.840, 0.440, 1.000) .5s; transform:scale(0.9) translateY(2.7778vw);}
                                &.on .object04-01 {transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000); transform:scale(1) translateY(0);}
                                .object04-02 {
                                    position:absolute; right:3.1944vw;bottom:3.4722vw;width:5.8333vw;height:5.8333vw; background: url('/assets/images/main/img-service-object04-02.png') no-repeat 0 0; background-size: 5.8333vw; transition: all .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) .1s; transform:scale(0); opacity: 0;
                                    &::before{content:''; display: block; height: 1.9444vw;  position: absolute; left: 50%; top: 50%; width: 1.25vw;  margin:0 0 0 -1.3889vw;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 0.6944vw solid transparent; border-top: 0.6944vw solid transparent;;}
                                }
                                &.on .object04-02 {
                                    transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) .6s; transform:scale(1); opacity: 1;  
                                    &::before {animation: check .8s infinite alternate forwards 1.5s;border-color: #fff;z-index: 5; opacity: 0;}  
                                }
                            }
                  
                            .object05 {
                                background:#ff9fa5;
                                .object05-01 {               
                                    position:absolute; left:0.4167vw;top:4.7222vw;width:12.2222vw;height:14.5833vw; background: url('/assets/images/main/img-service-object05-01.png') no-repeat 0 0;background-size: 12.2222vw;  z-index: 3; transform: translateX(-4.8611vw);transition: all .8s;
                                    &::before {content: ""; position: absolute; left: 4.0278vw; top: 3.6111vw; width: 0.6944vw; height: 0.6944vw; background: #313131; border-radius: 100%; animation: object05-02 3s infinite alternate forwards;}
                                    &::after {content: ""; position: absolute; right: 5.8333vw; top: 3.6111vw; width: 0.6944vw; height: 0.6944vw; background: #313131; border-radius: 100%;animation: object05-02 3s infinite alternate forwards;} 
                                }         
                                &.on  .object05-01 { transform: translateX(0); transition: all 1.3s ; }
                            
                                .object05-02 {                  
                                    position:absolute; right:0.2778vw;top:3.3333vw;width:12.6389vw;height:15.6944vw; transform: translateX(5.5556vw); background: url('/assets/images/main/img-service-object05-02.png') no-repeat 0 0; background-size: 12.6389vw; z-index: 0;transform: translateX(4.8611vw); transition: all .8s; 
                                    &::before {content: ""; position: absolute; left: 6.25vw; top: 3.4722vw; width: 0.6944vw; height: 0.6944vw; background: #313131; border-radius: 100%; animation: object05-02 3s infinite alternate forwards 1.5s;}
                                    &::after {content: ""; position: absolute; right: 3.8889vw; top: 3.6111vw; width: 0.6944vw; height: 0.6944vw; background: #313131; border-radius: 100%;animation: object05-02 3s infinite alternate forwards 1.5s; }
                                }
                                &.on .object05-02 { transform: translateX(0); transition: all 1.5s; }

                                .object05-03 {position: absolute; background: #9845ed; top: 120%;  height: 200%;  width: 200%;  border-radius: 38%;  left: -50%;  transform: rotate(560deg); transition: all 1.5s ease .5s ; animation: wave 4.5s ease infinite 1.5s; opacity: .3; z-index: 5;}
                                &.on .object05-03 { top: 0%; opacity: 1; transition: all 1.5s ease .5s; }
                                .object05-04 { position:absolute;left:0;top:0;width:18.8889vw;height:18.8889vw; background: url('/assets/images/main/img-service-object05-03.png') no-repeat 50%; background-size: 18.8889vw; opacity: 0; transform: scale(0); z-index: 0; transition: all .8s ease .6s;}
                                &.on .object05-04 {opacity: 1; transform: scale(1);  z-index: 7; transition: all 1s ease 1s;}  
                            }


                            .object06 {
                                .object06-01 {position:absolute; left:1.9444vw; top:4.8611vw; width:15vw; height:11.25vw; background:url('/assets/images/main/img-service-object06-01.png') no-repeat 0 0; background-size: 15vw;;}   
                                .object06-02 {position:absolute; left:3.1944vw; top:7.5vw; width:6.9444vw; height:6.9444vw; background:url('/assets/images/main/img-service-object06-02.png') no-repeat 0 0; background-size: 6.9444vw;}
                                .object06-03 {position:absolute; left:3.1944vw; top:7.5vw; width:6.9444vw; height:6.9444vw; background:url('/assets/images/main/img-service-object06-03.png') no-repeat 0 0; background-size: 6.9444vw;}
                                .object06-04 {position:absolute; left:3.1944vw; top:7.5vw; width:6.9444vw; height:6.9444vw; background:url('/assets/images/main/img-service-object06-04.png') no-repeat 0 0; background-size: 6.9444vw;}
                                .photo {
                                    transition: all .8s ease-in-out; opacity: 0;  z-index: 0;
                                    &.on {transition: all .8s ease-in;opacity: 1;  z-index: 5;}
                                }                    
                                .object06-06 {position:absolute;left:5.6944vw;top:5.6944vw;width:6.9444vw;height:1.3889vw;
                                    .dot-flashing {position: relative; width: 0.5556vw; height: 0.5556vw; background-color: #fff;color: #fff; animation: main-dot-flashing 1s infinite linear alternate; animation-delay: 0.5s;}                   
                                    .dot-flashing::before, .dot-flashing::after {content: ""; display: inline-block; position: absolute;top: 0;}
                                    .dot-flashing::before {left: 1.3889vw; width: 0.5556vw; height: 0.5556vw; background-color: #fff;color: #fff;animation: main-dot-flashing 1s infinite alternate;  animation-delay: 0s;}
                                    .dot-flashing::after { left: 2.7778vw; width: 0.5556vw; height: 0.5556vw; background-color: #fff; color: #fff; animation: main-dot-flashing 1s infinite alternate;animation-delay: 1s;}
                                } 
                            }

                            .object07{
                                .object07-01 {position:absolute; left:3.4722vw; top:3.3333vw; width:13.6111vw; height:10.1389vw; background:url('/assets/images/main/img-service-object07-01.png') no-repeat 0 0;background-size: 13.6111vw; transition:all .8s ease-in-out 1s; transform:rotate(-10deg)  translateY(3.0556vw); }   
                                .object07-01 {animation:object07 2s cubic-bezier(0.950, 0.050, 0.795, 0.035)  infinite alternate .5s; }
                                .object07-02 {position:absolute; left:2.6389vw; top:7.3611vw; width:13.6111vw; height:10.1389vw; background:url('/assets/images/main/img-service-object07-02.png') no-repeat 0 0; background-size: 13.6111vw;}  
                                .object07-03 {
                                    position:absolute; right:2.7778vw; bottom:2.5vw; width:5.8333vw; height:5.9722vw; background:url('/assets/images/main/img-service-object07-03.png') no-repeat 0 0;background-size: 5.8333vw; opacity: 0; transform: translateX(2.7778vw);  animation: object07-01 2s ease infinite alternate 1s;
                                    &::before{content:''; display: block; height: 1.9444vw;  position: absolute; left: 50%; top: 50%; width: 1.25vw;  margin:0 0 0 -1.3889vw;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 0.6944vw solid transparent; border-top: 0.6944vw solid transparent; border-color: #fff;z-index: 5; opacity: 0;}
                                    &::before {animation: check .8s alternate forwards 2.5s;  }
                                }
                            }

                            .object08{
                                .object08-01 {position:absolute;left:3.5556vw;bottom:0;width:10.6944vw; height:11.8056vw; background: url('/assets/images/main/img-service-object08-01.png') no-repeat 0 0;background-size: 10.6944vw; z-index: 5; animation: object08 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) infinite alternate forwards 2.5s;  transform:translateY(10px)}
                                .object08-02 {
                                    position:absolute;left:3.3333vw;top:3.75vw;width:12.6389vw; height:8.1944vw;
                                    span:nth-child(1) {position: absolute; width:12.6389vw; height:8.1944vw; background: url('/assets/images/main/img-service-object08-02.png') no-repeat 0 0;background-size: 12.6389vw; z-index: 0;}                           
                                    span:nth-child(2) {position: absolute; width:12.6389vw; height:8.1944vw; background: url('/assets/images/main/img-service-object08-03.png') no-repeat 0 0;background-size: 12.6389vw;z-index: 0;}
                                    span {z-index: 0;transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000); transform: scale(0.5);}
                                    span.on {z-index: 3;transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000); transform: scale(1);}
                                }
                            }
                            
                            .object09 {
                                .object09-01 {
                                    position:absolute;left:-1.3889vw;bottom:-1.3889vw;
                                    img {width:20vw;}
                                    .objt01 {position:absolute;left:0;top:0;z-index: 3;}
                                    .objt02 {position:absolute;left:0;top:0;z-index: 3;opacity: 0;}
                                }
                                .object09-02 {
                                    position:absolute;right:-1.3889vw;bottom:-11.1111vw; height:15.2778vw; z-index: 5;
                                    img {height:100%;}
                                }
                            }

                            .object10 {
                                img {width:100%;}
                                .object10-01 {position:absolute;left:4.5833vw;top:5.1389vw;width:11.25vw;z-index: 3; transform: scale(0.7);}
                                .object10-02 {position:absolute;left:5.3333vw;bottom:4.7778vw;width:8.8889vw; z-index:1;opacity: 0;}
                                .object10-03 {position:absolute;left:3.3333vw;bottom:2.7778vw;width:8.8889vw; z-index: 5;opacity: 0;}
                                .object10-04 {position:absolute;left:50%;top:50%;width:15.4167vw;margin:-6.6389vw 0 0 -7.8333vw; z-index: 1;opacity: 0;transform: scale(0.5);}                            
                            }

                            .tit {display:block;margin-top:2.7778vw; font-size: 3.6111vw;  font-weight: 600; line-height: 1.63; letter-spacing: -0.0444vw;text-align: center;color: #191919;}
                        }
                }
            }
                 
            @keyframes wave {
                0% {
                    transform: rotate(0deg);
                }
            80%, 100% {
                    transform: rotate(50%);
                }
            }

                    
            @keyframes object05-01 {
                0% {
                    transform: translateX(-5.5556vw);
                }
                60%, 70%, 80%, 90%, 100% {
                    transform: translateX(0px);
                }
            }

            @keyframes object05-01-01 {
                0% {
                    transform: translateX(5.5556vw);
                }
                60%, 70%, 80%, 90%, 100% {
                    transform: translateX(0px);
                }
            }

            @keyframes check {
                0% {
                    opacity: 1;
                height: 0;
                width: 0;
                }
                30% {       
                    opacity: 1;     
                height: 0;
                width: 1.25vw;
                }
                50%, 70%, 100% {
                height: 1.9444vw;
                width: 1.25vw;
                opacity: 1;
                }        
            }

            @keyframes object02-01 {
                0%,
                100% {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                    -webkit-transform-origin: 50% 50%;
                            transform-origin: 50% 50%;
                }
                15% {
                    -webkit-transform: translateX(-1.1111vw) rotate(-6deg);
                            transform: translateX(-1.1111vw) rotate(-6deg);
                }
                30% {
                    -webkit-transform: translateX(1.1111vw) rotate(6deg);
                            transform: translateX(1.1111vw) rotate(6deg);
                }
                45% {
                    -webkit-transform: translateX(-1.3889vw) rotate(-3.6deg);
                            transform: translateX(-1.3889vw) rotate(-3.6deg);
                }
                60% {
                    -webkit-transform: translateX(1.25vw) rotate(2.4deg);
                            transform: translateX(1.25vw) rotate(2.4deg);
                }
                75% {
                    -webkit-transform: translateX(-0.8333vw) rotate(-1.2deg);
                            transform: translateX(-0.8333vw) rotate(-1.2deg);
                }
            }

            @keyframes object02-02 {
                0% {
                    transform:scale(0)  translateY(0);
                }
                20% {
                    opacity: 1;
                    transform:scale(.5) translateY(-1.3889vw);
                }
                20% {
                    opacity: 1;
                    transform:scale(1) translateY(-1.3889vw);
                }
                100% {
                    opacity: 0;
                    transform:scale(1) translateY(0px);
                }
            }

            @keyframes object05-02 {
                0% {
                transform: scaleY(1);
                }
                18% {
                transform: scaleY(1);
                }
                20% {
                transform: scaleY(0);
                }
                25% {
                transform: scaleY(1);
                }
                38% {
                transform: scaleY(1);
                }
                40% {
                transform: scaleY(0);
                }
                45% {
                transform: scaleY(1);
                }
                80% {
                transform: scaleY(1);
                }
            }
            
            @keyframes love1 {
                0% {
                opacity: 0;
                transform: scale(0.2) rotate(0deg) translate3d(13.8889vw, 0, 0);
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 0; 
                transform: scale(0.8) rotate(-40deg) translate3d(-6.9444vw, -55.5556vw, 0);
                }
            }

            @keyframes love2 {
                0% {
                opacity: 0;
                transform: scale(0.3) rotate(0deg) translate3d(13.8889vw, 0, 0);
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 0;  
                transform: scale(0.7) rotate(-50deg) translate3d(-11.1111vw, -62.5vw, 0);
                }
            }
            
            @keyframes love3 {
                0% {
                opacity: 0;
                -webkit-transform: scale(0.3) rotate(0deg) translate3d(13.8889vw, 0, 0);
                transform: scale(0.3) rotate(0deg) translate3d(13.8889vw, 0, 0);
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 0; 
                transform: scale(0.65) rotate(-60deg) translate3d(-5.5556vw, -55.5556vw, 0);
                }
            }

            @keyframes love4 {
                0% {
                opacity: 0;  
                transform: scale(0.2) rotate(0) translate3d(0, 0, 0);
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 0;
                transform: scale(0.6) rotate(-25deg) translate3d(13.8889vw, -27.7778vw, 0);
                }
            }

            @keyframes love5 {
                0% {
                opacity: 0;   
                transform: scale(0.2) rotate(0) translate3d(0, 0, 0);
                }
                50% {
                opacity: 1;
                }
                100% {
                opacity: 0;
                transform: scale(0.5) rotate(-20deg) translate3d(27.7778vw, -34.7222vw, 0);
                }
            }

            @keyframes main-dot-flashing {
                0% {
                background-color: #ffffff;
                }
                50%,
                100% {
                background-color: rgba(152, 128, 255, 0.2);
                }
            }
            
            @keyframes object07-01{
                0%, 30%, 40%, 50% , 70% {
                    opacity: 0;
                    transform: translateX(2.7778vw);
                }
            80%, 100% {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }

            @keyframes object07 {
            0%, 40%,  40%, 50% { 
                transform:rotate(-10deg)  translateY(3.0556vw); 
            } 
            70%, 80%, 100% {
                transform:rotate(0)  translateY(0px);
            }
            }
            
            @keyframes object08 {
                0% {
                    transform:translateY(10px);
                }                 
                70%, 100% {                  
                    transform:translateY(0);
                }
            }

            @keyframes object05-06{
                0%{
                  background-size: 80%;
                  opacity: 0;
                }  
                100%{
                  background-size: 100%;
                  opacity: 1;
               
                }
              }

        }
    }

    .menu-shop-wrap{
        .menu-shop{
            position: fixed; left: 0; bottom: 0; z-index: 11; width: 100%; height: 17.7778vw; transition: 0.3s;
            .inner{
                height: 100%; transition: 1s;
                &:before{
                    content: ''; position: absolute; left: 0; bottom: 0; z-index: -1; width: 51%; height: 17.7778vw; background: $white; border-radius: 2.7778vw 18vw 0 0; transition: 0.3s; box-shadow: 0vw -2.7778vw 2.6389vw 0vw rgba(0,0,0,0.1);
                }
                &:after{
                    content: ''; position: absolute; right: 0; bottom: 0; z-index: -1; width: 51%; height: 17.7778vw; background: $white; border-radius: 18vw 2.7778vw 0 0; transition: 0.3s; box-shadow: 0vw -2.7778vw 2.6389vw 0vw rgba(0,0,0,0.1);
                }
                .shop{
                    position: absolute; left: 50%; bottom: 2.7778vw; z-index: 10; transform: translateX(-50%); width: 21.1111vw; height: 21.1111vw; padding-top: 12.0833vw; background: url('/assets/images/main/ico-main-shop.svg') no-repeat 50% 4.4444vw; background-size: 6.6667vw; background-color: $purple; border-radius: 50%; color: $white; font-size: 3.8889vw; text-align: center;  transition-property: width, height, bottom; transition-duration: 0.3s;
                    &:before{
                        content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) rotate(45deg); width: 0; height: 0.5556vw; background-color: $white; border-radius: 0.2778vw; transition: 0.3s;
                    }
                      &:after{
                        content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) rotate(-45deg); width: 0; height: 0.5556vw; background-color: $white; border-radius: 0.2778vw; transition: 0.3s;
                      }
                }
                .menu{
                    display: flex; gap: 30.9722vw; position: absolute; left: 0; bottom: 0; width: 100%; height: 17.7778vw; transition: 0.3s;
                    li{
                        flex: 1;
                        &:nth-child(1){
                            transition: 0.2s;
                        }
                        &:nth-child(2){
                            transition: 0.25s;
                        }
                        a{
                            display: block; position: relative; height: 17.7778vw; padding-top: 10.4167vw; color: $black; font-size: 3.8889vw; text-align: center;
                            &:before{
                                content: ''; position: absolute; left: 50%; top: 2.7778vw; transform: translateX(-50%); width: 6.6667vw; height: 6.6667vw; background-size: 6.6667vw; background-repeat: no-repeat; background-position: 0;
                            }
                        }
                        .benefit{
                            &:before{
                                background-image: url('/assets/images/main/ico-main-benefit.svg');
                            }
                        }
                        .my{
                            &:before{
                                background-image: url('/assets/images/main/ico-main-my.svg');
                            }
                        }
                    }
                }
                .sub-menu-tit{
                    position: absolute; left: 0; top: 9.4444vw; width: 100%; @include subContsTitle; text-align: center; opacity: 0; transition: 0.3s;
                }
                .sub-menu{
                    display: flex; flex-wrap: wrap; gap: 2.7778vw; position: absolute; left: 0; top: 119.4444vw; width: 100%; padding: 0 5.5556vw;
                    li{
                        &:nth-child(1){
                            a{
                                transform: translateY(2.0833vw); opacity: 0; transition: 0.4s;
                            }
                        }
                        &:nth-child(2){
                            a{
                                transform: translateY(2.0833vw); opacity: 0; transition: 0.45s;
                            }
                        }
                        &:nth-child(3){
                            a{
                                transform: translateY(2.0833vw); opacity: 0; transition: 0.5s;
                            }
                        }
                        &:nth-child(4){
                            a{
                                transform: translateY(2.0833vw); opacity: 0; transition: 0.55s;
                            }
                        }
                        a{
                          display: flex; align-items: center; height: 15vw; padding-left: 15.9722vw; background-repeat: no-repeat; background-position: 4.1667vw 50%; background-size: 11.1111vw; border: 0.2778vw solid $gray02; border-radius: 1.3889vw; color: $black; font-size: 3.8889vw; letter-spacing: -0.1167vw;
                          &.usim-buy{
                            background-image: url('/assets/images/main/ico-main-usim-buy.svg');
                          }
                          &.usim-join{
                            background-image: url('/assets/images/main/ico-main-usim-join.svg');
                          }
                          &.phone-join{
                            background-image: url('/assets/images/main/ico-main-phone-join.svg');
                          }
                          &.rq{
                            background-image: url('/assets/images/main/ico-main-rq.svg');
                          }
                          &:active{
                            border-color: $purple; color: $purple;
                          }
                        }
                    }
                }
            }
        }
        &.on{
            position: fixed; left: 0; top: 0; z-index: 10000; width: 100vw; height: 100vh; background-color: rgba(0,0,0, 0.6);
            .menu-shop{
                height: 76.8056vw;
                .inner{
                    &:before,&:after{
                       height: 100%; border-radius: 0; background-color: $white; box-shadow:none;
                    }
                    .shop{
                      bottom: 5.5556vw; width: 13.3333vw; height: 13.3333vw; background: $purple; font-size: 0;
                      &:before{
                        width: 50%;
                    }
                      &:after{
                        width: 50%;
                      }
                    }
                    .menu{
                        height: 0;
                        li{
                          &:nth-child(1){
                            transform: translateY(2vw); opacity: 0;
                          }
                          &:nth-child(2){
                            transform: translateY(2vw); opacity: 0;
                          }
                        }
                    }
                    .sub-menu-tit{
                        opacity: 1;
                    }
                    .sub-menu{
                        display: flex; flex-wrap: wrap; gap: 2.7778vw; position: absolute; left: 0; top: 19.4444vw; width: 100%; padding: 0 5.5556vw;
                        li{
                            flex: 1 1 40%; width: 50%;
                            &:nth-child(1){
                                a{
                                    transform: translateY(0); opacity: 1;
                                }
                            }
                            &:nth-child(2){
                                a{
                                    transform: translateY(0); opacity: 1;
                                }
                            }
                            &:nth-child(3){
                                a{
                                    transform: translateY(0); opacity: 1;
                                }
                            }
                            &:nth-child(4){
                                a{
                                    transform: translateY(0); opacity: 1;
                                }
                            }
                        }
                    }

                }

            }
        }
    }
    // 혜택 홈
    .evt-benefit-home{      
      /*   padding-top: 21.6667vw; */
        // .banner{
        //     margin-top: 4.1667vw;
        // }
        .slick-default{
            margin-top: 11.1111vw;
            .slick-slide{
                a{
                    display: block; border-radius: 2.7778vw; overflow: hidden;
                }
            }
        }
        .home-list{
            margin-top: 11.1111vw;
        }
        .c-unit{
            & > p{@include smallTxt; margin-bottom: 1.3889vw;}
            .banner{
                width:100%; height:55.5556vw; overflow: hidden; border-radius: 2.7778vw;
                a{
                    display: block; font-size: 0; height: 100%;
                    img{
                        width: 100%; height: 100%; object-fit: cover;
                    }
                }
                & + .banner{ margin-top: 4.1667vw;}
            }
            .box-btn{margin-top: 2.7778vw;}
        }
    }

}
// 오픈 팝업
.open-modal-popup-wrap{
    position: fixed;top: 0;left: 0;width: 100%; height: 100%;background: rgba(25, 25, 25, 0.7);z-index: 200;display: flex; flex-direction: column;align-items: center;justify-content: flex-end;opacity: 0; visibility: hidden; transition: opacity 0.3s, visibility 0.3s;
    &.on {
        opacity: 1; visibility: visible;
        .open-modal-popup {
            transform: translateY(0);
        }
        .open-modal-popup.type-center {
            position:absolute; left:50%;top:50%; padding:0 2.7778vw;transform: translate(-50%, -50%); border-radius: 0;
        }
    }
    .open-modal-popup{
        width: 100%; min-width: 100%; border-radius: 5.5556vw 5.5556vw 0 0; overflow: hidden;transform: translateY(100%);transition: all 0.3s;
        .slick-default{
            & > div {display: none;}
            .slick-list{
                display: block !important;
                background-color: $white;
                .slick-slide{
                    a{
                        display: block;
                        img{width: 100%;}
                    }
                }
            }
            .slick-dots{
                li{
                    button{background: rgba(255, 255, 255, 0.4);}
                    &.slick-active {
                        button{
                            background: $white;
                        }
                    }
                }
            }
        }
        .bottom-bar{
            display: flex; align-items: center; justify-content: space-between; background-color: $black; width: 100%; height: 13.8889vw; padding: 0 5.5556vw;
            .btn-check{
                label {
                    width: 100%;
                    *{color: $white;}
                }
            }
            .popup-close{
                width: 4.1667vw; height: 4.1667vw;
                @include backgroundImg('/assets/images/icon/ico-close-wh.svg',center,contain);
            }
        }
    }

    .open-modal-popup.type-center {
         .bottom-bar {
            height:12.2222vw;
            .btn-check label {
                width: 100%;align-items: normal; color:#fff;
                span::before,
                span::after {top:-0.2778vw}
            }      
            .btn-close-x-wh {width: 4.1667vw; height: 4.1667vw; background-image: url('/assets/images/icon/ico-close-wh.svg'); background-repeat: no-repeat;   background-position: center;   background-size: contain;}    
        }
    }
}


@media (min-width: 400px) and  (max-width: 1000px) and (min-aspect-ratio: 13/9) {
    .main{
        #header{
            border-bottom: 0;
            .top-util{
                width: auto;
            }
        }
        .personal-message{
            position: fixed; left: 50%; top: 10.2857vw; transform: translateX(-50%); z-index: 10; width: 45.7143vw;
            li{
                position: relative;
                &+li{
                    margin-top: 1.4286vw;
                }
                a{
                    display: block; padding: 1.5714vw 2.1429vw; background-color: rgba(0,0,0, 0.7); border-radius: 2.8571vw; color: $white;
                }
                button{
                    position: absolute; right: 1.4286vw; top: 50%; transform: translateY(-50%); width: 2.8571vw; height: 2.8571vw; @include backgroundImg('/assets/images/icon/ico-delete.svg', 0, 100%); font-size: 0;
                }
            }
        }
        .container{
            padding-top: 0;
            .main-conts{
                .main-sales{
                    > div{
                        margin-bottom: 8.5714vw;
                        > h2{
                           margin-bottom: 2.8571vw;
                        }
                        > .box-btn{
                            margin-top: 4.2857vw; text-align: center;
                            .btn-text-arrow{
                                font-size: 2.2857vw;
                            }
                        }
                    }
                    .main-event{
                        padding: 0 2.8571vw;
                        a{
                            display: block; position: relative; width: 45.7143vw; min-height: 47.1429vw; padding: 4.2857vw 4.2857vw 26.1429vw 4.2857vw; background-color: $gray01;
                            dl{
                                margin-bottom: 2.8571vw;
                                dt{
                                    margin-bottom: 0.7143vw;
                                }
                                dd{
                                    @include mainContsTitle;
                                    strong{
                                        margin-right: 0.7143vw;  color: $purple !important;
                                    }
                                }
                            }
                            p{
                                @include smallTxt;
                                strong{
                                    display: block; margin-bottom: 0.9286vw;
                                }
                            }
                            span{
                                position: absolute; right: 2.8571vw; bottom: 4.2857vw; width: auto; height: 16.6429vw;
                                img{
                                    width: 100%;height: 100%;object-fit: contain;
                                }
                            }
                        }
                    }
                    .main-event-list{
                        padding: 0 2.8571vw; margin-bottom: 7.1429vw;
                        ul{
                            li{
                                & + li {
                                    margin-top: 2.1429vw;
                                }
                            }
                        }
                    }
                    .main-plan{
                        padding: 7.8571vw 0 5.0000vw 0; background-color: #f2f4f5;
                        .plan-tab{
                            width: calc(100% - 2.8571vw); margin-left: 2.8571vw; padding-right: 2.8571vw; overflow-x: scroll; overflow-y: hidden; white-space: nowrap; font-size: 0; display: flex; align-items: center;
                            li{
                                display: inline-block;
                                & + li{
                                    margin-left: 4.2857vw;
                                }
                                a{
                                    display: block; padding-bottom: 0.7143vw;
                                    &.on{
                                        border-bottom: 0.2857vw solid $purple; color: $purple;
                                    }
                                }
                            }
                            &::-webkit-scrollbar{
                                    display: none;
                            }
                        }
                        .plan-tab-wrap{
                            .plan-tab-conts{
                                display: none;
                                &:first-child{
                                    display: block;
                                }
                                > a{
                                    display: block; margin: 5.7143vw 2.8571vw 4.2857vw 2.8571vw;
                                    img{
                                        width: 100%;
                                    }
                                }
                                .inner{
                                    padding-bottom: 4.0000vw;
                                    .slick-slide{
                                        max-height: none; padding: 4.2857vw 3.5714vw; background-color: $white; border-radius: 1.4286vw; transform: scale(.85);
                                        &.slick-active{
                                            transform: scale(1);
                                        }
                                        > strong{
                                            display: block; margin-bottom: 3.5714vw;
                                        }
                                        > p{
                                            strong{
                                                display: block;margin-bottom: 0.7143vw;
                                            }
                                        }
                                        > dl{
                                            margin-top: 2.1429vw; padding-left: 2.8571vw; background-repeat: no-repeat; background-position: 0 50%; background-size: 2.8571vw;
                                            &.call{
                                                background-image: url('/assets/images/icon/ico-call-fill.svg');
                                            }
                                            &.sms{
                                                background-image: url('/assets/images/icon/ico-sms-fill.svg');
                                            }
                                            dt{
                                                font-size: 0;
                                            }

                                        }
                                        .won{
                                            margin: 4.2857vw 0 3.5714vw 0;
                                            strong{
                                                display: block; color: $purple;
                                            }
                                            span{
                                                text-decoration: line-through;
                                            }
                                        }
                                        .gift{
                                            padding: 2.8571vw; background-color: #f8f4ff; border-radius: 1.0000vw;
                                            ul{
                                                li{
                                                    color: $black;
                                                    & + li{
                                                        margin-top: 0.7143vw;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .slick-dots{
                                        bottom: 0;
                                        li{
                                            button{
                                                background-color: $white;
                                            }
                                            &.slick-active{
                                                button{
                                                    background-color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .main-phone{
                        padding: 0 2.8571vw;
                        .phone-slick{
                            padding-bottom: 4.0000vw; margin-bottom: 4.2857vw;
                            .slick-dots{
                                bottom: 0;
                            }
                        }
                        .kind{
                            ul{
                                display: flex; gap: 1.7143vw;
                                li{
                                    flex: 1;
                                }
                            }
                        }
                    }
                    .main-subscribe{
                        h2{
                            padding: 0 2.8571vw;
                        }
                        .slick-slider{
                            padding-bottom: 4.0000vw;
                            .slick-slide{
                                max-height: none; transform: scale(1); margin-right: 2.1429vw;
                            }
                            .slick-dots{
                                bottom: 0;
                            }
                        }
                    }
                    .main-after{
                        padding: 0 2.8571vw;
                    }
                    .main-inquiry{
                        padding: 0 2.8571vw;
                        ul{
                            margin-top: 5.7143vw;
                            li{
                                position: relative; min-height: 8.5714vw;
                                & + li{
                                    margin-top: 2.8571vw;
                                }
                                &:before{
                                    content: ''; position: absolute; left: 0; top: 0; width: 8.5714vw; height: 8.5714vw; border-radius: 50%; background-position: 50%; background-size: 5.7143vw; background-color: $gray06; background-repeat: no-repeat;
                                }
                                &:nth-child(1){
                                    &:before{
                                        background-image: url('/assets/images/main/ico-main01.svg');
                                    }
                                }
                                &:nth-child(2){
                                    &:before{
                                        background-image: url('/assets/images/main/ico-main02.svg');
                                    }
                                }
                                &:nth-child(3){
                                    &:before{
                                        background-image: url('/assets/images/main/ico-main03.svg');
                                    }
                                }
                                &:nth-child(4){
                                    &:before{
                                        background-image: url('/assets/images/main/ico-main04.svg');
                                    }
                                }
                                a{
                                    display: block; padding-left: 11.6429vw;
                                    dl{
                                        dt{
                                            margin-bottom: 1.4286vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .main-my{
                    display: none;
                }
                .main-kv{
                    img{width: 100%;}
                }
            }
        }
        .menu-shop-wrap{
            .menu-shop{
                position: fixed; left: 0; bottom: 0; z-index: 1; width: 100%; height: 9.1429vw; transition: 0.3s;
                .inner{
                    height: 100%; transition: 1s;
                    &:before{
                        content: ''; position: absolute; left: 0; bottom: 0; z-index: -1; width: 51%; height: 9.1429vw; background: $white; border-radius: 1.4286vw 9.2857vw 0 0; transition: 0.3s; box-shadow: 0.0000vw -1.4286vw 1.3571vw 0.0000vw rgba(0,0,0,0.1);
                    }
                    &:after{
                        content: ''; position: absolute; right: 0; bottom: 0; z-index: -1; width: 51%; height: 9.1429vw; background: $white; border-radius: 9.2857vw 1.4286vw 0 0; transition: 0.3s; box-shadow: 0.0000vw -1.4286vw 1.3571vw 0.0000vw rgba(0,0,0,0.1);
                    }
                    .shop{
                        position: absolute; left: 50%; bottom: 1.4286vw; z-index: 10; transform: translateX(-50%); width: 10.8571vw; height: 10.8571vw; padding-top: 6.2143vw; background: url('/assets/images/main/ico-main-shop.svg') no-repeat 50% 2.2857vw; background-size: 3.4286vw; background-color: $purple; border-radius: 50%; color: $white; font-size: 2.0000vw; text-align: center;  transition-property: width, height, bottom; transition-duration: 0.3s;
                        &:before{
                            content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) rotate(45deg); width: 0; height: 0.2857vw; background-color: $white; border-radius: 0.1429vw; transition: 0.3s;
                        }
                          &:after{
                            content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) rotate(-45deg); width: 0; height: 0.2857vw; background-color: $white; border-radius: 0.1429vw; transition: 0.3s;
                          }
                    }
                    .menu{
                        display: flex; gap: 15.9286vw; position: absolute; left: 0; bottom: 0; width: 100%; height: 9.1429vw; transition: 0.3s;
                        li{
                            flex: 1;
                            &:nth-child(1){
                                transition: 0.2s;
                            }
                            &:nth-child(2){
                                transition: 0.25s;
                            }
                            a{
                                display: block; position: relative; height: 9.1429vw; padding-top: 5.3571vw; color: $black; font-size: 2.0000vw; text-align: center;
                                &:before{
                                    content: ''; position: absolute; left: 50%; top: 1.4286vw; transform: translateX(-50%); width: 3.4286vw; height: 3.4286vw; background-size: 3.4286vw; background-repeat: no-repeat; background-position: 0;
                                }
                            }
                            .benefit{
                                &:before{
                                    background-image: url('/assets/images/main/ico-main-benefit.svg');
                                }
                            }
                            .my{
                                &:before{
                                    background-image: url('/assets/images/main/ico-main-my.svg');
                                }
                            }
                        }
                    }
                    .sub-menu-tit{
                        position: absolute; left: 0; top: 4.8571vw; width: 100%;  text-align: center; opacity: 0; transition: 0.3s;
                    }
                    .sub-menu{
                        display: flex; flex-wrap: wrap; gap: 1.4286vw; position: absolute; left: 0; top: 61.4286vw; width: 100%; padding: 0 2.8571vw;
                        li{
                            &:nth-child(1){
                                a{
                                    transform: translateY(1.0714vw); opacity: 0; transition: 0.4s;
                                }
                            }
                            &:nth-child(2){
                                a{
                                    transform: translateY(1.0714vw); opacity: 0; transition: 0.45s;
                                }
                            }
                            &:nth-child(3){
                                a{
                                    transform: translateY(1.0714vw); opacity: 0; transition: 0.5s;
                                }
                            }
                            &:nth-child(4){
                                a{
                                    transform: translateY(1.0714vw); opacity: 0; transition: 0.55s;
                                }
                            }
                            a{
                              display: flex; align-items: center; height: 7.7143vw; padding-left: 9.2857vw; background-repeat: no-repeat; background-position: 2.1429vw 50%; background-size: 5.7143vw; border: 0.1429vw solid $gray02; border-radius: 0.7143vw; color: $black; font-size: 2.0000vw; letter-spacing: -0.0714vw;
                              &.usim-buy{
                                background-image: url('/assets/images/main/ico-main-usim-buy.svg');
                              }
                              &.usim-join{
                                background-image: url('/assets/images/main/ico-main-usim-join.svg');
                              }
                              &.phone-join{
                                background-image: url('/assets/images/main/ico-main-phone-join.svg');
                              }
                              &.rq{
                                background-image: url('/assets/images/main/ico-main-rq.svg');
                              }
                              &:active{
                                border-color: $purple; color: $purple;
                              }
                            }
                        }
                    }
                }
            }
            &.on{
                position: fixed; left: 0; top: 0; z-index: 10000; width: 100%; height: 100vh; background-color: rgba(0,0,0, 0.6);
                .menu-shop{
                    height: 39.5000vw;
                    .inner{
                        &:before,&:after{
                           height: 100%; border-radius: 0; background-color: $white; box-shadow:none;
                        }
                        .shop{
                          bottom: 2.8571vw; width: 6.8571vw; height: 6.8571vw; background: $purple; font-size: 0;
                          &:before{
                            width: 50%;
                        }
                          &:after{
                            width: 50%;
                          }
                        }
                        .menu{
                            height: 0;
                            li{
                              &:nth-child(1){
                                transform: translateY(1.0000vw); opacity: 0;
                              }
                              &:nth-child(2){
                                transform: translateY(1.0000vw); opacity: 0;
                              }
                            }
                        }
                        .sub-menu-tit{
                            opacity: 1;
                        }
                        .sub-menu{
                            display: flex; flex-wrap: wrap; gap: 1.4286vw; position: absolute; left: 0; top: 10.0000vw; width: 100%; padding: 0 2.8571vw;
                            li{
                                flex: 1 1 40%; width: 50%;
                                &:nth-child(1){
                                    a{
                                        transform: translateY(0); opacity: 1;
                                    }
                                }
                                &:nth-child(2){
                                    a{
                                        transform: translateY(0); opacity: 1;
                                    }
                                }
                                &:nth-child(3){
                                    a{
                                        transform: translateY(0); opacity: 1;
                                    }
                                }
                                &:nth-child(4){
                                    a{
                                        transform: translateY(0); opacity: 1;
                                    }
                                }
                            }
                        }

                    }

                }
            }
    }
        // 혜택 홈
        .evt-benefit-home{
            padding-top: 11.1429vw;
            .slick-default{
                margin-top: 5.7143vw;
                .slick-slide{
                    a{
                        display: block; border-radius: 1.4286vw; overflow: hidden;
                    }
                }
            }
            .home-list{
                margin-top: 5.7143vw;
            }
            .c-unit{
                & > p{margin-bottom: 0.7143vw;}
                .banner{
                    width:100%; height:28.5714vw; overflow: hidden; border-radius: 1.4286vw;
                    a{
                        display: block; font-size: 0; height: 100%;
                        img{
                            width: 100%; height: 100%; object-fit: cover;
                        }
                    }
                    & + .banner{ margin-top: 2.1429vw;}
                }
                .box-btn{margin-top: 1.4286vw;}
            }
        }
    }
}

@media (min-width: 674px) { // 갤럭시 Z 폴드 화면 대응

    .main{
        .header-wrp{
            height:60px;
            padding-bottom:60px;
            #header.on {
                border-bottom:1px solid #ddd;
                .btn-menu {
                    width: 40px;
                    height: 40px;      
                    span {
                        width:20px;
                        height:2px;
                    }
                }
            }
            #header.on .logo {
                width: 113px; height: 40px;
            }
            #header .btn-menu span {
                width: 20px;
                height: 2px;
            }

             
        } 
        .survey-wrap{
            right: 20px !important; bottom: 135px !important; z-index: 90 !important; width: 54px; height: 54px;
        }
        .chat-bot{
            right: 20px; bottom: 72px; z-index: 90; width: 54px; height: 54px;
            a.open, a.close{
                width: 54px; height: 54px;
            }
        }
        .personal-message{
            li{
                a{color: #fff;}
            }
        }
        .menu-shop-wrap{
            &.on{
                width: 100%;
                .menu-shop{
                    height: 277px;
                    .inner{
                        .shop{
                            bottom: 20px; width: 48px; height: 48px;
                        }
                        .sub-menu{
                            gap: 10px; top: 70px; padding: 0 20px;
                            li{
                                a{height: 54px; font-size: 14px; letter-spacing: -1px; padding-left: 65px; background-position: 15px 50%; background-size: 40px;}
                            }
                        }
                    }
                }
            }
            .menu-shop{
                position: fixed; left: 0; bottom: 0; z-index: 1; width: 100%; height: 64px;
                .inner{
                    &:before{
                        width: 51%; height: 64px; border-radius: 10px 65px 0 0; box-shadow: 0px -10px 9px 0px rgba(0,0,0,0.1);
                    }
                    &:after{
                        width: 51%; height: 64px; border-radius: 65px 10px 0 0; box-shadow: 0px -10px 9px 0px rgba(0,0,0,0.1);
                    }
                    .menu{
                        gap: 112px; width: 100%; height: 64px;
                        li{
                            a{
                                height: 64px; padding-top:37px; font-size: 14px; letter-spacing: -1px;
                                &:before{
                                    top: 10px; width: 24px; height: 24px; background-size: 24px;
                                }
                            }
                        }
                    }
                    .shop{
                        bottom: 10px; width: 76px; height: 76px; padding-top: 44px; background-position: 50% 16px; background-size: 24px; font-size: 14px; letter-spacing: -1px;
                        &:before, &:after{
                            height: 2px; border-radius: 1px; 
                        }
                    }
                    .sub-menu-tit{
                        font-size: 18px; letter-spacing: -1px; top: 34px;
                    }
                }
            }
        }
        .container{
            padding: 60px 0 70px 0; padding-top: 0;
            &.my{padding-top: 60px;}
            .main-conts{
                .main-sales{
                    > div{
                        margin-bottom: 60px;
                        > h2{
                            margin-bottom: 20px; font-size: 26px; letter-spacing: -2px !important;
                        }
                        .box-btn{
                            margin-top: 30px;
                            .btn-text-arrow{
                                font-size: 16px; letter-spacing: -1px; background-size: 16px 16px; padding-right: 26px;
                            }
                        }
                    }
                    .main-event{
                        padding: 0 20px;
                        a{
                            dl{
                                margin-bottom: 20px;
                                dt{
                                    font-size: 15px; letter-spacing: -1px;
                                }
                                dd{
                                    font-size: 26px; letter-spacing: -1px;
                                }
                            }
                            width: 100%; min-height: 330px; padding: 30px;
                            span{
                                right: 20px; bottom: 30px; height: 117px;
                            }
                            p{
                                font-size: 15px; letter-spacing: -1px;
                                strong{
                                    margin-bottom: 7px; font-size: 15px; letter-spacing: -1px;
                                }
                            }
                        }
                    }
                    .main-event-list{
                        padding: 0 20px; margin-bottom: 50px;
                        ul{
                            li{
                                a{
                                    img{width: 100%;}
                                }
                                &+li{margin-top: 15px;}
                            }
                        }
                    }
                    .main-plan{
                        padding: 55px 0 35px 0;
                        .plan-tab{
                            width: calc(100% - 20px); margin-left: 20px; padding-right: 20px;
                            li{
                                a{
                                    font-size: 18px; letter-spacing: -1px;
                                    &.on{
                                        border-bottom-width: 2px;
                                    }
                                }
                                &+li{
                                    margin-left: 30px;
                                }
                            }
                        }
                        .plan-tab-wrap{
                            .plan-tab-conts{
                                > a{
                                    margin: 40px 20px 30px 20px;
                                }
                                .inner{
                                    padding-bottom: 28px;
                                    .slick-slide{
                                        padding: 30px 25px;
                                        > strong{
                                            margin-bottom: 25px; font-size: 18px; letter-spacing: -1px;
                                        }
                                        > em{
                                            font-size: 15px; letter-spacing: -1px;
                                        }
                                        > p{
                                            font-size: 15px; letter-spacing: -1px;
                                            strong{
                                                font-size: 20px; letter-spacing: -1px;
                                            }
                                        }
                                        > dl{
                                            dd{
                                                font-size: 15px; letter-spacing: -1px;
                                            }
                                            &.call, &.sms{
                                                padding-left:25px; background-size:20px;
                                            }
                                        }
                                        .won{
                                            margin: 30px 0 25px 0;
                                            strong{
                                                font-size: 26px; letter-spacing: -1px;
                                            }
                                            span{
                                                font-size: 15px; letter-spacing: -1px;
                                            }
                                        }
                                        .gift{
                                            padding: 20px;
                                            ul{
                                                li{
                                                    font-size: 15px; letter-spacing: -1px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .main-phone{
                        padding: 0 20px;
                        .phone-slick{
                            padding-bottom: 28px; margin-bottom: 30px;
                        }
                        .kind{
                            li{
                                a{
                                    img{width:100%;}
                                }
                            }
                        }
                    }
                    .main-after{
                        padding: 0 20px;
                    }
                    .main-subscribe{
                        h2{padding: 0 20px;}
                        .slick-slider{
                            padding-bottom: 28px;
                        }
                    }
                    .main-inquiry{
                        padding: 0 20px;
                        ul{
                            margin-top: 40px;
                            li{
                                &:before{
                                    width: 60px; height: 60px; background-size: 40px;
                                }
                                min-height: 60px;
                                a{
                                    padding-left: 82px;
                                    dl{
                                        font-size: 15px;
                                        dt{
                                            font-size: 18px; margin-bottom: 10px;
                                        }
                                        dd{
                                            font-size: 15px; letter-spacing: -1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .slick-slider{
                .slick-dots{
                    li{
                        & +li{
                            margin-left: 5px;
                        }
                        button{
                            width: 8px; height: 8px;
                        }
                        &.slick-active{
                            button{width: 20px;}
                        }
                    }
                }
            }
        }
        .evt-benefit-home{
            padding: 40px 20px 0 20px; padding-top: 78px;
            .home-list{margin-top: 40px;}
            .c-unit{
                p{margin-bottom: 5px; font-size: 15px;}
                .unit-tit{
                    margin-bottom: 20px; font-size: 18px;
                }
                .box-btn{
                    margin-top: 10px;
                    .btn-text-arrow{
                        font-size: 15px; background-position: calc(100% - 0px) center; background-size: 16px 16px; padding-right: 26px;
                    }
                }
                & + .c-unit{margin-top: 40px;}
            }
        }
    }
}

.renewal-plan,
.renewal-phone {    
    margin-top:16.6667vw !important;
    .slider-title {
        width: calc(100% - 5.5556vw);white-space: nowrap;margin-left:5.5556vw;
        .slick-track {
            overflow-y: hidden; overflow-x: scroll;  display: flex; gap:6.9444vw; align-items: center;  width: 110% !important;  margin-left:-6.9444vw; padding-top:2.0833vw;     transform: translate3d(0px, 0px, 0px) !important; 
            &::-webkit-scrollbar{
                display: none;
            }
        }       
        .swipe-tab {
            position:relative;padding-bottom:1.1111vw;
            .new {position:absolute;right:-1.9444vw;top:-3px;width:6px;height:6px;border-radius: 100%;background:#ff0200; box-shadow: 0 0 0 ; animation:2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite  new-pulse; font-size:0;}              
            a {
                padding:0;border-radius: 0;background:none; font-size: 4.4444vw;font-weight: 600;line-height: 1.19;letter-spacing: -0.0889vw;text-align: left;color: #666;                
            }
            &.slick-current {
                &::after {content: '';position:absolute;left:0;bottom:0;width:100%;height:2px; background: #191919;}
                a {
                    color:#191919;
                }                    
            }            
        }         
    }
    .renewal-slider {  
        margin-top: 5.5556vw;      
        .slider-item {padding:0 5.5556vw;}        
    }
    .plan-list {
        li {
            & + li {margin-top:4.1667vw;}
            &:last-child {padding-bottom:2.7778vw;}
            a {
                display:block;padding: 5.5556vw; border-radius: 2.7778vw; box-shadow: 0.2778vw 0.6944vw 2.7778vw 0 rgba(220, 217, 238, 0.7); background-color: #fff;
                .plan-tit {display: block;font-size: 3.8889vw; line-height: 1.21; letter-spacing: -0.0778vw; text-align: left; color: #191919;}
                .plan-spc {display: block;margin-top:1.3889vw; font-size: 4.4444vw; font-weight: bold; line-height: 1.63; letter-spacing: -0.0889vw; text-align: left; color: #191919;}
                .plan-itm {
                    display: flex; margin-top: 4.1667vw; align-items: center; gap: 8.3333vw;
                    dl {
                        display: flex;flex-wrap: nowrap;align-items: center;gap:2.7778vw;
                        dt {font-size: 3.8889vw; line-height: 1.43;letter-spacing: -0.0778vw;text-align: left;color: #666;}
                        dd {font-size: 3.8889vw;font-weight: bold;line-height: 1.43;letter-spacing: -0.0778vw;text-align: left;color: #191919;}
                    }
                }
                .plan-pric {
                    display: flex; justify-content: flex-end; align-items: center; gap:2.7778vw; margin-top:4.1667vw; 
                    .cost-price {font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: right;color: #666; text-decoration: line-through;}
                    .dc-price {
                        font-size:3.6111vw; color:#662d91;
                        strong {font-size: 6.1111vw;font-weight: bold; line-height: 0.7; letter-spacing: -0.1222vw; color: #662d91;}
                    }
                }
            }
        }
    }
}

.renewal-phone {   
    .phoe-list {
        li {
            & + li {margin-top:4.1667vw;}
            &:last-child {padding-bottom:2.7778vw;}
            a {display: block; padding: 5.5556vw; border-radius: 2.7778vw;box-shadow: 0.2778vw 0.6944vw 2.7778vw 0 rgba(0, 0, 0, 0.1);border: solid 0.1389vw #eff1f5;background-color: #fff;}
            .phone-info {
                display:flex;gap:3.3333vw;
                .phone-img {
                    width:29.1667vw; 
                    img {width:100%;}
                }
                .phone-spc {
                    width:44.4444vw;
                    .spc-tit {display: block; font-size: 4.4444vw;font-weight: bold;line-height: 1.19;letter-spacing: -0.0889vw;text-align: left;color: #191919;}
                    .fixed-pric {display:block;margin-top:2.5vw; font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: right;color: #666;text-decoration: line-through;}
                    .fixed-pric + .spc-pric  {margin-top:1.3889vw;}
                    .spc-pric {
                        display:block; margin-top:5.5556vw;font-size: 3.6111vw; font-weight: bold;line-height: 0.7;letter-spacing: -0.1222vw;text-align: right;color: #662d91; 
                        strong {margin-left:0.6944vw; font-size:6.1111vw;font-weight: bold;color: #662d91;}
                    }
                    .itm-spc {display: block;margin-top:1.3889vw;  font-size: 3.6111vw;line-height: 1.54;letter-spacing: -0.0722vw;text-align: left;color: #666;}
                    .pric {
                        display: flex;align-items: center;justify-content: space-between; margin-top: 2.7778vw;
                        span {font-size: 3.6111vw;line-height: 1.19;letter-spacing: -0.0722vw;text-align: left;color: #191919;}
                        strong {font-size: 3.6111vw;font-weight: bold;line-height: 1.19;letter-spacing: -0.0722vw;text-align: right;color: #191919;}
                    }
                }
            }
        }
    }
}